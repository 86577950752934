import React, { useEffect, useState, useCallback, useRef } from 'react';
import MerchantListContainerComponent from './merchant-list-container.component';
import { NonResellerMerchantService, DocumentsService, PaymentsService } from 'services';
import { useBalanceContext, useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import _, { debounce } from 'lodash';
import { getFormattedDocumentsStatsForMerchant } from 'utils/helper';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import moment from 'moment';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

function MerchantListContainer(props) {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const { refreshBalance } = useBalanceContext();
    const [open, setOpen] = useState(false);
    const [searchedMerchantList, setSearchedMerchantList] = useState([]);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [thirdPartyCustomerKey, setThirdPartyCustomerKey] = useState(null);
    const [updateMerchantData, setUpdateMerchantData] = useState(false);
    const [needToSignTermsAndCondition, setNeedToSignTermsAndCondition] = useState(false);
    const [inCompleteOnboardingMerchants, setInCompleteOnboardingMerchants] = useState([]);
    const isMerchantUserType = globalState.user?.UserType?.name === UserType.Merchant;
    const isAdminUserType =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const location = useLocation();
    const wrapperRef = useRef(null);
    const skip_clicked = localStorage.getItem('skip_clicked');

    const getMerchants = async (isNewCreated, forcedMerchantId) => {
        setLoading(true);
        var merchants;
        var referralDataString;
        let inCompleteOnboardingMerchants;

        if (isMerchantUserType) {
            const response = await NonResellerMerchantService.getUserMerchants(globalState.reseller?.id);
            if (response !== null && response.isSuccesfully) {
                merchants = response.data.merchantsDto;
                referralDataString = response.data?.referralDataString;
                inCompleteOnboardingMerchants = JSON.parse([response.data?.InCompleteOnboardingMerchants]);
                setInCompleteOnboardingMerchants(inCompleteOnboardingMerchants);
            }
        } else if (isAdminUserType) {
            const response = await NonResellerMerchantService.getAdminMerchants(
                globalState.reseller?.id,
                'no',
                forcedMerchantId ?? globalState.merchant?.id
            );
            if (response !== null && response.isSuccesfully) {
                merchants = response.data.merchants;
            }
        }
        if (merchants?.length > 0) {
            setSearchedMerchantList(merchants);
            if (isNewCreated) {
                let sortedmerchants = _.sortBy(merchants, ['id']);
                selectMerchant(sortedmerchants[sortedmerchants.length - 1], inCompleteOnboardingMerchants);
                refreshBalance();
            } else {
                if (forcedMerchantId) {
                    const merchant = merchants.filter((merch) => {
                        return merch.id === forcedMerchantId;
                    });
                    selectMerchant(merchant[0] ?? null, inCompleteOnboardingMerchants);
                    if (merchant[0]) refreshBalance();
                } else {
                    const merchantFromGlobalState = globalState.merchant;
                    if (merchantFromGlobalState != null) {
                        const existMerchantFromList = merchants.find(
                            (merchant) => merchant.id === merchantFromGlobalState.id
                        );
                        if (existMerchantFromList != null) {
                            selectMerchant(existMerchantFromList, inCompleteOnboardingMerchants);
                        } else {
                            selectMerchant(merchants[0], inCompleteOnboardingMerchants);
                        }
                    } else {
                        selectMerchant(merchants[0], inCompleteOnboardingMerchants);
                        if (!sessionStorage.getItem('balance')) refreshBalance();
                    }
                }
            }
        } else {
            setSearchedMerchantList([]);
            selectMerchant(null);
            setIsOpenCreateModal(true);

            // if merchant was not created while sign up using referral link
            if (referralDataString) {
                setThirdPartyCustomerKey(referralDataString.referral_data);
            }
        }

        const response = await PaymentsService.GetPaymentProvider();
        if (response.isSuccesfully) {
            setGlobalState((globalState) => ({
                ...globalState,
                merchant: {
                    ...globalState.merchant,
                    paymentProviders: ['SELECT ALL', ...response.data]
                }
            }));
        }

        setLoading(false);
    };

    const onGetMerchants = useCallback(debounce(getMerchants, 500), []);
    useEffect(() => {
        const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (queryString.q) {
            setThirdPartyCustomerKey(queryString.q);
            setIsOpenCreateModal(true);
            localStorage.removeItem('thirdPartyResellerUrl');
        }

        let forceSelectMerchantId = props?.forcedMerchantIdThroughParam;

        if (forceSelectMerchantId) {
            onGetMerchants(false, +forceSelectMerchantId);
        } else {
            onGetMerchants();
        }
        // eslint-disable-next-line
    }, [location.search]);

    useEffect(() => {
        if (globalState.shouldLoadMerchants) {
            if (globalState?.shouldLoadThisMerchant) {
                onGetMerchants(false, globalState?.shouldLoadThisMerchant);
            } else {
                onGetMerchants();
            }
        }
        // eslint-disable-next-line
    }, [globalState.shouldLoadMerchants]);

    const selectMerchant = (merchant, inCompleteOnboardingMerchants) => {
        let pageUrlNeedsMerchantIdUpdate = window?.location?.href?.includes('/admin-functions/');
        let documentPageUrlMerchantIdUpdate = window?.location?.href?.includes('/document-management/');
        let MissingMerchantDocumentUpdate = window?.location?.href?.includes('/missing-merchant-document/');
        const notSignedTermsAndConditions = merchant ? merchant.noOfNotSignedTermsAndConditions : false;
        const isCreatedByCanonicalReseller = merchant ? ![1, 2].includes(merchant.canonicalResellerId) : false;
        if (notSignedTermsAndConditions && isCreatedByCanonicalReseller && isMerchantUserType) {
            setNeedToSignTermsAndCondition(true);
        }
        if (merchant) {
            if (pageUrlNeedsMerchantIdUpdate) {
                setRoute(`/admin-functions/${merchant?.id}`);
            }

            if (documentPageUrlMerchantIdUpdate) {
                setRoute(`/document-management/${merchant?.id}`);
            }
            if (MissingMerchantDocumentUpdate) {
                setRoute(`/missing-merchant-document/${merchant?.id}`);
            }

            // session storage to store two different merchant id in their respective tab if more than one tab opened
            sessionStorage.setItem('merchant', JSON.stringify(merchant));
            setGlobalState({ ...globalState, merchant: merchant, areLoadedMerchants: true });
            setSelectedMerchant(merchant);
            const isUkMerchant = merchant?.country === MerchantCountries.UNITED_KINGDOM;
            const isActiveMerchant = merchant?.status === MerchantStatus.ACTIVE;

            !skip_clicked &&
                isUkMerchant &&
                isActiveMerchant &&
                inCompleteOnboardingMerchants?.includes(merchant?.id) &&
                initCompletedSteps(merchant);
        } else {
            sessionStorage.removeItem('merchant');
            setGlobalState({ ...globalState, merchant: null, areLoadedMerchants: true });
            setSelectedMerchant(null);
        }
    };

    const handleClick = () => {
        setOpen((open) => !open);
    };

    useEffect(() => {
        const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
        const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
        if (
            !globalState?.completedOnboarding &&
            isUkMerchant &&
            isActiveMerchant &&
            inCompleteOnboardingMerchants?.includes(globalState.merchant?.id) &&
            skip_clicked
        ) {
            const endTime = moment().format('DD-MM-YYYY hh:mm:ss');
            const startTime = skip_clicked;
            let seconds = moment.utc(moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss'))).format('ss');
            if (seconds >= 7200) {
                isUkMerchant &&
                    isActiveMerchant &&
                    inCompleteOnboardingMerchants?.includes(globalState.merchant?.id) &&
                    initCompletedSteps(globalState.merchant);
                localStorage.removeItem('skip_clicked');
            } else {
                let timeOut = 7200000;
                if (seconds !== 'Invalid date') {
                    timeOut = timeOut - seconds * 10 ** 3;
                }
                const timeout = setTimeout(() => {
                    isUkMerchant &&
                        isActiveMerchant &&
                        inCompleteOnboardingMerchants?.includes(globalState.merchant?.id) &&
                        initCompletedSteps(globalState.merchant, false);
                    localStorage.removeItem('skip_clicked');
                }, timeOut);
                return () => clearTimeout(timeout);
            }
        }
        // eslint-disable-next-line
    }, [skip_clicked, inCompleteOnboardingMerchants, globalState.merchant]);

    const handleHideDropdown = (event) => {
        if (event.key === 'Escape') {
            setOpen(false);
        }
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    const openUpdateMerchantDataModal = () => {
        setIsOpenCreateModal(true);
        setUpdateMerchantData(true);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const initCompletedSteps = async (merchant, reload = true) => {
        reload && setLoading(true);
        const merchantId = merchant.id;
        const completedStepsResponse = await NonResellerMerchantService.t2sIncompleteOnBoardingFormCheck(merchantId);
        const documentsResponse = await DocumentsService.listObjects('merchant', merchantId);
        reload && setLoading(false);

        if (completedStepsResponse.isSuccesfully) {
            const isInComplete = !completedStepsResponse.data?.validOnboardingFrom;
            const inCompleteSteps = completedStepsResponse.data?.inCompleteSteps;
            const noDocumentsPending = getFormattedDocumentsStatsForMerchant({
                merchantBusinessTypeId: globalState.merchant?.businessTypeId,
                merchantCountry: globalState.merchant?.country,
                merchantDocuments: documentsResponse.data
            }).hasAllMandatoryDocsUploaded;

            if (isInComplete) {
                setGlobalState((globalState) => ({
                    ...globalState,
                    merchant: {
                        ...globalState.merchant,
                        onboardingStep: inCompleteSteps[0]
                    }
                }));
                setRoute('/onboarding');
            } else {
                if (!noDocumentsPending) {
                    setGlobalState((globalState) => ({
                        ...globalState,
                        merchant: {
                            ...globalState.merchant,
                            onboardingStep: 8
                        }
                    }));
                    setRoute('/onboarding');
                }
            }
        }
    };

    return (
        <MerchantListContainerComponent
            merchants={searchedMerchantList}
            setSelectedMerchant={setSelectedMerchant}
            selectMerchant={selectMerchant}
            selectedMerchant={selectedMerchant}
            isOpenCreateModal={isOpenCreateModal}
            setIsOpenCreateModal={setIsOpenCreateModal}
            loadMerchants={getMerchants}
            isAdminUserType={isAdminUserType}
            open={open}
            setOpen={setOpen}
            handleClick={handleClick}
            loading={loading}
            success={success}
            setSuccess={setSuccess}
            thirdPartyCustomerKey={thirdPartyCustomerKey}
            wrapperRef={wrapperRef}
            openUpdateMerchantDataModal={openUpdateMerchantDataModal}
            setUpdateMerchantData={setUpdateMerchantData}
            updateMerchantData={updateMerchantData}
            needToSignTermsAndCondition={needToSignTermsAndCondition}
            setNeedToSignTermsAndCondition={setNeedToSignTermsAndCondition}
        ></MerchantListContainerComponent>
    );
}

export default MerchantListContainer;
