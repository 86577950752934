import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Grid, Button, Box, CircularProgress } from '@material-ui/core';
import { FormItem, Label } from '../../../onboarding/onboarding-ui-components';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import styles from './trigger-reset-password.module.scss';
import { Spinner } from 'react-bootstrap';
import { SnackBarAndAlert } from 'components';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';

const AdminTriggerResetPasswordComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const {
        rows,
        loading,
        isErrorInTeamFetch,
        message,
        setMessage,
        merchantName,
        loggedInUserEmail,
        emailUndergoingReset,
        isSuccess,
        sendResetPasswordLink,
        count,
        rowsPerPage,
        page,
        handleChangeRowsPerPage,
        handleChangePage
    } = props;
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;

    return (
        <Grid>
            <Grid>
                <FormItem>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Label>Reset password link</Label>
                        </Grid>
                    </Grid>
                </FormItem>
            </Grid>
            {loading ? (
                <Box textAlign="center" py={5}>
                    <CircularProgress />
                </Box>
            ) : isErrorInTeamFetch ? (
                <div className={styles.noMembers}>{`Error fetching team members for merchant: ${merchantName}`}</div>
            ) : (
                <React.Fragment>
                    {message && (
                        <SnackBarAndAlert
                            open={message}
                            onClose={() => setMessage('')}
                            type={isSuccess ? 'success' : 'error'}
                        >
                            {message}
                        </SnackBarAndAlert>
                    )}
                    <Grid>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell align="left">
                                                {[row.firstName, row.lastName].join(' ')}
                                            </TableCell>
                                            <TableCell align="left" size="small">
                                                {' '}
                                                {row.email}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Grid container direction="row">
                                                    {emailUndergoingReset === row.email ? (
                                                        <div className={styles.requestOngoingSpinner}>
                                                            <Spinner animation="border" variant="primary" />
                                                        </div>
                                                    ) : (
                                                        <Button
                                                            disabled={
                                                                row.email === loggedInUserEmail ||
                                                                !!emailUndergoingReset ||
                                                                isReseller
                                                            }
                                                            onClick={() =>
                                                                sendResetPasswordLink(
                                                                    row.email,
                                                                    globalState.reseller?.portalURL
                                                                )
                                                            }
                                                        >
                                                            Send reset link
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    );
};

export default AdminTriggerResetPasswordComponent;
