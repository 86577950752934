import React from 'react';
import styles from './balance-card.module.scss';
import { Card, Typography, CardContent, CardActions } from '@material-ui/core';
import { formatAmount } from '../../utils/helper';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { getCurrencySymbol, getCountryImage } from 'utils/getCurrencyByCountry';
import { DotFlashingLoading } from 'components';

function BalanceCardComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { balance, calculateFontSize, balanceIsLoading } = props;

    return (
        <React.Fragment>
            <Card className={styles.card}>
                <CardContent className={styles.title}>
                    <Typography color="primary" gutterBottom>
                        <span className={styles.card_name}>Balance</span>
                        <span className={styles.country}>
                            <img alt="" width={40} height={30} src={getCountryImage(globalState.merchant?.country)} />
                        </span>
                    </Typography>
                </CardContent>
                <CardActions className={styles.balance}>
                    <div
                        style={calculateFontSize(parseFloat(balance)?.toFixed(2).toString().length)}
                        className={styles.action_items__account_balance}
                    >
                        {balanceIsLoading ? (
                            <DotFlashingLoading />
                        ) : (
                            <React.Fragment>
                                <span className={styles.action_items__currency}>
                                    {getCurrencySymbol(globalState.merchant?.country)}
                                </span>
                                {formatAmount(balance)}
                            </React.Fragment>
                        )}
                    </div>
                </CardActions>
            </Card>
        </React.Fragment>
    );
}

BalanceCardComponent.propTypes = {
    balance: PropTypes.number,
    calculateFontSize: PropTypes.func,
    balanceIsLoading: PropTypes.bool
};

export default BalanceCardComponent;
