import React, { useEffect, useState } from 'react';
import ClientRegisterComponent from './client-registration.component';
import { useGlobalStateContext } from 'contexts';
import { TaxonomyService, mypayClientRegisterService } from 'services';
import { InputValidators } from 'validators';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

function ClientRegister() {
    const virtualTerminal = 3;
    const { globalState, setRoute } = useGlobalStateContext();
    const [products, setProducts] = useState([]);
    const [contactDetails, setContactDetails] = useState({ country: MerchantCountries.UNITED_KINGDOM });
    const [businessDetails, setBusinessDetails] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [configDetails, setConfigDetails] = useState({
        acquireBank: 'FirstData',
        processorId: 5,
        testMode: false,
        threeDSecure: false
    });
    const [testmode, setTestmode] = useState(1);
    const [message, setMessage] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submitFail, setSubmitFail] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([virtualTerminal]);
    const [isLoading, setIsLoading] = useState([]);
    const [businessDescriptions, setBusinessDescriptions] = useState([]);
    const [businessDescriptionSearchString, setBusinessDescriptionSearchString] = useState({ name: '' });
    const imgPaths = [
        require('../../assets/icons/gateway.svg'),
        require('../../assets/icons/my-pay-app.svg'),
        require('../../assets/icons/virtual-terminal.png'),
        require('../../assets/icons/my-ecomm.svg'),
        require('../../assets/icons/card-machine.svg')
    ];
    useEffect(() => {
        const getProducts = async () => {
            setIsLoading(true);
            const productsRequest = await TaxonomyService.getProductsRequired();
            if (productsRequest.isSuccesfully) {
                const products = productsRequest.data;
                products.forEach((element, index) => {
                    element.imgPath = imgPaths[index];
                });
                setProducts(products);
            }
            setIsLoading(false);
        };

        getProducts();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const Descriptions = async () => {
            let businessDescriptions;
            const descriptionsFetchResponse = await TaxonomyService.getDescriptions();
            if (descriptionsFetchResponse.isSuccesfully) {
                businessDescriptions = descriptionsFetchResponse.data.businessDescriptions;
                businessDescriptions.push({ name: '' });
                setBusinessDescriptions(businessDescriptions);
            }
            setIsLoading(false);
            return businessDescriptions;
        };
        Descriptions();
    }, []);

    useEffect(() => {
        const allFieldsCompleted =
            contactDetails.legalName &&
            contactDetails.addressLine &&
            contactDetails.city &&
            contactDetails.postCode &&
            contactDetails.country &&
            contactDetails.telephoneNumber &&
            InputValidators.isEmailValid(contactDetails.email) &&
            businessDetails.legalName &&
            InputValidators.isWebsiteUrlValid(businessDetails.url) &&
            configDetails.merchantId &&
            selectedProducts.length > 0;

        if (allFieldsCompleted) {
            setDisabled(false);
        } else setDisabled(true);
    }, [configDetails, contactDetails, businessDetails, selectedProducts]);

    const selectAddressData = (addressData, contactDetails, setContactDetails) => {
        if (addressData) {
            setContactDetails({
                ...contactDetails,
                addressLine: addressData.line_1,
                city: addressData.town_or_city,
                country: MerchantCountries.UNITED_KINGDOM
            });
        }
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const response = await mypayClientRegisterService.clientDetails({
            contactDetails,
            businessDetails,
            configDetails,
            selectedProducts,
            resellerId: globalState.reseller.id
        });

        setIsLoading(false);
        if (response.isSuccesfully) {
            setSubmitSuccess(true);
            sessionStorage.setItem('isUserSubmitted', true);
            setRoute(`/admin-functions/${globalState?.merchant?.id}`);
        } else {
            setMessage(response?.error);
            setSubmitFail(true);
        }
    };

    return (
        <ClientRegisterComponent
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            isLoading={isLoading}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            configDetails={configDetails}
            setConfigDetails={setConfigDetails}
            onSubmit={onSubmit}
            testmode={testmode}
            setTestmode={setTestmode}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            setMessage={setMessage}
            submitSuccess={submitSuccess}
            setSubmitSuccess={setSubmitSuccess}
            message={message}
            submitFail={submitFail}
            setSubmitFail={setSubmitFail}
            disabled={disabled}
            selectAddressData={selectAddressData}
            businessDescriptions={businessDescriptions}
            setBusinessDescriptionSearchString={setBusinessDescriptionSearchString}
            businessDescriptionSearchString={businessDescriptionSearchString}
        />
    );
}
export default ClientRegister;
