import React, { useCallback } from 'react';
import ValidUrlInputComponent from './ur-input-field.component';
import { InputValidators } from 'validators';
import { debounce } from 'lodash';

const ValidUrlInput = (props) => {
    const { showDomainValidationMessage, setShowDomainValidationMessage } = props;

    const validation = (inputDomain) => {
        let isDomainValid;
        isDomainValid = InputValidators.isDomainUrlValid(inputDomain);
        inputDomain.length > 0 && setShowDomainValidationMessage(!isDomainValid);
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 500), []);

    return (
        <ValidUrlInputComponent
            {...props}
            doValidation={doValidation}
            showDomainValidationMessage={showDomainValidationMessage}
            setShowDomainValidationMessage={setShowDomainValidationMessage}
        />
    );
};

export default ValidUrlInput;
