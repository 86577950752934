import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText, DialogTitle } from '@material-ui/core';
import styles from './modal-form-confirmation.module.scss';
import Divider from '@material-ui/core/Divider';

export default function ModalFromConfirmationComponent(props) {
    const {
        isModalOpen,
        setIsModalOpen,
        confirmationAction,
        buttonText,
        modalText,
        setDouble,
        double,
        onCancelRefund,
        isDisabled,
        cancelAction,
        newStyle,
        modalHeader
    } = props;

    return (
        <Dialog
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            className={{
                paper: styles.paper
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            max-width="false"
        >
            {modalHeader && (
                <DialogTitle>
                    <div className={styles.dialogTitle}>{modalHeader}</div>
                </DialogTitle>
            )}
            <DialogContent
                classes={{
                    root: styles.dialogContent
                }}
            >
                <DialogContentText id="alert-dialog-description">{modalText}</DialogContentText>
                <Divider style={{ width: '100%' }} />
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        if (onCancelRefund) {
                            onCancelRefund();
                        }
                        setIsModalOpen(false);
                        if (cancelAction) {
                            cancelAction();
                        }
                    }}
                    color="primary"
                    variant="outlined"
                    className={`${newStyle ? '' : styles.leaveBtn}`}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    disabled={double || isDisabled}
                    onClick={() => {
                        setDouble(true);
                        confirmationAction();
                        setIsModalOpen(false);
                        setDouble(false);
                    }}
                    color="primary"
                    className={`${newStyle ? '' : styles.dialogBtn}`}
                >
                    {buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalFromConfirmationComponent.propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    confirmationAction: PropTypes.func,
    onChange: PropTypes.func,
    modalText: PropTypes.string,
    buttonText: PropTypes.string
};
