import React, { useState, useEffect, useCallback } from 'react';
import DialogViewDocumentsComponent from './dialog-view-documents.component';
import { AdminService, BankService, DocumentsService } from 'services';
import { useAdminFunctionsContext } from 'contexts';
import { useGlobalStateContext } from 'contexts';
import { debounce } from 'lodash';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';
import { BankAccountLengthConstaints } from 'utils/enums/BankAccountLength';
import { DocumentTypesId } from 'utils/enums/DocumentType';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { DocumentEventType } from 'utils/enums/DocumentEventType';
import _ from 'lodash';

export default function DialogViewDocuments(props) {
    const { documentToReview, documentIndex, setDocumentsUpdated, isModalOpen, setDocumentToReview } = props;
    const { globalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const [listOfUrlObj, setListOfUrlObj] = useState([]);
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const [currentDocumentNumber, setCurrentDocumentNumber] = useState(0);
    const [documentToView, setDocumentToView] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [sendNotesError, setSendNotesError] = useState(false);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
    const [sendButtonText, setSendButtonText] = useState('');
    const [accountBtnText, setAccountBtnText] = useState('');
    const [bankAccountBtnText, setBankAccountBtnText] = useState('');
    const [accountBtnDisabled, setAccountBtnDisabled] = useState(true);
    const [bankAccountBtnDisabled, setBankAccountBtnDisabled] = useState(true);
    const [bankUpdateRejected, setBankUpdateRejected] = useState(false);
    const [showNotesBlock, setShowNotesBlock] = useState(0);
    const [isValidIdNumber, setIsValidIdNumber] = useState(false);
    const [isValidCardNumber, setIsValidCardNumber] = useState(false);
    const [newBankDetailsConfirmation, setNewBankDetailsConfirmation] = useState({
        newSortCode: '',
        newAccountNumber: '',
        routingNumber: '',
        newBsbCode: '',
        transitNumber: '',
        financialInstitutionNumber: ''
    });
    const [bankData, setBankData] = useState({});
    const [updatedBankData, setUpdatedBankData] = useState({});
    const [codeValidationMessage, setCodeValidationMessage] = useState('');
    const [accNumberValidationMessage, setAccNumberValidationMessage] = useState('');
    const [approveBtnDisabled, setApproveBtnDisabled] = useState(true);
    const [rejectBtnDisabled, setRejectBtnDisabled] = useState(false);
    const [showAcceptReject, setShowAcceptReject] = useState(false);
    const [showRestore, setShowRestore] = useState(false);
    const [isBankUpdateRequest, setIsBankUpdateRequest] = useState(false);
    const [disableNewFields, setDisableNewFields] = useState(false);
    const [hideDelete, setHideDelete] = useState(false);
    const [firstTimeVisit, setFirstTimeVisit] = useState(false);
    const [showMerchantVerification, setShowMerchantVerification] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [fetchRejectReasonLoader, setFetchRejectReasonLoader] = useState(false);
    const [validationComplete, setValidationComplete] = useState(false);
    const [transitNumberMsg, setTransitNumberMsg] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankNameValidation, setBankNameValidation] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [cardNumber, setCardNumber] = useState('');

    const getDoc = async (document) => {
        const doc = await DocumentsService.getMerchantFilesUrls([document]);
        return doc[0];
    };
    useEffect(() => {
        const getData = async () => {
            if (documentToView.documentStatus == DocumentStatusToName.REJECTED) {
                const response = await AdminService.getDocumentById(documentToView.documentId);
                if (response.isSuccesfully) {
                    setNotes(response.data.comment);
                } else {
                    setNotes('');
                }
                setFetchRejectReasonLoader(false);
            }
        };
        getData();
    }, [documentToView]);

    useEffect(() => {
        const getData = async () => {
            setListOfUrlObj(documentToReview);
            if (documentToReview.length > 0) {
                const doc = await getDoc(documentToReview[documentIndex]);
                doc?.documentStatus === DocumentStatusToName.REJECTED && setFetchRejectReasonLoader(true);
                setDocumentToView(doc);
                setCurrentDocumentNumber(documentIndex);
                setFirstTimeVisit(true);
                setIdNumber(doc.idNumber);
                setCardNumber(doc.cardNumber);
            }
        };
        if (props.isModalOpen) {
            reset();
            getData();
        }

        // eslint-disable-next-line
    }, [props.isModalOpen, globalState.merchant]);

    useEffect(() => {
        if (isModalOpen && firstTimeVisit) {
            if (
                documentToView.documentStatus === DocumentStatusToName.NEED_APPROVAL ||
                documentToView.documentStatus === DocumentStatusToName.REJECTED ||
                documentToView.documentStatus === DocumentStatusToName.ACTIVE
            ) {
                setShowAcceptReject(true);
            } else {
                setShowAcceptReject(false);
            }

            if (
                documentToView.documentStatus === DocumentStatusToName.OLD ||
                documentToView.documentStatus === DocumentStatusToName.DELETED
            ) {
                setShowRestore(true);
            } else {
                setShowRestore(false);
            }

            if (
                documentToView.documentStatus === DocumentStatusToName.DELETED ||
                documentToView.documentStatus === DocumentStatusToName.REJECTED ||
                documentToView.documentStatus === DocumentStatusToName.ACTIVE
            ) {
                setHideDelete(true);
            } else {
                setHideDelete(false);
            }

            setFirstTimeVisit(false);
        }
        // eslint-disable-next-line
    }, [documentToView, isModalOpen, firstTimeVisit]);

    useEffect(() => {
        if (
            documentToView.flag === DocumentFlag.ONBOARDING &&
            (documentToView.docTypeId === DocumentTypesId.BANK_STATEMENT ||
                documentToView.docTypeId === DocumentTypesId.VOID_CHEQUE) &&
            !adminFunctions.bankVerify
        ) {
            setShowMerchantVerification(true);
        }

        if (
            documentToView.flag === DocumentFlag.ONBOARDING &&
            documentToView.docTypeId !== DocumentTypesId.BANK_STATEMENT &&
            documentToView.docTypeId !== DocumentTypesId.VOID_CHEQUE &&
            !adminFunctions.accountVerify
        ) {
            setShowMerchantVerification(true);
        }

        // eslint-disable-next-line
    }, [documentToView]);

    useEffect(() => {
        if (isModalOpen) {
            if (
                updatedBankData.approvalStatus === 'WAITING_APPROVAL' &&
                documentToView.flag === DocumentFlag.BANK_VERIFY &&
                documentToView.flag === DocumentStatusToName.NEED_APPROVAL &&
                !adminFunctions.bankVerify
            ) {
                const disableRejectButton = documentToView.documentStatus !== DocumentStatusToName.REJECTED;
                const disableApproveButton = documentToView.documentStatus !== DocumentStatusToName.ACTIVE;
                setApproveBtnDisabled(disableApproveButton);
                setRejectBtnDisabled(disableRejectButton);
            } else {
                const isNotAccountDoc =
                    documentToView.docTypeId === DocumentTypesId.BANK_STATEMENT ||
                    documentToView.docTypeId === DocumentTypesId.VOID_CHEQUE;

                const isNotBankDoc =
                    documentToView.docTypeId !== DocumentTypesId.BANK_STATEMENT &&
                    documentToView.docTypeId !== DocumentTypesId.VOID_CHEQUE;

                const disableAccountButton = isNotAccountDoc
                    ? isNotAccountDoc
                    : documentToView.documentStatus !== DocumentStatusToName.ACTIVE;

                const disableBankAccountButton = isNotBankDoc
                    ? isNotBankDoc
                    : documentToView.documentStatus !== DocumentStatusToName.ACTIVE;

                if (adminFunctions.accountVerify) {
                    setAccountBtnDisabled(adminFunctions.accountVerify);
                } else {
                    setAccountBtnDisabled(disableAccountButton);
                }
                if (adminFunctions.bankVerify) {
                    setBankAccountBtnDisabled(adminFunctions.bankVerify);
                } else {
                    setBankAccountBtnDisabled(disableBankAccountButton);
                }
            }
        }

        // eslint-disable-next-line
    }, [documentToView, isModalOpen, adminFunctions]);
    useEffect(() => {
        const checkDisabled = async () => {
            if (globalState.merchant?.country === MerchantCountries.IRELAND) {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length >= 22 &&
                    accNumberValidationMessage === '' &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            } else if (globalState.merchant?.country === MerchantCountries.NEW_ZEALAND) {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length >= 15 &&
                    accNumberValidationMessage === '' &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            } else if (globalState.merchant?.country === MerchantCountries.AUSTRALIA) {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length >= 5 &&
                    accNumberValidationMessage === '' &&
                    newBankDetailsConfirmation.newBsbCode.length === 6 &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            } else if (globalState.merchant?.country === MerchantCountries.UNITED_STATES) {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length >=
                        BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
                    accNumberValidationMessage === '' &&
                    newBankDetailsConfirmation.routingNumber.length === 9 &&
                    codeValidationMessage === '' &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            } else if (globalState.merchant?.country === MerchantCountries.MEXICO) {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length === 18 &&
                    accNumberValidationMessage === '' &&
                    codeValidationMessage === '' &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            } else if (globalState.merchant?.country === MerchantCountries.CANADA) {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length >= 7 &&
                    accNumberValidationMessage === '' &&
                    newBankDetailsConfirmation.financialInstitutionNumber.length >= 3 &&
                    newBankDetailsConfirmation.transitNumber.length >= 5 &&
                    codeValidationMessage === '' &&
                    transitNumberMsg === '' &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            } else {
                if (
                    newBankDetailsConfirmation.newAccountNumber.length > 7 &&
                    (newBankDetailsConfirmation.newBsbCode.length === 6 ||
                        newBankDetailsConfirmation.newSortCode.length === 6) &&
                    accNumberValidationMessage === '' &&
                    codeValidationMessage === '' &&
                    bankNameValidation === '' &&
                    bankName !== ''
                ) {
                    setApproveBtnDisabled(false);
                }
            }
            setValidationComplete(false);
        };

        if (validationComplete) {
            setApproveBtnDisabled(true);
            checkDisabled();
        } else {
        }

        // eslint-disable-next-line
    }, [codeValidationMessage, accNumberValidationMessage, newBankDetailsConfirmation, validationComplete, bankName]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await BankService.getBankDetails(globalState.merchant?.id);
            if (response.isSuccesfully) {
                setBankData(response.data);
            }
        };
        if (props.isModalOpen) {
            fetchData();
        }
    }, [globalState.merchant, props.isModalOpen]);

    useEffect(() => {
        const fetchNewBankData = async () => {
            const response = await BankService.getNewBankUpdateRequestDetails(globalState.merchant?.id);
            if (response.isSuccesfully) {
                setUpdatedBankData(response.data);
            }
        };

        fetchNewBankData();
    }, [globalState.merchant, bankData]);

    useEffect(() => {
        if (isModalOpen) {
            if (
                documentToView.flag === DocumentFlag.BANK_VERIFY &&
                documentToView.documentStatus === DocumentStatusToName.NEED_APPROVAL
            ) {
                setIsBankUpdateRequest(true);
            } else {
                setIsBankUpdateRequest(false);
            }
        }
    }, [documentToView, isModalOpen]);

    const downloadDocument = async () => {
        setIsLoading(true);
        await DocumentsService.getObjects([{ ...documentToView }]);
        setIsLoading(false);
    };

    const validation = (inputValue, type) => {
        if (inputValue) {
            if (type !== 'bankName' && inputValue.includes('-')) {
                inputValue = inputValue.replaceAll('-', '');
            }
            if (type === 'accountNumber') {
                if (updatedBankData.newAccountNumber !== inputValue) {
                    setAccNumberValidationMessage('Account number does not match');
                } else {
                    setAccNumberValidationMessage('');
                }
            }
            if (type === 'bsbcode') {
                if (updatedBankData.bsb !== inputValue) {
                    setCodeValidationMessage('BSB code does not match');
                } else {
                    setCodeValidationMessage('');
                }
            }
            if (type === 'routingnumber') {
                if (updatedBankData.routingNumber !== inputValue) {
                    setCodeValidationMessage('Routing does not match');
                } else {
                    setCodeValidationMessage('');
                }
            }
            if (type === 'transitnumber') {
                if (updatedBankData.transitNumber !== inputValue) {
                    setTransitNumberMsg('Transit number does not match');
                } else {
                    setTransitNumberMsg('');
                }
            }
            if (type === 'financenumber') {
                if (updatedBankData.financialInstitutionNumber !== inputValue) {
                    setCodeValidationMessage(' Financial Institution does not match');
                } else {
                    setCodeValidationMessage('');
                }
            }
            if (type === 'sortcode') {
                if (updatedBankData.sortCode?.toString() !== inputValue) {
                    setCodeValidationMessage('Sort code does not match');
                } else {
                    setCodeValidationMessage('');
                }
            }
            if (type === 'bankName') {
                if (updatedBankData.nameOfBank?.toString() !== inputValue) {
                    setBankNameValidation('Name of bank does not match');
                } else {
                    setBankNameValidation('');
                }
            }
        } else {
            if (type === 'accountNumber') {
                setAccNumberValidationMessage('');
            } else if (type === 'transitNumber') {
                setTransitNumberMsg('');
            } else {
                setCodeValidationMessage('');
            }
        }

        if (globalState.merchant?.country === MerchantCountries.IRELAND && inputValue === 'IE') {
            setAccNumberValidationMessage('');
        }
        setValidationComplete(true);
    };

    const updateDocumentInView = (status) => {
        let inViewDocument = { ...documentToView };
        inViewDocument.documentStatus = status;
        let allDocumentsInView = [...documentToReview];
        allDocumentsInView[documentIndex] = inViewDocument;

        setDocumentToView(inViewDocument);
        setDocumentToReview(allDocumentsInView);
    };

    // trigger validation function when user stop typing
    const bankDetailsValidation = useCallback(debounce(validation, 500), [updatedBankData]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let newBankDetails;
        newBankDetails = {
            newAccountNumber: newBankDetailsConfirmation.newAccountNumber,
            bsb: newBankDetailsConfirmation.newBsbCode,
            sortCode: newBankDetailsConfirmation.newSortCode,
            routingNumber: newBankDetailsConfirmation.routingNumber,
            transitNumber: newBankDetailsConfirmation.transitNumber,
            financialInstitutionNumber: newBankDetailsConfirmation.financialInstitutionNumber,
            documentId: documentToView.documentId,
            resellerId: globalState.reseller?.id,
            nameOfBank: bankName
        };
        setIsLoading(true);
        const response = await BankService.updateBankDetails(globalState.merchant?.id, newBankDetails);
        if (response.isSuccesfully) {
            setAdminFunctions({
                ...adminFunctions,
                bankVerify: true
            });
            setApproveBtnDisabled(true);
            setRejectBtnDisabled(true);
            setDisableNewFields(true);
            updateDocumentInView(DocumentStatusToName.ACTIVE);
            setDocumentsUpdated(true);
            setFirstTimeVisit(true);
            setShowMessage(true);
            setMessageType('success');
            setMessage('Bank details were accepted successfully.');
            setTimeout(() => {
                reset();
                props.setIsModalOpen(false);
            }, 3000);
        }
        setIsLoading(false);
    };

    const rejectBankUpdate = async () => {
        setIsLoading(true);
        const data = { documentId: documentToView.documentId, resellerId: globalState.reseller?.id, notes: notes };
        const response = await BankService.rejectNewBankDetailsUpdateRequest(globalState.merchant?.id, data);

        if (response.isSuccesfully) {
            setDisableNewFields(true);
            updateDocumentInView(DocumentStatusToName.REJECTED);
            setDocumentsUpdated(true);
            setFirstTimeVisit(true);
            setNotes('');
            setShowMessage(true);
            setMessageType('success');
            setTimeout(() => {
                reset();
                props.setIsModalOpen(false);
            }, 3000);

            setMessage('Bank details were rejected successfully.');
        }
        setIsLoading(false);
    };

    const verifyAccount = async () => {
        setAccountBtnDisabled(true);
        setAccountBtnText('Accepting...');

        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            accountVerify: true,
            resellerId: globalState.reseller?.id
        });
        if (response.isSuccesfully) {
            setAdminFunctions({
                ...adminFunctions,
                accountVerify: true
            });
            setAccountBtnText('Account accepted');
            setShowMerchantVerification(true);
        } else {
            setAccountBtnText('Account accept failed');
        }
    };

    const verifyBankAccount = async () => {
        setBankAccountBtnDisabled(true);
        setBankAccountBtnText('Accepting...');
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            bankVerify: true,
            resellerId: globalState.reseller?.id,
            accountStatus: 'Approved'
        });

        if (response.isSuccesfully) {
            setAdminFunctions({
                ...adminFunctions,
                bankVerify: true
            });
            setBankAccountBtnText('Bank account accepted');
            setShowMerchantVerification(true);
        } else {
            setBankAccountBtnText('Bank account accept failed');
        }
    };

    const updateDocumentStatus = async (status) => {
        setIsLoading(true);
        const data = {
            flag: documentToView.flag,
            documentStatus: status,
            documentTypeId: documentToView.docTypeId
        };
        const response = await DocumentsService.updateDocumentStatus(
            globalState.merchant?.id,
            documentToView.documentId,
            data
        );

        if (response.isSuccesfully) {
            setDocumentsUpdated(true);
            updateDocumentInView(status);
            setShowMessage(true);
            setMessageType('success');
            setDisableNewFields(false);
            if (status === DocumentStatusToName.REJECTED) {
                setMessage('Successfully Rejected the document.');
            } else if (status === DocumentStatusToName.ACTIVE) {
                setMessage('Document is accepted successfully.');
            } else if (status === DocumentStatusToName.DELETED) {
                setMessage('Document is deleted successfully.');
            } else if (status === DocumentStatusToName.NEED_APPROVAL) {
                setMessage('Document is restored successfully');
            }
            setTimeout(() => {
                reset();
                props.setIsModalOpen(false);
            }, 3000);
        }
        setIsLoading(false);
    };

    const reset = () => {
        setCurrentDocumentNumber(-1);
        setDocumentToView({});
        setListOfUrlObj([]);
        setNextDisabled(true);
        setPrevDisabled(true);
        setShowMessage(false);
        setIsLoading(false);
        setShowNotesBlock(false);
        setNotes('');
        setMessage('');
        setSendNotesError(false);
        setSendButtonDisabled(false);
        setSendButtonText('Send');
        setAccountBtnText(adminFunctions.accountVerify ? 'Account accepted' : 'Accept account');
        setBankAccountBtnText(adminFunctions.bankVerify ? 'Bank account accepted' : 'Accept bank account');
        setAccountBtnDisabled(adminFunctions.accountVerify);
        setBankAccountBtnDisabled(adminFunctions.bankVerify);
        setBankUpdateRejected(false);
        setShowMerchantVerification(false);
        setAccNumberValidationMessage('');
        setCodeValidationMessage('');
        setNewBankDetailsConfirmation({
            newSortCode: '',
            newAccountNumber: '',
            routingNumber: '',
            newBsbCode: '',
            transitNumber: '',
            financialInstitutionNumber: ''
        });
        setBankName('');
        setBankNameValidation('');
        setApproveBtnDisabled(true);
    };

    const navPrev = async () => {
        if (listOfUrlObj.length > 1 && currentDocumentNumber > 0) {
            setCurrentDocumentNumber((currentDocumentNumber) => currentDocumentNumber - 1);
            const doc = await getDoc(listOfUrlObj[currentDocumentNumber - 1]);
            doc?.documentStatus === DocumentStatusToName.REJECTED && setFetchRejectReasonLoader(true);
            setDocumentToView(doc);
            setFirstTimeVisit(true);
        }
    };

    const navNext = async () => {
        if (listOfUrlObj.length > 1 && currentDocumentNumber < listOfUrlObj.length - 1) {
            setCurrentDocumentNumber((currentDocumentNumber) => currentDocumentNumber + 1);
            const doc = await getDoc(listOfUrlObj[currentDocumentNumber + 1]);
            doc?.documentStatus === DocumentStatusToName.REJECTED && setFetchRejectReasonLoader(true);
            setDocumentToView(doc);
            setFirstTimeVisit(true);
        }
    };

    useEffect(() => {
        if (isModalOpen && listOfUrlObj.length > 1) {
            if (currentDocumentNumber < listOfUrlObj.length - 1) {
                setNextDisabled(false);
            } else {
                setNextDisabled(true);
            }
            if (currentDocumentNumber > 0) {
                setPrevDisabled(false);
            } else {
                setPrevDisabled(true);
            }
        }
        // eslint-disable-next-line
    }, [currentDocumentNumber, listOfUrlObj.length, isModalOpen]);

    const sendNotes = async (status, rejectBankDoc) => {
        const merchantId = globalState.merchant?.id;
        const dataToSend = {
            resellerId: globalState.reseller?.id,
            notes: status === DocumentStatusToName.REJECTED ? notes?.trim() : '',
            documentId: documentToView.documentId,
            documentStatus: status === DocumentStatusToName.ACTIVE ? 'APPROVED' : status,
            docTypeId: documentToView.docTypeId
        };

        setSendButtonDisabled(true);
        setSendButtonText('Sending...');

        console.log('dataToSend 2: ', dataToSend, documentToView.documentId, documentToView);

        const response = await AdminService.saveUserKycLogs(merchantId, dataToSend);

        if (response.isSuccesfully) {
            setNotes('');
            setSendButtonText('Send');
        } else {
            setSendNotesError(true);
            setSendButtonText('Send failed');
            setSendButtonDisabled(true);
        }
        if (rejectBankDoc) {
            rejectBankUpdate();
        }
    };

    const openInNewTab = async (doc) => {
        setIsLoading(true);
        const response = await DocumentsService.getPresignedUrl(doc, DocumentEventType.VIEWED);
        const tempDoc = { ...documentToView };
        tempDoc.url = response.data.presignedUrl;
        setDocumentToView(tempDoc);
        window.open(response.data.presignedUrl);
        setIsLoading(false);
    };

    let finalVal;
    if (!_.isEmpty(bankData)) {
        finalVal = bankData.newAccountNumber.toString();
        var myRegexp = /^([^\s]{6})([^\s]{7})([^\s]{2,3})$/g;
        var match = myRegexp.exec(finalVal);
        if (match) {
            match.shift();
            finalVal = match.join('-');
        }
    }

    const FormatNumber = () => {
        let val = '';
        const accNumberArr = newBankDetailsConfirmation?.newAccountNumber.split('');
        accNumberArr.forEach((value, index) => {
            if (index === 6 || index === 13) {
                val = val + '-';
                val = val + accNumberArr[index];
            } else {
                val = val + accNumberArr[index];
            }
        });
        return val;
    };

    const bankNameFieldOnChange = (e) => {
        let inputValue = e.target.value.replace(/[^A-Za-z0-9.()& -]/g, '').toUpperCase();
        bankDetailsValidation(inputValue, 'bankName');
        if (inputValue.length <= 80) setBankName(inputValue);
    };

    const bankNameFieldOnBlur = () => {
        let trimmedValue = bankName.trim().replace(/\s+/g, ' ');
        setBankName(trimmedValue);
        bankDetailsValidation(trimmedValue, 'bankName');
    };

    const bankNameFieldOnKeyDown = (e) => {
        const isCharacterAllowed = !/^[a-zA-Z0-9.()&\- ]+$/.test(e.key);
        const isBackspace = e.key === 'Backspace';
        if (isCharacterAllowed && !isBackspace) e.preventDefault();
    };

    const updateIdNumber = async () => {
        const data = {
            idNumber: idNumber
        };
        const response = await DocumentsService.updateDocumentType(
            globalState.merchant?.id,
            documentToView.documentId,
            data
        );
        if (response.isSuccesfully) {
            setShowMessage(true);
            setMessageType('success');
            setMessage('ID Number got updated successfully');
        }
    };
    const updateCardNumber = async () => {
        const data = {
            cardNumber: cardNumber
        };
        const response = await DocumentsService.updateDocumentType(
            globalState.merchant?.id,
            documentToView.documentId,
            data
        );
        if (response.isSuccesfully) {
            setShowMessage(true);
            setMessageType('success');
            setMessage('Card number got updated successfully');
        }
    };

    useEffect(() => {
        const coutry = globalState.merchant?.country === MerchantCountries.AUSTRALIA;
        if (coutry) {
            let validNumber = /^[a-zA-Z0-9]{6,11}$/.test(idNumber);
            if (validNumber) {
                setIsValidIdNumber(true);
            } else {
                setIsValidIdNumber(false);
            }
        } else {
            let validNumber = /^[0-9]{7,15}$/.test(idNumber);
            if (validNumber) {
                setIsValidIdNumber(true);
            } else {
                setIsValidIdNumber(false);
            }
        }
    }, [idNumber]);
    useEffect(() => {
        let validNumber = /^[a-zA-Z0-9]{6,10}$/.test(cardNumber);
        if (validNumber) {
            setIsValidCardNumber(true);
        } else {
            setIsValidCardNumber(false);
        }
    }, [cardNumber]);

    return (
        <DialogViewDocumentsComponent
            {...props}
            isLoading={isLoading}
            notes={notes}
            setNotes={setNotes}
            sendNotes={sendNotes}
            showNotesBlock={showNotesBlock}
            setShowNotesBlock={setShowNotesBlock}
            prevDisabled={prevDisabled}
            nextDisabled={nextDisabled}
            verifyAccount={verifyAccount}
            verifyBankAccount={verifyBankAccount}
            merchantName={globalState.merchant?.name}
            navPrev={navPrev}
            navNext={navNext}
            documentToView={documentToView}
            currentDocumentNumber={currentDocumentNumber}
            nrOfDocuments={listOfUrlObj.length}
            sendButtonDisabled={sendButtonDisabled}
            sendButtonText={sendButtonText}
            sendNotesError={sendNotesError}
            accountBtnText={accountBtnText}
            bankAccountBtnText={bankAccountBtnText}
            accountBtnDisabled={accountBtnDisabled}
            bankAccountBtnDisabled={bankAccountBtnDisabled}
            setNewBankDetailsConfirmation={setNewBankDetailsConfirmation}
            newBankDetailsConfirmation={newBankDetailsConfirmation}
            bankData={bankData}
            updatedBankData={updatedBankData}
            bankDetailsValidation={bankDetailsValidation}
            codeValidationMessage={codeValidationMessage}
            accNumberValidationMessage={accNumberValidationMessage}
            rejectBankUpdate={rejectBankUpdate}
            approveBtnDisabled={approveBtnDisabled}
            handleSubmit={handleSubmit}
            rejectBtnDisabled={rejectBtnDisabled}
            downloadDocument={downloadDocument}
            updateDocumentStatus={updateDocumentStatus}
            showAcceptReject={showAcceptReject}
            showRestore={showRestore}
            isBankUpdateRequest={isBankUpdateRequest}
            setBankUpdateRejected={setBankUpdateRejected}
            bankUpdateRejected={bankUpdateRejected}
            disableNewFields={disableNewFields}
            reset={reset}
            hideDelete={hideDelete}
            showMerchantVerification={showMerchantVerification}
            message={message}
            messageType={messageType}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            setMessage={setMessage}
            openInNewTab={openInNewTab}
            finalVal={finalVal}
            FormatNumber={FormatNumber}
            transitNumberMsg={transitNumberMsg}
            bankName={bankName}
            setBankName={setBankName}
            fetchRejectReasonLoader={fetchRejectReasonLoader}
            bankNameValidation={bankNameValidation}
            bankNameFieldOnChange={bankNameFieldOnChange}
            bankNameFieldOnBlur={bankNameFieldOnBlur}
            bankNameFieldOnKeyDown={bankNameFieldOnKeyDown}
            idNumber={idNumber}
            setIdNumber={setIdNumber}
            updateIdNumber={updateIdNumber}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            updateCardNumber={updateCardNumber}
            isValidIdNumber={isValidIdNumber}
            isValidCardNumber={isValidCardNumber}
        />
    );
}
