import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    CircularProgress,
    Button,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import TableHeader from '../../transaction-tables/table-header/table-header.container';
import { formatCurrency, formatDateTimeByFormatString } from 'utils/helper';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import moment from 'moment';
import { ModalExportReport } from 'components';
import styles from './stripe-payouts-summary-and-transactions.module.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        'overflow-y': 'visible'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    'MuiIconButton-root': {
        padding: '0px 12px'
    }
}));

const StripePayoutsSummaryAndTransactionsComponents = (props) => {
    const {
        summaryTableHeaders,
        transactionsTableHeaders,
        transactions,
        summary,
        totalPayouts,
        loading,
        csvData,
        setShowReportModel,
        showReportModel,
        onCancelExport,
        onConfirmExport,
        via,
        date,
        payoutStartDate,
        payoutEndDate,
        page,
        handleChangeRowsPerPage,
        handleChangePage,
        rowsPerPage
    } = props;
    let count = 0;
    const classes = useStyles();
    const { globalState } = useGlobalStateContext();

    return (
        <React.Fragment>
            {loading ? (
                <Box textAlign="center" py={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <div>
                    <div className={styles.summaryDivContainer}>
                        <Box width="100%">
                            <div>
                                <Typography color={'primary'}>
                                    <span className={styles.sectionTitle}>Summary</span>
                                </Typography>
                            </div>
                            <Box mt={2} className={styles.overflowX}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size="small"
                                    stickyHeader
                                    aria-label="enhanced table"
                                >
                                    <TableHeader classes={classes} headCells={summaryTableHeaders} />
                                    <TableBody>
                                        {summary.map((row) => (
                                            <TableRow>
                                                <TableCell>{row.label}</TableCell>
                                                <TableCell className={styles.alignLeft}>{row.count}</TableCell>
                                                <TableCell className={styles.alignLeft}>
                                                    {formatCurrency(
                                                        row?.gross / 100,
                                                        getCurrencyThreeAlpha(globalState.merchant?.country)
                                                    )}
                                                </TableCell>
                                                <TableCell className={styles.alignLeft}>
                                                    {formatCurrency(
                                                        row?.fees / 100,
                                                        getCurrencyThreeAlpha(globalState.merchant?.country)
                                                    )}
                                                </TableCell>
                                                <TableCell className={styles.alignLeft}>
                                                    {formatCurrency(
                                                        row.total / 100,
                                                        getCurrencyThreeAlpha(globalState.merchant?.country)
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <span className={styles.totalPayouts}>Payouts</span>
                                            </TableCell>
                                            <TableCell className={styles.alignLeft}>
                                                <span className={styles.totalPayouts}>
                                                    {formatCurrency(
                                                        totalPayouts / 100,
                                                        getCurrencyThreeAlpha(globalState.merchant?.country)
                                                    )}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </div>
                    <div className={styles.transactionsDivContainer}>
                        <Box width="100%">
                            <div className={styles.transactionsHeader}>
                                <Typography color={'primary'}>
                                    <span className={styles.sectionTitle}>Transactions</span>
                                </Typography>
                                <Box mx={2}>
                                    <Button
                                        id="transactionsExportBtn"
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setShowReportModel(true)}
                                    >
                                        Export
                                    </Button>
                                </Box>
                            </div>

                            <Box mt={2} className={styles.overflowX}>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size="small"
                                    stickyHeader
                                    aria-label="enhanced table"
                                >
                                    <TableHeader classes={classes} headCells={transactionsTableHeaders} />
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : transactions
                                        ).map((transaction) => {
                                            if (transaction.type !== 'payout') {
                                                count++;
                                                return (
                                                    <TableRow>
                                                        <TableCell className={styles.transactionType}>
                                                            {transaction.type}
                                                        </TableCell>
                                                        <TableCell className={styles.alignLeft}>
                                                            {formatCurrency(
                                                                transaction.amount / 100,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={styles.alignLeft}>
                                                            {formatCurrency(
                                                                transaction.fee / 100,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={styles.alignLeft}>
                                                            {formatCurrency(
                                                                transaction.net / 100,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatDateTimeByFormatString(
                                                                moment.unix(transaction.created),
                                                                'DD MMM'
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            return null;
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            {transactions.length > rowsPerPage ? (
                                                <TablePagination
                                                    rowsPerPageOptions={[5, { label: 'All', value: count }]}
                                                    count={count}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true
                                                    }}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            ) : null}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ color: '#000' }} colSpan={5}>
                                                <Box className={styles.divTableFooter}>
                                                    <Box>
                                                        <span className={styles.columnSpan}>Transactions:</span>
                                                        <span className={styles.amountSpan}>{count}</span>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </Box>
                        </Box>
                    </div>
                </div>
            )}
            {showReportModel ? (
                <ModalExportReport
                    reportType="Stripe_Payout_details"
                    timeFrameDetails={{ via: via || 'month', date: date }}
                    cancelExport={onCancelExport}
                    confirmExport={onConfirmExport}
                    csvData={csvData}
                    payOutDetails={true}
                    payoutStartDate={payoutStartDate}
                    payoutEndDate={payoutEndDate}
                    setShowReportModel={setShowReportModel}
                    showReportModel={showReportModel}
                />
            ) : null}
        </React.Fragment>
    );
};

export default StripePayoutsSummaryAndTransactionsComponents;
