import React, { useState } from 'react';
import CreateUserFormComponent from './create-user-form.component';
import { UserService } from 'services';

function CreateUserForm(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successfully, setSuccessfully] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = (event) => {
        setSuccessfully(false);
        setError(false);
        setLoading(true);
        UserService.createUser({
            firstName: firstName,
            lastName: lastName,
            email: email
        }).then((result) => {
            if (!result.isSuccesfully) {
                setError(true);
                const errorMessage = result.data.error ?? 'An error has occurred.';
                setErrorMessage(errorMessage);
                setLoading(false);
            } else {
                props.setShow(false);
                props.loadUsersAfterOperation();
                setSuccessfully(true);
                setLoading(false);
            }
        });

        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <CreateUserFormComponent
            {...props}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            error={error}
            errorMessage={errorMessage}
            loading={loading}
            successfully={successfully}
            onSubmit={onSubmit}
        />
    );
}

export default CreateUserForm;
