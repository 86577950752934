import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PaymentActionComponent from './payment-action.component';
import { WithdrawalSubmission, InternalTransferSubmission } from 'components';
import { PaymentType } from 'utils/enums/PaymentType';
import { useBalanceContext, useGlobalStateContext } from 'contexts';
import { BusinessDetailsService } from 'services';
import { validatePaymentAmount } from 'validators';
import { validateWithdraw } from 'validators';

/**
 * 
 * @param {{
    textPlaceHolder: string,
    textInСircle: string,
    balanceValue?: number,
    inputValue: number,
    onInput: (event: Event) => {},
    btnText: string,
    onBtnClick: () => {}
 * }} props 
 */
function PaymentAction(props) {
    const [isSuccessWithdraw, setIsSuccessWithdraw] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const { show, setShow, paymentType } = props;

    const { globalState, setRoute } = useGlobalStateContext();
    // Check please if we should remove or leave refreshBalance (if you will see conflict on merge)
    // On migration it was removed, on dev we should leave it for now.
    const { availableBalance } = useBalanceContext();
    const [showActionError, setShowActionError] = useState(false);
    const textInCircle = `${globalState?.user?.firstName?.charAt(0)}${globalState?.user?.lastName?.charAt(0)}`;
    const textPlaceHolder = 'Hi ' + globalState.user?.firstName;
    const [amount, setAmount] = useState(null);
    const [isVerifiedBank, setIsVerifiedBank] = useState(false);
    const [openView, setOpenView] = useState(false);

    useEffect(() => {
        const getBankStatus = async () => {
            const response = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setIsVerifiedBank(
                    (response.data.isBankAccountVerified && response.data.isAccountVerified) ||
                        response.data.allowWithdrawals
                );
            } else {
                setIsVerifiedBank(false);
            }
        };

        if (globalState.merchant?.id) {
            getBankStatus();
        }
    }, [globalState.merchant]);

    function paymentActionValidation() {
        const validationMessage = validatePaymentAmount(availableBalance, amount);
        setShowActionError(!validationMessage.isValid);
        return validationMessage.message;
    }
    const [cantWithdraw, setCantWithdraw] = useState(false);
    const [cantWithdrawMessage, setCantWithdrawMessage] = useState('');

    const renderPopup = () => {
        switch (paymentType) {
            case PaymentType.ChargeBack:
                //Add the ChargeBack popup
                break;
            case PaymentType.InternalTransfer:
                return (
                    <InternalTransferSubmission
                        setIsSuccessWithdraw={setIsSuccessWithdraw}
                        setSuccessMessage={setSuccessMessage}
                        show={show}
                        setShow={setShow}
                        transferAmount={amount}
                        setTransferAmount={setAmount}
                        setOpenView={setOpenView}
                    />
                );
            case PaymentType.MerchantCharges:
                //Add the Merchant Charges popup
                break;
            case PaymentType.Withdrawal:
                return (
                    <WithdrawalSubmission
                        setIsSuccessWithdraw={setIsSuccessWithdraw}
                        setSuccessMessage={setSuccessMessage}
                        show={show}
                        setShow={setShow}
                        withdrawalAmount={amount}
                        setWithdrawalAmount={setAmount}
                        setOpenView={setOpenView}
                    />
                );
            default:
                break;
        }
    };

    const onBtnClick = async () => {
        if (isVerifiedBank) {
            const response = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                const cantWithDrawValidationResult = validateWithdraw(
                    response.data.isAccountVerified,
                    response.data.isBankAccountVerified,
                    response.data.allowWithdrawals
                );
                if (!cantWithDrawValidationResult.isValid) {
                    setCantWithdrawMessage(cantWithDrawValidationResult.message);
                    setCantWithdraw(true);
                } else {
                    setShow(true);
                }
            } else {
                setCantWithdrawMessage('An error occurred. Please contact support or try again later.');
                setCantWithdraw(true);
            }
        } else {
            setRoute('/upload-documents');
        }
    };

    return (
        <PaymentActionComponent
            {...props}
            renderPopup={renderPopup}
            isSuccessWithdraw={isSuccessWithdraw}
            setIsSuccessWithdraw={setIsSuccessWithdraw}
            successMessage={successMessage}
            textPlaceHolder={textPlaceHolder}
            inputValue={amount}
            isVerifiedBank={isVerifiedBank}
            onBtnClick={onBtnClick}
            textInСircle={textInCircle}
            showActionError={showActionError}
            paymentActionValidation={paymentActionValidation}
            amount={amount}
            setAmount={setAmount}
            cantWithdraw={cantWithdraw}
            cantWithdrawMessage={cantWithdrawMessage}
            setOpenView={setOpenView}
            openView={openView}
        />
    );
}

PaymentAction.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    paymentType: PropTypes.number
};

export default PaymentAction;
