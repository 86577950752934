import React, { useState, useEffect } from 'react';
import LoginFormComponent from './login-form.component';
import { useGlobalStateContext } from 'contexts';
import moment from 'moment';
import {
    UserService,
    AuthService,
    NonResellerMerchantService,
    DocumentsService,
    CanonicalResellerService
} from 'services';
import { Auth, Hub, Cache } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import * as Sentry from '@sentry/react';
import { supportedLanguages } from 'utils/constants';
import { UserType } from 'utils/enums/UserType';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { MAX_PASSWORD_CHANGE_DAYS } from 'utils/enums/ChangePasswordTriggerDays';
import { getFormattedDocumentsStatsForMerchant } from 'utils/helper';
import { Roles } from 'utils/enums/Roles';
import _ from 'lodash';
import { errorCodes } from 'utils/internalStatusCode';
import { ResellerType } from 'utils/enums/ResellerType';

function LoginForm(props) {
    const { globalState, setGlobalState, setRoute, getRoute } = useGlobalStateContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [errorCodeMsg, setErrorCodeMsg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isСonfirmationEmailShows, setСonfirmationEmailShows] = useState(false);
    const [isEmailConfirmedSuccessfully, setIsEmailConfirmedSuccessfully] = useState(false);
    const [isPasswordlessLogin, setIsPasswordlessLogin] = useState(false);
    const [customChallengePreviousResponseEmail, setCustomChallengePreviousResponseEmail] = useState(false);
    const [customChallengePreviousResponsePhone, setCustomChallengePreviousResponsePhone] = useState(false);
    const [verificationCode, setVerificationCode] = useState(null);
    const [passwordLessEmail, setPasswordLessEmail] = useState(null);
    const [passwordLessPhone, setPasswordLessPhone] = useState(null);
    const [passwordLessPhoneRelatedEmail, setPasswordLessPhoneRelatedEmail] = useState(null);
    const [needToVerifyCode, setNeedToVerifyCode] = useState(false);
    const [codeSentToPhone, setCodeSentToPhone] = useState(false);
    const [remainingRetryNumber, setRemainingRetryNumber] = useState(3);
    const [showSmsSent, setShowSmsSent] = useState(false);
    const [cloakedPhoneNumber, setCloakedPhoneNumber] = useState('');
    const [isUpdatePasswordMessageShows, setIsUpdatePasswordMessageShows] = useState(false);
    const [querySubmitted, setQuerySubmitted] = useState(false);
    const [generalPageError, setGeneralPageError] = useState('');
    const [countryCodeByNumber, setCountryCodeByNumber] = useState(null);
    const [orangeMessageStyle, setOrangeMessageStyle] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const newPasswordRequired = 'NEW_PASSWORD_REQUIRED';
    const CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE';
    const invalidOtpMessage = 'Verification code is invalid.';
    const location = useLocation();
    const local = navigator?.language;
    const locale = supportedLanguages.find((language) => language.locale === local) ? local : 'en-GB';
    const isDatman = document.URL.includes('portal.datmancrm.com');
    const isOmnipay = document.URL.includes('portal.omni-pay.com');
    const [invalidAttempts, setInvalidAttempts] = useState(0);
    let maxAttempts = 6;

    function resetCountAndStartTimer() {
        const timer = setTimeout(localStorage.setItem('invalidAttempts', 0), 60000); // Reset count after 60 seconds
        clearTimeout(timer);
    }

    useEffect(() => {
        const storedAttempts = localStorage.getItem('invalidAttempts');
        if (storedAttempts) {
            setInvalidAttempts(parseInt(storedAttempts));
        }
    }, []);

    useEffect(() => {
        const timerStart = parseInt(localStorage.getItem('timerStart'));
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - timerStart;
        const minuteInMillis = 60 * 1000;

        if (invalidAttempts === maxAttempts || !timerStart || elapsedTime >= minuteInMillis) {
            localStorage.setItem('invalidAttempts', 0);
            resetCountAndStartTimer();
        } else {
            localStorage.setItem('invalidAttempts', invalidAttempts.toString());
        }
        //eslint-disable-next-line
    }, [invalidAttempts]);
    useEffect(() => {
        const urlParams = parse(location.hash);
        if (urlParams.email_verification) {
            setСonfirmationEmailShows(true);
            if (urlParams.email_verification === 'success') {
                setIsEmailConfirmedSuccessfully(true);
                if (urlParams.email && urlParams.email !== 'undefined') {
                    setEmail(urlParams.email.replaceAll(' ', '+'));
                }
            } else {
                if (urlParams.errorMessage) {
                    setError(urlParams.errorMessage);
                }
                setIsEmailConfirmedSuccessfully(false);
            }
        }

        if (urlParams['update-password']) {
            setIsUpdatePasswordMessageShows(true);
        }
    }, [location]);

    useEffect(() => {
        Hub.listen('SignupSocial', ({ payload: { event, data } }) => {
            switch (event) {
                case 'start': {
                    setLoading(true);
                    Auth.currentAuthenticatedUser({ bypassCache: true })
                        .then((response) => {
                            const thirdPartyResellerUrlParameterCache = Cache.getItem('thirdPartyResellerUrlParameter');
                            if (globalState.reseller?.portalURL === 'portal.datmancrm.com') {
                                if (thirdPartyResellerUrlParameterCache) {
                                    //Register user in db and send email
                                    saveUserAndSendEmail(data, response, thirdPartyResellerUrlParameterCache);
                                }
                                // restrict signup for non t2s users and remove already registered  user in cognito
                                else {
                                    UserService.getUserByCognitoUserName(response.attributes.sub).then(
                                        (responseUser) => {
                                            if (responseUser.isSuccessfully && responseUser.user) {
                                                localStorage.setItem('user', JSON.stringify(responseUser.user));
                                                setGlobalState({ ...globalState, user: responseUser.user });
                                                if (props.linkQrData) setRoute(`/home${props.linkQrData}`);
                                                setRoute('/home');
                                            } else if (responseUser.isSuccessfully && !responseUser.user) {
                                                setLoading(false);
                                                setError('User not allowed to enter in this portal');
                                                AuthService.removeCognitoUser(
                                                    data.signInUserSession.idToken.payload['cognito:username']
                                                )
                                                    .then({})
                                                    .catch({});
                                                AuthService.logout();
                                            } else {
                                                setLoading(false);
                                                setError(response.message);
                                            }
                                        }
                                    );
                                }
                            } else {
                                // Mypay
                                //Check if user exists
                                UserService.getUserByCognitoUserName(response.attributes.sub).then((responseUser) => {
                                    if (responseUser.isSuccessfully && responseUser.user) {
                                        localStorage.setItem('user', JSON.stringify(responseUser.user));
                                        setGlobalState({ ...globalState, user: responseUser.user });
                                        if (props.linkQrData) setRoute(`/home${props.linkQrData}`);
                                        else setRoute('/home');
                                    } else if (responseUser.isSuccessfully && !responseUser.user) {
                                        //Signup new user
                                        saveUserAndSendEmail(data, response, thirdPartyResellerUrlParameterCache);
                                    } else {
                                        setLoading(false);
                                        setError(response.message);
                                        console.error(response.message);
                                    }
                                });
                            }
                        })
                        .catch((authError) => {
                            setLoading(false);
                            setError(authError.message);
                        });
                    break;
                }
                default: {
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const showMessage = sessionStorage.getItem('querySubmitted');
        if (showMessage) {
            sessionStorage.removeItem('querySubmitted');
            setQuerySubmitted(true);
        }
    }, []);

    useEffect(() => {
        const pageError = sessionStorage.getItem('generalPageError');
        if (pageError) {
            sessionStorage.removeItem('generalPageError');
            setGeneralPageError(pageError);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setError(null);
        setErrorCode(null);
        setLoading(true);
        setIsDisabled(true);

        Auth.signIn({
            username: email,
            password: password
        })
            .then((response) => {
                if (response.challengeName === newPasswordRequired) {
                    setRoute('/signup', {
                        email: email,
                        password: password
                    });
                } else {
                    UserService.getUserByCognitoUserName(response.attributes.sub).then(async (response) => {
                        const isReseller = response.user?.UserType?.name === UserType.Reseller;
                        let resellerResponse;

                        let resellerType;

                        const isAgent = _.isArray(response.user?.Relationships)
                            ? response.user?.Relationships[0]?.Role.name === Roles.Agent
                            : false;

                        if (isReseller) {
                            resellerResponse = await CanonicalResellerService.getCanonicalResellersAPI();
                            resellerType =
                                resellerResponse.data[0].resellerId === 1 ? ResellerType.OMNIPAY : ResellerType.DATMAN;
                        }
                        if (response.user.isPep) {
                            AuthService.logout();
                            Auth.signOut({ global: true });
                            setError('Your account is locked');
                            setLoading(false);
                        } else if (
                            (isDatman && resellerType === ResellerType.OMNIPAY) ||
                            (isOmnipay && resellerType === ResellerType.DATMAN) ||
                            (isAgent && !isDatman)
                        ) {
                            AuthService.logout();
                            Auth.signOut({ global: true });
                            setError('User not found');
                            setLoading(false);
                        } else {
                            localStorage.setItem('selectedLanguage', locale);
                            const user = response.user;
                            const isMerchant = response.user?.UserType?.name === UserType.Merchant;

                            setGlobalState({ ...globalState, user: response.user, merchant: null });
                            if (isMerchant) {
                                const response = await NonResellerMerchantService.getUserMerchants(
                                    globalState.reseller?.id
                                );

                                if (response !== null && response.isSuccesfully) {
                                    const merchants = response.data.merchantsDto;
                                    const inCompleteOnboardingMerchants = JSON.parse([
                                        response.data?.InCompleteOnboardingMerchants
                                    ]);
                                    if (merchants?.length > 0) {
                                        const merchant = merchants[0];
                                        setGlobalState((globalState) => ({
                                            ...globalState,
                                            merchant,
                                            user: user
                                        }));

                                        sessionStorage.setItem('merchant', JSON.stringify(merchant));
                                        const isUkMerchant = merchant?.country === MerchantCountries.UNITED_KINGDOM;
                                        const isActiveMerchant = merchant?.status === MerchantStatus.ACTIVE;
                                        if (
                                            isUkMerchant &&
                                            isActiveMerchant &&
                                            inCompleteOnboardingMerchants?.includes(merchant?.id)
                                        ) {
                                            const merchantId = merchant.id;
                                            const completedStepsResponse = await NonResellerMerchantService.t2sIncompleteOnBoardingFormCheck(
                                                merchantId
                                            );
                                            const documentsResponse = await DocumentsService.listObjects(
                                                'merchant',
                                                merchantId
                                            );
                                            if (completedStepsResponse.isSuccesfully) {
                                                const isInComplete = !completedStepsResponse.data?.validOnboardingFrom;
                                                const inCompleteSteps = completedStepsResponse.data?.inCompleteSteps;
                                                const noDocumentsPending = getFormattedDocumentsStatsForMerchant({
                                                    merchantBusinessTypeId: merchant?.businessTypeId,
                                                    merchantCountry: merchant?.country,
                                                    merchantDocuments: documentsResponse.data
                                                }).hasAllMandatoryDocsUploaded;
                                                if (isInComplete) {
                                                    setGlobalState((globalState) => ({
                                                        ...globalState,
                                                        merchant: {
                                                            ...globalState.merchant,
                                                            onboardingStep: inCompleteSteps[0]
                                                        }
                                                    }));
                                                    localStorage.setItem('user', JSON.stringify(user));
                                                    setRoute('/onboarding');
                                                    return;
                                                } else {
                                                    if (!noDocumentsPending) {
                                                        setGlobalState((globalState) => ({
                                                            ...globalState,
                                                            merchant: {
                                                                ...globalState.merchant,
                                                                onboardingStep: 8
                                                            }
                                                        }));
                                                        localStorage.setItem('user', JSON.stringify(user));
                                                        setRoute('/onboarding');
                                                        return;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            setLoading(false);
                            setInvalidAttempts(0);
                            localStorage.setItem('user', JSON.stringify(response.user));
                            Sentry.withScope((scope) => {
                                scope.setTag('login', 'success');
                                Sentry.captureMessage('Login success');
                            });
                            if (props.linkQrData) setRoute(`/home${props.linkQrData}`);
                            else setRoute('/home');
                        }
                        const diffeneceInTime = moment().diff(moment(response.user.updatedPasswordAt), 'days');
                        if (diffeneceInTime >= MAX_PASSWORD_CHANGE_DAYS) {
                            const { firstName, lastName } = response.user;
                            const encodedEmail = encodeURIComponent(email);
                            AuthService.logout();
                            Auth.signOut({ global: true });
                            const redirectUrlForresetPassword = getRoute(
                                `/reset-password?email=${encodedEmail}&reset-token=${password}&firstName=${firstName}&lastName=${lastName}&passwordRotation=passwordRotation`
                            );
                            window.location.href = redirectUrlForresetPassword;
                        }
                    });
                }
            })
            .catch((err) => {
                let errorMessage = err.message?.replace('UserMigration failed with error ', '');

                if (
                    errorMessage === 'Incorrect username or password.' ||
                    errorMessage === 'User does not exist.' ||
                    errorMessage === 'Password attempts exceeded' ||
                    errorMessage === 'User is disabled.'
                ) {
                    errorMessage = 'Your authentication information is incorrect. Please try again.';
                }
                setError(errorMessage);
                const updatedInvalidAttempts = invalidAttempts + 1;
                setInvalidAttempts(updatedInvalidAttempts);
                if (updatedInvalidAttempts === 1) {
                    localStorage.setItem('timerStart', new Date().getTime().toString());
                }
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                const maxAttemptsforError = 4;
                const remainingValidAttempt = maxAttempts - maxAttemptsforError;
                if (updatedInvalidAttempts === maxAttemptsforError) {
                    setError(
                        `You have tried too many times, you have ${remainingValidAttempt} valid attempts remaining.`
                    );
                }
                setLoading(false);
                // setIsDisabled(false);
                setErrorCode(err.code);
                event.preventDefault();
                event.stopPropagation();
                Sentry.withScope((scope) => {
                    scope.setTag('login', 'failed');
                    scope.setUser({ email: email });
                    Sentry.captureException(new Error(err.message));
                });
            });
    };

    const loginWithEmail = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setError(null);
        setErrorCode(null);
        setErrorCodeMsg(false);
        setLoading(true);

        try {
            await startLoginWithEmail();
        } catch (error) {
            setError(error.message);
            setLoading(false);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const loginWithPhone = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setError(null);
        setErrorCode(null);
        setLoading(true);

        try {
            await startLoginWithPhone();
        } catch (error) {
            setError(error.message);
            setLoading(false);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const resend = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setError(null);
        setErrorCode(null);
        setLoading(true);
        setErrorCodeMsg(false);
        setCustomChallengePreviousResponseEmail(null);
        setCustomChallengePreviousResponsePhone(null);
        setVerificationCode('');
        setRemainingRetryNumber(3);
        setIsDisabled(true);

        try {
            if (codeSentToPhone) {
                await startLoginWithPhone();
            } else {
                await startLoginWithEmail();
            }
            setIsDisabled(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const verifyCode = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setError(null);
        setErrorCode(null);
        setErrorCodeMsg(false);
        setLoading(true);

        try {
            if (codeSentToPhone) {
                const answer = await Auth.sendCustomChallengeAnswer(
                    customChallengePreviousResponsePhone,
                    verificationCode
                );
                if (answer.challengeName === CUSTOM_CHALLENGE) {
                    setRemainingRetryNumber(remainingRetryNumber - 1);
                    setCustomChallengePreviousResponsePhone(answer);
                    setLoading(false);
                    setNeedToVerifyCode(true);
                    setError(invalidOtpMessage);
                    setErrorCodeMsg(true);
                } else {
                    UserService.getUserByCognitoUserName(answer.attributes.sub).then((response) => {
                        localStorage.setItem('user', JSON.stringify(response.user));
                        setGlobalState({ ...globalState, user: response.user, merchant: null });
                        setLoading(false);
                        if (props.linkQrData) setRoute(`/home${props.linkQrData}`);
                        else setRoute('/home');
                    });
                }
            } else {
                const answer = await Auth.sendCustomChallengeAnswer(
                    customChallengePreviousResponseEmail,
                    verificationCode
                );

                if (answer.challengeName === CUSTOM_CHALLENGE) {
                    setRemainingRetryNumber(remainingRetryNumber - 1);
                    setCustomChallengePreviousResponseEmail(answer);
                    setLoading(false);
                    setError(invalidOtpMessage);
                    setErrorCodeMsg(true);
                    setNeedToVerifyCode(true);
                } else {
                    UserService.getUserByCognitoUserName(answer.attributes.sub).then((response) => {
                        localStorage.setItem('user', JSON.stringify(response.user));
                        setGlobalState({ ...globalState, user: response.user, merchant: null });
                        setLoading(false);
                        if (props.linkQrData) setRoute(`/home${props.linkQrData}`);
                        else setRoute('/home');
                    });
                }
            }
        } catch (error) {
            setRemainingRetryNumber(0);
            setError(invalidOtpMessage);
            setErrorCodeMsg(true);
            setLoading(false);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const resetPasswordLessFields = () => {
        setCustomChallengePreviousResponseEmail(null);
        setCustomChallengePreviousResponsePhone(null);
        setVerificationCode(null);
        setPasswordLessPhoneRelatedEmail(null);
        setNeedToVerifyCode(false);
        setCodeSentToPhone(false);
        setErrorCodeMsg(false);
        setShowSmsSent(false);
        setRemainingRetryNumber(3);
    };

    async function startLoginWithEmail() {
        setIsDisabled(true);
        const response = await AuthService.createNewAuthOtp({ parameter: passwordLessEmail, viaPhone: false });
        if (response.isSuccesfully) {
            if (response.status === 202) {
                setError(response.data);
                setOrangeMessageStyle(true);
            } else if (
                response.data.statusCode === errorCodes.INCORRECT_CREDENTIAL.statusCode ||
                response.data.statusCode === errorCodes.USER_NOT_FOUND.statusCode
            ) {
                setNeedToVerifyCode(true);
                setCodeSentToPhone(true);
            } else {
                const rspAuth = await Auth.signIn({ username: passwordLessEmail });
                if (rspAuth.challengeName === CUSTOM_CHALLENGE) {
                    setCustomChallengePreviousResponseEmail(rspAuth);
                    setNeedToVerifyCode(true);
                    setCodeSentToPhone(false);
                }
            }
            setLoading(false);
            setIsDisabled(false);
        } else {
            let errorMessage = response.error;
            if (errorMessage === 'Maximum limit reached. Please try after 30 minute') {
                setError(errorMessage);
            } else if (errorMessage.errorCode === errorCodes.PASSWORD_IS_EXPIRED.statusCode) {
                setError(errorMessage.message);
            } else {
                setNeedToVerifyCode(true);
                setCodeSentToPhone(false);
            }

            setLoading(false);
            setIsDisabled(false);
        }
    }

    async function startLoginWithPhone() {
        setIsDisabled(true);
        const response = await AuthService.createNewAuthOtp({ parameter: `+${passwordLessPhone}`, viaPhone: true });
        if (response.isSuccesfully) {
            if (response.status === 202) {
                setError(response.data);
                setOrangeMessageStyle(true);
            } else if (
                response.data.statusCode === errorCodes.USER_NOT_FOUND.statusCode ||
                response.data.statusCode === errorCodes.INCORRECT_CREDENTIAL.statusCode
            ) {
                setNeedToVerifyCode(true);
                setCodeSentToPhone(true);
            } else {
                setPasswordLessPhoneRelatedEmail(response.data.email);
                const rspAuth = await Auth.signIn({ username: response.data.email });
                if (rspAuth.challengeName === CUSTOM_CHALLENGE) {
                    setCustomChallengePreviousResponsePhone(rspAuth);
                    setNeedToVerifyCode(true);
                    setShowSmsSent(true);
                    setCodeSentToPhone(true);
                }
            }
            setLoading(false);
            setIsDisabled(false);
        } else {
            let errorMessage = response.error;
            if (errorMessage === 'Maximum limit reached. Please try after 30 minute') {
                setError(errorMessage);
            } else if (
                errorMessage.errorCode === errorCodes.PASSWORD_IS_EXPIRED.statusCode ||
                errorMessage.errorCode === errorCodes.SUSPENDED_ACCOUNT.statusCode
            ) {
                setError(errorMessage.message);
            } else {
                setNeedToVerifyCode(true);
                setShowSmsSent(true);
                setCodeSentToPhone(true);
            }
            setLoading(false);
            setIsDisabled(false);
        }
    }

    useEffect(() => {
        if (passwordLessPhone?.length > 4) {
            const passwordLessPhoneMaskedLength = passwordLessPhone.length - 4;
            const cloakedPhone = `${Array(passwordLessPhoneMaskedLength).join('*')}${passwordLessPhone.slice(
                passwordLessPhoneMaskedLength
            )}`;
            setCloakedPhoneNumber(cloakedPhone);
        } else {
            setCloakedPhoneNumber('*********');
        }
    }, [passwordLessPhone]);

    useEffect(() => {
        function focusOnPasswordLessPhoneInput() {
            let phoneInputElement = document.getElementsByClassName('passwordless-auth-phone-input')[0];
            phoneInputElement && phoneInputElement.focus();
        }
        if (isPasswordlessLogin && !needToVerifyCode) {
            focusOnPasswordLessPhoneInput();
        }
    }, [isPasswordlessLogin, needToVerifyCode]);

    return (
        <LoginFormComponent
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            onSubmit={handleSubmit}
            setCountryCodeByNumber={setCountryCodeByNumber}
            countryCodeByNumber={countryCodeByNumber}
            error={error}
            errorCode={errorCode}
            errorCodeMsg={errorCodeMsg}
            setErrorCodeMsg={setErrorCodeMsg}
            setError={setError}
            loading={loading}
            isСonfirmationEmailShows={isСonfirmationEmailShows}
            isEmailConfirmedSuccessfully={isEmailConfirmedSuccessfully}
            setСonfirmationEmailShows={setСonfirmationEmailShows}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            isPasswordlessLogin={isPasswordlessLogin}
            setIsPasswordlessLogin={setIsPasswordlessLogin}
            passwordLessEmail={passwordLessEmail}
            setPasswordLessEmail={setPasswordLessEmail}
            passwordLessPhone={passwordLessPhone}
            setPasswordLessPhone={setPasswordLessPhone}
            loginWithEmail={loginWithEmail}
            verifyCode={verifyCode}
            loginWithPhone={loginWithPhone}
            needToVerifyCode={needToVerifyCode}
            setNeedToVerifyCode={setNeedToVerifyCode}
            codeSentToPhone={codeSentToPhone}
            resend={resend}
            remainingRetryNumber={remainingRetryNumber}
            resetPasswordLessFields={resetPasswordLessFields}
            showSmsSent={showSmsSent}
            setShowSmsSent={setShowSmsSent}
            cloakedPhoneNumber={cloakedPhoneNumber}
            isUpdatePasswordMessageShows={isUpdatePasswordMessageShows}
            setIsUpdatePasswordMessageShows={setIsUpdatePasswordMessageShows}
            querySubmitted={querySubmitted}
            setQuerySubmitted={setQuerySubmitted}
            generalPageError={generalPageError}
            setGeneralPageError={setGeneralPageError}
            passwordLessPhoneRelatedEmail={passwordLessPhoneRelatedEmail}
            setOrangeMessageStyle={setOrangeMessageStyle}
            orangeMessageStyle={orangeMessageStyle}
            isDisabled={isDisabled}
        />
    );

    function saveUserAndSendEmail(data, response, thirdPartyResellerUrlParameterCache) {
        AuthService.newSocialAccount({
            providerId: data.signInUserSession.idToken.payload['cognito:username'],
            email: response.attributes.email,
            resellerUrl: globalState.reseller?.portalURL,
            refreshToken: data.signInUserSession.refreshToken.token,
            userData: {
                email: response.attributes.email,
                given_name: data.signInUserSession.idToken.payload['given_name'],
                family_name: data.signInUserSession.idToken.payload['family_name'],
                picture: data.signInUserSession.idToken.payload['picture']
            }
        }).then((responseSocial) => {
            if (responseSocial.isSuccesfully) {
                ///Request new token which will contains userId and other details
                AuthService.requestNewTokenWithRefreshToken(data.signInUserSession.refreshToken.token).then(
                    (newTokenReponse) => {
                        if (newTokenReponse.isSuccesfully) {
                            const route = thirdPartyResellerUrlParameterCache
                                ? `/home${thirdPartyResellerUrlParameterCache}`
                                : '/home';
                            Cache.removeItem('thirdPartyResellerUrlParameter');
                            Auth.currentAuthenticatedUser({ bypassCache: true }).then((krs) => {
                                //Force refresh token
                                //Get user
                                UserService.getUserByEmail(response.attributes.email).then((responseUser) => {
                                    if (responseUser.isSuccessfully && responseUser.user) {
                                        localStorage.setItem('user', JSON.stringify(responseUser.user));
                                        setGlobalState({ ...globalState, user: responseUser.user });
                                        setLoading(false);
                                        setRoute(route);
                                    } else {
                                        setLoading(false);
                                        setError(response.message);
                                    }
                                });
                            });
                        } else {
                            setLoading(false);
                            setError('Internal exception');
                        }
                    }
                );
            } else {
                setLoading(false);
                setError('Internal exception');
            }
        });
    }
}

export default LoginForm;
