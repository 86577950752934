import React from 'react';
import { Modal, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import styles from './create-user-form.module.scss';
import PropTypes from 'prop-types';

function CreateUserFormComponent(props) {
    const {
        show,
        setShow,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        onSubmit,
        successfully,
        error,
        errorMessage,
        loading
    } = props;

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>Create user</Modal.Header>
            <Modal.Body>
                <Form className={styles.createUserForm} onSubmit={onSubmit}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}
                                    minLength="1"
                                    required
                                    placeholder="First name"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}
                                    minLength="1"
                                    required
                                    placeholder="Last name"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required
                            type="email"
                            placeholder="Email"
                        />
                    </Form.Group>
                    <Button id="search-btn" variant="primary" type="submit">
                        Submit
                    </Button>
                    {loading && (
                        <div className={styles.loadingSubmit}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                    {error && (
                        <div className={styles.alertWindow}>
                            <Alert variant="danger">{errorMessage}</Alert>
                        </div>
                    )}
                    {successfully && (
                        <div className={styles.alertWindow}>
                            <Alert variant="success">User has been created successfully</Alert>
                        </div>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

CreateUserFormComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    firstName: PropTypes.string,
    setFirstName: PropTypes.func,
    lastName: PropTypes.string,
    setLastName: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    onSubmit: PropTypes.func,
    successfully: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    loading: PropTypes.bool
};

export default CreateUserFormComponent;
