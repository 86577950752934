import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import MerchantChargesListComponent from './merchant-charges-list.component.jsx';

import { MerchantChargesService, BusinessDetailsService } from 'services';
import { useGlobalStateContext } from 'contexts';

const MerchantChargesList = (props) => {
    const { loading, setLoading, setOpenMerchantModal, openMerchantModal } = props;
    const [error, setError] = useState(false);
    const [merchantCharges, setMerchantCharges] = useState([]);
    const { globalState } = useGlobalStateContext();
    const [page, setPage] = useState(0);
    const [merchantChargeResponse, setMerchantChargeResponse] = useState(false);
    const [merchantChargeResponseFlag, setMerchantChargeResponseFlag] = useState(false);

    const { date, via } = props.match.params;
    const [merchantTradingAddress, setMerchantTradingAddress] = useState({});
    const [companyAddress, setCompanyAddress] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [count, setCount] = useState(0);

    const getMerchantChargesList = async (newPage, newRowsPerPage) => {
        setLoading(true);
        const merchantId = globalState.merchant?.id;
        const month = moment(date, 'YYYY-MM-DD').month() + 1;
        const year = moment(date, 'YYYY-MM-DD').year();
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;

        const merchantChargesList = await MerchantChargesService.getMerchantChargesList({
            merchantId,
            month,
            year,
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,
            via: via.toUpperCase()
        });
        const merchantResponse = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);
        if (merchantResponse.isSuccesfully) {
            setMerchantTradingAddress(merchantResponse.data.businessDetails);
        }

        if (merchantChargesList.isSuccesfully) {
            setMerchantCharges(merchantChargesList.data.data.data);
            setCount(merchantChargesList.data.data.count);
            setCompanyAddress(globalState.reseller.address);
            setError(false);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        setPage(0);
        setMerchantChargeResponseFlag(false);
        getMerchantChargesList(0);
        // eslint-disable-next-line
    }, [props.location.pathname, date, via, globalState.merchant, merchantChargeResponseFlag]);

    return (
        <MerchantChargesListComponent
            {...props}
            loading={loading}
            setLoading={setLoading}
            error={error}
            merchantCharges={merchantCharges}
            page={page}
            setPage={setPage}
            merchantChargeResponse={merchantChargeResponse}
            setMerchantChargeResponse={setMerchantChargeResponse}
            merchantChargeResponseFlag={merchantChargeResponseFlag}
            setMerchantChargeResponseFlag={setMerchantChargeResponseFlag}
            getMerchantChargesList={getMerchantChargesList}
            companyAddress={companyAddress}
            merchantTradingAddress={merchantTradingAddress}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            count={count}
            openMerchantModal={openMerchantModal}
            setOpenMerchantModal={setOpenMerchantModal}
        />
    );
};

export default MerchantChargesList;

MerchantChargesList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string.isRequired,
            via: PropTypes.string.isRequired
        })
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    })
};
