import React, { useState } from 'react';
import UpdatePasswordComponent from './update-password.component';
import { Auth } from 'aws-amplify';
import { AuthService } from 'services';
import { useGlobalStateContext } from 'contexts';

function UpdatePassword() {
    const { setRoute, globalState } = useGlobalStateContext();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [newPasswordChange, setNewPasswordChange] = useState(false);
    const [redoPasswordValidation, setRedoPasswordValidation] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const changePassword = async () => {
        // let isUserDisabled = false
        Auth.currentAuthenticatedUser().then((currentUser) => {
            Auth.changePassword(currentUser, currentPassword, newPassword)
                .then(async () => {
                    // await AuthService.updateDatmanPassword(currentPassword, newPassword);
                    await AuthService.updateChangePasswordTime({ email: globalState?.user?.email });
                    AuthService.logout();
                    Auth.signOut({ global: true }).then(() => {
                        setRoute('/login#update-password=true');
                    });
                })
                .catch(async (error) => {
                    if (error.message === 'User is disabled.') {
                        try {
                            AuthService.logout();
                            await Auth.signOut({ global: true });
                        } catch (logoutError) {
                            console.log('logoutError', logoutError); // Handle any errors that occur during logout
                        }
                        setRoute('/login');
                    }
                    if (error.message === 'Incorrect username or password.') {
                        setErrorMessage('Please Enter Valid Current Password');
                    }
                    if (error.message === 'Attempt limit exceeded, please try after some time.') {
                        setErrorMessage('Attempt limit exceeded, please try after some time');
                    }
                });
        });
    };

    return (
        <UpdatePasswordComponent
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            isPasswordValid={isPasswordValid}
            setIsPasswordValid={setIsPasswordValid}
            changePassword={changePassword}
            newPasswordChange={newPasswordChange}
            setNewPasswordChange={setNewPasswordChange}
            redoPasswordValidation={redoPasswordValidation}
            setRedoPasswordValidation={setRedoPasswordValidation}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            userDetails={globalState.user}
        />
    );
}

export default UpdatePassword;
