import React from 'react';
import PropTypes from 'prop-types';
import RejectedReasonComponent from './rejected-reason.component';

export default function RejectedReason(props) {
    const { onChange, setShowNotesBlock } = props;

    return <RejectedReasonComponent onChange={onChange} setShowNotesBlock={setShowNotesBlock} />;
}

RejectedReason.propTypes = {
    onChange: PropTypes.func
};
