import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import React, { useState } from 'react';
import { AdminService } from 'services';
import BankVerificationComponent from './bank-verification.component';

export default function BankVerification() {
    const { globalState } = useGlobalStateContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const [isLoading, setIsLoading] = useState(false);

    const [updatedMerchantData, setUpdatedMerchantData] = useState({});

    const onChange = (value) => {
        const data = {
            bankVerify: value,
            resellerId: globalState.reseller?.id
        };
        setUpdatedMerchantData(data);
        if (value === '0') {
            setIsModalOpen(true);
        } else {
            setAdminFunctions({
                ...adminFunctions,
                bankVerify: value === '1' ? true : false
            });
            AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, data);
        }
    };

    return (
        <BankVerificationComponent
            onChange={onChange}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            updatedMerchantData={updatedMerchantData}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
        />
    );
}
