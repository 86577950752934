import React from 'react';

import CardDetailsForm from '../card-details-form/card-details-form.container';
import CardImage from '../card-image/card-image.container';
import './card-details.styles.scss';
import ModalTakePayment from '../modal-take-payment/modal-take-payment.container';
import { Spinner } from 'react-bootstrap';

const CardDetailsComponent = (props) => {
    const {
        state,
        dispatch,
        formFieldsRefObj,
        focusFormFieldByKey,
        cardElementsRef,
        onCardFormInputFocus,
        onCardInputBlur,
        propState,
        handleIframeTask,
        initiateSale,
        description,
        loading
    } = props;

    const { amount, merchantAcquirer } = propState;

    return (
        <>
            {merchantAcquirer.acquirer ? (
                <>
                    {/* <iframe src={``} id="dnaForm" width="100%" height="600"></iframe>
                        have to show Iframe */}
                    <div style={{ marginTop: '30px' }} className="messageView">
                        {!loading ? (
                            <div className="contentView">
                                {description ? (
                                    <div>
                                        <p style={{ textAlign: 'center' }}>
                                            Current payment with description {description} is being processed...
                                        </p>
                                    </div>
                                ) : (
                                    <div>
                                        <p style={{ textAlign: 'center' }}>Current payment is being processed...</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="contentView">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className="card" style={{ marginTop: '30px' }}>
                        <div>
                            <div className="wrapper">
                                <CardDetailsForm
                                    cardMonth={state.cardMonth}
                                    cardYear={state.cardYear}
                                    dispatch={dispatch}
                                    cardNumberRef={formFieldsRefObj.cardNumber}
                                    cardHolderRef={formFieldsRefObj.cardHolder}
                                    cardMonthRef={formFieldsRefObj.cardMonth}
                                    cardCvvRef={formFieldsRefObj.cardCvv}
                                    cardYearRef={formFieldsRefObj.cardYear}
                                    onCardInputFocus={onCardFormInputFocus}
                                    onCardInputBlur={onCardInputBlur}
                                    initiateSale={initiateSale}
                                    cvv={state.cardCvv}
                                    propState={propState}
                                    cardNumber={state.cardNumber}
                                    cardHolder={state.cardHolder}
                                    isFormDataValid={state.isFormDataValid}
                                    cardHolderAddress={state.cardHolderAddress}
                                >
                                    <CardImage
                                        cardNumber={state.cardNumber}
                                        cardHolder={state.cardHolder}
                                        cardMonth={state.cardMonth}
                                        cardYear={state.cardYear}
                                        cardCvv={state.cardCvv}
                                        isCardFlipped={state.isCardFlipped}
                                        currentFocusedElm={state.currentFocusedElm}
                                        onCardElementClick={focusFormFieldByKey}
                                        cardNumberRef={cardElementsRef.cardNumber}
                                        cardHolderRef={cardElementsRef.cardHolder}
                                        cardDateRef={cardElementsRef.cardDate}
                                    />
                                </CardDetailsForm>
                                {state.showModal ? (
                                    <ModalTakePayment
                                        {...props}
                                        onUpdateState={dispatch}
                                        showLoadingSpinner={state.showLoadingSpinner}
                                        paymentSuccess={state.paymentSuccess}
                                        state={state}
                                        handleIframeTask={handleIframeTask}
                                        amount={amount}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CardDetailsComponent;
