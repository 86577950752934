import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalMerchantComponent from './modal-merchant-creation.component';
import { validateMerchantName, validateMerchantNameUniqueness } from 'validators';
import { ValidationMessage } from 'models';
import { MerchantService } from 'services';

function ModalMerchantCreation(props) {
    const initialValidationMessage = new ValidationMessage();

    const [merchantNameState, setMerchantNameState] = useState('');
    const [validationMessage, setValidationMessage] = useState(initialValidationMessage);
    const [isLoading, setIsLoading] = useState(false);

    const setMerchantName = (event) => {
        const validationResult = validateMerchantName(event.target.value);
        setValidationMessage(validationResult);
        setMerchantNameState(event.target.value);
    };

    const validateMerchantNameIsUnique = async (event) => {
        const validationResult = await validateMerchantNameUniqueness(
            props.businessId,
            props.clientId,
            event.target.value
        );
        setValidationMessage(validationResult);
    };

    const createMerchant = async () => {
        setIsLoading(true);
        MerchantService.createMerchant(props.businessId, props.clientId, { name: merchantNameState.trim() }).then(
            (createMerchantResponse) => {
                if (createMerchantResponse.isSuccesfully) {
                    props.hideModal();
                    setIsLoading(false);
                    props.setUpdateWorkItems((state) => !state);
                } else {
                    if (createMerchantResponse.data.error) {
                        const validationMessage = new ValidationMessage(createMerchantResponse.data.error, false);
                        setValidationMessage(validationMessage);
                        setIsLoading(false);
                    }
                }
            }
        );
    };

    return (
        <ModalMerchantComponent
            showModal={props.showModal}
            hideModal={props.hideModal}
            merchantName={merchantNameState}
            validationMessage={validationMessage}
            setMerchantName={setMerchantName}
            createMerchant={createMerchant}
            isLoading={isLoading}
            validateMerchantNameIsUnique={validateMerchantNameIsUnique}
        ></ModalMerchantComponent>
    );
}

ModalMerchantCreation.propTypes = {
    showModal: PropTypes.bool,
    hideModal: PropTypes.func,
    onActionDone: PropTypes.func,
    businessId: PropTypes.any,
    clientId: PropTypes.any,
    setUpdateWorkItems: PropTypes.func
};

export default ModalMerchantCreation;
