import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalClientComponent from './modal-client-creation.component';
import { validateClientName, validateClientNameUniqueness } from 'validators';
import { ValidationMessage } from 'models';
import { ClientService } from 'services';

function ModalClientCreation(props) {
    const initialValidationMessage = new ValidationMessage();

    const [clientNameState, setClientNameState] = useState('');
    const [validationMessage, setValidationMessage] = useState(initialValidationMessage);
    const [isLoading, setIsLoading] = useState(false);

    const setClientName = (event) => {
        const validationResult = validateClientName(event.target.value);
        setValidationMessage(validationResult);
        setClientNameState(event.target.value);
    };

    const validateClientNameIsUnique = async (event) => {
        const validationResult = await validateClientNameUniqueness(props.businessId, event.target.value);

        setValidationMessage(validationResult);
    };

    const createClient = async () => {
        setIsLoading(true);
        ClientService.createClient(props.businessId, { name: clientNameState.trim() }).then((createClientResponse) => {
            if (createClientResponse.isSuccesfully) {
                props.hideModal();
                setIsLoading(false);
                props.setUpdateWorkItems((state) => !state);
            } else {
                if (createClientResponse.data.error) {
                    const validationMessage = new ValidationMessage(createClientResponse.data.error, false);
                    setValidationMessage(validationMessage);
                    setIsLoading(false);
                }
            }
        });
    };

    return (
        <ModalClientComponent
            showModal={props.showModal}
            hideModal={props.hideModal}
            clientName={clientNameState}
            validationMessage={validationMessage}
            setClientName={setClientName}
            createClient={createClient}
            isLoading={isLoading}
            validateClientNameIsUnique={validateClientNameIsUnique}
        ></ModalClientComponent>
    );
}

ModalClientCreation.propTypes = {
    showModal: PropTypes.bool,
    hideModal: PropTypes.func,
    onActionDone: PropTypes.func,
    businessId: PropTypes.any,
    setUpdateWorkItems: PropTypes.func
};

export default ModalClientCreation;
