import React from 'react';
import { List, ListItem, Collapse } from '@material-ui/core';
import { StoreMallDirectoryTwoTone, ExpandLess, ExpandMore, Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import style from './merchant-list-container.module.scss';
import AddIcon from '@material-ui/icons/Add';
import ModalCreateMerchantAccount from 'components/modal-create-merchant-account/modal-create-merchant-account.container';
import { ModalMerchantTermsAndConditions } from 'components';
import { useGlobalStateContext, useBalanceContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
function MerchantListComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { refreshBalance } = useBalanceContext();

    const {
        openUpdateMerchantDataModal,
        setUpdateMerchantData,
        updateMerchantData,
        needToSignTermsAndCondition,
        setNeedToSignTermsAndCondition
    } = props;

    const ListItems = () => {
        return (
            <React.Fragment>
                {props.merchants != null ? (
                    props.merchants.map((merchant) => {
                        if (merchant.id === props.selectedMerchant?.id) return null;
                        return (
                            <ListItem
                                key={merchant.id}
                                className={style.listItem}
                                button
                                onClick={() => {
                                    props.handleClick();
                                    props.selectMerchant(merchant);
                                    refreshBalance();
                                }}
                            >
                                {props.isAdminUserType ? (
                                    <div> {merchant.nameWithAddress}</div>
                                ) : (
                                    <div>{merchant.name}</div>
                                )}
                            </ListItem>
                        );
                    })
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <List ref={props.wrapperRef} className={style.listContainer}>
                <ListItem button>
                    {!props.open && props.loading ? (
                        <div className={style.loading}>Loading...</div>
                    ) : (
                        <div className={style.listContentContainer}>
                            <div className={style.listContentContainer} button onClick={props.handleClick}>
                                <span className={style.merchantName}>
                                    {props.isAdminUserType
                                        ? props.selectedMerchant?.nameWithAddress
                                        : props.selectedMerchant?.name}
                                </span>

                                {!props.isAdminUserType && props.merchants.length >= 0 && (
                                    <span className={style.expandArrow}>
                                        {props.open ? <ExpandLess /> : <ExpandMore />}
                                    </span>
                                )}
                            </div>

                            <div>
                                <span>
                                    {props.isAdminUserType && (
                                        <Edit className={style.editIcon} onClick={openUpdateMerchantDataModal} />
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                </ListItem>

                {props.merchants.length > 1 && !props.isAdminUserType && (
                    <Collapse in={props.open} timeout="auto" unmountOnExit>
                        <List disabled={true} component="div" className={style.overflowCollapse}>
                            {!props.isAdminUserType && globalState.reseller?.name === ResellerType.OMNIPAY && (
                                <ListItem
                                    disabled={true}
                                    button
                                    className={style.listItem}
                                    onClick={() => {
                                        props.setIsOpenCreateModal(true);
                                    }}
                                >
                                    <div className={style.createNewMerchant}>
                                        <AddIcon /> Create New Merchant
                                    </div>
                                </ListItem>
                            )}
                            {props.open && props.loading && props.merchants?.length > 1 ? (
                                <ListItem button>
                                    <StoreMallDirectoryTwoTone className={style.listIcon}></StoreMallDirectoryTwoTone>
                                    <div className={style.loading}>Loading...</div>
                                </ListItem>
                            ) : (
                                <ListItems />
                            )}
                        </List>
                    </Collapse>
                )}
            </List>
            {props.isOpenCreateModal && (
                <ModalCreateMerchantAccount
                    selectMerchant={props.selectMerchant}
                    setOpenMerchantList={props.setOpen}
                    setOpen={props.setIsOpenCreateModal}
                    loadMerchants={props.loadMerchants}
                    setSuccess={props.setSuccess}
                    thirdPartyCustomerKey={props.thirdPartyCustomerKey}
                    setUpdateMerchantData={setUpdateMerchantData}
                    updateMerchantData={updateMerchantData}
                />
            )}
            <ModalMerchantTermsAndConditions
                open={needToSignTermsAndCondition}
                setOpen={setNeedToSignTermsAndCondition}
            />
        </React.Fragment>
    );
}

MerchantListComponent.propTypes = {
    merchants: PropTypes.array,
    setSelectedMerchant: PropTypes.func,
    selectMerchant: PropTypes.func,
    selectedMerchant: PropTypes.object,
    isOpenCreateModal: PropTypes.bool,
    setIsOpenCreateModal: PropTypes.func,
    loadMerchants: PropTypes.func,
    isAdminUserType: PropTypes.bool,
    onSearchMerchants: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleClick: PropTypes.func,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    setSuccess: PropTypes.func,
    countMerchants: PropTypes.number,
    thirdPartyCustomerKey: PropTypes.string,
    wrapperRef: PropTypes.any
};

export default MerchantListComponent;
