import React from 'react';
import { Label, FormItem, SelectedDropDown } from 'components/onboarding/onboarding-ui-components';

const CountyInputComponent = (props) => {
    const { value, onChange, disabled, id, labelText, labelId, countyList } = props;

    return (
        <FormItem>
            <Label text={labelText} id={labelId}></Label>
            {countyList.length && !props.loading && (
                <SelectedDropDown
                    id={id}
                    placeholder="Select county"
                    hideNoOptionsTextSelect={true}
                    value={value}
                    getOptionSelected={(option, value) => option === value}
                    options={countyList}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => onChange(newValue)}
                    customListRender={(option) => {
                        return (
                            <React.Fragment>
                                <span>{option}</span>
                            </React.Fragment>
                        );
                    }}
                    disabled={disabled}
                />
            )}
        </FormItem>
    );
};

export default CountyInputComponent;
