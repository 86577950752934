import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import React from 'react';
import { AdminService } from 'services';
import AccountVerificationComponent from './account-verification.component';

export default function AccountVerification() {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();

    const onChange = (value) => {
        AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            accountVerify: value,
            resellerId: globalState.reseller?.id
        });

        setAdminFunctions({
            ...adminFunctions,
            accountVerify: value === '1' ? true : false
        });
    };

    return <AccountVerificationComponent onChange={onChange} />;
}
