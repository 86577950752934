import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { UrgentMessagesService } from 'services';
import CreateUrgentMessageComponent from './create-urgent-message.component';
import moment from 'moment';
function CreateUrgentMessage(props) {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const { setShow, setSuccess } = props;

    const initialFormItems = {
        startDate: null,
        endDate: null,
        subject: '',
        message: '',
        merchantIds: [],
        btnText: '',
        url: ''
    };
    const [formItems, setFormItems] = useState(initialFormItems);
    const [isDisable, setIsDisable] = useState(true);
    const [error, setError] = useState('');
    const [characterCount, setCharacterCount] = useState(0);
    const [showMid, setShowMid] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const formatedDate = moment().format('yyyy/MM/DD HH:mm:ss');
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [dateError, setDateError] = useState('');
    const [showDomainValidationMessage, setShowDomainValidationMessage] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    let timeDifference = moment(selectedEndDate).diff(moment(selectedStartDate), 'seconds');
    let currentDate = new Date().toISOString().slice(0, 16);

    useEffect(() => {
        const btnTextValidation = formItems?.url?.length > 0 ? formItems.btnText?.trim().length === 0 : false;
        if (showMid === true) {
            setIsDisable(
                formItems?.message?.trim().length === 0 ||
                    formItems?.merchantIds?.length === 0 ||
                    dateError.length !== 0 ||
                    !selectedStartDate ||
                    !selectedEndDate ||
                    showDomainValidationMessage ||
                    isFocused ||
                    btnTextValidation
            );
        } else {
            setIsDisable(
                formItems?.message?.length === 0 ||
                    dateError.length !== 0 ||
                    !selectedStartDate ||
                    !selectedEndDate ||
                    showDomainValidationMessage ||
                    isFocused ||
                    btnTextValidation
            );
        }

        if (selectedStartDate && selectedStartDate) {
            if (moment(selectedStartDate).isBefore(moment(new Date()))) {
                setDateError('Start date should not be less than current date');
            } else if (moment(selectedStartDate).isSame(moment(selectedEndDate))) {
                setDateError('Start date and end date should not be same.');
            } else if (selectedEndDate === undefined ? '' : timeDifference <= 5 * 60) {
                setDateError('End date should be minimum 5 minutes greater than start date');
            } else {
                setDateError('');
            }
        }

        // eslint-disable-next-line
    }, [
        formItems,
        showMid,
        dateError,
        selectedStartDate,
        selectedEndDate,
        timeDifference,
        currentDate,
        showDomainValidationMessage,
        isFocused
    ]);

    const onSubmit = async () => {
        setIsDisable(true);
        const response = await UrgentMessagesService.addUrgentMessage(globalState.reseller?.id, {
            ...formItems,
            subject: formItems.subject,
            message: formItems.message,
            startDate: !formItems.startDate
                ? moment(formatedDate).utc().format('YYYY-MM-DD HH:mm:ss') // in local db date is getting stored as utc but not in beta db
                : moment(formItems.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: !formItems.endDate
                ? moment(formatedDate).utc().format('YYYY-MM-DD HH:mm:ss')
                : moment(formItems.endDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            merchantIds: formItems?.merchantIds?.length > 0 ? formItems.merchantIds.split(',').map(Number) : [],
            url: formItems.url ? `https://${formItems?.url}` : '',
            btnText: formItems?.btnText
        });
        if (response.isSuccesfully) {
            setFormItems(initialFormItems);
            setSuccess(true);
            setShow(false);
            setGlobalState({
                ...globalState,
                shouldCheckForUrgentMessages: !globalState.shouldCheckForUrgentMessages
            });
            setIsDisable(true);
        } else {
            setError('An error was occurred when checking the email. Please contact support or try again.');
            setIsDisable(false);
        }
    };

    const onChange = (e) => {
        //Below regex will check input should contain numbers or coma(,) or space only
        const numberMatch = /^[0-9,\s\b]+$/;

        if (e.target.value === '' || numberMatch.test(e.target.value)) {
            setFormItems({
                ...formItems,
                merchantIds: e.target.value
            });
        }
        setShowValidationMessage(false);
    };

    return (
        <CreateUrgentMessageComponent
            {...props}
            onSubmit={onSubmit}
            formItems={formItems}
            setFormItems={setFormItems}
            isDisable={isDisable}
            initialFormItems={initialFormItems}
            error={error}
            setError={setError}
            setCharacterCount={setCharacterCount}
            characterCount={characterCount}
            setShowMid={setShowMid}
            showMid={showMid}
            onChange={onChange}
            setSelectedStartDate={setSelectedStartDate}
            selectedStartDate={selectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
            selectedEndDate={selectedEndDate}
            showValidationMessage={showValidationMessage}
            dateError={dateError}
            showDomainValidationMessage={showDomainValidationMessage}
            setShowDomainValidationMessage={setShowDomainValidationMessage}
            setIsFocused={setIsFocused}
        />
    );
}

export default CreateUrgentMessage;
