export const KeyToName = {
    fullName: { label: 'Name', type: 'text', required: true },
    merchantId: { label: 'Merchant ID', type: 'text', disable: true, required: true },
    birthDate: { label: 'Date Of Birth', type: 'text', required: true },
    homeAddress: { label: 'Address', type: 'text', required: true },
    ownership: { label: 'Percentage of Company Owners', type: 'float', required: true },
    contactPhone: { label: 'Mobile Phone', type: 'tel', required: true },
    email: { label: 'Email', type: 'email', required: true },
    addressLine1: { label: 'Address Line 1', type: 'text', length: 150, required: true },
    addressLine2: { label: 'Address Line 2', type: 'text', length: 150 },
    city: { label: 'City', type: 'text', length: 150, required: true },
    postCode: { label: 'Post Code', type: 'text', length: 15, required: true },
    businessPrinciple: {
        label: 'How many principles does the business have',
        type: 'number',
        length: 2,
        required: false
    },
    businessDescription: { label: 'Business Description', type: 'text', disable: true, required: true },
    mcc: { label: 'MCC Code', type: 'text', disable: true, required: true },
    b2b: { label: 'Business to Business (B2B)', type: 'number', length: 2, required: false },
    b2c: { label: 'Business to Consumer', type: 'number', length: 2, required: false },
    outlets: {
        label: 'Outlets - How many Locations does the Business trade from',
        type: 'number',
        length: 2,
        required: false
    },
    cardTurnover: { label: 'Expected Annual Card Turnover (£)', type: 'float', required: true },
    additionalOutlets: { label: 'Additional Outlets', type: 'number', length: 2, required: false },
    merchantCreatedAt: { label: 'Added Time', type: 'text', disable: true, required: true },
    thirdPartyCustomer: { label: 'Store ID', type: 'number', length: 10, required: false },
    registeredNumber: { label: 'Registered Company or Charity Number', type: 'text', length: 12, required: false },

    nameOfBank: { label: 'Bank Name', type: 'text', length: 250, required: false },
    accountHolderName: { label: 'Name of the account holder', type: 'text', length: 250, required: false },
    newAccountNumber: { label: 'Account Number', type: 'number', length: 8, required: false },
    sortCode: { label: 'Sort Code', type: 'number', length: 6, required: false },
    avgTransaction: { label: 'Average Transaction Value (£)', type: 'float', required: false },
    monthlyTurnOver: { label: 'Projected Monthly Turnover', type: 'float', required: false },
    annualTurnOver: { label: 'Projected Annual Turnover', type: 'float', required: false },
    monthlyCount: { label: 'Projected Monthly Transaction Count', type: 'number', required: false, length: 7 },
    annualCount: { label: 'Projected Annual Transaction Count', type: 'number', required: false, length: 7 }
};
