import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import React, { useState } from 'react';
import { AdminService } from 'services';
import AccountStatusComponent from './account-status.component';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

export default function AccountStatus() {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const [isLoading, setIsLoading] = useState(false);

    const [updatedMerchantData, setUpdatedMerchantData] = useState({});

    const onChange = (value) => {
        var status;
        switch (value) {
            case MerchantAccountStatus.PENDING:
                status = MerchantStatus.MERCHANT_PENDING;
                break;
            case MerchantAccountStatus.PENDING_STRIPE:
                status = MerchantStatus.PENDING_STRIPE;
                break;
            case MerchantAccountStatus.ACTIVE:
                status = MerchantStatus.ACTIVE;
                break;
            case MerchantAccountStatus.CLOSED:
                status = MerchantStatus.CLOSED;
                break;
            case MerchantAccountStatus.REJECTED:
                status = MerchantStatus.REJECTED;
                break;
            case MerchantAccountStatus.WATCHLIST:
                status = MerchantStatus.WATCHLIST;
                break;
            case MerchantAccountStatus.NOT_SUBMITTED:
                status = MerchantStatus.NOT_SUBMITTED;
                break;
            default:
                status = MerchantStatus.MERCHANT_PENDING;
                break;
        }

        let data = {
            status: status,
            resellerId: globalState.reseller?.id
        };

        if (status === MerchantStatus.WATCHLIST) {
            data = {
                ...data,
                accountVerify: '0',
                bankVerify: '0',
                allowWithdrawals: '0',
                autoWithdraw: '0',
                internalTransferStatus: '0'
            };
        }

        setUpdatedMerchantData(data);
        if (status === MerchantStatus.WATCHLIST) {
            setIsModalOpen(true);
        } else {
            setAdminFunctions({
                ...adminFunctions,
                accountStatus: value
            });
            setGlobalState((globalState) => ({
                ...globalState,
                merchant: {
                    ...globalState.merchant,
                    status: status
                }
            }));
            AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, data);
        }
    };

    return (
        <AccountStatusComponent
            onChange={onChange}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            updatedMerchantData={updatedMerchantData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
        />
    );
}
