import React from 'react';
import styles from './t2s-onboarding-name-address.module.scss';
import {
    Grid,
    NativeSelect,
    makeStyles,
    TextField,
    Button,
    CircularProgress,
    withStyles,
    Tooltip
} from '@material-ui/core';
import PropTypes from 'prop-types';

import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem,
    PostCodeSelector
} from 'components/onboarding/onboarding-ui-components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { Typography } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Alert } from 'react-bootstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { AntSwitch, ValidURLInput } from 'components';
import { FormatMessage } from 'utils/languageTranslater';
import { ValidEmailInput } from 'components';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import {
    dropdownTypeOrganisations,
    getOnboardingTitle,
    dropdownTypeForTrust,
    AUSStateCodes,
    USAStateCodes
} from 'utils/onboarding-helper';
import { OnboardingSteps } from 'utils/enums/OnboardingStepsMapping';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fdecea',
        color: '#611a15',
        fontSize: 14,
        height: '50px'
    }
}))(Tooltip);
const useStyles = makeStyles(() => ({
    noOptions: {
        color: 'red !important'
    }
}));

function T2SOnboardingNameAddressComponent(props) {
    const classes = useStyles();

    const { globalState } = useGlobalStateContext();
    const {
        nextStep,
        onboardingForm,
        setOnboardingForm,
        isDisabled,
        onSubmit,
        phoneNumberValidator,
        openDropdown,
        setOpenDropdown,
        value,
        setValue,
        handleSelectedCompany,
        inputValue,
        setInputValue,
        getCompanyDetails,
        isLoading,
        showErrorForRegisteredNumber,
        registeredNumberErrorMessage,
        setRegisteredNumberHandler,
        isSoleTrader,
        setIsSoleTrader,
        newBusinessTypes,
        setIsBankAccountNameSwitchOn,
        setDialCode,
        dialCode,
        setIsTrust,
        isTrust
    } = props;

    const isUKMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const isMexicanmerchant = globalState.merchant?.country === MerchantCountries.MEXICO;
    const registeredBusiness = onboardingForm.businessDetails.isRegisteredBusiness;
    const isSameAccountName = onboardingForm.businessDetails.isAccountNameSame;
    const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
    const isUSMerchant = globalState.merchant?.country === MerchantCountries.UNITED_STATES;
    const merchantCountry = globalState.merchant?.country;

    function FormBody() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <Label
                                text="Legal Entity/Business Type"
                                id="business-type-label"
                                transalterEnabled="true"
                            ></Label>
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder="Legal Entity/Business Type"
                                        id="business-type"
                                        value={onboardingForm.businessDetails.businessTypeId}
                                        onChange={(e) => {
                                            setIsSoleTrader(e.target.value?.toString() === '2');
                                            setIsTrust(e.target.value?.toString() === '12');
                                            if (
                                                e.target.value?.toString() == BusinessTypeEnumId.Sole_Trader ||
                                                e.target.value?.toString() == BusinessTypeEnumId.Trust
                                            ) {
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    businessDetails: {
                                                        ...onboardingForm.businessDetails,
                                                        businessTypeId: e.target.value,
                                                        businessCategory: ''
                                                    }
                                                });
                                            } else {
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    businessDetails: {
                                                        ...onboardingForm.businessDetails,
                                                        businessTypeId: e.target.value
                                                    }
                                                });
                                            }
                                        }}
                                    ></Input>
                                }
                            >
                                {newBusinessTypes.map((type) => {
                                    return (
                                        <option key={type.id} value={type.id}>
                                            {FormatMessage({ id: type.name, text: type.name })}
                                        </option>
                                    );
                                })}
                            </NativeSelect>
                        </FormItem>
                        {isTrust && (
                            <React.Fragment>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                                    <FormItem>
                                        <Label text="Trust type" id="Trust-type" transalterEnabled="true"></Label>
                                        <NativeSelect
                                            inputProps={{
                                                className:
                                                    onboardingForm.businessDetails.businessCategory == '' ||
                                                    onboardingForm.businessDetails.businessCategory ==
                                                        '--Please select--'
                                                        ? styles.unselectedDropdownErrorStyle
                                                        : ''
                                            }}
                                            input={
                                                <Input
                                                    placeholder="Trust type"
                                                    id="Trust-type"
                                                    value={onboardingForm.businessDetails.businessCategory}
                                                    onChange={(e) =>
                                                        setOnboardingForm({
                                                            ...onboardingForm,
                                                            businessDetails: {
                                                                ...onboardingForm.businessDetails,
                                                                businessCategory: e.target.value
                                                            }
                                                        })
                                                    }
                                                ></Input>
                                            }
                                        >
                                            {dropdownTypeForTrust.map((type) => {
                                                return (
                                                    <option key={type.id} value={type.id}>
                                                        {FormatMessage({ id: type.name, text: type.name })}
                                                    </option>
                                                );
                                            })}
                                        </NativeSelect>
                                    </FormItem>
                                </CheckMerchantCountries>
                            </React.Fragment>
                        )}
                        {isSoleTrader && (
                            <React.Fragment>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justify="space-between"
                                    className={styles.formItemGrid + ' ' + styles.regBusinessName}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <Label
                                            id="registed-bussiness-name-enable"
                                            text="Do you have a registered Business name ?"
                                            transalterEnabled={true}
                                        ></Label>
                                    </Grid>
                                    <Grid item>
                                        <div className={styles.yesNoSwitch}>
                                            <Typography
                                                color="primary"
                                                component="span"
                                                className={styles.checkBoxText}
                                            >
                                                No
                                            </Typography>
                                            <AntSwitch
                                                id="is-business-making-products"
                                                checked={onboardingForm.businessDetails.isRegisteredBusiness}
                                                onChange={(e) => {
                                                    setOnboardingForm({
                                                        ...onboardingForm,
                                                        businessDetails: {
                                                            ...onboardingForm.businessDetails,
                                                            isRegisteredBusiness: e.target.checked
                                                        }
                                                    });
                                                }}
                                            ></AntSwitch>
                                            <Typography
                                                color="primary"
                                                component="span"
                                                className={styles.checkBoxText}
                                            >
                                                {FormatMessage({ id: 'Yes', text: 'Yes' })}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                {registeredBusiness && (
                                    <FormItem>
                                        <Label
                                            text="Please provide a registered Business name"
                                            id="register-business-name-label"
                                            transalterEnabled={true}
                                        ></Label>
                                        <Input
                                            placeholder={FormatMessage({
                                                id: 'business_name_input',
                                                text: 'Business name'
                                            })}
                                            id="register-business-name"
                                            value={onboardingForm.businessDetails.tradingName}
                                            onChange={(e) => {
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    businessDetails: {
                                                        ...onboardingForm.businessDetails,
                                                        tradingName: e.target.value,
                                                        isAccountNameSame: false
                                                    },
                                                    tradingAddress: {
                                                        ...onboardingForm.tradingAddress,
                                                        accountHolderName: ''
                                                    }
                                                });
                                                setIsBankAccountNameSwitchOn(false);
                                            }}
                                            emptyField={
                                                isUKMerchant &&
                                                isActiveMerchant &&
                                                !checkEmptyValue(onboardingForm.businessDetails.tradingName)
                                                    ? true
                                                    : false
                                            }
                                        ></Input>
                                    </FormItem>
                                )}

                                {!registeredBusiness && (
                                    <Grid item xs={12} className={styles.formItemGrid}>
                                        <FormItem>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                justify="space-between"
                                                className={styles.formItemGrid}
                                            >
                                                <Grid item xs={12} sm={6}>
                                                    <Label
                                                        id="trade-under-name-as-bank"
                                                        text="Please confirm you trade under your name and your name appears
                                                    on your Bank ?"
                                                        transalterEnabled={true}
                                                    ></Label>
                                                </Grid>
                                                <Grid item>
                                                    <div className={styles.yesNoSwitch}>
                                                        <Typography
                                                            color="primary"
                                                            component="span"
                                                            className={styles.checkBoxText}
                                                        >
                                                            No
                                                        </Typography>
                                                        <AntSwitch
                                                            id="is-business-making-products"
                                                            checked={onboardingForm.businessDetails.isAccountNameSame}
                                                            onChange={(e) => {
                                                                setOnboardingForm({
                                                                    ...onboardingForm,
                                                                    businessDetails: {
                                                                        ...onboardingForm.businessDetails,
                                                                        isAccountNameSame: e.target.checked,
                                                                        tradingName: ''
                                                                    }
                                                                });
                                                                setIsBankAccountNameSwitchOn(e.target.checked);
                                                            }}
                                                        ></AntSwitch>
                                                        <Typography
                                                            color="primary"
                                                            component="span"
                                                            className={styles.checkBoxText}
                                                        >
                                                            {FormatMessage({ id: 'Yes', text: 'Yes' })}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </FormItem>
                                        {!isSameAccountName && (
                                            <FormItem>
                                                <Label
                                                    id="name-as-bank"
                                                    text="Enter the name that appears on your bank account"
                                                    transalterEnabled={true}
                                                ></Label>
                                                <Input
                                                    placeholder={FormatMessage({
                                                        id: 'bank_account_name',
                                                        text: 'Bank Account Name'
                                                    })}
                                                    value={onboardingForm.tradingAddress.accountHolderName}
                                                    onChange={(e) => {
                                                        setOnboardingForm({
                                                            ...onboardingForm,
                                                            tradingAddress: {
                                                                ...onboardingForm.tradingAddress,
                                                                accountHolderName: e.target.value
                                                            },
                                                            businessDetails: {
                                                                ...onboardingForm.businessDetails,
                                                                isAccountNameSame: false,
                                                                tradingName: ''
                                                            }
                                                        });
                                                        setIsBankAccountNameSwitchOn(false);
                                                    }}
                                                    emptyField={
                                                        isUKMerchant &&
                                                        isActiveMerchant &&
                                                        !checkEmptyValue(
                                                            onboardingForm.tradingAddress.accountHolderName
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                ></Input>
                                            </FormItem>
                                        )}
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                        {!isSoleTrader ? (
                            <React.Fragment>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                    <FormItem>
                                        <Label
                                            text="Company look-up"
                                            id="company-lookup-lebel"
                                            transalterEnabled="true"
                                        ></Label>
                                        <Grid container className={styles.companyContainer}>
                                            <Grid item xs={12} sm={8}>
                                                <Autocomplete
                                                    noOptionsText={'Company not found'}
                                                    id="name-address-input-company-lookup-name"
                                                    size="small"
                                                    open={openDropdown}
                                                    options={onboardingForm.nameAndAddress.companies || []}
                                                    getOptionLabel={(option) =>
                                                        `${option.title ?? ''}
                                        ${option.company_number ?? ''}
                                        ${option.address_snippet ?? ''}`
                                                    }
                                                    classes={{ noOptions: classes.noOptions }}
                                                    className={`${styles.withoutBorders} ${styles.customInput}`}
                                                    value={value}
                                                    onChange={(event, newValue) => {
                                                        setValue(newValue);
                                                        handleSelectedCompany(newValue);
                                                        setOpenDropdown(false);
                                                    }}
                                                    inputValue={inputValue}
                                                    onInputChange={(event, newInputValue) => {
                                                        setOnboardingForm({
                                                            ...onboardingForm,
                                                            nameAndAddress: {
                                                                ...onboardingForm.nameAndAddress,
                                                                companyName: newInputValue
                                                            }
                                                        });
                                                        setInputValue(newInputValue);
                                                    }}
                                                    renderOption={(option) => {
                                                        return (
                                                            <div>
                                                                <strong>{option.title ?? ''}</strong>
                                                                <br />
                                                                {`${option.company_number ?? ''}
                                                                    ${option.address_snippet ?? ''}`}
                                                            </div>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            className={styles.inputLookupCompany}
                                                            placeholder="Company number/name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    onBlur={getCompanyDetails}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <React.Fragment>
                                                    <Button
                                                        id="lookup-company-button"
                                                        className={styles.lookupCompanyButton}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={getCompanyDetails}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress color="white" size={20} />
                                                        ) : (
                                                            <span className={styles.textButton}>Look up</span>
                                                        )}
                                                    </Button>
                                                </React.Fragment>
                                            </Grid>
                                        </Grid>
                                    </FormItem>
                                </CheckMerchantCountries>
                            </React.Fragment>
                        ) : null}
                        {/* code for the solo trader is false */}
                        {!isSoleTrader && (
                            <React.Fragment>
                                {!isTrust && (
                                    <CheckMerchantCountries
                                        includedCountries={[
                                            MerchantCountries.AUSTRALIA,
                                            MerchantCountries.UNITED_STATES,
                                            MerchantCountries.UNITED_KINGDOM
                                        ]}
                                    >
                                        <FormItem>
                                            <Label
                                                text="Organisation type"
                                                id="Organisation-type"
                                                transalterEnabled="true"
                                            ></Label>
                                            <NativeSelect
                                                inputProps={{
                                                    className:
                                                        onboardingForm.businessDetails.businessCategory == '' ||
                                                        onboardingForm.businessDetails.businessCategory ==
                                                            '--Please select--'
                                                            ? styles.unselectedDropdownErrorStyle
                                                            : ''
                                                }}
                                                input={
                                                    <Input
                                                        placeholder="Organisation type"
                                                        id="Organisation-type"
                                                        value={onboardingForm.businessDetails.businessCategory}
                                                        onChange={(e) => {
                                                            setOnboardingForm({
                                                                ...onboardingForm,
                                                                businessDetails: {
                                                                    ...onboardingForm.businessDetails,
                                                                    businessCategory: e.target.value
                                                                }
                                                            });
                                                        }}
                                                        emptyField={
                                                            onboardingForm.businessDetails.businessCategory ==
                                                                '--Please select--' ||
                                                            onboardingForm.businessDetails.businessCategory === ''
                                                        }
                                                    ></Input>
                                                }
                                            >
                                                {dropdownTypeOrganisations.map((type) => {
                                                    return (
                                                        <option key={type.id} value={type.id}>
                                                            {type.name}
                                                        </option>
                                                    );
                                                })}
                                            </NativeSelect>
                                        </FormItem>
                                    </CheckMerchantCountries>
                                )}
                                <FormItem>
                                    <CheckMerchantCountries
                                        excludedCountries={[MerchantCountries.MEXICO, MerchantCountries.UNITED_STATES]}
                                    >
                                        <Label
                                            text="Company registered number"
                                            id="registered-number-label"
                                            transalterEnabled="true"
                                        ></Label>
                                    </CheckMerchantCountries>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                        <Label text="Tax identification number" id="registered-number-label"></Label>
                                    </CheckMerchantCountries>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                                        <Label
                                            text="Company RFC"
                                            id="registered-RFC-label"
                                            transalterEnabled="true"
                                        ></Label>
                                    </CheckMerchantCountries>

                                    <ErrorTooltip
                                        open={showErrorForRegisteredNumber}
                                        arrow
                                        title={
                                            isMexicanmerchant ? (
                                                <Alert severity="error" style={{ padding: 5 }}>
                                                    <Grid container xs={12}>
                                                        <Grid item xs={1}>
                                                            <ErrorOutlineIcon /> &nbsp;&nbsp;
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            &nbsp;&nbsp; {registeredNumberErrorMessage}
                                                        </Grid>
                                                    </Grid>
                                                </Alert>
                                            ) : (
                                                <Alert severity="error">
                                                    <ErrorOutlineIcon />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{registeredNumberErrorMessage}
                                                </Alert>
                                            )
                                        }
                                    >
                                        <div>
                                            <Input
                                                placeholder={
                                                    isMexicanmerchant
                                                        ? 'ABC123456X12 '
                                                        : isUSMerchant
                                                        ? 'Tax identification number'
                                                        : 'Registered number'
                                                }
                                                id="registered-number"
                                                title="Title"
                                                inputProps={{
                                                    maxLength: isUKMerchant || isMexicanmerchant ? 12 : ''
                                                }}
                                                maxLength="4"
                                                value={checkEmptyValue(onboardingForm.nameAndAddress.registeredNumber)}
                                                emptyField={
                                                    isUKMerchant &&
                                                    isActiveMerchant &&
                                                    !checkEmptyValue(onboardingForm.nameAndAddress.registeredNumber)
                                                        ? true
                                                        : false
                                                }
                                                onChange={setRegisteredNumberHandler}
                                            ></Input>
                                        </div>
                                    </ErrorTooltip>
                                </FormItem>
                                <FormItem>
                                    <Label
                                        text="Legal name"
                                        id="name-address-label-legal-name"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        emptyField={
                                            isUKMerchant &&
                                            isActiveMerchant &&
                                            !checkEmptyValue(onboardingForm.nameAndAddress.legalName)
                                                ? true
                                                : false
                                        }
                                        placeholder={FormatMessage({
                                            id: 'name-address-label-legal-name',
                                            text: 'Legal name'
                                        })}
                                        id="name-address-input-legal-name"
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.legalName)}
                                        onChange={(e) => {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    legalName: e.target.value
                                                }
                                            });
                                        }}
                                    ></Input>
                                </FormItem>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                    <FormItem>
                                        <Label
                                            text="ZIP code"
                                            id="name-address-label-zip-code-selector"
                                            transalterEnabled="true"
                                        ></Label>
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries excludedCountries={[MerchantCountries.UNITED_STATES]}>
                                    <FormItem>
                                        <Label
                                            text="Post code"
                                            id="name-address-label-post-code-selector"
                                            transalterEnabled="true"
                                        ></Label>
                                    </FormItem>
                                </CheckMerchantCountries>
                                <PostCodeSelector
                                    placeholder={
                                        isUSMerchant
                                            ? FormatMessage({ id: 'zip_code', text: 'Please enter ZIP code' })
                                            : FormatMessage({ id: 'post_code_input', text: 'Please enter post code' })
                                    }
                                    postCodeValue={checkEmptyValue(onboardingForm.nameAndAddress.postCode)}
                                    onPostCodeChange={(postCode) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            nameAndAddress: {
                                                ...onboardingForm.nameAndAddress,
                                                postCode: postCode
                                            }
                                        })
                                    }
                                    onAddressObjectChange={() => {}}
                                    emptyField={
                                        isUKMerchant &&
                                        isActiveMerchant &&
                                        !checkEmptyValue(onboardingForm.nameAndAddress.postCode)
                                            ? true
                                            : false
                                    }
                                />

                                <FormItem>
                                    <Label
                                        text="Address line 1"
                                        id="name-address-label-address-code-1"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'name-address-label-address-code-1',
                                            text: 'Address line 1'
                                        })}
                                        id="name-address-input-address-code-1"
                                        emptyField={
                                            isUKMerchant &&
                                            isActiveMerchant &&
                                            !checkEmptyValue(onboardingForm.nameAndAddress.addressLine1)
                                                ? true
                                                : false
                                        }
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.addressLine1)}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    addressLine1: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                </FormItem>
                                <FormItem>
                                    <Label
                                        text="Address line 2"
                                        id="name-address-label-address-code-2"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'name-address-label-address-code-2',
                                            text: 'Address line 2'
                                        })}
                                        id="name-address-input-address-code-2"
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.addressLine2)}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    addressLine2: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                </FormItem>
                                <FormItem>
                                    <Label
                                        text="Town/City"
                                        id="address-label-town-city"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'address-label-town-city',
                                            text: 'Town/City'
                                        })}
                                        id="address-input-town-city"
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.city)}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    city: e.target.value
                                                }
                                            })
                                        }
                                        emptyField={
                                            isUKMerchant &&
                                            isActiveMerchant &&
                                            !checkEmptyValue(onboardingForm.nameAndAddress.city)
                                                ? true
                                                : false
                                        }
                                    />
                                </FormItem>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                                    <FormItem>
                                        <Label text="State" id="address-label-state" transalterEnabled="true"></Label>
                                        <Input
                                            placeholder={FormatMessage({ id: 'address-label-state', text: 'State' })}
                                            id="address-input-state"
                                            value={checkEmptyValue(onboardingForm.nameAndAddress.state)}
                                            onChange={(e) =>
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    nameAndAddress: {
                                                        ...onboardingForm.nameAndAddress,
                                                        state: e.target.value
                                                    }
                                                })
                                            }
                                        />
                                    </FormItem>
                                </CheckMerchantCountries>
                                <Grid item xs={12} className={styles.formItemGrid}>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                                        <FormItem>
                                            <Label
                                                text="State/Region/Province code"
                                                id="State-Region-province"
                                                transalterEnabled="true"
                                            ></Label>
                                            <NativeSelect
                                                inputProps={{
                                                    className:
                                                        onboardingForm.nameAndAddress.state == '' ||
                                                        onboardingForm.nameAndAddress.state == '--Please select--'
                                                            ? styles.unselectedDropdownErrorStyle
                                                            : ''
                                                }}
                                                input={
                                                    <Input
                                                        placeholder="State/Region/Province code"
                                                        id="State-Region-province"
                                                        value={checkEmptyValue(onboardingForm.nameAndAddress.state)}
                                                        onChange={(e) =>
                                                            setOnboardingForm({
                                                                ...onboardingForm,
                                                                nameAndAddress: {
                                                                    ...onboardingForm.nameAndAddress,
                                                                    state: e.target.value
                                                                }
                                                            })
                                                        }
                                                    ></Input>
                                                }
                                            >
                                                {AUSStateCodes.map((type) => {
                                                    return (
                                                        <option key={type.id} value={type.id}>
                                                            {FormatMessage({ id: type.name, text: type.name })}
                                                        </option>
                                                    );
                                                })}
                                            </NativeSelect>
                                        </FormItem>
                                    </CheckMerchantCountries>
                                </Grid>
                                <Grid item xs={12} className={styles.formItemGrid}>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                        <FormItem>
                                            <Label
                                                text="State/Region/Province code"
                                                id="State-Region-province"
                                                transalterEnabled="true"
                                            ></Label>
                                            <NativeSelect
                                                inputProps={{
                                                    className:
                                                        onboardingForm.nameAndAddress.state == '' ||
                                                        onboardingForm.nameAndAddress.state == '--Please select--'
                                                            ? styles.unselectedDropdownErrorStyle
                                                            : ''
                                                }}
                                                input={
                                                    <Input
                                                        placeholder="State/Region/Province code"
                                                        id="State-Region-province"
                                                        value={checkEmptyValue(onboardingForm.nameAndAddress.state)}
                                                        onChange={(e) =>
                                                            setOnboardingForm({
                                                                ...onboardingForm,
                                                                nameAndAddress: {
                                                                    ...onboardingForm.nameAndAddress,
                                                                    state: e.target.value
                                                                }
                                                            })
                                                        }
                                                    ></Input>
                                                }
                                            >
                                                {USAStateCodes.map((type) => {
                                                    return (
                                                        <option key={type.id} value={type.id}>
                                                            {FormatMessage({ id: type.name, text: type.name })}
                                                        </option>
                                                    );
                                                })}
                                            </NativeSelect>
                                        </FormItem>
                                    </CheckMerchantCountries>
                                </Grid>
                                <CheckMerchantCountries excludedCountries={[MerchantCountries.MEXICO]}>
                                    <FormItem>
                                        <Label
                                            text="Country"
                                            id="name-address-label-country"
                                            transalterEnabled="true"
                                        ></Label>
                                        <Input
                                            placeholder="Country"
                                            id="address-input-country"
                                            value={checkEmptyValue(onboardingForm.nameAndAddress.country)}
                                            onChange={(e) =>
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    nameAndAddress: {
                                                        ...onboardingForm.nameAndAddress,
                                                        country: e.target.value
                                                    }
                                                })
                                            }
                                            emptyField={
                                                isUKMerchant &&
                                                isActiveMerchant &&
                                                !checkEmptyValue(onboardingForm.nameAndAddress.country)
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                                    <FormItem>
                                        <Label
                                            text="Country"
                                            id="address-label-country"
                                            transalterEnabled="true"
                                        ></Label>
                                        <Input
                                            placeholder={FormatMessage({
                                                id: 'address-label-country',
                                                text: 'Country'
                                            })}
                                            id="address-input-country"
                                            value={MerchantCountries.MEXICO}
                                            disabled
                                        />
                                    </FormItem>
                                </CheckMerchantCountries>
                            </React.Fragment>
                        )}
                    </Grid>

                    <CheckMerchantCountries excludedCountries={[MerchantCountries.AUSTRALIA]}>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label
                                    text="Telephone number"
                                    id="telephone-number-label"
                                    transalterEnabled="true"
                                ></Label>
                                <PhoneInput
                                    country={'gb'}
                                    id="telephone-number"
                                    value={checkEmptyValue(onboardingForm.businessDetails.phoneNumber)}
                                    placeholder={FormatMessage({
                                        id: 'telephone-number-label',
                                        text: 'Telephone number'
                                    })}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone, data) => {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                phoneNumber: phoneNumberValidator(
                                                    phone,
                                                    onboardingForm.businessDetails.phoneNumber
                                                )
                                            }
                                        });
                                        setDialCode(data.dialCode);
                                    }}
                                    inputClass={
                                        isUKMerchant &&
                                        isActiveMerchant &&
                                        (!checkEmptyValue(onboardingForm.businessDetails.phoneNumber) ||
                                            onboardingForm.businessDetails.phoneNumber === dialCode)
                                            ? styles.phoneInputEmpty
                                            : styles.phoneInput
                                    }
                                    name="phone"
                                />
                            </FormItem>
                        </Grid>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Telephone number" id="telephone-number-label"></Label>
                                <PhoneInput
                                    country={'au'}
                                    id="telephone-number"
                                    value={checkEmptyValue(onboardingForm.businessDetails.phoneNumber)}
                                    placeholder={'Telephone no.'}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone, data) => {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                phoneNumber: phoneNumberValidator(
                                                    phone,
                                                    onboardingForm.businessDetails.phoneNumber
                                                )
                                            }
                                        });
                                        setDialCode(data.dialCode);
                                    }}
                                    name="phone"
                                    inputClass={styles.phoneInput}
                                />
                            </FormItem>
                        </Grid>
                    </CheckMerchantCountries>

                    <Grid item xs={12} className={styles.formItemGrid}>
                        <ValidEmailInput
                            labelText="Company email"
                            labelId="company-email-label"
                            placeholder={FormatMessage({ id: 'company-email-label', text: 'Company email' })}
                            transalterEnabled="true"
                            id="company-email"
                            value={checkEmptyValue(onboardingForm.businessDetails.email)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    businessDetails: {
                                        ...onboardingForm.businessDetails,
                                        email: e.target.value.toLowerCase()
                                    }
                                })
                            }
                            emptyField={
                                isUKMerchant &&
                                isActiveMerchant &&
                                !checkEmptyValue(onboardingForm.businessDetails.email)
                                    ? true
                                    : false
                            }
                        />
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <ValidURLInput
                            labelText="Company website"
                            labelId="company-website-label"
                            placeholder={FormatMessage({ id: 'company-website-label', text: 'Company Website' })}
                            transalterEnabled="true"
                            id="company-website"
                            value={checkEmptyValue(onboardingForm.businessDetails.websiteUrl)}
                            onChange={(value) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    businessDetails: {
                                        ...onboardingForm.businessDetails,
                                        websiteUrl: value
                                    }
                                })
                            }
                            emptyField={
                                isUKMerchant &&
                                isActiveMerchant &&
                                !checkEmptyValue(onboardingForm.businessDetails.websiteUrl)
                                    ? true
                                    : false
                            }
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    const title = (
        <OnboardingTitle
            title={getOnboardingTitle(OnboardingSteps.NAME_AND_ADDRESS, merchantCountry)}
            transalterEnabled={true}
        ></OnboardingTitle>
    );

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} nextButton={nextBtn} content={FormBody} />;
}

T2SOnboardingNameAddressComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    getCompanyDetails: PropTypes.func,
    phoneNumberValidator: PropTypes.func,
    isLoading: PropTypes.bool,
    setInputValue: PropTypes.func,
    handleSelectedCompany: PropTypes.func,
    value: PropTypes.string,
    setValue: PropTypes.func,
    inputValue: PropTypes.string,
    setOpenDropdown: PropTypes.func,
    setIsBankAccountNameSwitchOn: PropTypes.func,
    isSoleTrader: PropTypes.bool,
    setIsSoleTrader: PropTypes.func,
    newBusinessTypes: PropTypes.array,
    legalNameVal: PropTypes.string,
    setLegalNameVal: PropTypes.func,
    setRegisteredNumberHandler: PropTypes.func
};

export default T2SOnboardingNameAddressComponent;
