import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    Button,
    List,
    ListItem,
    Input,
    Radio,
    Typography,
    Tooltip
} from '@material-ui/core';
import styles from './modal-invite-user.module.scss';
import { Roles } from '../../utils/enums/Roles';
import { HighlightOff } from '@material-ui/icons';
import { ErrorOutline } from '@material-ui/icons';
import { UserType } from 'utils/enums/UserType';
import { useGlobalStateContext } from 'contexts';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ResellerType } from 'utils/enums/ResellerType';

export default function ModalInviteUserComponent(props) {
    const {
        emailsAndRoles,
        show,
        setShow,
        inviteMembers,
        validateEmptyField,
        setRole,
        setEmail,
        addNewUserEmailAndRole,
        removeNewUserEmailAndRole,
        doValidation,
        emailValidationMessagesArray,
        duplicateEmailMessage,
        disabled,
        count,
        maxUserCount,
        setCount,
        totalTeamMembers,
        wrapperRef,
        isAgent
    } = props;
    const { globalState } = useGlobalStateContext();

    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;
    const isDatman = globalState.reseller?.name === ResellerType.DATMAN;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;

    return (
        <Dialog
            ref={wrapperRef}
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="false"
        >
            <DialogTitle id="form-dialog-title" className={styles.inviteMembersTitle}>
                Invite new users
            </DialogTitle>
            <div className={styles.headerDivider}></div>

            <DialogContent>
                <p className={styles.title_info}>
                    Enter the email addresses of the users you&apos;d like to invite, and choose the role they should
                    have.
                </p>
                <form>
                    {/* Leaving this hear, as will be discussed later how we are gonna handle the UI for this labels */}
                    {/* <Grid container>
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                            <span className={styles.role_info}>Best for company administrators</span>
                        </Grid>
                        <Grid item xs={2}>
                            <span className={styles.role_info}>Best for people who need full access to MyPay data</span>
                        </Grid>
                        <Grid item xs={2}>
                            <span className={styles.role_info}>
                                Best for people who need to view Stripe data, but don&apos;t need to make any updates
                            </span>
                        </Grid>
                    </Grid> */}
                    <List id="emailsAndRoles">
                        {emailsAndRoles.map((emailAndRole, index) => {
                            return (
                                <ListItem key={index}>
                                    <Grid container item xs={12} sm={12}>
                                        <Grid item xs={12} sm={4}>
                                            <Input
                                                className={styles.email_input}
                                                type="email"
                                                placeholder="Email"
                                                value={emailAndRole?.email}
                                                onChange={(e) => {
                                                    setEmail(index, e.target.value.toLowerCase());
                                                    doValidation(index, e.target.value, emailValidationMessagesArray);
                                                }}
                                            ></Input>
                                            {emailValidationMessagesArray[index]?.show && (
                                                <div className={styles.notificationMessageContainer}>
                                                    <Typography
                                                        color="error"
                                                        style={{ marginLeft: '-10px', fontSize: '12px' }}
                                                    >
                                                        {emailValidationMessagesArray[index]?.emailValidationMessage}
                                                    </Typography>
                                                </div>
                                            )}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            container
                                            className={styles.userRolesContainer}
                                            justify="space-around"
                                        >
                                            {isAdmin && !isAgent && (
                                                <div className={styles.radio_with_label_container}>
                                                    <span className={styles.roleContainer}>
                                                        <Tooltip
                                                            placement="top"
                                                            title="Owner can create/remove Admins or any other user and has an ability to do anything"
                                                        >
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </Tooltip>
                                                        <span className={styles.roleName}>{Roles.Owner}</span>
                                                    </span>
                                                    <div className={styles.radio_container}>
                                                        <Radio
                                                            color="primary"
                                                            classes={styles.radio_container}
                                                            className={styles.radio_btn}
                                                            value={Roles.Owner}
                                                            checked={emailAndRole?.role === Roles.Owner}
                                                            size="small"
                                                            onChange={() => setRole(index, Roles.Owner)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {!isAgent && (
                                                <div className={styles.radio_with_label_container}>
                                                    <span className={styles.roleContainer}>
                                                        <Tooltip
                                                            title="Admin can create/disable/enable/add/remove users and modify account details."
                                                            placement="top"
                                                        >
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </Tooltip>
                                                        <span className={styles.roleName}>{Roles.Admin}</span>
                                                    </span>

                                                    <div className={styles.radio_container}>
                                                        <Radio
                                                            color="primary"
                                                            className={styles.radio_btn}
                                                            value={Roles.Admin}
                                                            checked={emailAndRole?.role === Roles.Admin}
                                                            size="small"
                                                            onChange={() => setRole(index, Roles.Admin)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {!isAgent && (
                                                <div className={styles.radio_with_label_container}>
                                                    <span className={styles.roleContainer}>
                                                        <Tooltip
                                                            title="Manager have a top level view of the business, can add users / drill down to detailed view of the transactions."
                                                            placement="top"
                                                        >
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </Tooltip>
                                                        <span className={styles.roleName}>{Roles.Manager}</span>
                                                    </span>
                                                    <div className={styles.radio_container}>
                                                        <Radio
                                                            color="primary"
                                                            classes={styles.radio_container}
                                                            className={styles.radio_btn}
                                                            value={Roles.Manager}
                                                            checked={emailAndRole?.role === Roles.Manager}
                                                            size="small"
                                                            onChange={() => setRole(index, Roles.Manager)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {isReseller && isDatman && !isAgent && (
                                                <div className={styles.radio_with_label_container}>
                                                    <span className={styles.roleContainer}>
                                                        <Tooltip
                                                            title="PDQ Agents can add multiple Agents"
                                                            placement="top"
                                                        >
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </Tooltip>
                                                        <span className={styles.roleName}>{Roles.Agent}</span>
                                                    </span>
                                                    <div className={styles.radio_container}>
                                                        <Radio
                                                            color="primary"
                                                            classes={styles.radio_container}
                                                            className={styles.radio_btn}
                                                            value={Roles.Agent}
                                                            checked={emailAndRole?.role === Roles.Agent}
                                                            size="small"
                                                            onChange={() => setRole(index, Roles.Agent)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {isAgent && (
                                                <div>
                                                    <Typography>
                                                        Role :<span className={styles.agentRole}> {Roles.Agent}</span>
                                                    </Typography>
                                                </div>
                                            )}
                                            {!isAgent && (
                                                <div className={styles.radio_with_label_container}>
                                                    <span className={styles.roleContainer}>
                                                        <Tooltip title="User have Read only access" placement="top">
                                                            <InfoOutlinedIcon fontSize="small" />
                                                        </Tooltip>
                                                        <span className={styles.roleName}>{Roles.User}</span>
                                                    </span>
                                                    <div className={styles.radio_container}>
                                                        <Radio
                                                            color="primary"
                                                            classes={styles.radio_container}
                                                            className={styles.radio_btn}
                                                            value={Roles.User}
                                                            checked={emailAndRole?.role === Roles.User}
                                                            size="small"
                                                            onChange={() => setRole(index, Roles.User)}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            <div className={styles.removeUserButton}>
                                                <Button onClick={() => removeNewUserEmailAndRole(index)}>
                                                    <HighlightOff></HighlightOff>
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            );
                        })}
                    </List>
                    {duplicateEmailMessage && (
                        <div className={styles.duplicateEmailContainer}>
                            <div className={styles.iconUnverified}>
                                <ErrorOutline className={styles.error_icon} />
                            </div>
                            <Typography color="primary" className={styles.errorMessage}>
                                {duplicateEmailMessage}
                            </Typography>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => addNewUserEmailAndRole()}
                        color="primary"
                        disabled={count >= maxUserCount}
                    >
                        Add
                    </Button>
                    {count === maxUserCount && (
                        <div className={styles.notificationMessageContainer}>
                            <Typography color="error" style={{ fontSize: '12px' }}>
                                Maximum number of users allowed is 50.
                            </Typography>
                        </div>
                    )}
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setShow(false);
                                setCount(totalTeamMembers);
                            }}
                            color="primary"
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={
                                !validateEmptyField() || disabled || duplicateEmailMessage || count === maxUserCount + 1
                            }
                            variant="contained"
                            onClick={() => inviteMembers()}
                            color="primary"
                        >
                            Invite
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
}

ModalInviteUserComponent.propTypes = {
    emailsAndRoles: PropTypes.array,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    inviteMembers: PropTypes.func,
    setRole: PropTypes.func,
    validateEmptyField: PropTypes.func,
    addNewUserEmailAndRole: PropTypes.func,
    setEmail: PropTypes.func,
    removeNewUserEmailAndRole: PropTypes.func,
    doValidation: PropTypes.func
};
