import React, { useState, useEffect } from 'react';
import UpdateAccountMypayComponent from './update-account-mypay.component';
import { BusinessDetailsService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { InputValidators } from 'validators';
import { difference, updateAccountMapKeyWord } from 'utils/helper';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

function UpdateAccountMypay() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [businessDetails, setBusinessDetails] = useState({});
    const [ownerDetails, setOwnerDetails] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [oldOwnerDetails, setOldOwnerDetails] = useState({});
    const [oldBusinessDetails, setOldBusinessDetails] = useState({});

    useEffect(() => {
        const allFieldsCompleted =
            businessDetails.legalName &&
            InputValidators.isWebsiteUrlValid(businessDetails.websiteUrl) &&
            ownerDetails.fullName &&
            ownerDetails.postCode &&
            ownerDetails.addressLine1 &&
            ownerDetails.city &&
            ownerDetails.telephoneNumber &&
            InputValidators.isEmailValid(ownerDetails.email);
        const updatedAccount =
            businessDetails.legalName !== oldBusinessDetails.legalName ||
            businessDetails.websiteUrl !== oldBusinessDetails.websiteUrl ||
            ownerDetails.fullName !== oldOwnerDetails.fullName ||
            ownerDetails.postCode !== oldOwnerDetails.postCode ||
            ownerDetails.addressLine1 !== oldOwnerDetails.addressLine1 ||
            ownerDetails.city !== oldOwnerDetails.city ||
            ownerDetails.telephoneNumber !== oldOwnerDetails.telephoneNumber ||
            ownerDetails.email !== oldOwnerDetails.email;
        if (allFieldsCompleted && updatedAccount) setDisabled(false);
        else setDisabled(true);
    }, [businessDetails, ownerDetails, oldOwnerDetails, oldBusinessDetails]);

    useEffect(() => {
        const getMerchantBusinessDetails = async () => {
            setIsLoading(true);
            const result = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);

            if (result.isSuccesfully) {
                setBusinessDetails(result.data.businessDetails);
                setOwnerDetails(result.data.ownerDetails);
                setOldBusinessDetails(result.data.businessDetails);
                setOldOwnerDetails(result.data.ownerDetails);
            }

            setIsLoading(false);
        };

        getMerchantBusinessDetails();
    }, [globalState.merchant]);

    const selectAddressData = (addressData, setObject, object) => {
        if (addressData) {
            setObject({
                ...object,
                addressLine1: addressData.line_1 + ', ' + addressData.line_2,
                city: addressData.town_or_city,
                country: MerchantCountries.UNITED_KINGDOM
            });
        }
    };

    const onSubmit = async () => {
        const updatedOwnerDetails = updateAccountMapKeyWord(difference(ownerDetails, oldOwnerDetails), oldOwnerDetails);
        const updatedBusinessDetails = updateAccountMapKeyWord(
            difference(businessDetails, oldBusinessDetails),
            oldBusinessDetails
        );
        const updatedAccountDetails = {
            ...updatedBusinessDetails,
            ...updatedOwnerDetails
        };

        setIsLoading(true);
        const response = await BusinessDetailsService.updateBusinessDetails(globalState.merchant?.id, {
            businessDetails,
            ownerDetails,
            resellerId: globalState.reseller.id,
            updatedAccountDetails
        });

        setIsLoading(false);
        if (response.isSuccesfully) {
            sessionStorage.setItem('accountUpdated', true);
            setRoute('/home');
        } else {
            setShowModal(true);
            setModalMessage('An error occurred. Please contact support or try again.');
        }
    };

    return (
        <UpdateAccountMypayComponent
            disabled={disabled}
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            ownerDetails={ownerDetails}
            setOwnerDetails={setOwnerDetails}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            showModal={showModal}
            setShowModal={setShowModal}
            modalMessage={modalMessage}
            isLoading={isLoading}
        />
    );
}

export default UpdateAccountMypay;
