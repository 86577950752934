export const PdqStatus = {
    0: 'UNSUCCESSFUL',
    1: 'SUCCESS',
    2: 'CANCELLED'
};
export const statusToFilter = {
    Usuccessful: 0,
    Success: 1,
    Cancelled: 2
};
