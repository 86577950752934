import React, { useEffect, useState } from 'react';
import ThirdPartyMerchantIdAndStoreIdComponent from './third-party-merchant-id-and-store-id.component';
import { useAdminFunctionsContext } from 'contexts';

export default function ThirdPartyMerchantIdAndStoreId(props) {
    const { adminFunctions } = useAdminFunctionsContext();
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [merchantIdAndMerchantStoreId, setMerchantIdAndMerchantStoreId] = useState('');
    const [withLabel, setWithLabel] = useState(false);
    const { title } = props;

    useEffect(() => {
        const getMerchantStoreIds = () => {
            let merchantIdAndMerchantStoreIds;
            if (props.ids) {
                const { thirdPartyCustomerId, merchantId } = props.ids;
                merchantIdAndMerchantStoreIds = thirdPartyCustomerId
                    ? `${thirdPartyCustomerId}-${merchantId}`
                    : merchantId;
                setWithLabel(false);
            } else {
                merchantIdAndMerchantStoreIds = adminFunctions.thirdPartyCustomerId
                    ? `${adminFunctions.thirdPartyCustomerId}-${adminFunctions.merchantId}`
                    : adminFunctions.merchantId;
                setWithLabel(true);
            }
            setMerchantIdAndMerchantStoreId(merchantIdAndMerchantStoreIds);
        };
        getMerchantStoreIds();
        // eslint-disable-next-line
    }, [adminFunctions]);

    const handleCopyMerchantAndStoreId = () => {
        navigator.clipboard.writeText(merchantIdAndMerchantStoreId);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    return (
        <ThirdPartyMerchantIdAndStoreIdComponent
            merchantIdAndMerchantStoreId={merchantIdAndMerchantStoreId}
            isCopyAlertOpen={isCopyAlertOpen}
            handleCopyMerchantAndStoreId={handleCopyMerchantAndStoreId}
            closeCopyAlert={closeCopyAlert}
            withLabel={withLabel}
            title={title}
        />
    );
}
