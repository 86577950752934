import React, { useState, useEffect } from 'react';
import PayoutCardComponent from './payout-card.component';
import moment from 'moment';
import { PayoutsService } from 'services';
import { useGlobalStateContext } from 'contexts';

function PayoutCard(props) {
    const [payout, setPayout] = useState(0);
    const [loading, setLoading] = useState(true);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        let sum = 0;
        const getPayout = async () => {
            const date = moment(new Date()).format('YYYY-MM-DD');

            const payoutsList = await PayoutsService.getAllPayouts(globalState.merchant?.id, {
                selectedDate: date,
                resellerId: globalState.reseller?.id
            });
            const inBatchPayouts = payoutsList.data?.transaction_data?.not_in_batch;

            if (inBatchPayouts) {
                inBatchPayouts.forEach((element) => {
                    sum += Number(element.total);
                });
            }
            setPayout(sum * -1);
            setLoading(false);
        };
        const getStripePayout = async () => {
            const merchantId = globalState.merchant?.id;
            const stripePayoutsList = await PayoutsService.getStripePayoutsList({
                merchantId
            });
            stripePayoutsList.isSuccesfully &&
                stripePayoutsList.data.data.forEach((element) => {
                    if (element.status === 'in_transit') sum += Number(element.amount);
                });
            setPayout(sum / 100);
            setLoading(false);
        };

        globalState.merchant.isStripe ? getStripePayout() : getPayout();
        // eslint-disable-next-line
    }, [globalState.merchant]);

    return <PayoutCardComponent {...props} payout={payout} loading={loading} />;
}

export default PayoutCard;
