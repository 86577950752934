import React from 'react';
import styles from './signup-form.module.scss';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
//import AuthWithSocialMedia from 'components/auth-with-social-media/auth-with-social-media.container';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { PasswordValidation, SnackBarAndAlert, CustomLoadingScreen, ValidEmailInput, PasswordInput } from 'components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Clear } from '@material-ui/icons';
function SignUpComponent(props) {
    const { getRoute } = useGlobalStateContext();
    const {
        onSubmit,
        email,
        setEmail,
        password,
        oldPassword,
        setOldPassword,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        signUpError,
        setSignUpError,
        errorMessage,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        isLoading,
        forceChangePassword,
        isUserInvited,
        phone,
        setPhone,
        isT2SUser,
        setIsPasswordValid,
        isPasswordValid,
        isPhoneValid,
        // thirdPartyResellerUrlParameter,
        setPhoneCountryIdentificator,
        redoPasswordValidation,
        setRedoPasswordValidation,
        isEmailValid
    } = props;

    const useStyles = makeStyles((theme) => ({
        disabledInput: {
            color: theme.palette.text.primary,
            backgroundColor: 'Gainsboro',
            border: '1px solid LightGray',
            borderRadius: '5px',
            fontWeight: '600',
            userSelect: 'none'
        }
    }));

    const classes = useStyles();

    return (
        <div className={styles.signUpForm}>
            <Form onSubmit={onSubmit}>
                <Grid container spacing={2} className={styles.gridContainer}>
                    <Grid item xs={12} sm={6}>
                        <Form.Label>First name*</Form.Label>
                        <Form.Control
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            placeholder="First name"
                            autoFocus={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Form.Label>Last name*</Form.Label>
                        <Form.Control
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            placeholder="Last name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidEmailInput
                            labelText="Email address*"
                            labelId="email-address*"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.toLowerCase().replace(/ /g, ''))}
                            onKeyPress={(e) => {
                                if (e.key === ' ') {
                                    e.preventDefault();
                                }
                            }}
                            required
                            readOnly={forceChangePassword}
                            type="email"
                            placeholder="Email"
                            name="email"
                            disabled
                            classes={{ disabled: classes.disabledInput }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Form.Label>Phone number {isT2SUser ? '*' : ''}</Form.Label>
                        {!isPhoneValid ? (
                            <Grid container className={styles.validaion__messages}>
                                <Grid item xs={1}>
                                    <Clear className={styles.crossIcon} />
                                </Grid>
                                <Grid item xs={11} className={styles.validationTextContainer}>
                                    <Typography className={styles.validationText}>Phone number is mandatory</Typography>
                                </Grid>
                            </Grid>
                        ) : null}

                        <PhoneInput
                            value={phone}
                            placeholder={'Phone'}
                            alwaysDefaultMask={true}
                            onChange={(phone, data) => {
                                setPhone(phone);
                                setPhoneCountryIdentificator(data.dialCode);
                            }}
                            inputClass={styles.phoneInput}
                            inputProps={{ required: isT2SUser }}
                            name="phone"
                            countryCodeEditable={false}
                        />
                    </Grid>
                    {forceChangePassword && !isUserInvited && (
                        <Grid item xs={12}>
                            <React.Fragment>
                                <Form.Label>Old password*</Form.Label>
                                <PasswordInput
                                    required
                                    password={oldPassword}
                                    placeholder="Old password"
                                    type="password"
                                    onChange={(value) => setOldPassword(value)}
                                    minLength="8"
                                />
                            </React.Fragment>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Form.Label>Password*</Form.Label>
                        <Grid container className={styles.validaion__messages}>
                            <PasswordValidation
                                honorCurrentPassword={false}
                                newPassword={password}
                                confirmPassword={confirmPassword}
                                setIsPasswordValid={setIsPasswordValid}
                                redoPasswordValidation={redoPasswordValidation}
                                email={email}
                                firstName={firstName}
                                lastName={lastName}
                            />
                        </Grid>
                        <PasswordInput
                            password={password}
                            onChange={(value) => {
                                setPassword(value);
                                setRedoPasswordValidation(false);
                            }}
                            required
                            minLength="8"
                            //pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,}"
                            pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                            title="Password must contain at least 8 characters including a number and a letter"
                            type="password"
                            placeholder="Password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Form.Label>Confirm password*</Form.Label>
                        <PasswordInput
                            required
                            password={confirmPassword}
                            onChange={(value) => {
                                setConfirmPassword(value);
                                setRedoPasswordValidation(true);
                            }}
                            type="password"
                            placeholder="Confirm password"
                        />
                    </Grid>
                </Grid>
                {signUpError && (
                    <SnackBarAndAlert open={signUpError} onClose={() => setSignUpError(false)} type="error">
                        {errorMessage}
                    </SnackBarAndAlert>
                )}
                {/* {verifyEmail && (
                    <SnackBarAndAlert open={verifyEmail} onClose={() => setVerifyEmail(false)} type="success">
                        {messages.verifyEmail}
                    </SnackBarAndAlert>
                )}
                {successSignupInvite && (
                    <SnackBarAndAlert
                        open={successSignupInvite}
                        onClose={() => setSuccessSignupInvite(false)}
                        type="success"
                    >
                        {messages.verifyEmail}
                    </SnackBarAndAlert>
                )} */}

                <div className={styles.signUpFormButton}>
                    <Button
                        className={styles.signUpButton}
                        id="signUpSubmitBtn"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={!(isPhoneValid && isPasswordValid && isEmailValid)}
                    >
                        Sign up
                    </Button>
                </div>
                {/* {isT2SUser && (
                    <div>
                        <AuthWithSocialMedia
                            isT2SUser={isT2SUser}
                            thirdPartyResellerUrlParameter={thirdPartyResellerUrlParameter}
                        />
                    </div>
                )} */}
                {!isT2SUser && (
                    <Form.Group className={styles.linkToSignUp}>
                        <Form.Label>
                            Do you already have an account? <Link to={getRoute(`/login`)}>Log in</Link>
                        </Form.Label>
                    </Form.Group>
                )}
            </Form>
            <CustomLoadingScreen
                open={isLoading}
                message={
                    !isUserInvited
                        ? 'Hi, thanks for signing up. Now we need to get some more detailed information before your account can be reviewed by our team.'
                        : 'Thank you for the registration! We will redirect you to the homepage'
                }
            />
        </div>
    );
}

SignUpComponent.propTypes = {
    onSubmit: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    password: PropTypes.string,
    messages: PropTypes.object,
    setPassword: PropTypes.func,
    confirmPassword: PropTypes.string,
    setConfirmPassword: PropTypes.func,
    signUpError: PropTypes.bool,
    setSignUpError: PropTypes.func,
    errorMessage: PropTypes.string,
    verifyEmail: PropTypes.bool,
    setVerifyEmail: PropTypes.func,
    firstName: PropTypes.string,
    setFirstName: PropTypes.func,
    lastName: PropTypes.string,
    setLastName: PropTypes.func,
    isLoading: PropTypes.bool,
    forceChangePassword: PropTypes.bool,
    oldPassword: PropTypes.string,
    setOldPassword: PropTypes.func,
    successSignupInvite: PropTypes.bool,
    setSuccessSignupInvite: PropTypes.func,
    isUserInvited: PropTypes.bool,
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    isT2SUser: PropTypes.bool,
    isPasswordValid: PropTypes.bool,
    setIsPasswordValid: PropTypes.func,
    isPhoneValid: PropTypes.bool,
    setPhoneCountryIdentificator: PropTypes.string,
    redoPasswordValidation: PropTypes.bool

    //thirdPartyResellerUrlParameter: PropTypes.string
};

export default SignUpComponent;
