import React, { useEffect } from 'react';

import ModalTakePaymentComponent from './modal-take-payment.component';

const onModalClose = (paymentSuccess, props, dispatch) => {
    dispatch({
        type: 'SET_MODAL_STATE',
        showModal: false,
        paymentSuccess: null,
        showLoadingSpinner: true
    });

    if (paymentSuccess) return props.history.push('/virtual-terminal');
    return null;
};

const ModalTakePayment = (props) => {
    const { handleIframeTask } = props;

    useEffect(() => {
        window.addEventListener('message', handleIframeTask);
        return () => window.removeEventListener('message', handleIframeTask);
    }, [handleIframeTask]);

    return <ModalTakePaymentComponent {...props} onModalClose={onModalClose} />;
};

export default ModalTakePayment;
