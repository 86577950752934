export const PdqOrderStatus = {
    all: 'Select all',
    background_check: 'In Review',
    need_additional_info: 'Send Back',
    msa: 'MSA Sent',
    msa_signed: 'MSA Signed',
    ecom_issued: 'Success',
    pos_issued: 'Success',
    rejected: 'Rejected',
    request_raised: 'Request Raised'
};

export const DNAEventStatus = {
    BACKGROUND_CHECK: 'background_check',
    NEED_ADDITIONAL_INFO: 'need_additional_info',
    MSA: 'msa',
    ECOM_ISSUED: 'ecom_issued',
    POS_ISSUED: 'pos_issued',
    REQUEST_RAISED: 'request_raised',
    REJECTED: 'rejected',
    MSA_SIGNED: 'msa_signed'
};
export const statusMatch = [
    {
        all: 'Select all',
        background_check: 'In Review',
        need_additional_info: 'Add More Info',
        msa: 'T&C',
        ecom_issued: 'Success',
        pos_issued: 'Success',
        rejected: 'Rejected'
    }
];
export const pdqStatusChecked = [
    { id: 0, name: 'Select all', value: 'all', checked: true },
    { id: 1, name: 'Request Raised', value: 'request_raised', checked: true },
    { id: 2, name: 'In Review', value: 'background_check', checked: true },
    { id: 3, name: 'MSA Sent', value: 'msa', checked: true },
    { id: 4, name: 'MSA Signed', value: 'msa_signed', checked: true },
    { id: 5, name: 'Send Back', value: 'need_additional_info', checked: true },
    { id: 6, name: 'Success', value: 'pos_issued', checked: true },
    { id: 7, name: 'Rejected', value: 'rejected', checked: true }
];
