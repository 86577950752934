import { PendingVerificationList } from 'components';
import React from 'react';

export default function AdminPendingAccountVerfication(props) {
    return (
        <div>
            <PendingVerificationList {...props} />
        </div>
    );
}
