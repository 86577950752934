import React, { useEffect, useState } from 'react';
import RequestSupportFormComponent from './request-support-form.component';
import { UrgentMessagesService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

const RequestSupportFormContainer = (props) => {
    const { globalState, setRoute } = useGlobalStateContext();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [query, setQuery] = useState('');
    const [merchantId, setMerchantId] = useState(null);
    const [isDisable, setIsDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        const shouldEnable = firstName && lastName && email && phoneNumber && query;

        if (shouldEnable) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }

        //eslint-disable-next-line
    }, [firstName, lastName, email, phoneNumber, query]);

    useEffect(() => {
        const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (queryString.merchantId) {
            setMerchantId(queryString.merchantId);
        }
    }, [location.search]);

    useEffect(() => {
        if (globalState?.merchant) {
            setMerchantId(globalState.merchant.id);
        }
    }, [globalState.merchant]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            query: query,
            resellerId: globalState.reseller.id,
            merchantId: merchantId
        };

        const response = await UrgentMessagesService.requestForSupport(data);

        setIsLoading(false);
        if (response.isSuccesfully) {
            sessionStorage.setItem('querySubmitted', true);
            setRoute('/');
        } else {
            setShowMessage(true);
            setMessage(response.error);
        }
    };

    const handleCloseMessage = () => {
        setShowMessage(false);
    };

    return (
        <RequestSupportFormComponent
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            query={query}
            setQuery={setQuery}
            handleSubmit={handleSubmit}
            isDisable={isDisable}
            isLoading={isLoading}
            showMessage={showMessage}
            message={message}
            handleCloseMessage={handleCloseMessage}
        />
    );
};

export default RequestSupportFormContainer;
