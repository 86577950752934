import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText } from '@material-ui/core';
import styles from './modal-update-bank-completion.module.scss';
import Divider from '@material-ui/core/Divider';

export default function ModalUpdateBankCompletionComponent(props) {
    const {
        bankUpdateDialogBox,
        setBankUpdateDialogBox,
        continueUpdating,
        override,
        bankDetailsUpdateInitiated,
        overRideRequest
    } = props;

    return (
        <Dialog
            open={bankUpdateDialogBox & (bankDetailsUpdateInitiated || overRideRequest)}
            onClose={() => setBankUpdateDialogBox(false)}
            className={{
                paper: styles.paper
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {bankDetailsUpdateInitiated ? (
                <div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The bank Update has not been completed.
                        </DialogContentText>
                        <Divider />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() => {
                                setBankUpdateDialogBox(false);
                            }}
                            color="primary"
                            variant="outlined"
                            className={styles.leavebtn}
                        >
                            Leave
                        </Button>

                        <Button
                            variant="contained"
                            onClick={() => continueUpdating()}
                            color="primary"
                            className={styles.dilogbtn}
                        >
                            Continue Updating
                        </Button>
                    </DialogActions>
                </div>
            ) : null}
            {overRideRequest ? (
                <div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            There is a bank update request in progress. Do you want to override the previous request ?
                        </DialogContentText>
                        <Divider />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={() => setBankUpdateDialogBox(false)}
                            color="primary"
                            variant="outlined"
                            className={styles.leavebtn}
                        >
                            Leave
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => override()}
                            color="primary"
                            className={styles.dilogbtn}
                        >
                            Override
                        </Button>
                    </DialogActions>
                </div>
            ) : null}
        </Dialog>
    );
}

ModalUpdateBankCompletionComponent.propTypes = {
    bankUpdateDialogBox: PropTypes.bool,
    setBankUpdateDialogBox: PropTypes.func,
    continueUpdating: PropTypes.func,
    override: PropTypes.func,
    overRideRequest: PropTypes.func
};
