import React from 'react';
import PropTypes from 'prop-types';
import styles from './modal-create-merchant-account.module.scss';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    NativeSelect,
    Input
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { FormItem, Label } from 'components/onboarding/onboarding-ui-components';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { ResellerType } from 'utils/enums/ResellerType';

function ModalCreateMerchantAccountComponent(props) {
    const {
        setOpen,
        onCreateMerchant,
        name,
        setName,
        error,
        country,
        isAdmin,
        setCountry,
        globalState,
        loading,
        disabled,
        isT2SInvitedUser,
        isDatManReseller,
        updateMerchantData,
        onUpdateMerchantName,
        merchantNameRef
    } = props;

    return (
        <Dialog
            open={true}
            classes={{
                paper: styles.paper
            }}
            onClose={() => setOpen(false)}
            aria-labelledby="draggable-dialog-title"
            disableBackdropClick={isT2SInvitedUser || isDatManReseller}
            disableEscapeKeyDown={isT2SInvitedUser || isDatManReseller}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {updateMerchantData ? 'Update merchant' : 'Create new merchant'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        id="textFieldName"
                        className={styles.textFieldName}
                        label="Name"
                        variant="outlined"
                        inputRef={merchantNameRef}
                        onChange={(e) => setName(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter' && name?.trim().length >= 3) onCreateMerchant();
                        }}
                        value={name}
                    />
                    {globalState.reseller?.name !== ResellerType.OMNIPAY && (!updateMerchantData || isAdmin) && (
                        <FormItem>
                            <Label text="Country" id="merchant-create-country-label"></Label>
                            <NativeSelect
                                className={styles.selectCountry}
                                input={
                                    <Input
                                        placeholder="Country"
                                        id="merchant-create-country-input"
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    ></Input>
                                }
                            >
                                {Object.keys(MerchantCountries).map((key) => (
                                    <option key={MerchantCountries[key]} value={MerchantCountries[key]}>
                                        {MerchantCountries[key]}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormItem>
                    )}
                </DialogContentText>
                {error && <Alert severity="error">{error}</Alert>}
            </DialogContent>
            <DialogActions>
                {!isT2SInvitedUser && (
                    <Button
                        id="cancelButtonCreateMerchant"
                        autoFocus
                        disabled={disabled}
                        onClick={() => setOpen(false)}
                        color="primary"
                        className={styles.cancelBtn}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    id="createButtonCreateMerchant"
                    disabled={disabled || name?.trim().length < 3}
                    onClick={() => (updateMerchantData ? onUpdateMerchantName() : onCreateMerchant())}
                    color="primary"
                    className={styles.nextButton}
                >
                    {updateMerchantData ? 'Update' : 'Next'}
                </Button>
            </DialogActions>
            <LoadingScreen open={loading} />
        </Dialog>
    );
}

ModalCreateMerchantAccountComponent.propTypes = {
    setOpen: PropTypes.func,
    onCreateMerchant: PropTypes.func,
    name: PropTypes.string,
    setName: PropTypes.func,
    error: PropTypes.string,
    country: PropTypes.string,
    setCountry: PropTypes.func,
    globalState: PropTypes.object,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    isT2SInvitedUser: PropTypes.bool
};

export default ModalCreateMerchantAccountComponent;
