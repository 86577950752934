import React, { useState } from 'react';
import { useGlobalStateContext } from 'contexts';
import ModalReasonToCloseComponent from './modal-reason-to-close.component';
import { DocumentsService } from 'services';

function ModalReasonToClose(props) {
    const {
        setShowConfirmationModal,
        showConfirmationModal,
        id,
        CloseSuccess,
        setCloseSuccess,
        setPreviewQrDetails
    } = props;
    const { globalState } = useGlobalStateContext();
    const [showMessage, setShowMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState('');

    const handleSubmit = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        const response = await DocumentsService.closeQrCode(globalState.merchant?.id, id, reason.trim());
        if (response.isSuccesfully) {
            setPreviewQrDetails(response.data.qrCode);
            setCloseSuccess(true);
            setShowMessage('Successfully closed QR code');
            setShowConfirmationModal(false);
            setReason('');
        } else {
            setShowMessage(response?.data?.data?.errorMessage);
        }
    };
    const handleCancel = async (event) => {
        setShowConfirmationModal(false);
        setReason('');
    };
    return (
        <ModalReasonToCloseComponent
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            handleSubmit={handleSubmit}
            showMessage={showMessage}
            isLoading={isLoading}
            CloseSuccess={CloseSuccess}
            setShowMessage={setShowMessage}
            handleCancel={handleCancel}
            reason={reason}
            setReason={setReason}
        />
    );
}
ModalReasonToClose.propTypes = {};
export default ModalReasonToClose;
