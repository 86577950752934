import React, { useEffect, useState } from 'react';
import PayoutsListComponent from './payouts-list.component.jsx';
import { Roles } from '../../../utils/enums/Roles';
import { PayoutsService } from 'services';
import { useGlobalStateContext, useBalanceContext } from 'contexts';
import PropTypes from 'prop-types';
import { AcquirersToAcquirerName, ReverseAcquirersToAcquirerName } from 'utils/enums/Acquirers';

const PayoutsList = (props) => {
    const [error, setError] = useState(false);
    const [payouts, setPayouts] = useState([]);
    const [notInBatchPayouts, setNotInBatchPayouts] = useState([]);
    const [allPayouts, setAllPayouts] = useState([]);
    const [allNotInBatchPayouts, setAllNotInBatchPayouts] = useState([]);
    const [total, setTotal] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const { globalState } = useGlobalStateContext();
    const { balance } = useBalanceContext();
    const { date, via } = props.match.params;
    const { notReceived, loading, setLoading, selectedProvider } = props;
    const [page, setPage] = useState(0);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        const getPayoutsList = async () => {
            const merchantId = globalState.merchant?.id;
            const payoutsList = await PayoutsService.getAllPayouts(merchantId, {
                selectedDate: date
            });

            if (payoutsList.isSuccesfully) {
                if (notReceived) {
                    const inBatchList = [...payoutsList.data.transaction_data.in_batch];
                    let notReceivedInBatchList = inBatchList.filter((item) => {
                        return item.not_received;
                    });
                    filterPayouts(selectedProvider, notReceivedInBatchList);
                    setAllPayouts(notReceivedInBatchList);
                } else {
                    filterPayouts(
                        selectedProvider,
                        payoutsList.data.transaction_data.in_batch,
                        payoutsList.data.transaction_data.not_in_batch
                    );
                    setAllPayouts(payoutsList.data.transaction_data.in_batch);

                    if (payoutsList.data.transaction_data.not_in_batch.length !== 0) {
                        filterPayouts(
                            selectedProvider,
                            payoutsList.data.transaction_data.in_batch,
                            payoutsList.data.transaction_data.not_in_batch
                        );
                        setAllNotInBatchPayouts(payoutsList.data.transaction_data.not_in_batch);
                    } else {
                        setNotInBatchPayouts([]);
                        setAllNotInBatchPayouts([]);
                    }
                }
                setTotal(payoutsList.data.transaction_data.not_in_batch.total);
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
        };

        if (globalState.merchant?.hasOwnProperty('userRole')) {
            setIsOwner(globalState.merchant?.userRole === Roles.Owner);
        }

        setLoading(true);
        setPage(0);

        getPayoutsList();
        // eslint-disable-next-line
    }, [props.location.pathname, date, via, globalState.merchant, notReceived, balance, refresh]);

    const filterPayouts = (selectedProvider, inBatchList = [], notInBatchList = []) => {
        if (selectedProvider !== AcquirersToAcquirerName.ALL) {
            const filterdInBatch = inBatchList.filter((payout) => {
                return payout.provider === ReverseAcquirersToAcquirerName[selectedProvider];
            });
            const filterdNotInBatch = notInBatchList.filter((payout) => {
                return payout.provider === ReverseAcquirersToAcquirerName[selectedProvider];
            });
            setPayouts(filterdInBatch);
            setNotInBatchPayouts(filterdNotInBatch);
        } else {
            setPayouts(inBatchList);
            setNotInBatchPayouts(notInBatchList);
        }
    };

    useEffect(() => {
        filterPayouts(selectedProvider, allPayouts, allNotInBatchPayouts);
        // eslint-disable-next-line
    }, [selectedProvider]);

    return (
        <PayoutsListComponent
            {...props}
            loading={loading}
            date={date}
            error={error}
            payouts={payouts}
            notInBatchPayouts={notInBatchPayouts}
            total={total}
            isOwner={isOwner}
            page={page}
            setPage={setPage}
            selectedProvider={selectedProvider}
            refresh={refresh}
            setRefresh={setRefresh}
        />
    );
};

export default PayoutsList;

PayoutsList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string.isRequired,
            via: PropTypes.string.isRequired
        })
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    })
};
