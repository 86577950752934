import React from 'react';
import MerchantDashboardPreviewComponent from './merchant-dashboard-preview.component';
import { useGlobalStateContext } from 'contexts';

function MerchantDashboardPreview() {
    const { globalState } = useGlobalStateContext();

    return (
        <MerchantDashboardPreviewComponent
            userName={globalState.user?.firstName}
            isEmailConfirmed={globalState.user?.isEmailConfirmed}
        />
    );
}

MerchantDashboardPreview.propTypes = {};

export default MerchantDashboardPreview;
