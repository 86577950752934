import { useGlobalStateContext } from 'contexts';
import React, { useState, useEffect } from 'react';
import SettingTermsAndConditionsComponent from './settings-terms-and-conditions.component';
import { NonResellerMerchantService, TermsAndConditionsService } from 'services';
import { UserType } from 'utils/enums/UserType';

function SettingTermsAndConditionsContainer(props) {
    const { isModal, setOpen, modalTermsAndConditions } = props;

    const { globalState, setGlobalState } = useGlobalStateContext();
    const [termsAndConditionsList, setTermsAndConditionsList] = useState([]);
    const [termsAncConditionAccepted, setTermsAndConditionAccepted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [signedOnDate, setSignedOnDate] = useState('');
    const [entity, setEntity] = useState('');
    const [entityId, setEntityId] = useState('');
    const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState([]);
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;

    useEffect(() => {
        const fetchMerchantTermsAndConditionData = async () => {
            setEntity('merchant');
            setEntityId(globalState.merchant?.id);
            setIsLoading(true);
            const response = await NonResellerMerchantService.getTermsAndConditionsForMerchant(
                globalState.merchant?.id,
                modalTermsAndConditions
            );

            if (response.isSuccesfully) {
                setTermsAndConditionsList(response.data);
            }
            setIsLoading(false);
            setSignedOnDate(globalState.merchant?.signedTermsAndConditionsDate);
        };

        const fetchCanonicalResellerTermsAndConditionData = () => {
            setEntity('canonical_reseller');
            setEntityId(globalState.canonicalReseller?.id);
            setIsLoading(true);
            const canonicalResellerTermsAndConditionDocument =
                globalState.canonicalReseller?.termsAndConditionInfo.termsAncConditionDocument;
            const signedOn =
                globalState.canonicalReseller?.termsAndConditionInfo.signingInfo.signedTermsAndConditionsDate;
            setTermsAndConditionsList(canonicalResellerTermsAndConditionDocument);
            setSignedOnDate(signedOn);
            setIsLoading(false);
        };

        if (globalState.merchant?.id) {
            fetchMerchantTermsAndConditionData();
        } else if (globalState.canonicalReseller?.id && isCanonicalResellerUser) {
            fetchCanonicalResellerTermsAndConditionData();
        }
        // eslint-disable-next-line
    }, [globalState.merchant, globalState.canonicalReseller]);

    const onSelectTermsAndConditions = (e) => {
        let updatedList = [...selectedTermsAndConditions];

        if (e.target.checked) {
            updatedList = [...selectedTermsAndConditions, e.target.value];
        } else {
            updatedList.splice(selectedTermsAndConditions.indexOf(e.target.value), 1);
        }
        setSelectedTermsAndConditions(updatedList);
    };

    const submitHandler = async (selectedDocumentIds) => {
        setIsLoading(true);
        const data = { selectedDocumentIds: selectedDocumentIds };
        const response = await TermsAndConditionsService.acceptTermsAndConditions(entity, entityId, data);
        if (response.isSuccesfully) {
            setSignedOnDate(response.data.signedOn);
            const noOfNotSignedTermsAndConditions = termsAndConditionsList.length - selectedTermsAndConditions.length;
            if (isCanonicalResellerUser) {
                const signingInfo = globalState.canonicalReseller?.termsAndConditionInfo.signingInfo;
                const canonicalReseller = sessionStorage.getItem('canonicalResellers');
                let canonicalResellerInfo = JSON.parse(canonicalReseller)[0];
                const canonicalResellerSigningInfo = {
                    ...canonicalResellerInfo.termsAndConditionInfo.signingInfo,
                    signedTermsAndConditionsDate: response.data.signedOn,
                    noOfSignedTermsAndConditions: signingInfo.noOfNotSignedTermsAndConditions,
                    noOfNotSignedTermsAndConditions: noOfNotSignedTermsAndConditions
                };
                canonicalResellerInfo = {
                    ...canonicalResellerInfo,
                    termsAndConditionInfo: {
                        ...canonicalResellerInfo.termsAndConditionInfo,
                        signingInfo: canonicalResellerSigningInfo
                    }
                };
                setGlobalState((globalState) => ({
                    ...globalState,
                    canonicalReseller: canonicalResellerInfo
                }));
                sessionStorage.setItem('canonicalResellers', JSON.stringify(canonicalResellerInfo));
            } else {
                setGlobalState((globalState) => ({
                    ...globalState,
                    merchant: {
                        ...globalState.merchant,
                        noOfSignedTermsAndConditions: globalState.merchant.noOfNotSignedTermsAndConditions,
                        noOfNotSignedTermsAndConditions: noOfNotSignedTermsAndConditions
                    }
                }));
            }

            if (noOfNotSignedTermsAndConditions === 0 && isModal) {
                setOpen(false);
            }
            setSelectedTermsAndConditions([]);
        }
        setIsLoading(false);
    };

    return (
        <SettingTermsAndConditionsComponent
            isModal={isModal}
            termsAndConditionsList={termsAndConditionsList}
            isLoading={isLoading}
            termsAncConditionAccepted={termsAncConditionAccepted}
            setTermsAndConditionAccepted={setTermsAndConditionAccepted}
            submitHandler={submitHandler}
            signedOnDate={signedOnDate}
            isCanonicalResellerUser={isCanonicalResellerUser}
            selectedTermsAndConditions={selectedTermsAndConditions}
            setSelectedTermsAndConditions={setSelectedTermsAndConditions}
            onSelectTermsAndConditions={onSelectTermsAndConditions}
            modalTermsAndConditions={modalTermsAndConditions}
        />
    );
}

export default SettingTermsAndConditionsContainer;
