import React, { useState, useEffect } from 'react';
import T2SOnboardingAdressStepComponent from './t2s-onboarding-address.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService, AuthService } from 'services';
import { BusinessTypeEnumId } from '../../../utils/enums/BusinessType';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { formTypes } from 'utils/enums/FormTypes';
import { isTokenGoingToExpire } from 'utils/helper';

function T2SOnboardingAdressStep(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isFormValid, setIsFormValid] = useState(true);
    const { globalState } = useGlobalStateContext();
    const [isLimited, setIsLimited] = useState(false);
    const [isSoleTrader, setIsSoleTrader] = useState(false);
    const isT2SMerchant = globalState.isT2SMerchant;
    const isMexicanmerchant = globalState.merchant?.country === MerchantCountries.MEXICO;

    const [csrfToken, setCSRFToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(
            formTypes.ONBOARDING_TRADING_ADDRESS,
            globalState.merchant?.id
        );
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const checkDisabled = async () => {
            const { postCode, addressLine1, state, city, country } = onboardingForm.tradingAddress;
            const { tradingName, businessTypeId } = onboardingForm.businessDetails;
            let checkValues;
            if (globalState.merchant.country === MerchantCountries.MEXICO) {
                checkValues = postCode && addressLine1 && state && city && tradingName;
                setIsFormValid(checkValues);
            } else {
                if (BusinessTypeEnumId.Limited === parseInt(businessTypeId)) {
                    checkValues = postCode && addressLine1 && city && country && tradingName;
                    setIsFormValid(checkValues);
                } else {
                    checkValues = postCode && addressLine1 && city && country;
                    setIsFormValid(checkValues);
                }
            }

            // This useState will be true if the businessType is "Limited", "Limited/LLC", "Corporation"
            if (
                BusinessTypeEnumId.Limited === parseInt(businessTypeId) ||
                BusinessTypeEnumId.Limited_LLC === parseInt(businessTypeId) ||
                BusinessTypeEnumId.Corporation === parseInt(businessTypeId)
            ) {
                setIsLimited(true);
            }
            if (BusinessTypeEnumId.Sole_Trader === parseInt(businessTypeId)) {
                setIsSoleTrader(true);
            }
        };

        checkDisabled();
    }, [
        onboardingForm.tradingAddress,
        globalState.merchant.country,
        isT2SMerchant,
        onboardingForm.businessDetails,
        setIsLimited
    ]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                tradingAddress: isFormValid ? true : false
            }
        }));
    }, [isFormValid, setOnboardingForm]);

    const selectAddressData = (addressData) => {
        if (addressData) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    addressLine1: addressData.line_1,
                    addressLine2: addressData.line_2,
                    city: addressData.town_or_city,
                    country: addressData.country,
                    postCode: addressData.postCode
                }
            }));
        }
    };

    const onChangeSameAddress = (checked) => {
        if (checked) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    addressLine1: onboardingForm.nameAndAddress.addressLine1,
                    addressLine2: onboardingForm.nameAndAddress.addressLine2,
                    postCode: onboardingForm.nameAndAddress.postCode,
                    city: onboardingForm.nameAndAddress.city,
                    state: onboardingForm.nameAndAddress.state,
                    country: onboardingForm.nameAndAddress.country,
                    isSameAsBusinessAddress: checked
                }
            }));
        } else {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    addressLine1: '',
                    addressLine2: '',
                    postCode: '',
                    state: '',
                    city: '',
                    country: '',
                    isSameAsBusinessAddress: checked
                }
            }));
        }
    };

    const onSubmit = async () => {
        props.nextStep();
        const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
        let latestCSRFToken = csrfToken;
        if (isCSRFGoingToExpire) {
            latestCSRFToken = await generateCSRFToken();
        }
        const status = true;
        const data = {
            ...onboardingForm.tradingAddress,
            tradingName: onboardingForm.businessDetails.tradingName,
            status: status,
            formType: formTypes.ONBOARDING_TRADING_ADDRESS
        };
        if (isMexicanmerchant) {
            data.country = MerchantCountries.MEXICO;
        }

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 3, data, latestCSRFToken);
    };

    return (
        <T2SOnboardingAdressStepComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isFormValid={isFormValid}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            isLimited={isLimited}
            isSoleTrader={isSoleTrader}
            onChangeSameAddress={onChangeSameAddress}
            isLoading={isLoading}
        />
    );
}

export default T2SOnboardingAdressStep;
