import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import styles from './help-contacts.module.scss';
import { useGlobalStateContext } from 'contexts';

function HelpContactsComponent() {
    const { setRoute, globalState } = useGlobalStateContext();

    return (
        <Grid container direction="column" alignItems="center" justify="center" className={styles.container}>
            <img
                className={styles.logo}
                src={globalState.reseller?.logo}
                alt="OmniPay Logo"
                onClick={() => {
                    setRoute('/home');
                }}
                width="700"
            />
            <Typography variant="h6">
                For support, please call: <span variant="h2">020810 33846</span>&nbsp;(UK)
            </Typography>
            {globalState.reseller?.id === 1 ? (
                <a href=" https://support.omni-pay.com/portal/en/kb" rel="noopener noreferrer" target="_blank">
                    https://support.omni-pay.com/portal/en/kb
                </a>
            ) : null}
        </Grid>
    );
}

export default HelpContactsComponent;
