import React, { useEffect, useState } from 'react';
import StripePayoutDetailsComponent from './stripe-payout-details.component';
import { PayoutsService } from 'services';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';

export default function StripePayoutDetailsContainer(props) {
    const { globalState } = useGlobalStateContext();
    const { batchId, payouts } = props;
    const [overViewFee, setOverViewFee] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const date = props.location.state.payouts.created;
    useEffect(() => {
        const stripePayoutTransactions = async () => {
            const transactionsList = await PayoutsService.getStripePayoutTransactions({
                payoutId: payouts.id,
                merchantId: globalState.merchant?.id
            });
            const transactionData = transactionsList.data.data;
            if (transactionData) {
                setTransactions(transactionData);
                let result = _.find(transactionData, function (element) {
                    if (element.type === 'payout') {
                        return true;
                    }
                });
                setOverViewFee(result.fee);
            } else {
                setOverViewFee(0);
            }
            setLoading(false);
        };
        setLoading(true);
        stripePayoutTransactions();
    }, [payouts, globalState.merchant.id]);

    return (
        <StripePayoutDetailsComponent
            batchId={batchId}
            payouts={payouts}
            overViewFee={overViewFee}
            transactions={transactions}
            loading={loading}
            date={date}
        />
    );
}

StripePayoutDetailsContainer.propTypes = {
    payouts: PropTypes.object
};
