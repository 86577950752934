import React, { useState } from 'react';

import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableFooter,
    TablePagination,
    Button
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import TransactionStatusLabel from '../transaction-status-label/transaction-status-label.container';
import TableHeader from '../table-header/table-header.container';
import { formatDateTimeByFormatString, getComparator, stableSort, formatCurrency } from '../../../../utils/helper';
import styles from './internal-transfers-list-table.module.scss';
import PropTypes from 'prop-types';
import TableFooterComponent from '../table-footer/table-footer.component';
import { useGlobalStateContext } from 'contexts';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { ModalCancelInternalTransfer, ModalRefundInternalTransfer } from 'components';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        'border-top': '1px solid #ddd',
        'margin-top': '10px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    }
}));

export default function InternalTransfersListTableComponent(props) {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const {
        rows,
        selected,
        isSelected,
        order,
        orderBy,
        loading,
        error,
        transferType,
        showCancelTransaction,
        setRefresh,
        showRefundTransaction,
        page,
        setPage
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={styles.paymentsNone}>There aren't any results for that query.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader
                                            classes={classes}
                                            numSelected={selected.length}
                                            rowCount={rows.length}
                                            hideSelectAll={true}
                                            {...props}
                                        />
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator(order, orderBy)
                                            ).map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        classes={{ selected: classes.selected }}
                                                        className={classes.tableRow}
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                    >
                                                        <TableCell component="th" id={labelId} scope="row">
                                                            {formatCurrency(
                                                                row.amount,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">{row.supplier}</TableCell>
                                                        <TableCell align="left">
                                                            {formatDateTimeByFormatString(
                                                                row.created_at,
                                                                'DD MMM HH:mm'
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">{row.description}</TableCell>
                                                        <TableCell align="left">
                                                            <TransactionStatusLabel status={row.status} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {transferType === 'sender' && row.status === 'PENDING' ? (
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        showCancelTransaction(row);
                                                                        props.setShowCancelModel(true);
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            ) : null}
                                                            {transferType === 'supplier' && row.status === 'COMPLETE' && (
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        showRefundTransaction(row);
                                                                        props.setShowRefundModel(true);
                                                                    }}
                                                                >
                                                                    <i className="fe fe-rotate-ccw"></i> Refund
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {rows.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                                        colSpan={6}
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                    <TableFooterComponent total={props.total} resultsCount={rows.length} />
                                </TableContainer>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                {props.showCancelModel && (
                    <ModalCancelInternalTransfer
                        showCancelModel={props.showCancelModel}
                        setShowCancelModel={props.setShowCancelModel}
                        supplier={props.supplier}
                        transactionId={props.transactionId}
                        amount={props.amount}
                        setRefresh={setRefresh}
                    />
                )}
                {props.showRefundModel && (
                    <ModalRefundInternalTransfer
                        showRefundModel={props.showRefundModel}
                        setShowRefundModel={props.setShowRefundModel}
                        sender={props.sender}
                        transactionId={props.transactionId}
                        amount={props.amount}
                    />
                )}
            </Paper>
        </div>
    );
}

InternalTransfersListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    isSelected: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    transferType: PropTypes.string.isRequired,
    showCancelTransaction: PropTypes.func,
    setRefresh: PropTypes.func,
    showRefundTransaction: PropTypes.func
};
