import React, { useState, useEffect } from 'react';
import GenericQrCodeInfoComponent from './generic-qr-code-info.component';
import { DocumentsService } from 'services';
import { useGlobalStateContext } from 'contexts';
import Axios from 'axios';
import { QrFields } from 'utils/enums/QrCode';

const GenericQrCodeInfo = (props) => {
    const [qrCodeData, setQrCodeData] = useState([]);
    const [qrDetails, setQrDetails] = useState({ qrCodeExpiryDate: null });
    const { globalState } = useGlobalStateContext();
    const [showCreteQr, setShowCreateQr] = useState(false);
    const [selectPaymentQr, setSelectPaymentQr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [previewQrDetails, setPreviewQrDetails] = useState({});
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [success, setSuccess] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [searchInput, setSearchInput] = useState('');
    const [showQrCode, setShowQrCode] = useState(false);
    const [CloseSuccess, setCloseSuccess] = useState(false);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);

    const headCells = [
        { id: 'id', numeric: true, disablePadding: false, label: QrFields.qrID },
        { id: 'qrName', numeric: false, disablePadding: false, label: QrFields.qrName },
        { id: 'description', numeric: false, disablePadding: false, label: QrFields.qrDescription },
        { id: 'created_at', numeric: false, disablePadding: false, label: QrFields.createdAt },
        { id: 'status', numeric: false, disablePadding: false, label: QrFields.qrStatus, hideSortingIcon: true },
        { id: 'action', numeric: false, disablePadding: false, label: 'Action', hideSortingIcon: true }
    ];

    const fetchQrData = async (newPage, newRowsPerPage, serchValue, filterValue) => {
        setLoading(true);
        const thePage = newPage ?? page;
        const searchString = serchValue ?? searchInput;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const statusFilterValue = filterValue ? filterValue : statusFilter;
        const result = await DocumentsService.fetchQrCodes(globalState.merchant?.id, {
            searchValue: searchString,
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,
            statusFilter: statusFilterValue === 'all' ? '' : statusFilterValue
        });
        if (result.isSuccesfully) {
            setQrCodeData([...result.data.qrCodeResponse]);
            setCount(result.data.count);
        }
        setLoading(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const previewData = (row) => {
        setPreviewQrDetails({
            ...row,
            amount: row.amount
        });
        setIsPaneOpen(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchQrData(newPage, rowsPerPage);
    };

    const download = async (qrImgLink, id) => {
        try {
            const imgResponse = await Axios.get(qrImgLink, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([imgResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `QRCode_${id}.png`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {}
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        fetchQrData(0, parseInt(event.target.value, 10));
        setPage(0);
    };
    const clearBtn = () => {
        setSearchInput('');
        setStatusFilter('all');
        fetchQrData(0, null, 'no', 'all');
    };

    const handleOnClick = (row) => {
        return row.status === 'Active' && (setShowQrCode(true), previewData(row), setIsPaneOpen(false));
    };

    useEffect(() => {
        fetchQrData(0);
        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    useEffect(() => {
        const updatedIndex = qrCodeData.findIndex((qrCode) => qrCode.id === previewQrDetails.id);
        const existingQrCode = qrCodeData.filter((qrCode) => qrCode.id === previewQrDetails.id)[0];
        if (JSON.stringify(existingQrCode) !== JSON.stringify(previewQrDetails)) {
            const tempQrData = [...qrCodeData];
            tempQrData[updatedIndex] = previewQrDetails;
            setQrCodeData([...tempQrData]);
        }
        // eslint-disable-next-line
    }, [previewQrDetails]);

    const onSearchQRCodes = (e) => {
        setPage(0);
        fetchQrData(0);
        e.preventDefault();
    };

    const filterQrCodes = (e) => {
        setPage(0);
        fetchQrData(0, null, searchInput, e.target.value);
        e.preventDefault();
    };

    const handleCopyUrl = (row) => {
        navigator.clipboard.writeText(row);
        setIsCopyAlertOpen(true);
    };

    return (
        <GenericQrCodeInfoComponent
            {...props}
            showCreteQr={showCreteQr}
            setShowCreateQr={setShowCreateQr}
            selectPaymentQr={selectPaymentQr}
            setSelectPaymentQr={setSelectPaymentQr}
            handleRequestSort={handleRequestSort}
            setQrCodeData={setQrCodeData}
            qrCodeData={qrCodeData}
            order={order}
            orderBy={orderBy}
            isPaneOpen={isPaneOpen}
            setIsPaneOpen={setIsPaneOpen}
            loading={loading}
            setPreviewQrDetails={setPreviewQrDetails}
            previewQrDetails={previewQrDetails}
            count={count}
            setCount={setCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            setQrDetails={setQrDetails}
            qrDetails={qrDetails}
            setSuccess={setSuccess}
            success={success}
            setShowMessage={setShowMessage}
            showMessage={showMessage}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            setSearchInput={setSearchInput}
            clearBtn={clearBtn}
            searchInput={searchInput}
            previewData={previewData}
            showQrCode={showQrCode}
            setShowQrCode={setShowQrCode}
            download={download}
            CloseSuccess={CloseSuccess}
            setCloseSuccess={setCloseSuccess}
            handleOnClick={handleOnClick}
            headCells={headCells}
            setPage={setPage}
            onSearchQRCodes={onSearchQRCodes}
            filterQrCodes={filterQrCodes}
            handleCopyUrl={handleCopyUrl}
            isCopyAlertOpen={isCopyAlertOpen}
            setIsCopyAlertOpen={setIsCopyAlertOpen}
        />
    );
};

export default GenericQrCodeInfo;
