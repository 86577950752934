import React, { useState, useReducer, useEffect } from 'react';
import ModalInvoiceComponent from './modal-invoice-form.component.jsx';
import { VirtualTerminalService } from 'services';
import { getCurrencySymbol, getCurrencyCode } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import { InputValidators } from 'validators';
import moment from 'moment';

export default function ModalInvoice(props) {
    const { globalState } = useGlobalStateContext();
    const {
        amount,
        description,
        showPayByInvoiceModal,
        userEmail,
        setUserEmail,
        setAmount,
        setDescription,
        setShow
    } = props;
    const initialFormFields = {
        email: userEmail,
        phone: '',
        firstName: '',
        lastName: '',
        quantity: 1,
        item: '',
        //expected default date to be 28 days by default
        dateOfExpiry: moment().add(28, 'days').format('YYYY-MM-DD'),
        supplyDate: moment().format('YYYY-MM-DD'),
        emailError: '',
        phoneError: ''
    };

    const [formFields, setFormFields] = useReducer(
        (curFields, newFields) => ({ ...curFields, ...newFields }),
        initialFormFields
    );

    function handleInvoiceFieldsChange(field, value) {
        setFormFields({ [field]: value });
    }

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [emailValidationMessage, setEmailValidationMessage] = useState('');

    useEffect(() => {
        const dateOfExpiry = formFields.dateOfExpiry;
        const supplyDate = formFields.supplyDate;
        if (moment(dateOfExpiry).endOf('d').isBefore(new moment(supplyDate).endOf('d'))) {
            handleInvoiceFieldsChange('supplyDate', dateOfExpiry);
        }
    }, [formFields.dateOfExpiry, formFields.supplyDate]);

    const handleClose = () => {
        if (!loading) {
            setShow(false);
            if (success) {
                setAmount('');
                setDescription('');
                setUserEmail('');
            }
        }
    };

    const isFormInvalid = () => {
        let isError = false;
        const { email, phone, dateOfExpiry, supplyDate } = formFields;
        if (email && !InputValidators.isEmailValid(email)) {
            handleInvoiceFieldsChange('emailError', 'provide valid email');
            isError = true;
        }
        if (phone && !InputValidators.validateInternationPhoneNumber('+' + phone)) {
            handleInvoiceFieldsChange('phoneError', 'provide valid phone number');
            isError = true;
        }
        if (!(email || phone)) {
            handleInvoiceFieldsChange('emailError', 'Please provide valid email');
            isError = true;
        }
        if (moment(supplyDate).endOf('D').isAfter(new moment(dateOfExpiry).endOf('D'))) {
            isError = true;
        }
        if (isError) {
            setTimeout(() => {
                setFormFields({
                    emailError: '',
                    phoneError: ''
                });
            }, 3000);
        }
        return isError;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!isFormInvalid()) {
            const { email, phone, firstName, lastName, quantity, item, dateOfExpiry, supplyDate } = formFields;
            const data = {
                amount: amount,
                description: description.trim(),
                cxInfo: {
                    email,
                    phone,
                    firstName,
                    lastName,
                    item,
                    quantity,
                    dateOfExpiry: moment(dateOfExpiry).format('YYYY-MM-DD'),
                    supplyDate: moment(supplyDate).format('YYYY-MM-DD')
                },
                receiptEmail: userEmail,
                portalURL: globalState.reseller?.portalURL,
                merchantName: globalState.merchant?.name,
                merchantId: globalState.merchant?.id,
                currencySymbol: getCurrencySymbol(globalState.merchant?.country),
                currencyCode: getCurrencyCode(globalState.merchant?.country)
            };
            let result = await VirtualTerminalService.sendInvoiceViaEmailPhone(data);
            setSuccess(result.isSuccesfully);
            setMessage(result.data);
            setLoading(false);
            if (!result.isSuccesfully) {
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            }
        } else {
            setLoading(false);
        }
    };

    return (
        <ModalInvoiceComponent
            {...props}
            formFields={formFields}
            handleInvoiceFieldsChange={handleInvoiceFieldsChange}
            loading={loading}
            success={success}
            message={message}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            emailValidationMessage={emailValidationMessage}
            setEmailValidationMessage={setEmailValidationMessage}
            showPayByInvoiceModal={showPayByInvoiceModal}
        />
    );
}
