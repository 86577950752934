import React from 'react';
import { Grid, Select, MenuItem, FormControl, InputLabel, useMediaQuery } from '@material-ui/core';
import styles from './merchant-account-details.module.scss';
import { FormItem, Input, Label } from '../../../onboarding/onboarding-ui-components';
import PhoneInput from 'react-phone-input-2';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import BankSortCodeNumberContainer from 'components/bank-sort-code-number/bank-sort-code-number.container';
import { LoadingScreen } from 'components';
import { CheckMerchantCountries } from '../../../onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import ReactCodeInput from 'react-verification-code-input';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
import _ from 'lodash';

const MerchantAccountDetailsComponent = (props) => {
    const {
        businessDetails,
        ownerDetails,
        selectedValue,
        showBusinessDetails,
        setSelectedValue,
        setShowBusinessDetails,
        bankData,
        isLoading,
        finalVal
    } = props;

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const mediaLessThan750px = useMediaQuery('(max-width:749px)');
    const mediaLessThan400px = useMediaQuery('(max-width:399px)');

    return (
        <Grid className={styles.MerchantDetailsContainer}>
            <Grid className={styles.title}>
                <FormControl variant="outlined" className={styles.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label" className={styles.inputlabel}>
                        Merchant details
                    </InputLabel>
                    <Select
                        className={styles.selectMerchantDetails}
                        inputProps={{ className: styles.subTitle }}
                        disableUnderline
                        id="merchant-details-selection"
                        label="Merchant details"
                        value={selectedValue}
                        onChange={(e) => {
                            setShowBusinessDetails(!showBusinessDetails);
                            setSelectedValue(e.target.value);
                        }}
                    >
                        <MenuItem value="Business details">Business details</MenuItem>
                        <MenuItem value="Owner details">Owner details</MenuItem>
                        <MenuItem value="Bank details">Bank details</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {selectedValue === 'Business details' && (
                <Grid className={styles.businessDetailContainer}>
                    <FormItem>
                        <Label text="Legal name" id="legal-name"></Label>
                        <Input
                            disabled
                            placeholder="Legal name"
                            id="legal-name-input"
                            value={businessDetails?.legalName}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="Post code" id="post-code"></Label>
                        <Input disabled value={checkEmptyValue(businessDetails?.postCode)} />
                    </FormItem>
                    <FormItem>
                        <Label text="Address line 1" id="address-line-1"></Label>
                        <Input
                            disabled
                            placeholder="Address line 1"
                            id="address-line-1-input"
                            value={businessDetails?.addressLine1}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="Address line 2" id="address-line-2"></Label>
                        <Input
                            disabled
                            placeholder="Address line 2"
                            id="address-line-2-input"
                            value={businessDetails?.addressLine2}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="City" id="city"></Label>
                        <Input disabled placeholder="City" id="city-input" value={businessDetails?.city} />
                    </FormItem>
                    <FormItem>
                        <Label text="Telephone number" id="telephone-number"></Label>
                        <PhoneInput
                            country={'gb'}
                            disabled
                            id="telephone-number-input"
                            placeholder="Telephone number"
                            value={checkEmptyValue(businessDetails?.telephoneNumber)}
                            alwaysDefaultMask={true}
                            countryCodeEditable={false}
                            inputClass={styles.phoneInput}
                            name="phone"
                        />
                    </FormItem>
                </Grid>
            )}
            {selectedValue === 'Owner details' && (
                <Grid className={styles.ownerDetailsContainer}>
                    <FormItem>
                        <Label text="Full name" id="full-name"></Label>
                        <Input disabled placeholder="Full name" id="full-name-input" value={ownerDetails?.fullName} />
                    </FormItem>
                    <FormItem>
                        <Label text="Post code" id="owner-post-code"></Label>
                        <Input disabled value={checkEmptyValue(ownerDetails?.postCode)} />
                    </FormItem>
                    <FormItem>
                        <Label text="Address line 1" id="owner-address-line-1"></Label>
                        <Input
                            disabled
                            placeholder="Address line 1"
                            id="owner-address-line-1-input"
                            value={ownerDetails?.addressLine1}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="Address line 2" id="owner-address-line-2"></Label>
                        <Input
                            disabled
                            placeholder="Address line 2"
                            id="owner-address-line-2-input"
                            value={ownerDetails?.addressLine2}
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="City" id="owner-city"></Label>
                        <Input disabled placeholder="City" id="owner-city-input" value={ownerDetails?.city} />
                    </FormItem>
                    <FormItem>
                        <Label text="Telephone number" id="owner-telephone-number"></Label>
                        <PhoneInput
                            disabled
                            country={'gb'}
                            id="owner-telephone-number-input"
                            placeholder="Telephone number"
                            value={checkEmptyValue(ownerDetails?.telephoneNumber)}
                            alwaysDefaultMask={true}
                            countryCodeEditable={false}
                            inputClass={styles.phoneInput}
                            name="phone"
                        />
                    </FormItem>
                    <FormItem>
                        <Label text="Email" id="owner-email"></Label>
                        <Input disabled placeholder="Email" id="owner-email-input" value={ownerDetails?.email} />
                    </FormItem>
                </Grid>
            )}
            {selectedValue === 'Bank details' && (
                <Grid className={styles.bankDetails}>
                    {!_.isEmpty(bankData) && (
                        <React.Fragment>
                            <FormItem>
                                <Label text="Name on Account" id="name-on-account" />
                                <Input
                                    value={bankData.accountHolderName}
                                    disabled
                                    placeholder="Name on account"
                                    id="name-on-account"
                                />
                            </FormItem>
                            <CheckMerchantCountries
                                includedCountries={[
                                    MerchantCountries.CANADA,
                                    MerchantCountries.AUSTRALIA,
                                    MerchantCountries.UNITED_STATES,
                                    MerchantCountries.UNITED_KINGDOM,
                                    MerchantCountries.MEXICO
                                ]}
                            >
                                <FormItem>
                                    <Label text="Account number" id="account-number" />
                                    <Input
                                        value={bankData.newAccountNumber}
                                        disabled
                                        placeholder="Account number"
                                        id="account-number-input"
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries includedCountries={MerchantCountries.NEW_ZEALAND}>
                                <FormItem>
                                    <Label text="Account number" id="account-number" />
                                    <Input
                                        value={finalVal}
                                        disabled
                                        placeholder="Account number"
                                        id="account-number-input"
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                                <FormItem>
                                    <Label text="Bank IBAN" id="bank-IBAN-ireland-label" />
                                    <Input
                                        className={styles.codeInput + ' ' + styles.codeInputIreland}
                                        fieldHeight={
                                            (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px ? 22 : 25
                                        }
                                        fieldWidth={
                                            (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px ? 22 : 25
                                        }
                                        autoFocus={false}
                                        fields={22}
                                        type="text"
                                        disabled
                                        value={bankData.newAccountNumber}
                                    />
                                    <Grid></Grid>
                                </FormItem>
                            </CheckMerchantCountries>

                            <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                                <FormItem>
                                    <Label text="BSB" id="bsb-australia-label" />
                                    <ReactCodeInput
                                        values={getArrayFromString(bankData.bsb)}
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        fields={6}
                                        type="string"
                                        pattern="\d*"
                                        disabled
                                        style={{ backgroundColor: 'gainsboro' }}
                                        inputStyle={{ backgroundColor: 'gainsboro' }}
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                <FormItem>
                                    <Label text="Routing number" id="routing-number" />
                                    <ReactCodeInput
                                        values={bankData.routingNumber}
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34}
                                        fieldWidth={mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34}
                                        autoFocus={false}
                                        fields={9}
                                        type="string"
                                        pattern="\d*"
                                        disabled
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                                <FormItem>
                                    <Label text="Transit number" id="transit-number" />
                                    <ReactCodeInput
                                        values={bankData.transitNumber}
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        fields={5}
                                        type="string"
                                        pattern="\d*"
                                        disabled
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Financial institution number" id="financial-institution-number" />
                                    <ReactCodeInput
                                        values={bankData.financialInstitutionNumber}
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        fields={3}
                                        type="string"
                                        pattern="\d*"
                                        disabled
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                <FormItem>
                                    <Label text="Sort code" id="bank-sort-code" />
                                    <BankSortCodeNumberContainer
                                        hideDashes={mediaLessThan600px}
                                        values={bankData.sortCode}
                                        disabled={true}
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <FormItem>
                                <Label text="Name of bank" id="name-on-account" />
                                <Input
                                    value={bankData.nameOfBank}
                                    disabled
                                    placeholder="Name on account"
                                    id="name-on-account"
                                />
                            </FormItem>
                        </React.Fragment>
                    )}
                </Grid>
            )}
            <LoadingScreen open={isLoading}></LoadingScreen>
        </Grid>
    );
};

export default MerchantAccountDetailsComponent;
