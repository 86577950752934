import React from 'react';
import styles from './modal-user-management.module.scss';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { EntityType } from 'utils/enums/EntityType';

function ModalUserManagementComponent(props) {
    const {
        show,
        setShow,
        entityType,
        setEntityType,
        entities,
        setSelectedEntity,
        roles,
        setSelectedRole,
        onSubmit,
        error,
        setError,
        errorMessage,
    } = props;

    return (
        <Modal
            show={show}
            className={styles.modalUserManagement}
            dialogClassName={styles.modalDialog}
            onHide={() => setShow(false)}
            animation={false}
        >
            <Modal.Body className={styles.modalBody}>
                <div className={styles.title}>
                    <h3>Assign entity</h3>
                </div>
                <Form>
                    <Form.Group controlId="userManagerSelectEntityType">
                        <Form.Label>Entity type</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.pointer}
                            onChange={(e) => setEntityType(e.target.value)}
                        >
                            {Object.keys(EntityType).map((entityType) => (
                                <option key={entityType}>{EntityType[entityType]}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="userManagerSelectEntityName">
                        <Form.Label>{entityType}</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.pointer}
                            onChange={(e) => setSelectedEntity(e.target.value)}
                        >
                            {entities.map((entity) => (
                                <option key={entity.id} value={entity.id}>
                                    {entity.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="userManagerSelectRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                            as="select"
                            className={styles.pointer}
                            onChange={(e) => setSelectedRole(e.target.value)}
                        >
                            {roles.map((role) => (
                                <option key={role.id} value={role.id}>
                                    {role.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
                {error && (
                    <Alert variant="danger" onClose={() => setError(false)} dismissible>
                        {errorMessage}
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                <Button variant="info" onClick={onSubmit}>
                    Assign
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalUserManagementComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    entityType: PropTypes.string,
    setEntityType: PropTypes.func,
    entities: PropTypes.array,
    setSelectedEntity: PropTypes.func,
    roles: PropTypes.array,
    setSelectedRole: PropTypes.func,
    onSubmit: PropTypes.func,
    error: PropTypes.bool,
    setError: PropTypes.func,
    errorMessage: PropTypes.string,
};

export default ModalUserManagementComponent;
