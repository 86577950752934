import React from 'react';
import PropTypes from 'prop-types';
import styles from './onboarding-owner-details.module.scss';
import { Grid, NativeSelect, OutlinedInput } from '@material-ui/core';
import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem,
    SelectedDropDown
} from 'components/onboarding/onboarding-ui-components';
import { ValidEmailInput } from 'components';
import { emailValidationPattern } from 'utils/enums/EmailValidationPattern';
import { OnboardingOwnerPrimaryAddress } from 'components';
import { checkEmptyValue, getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
import { OwnerTitle } from 'utils/enums/OwnerTitle';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import ReactCodeInput from 'react-verification-code-input';
import { useMediaQuery } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
function OnboardingOwnerDetailsComponent(props) {
    const {
        nextStep,
        onboardingForm,
        setOnboardingForm,
        isDisabled,
        shouldNextStep,
        setShouldNextStep,
        onSubmit,
        dobValidationFailed,
        setPersonDetails,
        oneHundredValues,
        phoneNumberValidator,
        isT2SMerchant,
        dobInvalidFields,
        monthInputElementRef,
        yearInputElementRef,
        emailInputElementRef,
        dayChangeHandler,
        monthChangeHandler,
        yearChangeHandler
    } = props;

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');

    const title = (
        <OnboardingTitle title="Owner details" subTitle="There are your personal contact details"></OnboardingTitle>
    );

    function formBody() {
        return (
            <React.Fragment>
                <div className={styles.primaryOwner}>Primary owner</div>
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={3} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Title" id="owner-label-title"></Label>
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder="Title"
                                        id="owner-input-title"
                                        value={onboardingForm.ownersDetails.title}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                ownersDetails: {
                                                    ...onboardingForm.ownersDetails,
                                                    title: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                }
                            >
                                {Object.keys(OwnerTitle).map((key) => (
                                    <option key={OwnerTitle[key]} value={OwnerTitle[key]}>
                                        {OwnerTitle[key]}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormItem>
                    </Grid>
                    <Grid item xs={8} sm={9} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Full name" id="owner-label-fullname"></Label>
                            {onboardingForm.ownersDetails.persons.length === 0 ? (
                                <Input
                                    placeholder="Full name"
                                    id="owner-input-title"
                                    value={checkEmptyValue(onboardingForm.ownersDetails.fullName)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownersDetails: {
                                                ...onboardingForm.ownersDetails,
                                                fullName: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            ) : (
                                <NativeSelect
                                    input={
                                        <Input
                                            placeholder="Full name"
                                            id="owner-dropdown-title"
                                            value={onboardingForm.ownersDetails.fullName}
                                            onChange={(e) => setPersonDetails(e.target.value)}
                                        ></Input>
                                    }
                                >
                                    {onboardingForm.ownersDetails.persons.map((person, index) => (
                                        <option key={index} value={person.names.fullName}>
                                            {person.names.fullName}
                                        </option>
                                    ))}
                                </NativeSelect>
                            )}
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Nationality" id="owner-label-nationality"></Label>
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder="Nationality"
                                        id="owner-select-nationality"
                                        value={onboardingForm.ownersDetails.nationality}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                ownersDetails: {
                                                    ...onboardingForm.ownersDetails,
                                                    nationality: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                }
                                value={onboardingForm.ownersDetails.nationality}
                            >
                                {onboardingForm.nationalities.map((nationality) => (
                                    <option key={nationality} value={nationality}>
                                        {nationality}
                                    </option>
                                ))}
                            </NativeSelect>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Date of birth" id="owner-label-dateofbirth"></Label>
                            <div className={styles.dateOfBirthWrapper}>
                                <NumberFormat
                                    className={`${styles.numberFormatInput} ${
                                        dobValidationFailed || dobInvalidFields.includes('day')
                                            ? styles.containsErrorDOB
                                            : ''
                                    }`}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    allowLeadingZeros={true}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="DD"
                                    value={onboardingForm.ownersDetails.dateOfBirth?.day}
                                    id="date-of-birth-day-input"
                                    onValueChange={dayChangeHandler}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                />
                                <NumberFormat
                                    className={`${styles.numberFormatInput} ${
                                        dobValidationFailed || dobInvalidFields.includes('month')
                                            ? styles.containsErrorDOB
                                            : ''
                                    }`}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric', ref: monthInputElementRef }}
                                    pattern="\d*"
                                    allowLeadingZeros={true}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="MM"
                                    value={onboardingForm.ownersDetails.dateOfBirth?.month}
                                    id="date-of-birth-month-input"
                                    onValueChange={monthChangeHandler}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                />
                                <NumberFormat
                                    className={`${styles.numberFormatInput} ${
                                        dobValidationFailed || dobInvalidFields.includes('year')
                                            ? styles.containsErrorDOB
                                            : ''
                                    }`}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric', ref: yearInputElementRef }}
                                    pattern="\d*"
                                    allowLeadingZeros={true}
                                    decimalSeparator={false}
                                    allowNegative={false}
                                    isNumericString={true}
                                    placeholder="YYYY"
                                    value={onboardingForm.ownersDetails.dateOfBirth?.year}
                                    id="date-of-birth-year-input"
                                    onValueChange={yearChangeHandler}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                />
                            </div>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <ValidEmailInput
                            labelText="Email"
                            labelId="wner-label-email"
                            id="owner-input-email"
                            fullWidth={true}
                            type="email"
                            emailInputElementRef={emailInputElementRef}
                            pattern={emailValidationPattern.regexString}
                            value={checkEmptyValue(onboardingForm.ownersDetails.email)}
                            onChange={(e) => {
                                setOnboardingForm({
                                    ...onboardingForm,
                                    ownersDetails: {
                                        ...onboardingForm.ownersDetails,
                                        email: e.target.value.toLowerCase()
                                    }
                                });
                            }}
                        />
                    </Grid>
                    <CheckMerchantCountries excludedCountries={[MerchantCountries.AUSTRALIA]}>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Mobile number" id="owner-label-contacttel"></Label>

                                <PhoneInput
                                    country={'gb'}
                                    placeholder="Mobile number"
                                    id="owner-input-contacttel"
                                    value={checkEmptyValue(onboardingForm.ownersDetails.contactTel)}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(mobileNumber) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownersDetails: {
                                                ...onboardingForm.ownersDetails,
                                                contactTel: phoneNumberValidator(
                                                    mobileNumber,
                                                    onboardingForm.ownersDetails.contactTel
                                                )
                                            }
                                        })
                                    }
                                    inputClass={styles.phoneInput}
                                    name="Mobile Number"
                                />
                            </FormItem>
                        </Grid>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Mobile number" id="owner-label-contacttel"></Label>

                                <PhoneInput
                                    country={'au'}
                                    placeholder="Mobile number"
                                    id="owner-input-contacttel"
                                    value={checkEmptyValue(onboardingForm.ownersDetails.contactTel)}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(mobileNumber) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            ownersDetails: {
                                                ...onboardingForm.ownersDetails,
                                                contactTel: phoneNumberValidator(
                                                    mobileNumber,
                                                    onboardingForm.ownersDetails.contactTel
                                                )
                                            }
                                        })
                                    }
                                    inputClass={styles.phoneInput}
                                    name="Mobile Number"
                                />
                            </FormItem>
                        </Grid>
                    </CheckMerchantCountries>
                    <Grid item xs={12} sm={4} className={styles.formItemGrid} hidden={isT2SMerchant}>
                        <FormItem>
                            <Label text="Business role" id="owner-label-businesstitle"></Label>
                            <Input
                                placeholder="Director"
                                id="owner-input-businesstitle"
                                value={checkEmptyValue(onboardingForm.ownersDetails.businessTitle)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownersDetails: {
                                            ...onboardingForm.ownersDetails,
                                            businessTitle: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    </Grid>
                    <Grid item xs={5} sm={4} className={styles.selectContainer} hidden={isT2SMerchant}>
                        <div>
                            <p className={styles.ownershipLabel}>Ownership</p>
                            <SelectedDropDown
                                id="advanceFullPaymentsDays"
                                options={oneHundredValues}
                                getOptionLabel={(option) => option}
                                listPrefix={' %'}
                                value={onboardingForm.ownersDetails.ownership}
                                onChange={(e, newValue) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownersDetails: {
                                            ...onboardingForm.ownersDetails,
                                            ownership: newValue
                                        }
                                    })
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={7} sm={4} className={styles.formItemGrid} hidden={isT2SMerchant}>
                        <FormItem>
                            <Label text="Ownership type" id="owner-label-ownershiptype"></Label>
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder="Ownership type"
                                        id="owner-select-ownershiptype"
                                        value={onboardingForm.ownersDetails.ownershipType}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                ownersDetails: {
                                                    ...onboardingForm.ownersDetails,
                                                    ownershipType: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                }
                            >
                                <option value="Direct">Direct</option>
                                <option value="Indirect">Indirect</option>
                            </NativeSelect>
                        </FormItem>
                    </Grid>

                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                        {onboardingForm.isPopulated && (
                            <Grid item xs={12}>
                                <FormItem>
                                    <Label text="Social security number (SSN) last 4 digits" id="social-security" />
                                    <ReactCodeInput
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 40}
                                        fieldWidth={mediaLessThan600px ? 30 : 40}
                                        autoFocus={false}
                                        fields={4}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(onboardingForm.ownersDetails.ssnLastDigits)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                ownersDetails: {
                                                    ...onboardingForm.ownersDetails,
                                                    ssnLastDigits: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            </Grid>
                        )}
                    </CheckMerchantCountries>
                </Grid>
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                setShouldNextStep(true);
                onSubmit();
            }}
        ></NextButton>
    );

    return (
        <React.Fragment>
            {shouldNextStep ? (
                <OnboardingOwnerPrimaryAddress nextStep={nextStep} />
            ) : (
                <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />
            )}
        </React.Fragment>
    );
}

OnboardingOwnerDetailsComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    shouldNextStep: PropTypes.bool,
    setShouldNextStep: PropTypes.func,
    onSubmit: PropTypes.func,
    oneHundredValues: PropTypes.array,
    setPersonDetails: PropTypes.func,
    nationalities: PropTypes.array,
    phoneNumberValidator: PropTypes.func,
    dayChangeHandler: PropTypes.func,
    monthChangeHandler: PropTypes.func,
    yearChangeHandler: PropTypes.func
};

export default OnboardingOwnerDetailsComponent;
