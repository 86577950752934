import React, { useEffect, useMemo, useState } from 'react';
import CardImageComponent from './card-image.component';

function CardImage(props) {
    const { cardNumber, currentFocusedElm } = props;

    const [style, setStyle] = useState(null);

    const useCardType = useMemo(() => {
        const CARDS = {
            visa: '^4',
            amex: '^(34|37)',
            mastercard: '^5[1-5]',
            discover: '^6011',
            unionpay: '^62',
            troy: '^9792'
        };

        const cardType = (cardNumber) => {
            const number = cardNumber;
            let re;
            for (const [card, pattern] of Object.entries(CARDS)) {
                re = new RegExp(pattern);
                if (number.match(re) != null) {
                    return card;
                }
            }

            return ''; // default type
        };

        return cardType(cardNumber);
    }, [cardNumber]);

    const outlineElementStyle = (element) => {
        return element
            ? {
                  width: `${element.offsetWidth}px`,
                  height: `${element.offsetHeight}px`,
                  transform: `translateX(${element.offsetLeft}px) translateY(${element.offsetTop}px)`
              }
            : null;
    };

    useEffect(() => {
        if (currentFocusedElm) {
            const style = outlineElementStyle(currentFocusedElm.current);
            setStyle(style);
        }
    }, [currentFocusedElm]);

    const maskCardNumber = (cardNumber) => {
        const cardNumberArr = cardNumber.split('');
        cardNumberArr.forEach((val, index) => {
            if (index > 4 && index < 14) {
                if (cardNumberArr[index] !== ' ') {
                    cardNumberArr[index] = '*';
                }
            }
        });

        return cardNumberArr;
    };

    return <CardImageComponent {...props} style={style} useCardType={useCardType} maskCardNumber={maskCardNumber} />;
}

export default CardImage;
