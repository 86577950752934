import React from 'react';
import styles from './onboarding-business-details.module.scss';
import { Grid, NativeSelect } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem
} from 'components/onboarding/onboarding-ui-components';
import { checkEmptyValue, getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import ReactCodeInput from 'react-verification-code-input';
import { useMediaQuery } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

function OnboardingBusinessDetailsComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { nextStep, onboardingForm, setOnboardingForm, isDisabled, onSubmit, phoneNumberValidator } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const isT2SMerchant = globalState.isT2SMerchant;

    function formBody() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    {!isT2SMerchant && (
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label
                                    text="Legal Entity/Business Type"
                                    id="business-type-label"
                                    languageSwitchEnabled={true}
                                ></Label>
                                <NativeSelect
                                    input={
                                        <Input
                                            placeholder="Legal Entity/Business Type"
                                            id="business-type"
                                            value={onboardingForm.businessDetails.businessTypeId}
                                            onChange={(e) =>
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    businessDetails: {
                                                        ...onboardingForm.businessDetails,
                                                        businessTypeId: e.target.value
                                                    }
                                                })
                                            }
                                        ></Input>
                                    }
                                >
                                    {onboardingForm.businessTypes.map((type) => {
                                        return (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        );
                                    })}
                                </NativeSelect>
                            </FormItem>
                        </Grid>
                    )}
                    {!isT2SMerchant && (
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                            <Grid item xs={12} className={styles.formItemGrid}>
                                <FormItem>
                                    <Label text="VAT number" id="vat-number-label"></Label>
                                    <Input
                                        placeholder="VAT number"
                                        id="vat-number"
                                        value={checkEmptyValue(onboardingForm.businessDetails.vatNumber)}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                businessDetails: {
                                                    ...onboardingForm.businessDetails,
                                                    vatNumber: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                </FormItem>
                            </Grid>
                        </CheckMerchantCountries>
                    )}
                    {!isT2SMerchant && (
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Trading name" id="trading-name-label"></Label>
                                <Input
                                    placeholder="Trading name"
                                    id="trading-name"
                                    value={checkEmptyValue(onboardingForm.businessDetails.tradingName)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                tradingName: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </Grid>
                    )}
                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                        {onboardingForm.isPopulated && (
                            <Grid item xs={12}>
                                <FormItem>
                                    <Label text="Employer identification number" id="employer-identification-number" />
                                    <ReactCodeInput
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 40}
                                        fieldWidth={mediaLessThan600px ? 30 : 40}
                                        autoFocus={false}
                                        fields={9}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(onboardingForm.businessDetails.employeeIdNumber)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                businessDetails: {
                                                    ...onboardingForm.businessDetails,
                                                    employeeIdNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            </Grid>
                        )}
                    </CheckMerchantCountries>
                    <CheckMerchantCountries excludedCountries={[MerchantCountries.AUSTRALIA]}>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Telephone number" id="telephone-number-label"></Label>
                                <PhoneInput
                                    country={'gb'}
                                    id="telephone-number"
                                    value={checkEmptyValue(onboardingForm.businessDetails.phoneNumber)}
                                    placeholder={'Telephone no.'}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                phoneNumber: phoneNumberValidator(
                                                    phone,
                                                    onboardingForm.businessDetails.phoneNumber
                                                )
                                            }
                                        })
                                    }
                                    inputClass={styles.phoneInput}
                                    name="phone"
                                />
                            </FormItem>
                        </Grid>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Telephone number" id="telephone-number-label"></Label>
                                <PhoneInput
                                    country={'au'}
                                    id="telephone-number"
                                    value={checkEmptyValue(onboardingForm.businessDetails.phoneNumber)}
                                    placeholder={'Telephone no.'}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={false}
                                    onChange={(phone) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                phoneNumber: phoneNumberValidator(
                                                    phone,
                                                    onboardingForm.businessDetails.phoneNumber
                                                )
                                            }
                                        })
                                    }
                                    inputClass={styles.phoneInput}
                                    name="phone"
                                />
                            </FormItem>
                        </Grid>
                    </CheckMerchantCountries>
                    {globalState.reseller?.name === ResellerType.OMNIPAY && (
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <FormItem>
                                <Label text="Website URL" id="website-label"></Label>
                                <Input
                                    placeholder="Website"
                                    id="website"
                                    value={checkEmptyValue(onboardingForm.businessDetails.websiteUrl)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                websiteUrl: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </Grid>
                    )}
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <Label text="Company email" id="company-email"></Label>
                            <Input
                                placeholder="Company email"
                                id="website"
                                value={checkEmptyValue(onboardingForm.businessDetails.email)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            email: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    const title = (
        <OnboardingTitle title="Business details" subTitle=" Some specifics on your business"></OnboardingTitle>
    );

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} nextButton={nextBtn} content={formBody} />;
}

OnboardingBusinessDetailsComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    phoneNumberValidator: PropTypes.func
};

export default OnboardingBusinessDetailsComponent;
