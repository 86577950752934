import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { Label, Input } from '../../../onboarding/onboarding-ui-components';
import { SnackBarAndAlert } from 'components';
import { LoadingScreen } from 'components';
import styles from './merchant-stripe-id.module.scss';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export default function MerchantStripeIdComponent(props) {
    const {
        merchantStripeId,
        handleCopyMerchantStripeId,
        closeCopyAlert,
        isCopyAlertOpen,
        createStripeId,
        updateStripeId,
        setMerchantStripeId,
        loading,
        successMessage,
        errorMessage,
        existingStripeId,
        showMessage,
        handleCloseMessage
    } = props;

    const createButtonDisableCondition = existingStripeId ? !!existingStripeId : !!merchantStripeId;
    const updateButtonDisableCondition = existingStripeId ? merchantStripeId === existingStripeId : !merchantStripeId;

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12}>
                <Grid item xs={12} sm={12} container spacing={2}>
                    <Grid item xs={4}>
                        <Label>Stripe Id</Label>
                    </Grid>

                    <Grid item container xs={8}>
                        <Grid item xs={9} container>
                            <Grid item xs={8} sm={8} md={8}>
                                <Input
                                    isRequired={false}
                                    inputProps={{ className: styles.textInput }}
                                    value={merchantStripeId}
                                    onChange={(event) => setMerchantStripeId(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} alignItems="center" className={styles.copyIconContainer}>
                                <FileCopyIcon className={styles.copyIcon} onClick={handleCopyMerchantStripeId} />
                            </Grid>
                        </Grid>

                        <Grid item container style={{ marginTop: '10px' }}>
                            <Button
                                id="createStripeId"
                                variant="contained"
                                color="primary"
                                className={styles.createContainer}
                                disabled={createButtonDisableCondition}
                                onClick={() => createStripeId()}
                            >
                                create
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => updateStripeId()}
                                color="primary"
                                disabled={updateButtonDisableCondition}
                            >
                                {merchantStripeId ? 'Update' : 'Reset'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <LoadingScreen open={loading}></LoadingScreen>
            {isCopyAlertOpen && (
                <SnackBarAndAlert open={isCopyAlertOpen} onClose={closeCopyAlert} type={'success'}>
                    Successfully copied the merchant's stripe id.
                </SnackBarAndAlert>
            )}
            {successMessage && (
                <SnackBarAndAlert open={showMessage} onClose={handleCloseMessage} type="success">
                    {successMessage}
                </SnackBarAndAlert>
            )}
            {errorMessage && (
                <SnackBarAndAlert open={showMessage} onClose={handleCloseMessage} type="error">
                    {errorMessage}
                </SnackBarAndAlert>
            )}
        </React.Fragment>
    );
}

MerchantStripeIdComponent.propTypes = {
    merchantStripeId: PropTypes.string.isRequired,
    isCopyAlertOpen: PropTypes.bool.isRequired,
    handleCopyMerchantStripeId: PropTypes.func.isRequired,
    closeCopyAlert: PropTypes.func.isRequired,
    createStripeId: PropTypes.func.isRequired
};
