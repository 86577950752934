import React, { useState, useEffect } from 'react';
import ModalUserManagementComponent from './modal-user-management.component';
import { EntityType } from 'utils/enums/EntityType';
import { BusinessService, ClientService, MerchantService, RoleService, EntitiesService } from 'services';
import PropTypes from 'prop-types';
import { Roles } from 'utils/enums/Roles';

function ModalUserManagement(props) {
    const { setShow, selectedUser, loadUsersAfterOperation } = props;
    const [entities, setEntities] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState();
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [entityType, setEntityType] = useState('Business');
    useEffect(() => {
        switch (entityType) {
            case EntityType.BUSINESS:
                getBusinesses();
                break;
            case EntityType.CLIENT:
                getClients();
                break;
            case EntityType.MERCHANT:
                getMerchants();
                break;
            default:
                setEntities([]);
        }

        async function getBusinesses() {
            const businesses = await BusinessService.getBusinesses();
            if (businesses.data.length > 0) {
                setEntities(businesses.data);
                setSelectedEntity(businesses.data[0].id);
            } else {
                setEntities([]);
            }
        }

        async function getClients() {
            const clients = await ClientService.getClients();
            if (clients.data.length > 0) {
                setEntities(clients.data);
                setSelectedEntity(clients.data[0].id);
            } else {
                setEntities([]);
            }
        }

        async function getMerchants() {
            const merchants = await MerchantService.getMerchants();
            if (merchants.data.length > 0) {
                setEntities(merchants.data);
                setSelectedEntity(merchants.data[0].id);
            } else {
                setEntities([]);
            }
        }
    }, [entityType]);

    useEffect(() => {
        async function getRoles() {
            const roles = await RoleService.getRoles();
            setRoles(roles.data.filter((role) => role.name !== Roles.Owner));
            setSelectedRole(roles.data[0].id);
        }
        getRoles();
    }, []);

    const onAssign = () => {
        var businessId = null;
        var clientId = null;
        var merchantId = null;
        switch (entityType) {
            case EntityType.BUSINESS:
                businessId = selectedEntity;
                break;
            case EntityType.CLIENT:
                clientId = selectedEntity;
                break;
            case EntityType.MERCHANT:
                merchantId = selectedEntity;
                break;
            default:
                setEntities([]);
        }
        EntitiesService.assignEntityAndRoleToUser({
            userId: selectedUser.id,
            businessId,
            clientId,
            merchantId,
            roleId: selectedRole,
        }).then((response) => {
            if (response.isSuccesfully) {
                setShow(false);
                loadUsersAfterOperation();
            } else {
                setError(true);
                if (response.data.error) {
                    setErrorMessage(response.data.error);
                } else {
                    setErrorMessage('An error occurred. Please try again!');
                }
            }
        });
    };

    return (
        <ModalUserManagementComponent
            {...props}
            entityType={entityType}
            setEntityType={setEntityType}
            entities={entities}
            setSelectedEntity={setSelectedEntity}
            roles={roles}
            setSelectedRole={setSelectedRole}
            onSubmit={onAssign}
            error={error}
            setError={setError}
            errorMessage={errorMessage}
        />
    );
}

ModalUserManagement.propTypes = {
    setShow: PropTypes.func,
    selectedUser: PropTypes.object,
    loadUsersAfterOperation: PropTypes.func,
};

export default ModalUserManagement;
