export const QrCodeType = {
    qrImgSource: 'qrImgSource',
    thirdPartyQR: 'thirdPartyQR'
};

export const QrFields = {
    qrID: 'QR ID',
    qrName: 'Name',
    qrDescription: 'Description',
    createdAt: 'Created At',
    amount: 'Amount Received',
    qrStatus: 'Status',
    qrDueDate: 'Expire By',
    link: 'Link',
    expiredAt: 'Expired At',
    typeOfQr: 'Type Of QR',
    download: 'Download',
    view: 'View',
    action: 'Action'
};

export const QrCodeStatus = {
    ACTIVE: 'Active',
    CLOSED: 'Closed',
    EXPIRED: 'Expired'
};
