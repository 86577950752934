import React from 'react';
import { Form } from 'react-bootstrap';
import { Grid, Button, Input, Container, InputLabel, Typography, TextareaAutosize } from '@material-ui/core';
import styles from './request-support-form.module.scss';
import PhoneInput from 'react-phone-input-2';
import { LoadingScreen } from 'components';
import { emailValidationPattern } from '../../utils/enums/EmailValidationPattern';
import PropTypes from 'prop-types';
import { SnackBarAndAlert } from 'components';

const RequestSupportFormComponent = (props) => {
    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        query,
        setQuery,
        handleSubmit,
        isDisable,
        isLoading,
        showMessage,
        message,
        handleCloseMessage
    } = props;

    return (
        <Container className={styles.requestSupportFormContainer}>
            <Grid className={styles.requestFormTitleGrid}>
                <Typography className={styles.requestFormTitle}>Request For Support</Typography>
            </Grid>
            <Form onSubmit={handleSubmit} className={styles.requestSupportForm}>
                <Grid className={styles.requestFormFields}>
                    <InputLabel className={styles.label} id="first-name-input">
                        <Typography color="primary" className={styles.labelText}>
                            First name
                        </Typography>
                    </InputLabel>
                    <Input
                        type="text"
                        className={styles.requestFormInputRoot}
                        disableUnderline
                        required
                        autoComplete="off"
                        inputProps={{ className: styles.requestFormInput }}
                        name="firstName"
                        placeholder="First name"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                    />
                </Grid>

                <Grid className={styles.requestFormFields}>
                    <InputLabel className={styles.label} id="last-name-input">
                        <Typography color="primary" className={styles.labelText}>
                            Last name
                        </Typography>
                    </InputLabel>
                    <Input
                        className={styles.requestFormInputRoot}
                        disableUnderline
                        type="text"
                        required
                        inputProps={{ className: styles.requestFormInput }}
                        autoComplete="off"
                        name="lastName"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                    />
                </Grid>

                <Grid className={styles.requestFormFields}>
                    <InputLabel className={styles.label} id="email-input">
                        <Typography color="primary" className={styles.labelText}>
                            Email
                        </Typography>
                    </InputLabel>
                    <Form.Control
                        className={styles.requestFormInputRoot + ' ' + styles.requestFormInput}
                        disableUnderline
                        type="Email"
                        required
                        autoComplete="off"
                        name="email"
                        value={email}
                        id="email"
                        pattern={emailValidationPattern.regexString}
                        title="Please provide valid email address"
                        placeholder="name@example.com"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </Grid>
                <Grid className={styles.requestFormFields}>
                    <InputLabel className={styles.label} id="phone-number-input">
                        <Typography color="primary" className={styles.labelText}>
                            Phone number
                        </Typography>
                    </InputLabel>
                    <PhoneInput
                        country={'gb'}
                        id="phone-number"
                        placeholder={'Phone number'}
                        alwaysDefaultMask={true}
                        countryCodeEditable={false}
                        inputClass={styles.phoneInput}
                        name="phone"
                        value={phoneNumber}
                        onChange={(phone) => setPhoneNumber(phone)}
                    />
                </Grid>

                <Grid className={styles.requestFormFields}>
                    <InputLabel className={styles.label} id="query-input">
                        <Typography color="primary" className={styles.labelText}>
                            Please explain the issue you're experiencing
                        </Typography>
                    </InputLabel>
                    <TextareaAutosize
                        className={styles.requestFormInputRoot + ' ' + styles.requestFormInput}
                        rows="5"
                        disableUnderline
                        type="text"
                        required
                        autoComplete="off"
                        name="query"
                        value={query}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                </Grid>

                <Grid className={styles.requestSupportFormSubmitContainer}>
                    <Button
                        className={styles.requestSupportFormSubmit}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isDisable}
                    >
                        Submit
                    </Button>
                </Grid>
            </Form>
            {showMessage && (
                <SnackBarAndAlert open={showMessage} onClose={handleCloseMessage} type={'error'}>
                    {message}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={isLoading}></LoadingScreen>
        </Container>
    );
};

RequestSupportFormComponent.propTypes = {
    firstName: PropTypes.string,
    setFirstName: PropTypes.func,
    lastName: PropTypes.string,
    setLastName: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    phoneNumber: PropTypes.string,
    setPhoneNumber: PropTypes.func,
    query: PropTypes.string,
    setQuery: PropTypes.func,
    handleSubmit: PropTypes.func,
    isDisable: PropTypes.bool,
    isLoading: PropTypes.bool,
    showMessage: PropTypes.bool,
    message: PropTypes.string,
    handleCloseMessage: PropTypes.func
};

export default RequestSupportFormComponent;
