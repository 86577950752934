import React from 'react';
import { Paper, Button, Typography, Box, Grid, OutlinedInput } from '@material-ui/core';
import {
    Input,
    Label,
    FormItem,
    PostCodeSelector,
    SelectedDropDown
} from 'components/onboarding/onboarding-ui-components';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import Divider from '@material-ui/core/Divider';
import ProductMultiSelect from './product-multi-select-ui/product-multi-select.container';
import NumberFormat from 'react-number-format';
import NativeSelect from '@material-ui/core/NativeSelect';
import { LoadingScreen, SnackBarAndAlert, ValidURLInput, ValidEmailInput } from 'components';

import style from './client-registration.module.scss';

function ClientRegisterComponent(props) {
    const {
        products,
        selectedProducts,
        setSelectedProducts,
        onSubmit,
        contactDetails,
        setContactDetails,
        phoneNumberValidator,
        configDetails,
        businessDetails,
        setBusinessDetails,
        setConfigDetails,
        isLoading,
        message,
        submitSuccess,
        setSubmitSuccess,
        submitFail,
        setSubmitFail,
        disabled,
        SubmitFail,
        selectAddressData,
        businessDescriptions,
        setBusinessDescriptionSearchString,
        businessDescriptionSearchString
    } = props;
    return (
        <React.Fragment>
            {!isLoading && (
                <Paper className={style.registerContainer}>
                    <Box className={style.divTitle}>
                        <Typography color="primary" component="span">
                            <span className={style.registrationTitle}>Omnipay Client Registration</span>
                        </Typography>
                    </Box>
                    <Grid container className={style.Container}>
                        <div></div>
                        <Grid item xs={12} sm={4}>
                            <div className={style.formContainer}>
                                <Typography component="span" color="primary" className={style.formTitle}>
                                    Contact Details
                                </Typography>
                                <FormItem>
                                    <Label text="Name" id="legal-name"></Label>
                                    <Input
                                        id="legal-name-input"
                                        fullWidth={true}
                                        value={contactDetails.legalName}
                                        onChange={(e) => {
                                            setContactDetails({
                                                ...contactDetails,
                                                legalName: e.target.value
                                            });
                                        }}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Post Code" id="post-code"></Label>
                                    <PostCodeSelector
                                        postCodeValue={checkEmptyValue(contactDetails.postCode)}
                                        onPostCodeChange={(postCode) => {
                                            setContactDetails({
                                                ...contactDetails,
                                                postCode: postCode
                                            });
                                        }}
                                        onAddressObjectChange={(addressData) =>
                                            selectAddressData(addressData, contactDetails, setContactDetails)
                                        }
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Address" id="address"></Label>
                                    <Input
                                        id="address-input"
                                        value={contactDetails.addressLine}
                                        onChange={(e) => {
                                            setContactDetails({
                                                ...contactDetails,
                                                addressLine: e.target.value
                                            });
                                        }}
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Town/City" id="city"></Label>
                                    <Input
                                        id="city-input"
                                        value={contactDetails.city}
                                        onChange={(e) => {
                                            setContactDetails({
                                                ...contactDetails,
                                                city: e.target.value
                                            });
                                        }}
                                    />
                                </FormItem>

                                <FormItem>
                                    <Label text="Country" id="country"></Label>
                                    <Input id="country-input" value={contactDetails.country} disabled={true} />
                                </FormItem>
                                <FormItem>
                                    <Label text="Phone Number" id="phone-number"></Label>
                                    <PhoneInput
                                        country={'gb'}
                                        name="phone"
                                        inputClass={style.PhoneInput}
                                        value={checkEmptyValue(contactDetails.telephoneNumber)}
                                        alwaysDefaultMask={true}
                                        countryCodeEditable={false}
                                        onChange={(phone) => {
                                            setContactDetails({
                                                ...contactDetails,
                                                telephoneNumber: phoneNumberValidator(phone)
                                            });
                                        }}
                                    />
                                </FormItem>

                                <ValidEmailInput
                                    labelText="Email Address"
                                    labelId="owner-email-id"
                                    id="owner-email-address-input"
                                    fullWidth={true}
                                    type="email"
                                    value={contactDetails.email}
                                    onChange={(e) => {
                                        setContactDetails({
                                            ...contactDetails,
                                            email: e.target.value.toLowerCase()
                                        });
                                    }}
                                    autoComplete="off"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container>
                                <div className={style.formContainer}>
                                    <SnackBarAndAlert
                                        open={submitSuccess}
                                        onClose={() => setSubmitSuccess(false)}
                                        type="success"
                                    >
                                        {message}
                                    </SnackBarAndAlert>
                                    <SnackBarAndAlert
                                        open={submitFail}
                                        onClose={() => setSubmitFail(false)}
                                        type="error"
                                    >
                                        {message}
                                    </SnackBarAndAlert>

                                    <Typography color="primary" className={style.formTitle}>
                                        Business Details
                                    </Typography>
                                    <FormItem>
                                        <Label text=" Business Name" id="business-name"></Label>
                                        <Input
                                            id="business-name-input"
                                            value={businessDetails.legalName}
                                            className
                                            onChange={(e) => {
                                                setBusinessDetails({
                                                    ...businessDetails,
                                                    legalName: e.target.value
                                                });
                                            }}
                                        />
                                    </FormItem>

                                    <ValidURLInput
                                        labelText="Business website"
                                        labelId="business-url"
                                        id="business-url-input"
                                        value={businessDetails.url}
                                        onChange={(value) => {
                                            setBusinessDetails({
                                                ...businessDetails,
                                                url: value
                                            });
                                        }}
                                    />
                                    <FormItem>
                                        <Label text="MCC Code" id="mcc-code"></Label>
                                        <SelectedDropDown
                                            id="business-descriptions"
                                            placeholder="Search by code or description"
                                            hideNoOptionsTextSelect={true}
                                            value={businessDescriptionSearchString}
                                            getOptionSelected={(option, value) => option?.name === value?.name}
                                            options={businessDescriptions}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setBusinessDetails({
                                                        ...businessDetails,
                                                        businessDescription: newValue.id,
                                                        mccCode: newValue.mccCode
                                                    });
                                                    setBusinessDescriptionSearchString(newValue);
                                                } else {
                                                    setBusinessDetails({
                                                        ...businessDetails,
                                                        businessDescription: '',
                                                        mccCode: ''
                                                    });
                                                    setBusinessDescriptionSearchString({ name: '' });
                                                }
                                            }}
                                            customListRender={(option) => {
                                                return (
                                                    <React.Fragment>
                                                        <span>{option.name}</span>
                                                    </React.Fragment>
                                                );
                                            }}
                                        />
                                    </FormItem>
                                </div>

                                <SnackBarAndAlert open={submitSuccess} type="success">
                                    {message}
                                </SnackBarAndAlert>
                                <SnackBarAndAlert open={SubmitFail} type="error">
                                    {message}
                                </SnackBarAndAlert>
                            </Grid>

                            <Grid container>
                                <div className={style.configContainer}>
                                    <Typography component="span" color="primary" className={style.configTitle}>
                                        Configuration
                                    </Typography>
                                    <FormItem>
                                        <Label text="Acquirer Bank" id="acquirer-bank"></Label>
                                        <Input
                                            id="acquirer-bank-input"
                                            value={configDetails.acquireBank}
                                            disabled={true}
                                        />
                                    </FormItem>
                                    <FormItem>
                                        <Label text="Merchant Id (MID)" id="merchant-id"></Label>
                                        <NumberFormat
                                            customInput={OutlinedInput}
                                            className={style.codeInput}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                maxLength: 25,
                                                className: style.codeInput__input
                                            }}
                                            pattern="\d*"
                                            allowLeadingZeros={true}
                                            decimalSeparator={false}
                                            allowNegative={false}
                                            isNumericString={true}
                                            value={configDetails.merchantId}
                                            onChange={(e) => {
                                                setConfigDetails({
                                                    ...configDetails,
                                                    merchantId: e.target.value
                                                });
                                            }}
                                            labelWidth={0}
                                            required
                                        />
                                    </FormItem>

                                    <Grid container sm={12} className={style.dropDown} direction="row" spacing={1}>
                                        <Grid item xs={4} sm={4}>
                                            <FormItem>
                                                <Label text="Test Mode" id="test-mode"></Label>
                                                <NativeSelect
                                                    defaultValue={false}
                                                    input={
                                                        <Input
                                                            id="test-mode"
                                                            value={configDetails.testMode}
                                                            onChange={(e) => {
                                                                setConfigDetails({
                                                                    ...configDetails,
                                                                    testMode: e.target.value
                                                                });
                                                            }}
                                                        ></Input>
                                                    }
                                                >
                                                    <option value={true}>Y</option>
                                                    <option value={false}>N</option>
                                                </NativeSelect>
                                            </FormItem>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <FormItem>
                                                <Label text="3D Secure" id="3d-secure"></Label>
                                                <NativeSelect
                                                    defaultValue={false}
                                                    input={
                                                        <Input
                                                            id="owner-select-ownershiptype"
                                                            value={configDetails.threeDSecure}
                                                            onChange={(e) => {
                                                                setConfigDetails({
                                                                    ...configDetails,
                                                                    threeDSecure: e.target.value
                                                                });
                                                            }}
                                                        ></Input>
                                                    }
                                                >
                                                    <option value={true}>Y</option>
                                                    <option value={false}>N</option>
                                                </NativeSelect>
                                            </FormItem>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <FormItem>
                                                <Label text="Process ID" id="processor-id"></Label>
                                                <NativeSelect
                                                    input={
                                                        <Input id="test-mode" value={configDetails.processorId}></Input>
                                                    }
                                                >
                                                    <option value={5}>5</option>
                                                </NativeSelect>
                                            </FormItem>
                                        </Grid>
                                    </Grid>

                                    <FormItem>
                                        <Label text="TID (Terminal ID)" id="terminal-id"></Label>
                                        <NumberFormat
                                            className={style.codeInput}
                                            customInput={OutlinedInput}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                maxLength: 8,
                                                className: style.codeInput__input
                                            }}
                                            pattern="\d*"
                                            allowLeadingZeros={true}
                                            decimalSeparator={false}
                                            allowNegative={false}
                                            isNumericString={true}
                                            value={configDetails.terminalId}
                                            onChange={(e) => {
                                                setConfigDetails({
                                                    ...configDetails,
                                                    terminalId: e.target.value
                                                });
                                            }}
                                            labelWidth={0}
                                            required
                                        />
                                    </FormItem>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={style.productsContainer}>
                                <Box className={style.productBox}>
                                    <Grid container justify="center" alignItems="center" className={style.titleGrid}>
                                        <Typography color="primary" component="span">
                                            <span className={style.productsTitle}>Products</span>
                                        </Typography>
                                    </Grid>
                                    <Divider />
                                    <Grid container direction="column">
                                        <Grid item>
                                            <div className={style.listContainer}>
                                                <ProductMultiSelect
                                                    list={products}
                                                    onChange={(value) => {
                                                        setSelectedProducts(value);
                                                    }}
                                                    value={selectedProducts}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={style.btnContainer}>
                        <Button
                            disabled={disabled}
                            id="update-btn"
                            color="primary"
                            variant="contained"
                            className={style.submitBtn}
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </Paper>
            )}
            <LoadingScreen open={isLoading} />
        </React.Fragment>
    );
}
ClientRegisterComponent.propTypes = {
    products: PropTypes.array,
    selectedProducts: PropTypes.array,
    setSelectedProducts: PropTypes.func,
    onSubmit: PropTypes.func,
    phoneNumberValidator: PropTypes.func,
    isLoading: PropTypes.bool,
    contactDetails: PropTypes.object,
    setContactDetails: PropTypes.func,
    configDetails: PropTypes.object,
    businessDetails: PropTypes.object,
    setBusinessDetails: PropTypes.func,
    setConfigDetails: PropTypes.func,
    setMessage: PropTypes.func,
    setSubmitSuccess: PropTypes.func,
    setSubmitFail: PropTypes.func,
    acceptance: PropTypes.array,
    message: PropTypes.string,
    submitSuccess: PropTypes.bool,
    disabled: PropTypes.bool,
    SubmitFail: PropTypes.bool,
    selectAddressData: PropTypes.func
};

export default ClientRegisterComponent;
