import React, { useEffect, useState } from 'react';
import ModalUserIconComponent from './modal-user-icon.component';
import { useGlobalStateContext } from 'contexts';

function ModalUserIcon(props) {
    const [user, setUser] = useState({});
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        setUser(globalState.user);
    }, [globalState.user]);

    return <ModalUserIconComponent user={user} {...props} />;
}

export default ModalUserIcon;
