import React from 'react';
import InternalTransfersListTable from '../transaction-tables/internal-transfers-list-table/internal-transfers-list-table.container';

const InternalTransfersListComponent = (props) => {
    return (
        <div>
            <InternalTransfersListTable {...props} />
        </div>
    );
};

export default InternalTransfersListComponent;
