import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Box, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, OutlinedInput, InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import styles from './modal-refund-internal-transfer.module.scss';
import { numberFormatAllowValuesForPayments } from 'utils/helper';

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fdecea',
        fontSize: 11
    },
    arrow: { backgroundColor: '#fdecea' }
}))(Tooltip);

const useStyles = makeStyles(() => ({
    root: {
        height: '53px',
        '&.MuiOutlinedInput-root': {
            'border-radius': '0px',
            '& input': {
                fontSize: '20px',
                fontWeight: 'bold'
            }
        },
        '& fieldset': {
            border: '1px solid #e8e9ec'
        }
    }
}));

const ModalRefundInternalTransferComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const classes = useStyles();
    const { refundAmount, loading, buttonDisabled, error, errorMessage, disableSubmit, success } = props;
    return (
        <React.Fragment>
            <Dialog
                maxWidth="md"
                open={props.showRefundModel}
                onClose={() => props.setShowRefundModel(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={() => props.setShowCancelModel(false)}>
                    Refund confirmation
                </DialogTitle>

                <DialogContent dividers>
                    <Box width={400}>
                        {error ? (
                            <>
                                <div className="payments-none text-center">
                                    <p className={styles.spanErrorMessage}>
                                        {errorMessage ? errorMessage : `Something went wrong... Try again later.`}
                                    </p>
                                </div>
                            </>
                        ) : loading ? (
                            <>
                                <div className="payments-none text-center">
                                    <Box textAlign="center" py={2}>
                                        <CircularProgress />
                                    </Box>{' '}
                                    <p className={styles.spanMessage}>Please wait, we are processing your request...</p>
                                </div>
                            </>
                        ) : success ? (
                            <>
                                <div className="payments-none text-center">
                                    <p className="text-muted">Refunded successfully</p>
                                </div>
                            </>
                        ) : (
                            <React.Fragment>
                                <Box py={2}>
                                    <span className={styles.spanLabel}>Sender Name:</span>{' '}
                                    <span className={styles.sender}>{props.sender}</span>
                                </Box>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={3}>
                                        <span className={styles.spanLabel}>Amount</span>
                                    </Grid>
                                    <Grid item xs={4} className={styles.action_items__account_balance}>
                                        <span className={styles.action_items__account_balance__currency}>
                                            {getCurrencySymbol(globalState.merchant?.country)}: &nbsp;
                                        </span>
                                        <NumberFormat
                                            customInput={OutlinedInput}
                                            inputProps={{ inputMode: 'numeric' }}
                                            pattern="\d*"
                                            thousandSeparator={true}
                                            allowLeadingZeros={true}
                                            allowNegative={true}
                                            isNumericString={true}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            displayType={'text'}
                                            value={props.amount}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ErrorTooltip
                                            open={props.showActionError}
                                            arrow
                                            title={
                                                <Alert severity="error">
                                                    {+props.amount > 0 && props.refundActionValidation()}
                                                </Alert>
                                            }
                                        >
                                            <div className={styles.action_items__input}>
                                                <NumberFormat
                                                    value={props.refundAmount}
                                                    customInput={OutlinedInput}
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    pattern="\d*"
                                                    thousandSeparator={true}
                                                    allowLeadingZeros={true}
                                                    allowNegative={false}
                                                    isNumericString={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={2}
                                                    disabled={+props.amount <= 0}
                                                    className={classes.root + ' ' + styles.inputAmount}
                                                    id="outlined-adornment-weight"
                                                    placeholder={`0.00`}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <span className={styles.action_items__input__balance}>
                                                                {getCurrencySymbol(globalState.merchant?.country)}:
                                                            </span>
                                                        </InputAdornment>
                                                    }
                                                    onValueChange={(values) => {
                                                        const { value } = values;
                                                        props.setRefundAmount(value);
                                                    }}
                                                    isAllowed={(values) => numberFormatAllowValuesForPayments(values)}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    labelWidth={0}
                                                    required
                                                />
                                            </div>
                                        </ErrorTooltip>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {!loading && !disableSubmit ? (
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                onClick={() => props.setShowRefundModel(false)}
                                disabled={buttonDisabled}
                            >
                                Cancel
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                autoFocus
                                onClick={props.onSubmitRefund}
                                disabled={
                                    disableSubmit || !refundAmount || +refundAmount === 0 || refundAmount > props.amount
                                }
                            >
                                Refund
                            </Button>
                        </React.Fragment>
                    ) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            autoFocus
                            onClick={() => props.setShowRefundModel(false)}
                            disabled={buttonDisabled}
                        >
                            Close
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalRefundInternalTransferComponent;

ModalRefundInternalTransferComponent.propTypes = {
    loading: PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    disableSubmit: PropTypes.bool
};
