import React, { useEffect, useState } from 'react';
import BalanceCardComponent from './balance-card.component';
import { useBalanceContext, useGlobalStateContext } from 'contexts';
import { PaymentsService } from 'services';

function BalanceCard(props) {
    const { balance, refreshBalance, balanceIsLoading } = useBalanceContext();
    const { globalState } = useGlobalStateContext();
    const [accountBalance, setAccountBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const isStripe = globalState.merchant?.isStripe;
    useEffect(() => {
        const getBalance = async () => {
            const balanceResponse = await PaymentsService.fetchAccountBalance(globalState.merchant?.id);
            if (balanceResponse.isSuccesfully) setAccountBalance(balanceResponse.data.balance);
            setIsLoading(false);
        };
        refreshBalance();
        if (isStripe) {
            setIsLoading(true);
            getBalance();
        }

        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    return (
        <BalanceCardComponent
            {...props}
            balance={isStripe ? Number(accountBalance) + Number(balance) : balance}
            balanceIsLoading={isStripe ? isLoading : balanceIsLoading}
        />
    );
}

export default BalanceCard;
