import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalCreateMerchantAccountComponent from './modal-create-merchant-account.component';
import { NonResellerMerchantService, AuthService } from 'services';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { validateMerchantName } from 'validators';
import { useGlobalStateContext, useMerchantCreationContext } from 'contexts';
import { getT2SReferralLinkPayload } from 'utils/getT2SReferralLinkPayload';
import AuthHelper from 'utils/authHelper';
import { ResellerType } from 'utils/enums/ResellerType';
import { UserType } from 'utils/enums/UserType';
function ModalCreateMerchantAccount(props) {
    const { globalState, setRoute, setGlobalState } = useGlobalStateContext();
    const { setMerchantCreationState } = useMerchantCreationContext();
    const [name, setName] = useState('');
    const [country, setCountry] = useState(MerchantCountries.UNITED_KINGDOM);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isT2SInvitedUser, setIsT2SInvitedUser] = useState(false);
    const isDatManReseller = globalState.reseller?.name === ResellerType.DATMAN;
    const { setUpdateMerchantData, updateMerchantData, setOpen } = props;
    const merchantNameRef = useRef(null);
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const supportedCountries = Object.values(MerchantCountries);

    useEffect(() => {
        if (!updateMerchantData) {
            if (props.thirdPartyCustomerKey) {
                const referralData = getT2SReferralLinkPayload(props.thirdPartyCustomerKey, { globalState, setRoute });
                if (supportedCountries.includes(referralData?.BusinessAddressCountry)) {
                    setCountry(referralData.BusinessAddressCountry);
                }
                setName(referralData.TradingName?.toString() || '');
                setIsT2SInvitedUser(true);
            }
        }
        // eslint-disable-next-line
    }, [props.thirdPartyCustomerKey]);

    useEffect(() => {
        if (updateMerchantData) {
            setName(globalState.merchant.name);
            setCountry(globalState.merchant.country);
        }
        setTimeout(() => {
            if (merchantNameRef && merchantNameRef.current) {
                merchantNameRef.current.focus();
            }
        }, 500);

        // eslint-disable-next-line
    }, []);

    const onCreateMerchant = async () => {
        setLoading(true);
        setDisabled(true);
        const validationResult = validateMerchantName(name);

        if (validationResult.isValid) {
            const response = await NonResellerMerchantService.createUserMerchant({
                name: name.trim(),
                country: country,
                resellerId: globalState.reseller?.id,
                thirdPartyCustomerId: props.thirdPartyCustomerKey
                    ? getT2SReferralLinkPayload(props.thirdPartyCustomerKey, {
                          globalState,
                          setRoute,
                          noRedirect: true
                      }).i
                    : null,
                host: props.thirdPartyCustomerKey
                    ? getT2SReferralLinkPayload(props.thirdPartyCustomerKey, {
                          globalState,
                          setRoute,
                          noRedirect: true
                      }).f
                    : null,
                refferalDataValue: props.thirdPartyCustomerKey
            });
            if (response.isSuccesfully) {
                const newTokenReponse = await AuthService.requestNewToken();
                await AuthHelper.refreshCurrentSession();
                if (newTokenReponse.isSuccesfully) {
                    props.setOpen(false);
                    props.setOpenMerchantList(false);
                    await props.loadMerchants(true);
                    setError(null);
                    props.setSuccess(true);
                    setMerchantCreationState((state) => ({ ...state, showMerchantCreationMessage: true }));
                    setRoute('/home');
                } else {
                    setError(newTokenReponse.data ? newTokenReponse.data : 'New merchant not added in public claims.');
                    props.setSuccess(false);
                }
            } else {
                setError('An error occurred. Please contact support or try again.');
                props.setSuccess(false);
            }
        } else {
            setError(validationResult.message);
            props.setSuccess(false);
        }
        setLoading(false);
        setDisabled(false);
        setRoute('/onboarding');
    };

    const onUpdateMerchantName = async () => {
        const merchantName = globalState.merchant.name;
        const merchantCountry = globalState.merchantCountry;
        if (merchantName !== name || merchantCountry !== country) {
            setLoading(true);
            setDisabled(true);
            const validationResult = validateMerchantName(name);
            const merchantId = globalState.merchant?.id;
            const data = {
                name: name.trim(),
                country: country
            };
            if (validationResult.isValid) {
                const response = await NonResellerMerchantService.updateMerchantInfo(merchantId, data);
                if (response.isSuccesfully) {
                    const merchant = {
                        ...globalState.merchant,
                        country: response.data.country,
                        name: response.data.name
                    };
                    localStorage.setItem('merchant', merchant);
                    setGlobalState({ ...globalState, merchant: merchant, areLoadedMerchants: true });
                    await props.loadMerchants(false, merchantId);
                    setError(null);
                    props.setSuccess(true);
                    setUpdateMerchantData(false);
                } else {
                    setError('An error occurred. Please contact support or try again.');
                    props.setSuccess(false);
                    setUpdateMerchantData(false);
                }
                setOpen(false);
            }
            setDisabled(false);
            setLoading(false);
            setOpen(false);
            setUpdateMerchantData(false);
        }
    };

    return (
        <ModalCreateMerchantAccountComponent
            {...props}
            onCreateMerchant={onCreateMerchant}
            name={name}
            setName={setName}
            error={error}
            country={country}
            setCountry={setCountry}
            globalState={globalState}
            loading={loading}
            disabled={disabled}
            isT2SInvitedUser={isT2SInvitedUser}
            isDatManReseller={isDatManReseller}
            onUpdateMerchantName={onUpdateMerchantName}
            merchantNameRef={merchantNameRef}
            isAdmin={isAdmin}
        />
    );
}

ModalCreateMerchantAccount.propTypes = {
    setOpen: PropTypes.func,
    selectMerchant: PropTypes.func,
    setOpenMerchantList: PropTypes.func,
    loadMerchants: PropTypes.func,
    setSuccess: PropTypes.func,
    name: PropTypes.string,
    thirdPartyCustomerKey: PropTypes.string
};

export default ModalCreateMerchantAccount;
