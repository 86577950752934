import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, Button, Typography, Box } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import styles from './payments-filter.module.scss';

import { ProductRequirme } from '../../models';
const useStyles = makeStyles((theme) => ({
    popover: {
        marginTop: '10px'
    },
    typography: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        margin: 'auto',
        fontWeight: '500'
    },
    button: {
        margin: theme.spacing(1),
        textTransform: 'none'
    }
}));

export default function PaymentsFilterComponent(props) {
    const classes = useStyles();
    const { anchorEl, setAnchorEl, channelFilter, setChannelFilter } = props;

    return (
        <div>
            <Button
                aria-describedby="payments-filter-popover"
                variant="outlined"
                color="primary"
                size="small"
                disableRipple
                className={classes.button}
                startIcon={<FilterListIcon />}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                Filter
            </Button>
            <Popover
                id="payments-filter-popover"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={(event) => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                className={classes.popover}
            >
                <div className={styles.filterHeader}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(event) => {
                            setChannelFilter('All');
                            setAnchorEl(null);
                        }}
                    >
                        Clear
                    </Button>
                    <Typography className={classes.typography}>Filter</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(event) => setAnchorEl(null)}
                    >
                        Done
                    </Button>
                </div>

                <div className={styles.filterItem}>
                    <Box px={2} display="flex">
                        <div>Channel : &nbsp;</div>
                        <select name="channel" onChange={(e) => setChannelFilter(e.target.value)} value={channelFilter}>
                            <option value="All">All</option>

                            {ProductRequirme &&
                                Object.entries(ProductRequirme).map(([key, value], i) => (
                                    <option key={i} value={key}>
                                        {value}
                                    </option>
                                ))}
                        </select>
                    </Box>
                </div>
            </Popover>
        </div>
    );
}

PaymentsFilterComponent.propTypes = {
    anchorEl: PropTypes.string,
    setAnchorEl: PropTypes.func.isRequired,
    channelFilter: PropTypes.string.isRequired,
    setChannelFilter: PropTypes.func.isRequired
};
