import React, { useState, useEffect } from 'react';
import { useGlobalStateContext } from 'contexts';
import ModalCreateFeeTierComponent from './modal-create-fee-tier.component';
import { AdminService } from 'services';

function ModalCreateFeeTier(props) {
    const { globalState } = useGlobalStateContext();
    const {
        setShowCreateFeeTier,
        showCreateFeeTier,
        pricingTiers,
        setPricingTiers,
        setMessage,
        showFeeTierMessage,
        setShowFeeTierMessage,
        setMessageType
    } = props;
    const [feeTierData, setFeeTierData] = useState({});
    const [isDisable, setIsDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const isDisable = feeTierData.percentageFee && feeTierData.fixedFee && Number(feeTierData.fixedFee) >= 0.1;
        setIsDisable(!isDisable);
    }, [feeTierData.percentageFee, feeTierData.fixedFee]);

    const isPercentageFee = (values) => {
        const { value, floatValue } = values;
        const MAX_PERCENTAGE_FEE = 20.0;
        const MIN_PERCENTAGE_FEE = 0.0;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_PERCENTAGE_FEE || value < MIN_PERCENTAGE_FEE) {
            return false;
        }
        return true;
    };

    const onClickAdd = async () => {
        const existingFeeTier = pricingTiers.filter(
            (data) => data.percentage_fee === feeTierData.percentageFee && data.fixed_fee === feeTierData.fixedFee
        );
        if (existingFeeTier.length !== 0) {
            setShowFeeTierMessage(true);
            setMessageType('error');
            setMessage('Fee tier already exist with the same data.');
        } else {
            setIsLoading(true);
            const response = await AdminService.createFeeTier(globalState.merchant?.id, feeTierData);
            if (response.isSuccesfully) {
                setShowFeeTierMessage(true);
                setMessageType('success');
                setMessage('Successfully created fee tier');
                setPricingTiers([
                    ...pricingTiers,
                    {
                        name: response.data?.data?.feeTierName,
                        id: response.data?.data?.feeTierId,
                        percentage_fee: feeTierData.percentageFee,
                        fixed_fee: feeTierData.fixedFee
                    }
                ]);
                setFeeTierData({});
            } else {
                setShowFeeTierMessage(true);
                setMessageType('error');
                setMessage('Something went wrong');
            }
            setIsLoading(false);
            setShowCreateFeeTier(false);
        }
    };

    return (
        <ModalCreateFeeTierComponent
            setShowCreateFeeTier={setShowCreateFeeTier}
            showCreateFeeTier={showCreateFeeTier}
            onClickAdd={onClickAdd}
            setFeeTierData={setFeeTierData}
            feeTierData={feeTierData}
            isDisable={isDisable}
            setShowFeeTierMessage={setShowFeeTierMessage}
            showFeeTierMessage={showFeeTierMessage}
            isPercentageFee={isPercentageFee}
            isLoading={isLoading}
        />
    );
}

export default ModalCreateFeeTier;
