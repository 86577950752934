import React from 'react';
import { Link } from 'react-router-dom';
import styles from './reset-password-form.module.scss';

import { Form, Spinner } from 'react-bootstrap';
import { useGlobalStateContext } from 'contexts';
import { PasswordValidation, SnackBarAndAlert } from 'components';
import PasswordInput from 'components/password-input/password-input.container';

import { Button, Grid, Typography } from '@material-ui/core';

const ResetPasswordFormComponent = (props) => {
    const { getRoute } = useGlobalStateContext();
    const {
        handleSubmit,
        isSuccess,
        message,
        setMessage,
        loading,
        newPassword,
        setNewPassword,
        confirmPassword,
        setConfirmPassword,
        isPasswordValidationFailed,
        setIsPasswordValidationFailed,
        redoPasswordValidation,
        setRedoPasswordValidation,
        email,
        firstName,
        lastName,
        userOnPasswordUpdatePageAfterExpiry,
        oldPassword,
        setOldPassword,
        handleSubmitOnExpiry
    } = props;

    return (
        <Grid className={styles.resetPasswordForm}>
            <Form onSubmit={!userOnPasswordUpdatePageAfterExpiry ? handleSubmit : handleSubmitOnExpiry}>
                <Grid>
                    {newPassword.length > 4 ? (
                        <Grid item xs={10}>
                            <div className={styles.password_errors}>
                                {isPasswordValidationFailed ? <Typography>Password Requirements:</Typography> : null}

                                <PasswordValidation
                                    newPasswordChange={true}
                                    honorCurrentPassword={false}
                                    currentPassword={oldPassword}
                                    newPassword={newPassword}
                                    redoPasswordValidation={redoPasswordValidation}
                                    setRedoPasswordValidation={setRedoPasswordValidation}
                                    confirmPassword={confirmPassword}
                                    email={email}
                                    firstName={firstName}
                                    lastName={lastName}
                                    setIsPasswordValid={(isValid) => {
                                        setIsPasswordValidationFailed(!isValid);
                                    }}
                                />
                            </div>
                        </Grid>
                    ) : null}
                    <Grid container spacing={2} className={styles.gridContainer}>
                        {userOnPasswordUpdatePageAfterExpiry && (
                            <Grid item xs={12}>
                                <Form.Label>Old password</Form.Label>
                                <PasswordInput
                                    password={oldPassword}
                                    onChange={(value) => {
                                        setOldPassword(value);
                                    }}
                                    type="password"
                                    placeholder="Password"
                                    required={true}
                                    autoFocus={true}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Form.Label>New password</Form.Label>
                            <PasswordInput
                                password={newPassword}
                                onChange={(value) => {
                                    setNewPassword(value);
                                    setRedoPasswordValidation(true);
                                }}
                                type="password"
                                placeholder="Password"
                                required={true}
                                autoFocus={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Label>Confirm password</Form.Label>
                            <PasswordInput
                                required={true}
                                password={confirmPassword}
                                onChange={(value) => {
                                    setConfirmPassword(value);
                                    setRedoPasswordValidation(true);
                                }}
                                type="Password"
                                placeholder="Confirm password"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {message && (
                    <SnackBarAndAlert
                        open={message}
                        onClose={() => setMessage(false)}
                        type={isSuccess ? 'success' : 'error'}
                    >
                        {message}
                    </SnackBarAndAlert>
                )}

                <Grid className={styles.resetPasswordFormButton}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={loading || isPasswordValidationFailed}
                        className={styles.resetPasswordButton}
                    >
                        {loading && (
                            <div className={styles.loading}>
                                <Spinner animation="border" variant="primary" />
                            </div>
                        )}
                        Set password
                    </Button>
                </Grid>
            </Form>
            <div className="text-center">
                Switch to another account? <Link to={getRoute(`/login`)}>Sign in</Link>
            </div>
        </Grid>
    );
};

export default ResetPasswordFormComponent;
