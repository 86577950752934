import React from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './qr-detailed-view.module.scss';
import { ModalPreviewQrCode, ModalReasonToClose } from 'components';
import { VisibilityOutlined } from '@material-ui/icons';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import CloseIcon from '@material-ui/icons/Close';
import { formatCurrency, formatDateTimeByFormatStringV2 } from '../../utils/helper';
import { QrCodeStatus } from 'utils/enums/QrCode';
import TransactionStatusLabel from '../payments/transaction-tables/transaction-status-label/transaction-status-label.container';

const useStyles = makeStyles(() => ({
    active: {
        backgroundColor: 'transparent',
        color: 'rgb(35, 147, 240)',
        textTransform: 'capitalize',
        cursor: 'pointer'
    },
    expired: {
        backgroundColor: 'transparent',
        color: 'grey',
        textTransform: 'capitalize',
        cursor: 'pointer'
    }
}));

export default function QrDetailedViewComponent(props) {
    const classes = useStyles();
    const {
        setShowQrCode,
        showQrCode,
        previewQrDetails,
        download,
        showConfirmationModal,
        setShowConfirmationModal,
        qrDetails,
        setQrDetails,
        closeSlider,
        CloseSuccess,
        setCloseSuccess,
        setPreviewQrDetails
    } = props;
    const { globalState } = useGlobalStateContext();

    return (
        <Grid container>
            <Grid item xs={12}>
                <div>
                    <i className={styles.qrIcon}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                        >
                            <g>
                                <rect fill="none" height="24" width="24" />
                            </g>
                            <path d="M15,21h-2v-2h2V21z M13,14h-2v5h2V14z M21,12h-2v4h2V12z M19,10h-2v2h2V10z M7,12H5v2h2V12z M5,10H3v2h2V10z M12,5h2V3h-2V5 z M4.5,4.5v3h3v-3H4.5z M9,9H3V3h6V9z M4.5,16.5v3h3v-3H4.5z M9,21H3v-6h6V21z M16.5,4.5v3h3v-3H16.5z M21,9h-6V3h6V9z M19,19v-3 l-4,0v2h2v3h4v-2H19z M17,12l-4,0v2h4V12z M13,10H7v2h2v2h2v-2h2V10z M14,9V7h-2V5h-2v4L14,9z M6.75,5.25h-1.5v1.5h1.5V5.25z M6.75,17.25h-1.5v1.5h1.5V17.25z M18.75,5.25h-1.5v1.5h1.5V5.25z" />
                        </svg>
                    </i>
                    <strong className={styles.qrId}>{previewQrDetails.id}</strong>
                    <hr />
                </div>
                <div>
                    <CloseIcon className={styles.closeIcon} onClick={closeSlider} />
                </div>
            </Grid>
            <Grid item xs={12}>
                {/* <div className={styles.transactions}>
                    <div>
                        <span className={styles.fontColor}>Amount Received</span>
                        <br></br>
                        <span>
                            {formatCurrency(
                                previewQrDetails.amount,
                                getCurrencyThreeAlpha(globalState.merchant?.country)
                            )}
                        </span>
                    </div>
                    <div>
                        <span className={styles.fontColor}>Number of Payments</span>
                        <br></br>
                        <span>2</span>
                    </div>
                </div> */}
                <div className={styles.boxView}>
                    <Box className={styles.divTitle}>
                        <VisibilityOutlined
                            className={
                                previewQrDetails.status === QrCodeStatus.ACTIVE ? classes.active : classes.expired
                            }
                        />
                        <Button
                            rel="noopener"
                            style={{
                                backgroundColor: 'transparent',
                                textTransform: 'capitalize'
                            }}
                            className={
                                previewQrDetails.status === QrCodeStatus.ACTIVE ? classes.active : classes.expired
                            }
                            onClick={() => {
                                previewQrDetails.status === QrCodeStatus.ACTIVE && setShowQrCode(true);
                            }}
                        >
                            Preview QR
                        </Button>
                    </Box>
                    <span className={styles.verticalLine}>|</span>

                    <Box className={styles.divTitle}>
                        <div
                            onClick={() =>
                                previewQrDetails.status === QrCodeStatus.ACTIVE &&
                                download(previewQrDetails.qrImgLink, previewQrDetails.id)
                            }
                            className={styles.downloadButton}
                        >
                            <GetAppSharpIcon
                                className={
                                    previewQrDetails.status === QrCodeStatus.ACTIVE ? classes.active : classes.expired
                                }
                            />
                            <Button
                                rel="noopener"
                                style={{
                                    backgroundColor: 'transparent',
                                    textTransform: 'capitalize'
                                }}
                                className={
                                    previewQrDetails.status === QrCodeStatus.ACTIVE ? classes.active : classes.expired
                                }
                            >
                                Download QR
                            </Button>
                        </div>
                    </Box>
                </div>
                <TableContainer>
                    <Table aria-label="simple table" className={styles.tableBody}>
                        <TableBody>
                            <TableRow className={styles.tableBorder}>
                                <TableCell variant="head">Created At</TableCell>
                                <TableCell>
                                    <span className={styles.values}>
                                        {formatDateTimeByFormatStringV2(
                                            previewQrDetails.created_at,
                                            'DD MMM YYYY HH:mm'
                                        )}
                                    </span>
                                </TableCell>
                            </TableRow>
                            <TableRow className={styles.tableBorder}>
                                <TableCell variant="head">Status</TableCell>
                                <TableCell>
                                    <TransactionStatusLabel status={previewQrDetails.status} />
                                    {previewQrDetails.status === QrCodeStatus.ACTIVE && (
                                        <button
                                            class="btn btn-link"
                                            onClick={() => {
                                                setShowConfirmationModal(true);
                                            }}
                                        >
                                            Close
                                        </button>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className={styles.tableBorder}>
                                <TableCell variant="head">QR Name</TableCell>
                                <TableCell>
                                    <span className={styles.values}>
                                        {previewQrDetails.qrName ? previewQrDetails.qrName : '--'}
                                    </span>
                                </TableCell>
                            </TableRow>
                            {previewQrDetails.amount > 0.0 && (
                                <TableRow className={styles.tableBorder}>
                                    <TableCell variant="head">Payment Amount</TableCell>
                                    <TableCell>
                                        <span className={styles.values}>
                                            {formatCurrency(
                                                previewQrDetails.amount / 100,
                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                            )}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow className={styles.tableBorder}>
                                <TableCell variant="head">Closed At</TableCell>
                                <TableCell>
                                    <span className={styles.values}>
                                        {previewQrDetails.status === 'Closed'
                                            ? formatDateTimeByFormatStringV2(
                                                  previewQrDetails.updated_at,
                                                  'DD MMM YYYY HH:mm'
                                              )
                                            : '--'}
                                    </span>
                                </TableCell>
                            </TableRow>
                            <TableRow className={styles.tableBorder}>
                                <TableCell variant="head">Description</TableCell>
                                <TableCell>
                                    <span className={styles.values}>
                                        {previewQrDetails.description ? previewQrDetails.description : '--'}
                                    </span>
                                </TableCell>
                            </TableRow>
                            {previewQrDetails.typeOfQr === 'generic' && (
                                <TableRow className={styles.tableBorder}>
                                    <TableCell variant="head">Link</TableCell>
                                    <TableCell>
                                        <span className={styles.reason}>
                                            {previewQrDetails.link ? previewQrDetails.link : '--'}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )}
                            {previewQrDetails.typeOfQr === 'generic' && (
                                <TableRow className={styles.tableBorder}>
                                    <TableCell variant="head">Type Of QR</TableCell>
                                    <TableCell>
                                        <span className={styles.values}>Generic</span>
                                    </TableCell>
                                </TableRow>
                            )}
                            {previewQrDetails.typeOfQr === 'payment' && (
                                <TableRow className={styles.tableBorder}>
                                    <TableCell variant="head">Type Of QR</TableCell>
                                    <TableCell>
                                        <span className={styles.values}>
                                            {previewQrDetails.amount > 0.0
                                                ? 'Payment (Fixed amount)'
                                                : 'Payment (Variable amount)'}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )}
                            <TableRow className={styles.tableBorder}>
                                <TableCell variant="head">Expired By</TableCell>
                                <TableCell>
                                    <span className={styles.values}>
                                        {previewQrDetails.qrCodeExpiryDate
                                            ? formatDateTimeByFormatStringV2(
                                                  previewQrDetails.qrCodeExpiryDate,
                                                  'DD MMM YYYY HH:mm'
                                              )
                                            : '--'}
                                    </span>
                                </TableCell>
                            </TableRow>
                            {previewQrDetails.reason && (
                                <TableRow className={styles.tableBorder}>
                                    <TableCell variant="head">Reason To Close</TableCell>
                                    <TableCell>
                                        <span className={styles.reason}>{previewQrDetails.reason}</span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <hr />
                {/* <div className={styles.payments}>
                    <span className={styles.fontColor}>Recent Payments</span>
                    <Link href="#" target="_blank" rel="noopener">
                        View All Payments
                    </Link>
                </div> */}
            </Grid>
            <ModalPreviewQrCode
                previewQrDetails={previewQrDetails}
                showQrCode={showQrCode}
                setShowQrCode={setShowQrCode}
            />

            {showConfirmationModal && (
                <ModalReasonToClose
                    showConfirmationModal={showConfirmationModal}
                    setShowConfirmationModal={setShowConfirmationModal}
                    qrDetails={qrDetails}
                    setQrDetails={setQrDetails}
                    id={previewQrDetails.id}
                    CloseSuccess={CloseSuccess}
                    setCloseSuccess={setCloseSuccess}
                    setPreviewQrDetails={setPreviewQrDetails}
                    previewQrDetails={previewQrDetails}
                />
            )}
        </Grid>
    );
}
