import React from 'react';
import styles from './loader.module.scss';

const LoaderComponent = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.loader__component}></div>
        </div>
    );
};

export default LoaderComponent;
