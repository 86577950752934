import React, { useEffect, useState } from 'react';
import PendingCustomerInfoComponent from './pending-customer-info.component';
import { AdminService } from 'services';
export default function PendingCustomerInfo() {
    const [pendingCustomersData, setPendingCustomersData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const pendingCustomers = await AdminService.GetPendingCustomers();
            if (pendingCustomers.isSuccesfully) {
                setPendingCustomersData(pendingCustomers.data);
            }
        };
        fetchData();
        setIsLoading(false);
    }, []);

    return <PendingCustomerInfoComponent rows={pendingCustomersData} isLoading={isLoading} />;
}
