import React from 'react';
import PropTypes from 'prop-types';

import PdqListTable from '../transaction-tables/pdq-list-table/pdq-list-table.container';

export default function PdqTransactionsListComponent(props) {
    return (
        <div>
            <PdqListTable {...props} />
        </div>
    );
}

PdqTransactionsListComponent.propTypes = {
    list: PropTypes.array,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    totalPDQ: PropTypes.func
};
