import React, { useState, useEffect } from 'react';
import SignUpFormComponent from './signup-form.component';
import { AuthService, UserService, NonResellerMerchantService } from 'services';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ALIAS } from '../../utils/constants';
import qs from 'query-string';
import _ from 'lodash';
import AuthHelper from 'utils/authHelper';
import { useGlobalStateContext } from 'contexts';
import { supportedLanguages } from 'utils/constants';
import { getT2SReferralLinkPayload } from 'utils/getT2SReferralLinkPayload';
import { MerchantCountriesDialCode } from 'utils/enums/MerchantCountries';
import { InputValidators } from 'validators';

function SignUpForm() {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [signUpError, setSignUpError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState(MerchantCountriesDialCode['United Kingdom']);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [phoneCountryIdentificator, setPhoneCountryIdentificator] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [redoPasswordValidation, setRedoPasswordValidation] = useState(false);
    const [signature, setSignature] = useState('');
    const local = navigator.language;
    const locale = supportedLanguages.find((language) => language.locale === local) ? local : 'en-GB';

    const messages = {
        invalidConfirmPassword: 'Passwords do not match',
        invalidOldPassword: 'Invalid old password',
        oldPassAndNewPassMatch: 'Old password and new password match',
        signUpError: 'An error has occurred. Please try again later.',
        verifyEmail: 'Thank you for the registration! We will redirect you to the homepage.',
        thitdPartyIdExistMessage: 'Sorry, but this referral link is already in the system.'
    };

    const [forceChangePassword, setForceChangePassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [successSignupInvite, setSuccessSignupInvite] = useState(false);
    const [isUserInvited, setIsUserInvited] = useState(false);
    const [thirdPartyResellerUrlParameter, setThirdPartyResellerUrlParameter] = useState('');
    const [thirdPartyCustomerKey, setThirdPartyCustomerKey] = useState(null);
    const [referralData, setReferralData] = useState(null);
    const [isT2SUser, setIsT2SUser] = useState(false);
    const location = useLocation();
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);

    useEffect(() => {
        const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (queryString?.q && !queryString?.signature) {
            setRoute('/login');
        }
        if (queryString?.q && queryString?.signature) {
            const data = getT2SReferralLinkPayload(queryString.q, { globalState, setRoute });
            setThirdPartyResellerUrlParameter('?q=' + queryString.q);
            setThirdPartyCustomerKey(queryString.q);
            setReferralData(data);
            setIsT2SUser(true);
            setFirstName(data?.OwnerFirstName || '');
            setLastName(data?.OwnerLastName || '');
            setEmail(data?.OwnerEmail || '');
            setSignature(queryString?.signature);

            if (data?.OwnerPhoneNumber) {
                let dialCode =
                    MerchantCountriesDialCode[data.OwnerAddressCountry] || MerchantCountriesDialCode['United Kingdom'];
                if (data.OwnerPhoneNumber.toString().startsWith('0')) {
                    setPhone(dialCode + data.OwnerPhoneNumber.toString().substr(1));
                } else if (data.OwnerPhoneNumber.toString().startsWith('+')) {
                    setPhone(data?.OwnerPhoneNumber);
                } else {
                    setPhone(dialCode + data?.OwnerPhoneNumber);
                }
            }
        }

        const fetchData = async () => {
            const userDetails = await UserService.getUserByEmail(location.state.email);
            setEmail(userDetails.user.email);
            setFirstName(userDetails.user.firstName);
            setLastName(userDetails.user.lastName);
            setForceChangePassword(true);
        };

        if (location && location.state) {
            fetchData();
        } else if (queryString.email && queryString.oldPassword) {
            setIsUserInvited(true);
            setForceChangePassword(true);
            setOldPassword(decodeURIComponent(queryString.oldPassword));
            setEmail(decodeURIComponent(queryString.email));

            if (queryString.phone) setPhone(queryString.phone);
        }
        // eslint-disable-next-line
    }, [location, thirdPartyResellerUrlParameter, setThirdPartyResellerUrlParameter]);

    useEffect(() => {
        let tmpPhoneIsValid = false;
        if (isT2SUser) {
            if (phone.length > 6) {
                tmpPhoneIsValid = true;
            } else {
                tmpPhoneIsValid = false;
            }
        } else {
            tmpPhoneIsValid = true;
        }

        setIsPhoneValid(tmpPhoneIsValid);
    }, [phone, isT2SUser]);

    useEffect(() => {
        if (InputValidators.isEmailValid(email)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }, [email]);

    const handleSubmit = (event) => {
        setVerifyEmail(false);
        setSignUpError(false);
        setIsLoading(true);

        if (password !== confirmPassword) {
            setSignUpError(true);
            setErrorMessage(messages.invalidConfirmPassword);
            event.preventDefault();
            event.stopPropagation();
            setIsLoading(false);
            return;
        }

        if (forceChangePassword && oldPassword === password) {
            setSignUpError(true);
            setErrorMessage(messages.oldPassAndNewPassMatch);
            event.preventDefault();
            event.stopPropagation();
            setIsLoading(false);
            return;
        }

        if (forceChangePassword) {
            let phoneWithoutZero = phone.slice();
            if (phoneWithoutZero[phoneCountryIdentificator.length] === '0') {
                phoneWithoutZero =
                    phoneWithoutZero.slice(0, phoneCountryIdentificator.length) +
                    phoneWithoutZero.slice(phoneCountryIdentificator.length + 1);
            }

            AuthService.completeInvite({
                username: email,
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                oldPassword: oldPassword,
                phone: phoneWithoutZero
                    ? phoneWithoutZero.startsWith('+')
                        ? phoneWithoutZero
                        : '+' + phoneWithoutZero
                    : ''
            }).then((response) => {
                if (response.isSuccesfully) {
                    setSuccessSignupInvite(true);
                    Auth.signIn({
                        username: email,
                        password: password
                    })
                        .then((response) => {
                            sessionStorage.setItem('signUp', true);
                            UserService.getUserByCognitoUserName(response.attributes.sub).then((response) => {
                                localStorage.setItem('selectedLanguage', locale);
                                localStorage.setItem('user', JSON.stringify(response.user));
                                setGlobalState({ ...globalState, user: response.user });
                                setIsLoading(false);
                                setRoute('/home');
                            });
                        })
                        .catch((err) => {
                            setErrorMessage(err.message);
                            setIsLoading(false);
                            event.preventDefault();
                            event.stopPropagation();
                        });
                } else {
                    setSignUpError(true);
                    response.data.error ? setErrorMessage(response.data.error) : setErrorMessage(messages.signUpError);
                    setPassword('');
                    setConfirmPassword('');
                    setIsLoading(false);
                }
            });
        } else {
            //const params = new URLSearchParams(window.location.href);
            //const merchantId = params.get('merchantId');
            let phoneWithoutZero = phone.slice();
            if (phoneWithoutZero[phoneCountryIdentificator.length] === '0') {
                phoneWithoutZero =
                    phoneWithoutZero.slice(0, phoneCountryIdentificator.length) +
                    phoneWithoutZero.slice(phoneCountryIdentificator.length + 1);
            }

            Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    given_name: firstName,
                    family_name: lastName,
                    'custom:alias': ALIAS,
                    'custom:resellerUrl': globalState.reseller?.portalURL,
                    phone_number: phoneWithoutZero
                        ? phoneWithoutZero.startsWith('+')
                            ? phoneWithoutZero
                            : '+' + phoneWithoutZero
                        : '',
                    'custom:request3PartyData': thirdPartyCustomerKey,
                    'custom:requestSignature': signature
                }
            })
                .then(() => {
                    setVerifyEmail(true);

                    Auth.signIn({
                        username: email,
                        password: password
                    })
                        .then((response) => {
                            // saving referral data in db while sign up
                            if (thirdPartyCustomerKey) {
                                UserService.saveReferralDataString({ referralDataValue: thirdPartyCustomerKey });
                            }

                            UserService.getUserByCognitoUserName(response.attributes.sub).then(async (response) => {
                                localStorage.setItem('selectedLanguage', locale);
                                localStorage.setItem('user', JSON.stringify(response.user));
                                if (thirdPartyCustomerKey) {
                                    let merchantCreationResp = await NonResellerMerchantService.createUserMerchantUsingThirdPartyCustomerKey(
                                        thirdPartyCustomerKey,
                                        globalState.reseller?.id
                                    );
                                    if (merchantCreationResp?.isSuccesfully) {
                                        let merchantRetrivalResp = await NonResellerMerchantService.getUserMerchants(
                                            globalState.reseller?.id
                                        );
                                        if (merchantRetrivalResp?.isSuccesfully) {
                                            let merchantsDto = merchantRetrivalResp?.data?.merchantsDto;
                                            merchantsDto = _.sortBy(merchantsDto, ['id']);
                                            let merchant = merchantsDto[merchantsDto.length - 1];
                                            if (merchant && merchant.id) {
                                                const newTokenReponse = await AuthService.requestNewToken();
                                                await AuthHelper.refreshCurrentSession();
                                                if (newTokenReponse.isSuccesfully) {
                                                    sessionStorage.setItem('merchant', JSON.stringify(merchant));
                                                    setGlobalState({ ...globalState, merchant, user: response.user });
                                                    setIsLoading(false);
                                                    setRoute('/onboarding');
                                                    return;
                                                }
                                            }
                                        }
                                    }
                                }
                                setGlobalState({ ...globalState, user: response.user });
                                setIsLoading(false);
                                setRoute('/home' + thirdPartyResellerUrlParameter);
                            });
                        })
                        .catch((err) => {
                            setErrorMessage(err.message);
                            setIsLoading(false);

                            event.preventDefault();
                            event.stopPropagation();
                        });
                })
                .catch((err) => {
                    setSignUpError(true);
                    setErrorMessage(err.message);
                    setPassword('');
                    setConfirmPassword('');
                    setIsLoading(false);
                });
        }

        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <SignUpFormComponent
            onSubmit={handleSubmit}
            email={email}
            setEmail={setEmail}
            password={password}
            messages={messages}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            signUpError={signUpError}
            setSignUpError={setSignUpError}
            errorMessage={errorMessage}
            verifyEmail={verifyEmail}
            setVerifyEmail={setVerifyEmail}
            lastName={lastName}
            setLastName={setLastName}
            firstName={firstName}
            setFirstName={setFirstName}
            isLoading={isLoading}
            forceChangePassword={forceChangePassword}
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            successSignupInvite={successSignupInvite}
            setSuccessSignupInvite={setSuccessSignupInvite}
            isUserInvited={isUserInvited}
            phone={phone}
            setPhone={setPhone}
            isT2SUser={isT2SUser}
            setIsPasswordValid={setIsPasswordValid}
            isPasswordValid={isPasswordValid}
            isPhoneValid={isPhoneValid}
            thirdPartyResellerUrlParameter={thirdPartyResellerUrlParameter}
            setPhoneCountryIdentificator={setPhoneCountryIdentificator}
            redoPasswordValidation={redoPasswordValidation}
            setRedoPasswordValidation={setRedoPasswordValidation}
            referralData={referralData}
            isEmailValid={isEmailValid}
        />
    );
}

export default SignUpForm;
