import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    Button,
    Grid,
    DialogContent,
    DialogActions,
    DialogTitle,
    DialogContentText,
    TextareaAutosize,
    CircularProgress,
    Box
} from '@material-ui/core';
import { FormItem } from 'components/onboarding/onboarding-ui-components';
import styles from './modal-reason-to-close.module.scss';
import { SnackBarAndAlert } from 'components';

function ModalReasonToCloseComponent(props) {
    const {
        showConfirmationModal,
        setShowConfirmationModal,
        handleSubmit,
        isLoading,
        CloseSuccess,
        showMessage,
        setShowMessage,
        handleCancel,
        reason,
        setReason
    } = props;

    return (
        <Dialog
            open={showConfirmationModal}
            onClose={(() => setShowConfirmationModal(false), handleCancel)}
            styles={{ justifyContent: 'center' }}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Close QR Code
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>
                        This QR code will be made inactive, your customers will no longer be able to pay via this QR
                        code.
                    </div>
                    <Grid xs={12}>
                        <FormItem>
                            <span>Reason to close this QR code</span>
                            <TextareaAutosize
                                id="name-input"
                                className={styles.reasonInput}
                                rows="2"
                                disableUnderline
                                type="text"
                                required
                                autoComplete="off"
                                name="query"
                                maxLength={120}
                                value={reason}
                                onChange={(event) => {
                                    let value = event.target.value.replace(/(\s{2,})|[^a-zA-Z0-9.,;?]/g, ' ');
                                    setReason(value);
                                }}
                            />
                        </FormItem>
                    </Grid>
                </DialogContentText>
                {isLoading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DialogActions>
                        <div className={styles.btnContainer}>
                            <Button className={styles.qrBtn} variant="contained" color="grey" onClick={handleCancel}>
                                No
                            </Button>
                            <Button
                                className={styles.submitBtn}
                                variant="contained"
                                disabled={!reason.trim()}
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Yes
                            </Button>
                        </div>
                    </DialogActions>
                )}
                {CloseSuccess && (
                    <SnackBarAndAlert open={showMessage} onClose={() => setShowMessage('')} type={'success'}>
                        {showMessage}
                    </SnackBarAndAlert>
                )}
            </DialogContent>
        </Dialog>
    );
}

ModalReasonToCloseComponent.propTypes = {
    setShowQrCode: PropTypes.bool
};

export default ModalReasonToCloseComponent;
