import React, { useEffect, useState } from 'react';
import { PaymentsService } from 'services';
import { useGlobalStateContext } from 'contexts';
const GrossVolumeGraphComponent = React.lazy(() => import('./gross-volume-graph.component'));

function GrossVolumeGraph() {
    const { globalState } = useGlobalStateContext();
    const numOfMonths = 12;
    const numOfDays = 10;
    const today = new Date();
    const [timePeriod, setTimePeriod] = useState('last30days');
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(today.setDate(today.getDate() - numOfDays + 1));
    const [dateValue, setDateValue] = useState(new Date());
    const [redrawKey, setRedrawKey] = useState(0);

    useEffect(() => {
        const getPaymentsList = async () => {
            setIsLoading(true);
            const data = [];
            if (timePeriod === 'last30days') {
                const date = new Date();

                const lastMonthPayments = await PaymentsService.GetPaymentsTotal({
                    month: date.getMonth() - 1,
                    year: date.getFullYear(),
                    via: 'MONTH'
                });

                const currentMonthPayments = await PaymentsService.GetPaymentsTotal({
                    month: date.getMonth(),
                    year: date.getFullYear(),
                    via: 'MONTH'
                });

                const allPayments = lastMonthPayments?.data?.transactions?.concat(
                    currentMonthPayments?.data?.transactions
                );

                //On the right side, calculating the day that was 30 days ago.
                const last30DaysPayments = allPayments?.filter(
                    (x) => new Date(x.time) > new Date(new Date().setDate(date.getDate() - 30))
                );

                for (let i = 1; i <= 30; ++i) {
                    const dayName = `${date.getDate().toString()} ${date.toLocaleString('default', {
                        month: 'short'
                    })}`;
                    const dayTransactionsAmount = last30DaysPayments
                        ?.filter((x) => new Date(x.time).getDate() === i)
                        .map((x) => parseFloat(x.total));
                    if (dayTransactionsAmount && dayTransactionsAmount.length > 0) {
                        const daySum = dayTransactionsAmount.reduce((x, y) => x + y, 0);
                        data.push({ name: dayName, value: daySum });
                        date.setDate(date.getDate() + 1);
                    }
                    data.push({ name: dayName, value: 0 });
                    date.setDate(date.getDate() + 1);
                }
            } else if (timePeriod === 'month') {
                const date = new Date();
                date.setFullYear(date.getFullYear() - 1);
                date.setMonth(date.getMonth() + 1);

                for (let i = 1; i <= numOfMonths; ++i) {
                    const payments = await PaymentsService.GetPaymentsTotal({
                        month: date.getMonth() + 1,
                        year: date.getFullYear(),
                        via: 'MONTH'
                    });
                    const monthName = date.toLocaleString('default', { month: 'short' });
                    data.push({ name: monthName, value: payments.data.total });
                    date.setMonth(date.getMonth() + 1);
                }
            } else {
                const date = new Date(startDate);

                for (let i = 1; i <= numOfDays; ++i) {
                    const payments = await PaymentsService.GetPaymentsTotal({
                        day: date.getDate(),
                        month: date.getMonth() + 1,
                        year: date.getFullYear(),
                        via: 'DAY'
                    });
                    const dayName = `${date.getDate().toString()} ${date.toLocaleString('default', {
                        month: 'short'
                    })}`;
                    data.push({ name: dayName, value: payments.data.total });
                    date.setDate(date.getDate() + 1);
                }
            }
            setChartData(data);
            setIsLoading(false);
        };

        getPaymentsList();
    }, [timePeriod, startDate]);

    const setStartDateHandler = (selectedDate) => {
        const selectedDateTimeStamp = selectedDate.getTime();

        const merchantCreationDate = new Date(globalState?.merchant.createdAt);
        const merchantCreationTimeStamp = merchantCreationDate.getTime();

        const tenDaysUpToNow = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000);
        const tenDaysUpToNowTimeStamp = tenDaysUpToNow.getTime();

        if (selectedDateTimeStamp > merchantCreationTimeStamp && selectedDateTimeStamp < tenDaysUpToNowTimeStamp) {
            setDateValue(new Date(selectedDate));
            setStartDate(selectedDate);
        } else if (selectedDateTimeStamp < merchantCreationTimeStamp) {
            setDateValue(new Date(merchantCreationDate));
            setStartDate(merchantCreationDate);
        } else {
            setDateValue(new Date(tenDaysUpToNow));
            setStartDate(tenDaysUpToNow);
        }

        setRedrawKey((v) => v + 1);
    };

    return (
        <GrossVolumeGraphComponent
            chartData={chartData}
            timePeriod={timePeriod}
            setTimePeriod={setTimePeriod}
            isLoading={isLoading}
            setStartDateHandler={setStartDateHandler}
            dateValue={dateValue}
            redrawKey={redrawKey}
        ></GrossVolumeGraphComponent>
    );
}

export default GrossVolumeGraph;
