export const PayoutsSummaryTypes = {
    charge: 'Charge',
    payment: 'Charge',
    refund: 'Refund',
    payment_refund: 'Refund',
    payout_cancel: 'Payout Reversal',
    payout_failure: 'Payout Reversal',
    transfer: 'Transfer',
    recipient_transfer: 'Transfer',
    transfer_cancel: 'Transfer reversal',
    transfer_failure: 'Transfer reversal',
    recipient_transfer_cancel: 'Transfer reversal',
    recipient_transfer_failure: 'Transfer reversal'
};
