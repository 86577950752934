import moment from 'moment-timezone';
import React from 'react';
import {
    Paper,
    // Accordion,
    // AccordionSummary,
    // AccordionDetails,
    Typography,
    List,
    ListItem,
    Link,
    Grid
} from '@material-ui/core';
import { LoadingScreen, SnackBarAndAlert } from 'components';
import style from './merchant-dashboard-options.module.scss';
import { /*ChevronRight, ExpandMore,*/ CheckCircleSharp, Cancel, Clear } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useMerchantCreationContext, useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { Roles } from 'utils/enums/Roles';
import { ErrorOutline } from '@material-ui/icons';

function MerchantDashboardOptionsComponent(props) {
    const { globalState, setRoute } = useGlobalStateContext();
    const { merchantCreationState, setMerchantCreationState } = useMerchantCreationContext();
    const passwordAge = moment().diff(moment(globalState.user.updatedPasswordAt), 'days');
    const passwordExpiryDate = moment(globalState.user.updatedPasswordAt).add(90, 'days').format('DD-MM-YYYY');
    const passwordExpiryBannerVisibility = 75; // After 75 days of last password update we will show the banner
    const {
        verified,
        setVerified,
        reseller,
        sendVerificationEmail,
        showMessage,
        setShowMessage,
        verificationEmailMessage,
        messageType,
        isLoading,
        snackbar,
        userHasSeenSnackbar
    } = props;

    const isOwner = globalState.merchant?.userRole === Roles.Owner;
    const isAdmin = globalState.user?.UserType?.name === Roles.Admin;
    let hidePaper = !(verified && !props.user?.isEmailConfirmed) && !merchantCreationState.showMerchantCreationMessage;
    const showBanner = passwordAge >= passwordExpiryBannerVisibility;

    /*const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }; */
    return (
        <>
            <Paper className={`${style.stepsContainer} ${hidePaper ? style.hidePaper : ''}`}>
                <List className={style.list}>
                    {/* 
                 //TODO: Was commented because this is not needed for MVP
                <ListItem className={style.listItem}>
                    <Typography color="primary">
                        <span className={style.mainTitle}>Follow these steps to get started</span>
                    </Typography>
                </ListItem>

                <ListItem className={`${style.listItem} ${style.expandedBackGroundColor}`}>
                    <Accordion
                        className={`${style.accordionItem} ${style.expandedBackGroundColor}`}
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                    >
                        <AccordionSummary>
                            <div className={style.summaryContainer}>
                                {expanded === 'panel1' ? <ExpandMore /> : <ChevronRight />}

                                <Typography color="primary" component={'div'}>
                                    <div className={style.itemContainerText}>
                                        <div className={style.textBold}>
                                            Find the right integration for your business
                                        </div>
                                        Browse our docs for use cases, sample code, and tools or see options without
                                        code
                                    </div>
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={style.accordionDetails}>
                            <Button variant="contained" color="primary" className={style.accordionDetailsBtn}>
                                <span className={style.btnText}>Browse docs</span>
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                </ListItem>

                <ListItem className={`${style.listItem}`}>
                    <Accordion
                        className={style.accordionItem}
                        expanded={expanded === 'panel2'}
                        onChange={handleChange('panel2')}
                    >
                        <AccordionSummary>
                            <div className={style.summaryContainer}>
                                {expanded === 'panel2' ? <ExpandMore /> : <ChevronRight />}
                                <Typography color="primary">
                                    <span className={`${style.textBold} ${style.itemContainerText}`}>
                                        Get your API keys
                                    </span>
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={style.accordionDetails}>
                            <Typography color="primary">
                                <span>nan..(n.n)</span>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </ListItem> */}

                    {verified && !props.user?.isEmailConfirmed ? (
                        <ListItem className={style.listItem}>
                            <div className={style.listItemContainer}>
                                <Grid container>
                                    <Grid item xs={1} className={style.center}>
                                        {props.user?.isEmailConfirmed ? (
                                            <CheckCircleSharp className={style.success_icon} />
                                        ) : (
                                            <Cancel className={style.error_icon} />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={11}
                                        direction={'row'}
                                        container
                                        className={style.merchant__creation__container}
                                    >
                                        <Grid container item xs={12}>
                                            <Grid item xs={11}>
                                                <Typography color="primary">
                                                    <p className={`${style.textBold} ${style.itemContainerText2}`}>
                                                        The email&nbsp;
                                                        <Link
                                                            className={`${style.textBold} ${style.linkFont}`}
                                                            href="#"
                                                            variant="body2"
                                                        >
                                                            {props.user?.email}
                                                        </Link>
                                                        {' is '}
                                                        {props.user?.isEmailConfirmed
                                                            ? 'verified.'
                                                            : reseller.name === ResellerType.DATMAN
                                                            ? 'not verified.'
                                                            : 'not verified. Once you have verified your email you can begin the onboarding process.'}
                                                        {!props.user?.isEmailConfirmed && (
                                                            <span>
                                                                &nbsp; Click &nbsp;
                                                                <span
                                                                    className={`${style.textBold} ${style.linkFont}`}
                                                                    onClick={sendVerificationEmail}
                                                                >
                                                                    here &nbsp;
                                                                </span>
                                                                to receive verification email.
                                                            </span>
                                                        )}
                                                    </p>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Clear
                                                    className={style.merchant_remove_icon}
                                                    onClick={() => setVerified(false)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </ListItem>
                    ) : null}

                    {merchantCreationState.showMerchantCreationMessage && (
                        <ListItem className={`${style.listItem} ${style.last__listItem}`}>
                            <div className={style.listItemContainer}>
                                <Grid container>
                                    <Grid item xs={1} className={style.center}>
                                        <CheckCircleSharp className={style.success_icon} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={11}
                                        direction={'row'}
                                        container
                                        className={style.merchant__creation__container}
                                    >
                                        <Typography color="primary" className={style.text__option__container}>
                                            <p className={`${style.textBold} ${style.itemContainerText2}`}>
                                                Your new Merchant account has been added. Please click on Onboard
                                                Merchant in order to activate your account.
                                            </p>
                                        </Typography>
                                        <Clear
                                            className={style.merchant__creation__message__remove_icon}
                                            onClick={() =>
                                                setMerchantCreationState((state) => ({
                                                    ...state,
                                                    showMerchantCreationMessage: false
                                                }))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </ListItem>
                    )}
                    {showMessage && (
                        <SnackBarAndAlert open={showMessage} onClose={() => setShowMessage(false)} type={messageType}>
                            {verificationEmailMessage}
                        </SnackBarAndAlert>
                    )}
                </List>
                <LoadingScreen open={isLoading} />
            </Paper>
            {showBanner && (
                <div className={`${style.bannerStyle}`}>
                    <div className={`${style.bannerMessageStyle}`}>
                        <div className={style.iconUnverified}>
                            <ErrorOutline className={style.error_icon} />
                        </div>
                        <Typography color="primary">
                            <div className={style.bannerMessage}>
                                Your password will expire on {passwordExpiryDate}, please{' '}
                                <span
                                    className={style.passwordExpiryBanner}
                                    onClick={() => setRoute('/update-password')}
                                >
                                    update your password.
                                </span>
                                &nbsp;
                            </div>
                        </Typography>
                    </div>
                </div>
            )}
            {snackbar && !userHasSeenSnackbar && (
                <SnackBarAndAlert open={snackbar.length > 0} type="success">
                    {snackbar}
                </SnackBarAndAlert>
            )}
            {(isOwner || isAdmin) &&
                [MerchantStatus.NOT_SUBMITTED].includes(globalState?.merchant?.status) &&
                globalState.reseller?.id === 2 &&
                props.user?.isEmailConfirmed && (
                    <div
                        className={`${style.continueOnboardingContainer}
                                ${style.continueOnboarding}`}
                    >
                        <Typography color="primary" style={{ fontSize: '15px' }}>
                            <React.Fragment>
                                <div>
                                    Your Onboarding is not yet complete. Please click{' '}
                                    <span
                                        className={`${style.textBold} ${style.linkFont}`}
                                        onClick={() => setRoute('/onboarding')}
                                    >
                                        here
                                    </span>{' '}
                                    to complete onboarding.
                                </div>
                            </React.Fragment>
                        </Typography>
                    </div>
                )}
        </>
    );
}

MerchantDashboardOptionsComponent.propTypes = {
    user: PropTypes.object
};

export default MerchantDashboardOptionsComponent;
