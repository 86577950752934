import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TableContainer,
    Table,
    Paper,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    FormControl,
    NativeSelect,
    Input,
    Typography,
    Divider,
    Grid,
    Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Label } from 'components/onboarding/onboarding-ui-components';
import { Resolution } from 'utils/enums/Action';

import styles from './modal-chargeback-list-action.module.scss';
import TableHeader from '../payments/transaction-tables/table-header/table-header.container';
import { LoadingScreen } from 'components';
import { formatDateTimeByFormatStringV2 } from 'utils/helper';

export default function ModalChargebackActionComponent(props) {
    const {
        openModal,
        setOpenModal,
        wrapperRef,
        headCells,
        chargebackDropdown,
        setChargebackDropdown,
        chargebackDate,
        setChargebackDate,
        chargebackComments,
        setChargebackComments,
        chargebackOutcome,
        setChargebackOutcome,
        chargebackBtn,
        chargebackBtnHandler,
        loading,
        chargebackStatus,
        showingReceiptData
    } = props;

    const showReceipt = chargebackStatus.chargebackStatus ? true : false;

    let receiptDate = showingReceiptData(chargebackStatus.chargebackDate, chargebackDate);
    let receiptReason = showingReceiptData(chargebackStatus.chargebackReason, chargebackDropdown);
    let receiptComments = showingReceiptData(chargebackStatus.chargebackComments, chargebackComments);
    let receiptOutcome = showingReceiptData(chargebackStatus.chargebackOutcome, chargebackOutcome);

    return (
        <Dialog
            ref={wrapperRef}
            open={openModal}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className={styles.dialog}
        >
            <div>
                <LoadingScreen open={loading} />

                <DialogTitle sx={{ m: 0, p: 1 }} className={styles.title}>
                    <div className={styles.dialogTitle}>
                        <Typography>Charge Back Details</Typography>
                        <CloseIcon
                            className={styles.closeIcon}
                            onClick={() => {
                                setOpenModal(false);
                            }}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Divider />
                </DialogContent>

                <DialogContent className={styles.dialogContent}>
                    <div>
                        {!showReceipt ? (
                            <Grid
                                container
                                justifyContent="space-evenly"
                                direction="column"
                                alignItems="left"
                                xs={12}
                                md={12}
                            >
                                <Grid container xs={12} md={12} spacing={2} direction="column">
                                    <Grid item xs={12} md={12}>
                                        <Label text="Date"></Label>
                                        <TextField
                                            id="date"
                                            type="date"
                                            variant="outlined"
                                            className={styles.datePicker}
                                            value={chargebackDate}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                style: {
                                                    padding: '10px'
                                                },
                                                min: new Date().toISOString().slice(0, 10)
                                            }}
                                            onChange={(e) => setChargebackDate(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Label id="chargeback-reason" text="Chargeback Reason"></Label>
                                        <Grid item xs={12} md={12}>
                                            <FormControl>
                                                <NativeSelect
                                                    className={styles.datePickerReason}
                                                    input={
                                                        <Input
                                                            id="merchant-receipt-select"
                                                            value={chargebackDropdown}
                                                            onChange={(e) => {
                                                                setChargebackDropdown(e.target.value);
                                                            }}
                                                        ></Input>
                                                    }
                                                    disableUnderline
                                                >
                                                    <option value="">Select Reason</option>
                                                    <option value="Goods or services not received">
                                                        Good or services not received
                                                    </option>
                                                    <option value="Transaction not recognised">
                                                        Transaction not recognised
                                                    </option>
                                                    <option value="Duplicate Payment">Duplicate payment</option>
                                                    <option value="Defective goods">Defective goods</option>
                                                    <option value="Other">Other</option>
                                                </NativeSelect>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Label text="Comments"></Label>
                                        <TextField
                                            id="date"
                                            type="text"
                                            variant="outlined"
                                            className={styles.datePicker}
                                            value={chargebackComments}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                style: {
                                                    padding: '10px'
                                                },
                                                maxLength: 150
                                            }}
                                            onChange={(e) => setChargebackComments(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Label text="Outcome"></Label>
                                        <FormControl>
                                            <NativeSelect
                                                className={styles.datePickerReason}
                                                input={
                                                    <Input
                                                        id="merchant-receipt-select"
                                                        value={chargebackOutcome}
                                                        onChange={(e) => {
                                                            setChargebackOutcome(e.target.value);
                                                        }}
                                                    ></Input>
                                                }
                                                disableUnderline
                                            >
                                                {Resolution.map((reason) => {
                                                    return <option value={reason.key}>{reason.value}</option>;
                                                })}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12} className={styles.dialogbtn}>
                                        <Button
                                            variant="contained"
                                            onClick={chargebackBtnHandler}
                                            disabled={chargebackBtn}
                                            className={styles.refundProcess}
                                            size="small"
                                            color="primary"
                                        >
                                            Add Record
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                justifyContent="space-evenly"
                                direction="column"
                                alignItems="center"
                                xs={12}
                                md={12}
                            >
                                <Grid container xs={12} md={12} spacing={2} direction="column">
                                    <Grid item xs={12} md={12} className={styles.banner}>
                                        <p className={styles.bannerPara}>
                                            Chargeback has already been added to this customer
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} md={12} spacing={2} direction="column" className={styles.table}>
                                    <Paper className={styles.paper}>
                                        <TableContainer className={styles.TableContainer}>
                                            <Table
                                                aria-label="simple table"
                                                aria-labelledby="tableTitle"
                                                size="small"
                                                stickyHeader
                                                component={Paper}
                                                sx={{ minWidth: 650 }}
                                            >
                                                <TableHeader headCells={headCells} />
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={styles.tableDataCell}>
                                                            {formatDateTimeByFormatStringV2(
                                                                receiptDate,
                                                                'DD MMM YYYY '
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={styles.tableDataCell}>
                                                            {receiptReason}
                                                        </TableCell>
                                                        <TableCell className={styles.tableDataCell}>
                                                            {receiptComments}
                                                        </TableCell>
                                                        <TableCell className={styles.tableDataCell}>
                                                            {receiptOutcome}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
}
