import React from 'react';
import style from './generic-qr-code-info.module.scss';
import { ModalCreateQrCode, ModalPreviewQrCode } from 'components';
import { stableSort, getComparator } from 'utils/helper';
import { QrCodeStatus } from 'utils/enums/QrCode';
import {
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    Button,
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableFooter,
    TablePagination,
    useMediaQuery,
    Tooltip,
    Link,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
// import { useGlobalStateContext } from 'contexts';
import { VisibilityOutlined } from '@material-ui/icons';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import SlidingPane from 'react-sliding-pane';
import { formatDateTimeByFormatStringV2 } from '../../utils/helper';
import { QrDetailedView, SnackBarAndAlert } from 'components';
import TableHeader from 'components/payments/transaction-tables/table-header/table-header.container';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import TransactionStatusLabel from '../payments/transaction-tables/transaction-status-label/transaction-status-label.container';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    },
    container: {
        'overflow-y': 'visible'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    slidePaneOverlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
        zIndex: 999
    },
    slidePanel: {
        boxShadow: 'none !important'
    },
    active: {
        backgroundColor: 'transparent',
        color: 'rgb(35, 147, 240)',
        textTransform: 'capitalize',
        cursor: 'pointer',
        fontSize: '1.1 rem'
    },
    expired: {
        backgroundColor: 'transparent',
        color: 'grey',
        textTransform: 'capitalize',
        cursor: 'pointer',
        fontSize: '1.1 rem'
    }
}));

const GenericQrCodeInfoComponent = (props) => {
    const classes = useStyles();
    const {
        showCreteQr,
        setShowCreateQr,
        setSelectPaymentQr,
        selectPaymentQr,
        setQrCodeData,
        qrCodeData,
        order,
        orderBy,
        isPaneOpen,
        setIsPaneOpen,
        loading,
        previewQrDetails,
        count,
        setCount,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        setQrDetails,
        qrDetails,
        setSuccess,
        success,
        setShowMessage,
        showMessage,
        setStatusFilter,
        statusFilter,
        setSearchInput,
        clearBtn,
        searchInput,
        previewData,
        showQrCode,
        setShowQrCode,
        download,
        CloseSuccess,
        setCloseSuccess,
        setPreviewQrDetails,
        handleOnClick,
        setPage,
        onSearchQRCodes,
        filterQrCodes,
        handleCopyUrl,
        isCopyAlertOpen,
        setIsCopyAlertOpen
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:640px)');

    return (
        <div className={style.mainDiv}>
            <Paper className={style.settingsContainer}>
                <Box>
                    <div className={style.createQrContainer}>
                        <Box className={style.divTitle}>
                            <Typography color="primary" component="span">
                                <span className={style.qrTitle}>QR Code</span>
                            </Typography>
                        </Box>
                        {!mediaLessThan600px && (
                            <Box className={style.navContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setSelectPaymentQr(true);
                                        setShowCreateQr(true);
                                    }}
                                    className={style.createQrBtn}
                                >
                                    + Create QR Code
                                </Button>
                            </Box>
                        )}
                    </div>

                    <Grid className={style.mainContainer}>
                        <Grid item xs={12} sm={4}>
                            <Box className={style.navContainer}>
                                <h3 className="font-weight-bold">QR ID</h3>
                                <TextField
                                    className={style.qrIdInput}
                                    id="qr-id-search"
                                    size="small"
                                    variant="outlined"
                                    autoComplete="off"
                                    value={searchInput}
                                    type="number"
                                    autoFocus
                                    onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                    onChange={(e) => {
                                        setSearchInput(e.target.value);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={style.navContainer}>
                                <h3 className="font-weight-bold">QR Status</h3>
                                <Select
                                    displayEmpty
                                    variant="outlined"
                                    className={style.selctbtn}
                                    value={statusFilter}
                                    onChange={(e) => {
                                        setStatusFilter(e.target.value);
                                        filterQrCodes(e);
                                        setPage(0);
                                    }}
                                >
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={'Active'}>Active</MenuItem>
                                    <MenuItem value={'Closed'}>Closed</MenuItem>
                                    <MenuItem value={'Expired'}>Expired</MenuItem>
                                </Select>
                            </Box>
                            {mediaLessThan600px && (
                                <div className={style.filterContainer}>
                                    <Button variant="contained" color="primary" onClick={clearBtn}>
                                        Clear
                                    </Button>
                                    <Button
                                        id="qr-search-button"
                                        variant="contained"
                                        color="green"
                                        onClick={onSearchQRCodes}
                                        disabled={searchInput.length < 3}
                                        className={style.buttonColor}
                                    >
                                        Search
                                    </Button>
                                </div>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {!mediaLessThan600px && (
                                <div className={style.filterContainer}>
                                    <Button variant="contained" color="primary" onClick={clearBtn}>
                                        Clear
                                    </Button>
                                    <Button
                                        id="qr-search-button"
                                        variant="contained"
                                        color="green"
                                        onClick={onSearchQRCodes}
                                        disabled={searchInput.length < 3}
                                        className={style.buttonColor}
                                    >
                                        Search
                                    </Button>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid>
                        {loading ? (
                            <div className={style.loading}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHeader classes={classes} {...props} />
                                        {qrCodeData.length === 0 ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.noData} colSpan={8} align="center">
                                                        No records found
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {stableSort(qrCodeData, getComparator(order, orderBy))?.map((row) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                <Link
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                    className={style.textInput}
                                                                    onClick={() => {
                                                                        previewData(row);
                                                                    }}
                                                                >
                                                                    {row.id}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align="left" className={style.shortText}>
                                                                <Tooltip
                                                                    title={row.qrName}
                                                                    arrow
                                                                    placement="bottom-start"
                                                                >
                                                                    <span>{row.qrName}</span>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell align="left" className={style.shortText}>
                                                                <Tooltip
                                                                    title={row.description}
                                                                    arrow
                                                                    placement="bottom-start"
                                                                >
                                                                    <span>{row.description}</span>
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {formatDateTimeByFormatStringV2(
                                                                    row.created_at,
                                                                    'DD MMM YYYY HH:mm'
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                <TransactionStatusLabel status={row.status} />
                                                            </TableCell>
                                                            <TableCell style={{ width: '115px' }}>
                                                                <div className={style.qrfieldStyle}>
                                                                    <div
                                                                        onClick={() =>
                                                                            row.status === QrCodeStatus.ACTIVE &&
                                                                            download(row.qrImgLink, row.id)
                                                                        }
                                                                    >
                                                                        <GetAppSharpIcon
                                                                            className={
                                                                                row.status === QrCodeStatus.ACTIVE
                                                                                    ? classes.active
                                                                                    : classes.expired
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <VisibilityOutlined
                                                                            onClick={() => handleOnClick(row)}
                                                                            className={
                                                                                row.status === QrCodeStatus.ACTIVE
                                                                                    ? classes.active
                                                                                    : classes.expired
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <FileCopyIcon
                                                                            style={{ width: '20px' }}
                                                                            className={
                                                                                row.status === QrCodeStatus.ACTIVE
                                                                                    ? classes.active
                                                                                    : classes.expired
                                                                            }
                                                                            onClick={() =>
                                                                                row.status === QrCodeStatus.ACTIVE &&
                                                                                handleCopyUrl(row.link)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {isCopyAlertOpen && (
                                                                    <SnackBarAndAlert
                                                                        open={isCopyAlertOpen}
                                                                        onClose={() => {
                                                                            setIsCopyAlertOpen(false);
                                                                        }}
                                                                        type={'success'}
                                                                    >
                                                                        Successfully copied url.
                                                                    </SnackBarAndAlert>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        )}
                                        {count !== 0 && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                                        colSpan={8}
                                                        count={count}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                </TableContainer>
                                {isPaneOpen && (
                                    <SlidingPane
                                        className={classes.slidePanel}
                                        overlayClassName={classes.slidePaneOverlay}
                                        isOpen={isPaneOpen}
                                        title={previewQrDetails.id}
                                        width={mediaLessThan600px ? '100%' : '400px'}
                                        onRequestClose={() => {
                                            setIsPaneOpen(false);
                                        }}
                                        hideHeader={true}
                                    >
                                        <QrDetailedView
                                            previewQrDetails={previewQrDetails}
                                            qrDetails={qrDetails}
                                            setQrDetails={setQrDetails}
                                            setSuccess={setSuccess}
                                            success={success}
                                            setIsPaneOpen={setIsPaneOpen}
                                            download={download}
                                            CloseSuccess={CloseSuccess}
                                            setCloseSuccess={setCloseSuccess}
                                            setPreviewQrDetails={setPreviewQrDetails}
                                            setShowQrCode={setShowQrCode}
                                        />
                                    </SlidingPane>
                                )}
                                {showQrCode && (
                                    <ModalPreviewQrCode
                                        previewQrDetails={previewQrDetails}
                                        showQrCode={showQrCode}
                                        setShowQrCode={setShowQrCode}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                </Box>

                <ModalCreateQrCode
                    showCreteQr={showCreteQr}
                    setShowCreateQr={setShowCreateQr}
                    selectPaymentQr={selectPaymentQr}
                    setQrCodeData={setQrCodeData}
                    setQrDetails={setQrDetails}
                    qrDetails={qrDetails}
                    setSuccess={setSuccess}
                    success={success}
                    setShowMessage={setShowMessage}
                    count={count}
                    setCount={setCount}
                    setSelectPaymentQr={setSelectPaymentQr}
                />
                {success && (
                    <SnackBarAndAlert open={success} onClose={() => setSuccess(false)} type="success">
                        {showMessage}
                    </SnackBarAndAlert>
                )}
            </Paper>
            <Box className={style.createQrmobile}>
                <Grid item xs={12} sm={4}>
                    {mediaLessThan600px && (
                        <Box className={style.mobilenavContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSelectPaymentQr(true);
                                    setShowCreateQr(true);
                                }}
                                className={style.mobileQrSelctor}
                            >
                                + Create QR Code
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Box>
        </div>
    );
};

export default GenericQrCodeInfoComponent;
