import React, { useState, useEffect } from 'react';
import SignUpFormComponent from './signup-form.component';
import { AuthService, UserService } from 'services';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import qs from 'query-string';
import { useGlobalStateContext } from 'contexts';
import { supportedLanguages, REACT_APP_SIGUP_EXPIRY_TIME } from 'utils/constants';
import { MerchantCountriesDialCode, MerchantCountries } from 'utils/enums/MerchantCountries';
import { ErrorCodes } from 'utils/enums/ErrorCode';
import { statusCodes } from 'utils/StatusCodes';

function ExternalSignUpForm() {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const [loader, setLoader] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState(MerchantCountriesDialCode[MerchantCountries.UNITED_KINGDOM]);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [redoPasswordValidation, setRedoPasswordValidation] = useState(false);
    const [uuid, setUuid] = useState('');
    const location = useLocation();
    const [verificationCode, setVerificationCode] = useState('');
    const [snackbar, setSnackbar] = useState({ type: '', message: '' });
    const [verificationTxt, setVerificationTxt] = useState(false);
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const local = navigator.language;
    const locale = supportedLanguages.find((language) => language.locale === local) ? local : 'en-GB';
    const [isLinkExpired, setIsLinkExpired] = useState(false);
    const [otpMaxLimit, setOtpMaxLimit] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isSubmitTriggered, setIsSubmitTriggered] = useState(false);

    useEffect(() => {
        if (queryString.email && queryString.oldPassword) {
            setEmail(decodeURIComponent(queryString.email));
            setFirstName(queryString.firstname);
            setLastName(queryString.lastname);
            setPhone(queryString.phone);
            setUuid(queryString.code);
        }
    }, [location]);

    useEffect(() => {
        if (uuid) {
            linkExpiryFunction(uuid);
        }
    }, [uuid]);

    useEffect(() => {
        if (countdown > 0) {
            const timerId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
            return () => clearInterval(timerId);
        } else {
            setIsButtonDisabled(false);
        }
    }, [countdown]);

    const linkExpiryFunction = async (id) => {
        try {
            setLoader(true);
            const res = await AuthService.getLinkStatus(id);
            if (res?.isSuccesfully && res?.data?.statusCode !== 5002) {
                setIsLinkExpired(true);
            } else {
                setIsLinkExpired(false);
            }
        } catch (error) {
            console.error('Error in linkExpiryFunction:', error);
        }
        setLoader(false);
    };

    const sendOTPFunction = async (email) => {
        try {
            setIsButtonDisabled(true);
            setVerificationTxt(true);
            setCountdown(60);
            const response = await AuthService.updateOtpLinkStatus({
                uuid: uuid
            });
            if (response.isSuccesfully && response.data.statusCode === 5001) {
                setOtpMaxLimit(true);
                setIsLinkExpired(true);
                setIsButtonDisabled(true);
            } else if (response.isSuccesfully && response.data.statusCode === 6001) {
                setSnackbar({ type: 'error', message: ErrorCodes.OTP_MESSAGE_LIMIT_EXCEEDED.message });
                setIsButtonDisabled(false);
            } else {
                setOtpMaxLimit(false);
                try {
                    await Auth.forgotPassword(email, {
                        portalURL: globalState.reseller?.portalURL,
                        externalSignupForm: '1'
                    });
                    await AuthService.updateOtpLinkStatus({
                        uuid: uuid,
                        otpGenerated: 'true'
                    });
                    setSnackbar({ type: 'success', message: statusCodes.RESEND_EMAIL_VERIFICATION_CODE });
                    setCountdown(60);
                } catch (err) {
                    setIsButtonDisabled(false);
                    setSnackbar({ type: 'error', message: ErrorCodes.OTP_MESSAGE_LIMIT_EXCEEDED.message });
                }
            }
        } catch (error) {
            console.error('Error in sendOTPFunction:', error);
            setIsButtonDisabled(false);
            setSnackbar({ type: 'error', message: ErrorCodes.OTP_MESSAGE_LIMIT_EXCEEDED.message });
        }
    };

    const completeVerification = async (email) => {
        try {
            await Auth.forgotPasswordSubmit(email, verificationCode, password);
            await AuthService.updateChangePasswordTime({ email });
            return true;
        } catch (error) {
            console.error('Error in completeVerification:', error);
            setSnackbar({ type: 'error', message: ErrorCodes.INCOMPLETE_VERIFICATION_PROCESS.message });
            return false;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsSubmitTriggered(true);
        setLoader(true);
        try {
            const isVerified = await completeVerification(email);
            if (isVerified) {
                const user = await Auth.signIn({ username: email, password: password });
                sessionStorage.setItem('signUp', true);
                const userDetails = await UserService.getUserByCognitoUserName(user.attributes.sub);
                localStorage.setItem('selectedLanguage', locale);
                localStorage.setItem('user', JSON.stringify(userDetails.user));
                setGlobalState({ ...globalState, user: userDetails.user });
                setRoute('/home');
                await UserService.updateUserDetails({ email: email });
                await AuthService.updateOtpLinkStatus({
                    uuid: uuid,
                    linkStatus: '1'
                });
            } else {
                setSnackbar({ type: 'error', message: ErrorCodes.INVALID_VERIFICATION_CODE.message });
            }
        } catch (error) {
            event.preventDefault();
            event.stopPropagation();
            console.error('Error in handleSubmit:', error);
            setSnackbar({ type: 'error', message: ErrorCodes.INCOMPLETE_SIGNUP_PROCESS.message });
        }
        setLoader(false);
    };

    return (
        <SignUpFormComponent
            onSubmit={handleSubmit}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            lastName={lastName}
            setLastName={setLastName}
            firstName={firstName}
            setFirstName={setFirstName}
            phone={phone}
            setPhone={setPhone}
            setIsPasswordValid={setIsPasswordValid}
            isPasswordValid={isPasswordValid}
            redoPasswordValidation={redoPasswordValidation}
            setRedoPasswordValidation={setRedoPasswordValidation}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            snackbar={snackbar}
            setSnackbar={setSnackbar}
            loader={loader}
            isLinkExpired={isLinkExpired}
            sendOTPFunction={sendOTPFunction}
            verificationTxt={verificationTxt}
            otpMaxLimit={otpMaxLimit}
            isButtonDisabled={isButtonDisabled}
            countdown={countdown}
            isSubmitTriggered={isSubmitTriggered}
        />
    );
}

export default ExternalSignUpForm;
