import React from 'react';
import { GfoPaymentsListTable } from '../payments/transaction-tables';
import { SnackBarAndAlert } from 'components';

const GfoPaymentsListComponent = (props) => {
    const { isRefundHappen, setIsRefundHappen } = props;
    return (
        <div>
            <GfoPaymentsListTable {...props} />
            {isRefundHappen !== null && (
                <SnackBarAndAlert
                    open={true}
                    onClose={() => setIsRefundHappen(null)}
                    type={isRefundHappen ? 'success' : 'error'}
                >
                    {isRefundHappen
                        ? 'Your refund request has been sent successfully.'
                        : 'Your refund request has failed.'}
                </SnackBarAndAlert>
            )}
        </div>
    );
};

export default GfoPaymentsListComponent;
