import React, { useState, useEffect, useRef } from 'react';
import BankSortCodeNumberComponent from './bank-sort-code-number.component';

function BankSortCodeNumberContainer(props) {
    const { values, onChange, focusFirst, nextElementRef, disabled, emptyField } = props;

    const [firstInputValues, setFirstInputValues] = useState('');
    const [secondInputValues, setSecondInputValues] = useState('');
    const [thirdInputValues, setThirdInputValues] = useState('');

    const firstInputElementRef = useRef(null);
    const secondInputElementRef = useRef(null);
    const thirdInputElementRef = useRef(null);

    useEffect(() => {
        if (firstInputElementRef && focusFirst) {
            firstInputElementRef.current.focus();
        }
    }, [focusFirst]);

    useEffect(() => {
        if (values?.length === 6) {
            const inputValues = values.match(/.{1,2}/g);
            setFirstInputValues(inputValues[0]);
            setSecondInputValues(inputValues[1]);
            setThirdInputValues(inputValues[2]);
        }
    }, [values]);

    const firstInputChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = firstInputValues || '';
        }

        setFirstInputValues(value);
        if (value.length > 1) {
            setTimeout(() => {
                secondInputElementRef.current.focus();
            });
        }
    };

    const secondInputChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = secondInputValues || '';
        }

        setSecondInputValues(value);

        if (value.length > 1) {
            setTimeout(() => {
                thirdInputElementRef.current.focus();
            });
        }
    };

    const thirdInputChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = thirdInputValues || '';
        }

        setThirdInputValues(value);

        if (value.length > 1) {
            setTimeout(() => {
                thirdInputElementRef.current.blur();
                if (nextElementRef && nextElementRef.current) {
                    nextElementRef.current.focus();
                }
            });
        }
    };

    useEffect(() => {
        const toSendValues = `${firstInputValues}${secondInputValues}${thirdInputValues}`;
        if (toSendValues !== values && onChange) {
            onChange(toSendValues);
        }
    }, [firstInputValues, secondInputValues, thirdInputValues, onChange, values]);

    return (
        <BankSortCodeNumberComponent
            disabled={disabled}
            hideDashes={props.hideDashes}
            firstInputValues={firstInputValues}
            secondInputValues={secondInputValues}
            thirdInputValues={thirdInputValues}
            firstInputChangeHandler={firstInputChangeHandler}
            secondInputChangeHandler={secondInputChangeHandler}
            thirdInputChangeHandler={thirdInputChangeHandler}
            firstInputElementRef={firstInputElementRef}
            secondInputElementRef={secondInputElementRef}
            thirdInputElementRef={thirdInputElementRef}
            emptyField={emptyField}
        />
    );
}
export default BankSortCodeNumberContainer;
