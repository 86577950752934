import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import styles from './modal-merchant-terms-and-conditions.module.scss';
import { SettingTermsAndConditionsContainer, SnackBarAndAlert } from 'components';

const ModalMerchantTermsAndConditionsComponent = (props) => {
    const { open, setOpen, showMessage, setShowMessage, modalTermsAndConditions } = props;

    return (
        <Dialog
            open={open}
            classes={{
                paper: styles.paper
            }}
            onClose={() => setShowMessage(true)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="false"
        >
            <DialogContent
                classes={{
                    root: styles.dialogContent
                }}
            >
                <SettingTermsAndConditionsContainer
                    setOpen={setOpen}
                    isModal={true}
                    modalTermsAndConditions={modalTermsAndConditions}
                />
            </DialogContent>
            {showMessage && (
                <SnackBarAndAlert open={showMessage} type={'error'}>
                    Please sign the terms & conditions.
                </SnackBarAndAlert>
            )}
        </Dialog>
    );
};

export default ModalMerchantTermsAndConditionsComponent;
