import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem,
    PostCodeSelector
} from 'components/onboarding/onboarding-ui-components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { FormatMessage, translateText } from 'utils/languageTranslater';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { LoadingScreen } from 'components';
import { getOnboardingTitle } from 'utils/onboarding-helper';
import { OnboardingSteps } from 'utils/enums/OnboardingStepsMapping';

function T2SOnboardingAdressComponent(props) {
    const {
        onboardingForm,
        setOnboardingForm,
        isFormValid,
        onSubmit,
        selectAddressData,
        isLimited,
        onChangeSameAddress,
        isLoading
    } = props;

    const { globalState } = useGlobalStateContext();
    const merchantCountry = globalState.merchant?.country;
    const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
    const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const isUSAMerchant = globalState.merchant?.country === MerchantCountries.UNITED_STATES;

    const title = (
        <OnboardingTitle
            title={getOnboardingTitle(OnboardingSteps.TRADING_ADDRESS, merchantCountry)}
            subTitle="Some specifics on your business"
            transalterEnabled={true}
            subTransalterEnabled={true}
        ></OnboardingTitle>
    );

    function formBody() {
        return (
            <React.Fragment>
                {isLimited && (
                    <Grid>
                        <FormItem>
                            <Label
                                text={isUSAMerchant ? 'Business name' : 'Trading name'}
                                id={isUSAMerchant ? 'business_name_input' : 'address-label-trading-name'}
                                transalterEnabled="true"
                            ></Label>

                            <Input
                                placeholder={FormatMessage({
                                    id: isUSAMerchant ? 'business_name_input' : 'address-label-trading-name',
                                    text: isUSAMerchant ? 'Business name' : 'Trading name'
                                })}
                                id="address-input-trading-name"
                                value={checkEmptyValue(onboardingForm.businessDetails.tradingName)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            tradingName: e.target.value
                                        }
                                    })
                                }
                                emptyField={
                                    isUkMerchant &&
                                    isActiveMerchant &&
                                    !checkEmptyValue(onboardingForm.businessDetails.tradingName)
                                        ? true
                                        : false
                                }
                            ></Input>
                        </FormItem>

                        <FormItem>
                            <FormControlLabel
                                style={{ marginBottom: '0px' }}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={onboardingForm.tradingAddress.isSameAsBusinessAddress}
                                        onChange={(e) => onChangeSameAddress(e.target.checked)}
                                    />
                                }
                                label={translateText({
                                    id: isUSAMerchant ? 'business-address-check' : 'trading-address-check',
                                    text: isUSAMerchant
                                        ? 'Business address is the same as registered address'
                                        : 'Trading address is the same as registered address'
                                })}
                                labelPlacement="end"
                            />
                        </FormItem>
                    </Grid>
                )}
                {!isLimited && (
                    <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                        <FormItem>
                            <Label text="Trading name" id="address-label-trading-name" transalterEnabled="true"></Label>
                            <Input
                                placeholder={FormatMessage({ id: 'address-label-trading-name', text: 'Trading name' })}
                                id="address-input-trading-name"
                                value={checkEmptyValue(onboardingForm.businessDetails.tradingName)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            tradingName: e.target.value
                                        }
                                    })
                                }
                                emptyField={
                                    isUkMerchant &&
                                    isActiveMerchant &&
                                    !checkEmptyValue(onboardingForm.businessDetails.tradingName)
                                        ? true
                                        : false
                                }
                            ></Input>
                        </FormItem>
                    </CheckMerchantCountries>
                )}
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                    <FormItem>
                        <Label text="Postcode" id="address-label-post-code-selector"></Label>
                        <PostCodeSelector
                            postCodeValue={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onPostCodeChange={(postCode) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: postCode
                                    }
                                })
                            }
                            onAddressObjectChange={(addressData) => selectAddressData(addressData)}
                            emptyField={
                                isUkMerchant &&
                                isActiveMerchant &&
                                !checkEmptyValue(onboardingForm.tradingAddress.postCode)
                                    ? true
                                    : false
                            }
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                    <FormItem>
                        <Label text="ZIP code" id="name-address-label-post-code"></Label>
                        <Input
                            placeholder="ZIP code"
                            id="zip-code"
                            value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries
                    excludedCountries={[
                        MerchantCountries.UNITED_KINGDOM,
                        MerchantCountries.UNITED_STATES,
                        MerchantCountries.MEXICO
                    ]}
                >
                    <FormItem>
                        <Label text="Post code" id="address-label-post-code" transalterEnabled="true"></Label>
                        <Input
                            placeholder="Post code"
                            id="address-input-post-code"
                            value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                    <FormItem>
                        <Label text="Post code" id="address-label-post-code" transalterEnabled="true"></Label>
                        <Input
                            placeholder={FormatMessage({ id: 'address-label-post-code', text: 'Post code' })}
                            id="address-input-post-code"
                            value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <FormItem>
                    <Label text="Address line 1" id="address-label-address" transalterEnabled="true"></Label>
                    <Input
                        placeholder={FormatMessage({ id: 'address-label-address', text: 'Address line 1' })}
                        id="address-input-address"
                        value={checkEmptyValue(onboardingForm.tradingAddress.addressLine1)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                tradingAddress: {
                                    ...onboardingForm.tradingAddress,
                                    addressLine1: e.target.value
                                }
                            })
                        }
                        emptyField={
                            isUkMerchant &&
                            isActiveMerchant &&
                            !checkEmptyValue(onboardingForm.tradingAddress.addressLine1)
                                ? true
                                : false
                        }
                    />
                </FormItem>
                <FormItem>
                    <Label text="Address line 2" id="address-label-address-line-2" transalterEnabled="true"></Label>
                    <Input
                        placeholder={FormatMessage({ id: 'address-label-address-line-2', text: 'Address line 2' })}
                        id="address-input-address-line-2"
                        value={checkEmptyValue(onboardingForm.tradingAddress.addressLine2)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                tradingAddress: {
                                    ...onboardingForm.tradingAddress,
                                    addressLine2: e.target.value
                                }
                            })
                        }
                    />
                </FormItem>
                <FormItem>
                    <Label text="Town/City" id="address-label-town-city" transalterEnabled="true"></Label>
                    <Input
                        placeholder={FormatMessage({ id: 'address-label-town-city', text: 'Town/City' })}
                        id="address-input-town-city"
                        value={checkEmptyValue(onboardingForm.tradingAddress.city)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                tradingAddress: {
                                    ...onboardingForm.tradingAddress,
                                    city: e.target.value
                                }
                            })
                        }
                        emptyField={
                            isUkMerchant && isActiveMerchant && !checkEmptyValue(onboardingForm.tradingAddress.city)
                                ? true
                                : false
                        }
                    />
                </FormItem>
                <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                    <FormItem>
                        <Label text="State" id="address-label-state" transalterEnabled="true"></Label>
                        <Input
                            placeholder={FormatMessage({ id: 'address-label-state', text: 'State' })}
                            id="address-input-country"
                            value={checkEmptyValue(onboardingForm.tradingAddress.state)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        state: e.target.value
                                    }
                                })
                            }
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries excludedCountries={[MerchantCountries.MEXICO]}>
                    <FormItem>
                        <Label text="Country" id="address-label-country"></Label>
                        <Input
                            placeholder="Country"
                            id="address-input-country"
                            value={checkEmptyValue(onboardingForm.tradingAddress.country)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        country: e.target.value
                                    }
                                })
                            }
                            emptyField={
                                isUkMerchant &&
                                isActiveMerchant &&
                                !checkEmptyValue(onboardingForm.tradingAddress.country)
                                    ? true
                                    : false
                            }
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                    <FormItem>
                        <Label text="Country" id="address-label-country" transalterEnabled="true"></Label>
                        <Input
                            placeholder={FormatMessage({ id: 'address-label-country', text: 'Country' })}
                            id="address-input-country"
                            value={MerchantCountries.MEXICO}
                            disabled
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        country: e.target.value
                                    }
                                })
                            }
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <LoadingScreen open={isLoading} />
            </React.Fragment>
        );
    }

    const nextBtn = (
        <div>
            <NextButton
                disabled={!isFormValid}
                onSubmit={() => {
                    onSubmit();
                }}
            ></NextButton>
        </div>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

T2SOnboardingAdressComponent.propTypes = {
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isFormValid: PropTypes.bool,
    onSubmit: PropTypes.func,
    selectAddressData: PropTypes.func,
    statusValidationBankAccount: PropTypes.bool,
    onChangeSameAddress: PropTypes.bool,
    isBankAccountValid: PropTypes.bool,
    isBankDetailsNotFound: PropTypes.bool
};

export default T2SOnboardingAdressComponent;
