import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
    Box,
    CircularProgress,
    Link /*, useMediaQuery*/
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styles from './withdrawal-submission.module.scss';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
//import LoadingScreen from 'components/loading-screen/loading-screen.container';
// import { Label } from '../../onboarding/onboarding-ui-components';
// import ReactCodeInput from 'react-verification-code-input';
// import { getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
// import { CheckCircle, Error } from '@material-ui/icons';
// import { CheckMerchantCountries } from 'components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
// import { MerchantCountries } from 'utils/enums/MerchantCountries';

export default function WithdrawalSubmissionComponent(props) {
    const { globalState, setRoute } = useGlobalStateContext();
    // const allowWithdrawals = globalState.merchant?.allowWithdrawals;
    const {
        show,
        withdrawalAmount,
        submitWithdrawal,
        errorMessage,
        // isBankAndAccountVerified,
        // setRoute,
        // businessType,
        isLoading,
        isConfirmDisabled,
        isCancelDisabled,
        handlePopupClose,
        // sortCode,
        // setSortCode,
        // accountNumber,
        // setAccountNumber,
        // isFirstWithdrawal,
        // ibanIreland,
        // setIbanIreland,
        // isValid
        success,
        setOpenView,
        nextPayoutDay,
        info
    } = props;

    //const mediaLessThan750px = useMediaQuery('(max-width:750px)');
    // const mediaLessThan600px = useMediaQuery('(max-width:600px)');
    // const mediaLessThan400px = useMediaQuery('(max-width:400px)');
    return (
        <React.Fragment>
            <Dialog
                open={show}
                classes={{
                    paper: styles.containerDialog
                }}
                onClose={() => handlePopupClose()}
                disableBackdropClick={isCancelDisabled}
                disableEscapeKeyDown={isCancelDisabled}
            >
                <DialogTitle>
                    <p className={styles.withdrawalText}>
                        {success ? 'Withdrawal confirmation of' : 'You are about to withdraw'}{' '}
                        {getCurrencySymbol(globalState.merchant?.country)}
                        <span className={styles.withdrawalAmount}>{parseFloat(withdrawalAmount).toFixed(2)}</span>
                    </p>
                    <div className={styles.headerDivider}></div>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        {success ? (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    The withdrawal you requested is pending.
                                </Grid>
                                <Grid item xs={12}>
                                    <Link
                                        underline="always"
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            handlePopupClose();
                                            setRoute(props.payoutUrl);
                                        }}
                                    >
                                        To view your pending payouts click here
                                    </Link>
                                </Grid>
                            </React.Fragment>
                        ) : isLoading ? (
                            <React.Fragment>
                                <Box textAlign="center" width="100%">
                                    <Box textAlign="center" py={2}>
                                        <CircularProgress />
                                    </Box>{' '}
                                    <p className={styles.spanMessage}>Please wait, we are processing your request...</p>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {info !== '' ? (
                                    <Grid item xs={12}>
                                        <Alert className={styles.alert_message} color="info" severity="info">
                                            {info}
                                        </Alert>
                                    </Grid>
                                ) : null}
                                {errorMessage !== '' ? (
                                    <Grid item xs={12}>
                                        <Alert className={styles.alert_message} severity="error">
                                            {errorMessage}
                                        </Alert>
                                    </Grid>
                                ) : null}
                                {/* {!isBankAndAccountVerified && !allowWithdrawals && (
                            <Grid item xs={12}>
                                <Alert className={styles.alert_message} severity="error">
                                    Your account is unverified, please upload the required documents.&nbsp;
                                    <span
                                        className={styles.click__here}
                                        onClick={() =>
                                            setRoute('/upload-bank-documents', { businessType: businessType })
                                        }
                                    >
                                        Click here
                                    </span>
                                </Alert>
                            </Grid>
                        )} */}
                                <Grid item xs={12}>
                                    Expected to reach your account {nextPayoutDay}
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={styles.descriptionContainer}>
                                        <span className={styles.description}>
                                            Withdrawal requests may take up to 5-7 days to process
                                        </span>
                                    </div>
                                </Grid>
                                {/* {isFirstWithdrawal && (
                            <React.Fragment>
                                <Grid container className={styles.firstWithdrawalContainer}>
                                    <Grid item xs={12}>
                                        <Alert variant="warning">
                                            Since this is your first withdrawal, you will need to provide the bank
                                            details you provided while signing up.
                                        </Alert>
                                    </Grid>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                        <Grid item xs={12} sm={5}>
                                            <Label text="Sort code" />
                                        </Grid>
                                        {!mediaLessThan600px && (
                                            <Grid item sm={7}>
                                                <Label text="Account number" />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={5}>
                                            <ReactCodeInput
                                                fieldHeight={mediaLessThan400px ? 25 : 27}
                                                fieldWidth={mediaLessThan400px ? 25 : 27}
                                                autoFocus={false}
                                                fields={6}
                                                type="number"
                                                pattern="\d*"
                                                values={getArrayFromString(sortCode)}
                                                onChange={(value) => setSortCode(value)}
                                            />
                                        </Grid>
                                        {mediaLessThan600px && (
                                            <Grid item xs={12}>
                                                <Label text="Account number" />
                                            </Grid>
                                        )}
                                        <Grid item xs={10} sm={6}>
                                            <ReactCodeInput
                                                fieldHeight={mediaLessThan400px ? 25 : 27}
                                                fieldWidth={mediaLessThan400px ? 25 : 27}
                                                autoFocus={false}
                                                fields={8}
                                                type="number"
                                                pattern="\d*"
                                                values={getArrayFromString(accountNumber)}
                                                onChange={(value) => setAccountNumber(value)}
                                            />
                                        </Grid>
                                    </CheckMerchantCountries>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                                        <Grid item xs={12}>
                                            <Label text="Bank IBAN" id="bank-IBAN-ireland-label" />
                                        </Grid>
                                        <Grid item xs={10} sm={11}>
                                            <ReactCodeInput
                                                className={styles.codeInputIreland}
                                                fieldHeight={mediaLessThan750px ? 21 : 25}
                                                fieldWidth={mediaLessThan750px ? 21 : 25}
                                                autoFocus={false}
                                                fields={22}
                                                type="text"
                                                values={
                                                    ibanIreland.length > 0
                                                        ? getArrayFromString(ibanIreland)
                                                        : ['I', 'E']
                                                }
                                                onChange={(value) => setIbanIreland(value)}
                                            />
                                        </Grid>
                                    </CheckMerchantCountries>
                                    <Grid item xs={2} sm={1}>
                                        {isValid ? (
                                            <CheckCircle className={styles.validIcon} />
                                        ) : (
                                            <Error className={styles.errorIcon} />
                                        )}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )} */}
                            </React.Fragment>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {success ? (
                        <Button
                            disabled={isCancelDisabled}
                            onClick={() => {
                                handlePopupClose();
                                setOpenView(false);
                            }}
                            color="primary"
                            variant="outlined"
                        >
                            Close
                        </Button>
                    ) : (
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    className={styles.button}
                                    disabled={isCancelDisabled}
                                    onClick={() => {
                                        handlePopupClose();
                                    }}
                                    color="primary"
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    className={styles.button}
                                    variant="contained"
                                    disabled={isConfirmDisabled}
                                    // disabled={
                                    //     (!isBankAndAccountVerified && !allowWithdrawals) || isConfirmDisabled || (isFirstWithdrawal && !isValid)
                                    // }
                                    onClick={submitWithdrawal}
                                    color="primary"
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

WithdrawalSubmissionComponent.propTypes = {
    show: PropTypes.bool,
    withdrawalAmount: PropTypes.number,
    submitWithdrawal: PropTypes.func,
    errorMessage: PropTypes.string,
    setErrorMessage: PropTypes.func,
    isBankAndAccountVerified: PropTypes.bool,
    setRoute: PropTypes.func,
    businessType: PropTypes.string,
    wait: PropTypes.bool,
    isLoading: PropTypes.bool,
    isConfirmDisabled: PropTypes.bool,
    isCancelDisabled: PropTypes.bool,
    handlePopupClose: PropTypes.func,
    sortCode: PropTypes.string,
    setSortCode: PropTypes.func,
    accountNumber: PropTypes.string,
    setAccountNumber: PropTypes.func,
    isFirstWithdrawal: PropTypes.bool,
    ibanIreland: PropTypes.string,
    setIbanIreland: PropTypes.func,
    isValid: PropTypes.bool,
    success: PropTypes.bool
};
