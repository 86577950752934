import React, { useEffect, useState } from 'react';
import TeamMembersComponent from './team-members.component';
import { useGlobalStateContext } from 'contexts';
import { TeamService } from 'services';
import { UserType } from 'utils/enums/UserType';
import { SignupStatus } from 'utils/enums/signupStatus';
import { Roles } from 'utils/enums/Roles';
import _ from 'lodash';

function TeamMembers(props) {
    const { globalState } = useGlobalStateContext();
    const [teamMembers, setTeamMembers] = useState(null);
    const [filterMembers, setFilterMembers] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [selectedRole, setSelectedRole] = useState('All roles');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [show, setShow] = useState(false);
    const [userRole, setUserRole] = useState();
    const [emailsAndRoles, setEmailsAndRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [removeMember, setRemoveMember] = useState();
    const [resendSuccess, setResendSuccess] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [searchString, setSearchString] = useState('');
    const [count, setCount] = useState(0);
    const [isAlreadyMaxLimit, setIsAlreadyMaxLimit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disableInvite, setDisableInvite] = useState(true);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('signupStatus');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const isAgent = _.isArray(globalState.user?.Relationships)
        ? globalState.user?.Relationships[0]?.Role.name === Roles.Agent
        : false;

    const agentDropdownToBeVisible =
        globalState.user?.UserType?.name !== UserType.Merchant &&
        globalState.user?.UserType?.name !== UserType.SuperAdmin
            ? true
            : false;

    const maxUserCount = 50;
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const getTeamMembers = async () => {
        setLoading(true);
        let response;
        if (isCanonicalResellerUser) {
            response = await TeamService.getCanonicalResellerTeamMembers(globalState.canonicalReseller?.id);
        } else {
            response = await TeamService.getTeamMembers(globalState.merchant?.id);
        }

        if (response.isSuccesfully) {
            const team = response.data;
            team.teamMembers = team.teamMembers.map((member) => {
                member.Relationships = member.Relationships.find((r) => {
                    return isCanonicalResellerUser ? true : r.merchantId === globalState.merchant?.id;
                });
                return member;
            });
            setTeamMembers(team.teamMembers);
            setFilterMembers(team.teamMembers);
            getCurrentUserRole(team.teamMembers);
            setCount(team.teamMembers.length);
            if (team.teamMembers.length === maxUserCount) {
                setIsAlreadyMaxLimit(true);
            }
            setLoading(false);
        } else {
            setError(true);
        }
    };
    let headCells = [
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Team Members'
        },
        {
            id: 'signupStatus',
            numeric: false,
            disablePadding: false,
            label: 'Status'
        },
        {
            id: 'Relationships.Role.name',
            numeric: false,
            disablePadding: false,
            label: 'Role',
            hideSortingIcon: true
        },
        { id: 'remove', numeric: false, disablePadding: false, label: '', hideSortingIcon: true },
        { id: 'resend', numeric: false, disablePadding: false, label: '', hideSortingIcon: true }
    ];

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        if (count > 0) {
            if (count < 50) {
                setDisableInvite(false);
            } else {
                setDisableInvite(true);
            }
        }
    }, [count]);

    const onSearch = (searchField, role, status) => {
        setPage(0);
        setLoading(true);
        setSearchString(searchField);
        const userRole = role ? role : selectedRole;
        const userStatus = status ? status : selectedStatus;
        let result;

        if (searchField.trimRight() !== '') {
            result = teamMembers.filter((member) => {
                const fullName = `${member.firstName} ${member.lastName}`;
                return (
                    ((member.firstName && member.firstName?.toLowerCase().includes(searchField.trim().toLowerCase())) ||
                        (member.lastName &&
                            member.lastName?.toLowerCase().includes(searchField.trim().toLowerCase())) ||
                        (fullName && fullName?.toLowerCase().includes(searchField.trim().toLowerCase())) ||
                        member.email?.toLowerCase().includes(searchField)) &&
                    (userRole !== 'All roles' ? member.Relationships.Role.name === userRole : true) &&
                    (userStatus !== 'All' ? member.signupStatus === SignupStatus[userStatus] : true)
                );
            });
            setFilterMembers(result);
        } else {
            result = teamMembers.filter((member) => {
                return (
                    (userRole !== 'All roles' ? member.Relationships.Role.name === userRole : teamMembers) &&
                    (userStatus !== 'All' ? member.signupStatus === SignupStatus[userStatus] : teamMembers)
                );
            });

            setFilterMembers(result);
        }
        setLoading(false);
        return result;
    };

    const onRoleFilter = async (roleName) => {
        setSelectedRole(roleName);
        const membersForFilter = onSearch(searchString, roleName, selectedStatus);
        if (roleName !== 'All roles') {
            let result = membersForFilter.filter((member) => member.Relationships.Role.name === roleName);
            setFilterMembers(result);
        } else setFilterMembers(membersForFilter);
    };

    const onStatusFilter = async (status) => {
        setSelectedStatus(status);
        const membersForFilter = onSearch(searchString, selectedRole, status);
        if (status !== 'All') {
            let result = membersForFilter.filter((member) => {
                return member.signupStatus === SignupStatus[status];
            });
            setFilterMembers(result);
        } else setFilterMembers(membersForFilter);
    };
    const inviteMember = () => {
        setShow(true);
        setCount(() => count + 1);
    };

    const onResend = (member) => {
        setIsLoading(true);
        const data = {
            email: member.email,
            role: member.Relationships.Role.name
        };
        emailsAndRoles.push(data);
        setEmailsAndRoles([...emailsAndRoles]);

        if (isCanonicalResellerUser) {
            TeamService.inviteCanonicalResellerMembers(
                emailsAndRoles,
                globalState.canonicalReseller?.id,
                globalState.reseller?.id
            ).then((result) => {
                if (!result.isSuccesfully) {
                    const errorMessage = result.data.error ?? 'An error has occurred';
                    setValidationMessage(errorMessage);
                    setResendSuccess(true);
                    setMessageType('error');
                } else {
                    const successMessage = 'Invite was successfully resent';
                    setValidationMessage(successMessage);
                    setResendSuccess(true);
                    setMessageType('success');
                }
                setIsLoading(false);
            });
        } else {
            TeamService.inviteMembers(emailsAndRoles, globalState.merchant?.id, globalState.reseller?.id).then(
                (result) => {
                    if (!result.isSuccesfully) {
                        const errorMessage = result.data.error ?? 'An error has occurred';
                        setValidationMessage(errorMessage);
                        setResendSuccess(true);
                        setMessageType('error');
                    } else {
                        const successMessage = 'Invite was successfully resent';
                        setValidationMessage(successMessage);
                        setResendSuccess(true);
                        setMessageType('success');
                    }
                    setIsLoading(false);
                }
            );
        }
    };
    const isDisable = (user) => {
        if (
            userRole === Roles.Agent &&
            (user.Relationships?.Role.name === Roles.Admin ||
                user.Relationships?.Role.name === Roles.Manager ||
                user.Relationships?.Role.name === Roles.User)
        ) {
            return false;
        }
        if (user.Relationships.Role.name === 'Owner') return false;
        if (user.email === globalState.user?.email) return false;
        else return true;
    };
    const getCurrentUserRole = (members) => {
        const user = members.find((member) => member.email === globalState.user?.email);
        setUserRole(user?.Relationships.Role.name);
    };
    const onRemoveMember = (member) => {
        setRemoveMember(member);
        setOpen(true);
    };
    useEffect(() => {
        getTeamMembers();
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <TeamMembersComponent
                members={filterMembers}
                onSearch={onSearch}
                onRoleFilter={onRoleFilter}
                loading={loading}
                setLoading={setLoading}
                userEmail={globalState.user?.email}
                inviteMember={inviteMember}
                setShow={setShow}
                show={show}
                isDisable={isDisable}
                userRole={userRole}
                error={error}
                setError={setError}
                open={open}
                onRemoveMember={onRemoveMember}
                setOpen={setOpen}
                removeMember={removeMember}
                getTeamMembers={getTeamMembers}
                onResend={onResend}
                validationMessage={validationMessage}
                resendSuccess={resendSuccess}
                setResendSuccess={setResendSuccess}
                messageType={messageType}
                count={count}
                setCount={setCount}
                isAlreadyMaxLimit={isAlreadyMaxLimit}
                maxUserCount={maxUserCount}
                isLoading={isLoading}
                disableInvite={disableInvite}
                handleRequestSort={handleRequestSort}
                headCells={headCells}
                page={page}
                setPage={setPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                order={order}
                orderBy={orderBy}
                onStatusFilter={onStatusFilter}
                setSelectedRole={setSelectedRole}
                setSelectedStatus={setSelectedStatus}
                setSearchString={setSearchString}
                selectedRole={selectedRole}
                selectedStatus={selectedStatus}
                searchString={searchString}
                isAgent={isAgent}
                agentDropdownToBeVisible={agentDropdownToBeVisible}
                {...props}
            ></TeamMembersComponent>
        </>
    );
}

export default TeamMembers;
