import React from 'react';
import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem,
    MultiPickerInput
} from 'components/onboarding/onboarding-ui-components';
import styles from './onboarding-business-profile.module.scss';
import { Grid, OutlinedInput, Typography } from '@material-ui/core';
import { AntSwitch } from 'components';
import PropTypes from 'prop-types';
import { checkEmptyArray, checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import NumberFormat from 'react-number-format';

function OnboardingBusinessProfileComponent(props) {
    const { nextStep, onboardingForm, setOnboardingForm, isDisabled, onSubmit } = props;

    const title = (
        <OnboardingTitle
            title="Business profile"
            subTitle="Please provide your business profile details"
        ></OnboardingTitle>
    );

    function formBody() {
        function validMonthLimit(val) {
            const numberValue = +val;
            if (numberValue > 11) {
                if (
                    numberValue.toString().substring(0, 1) === '1' &&
                    Number(numberValue.toString().substring(1, 2)) <= 1
                ) {
                    return numberValue.toString().substring(0, 2);
                }
                return numberValue.toString().substring(0, 1);
            }
            return numberValue.toString();
        }

        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MultiPickerInput
                            label="Business descriptions"
                            list={onboardingForm.businessDescriptions}
                            property={(element) => element.name}
                            value={checkEmptyArray(onboardingForm.businessProfile.businessDescriptions)}
                            onChange={(value) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    businessProfile: {
                                        ...onboardingForm.businessProfile,
                                        businessDescriptions: value
                                    }
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={8} sm={6} className={styles.timeInBusiness}>
                        <FormItem>
                            <Label>Time in business</Label>
                            <Grid container spacing={1}>
                                <Grid item xs={6} className={styles.formItemGrid}>
                                    <Label>Years</Label>
                                    <NumberFormat
                                        className={styles.numberFormatInput}
                                        customInput={OutlinedInput}
                                        inputProps={{ inputMode: 'numeric' }}
                                        pattern="\d*"
                                        allowLeadingZeros={true}
                                        decimalSeparator={false}
                                        allowNegative={false}
                                        isNumericString={true}
                                        placeholder="Years"
                                        value={checkEmptyValue(onboardingForm.businessProfile.timeInBusinessYears)}
                                        id="time-in-business-years"
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                businessProfile: {
                                                    ...onboardingForm.businessProfile,
                                                    timeInBusinessYears: value
                                                }
                                            });
                                        }}
                                        aria-describedby="outlined-weight-helper-text"
                                        labelWidth={0}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6} className={styles.formItemGrid}>
                                    <Label>Months</Label>
                                    <NumberFormat
                                        className={styles.numberFormatInput}
                                        customInput={OutlinedInput}
                                        inputProps={{ inputMode: 'numeric' }}
                                        pattern="\d*"
                                        decimalSeparator={false}
                                        allowNegative={false}
                                        isNumericString={true}
                                        format={validMonthLimit}
                                        placeholder="Months"
                                        value={checkEmptyValue(onboardingForm.businessProfile.timeInBusinessMonths)}
                                        id="time-in-business-months"
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                businessProfile: {
                                                    ...onboardingForm.businessProfile,
                                                    timeInBusinessMonths: value
                                                }
                                            });
                                        }}
                                        aria-describedby="outlined-weight-helper-text"
                                        labelWidth={0}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.product}>Product</div>
                    </Grid>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <FormItem>
                            <Label>Does your business make products</Label>
                            <div className={styles.yesNoSwitch}>
                                <Typography color="primary" component="span" className={styles.checkBoxText}>
                                    No
                                </Typography>
                                <AntSwitch
                                    id="is-business-making-products"
                                    checked={onboardingForm.businessProfile.isBusinessMakingProducts}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessProfile: {
                                                ...onboardingForm.businessProfile,
                                                isBusinessMakingProducts: e.target.checked
                                            }
                                        })
                                    }
                                ></AntSwitch>
                                <Typography color="primary" component="span" className={styles.checkBoxText}>
                                    Yes
                                </Typography>
                            </div>
                        </FormItem>
                    </Grid>
                    {onboardingForm.businessProfile.isBusinessMakingProducts && (
                        <Grid xs={12} className={styles.productDescriptions}>
                            <FormItem>
                                <Label>Product description</Label>
                                <Input
                                    placeholder="Product description"
                                    value={checkEmptyValue(onboardingForm.businessProfile.productDescription)}
                                    id="product-description"
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessProfile: {
                                                ...onboardingForm.businessProfile,
                                                productDescription: e.target.value
                                            }
                                        })
                                    }
                                />
                            </FormItem>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6} className={styles.formItemGrid}>
                        <FormItem>
                            <Label>Where is the stock held?</Label>
                            <Input
                                placeholder="Local warehouse"
                                id="stock-location"
                                value={checkEmptyValue(onboardingForm.businessProfile.stockLocation)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessProfile: {
                                            ...onboardingForm.businessProfile,
                                            stockLocation: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    </Grid>
                    <Grid item xs={12} sm={6} className={styles.formItemGrid}>
                        <FormItem>
                            <Label>Sufficient stock?</Label>
                            <div className={styles.yesNoSwitch}>
                                <Typography color="primary" component="span" className={styles.checkBoxText}>
                                    No
                                </Typography>
                                <AntSwitch
                                    id="is-stock-sufficient"
                                    checked={onboardingForm.businessProfile.isStockSufficient}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessProfile: {
                                                ...onboardingForm.businessProfile,
                                                isStockSufficient: e.target.checked
                                            }
                                        })
                                    }
                                ></AntSwitch>
                                <Typography color="primary" component="span" className={styles.checkBoxText}>
                                    Yes
                                </Typography>
                            </div>
                        </FormItem>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} nextButton={nextBtn} content={formBody} />;
}

OnboardingBusinessProfileComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default OnboardingBusinessProfileComponent;
