import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import styles from './virtual-terminal.module.scss';
import ModalEmail from '../pay-by-link/modal-email-form/modal-email-form.container';
import NumberFormat from 'react-number-format';
import { ValidEmailInput } from 'components';
import { InputAdornment, OutlinedInput, FormControlLabel, Checkbox } from '@material-ui/core';
import { Input } from 'components/onboarding/onboarding-ui-components';
import { validateEmailAddress } from 'utils/helper';
import ModalInvoice from '../pay-by-link/modal-invoice-form/modal-invoice-form.container';
import { VirtualTerminalPayments, VirtualTerminalPaymentsText } from 'utils/enums/PaymentMode';

const VirtualTerminalComponent = (props) => {
    const {
        amount,
        description,
        handleAmountChange,
        handleDescriptionChange,
        isMaxAmount,
        showPayByLinkModal,
        setShowPayByLinkModal,
        isButtonDisabled,
        setAmount,
        setDescription,
        showReceipt,
        setShowReceipt,
        email,
        setEmail,
        phoneInput,
        showPayByInvoiceModal,
        setShowPayByInvoiceModal,
        paymentMode,
        setPaymentMode,
        handlePayment,
        isVat,
        isInvoiceEnabled
    } = props;
    const isDisable =
        isButtonDisabled(amount) || (!!showReceipt ? validateEmailAddress(email) && !(phoneInput.length > 2) : false);
    return (
        <div className={'card ' + styles.cardContainer}>
            <div className={styles.cardFormContainer}>
                <div className="card-input">
                    <h1>Enter the amount</h1>
                </div>
                <div className="card-input">
                    <label htmlFor="amount" className="card-input__label">
                        Amount
                    </label>
                    <NumberFormat
                        id="amount_input"
                        customInput={OutlinedInput}
                        inputProps={{ inputMode: 'numeric', className: styles.myInput }}
                        pattern="\d*"
                        thousandSeparator={true}
                        autoComplete="off"
                        allowLeadingZeros={true}
                        allowNegative={false}
                        isNumericString={true}
                        decimalScale={2}
                        value={amount}
                        startAdornment={
                            <InputAdornment position="start">
                                <span>{getCurrencySymbol('United Kingdom')}</span>
                            </InputAdornment>
                        }
                        onValueChange={(e) => handleAmountChange(e)}
                        isAllowed={isMaxAmount}
                        aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                        required
                        className={styles.cardInput}
                    />
                </div>
                <div className="card-input">
                    <label htmlFor="description" className="card-input__label">
                        Description
                    </label>
                    <Input
                        className="card-input__input"
                        type="text"
                        autoComplete="off"
                        value={description}
                        onChange={handleDescriptionChange}
                        name="description"
                        inputProps={{ maxLength: 255 }}
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={showReceipt}
                            onChange={(e) => {
                                setShowReceipt(e.target.checked);
                            }}
                        />
                    }
                    label="Send a receipt"
                />
                {showReceipt && (
                    <div className="card-input">
                        <div className={styles.headerText}>Please enter an email address to send a receipt</div>
                        <ValidEmailInput
                            style={{ margin: 0 }}
                            fullWidth={true}
                            className="card-input__input"
                            labelText="Recipient's Email Address"
                            labelId="email-address"
                            id="email-input"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value.toLowerCase());
                            }}
                        />
                        {/* using below condition for hiding phone number as of now in future we need this */}
                        {/* {!isDna && (
                            <FormItem>
                                <Label text="Telephone number" id="tele-phone-number"></Label>
                                <PhoneInput
                                    country={'gb'}
                                    id="telephone-number-input"
                                    placeholder="Phone number"
                                    value={phoneInput}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={true}
                                    onChange={(phone) => setPhoneInput(phone)}
                                    inputClass={styles.phoneInput}
                                    name="phone"
                                />
                            </FormItem>
                        )} */}
                        {/* <div className={styles.footerText}>Secure payments powered by Omnipay</div> */}
                    </div>
                )}
                <Form.Group>
                    <Form.Label>Proceed your payment via</Form.Label>
                    <div className={styles.paymentOptionContainer}>
                        <div
                            className={`${styles.paymentBox} ${
                                paymentMode === VirtualTerminalPayments.customer_card ? styles.select : ''
                            }`}
                            onClick={() => {
                                setPaymentMode(VirtualTerminalPayments.customer_card);
                            }}
                        >
                            <input
                                id="payment-mode"
                                name="payment-mode"
                                type="radio"
                                checked={paymentMode === VirtualTerminalPayments.customer_card}
                            />
                            <span className="ml-1">Customer Card</span>
                        </div>
                        <div
                            className={`${styles.paymentBox} ${
                                paymentMode === VirtualTerminalPayments.email ? styles.select : ''
                            }`}
                            onClick={() => {
                                setPaymentMode(VirtualTerminalPayments.email);
                            }}
                        >
                            <input
                                id="payment-mode"
                                name="payment-mode"
                                type="radio"
                                checked={paymentMode === VirtualTerminalPayments.email}
                            />
                            <span className="ml-1">Email</span>
                        </div>
                        {isVat && isInvoiceEnabled ? (
                            <div
                                className={`${styles.paymentBox} ${
                                    paymentMode === VirtualTerminalPayments.invoice ? styles.select : ''
                                }`}
                                onClick={() => {
                                    setPaymentMode(VirtualTerminalPayments.invoice);
                                }}
                            >
                                <input
                                    id="payment-mode"
                                    name="payment-mode"
                                    type="radio"
                                    checked={paymentMode === VirtualTerminalPayments.invoice}
                                />
                                <span className="ml-1">Invoice</span>
                            </div>
                        ) : null}
                    </div>
                </Form.Group>
                <div className="card-form__row">
                    <div className="col" style={{ padding: 0, marginTop: 10 }}>
                        <Button
                            className={styles.proceedViaLink}
                            size="large"
                            variant="secondary"
                            block
                            onClick={() => {
                                handlePayment(paymentMode);
                            }}
                            disabled={isDisable}
                        >
                            {VirtualTerminalPaymentsText[paymentMode]}
                        </Button>
                    </div>
                </div>
            </div>
            {showPayByLinkModal ? (
                <ModalEmail
                    setShow={setShowPayByLinkModal}
                    amount={amount}
                    description={description}
                    setAmount={setAmount}
                    setDescription={setDescription}
                    userEmail={email}
                    setUserEmail={setEmail}
                />
            ) : null}
            {showPayByInvoiceModal ? (
                <ModalInvoice
                    showPayByInvoiceModal={showPayByInvoiceModal}
                    setShow={setShowPayByInvoiceModal}
                    amount={amount}
                    description={description}
                    setAmount={setAmount}
                    setDescription={setDescription}
                    userEmail={email}
                    setUserEmail={setEmail}
                />
            ) : null}
        </div>
    );
};

export default VirtualTerminalComponent;
