import React, { useEffect, useState } from 'react';
import OnboardingBusinessProfileComponent from './onboarding-business-profile.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';
import moment from 'moment';

function OnboardingBusinessProfile(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const {
            businessDescriptions,
            timeInBusinessYears,
            timeInBusinessMonths,
            stockLocation,
            isBusinessMakingProducts
        } = onboardingForm.businessProfile;
        const checkEmptyValues = businessDescriptions.length > 0 && timeInBusinessYears && timeInBusinessMonths;
        const checkValues =
            (checkEmptyValues && !isBusinessMakingProducts) ||
            (checkEmptyValues && isBusinessMakingProducts && stockLocation);
        setIsDisabled(!checkValues);
    }, [onboardingForm.businessProfile]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                businessProfile: isDisabled ? false : true
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    const onSubmit = () => {
        var data = { ...onboardingForm.businessProfile };
        if (!onboardingForm.businessProfile.isBusinessMakingProducts) {
            data.productDescriptions = [];
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                businessProfile: {
                    ...onboardingForm.businessProfile,
                    productDescription: ''
                }
            }));
        }

        data = {
            ...data,
            startedBusinessAt: moment()
                .subtract(+data.timeInBusinessYears, 'years')
                .subtract(+data.timeInBusinessMonths, 'months')
                .utcOffset(0)
                .startOf('day')
                .toDate(),
            businessDescriptions: data.businessDescriptions.map((b) => b.id)
        };
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 5, data);
    };

    return (
        <OnboardingBusinessProfileComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
        />
    );
}

export default OnboardingBusinessProfile;
