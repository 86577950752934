import React, { useEffect, useState } from 'react';
import HomeWorkItemsComponent from './home-work-items.components';
import { EntitiesService } from 'services';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { getUserRole } from 'utils/roles-and-permissions';

function HomeWorkItems(props) {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const [businessList, setBusinessList] = useState(null);
    const [isBusinessCreationModalOpen, setIsBusinessCreationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const responseData = await EntitiesService.getHierarchy();
            if (responseData.isSuccesfully) {
                setBusinessList(responseData.data);
                const userRole = getUserRole(responseData.data);
                setGlobalState({ user: globalState.user, userRole: userRole });
            } else {
                setBusinessList(null);
            }
            setLoading(false);
        }
        fetchData();
    }, [props.isShouldUpdateWorkItems, globalState.user, setGlobalState]);

    const showBusinessModal = () => {
        setIsBusinessCreationModalOpen((state) => !state);
    };

    const refreshBusinessList = async () => {
        const responseData = await EntitiesService.getHierarchy();
        if (responseData.isSuccesfully) {
            setBusinessList(responseData.data);
            const userRole = getUserRole(responseData.data);
            setGlobalState({ user: globalState.user, userRole: userRole });
        } else {
            setBusinessList([]);
        }
    };
    return (
        <React.Fragment>
            {businessList ? (
                <HomeWorkItemsComponent
                    data={businessList}
                    isBusinessModalOpen={isBusinessCreationModalOpen}
                    showBusinessModal={showBusinessModal}
                    refreshBusinessList={refreshBusinessList}
                    loading={loading}
                ></HomeWorkItemsComponent>
            ) : (
                ''
            )}
        </React.Fragment>
    );
}

HomeWorkItems.propTypes = {
    isShouldUpdateWorkItems: PropTypes.bool,
};

export default HomeWorkItems;
