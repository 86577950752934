export const BankRejectedReasons = {
    0: '----  Select Reason  ----',
    1: 'Image not clear(blurred)',
    2: 'Image not in colour',
    3: 'Bank logo or name not visible',
    4: 'Account number or sort code not visible',
    5: 'Name of the account holder(owner) or limited company not visible',
    6: 'Document older than 3 months',
    7: 'All corners of the document not visible',
    8: 'Document not in the name of the business or account holder(owner)',
    9: 'Bank cheques not issued by the bank',
    10: 'Cannot accept picture of a credit debit card',
    11: 'The legal entity details of the account holder does not exactly match the legal entity we are processing for',
    12: 'Legal entity name handwritten is not accepted',
    13: 'Date of birth not matching with the user’s date of birth',
    14: 'The first name input needs to match the first or middle name on the ID document.',
    15: 'The last name input needs to match the last name on the identity document.',
    16: 'Nicknames and typos are not accepted',
    17: 'Driving licence is of a different country origin',
    18: 'ID proof expired',
    19: 'Resident permit requires both front and back side images',
    20: 'Name of the company/provider not visible',
    21: 'Address should be under the company/takeaway name',
    22: 'Invoices are not accepted',
    23: 'Mobile bill not accepted, upload a landline bill instead',
    24: 'Signboard not visible clearly',
    25: 'Did not answer the call for verification',
    26: 'Screenshots are not accepted',
    27: 'Custom'
};
