import React, { useState, useEffect } from 'react';
import ModalMerchantTermsAndConditionsComponent from './modal-merchant-terms-and-conditions.component';

function ModalMerchantTermsAndConditions(props) {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            const timeout = setTimeout(() => {
                setShowMessage(false);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [showMessage]);

    return (
        <ModalMerchantTermsAndConditionsComponent
            modalTermsAndConditions="modalDisplay"
            {...props}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
        />
    );
}

export default ModalMerchantTermsAndConditions;
