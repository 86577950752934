import React, { useState, useEffect, useCallback } from 'react';
import AdminService from 'services/admin.service.js';
import PendingVerificationListComponent from './pending-verification-list.component';
import moment from 'moment';
import { useGlobalStateContext } from 'contexts';

const PendingVerificationList = (props) => {
    const { getRoute } = useGlobalStateContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [verificationList, setVerificationList] = useState([]);
    const [total, setTotal] = useState(0);
    const [snackbar, setSnackbar] = useState({ type: '', message: '' });
    const [order, setOrder] = useState('DESC');
    const [orderBy, setOrderBy] = useState('createdDate');
    const [inputValue, setInputValue] = useState('');
    const [countryFilter, setCountryFilter] = useState('all');
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));
    const [daterange, setDateRange] = useState({
        start: moment().subtract(7, 'days').startOf('day'),
        end: moment().endOf('day')
    });
    const { pathname } = props.location;

    const headCells = [
        { id: 'MerchantId', numeric: false, disablePadding: false, label: 'MerchantId', hideSortingIcon: true },
        { id: 'Name', numeric: false, disablePadding: false, label: 'Name', hideSortingIcon: true },
        { id: 'Country', numeric: false, disablePadding: false, label: 'Country', hideSortingIcon: true },
        { id: 'Date', numeric: false, disablePadding: false, label: 'Date' },
        { id: 'Status', numeric: false, disablePadding: false, label: 'Status', hideSortingIcon: true }
    ];

    useEffect(() => {
        getdataResponse(page);
    }, [getdataResponse, page, rowsPerPage, startDate, endDate, countryFilter]);

    const getdataResponse = useCallback(
        async (newPage) => {
            const offsetValue = newPage * rowsPerPage;
            const searchInputData = inputValue.length > 2 ? inputValue.trim() : '';

            setLoading(true);
            try {
                if (pathname.includes('pending-account-verification-list')) {
                    const PendingAccountVerificationData = await AdminService.getPendingDocumentVerification({
                        offsetValue: offsetValue,
                        fromDate: startDate,
                        toDate: endDate,
                        limit: rowsPerPage,
                        searchValue: searchInputData,
                        countryFilter: countryFilter,
                        order
                    });

                    if (PendingAccountVerificationData?.isSuccesfully) {
                        setVerificationList(PendingAccountVerificationData?.data?.sortedDocumentVerificationDto);
                        setTotal(PendingAccountVerificationData.data.count ?? 0);
                        setError(false);
                    } else {
                        setError(true);
                    }
                } else {
                    const PendingBankVerificationData = await AdminService.getPendingBankVerification({
                        offsetValue: offsetValue,
                        fromDate: startDate,
                        toDate: endDate,
                        limit: rowsPerPage,
                        searchValue: inputValue.length > 2 ? inputValue.trim() : '',
                        countryFilter: countryFilter,
                        order
                    });

                    if (PendingBankVerificationData?.isSuccesfully) {
                        setVerificationList(PendingBankVerificationData?.data?.bankVerificationDto);
                        setTotal(PendingBankVerificationData.data.count ?? 0);
                        setError(false);
                    } else {
                        setError(true);
                    }
                }
            } catch (error) {
                setError(true);
            }
            setLoading(false);
        },
        [startDate, endDate, inputValue, countryFilter, order, page, verificationList, total, rowsPerPage]
    );

    const onChangeDateRange = (newStartDate, newEndDate) => {
        if (!newStartDate || !newEndDate) return;

        const initialDate = moment(newStartDate).startOf('day');
        const finalDate = moment(newEndDate).endOf('day');

        setStartDate(initialDate.format('YYYY-MM-DD'));
        setEndDate(finalDate.format('YYYY-MM-DD'));
        setVerificationList([]);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const onSearchMerchants = (e) => {
        e.preventDefault();
        setPage(0);
        getdataResponse(0);
    };

    const handleCountryFilter = useCallback(
        (e) => {
            e.preventDefault();
            setCountryFilter(e.target.value);
            setPage(0);
        },
        [countryFilter]
    );

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const onGoToAccount = (merchantId) => {
        let routeToGo = getRoute(`/document-management/${merchantId}`);
        window.open(routeToGo, '_blank');
    };

    return (
        <PendingVerificationListComponent
            {...props}
            rows={verificationList}
            onGoToAccount={onGoToAccount}
            error={error}
            loading={loading}
            page={page}
            setCountryFilter={setCountryFilter}
            countryFilter={countryFilter}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={total}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            setInputValue={setInputValue}
            handleRequestSort={handleRequestSort}
            onChangeDateRange={onChangeDateRange}
            onSearchMerchants={onSearchMerchants}
            handleCountryFilter={handleCountryFilter}
            snackbar={snackbar}
            setSnackbar={setSnackbar}
            daterange={daterange}
            setDateRange={setDateRange}
        />
    );
};

export default PendingVerificationList;
