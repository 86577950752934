import React, { useState } from 'react';
import CardDetailsFormComponent from './card-details-form.component';
import { isCardYearValid, isCardMonthValid, isCardHolderNameValid, isCvvValid } from '../../utils/validator';

function CardDetailsForm(props) {
    const {
        cardMonth,
        cardYear,
        cvv,
        cardHolder,
        dispatch,
        cardMonthRef,
        cardCvvRef,
        cardYearRef,
        initiateSale,
        propState,
        isFormDataValid
    } = props;

    const [cardNumberMask, setCardNumberMask] = useState('');

    const { amount, description, email, phoneInput } = propState;
    const isButtonDisabled = () => {
        const { cardNumber } = isFormDataValid;

        const cardYearValid = isCardYearValid(cardYear);
        const cardMonthValid = isCardMonthValid(cardMonth);
        const cardCvvValid = isCvvValid(cvv);
        const cardHolderValid = isCardHolderNameValid(cardHolder);

        return cardNumber && cardHolderValid && cardMonthValid && cardYearValid && cardCvvValid;
    };

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        if (name === 'cardHolder' && value.length > 26) return;
        dispatch({ type: 'SET_CARD_DETAILS', name, value });
    };

    // TODO: We can improve the regex check with a better approach like in the card component.
    const onCardNumberChange = (event) => {
        let { value } = event.target;
        const { name } = event.target;

        let newValue = '';
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === '*') {
                newValue = newValue.concat(props.cardNumber.charAt(i));
            } else {
                newValue = newValue.concat(value.charAt(i));
            }
        }
        let cardNumber = newValue;
        newValue = newValue.replace(/\D/g, '');
        if (/^3[47]\d{0,13}$/.test(newValue)) {
            cardNumber = newValue.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(newValue)) {
            // diner's club, 14 digits
            cardNumber = newValue.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        } else if (/^\d{0,16}$/.test(newValue)) {
            // regular cc number, 16 digits
            cardNumber = newValue
                .replace(/(\d{4})/, '$1 ')
                .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
                .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        }

        const formattedCard = cardNumber.trimRight();
        dispatch({
            type: 'SET_CARD_NUMBER',
            name,
            cardNumber: formattedCard
        });

        var strArray = [];
        for (let i = 0; i < formattedCard.length; i++) {
            if (formattedCard.charAt(i) !== ' ' && i > 4 && i < 15) {
                strArray.push('*');
            } else {
                strArray.push(formattedCard.charAt(i));
            }
        }

        setCardNumberMask(strArray.join(''));
    };

    const onCvvFocus = (e) => {
        e.preventDefault();
        dispatch({ type: 'SET_CARD_FLIPPED', isCardFlipped: true });
    };

    const onCvvBlur = (e) => {
        e.preventDefault();
        dispatch({ type: 'SET_CARD_FLIPPED', isCardFlipped: false });
    };

    const onInput = (e) => {
        const { value, name } = e.target;
        if (name === 'cardCvv') {
            e.target.value = value.length >= 0 ? value.substr(0, 4) : value;
            return null;
        }
        e.target.value = value.length >= 0 ? value.substr(0, 2) : value;
        if (value.length === 2) {
            if (name === 'cardMonth') cardYearRef.current.focus();
            if (name === 'cardYear') cardCvvRef.current.focus();
        }
        return null;
    };

    const onKeyDown = (event) => {
        const keyCode = event.keyCode || event.charCode;
        if (keyCode === 8 || keyCode === 46) {
            const { name, value } = event.target;
            if (name === 'cardCvv' && value.length === 0) {
                cardYearRef.current.focus();
            }
            if (name === 'cardYear' && value.length === 0) {
                cardMonthRef.current.focus();
            }
        }
    };

    return (
        <CardDetailsFormComponent
            {...props}
            amount={amount}
            description={description}
            isButtonDisabled={isButtonDisabled}
            handleFormChange={handleFormChange}
            onCardNumberChange={onCardNumberChange}
            onCvvFocus={onCvvFocus}
            onCvvBlur={onCvvBlur}
            onInput={onInput}
            onKeyDown={onKeyDown}
            initiateSale={initiateSale}
            cardNumberMask={cardNumberMask}
            email={email}
            phoneInput={phoneInput}
        />
    );
}

export default CardDetailsForm;
