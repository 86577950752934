import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MerchantDashBoardMenuComponent from './merchant-dashboard-menu.component';
import { useGlobalStateContext } from 'contexts';
import { ProductsRequired, ProductStatus } from '../../utils/enums/ProductsRequired';
import { UserType } from 'utils/enums/UserType';
import { cleverTapClick } from 'utils/helper';

function MerchantDashBoardMenu(props) {
    const date = moment().format('YYYY-MM-DD');
    const { globalState } = useGlobalStateContext();
    const [showPdq, setShowPdq] = useState(false);
    const [showVT, setShowVT] = useState(false);
    const [noOfNotSignedTermsAndConditions, setNoOfNotSignedTermsAndConditions] = useState(0);
    const [noOfSignedTermsAndConditions, setNoOfSignedTermsAndConditions] = useState(0);
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);

    useEffect(() => {
        if (globalState.merchant && globalState.merchant?.hasOwnProperty('merchantProductRequired')) {
            const merchantProductRequired = globalState.merchant?.merchantProductRequired;
            const pdq = merchantProductRequired.find((product) => {
                return (
                    product.productName === ProductsRequired.PDQ &&
                    (product.status === ProductStatus.ACTIVE || product.status === ProductStatus.DELETION_PENDING)
                );
            });

            const vtObj = merchantProductRequired.find((product) => {
                return (
                    product.productName === ProductsRequired.Virtual_Terminal &&
                    (product.status === ProductStatus.ACTIVE || product.status === ProductStatus.DELETION_PENDING)
                );
            });

            setShowVT(!!vtObj);

            if (pdq) setShowPdq(true);
            else setShowPdq(false);
        }
    }, [globalState.merchant]);

    useEffect(() => {
        if (isCanonicalResellerUser) {
            const signingInfo = globalState.canonicalReseller?.termsAndConditionInfo?.signingInfo;
            setNoOfNotSignedTermsAndConditions(signingInfo?.noOfNotSignedTermsAndConditions);
            setNoOfSignedTermsAndConditions(signingInfo?.noOfSignedTermsAndConditions);
        } else {
            setNoOfNotSignedTermsAndConditions(globalState.merchant?.noOfNotSignedTermsAndConditions);
            setNoOfSignedTermsAndConditions(globalState.merchant?.noOfSignedTermsAndConditions);
        }

        // eslint-disable-next-line
    }, [globalState.canonicalReseller, globalState.merchant]);

    const paymentUrl = {
        day: `/payments/day/${date}`,
        week: `/payments/week/${date}`,
        month: `/payments/month/${date}`
    };

    const chargebackUrl = {
        day: `/chargebacks/day/${date}`,
        week: `/chargebacks/week/${date}`,
        month: `/chargebacks/month/${date}`
    };

    const merchantChargesUrl = {
        day: `/merchant-charges/day/${date}`,
        week: `/merchant-charges/week/${date}`,
        month: `/merchant-charges/month/${date}`
    };

    const internalTransfersUrl = {
        day: `/internal-transfers/day/${date}`,
        week: `/internal-transfers/week/${date}`,
        month: `/internal-transfers/month/${date}`
    };

    const payoutsUrl = {
        day: `/payouts/day/${date}`,
        week: `/payouts/week/${date}`,
        month: `/payouts/month/${date}`
    };
    const gfoPaymentsUrl = {
        day: `/gfo-payments/day/${date}`,
        week: `/gfo-payments/week/${date}`,
        month: `/gfo-payments/month/${date}`
    };

    const pdqTransactionUrl = {
        day: `/pdq-transactions/day/${date}`,
        week: `/pdq-transactions/week/${date}`,
        month: `/pdq-transactions/month/${date}`
    };
    const handleCleverClick = (value) => {
        cleverTapClick(value, globalState, {});
    };
    const listRefundUrl = {
        day: `/refund-list/day/${date}`,
        week: `/refund-list/week/${date}`,
        month: `/refund-list/month/${date}`
    };
    const chargebackListUrl = {
        day: `/list-chargebacks/day/${date}`,
        week: `/list-chargebacks/week/${date}`,
        month: `/list-chargebacks/month/${date}`
    };
    return (
        <MerchantDashBoardMenuComponent
            paymentUrl={paymentUrl}
            chargebackUrl={chargebackUrl}
            merchantChargesUrl={merchantChargesUrl}
            internalTransfersUrl={internalTransfersUrl}
            payoutsUrl={payoutsUrl}
            gfoPaymentsUrl={gfoPaymentsUrl}
            showPdq={showPdq}
            showVT={showVT}
            pdqTransactionUrl={pdqTransactionUrl}
            listRefundUrl={listRefundUrl}
            noOfSignedTermsAndConditions={noOfSignedTermsAndConditions}
            noOfNotSignedTermsAndConditions={noOfNotSignedTermsAndConditions}
            isCopyAlertOpen={isCopyAlertOpen}
            setIsCopyAlertOpen={setIsCopyAlertOpen}
            handleCleverClick={handleCleverClick}
            chargebackListUrl={chargebackListUrl}
            {...props}
        />
    );
}

MerchantDashBoardMenu.propTypes = {};

export default MerchantDashBoardMenu;
