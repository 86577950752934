import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText, Typography } from '@material-ui/core';
import styles from './payment-configuration-modes-dialog.module.scss';
import Divider from '@material-ui/core/Divider';

export default function PaymentConfigurationModesModalComponent(props) {
    const {
        testModeUpdate,
        threeDSecureUpdate,
        testModeUpdateDialogBox,
        setTestModeUpdateDialogBox,
        testModeUpdateInitiated,
        threeDSecureUpdateInitiated,
        testModeStatus,
        threeDSecureStatus
    } = props;
    return (
        <React.Fragment>
            <Dialog
                open={testModeUpdateDialogBox}
                onClose={() => setTestModeUpdateDialogBox(false)}
                className={{
                    paper: styles.paper
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                max-width="false"
            >
                {testModeUpdate ? (
                    <div>
                        <DialogContent
                            classes={{
                                root: styles.dialogContent
                            }}
                        >
                            <DialogContentText id="alert-dialog-description">
                                {testModeStatus ? (
                                    <Typography>Are you sure you want this account to be in Test mode?</Typography>
                                ) : (
                                    <Typography>Test mode will be set to “N” </Typography>
                                )}
                            </DialogContentText>
                            <Divider />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setTestModeUpdateDialogBox(false);
                                }}
                                color="primary"
                                variant="outlined"
                                className={styles.leavebtn}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => testModeUpdateInitiated(testModeStatus)}
                                color="primary"
                                className={styles.dilogbtn}
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </div>
                ) : null}
                {threeDSecureUpdate ? (
                    <div>
                        <DialogContent
                            classes={{
                                root: styles.dialogContent
                            }}
                        >
                            <DialogContentText id="alert-dialog-description">
                                {threeDSecureStatus ? (
                                    <Typography>3D Secure will be set to “Y”</Typography>
                                ) : (
                                    <Typography>3D Secure will be set to “N”</Typography>
                                )}
                            </DialogContentText>
                            <Divider />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setTestModeUpdateDialogBox(false);
                                }}
                                color="primary"
                                variant="outlined"
                                className={styles.leavebtn}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => threeDSecureUpdateInitiated(threeDSecureStatus)}
                                color="primary"
                                className={styles.dilogbtn}
                            >
                                Continue
                            </Button>
                        </DialogActions>
                    </div>
                ) : null}
            </Dialog>
        </React.Fragment>
    );
}

PaymentConfigurationModesModalComponent.propTypes = {
    testModeUpdate: PropTypes.bool,
    threeDSecureUpdate: PropTypes.bool,
    setTestModeUpdate: PropTypes.func,
    setThreeDSecureUpdate: PropTypes.func,
    testModeUpdateDialogBox: PropTypes.bool,
    setTestModeUpdateDialogBox: PropTypes.func,
    testModeAcceptInitiated: PropTypes.func,
    threeDSecureUpdateInitiated: PropTypes.func
};
