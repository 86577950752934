import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ChargebackListComponent from './chargebacks-list.component.jsx';
import { ChargebacksService } from 'services';
import { useGlobalStateContext } from 'contexts';

const ChargebackList = (props) => {
    const [error, setError] = useState(false);
    const [chargebacks, setChargebacks] = useState([]);
    const { loading, setLoading } = props;
    const { date, via } = props.match.params;
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const getChargebacksList = async () => {
            const merchantId = globalState.merchant?.id;
            const day = moment(date, 'YYYY-MM-DD').date();
            const month = moment(date, 'YYYY-MM-DD').month() + 1;
            const year = moment(date, 'YYYY-MM-DD').year();
            const week = moment(date).isoWeek();

            const chargebacksList = await ChargebacksService.getChargebacksList({
                merchantId,
                day,
                month,
                year,
                week,
                via: via.toUpperCase()
            });

            if (chargebacksList.isSuccesfully) {
                setChargebacks(chargebacksList.data.data);
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
        };

        setLoading(true);

        getChargebacksList();
        //eslint-disable-next-line
    }, [props.location.pathname, date, via, globalState.merchant]);

    return <ChargebackListComponent {...props} chargebacks={chargebacks} loading={loading} error={error} />;
};

export default ChargebackList;

ChargebackList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string.isRequired,
            via: PropTypes.string.isRequired
        })
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    })
};
