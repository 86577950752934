export const CountryCode = {
    Australia: 'au',
    Anguilla: 'gd',
    default: 'gb'
};

export const CountryCodeOfPhoneNumber = {
    Australia: '61',
    'United Kingdom': '44',
    'New Zealand': '64',
    Canada: '1',
    'United States': '1',
    Ireland: '353',
    Mexico: '52'
};
