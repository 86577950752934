import React from 'react';
import PropTypes from 'prop-types';
import {
    Input,
    Label,
    OnboardingTitle,
    FormItem,
    NextButton,
    OnboardingFormsContainer,
    PostCodeSelector
} from 'components/onboarding/onboarding-ui-components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

function OnboardingOwnerPrimaryAddressComponent(props) {
    const {
        nextStep,
        onboardingForm,
        setOnboardingForm,
        isDisabled,
        onSubmit,
        selectAddressData,
        isT2SMerchant
    } = props;

    const title = (
        <OnboardingTitle title="Personal address" subTitle="Enter your home address details"></OnboardingTitle>
    );

    function formBody() {
        return (
            <React.Fragment>
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                    <FormItem>
                        <Label text="Home postcode" id="owner-primary-address-label-homepostcode-selector"></Label>
                        <PostCodeSelector
                            postCodeValue={checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)}
                            onPostCodeChange={(postCode) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    ownerPrimaryAddress: {
                                        ...onboardingForm.ownerPrimaryAddress,
                                        postCode: postCode
                                    }
                                })
                            }
                            onAddressObjectChange={(addressData) => selectAddressData(addressData)}
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries
                    excludedCountries={[MerchantCountries.UNITED_KINGDOM, MerchantCountries.UNITED_STATES]}
                >
                    <FormItem>
                        <Label text="Post code" id="owner-primary-address-label-homepostcode"></Label>
                        <Input
                            placeholder="Post code"
                            id="owner-primary-address-input-homepostcode"
                            value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    ownerPrimaryAddress: {
                                        ...onboardingForm.ownerPrimaryAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                    <FormItem>
                        <Label text="ZIP code" id="owner-primary-address-label-homepostcode"></Label>
                        <Input
                            placeholder="ZIP code"
                            id="owner-primary-address-input-zip-code"
                            value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    ownerPrimaryAddress: {
                                        ...onboardingForm.ownerPrimaryAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <FormItem>
                    <Label text="Address line 1" id="owner-primary-address-label-addressline1"></Label>
                    <Input
                        placeholder="Address line 1"
                        id="owner-primary-address-input-addressline1"
                        value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.addressLine1)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                ownerPrimaryAddress: {
                                    ...onboardingForm.ownerPrimaryAddress,
                                    addressLine1: e.target.value
                                }
                            })
                        }
                    ></Input>
                </FormItem>
                <FormItem>
                    <Label text="Address line 2" id="owner-primary-address-label-addressline2"></Label>
                    <Input
                        placeholder="Address line 2"
                        id="owner-primary-address-input-addressline2"
                        value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.addressLine2)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                ownerPrimaryAddress: {
                                    ...onboardingForm.ownerPrimaryAddress,
                                    addressLine2: e.target.value
                                }
                            })
                        }
                    ></Input>
                </FormItem>
                <FormItem>
                    <Label text="Town/City" id="owner-primary-address-label-city"></Label>
                    <Input
                        placeholder="Town"
                        id="owner-primary-address-input-city"
                        value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.city)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                ownerPrimaryAddress: {
                                    ...onboardingForm.ownerPrimaryAddress,
                                    city: e.target.value
                                }
                            })
                        }
                    ></Input>
                </FormItem>
                {!isT2SMerchant && (
                    <FormItem>
                        <Label text="Country" id="owner-primary-address-label-country"></Label>
                        <Input
                            placeholder="Country"
                            id="owner-primary-address-input-country"
                            value={checkEmptyValue(onboardingForm.ownerPrimaryAddress.country)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    ownerPrimaryAddress: {
                                        ...onboardingForm.ownerPrimaryAddress,
                                        country: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                )}
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

OnboardingOwnerPrimaryAddressComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    selectAddressData: PropTypes.func
};

export default OnboardingOwnerPrimaryAddressComponent;
