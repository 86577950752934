import React from 'react';
import {
    Box,
    CircularProgress,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Badge,
    Tooltip,
    Button,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import TransactionStatusLabel from '../transaction-tables/transaction-status-label/transaction-status-label.container';
import { formatCurrency, formatDateTimeByFormatString } from '../../../utils/helper';
import styles from './payout-details.module.scss';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import { SnackBarAndAlert, ModalFormConfirmation } from 'components';
import { withStyles } from '@material-ui/core/styles';
import { ModalExportReport } from 'components';
import { AcquirersNameMap } from 'utils/enums/Acquirers';
import { HighlightOff } from '@material-ui/icons';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { UserType } from 'utils/enums/UserType';
import { CheckMerchantCountries } from 'components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
const StyledBadge = withStyles({
    badge: {
        background: 'rgb(202, 245, 201)',
        color: 'rgb(41, 122, 93);',
        transform: 'scale(1) translate(100%, -100%)'
    }
})(Badge);

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        background: 'rgb(202, 245, 201)',
        color: 'rgb(41, 122, 93)',
        fontSize: '0.75rem',
        transform: 'translate(20%, -55%) !important',
        padding: '2px 6px'
    }
}))(Tooltip);

const PayoutDetailsComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const {
        loading,
        error,
        payouts,
        handleCopyBatchId,
        isCopyAlertOpen,
        closeCopyAlert,
        nextExpectedDate,
        type,
        csvData,
        setShowReportModel,
        showReportModel,
        onCancelExport,
        onConfirmExport,
        via,
        date,
        payoutStartDate,
        payoutEndDate,
        page,
        handleChangeRowsPerPage,
        handleChangePage,
        rowsPerPage,
        open,
        setOpen,
        onSubmit,
        setPaymentId,
        paymentId,
        snackbar,
        message,
        handleClose
    } = props;

    const dateLabel = payouts?.provider === AcquirersNameMap['CARDSTREAM-CH'] ? 'Transaction time' : 'Date requested';
    const bankAccountTableLabel =
        globalState?.merchant?.country === MerchantCountries.IRELAND ? 'IBAN' : 'Bank account number';
    const sortCodeLabel =
        globalState?.merchant?.country === MerchantCountries.UNITED_KINGDOM
            ? 'Sort code'
            : globalState?.merchant?.country === MerchantCountries.AUSTRALIA
            ? 'BSB code'
            : globalState?.merchant?.country === MerchantCountries.UNITED_STATES
            ? 'Routing number'
            : 'Transit number';
    const dateField =
        payouts?.provider === AcquirersNameMap['CARDSTREAM-CH']
            ? 'transaction_time'
            : type === 'IN-BATCH'
            ? 'date_issued'
            : 'date_requested';
    const rowKeyField = type === 'IN-BATCH' ? 'batch_item_id' : 'rowKeyIndex';
    const isUkMerchant =
        globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM ||
        globalState.merchant?.country === MerchantCountries.IRELAND;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isAdmin = globalState.user?.UserType?.name === UserType.Admin || isSuperAdmin;
    return (
        <React.Fragment>
            {loading ? (
                <Box textAlign="center" py={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    {error ? (
                        <Box textAlign="center" py={5}>
                            An error occurred, please try reloading your browser.
                        </Box>
                    ) : (
                        <React.Fragment>
                            <div className={styles.containerDiv}>
                                <div className={styles.headerDetails}>
                                    <div className={styles.contentBlock}>
                                        <div>
                                            <Typography color={'primary'}>
                                                <span className={styles.pageTitle}>Payout</span>
                                                {payouts?.batch_id && (
                                                    <span className={styles.spanRefId}>
                                                        {payouts.batch_id}
                                                        <sup>
                                                            <FileCopyOutlinedIcon
                                                                fontSize="inherit"
                                                                onClick={handleCopyBatchId}
                                                                className={styles.copyIcon}
                                                            />
                                                        </sup>
                                                    </span>
                                                )}
                                            </Typography>
                                            {isCopyAlertOpen && (
                                                <SnackBarAndAlert
                                                    open={isCopyAlertOpen}
                                                    onClose={closeCopyAlert}
                                                    type={'success'}
                                                >
                                                    Successfully copied the payout batch id.
                                                </SnackBarAndAlert>
                                            )}
                                        </div>
                                        <div className={styles.amount_items}>
                                            <span className={styles.amount_items__currency}>
                                                {formatCurrency(
                                                    payouts.total,
                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                )}
                                            </span>
                                            <span className={styles.spnaCurrency}>
                                                {getCurrencyThreeAlpha(globalState.merchant?.country)}
                                            </span>
                                            <span style={{ fontSize: '14px', marginLeft: '10px' }}>
                                                {payouts.status ? (
                                                    payouts.not_received ? (
                                                        <TransactionStatusLabel status="NOT RECEIVED" />
                                                    ) : (
                                                        <TransactionStatusLabel status={payouts.status} />
                                                    )
                                                ) : (
                                                    <TransactionStatusLabel status="Pending" />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    {nextExpectedDate && (
                                        <div className={styles.date_expected}>
                                            <Box px={2}>
                                                <div>Date expected</div>
                                                <div style={{ color: 'black' }}>
                                                    {formatDateTimeByFormatString(nextExpectedDate, 'DD MMM HH:mm')}
                                                </div>
                                            </Box>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={styles.payoutsDetailsDiv}>
                                <Box width="100%">
                                    <div className={styles.payoutsDetailsHeader}>
                                        <Typography color={'primary'}>
                                            <span className={styles.pageTitle}>Payout details</span>
                                        </Typography>
                                        <Box mx={2}>
                                            <Button
                                                id="transactionsExportBtn"
                                                color="primary"
                                                variant="outlined"
                                                size="small"
                                                onClick={() => setShowReportModel(true)}
                                            >
                                                Export
                                            </Button>
                                        </Box>
                                    </div>
                                    <Box mt={2}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow className={styles.tableHeader}>
                                                    <TableCell align="left">Amount</TableCell>
                                                    <TableCell align="left">{dateLabel}</TableCell>
                                                    {isAdmin && (
                                                        <>
                                                            <CheckMerchantCountries
                                                                includedCountries={[
                                                                    MerchantCountries.UNITED_STATES,
                                                                    MerchantCountries.AUSTRALIA,
                                                                    MerchantCountries.UNITED_KINGDOM,
                                                                    MerchantCountries.IRELAND
                                                                ]}
                                                            >
                                                                {payouts?.status ? (
                                                                    <TableCell align="left">
                                                                        {bankAccountTableLabel}
                                                                    </TableCell>
                                                                ) : null}
                                                            </CheckMerchantCountries>
                                                            <CheckMerchantCountries
                                                                includedCountries={[
                                                                    MerchantCountries.UNITED_STATES,
                                                                    MerchantCountries.AUSTRALIA,
                                                                    MerchantCountries.UNITED_KINGDOM
                                                                ]}
                                                            >
                                                                {payouts?.status ? (
                                                                    <TableCell align="left">{sortCodeLabel}</TableCell>
                                                                ) : null}
                                                            </CheckMerchantCountries>
                                                        </>
                                                    )}

                                                    {isUkMerchant &&
                                                        isSuperAdmin &&
                                                        payouts.provider === AcquirersNameMap.DATMAN &&
                                                        !payouts.status && (
                                                            <TableCell align="left">More options</TableCell>
                                                        )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(rowsPerPage > 0
                                                    ? payouts.batch_items.slice(
                                                          page * rowsPerPage,
                                                          page * rowsPerPage + rowsPerPage
                                                      )
                                                    : payouts.batch_items
                                                ).map((row) => {
                                                    return (
                                                        <TableRow key={row[rowKeyField]}>
                                                            <TableCell align="left">
                                                                {formatCurrency(
                                                                    row.total,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {row[dateField] ? (
                                                                    formatDateTimeByFormatString(
                                                                        row[dateField],
                                                                        'DD MMM HH:mm'
                                                                    )
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                                <StyledToolTip
                                                                    title={
                                                                        row.method === 'AutoWithdraw'
                                                                            ? 'This was an automatic withdrawal'
                                                                            : ''
                                                                    }
                                                                    placement="right-top"
                                                                >
                                                                    <StyledBadge
                                                                        id="badge"
                                                                        badgeContent={
                                                                            row.method === 'AutoWithdraw' ? 'A' : 0
                                                                        }
                                                                        color="default"
                                                                        data-tooltip="hello"
                                                                    ></StyledBadge>
                                                                </StyledToolTip>
                                                            </TableCell>

                                                            {isAdmin && (
                                                                <>
                                                                    <CheckMerchantCountries
                                                                        includedCountries={[
                                                                            MerchantCountries.UNITED_STATES,
                                                                            MerchantCountries.AUSTRALIA,
                                                                            MerchantCountries.UNITED_KINGDOM,
                                                                            MerchantCountries.IRELAND
                                                                        ]}
                                                                    >
                                                                        {payouts?.status ? (
                                                                            <TableCell align="left">
                                                                                {payouts.account_number ? (
                                                                                    payouts.account_number
                                                                                ) : (
                                                                                    <span> &mdash;</span>
                                                                                )}
                                                                            </TableCell>
                                                                        ) : null}
                                                                    </CheckMerchantCountries>
                                                                    <CheckMerchantCountries
                                                                        includedCountries={[
                                                                            MerchantCountries.UNITED_STATES,
                                                                            MerchantCountries.AUSTRALIA,
                                                                            MerchantCountries.UNITED_KINGDOM
                                                                        ]}
                                                                    >
                                                                        {payouts?.status ? (
                                                                            <TableCell align="left">
                                                                                {payouts.sort_code ? (
                                                                                    payouts.sort_code
                                                                                ) : (
                                                                                    <span> &mdash;</span>
                                                                                )}
                                                                            </TableCell>
                                                                        ) : null}
                                                                    </CheckMerchantCountries>
                                                                </>
                                                            )}
                                                            {isUkMerchant &&
                                                                isSuperAdmin &&
                                                                payouts.provider === AcquirersNameMap.DATMAN &&
                                                                !payouts.status && (
                                                                    <TableCell align="left">
                                                                        <HighlightOff
                                                                            style={{
                                                                                color: '#C62828',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => {
                                                                                setOpen(true);
                                                                                setPaymentId(row.paymentId);
                                                                            }}
                                                                        />
                                                                    </TableCell>
                                                                )}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    {payouts.batch_items.length ? (
                                                        <TablePagination
                                                            rowsPerPageOptions={[
                                                                20,
                                                                { label: 'All', value: payouts.batch_items.length }
                                                            ]}
                                                            count={payouts.batch_items.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: { 'aria-label': 'rows per page' },
                                                                native: true
                                                            }}
                                                            onChangePage={handleChangePage}
                                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                                        />
                                                    ) : null}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ color: '#000' }} colSpan={5}>
                                                        <Box className={styles.divTableFooter}>
                                                            <Box>
                                                                <span className={styles.columnSpan}>Transactions:</span>
                                                                <span className={styles.amountSpan}>
                                                                    {payouts.batch_items.length}
                                                                </span>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </Box>
                                </Box>
                            </div>
                            {open && (
                                <ModalFormConfirmation
                                    id="update-fee-tier"
                                    isModalOpen={open}
                                    setIsModalOpen={setOpen}
                                    confirmationAction={() => {
                                        onSubmit(paymentId);
                                    }}
                                    buttonText={'Confirm'}
                                    modalText={`Are you sure you want to delete this record ?`}
                                />
                            )}
                            {showReportModel ? (
                                <ModalExportReport
                                    reportType="Payout_details"
                                    timeFrameDetails={{ via: via || 'month', date: date }}
                                    cancelExport={onCancelExport}
                                    confirmExport={onConfirmExport}
                                    csvData={csvData}
                                    payOutDetails={true}
                                    payoutStartDate={payoutStartDate}
                                    payoutEndDate={payoutEndDate}
                                    setShowReportModel={setShowReportModel}
                                    showReportModel={showReportModel}
                                />
                            ) : null}
                            {message && (
                                <SnackBarAndAlert open={message} onClose={handleClose} type={snackbar.type}>
                                    {snackbar.message}
                                </SnackBarAndAlert>
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PayoutDetailsComponent;
