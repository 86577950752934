import React from 'react';
import { FormItem, Label } from '../../../onboarding/onboarding-ui-components';
import { Grid, OutlinedInput } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import styles from './update-txn-limit.module.scss';
import { Edit } from '@material-ui/icons';
import DoneIcon from '@material-ui/icons/Done';
import { ModalFormConfirmation } from 'components';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
const UpdateTxnLimitComponent = (props) => {
    const {
        isInputEnable,
        isModalOpen,
        handleButtonClick,
        setIsModalOpen,
        value,
        setValue,
        labelText,
        isMaxAmount,
        disable,
        updateCustomerConfig
    } = props;
    const { globalState } = useGlobalStateContext();
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;
    const disableValue = isSuperAdmin ? disable && isInputEnable : true;

    return (
        <React.Fragment>
            <FormItem>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={5}>
                        <Label>{labelText}</Label>
                    </Grid>
                    <Grid item xs={5} md={4} className={styles.editContainer}>
                        <NumberFormat
                            className={`${
                                !isInputEnable ? styles.midInput + ' ' + styles.disabledInput : styles.midInput
                            }`}
                            customInput={OutlinedInput}
                            inputProps={{
                                inputMode: 'numeric',
                                maxLength: 12
                            }}
                            disabled={!isInputEnable}
                            pattern="\d*"
                            allowLeadingZeros={true}
                            decimalSeparator={false}
                            allowNegative={false}
                            isNumericString={true}
                            isAllowed={isMaxAmount}
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                            labelWidth={0}
                            required
                        />

                        {/* Added a new Grid container */}

                        <button
                            variant="outlined"
                            size="small"
                            color="primary"
                            className={styles.dialogbtn}
                            onClick={handleButtonClick}
                            disabled={disableValue}
                        >
                            {isInputEnable ? <DoneIcon style={{ color: 'green' }} /> : <Edit />}
                        </button>
                    </Grid>
                </Grid>
            </FormItem>
            <ModalFormConfirmation
                id="submit-confirmation"
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                confirmationAction={() => {
                    updateCustomerConfig();
                }}
                buttonText={'Yes'}
                modalText="If you modify this order limit, it will be saved according to the new limit."
                newStyle={true}
                modalHeader="Are you sure you want to change the maximum order limit?"
            />
        </React.Fragment>
    );
};

export default UpdateTxnLimitComponent;
