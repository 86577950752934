import React from 'react';

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from '@material-ui/core/';
import { payoutStatus } from 'utils/enums/payoutStatusToDate';
import styles from './modal-payouts-mark-not-received.module.scss';
export default function ModalPayoutsMarkNotReceivedComponent(props) {
    const { open, setOpen, onmarkConfirm, markPayout, isLoading, error } = props;
    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            classes={{
                paper: styles.containerDialog
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                <p className={styles.headerText}>
                    {markPayout.status === payoutStatus.RESEND
                        ? 'Resend payout'
                        : markPayout.status === payoutStatus.NOT_RECEIVED
                        ? `Mark payout as not received`
                        : 'Cancel request'}
                </p>
                <div className={styles.headerDivider}></div>
            </DialogTitle>

            <DialogContent>
                {isLoading ? (
                    <React.Fragment>
                        <Box textAlign="center" width="100%">
                            <Box textAlign="center" py={2}>
                                <CircularProgress />
                            </Box>
                            <p>Please wait, we are processing your request...</p>
                        </Box>
                    </React.Fragment>
                ) : (
                    <DialogContentText id="alert-dialog-description">
                        {error ? (
                            `something went wrong.. try again later`
                        ) : (
                            <Box>
                                <span>
                                    {markPayout.status === payoutStatus.RESEND
                                        ? 'Are you sure you want to resend this payout?'
                                        : markPayout.status === payoutStatus.NOT_RECEIVED
                                        ? `Are you sure to make this payout ID ${markPayout.batch_id} as not received?`
                                        : 'Are you sure you want to cancel this request?'}
                                </span>
                            </Box>
                        )}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions p={3}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Button
                            className={styles.button}
                            disabled={isLoading}
                            onClick={() => {
                                setOpen(false);
                            }}
                            color="primary"
                            variant="outlined"
                        >
                            {error ? `Close` : `Cancel`}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            className={styles.button}
                            variant="contained"
                            disabled={isLoading}
                            onClick={() => {
                                onmarkConfirm(markPayout);
                            }}
                            color="primary"
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
