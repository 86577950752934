import React, { useState, useEffect } from 'react';
import MerchantDashboardOptionsComponent from './merchant-dashboard-options.component';
import { useGlobalStateContext } from 'contexts';
import { AuthService } from 'services';
import moment from 'moment';
import { triggerDays, MAX_PASSWORD_CHANGE_DAYS } from 'utils/enums/ChangePasswordTriggerDays';
import { SnackbarMessages } from 'utils/text enums/snackbar-text';

function MerchantDashboardOptions() {
    const { globalState } = useGlobalStateContext();
    const [verified, setVerified] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [verificationEmailMessage, setVerificationEmailMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [snackbar, setSnackbar] = useState('');
    let userHasSeenSnackbar;

    useEffect(() => {
        // Checking if the user has already seen the Snackbar
        const userHasSeenSnackbar = localStorage.getItem('userHasSeenSnackbar');

        // Calculating the difference in days since the password was updated
        const diffeneceInTime = moment().diff(
            moment(globalState.user.updatedPasswordAt, 'YYYY-MM-DD HH:mm:ss'),
            'days'
        );

        // Checking if the user hasn't seen the Snackbar and if the difference in days matches a trigger day
        if (!userHasSeenSnackbar && triggerDays.includes(diffeneceInTime)) {
            setSnackbar(SnackbarMessages.PASSWORD_UPCOMING_EXPIRY_MESSAGE(MAX_PASSWORD_CHANGE_DAYS - diffeneceInTime));

            // Setting a timeout to mark the Snackbar as seen and clear the message after a delay of 10 seconds
            const timeout = setTimeout(() => {
                localStorage.setItem('userHasSeenSnackbar', 'true');
                setSnackbar('');
            }, 10000);

            // Cleaning up the timeout when the component unmounts or when the effect runs again
            return () => clearTimeout(timeout);
        } else setSnackbar('');
    }, [globalState.user.updatedPasswordAt]);

    // For adding userHasSeenSnackbar if url changes. It will help us in not showing the snackbar only once
    useEffect(() => {
        localStorage.setItem('userHasSeenSnackbar', 'true');
        // eslint-disable-next-line
    }, [window.location.href]);

    const sendVerificationEmail = async () => {
        setIsLoading(true);
        const response = await AuthService.sendVerificationEmail({
            email: globalState.user.email,
            resellerUrl: globalState.reseller.portalURL
        });
        setIsLoading(false);
        setShowMessage(true);
        if (response.isSuccesfully) {
            setMessageType('success');
            setVerificationEmailMessage('Verification email has been sent successfully. Please check inbox.');
        } else {
            setMessageType('error');
            setVerificationEmailMessage('An error occurred. Try again !');
        }
    };

    return (
        <MerchantDashboardOptionsComponent
            user={globalState.user}
            verified={verified}
            setVerified={setVerified}
            reseller={globalState.reseller}
            sendVerificationEmail={sendVerificationEmail}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            verificationEmailMessage={verificationEmailMessage}
            messageType={messageType}
            isLoading={isLoading}
            snackbar={snackbar}
            userHasSeenSnackbar={userHasSeenSnackbar}
        />
    );
}

export default MerchantDashboardOptions;
