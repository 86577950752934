import React, { useState, useEffect } from 'react';
import OnboardingTransactionProfileStepTwoComponent from './onboarding-transaction-profile-step-two.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';
function OnboardingTransactionProfileStpTwo(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const {
            companyTurnOverActual,
            companyTurnOverProjected,
            cardTurnOverActual,
            cardTurnOverProjected,
            priceRangeMin,
            priceRangeMax,
            priceRangeAvg
        } = onboardingForm.transactionProfile.stepTwo;
        const checkValues =
            companyTurnOverActual &&
            companyTurnOverProjected &&
            cardTurnOverActual &&
            cardTurnOverProjected &&
            priceRangeMin &&
            priceRangeMax &&
            priceRangeAvg;
        setIsDisabled(!checkValues);
    }, [onboardingForm.transactionProfile.stepTwo]);

    const onSubmit = () => {
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant.id, 6, {
            transactionProfileData: onboardingForm.transactionProfile.stepTwo
        });
    };

    return (
        <OnboardingTransactionProfileStepTwoComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
        />
    );
}

export default OnboardingTransactionProfileStpTwo;
