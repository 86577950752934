import React from 'react';
import AdminDashboardInfoComponent from './admin-dashboard-info.component';

export default function AdminDashboardInfo() {
    function createData(customer, price, fee, net, time, refund, ipAddr, date) {
        return { customer, price, fee, net, time, refund, ipAddr, date };
    }

    const rows = [
        createData('Tikaram Mardi', 159, 16.0, 2.4, 4.0, 3, '192.279.3.24', '12 Aug'),
        createData('Test Account', 19, 63.0, 24, 4.0, 32, '192.279.3.34', '12 Aug'),
        createData('New  Customer', 150, 6.0, 24, 4.1, 3, '192.279.3.4', '12 Aug')
    ];
    return <AdminDashboardInfoComponent rows={rows} />;
}
