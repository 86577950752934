import React, { useState, useEffect, useRef } from 'react';
import UpdateBankInfoComponent from './update-bank-info.component';
import { BankService, BusinessDetailsService, DocumentsService, AuthService, AdminService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { ValidationMessage } from 'models';
import {
    validateBankDetailsData,
    validateIrelandBankDetailsData,
    validateMypayBankDetailsData,
    validateAustraliaBankDetails,
    validateUnitedStatesBankDetails
} from 'validators';
import { validateNZBankDetailsData } from 'validators/update-bank/update-bank.validator';
import { CheckMerchantCountries } from 'components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { BankAccountLengthConstaints } from 'utils/enums/BankAccountLength';
import { ResellerType } from 'utils/enums/ResellerType';
import { bankValidationMap } from 'validators/onboarding/onboarding-account-number.validator';
import OtpService from 'services/otp.service';
import { UserType } from 'utils/enums/UserType';
import { maskCharacter } from 'utils/helper';
import { formTypes } from 'utils/enums/FormTypes';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';

export default function UpdateBankInfo() {
    const initialValidationMessage = new ValidationMessage();
    const { globalState, setRoute } = useGlobalStateContext();
    const [errorMessage, setErrorMessage] = useState(initialValidationMessage.message);
    const [bankData, setBankData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [bankUpdateDialogBox, setBankUpdateDialogBox] = useState(true);
    const [bankUpdateVerifyDialogBox, setBankUpdateVerifyDialogBox] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpError, setOtpError] = useState('');
    const [confirmExistingSortCode, setConfirmExistingSortCode] = useState('');
    const [confirmExistingBsbCode, setConfirmExistingBsbCode] = useState('');
    const [confirmExistingRouteNum, setConfirmExistingRouteNum] = useState('');
    const [confirmExistingAcountNumber, setConfirmExistingAcountNumber] = useState('');
    const [confirmAustraliaAcountNum, setConfirmAustraliaAcountNum] = useState('');
    const [confirmUnitedStatesAcountNum, setConfirmUnitedStatesAcountNum] = useState('');

    const [confirmExistingIBAN, setConfirmExistingIBAN] = useState('IE');
    const [newSortCode, setNewSortCode] = useState('');
    const [newBsbCode, setNewBsbCode] = useState('');
    const [newRouteNum, setNewRouteNum] = useState('');
    const [newAcountNumber, setNewAcountNumber] = useState('');
    const [confirmExistingNZAcountNumber, setConfirmExistingNZAcountNumber] = useState('');
    const [newNZAcountNumber, setNewNZAcountNumber] = useState('');
    const [newNZAcountNumberConfirm, setNewNZAcountNumberConfirm] = useState('');
    const [newAustraliaAccountNum, setNewAustraliaAccountNum] = useState('');
    const [newIBAN, setNewIBAN] = useState('IE');
    const [newAcountNumberConfirm, setNewAcountNumberConfirm] = useState('');
    const [newAustraliaAcountNumConfirm, setNewAustraliaAcountNumConfirm] = useState('');
    const [newUnitedStatesAcountNumConfirm, setNewUnitedStatesAcountNumConfirm] = useState('');
    const [newIBANConfirm, setNewIBANConfirm] = useState('IE');
    const [newAcountHolder, setNewAcountHolder] = useState('');
    const [bankDetailsUpdateInitiated, setBankUpdateDetailsInitiated] = useState(false);
    const [overRideRequest, setOverRideRequest] = useState(false);
    const [newBankDetailsToSave, setNewBankDetailsToSave] = useState({});
    const [showMessage, setShowMessage] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showErrorForAccNo, setShowErrorForAccNo] = useState(false);
    const [AccNoErrorMessage, setAccNoErrorMessage] = useState('');
    const [showErrorForNewAccNo, setShowErrorForNewAccNo] = useState(false);
    const [newAccNoErrorMessage, setNewAccNoErrorMessage] = useState('');
    const [show, setShow] = useState(false);
    const [isBankDetailsNotFound, setIsBankDetailsNotFound] = useState(false);
    const [newUnitedStatesAccountNum, setNewUnitedStatesAccountNum] = useState('');
    const [bankName, setBankName] = useState('');

    const [showUKValidation, setShowUKValidation] = useState(
        globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM
    );
    const [showIrelandValidation, setShowIrelandValidation] = useState(
        globalState.merchant?.country === MerchantCountries.IRELAND
    );
    const [showAustraliaValidation, setShowAustraliaValidation] = useState(
        globalState.merchant?.country === MerchantCountries.AUSTRALIA
    );
    const [showUnitedStatesValidation, setShowUnitedStatesValidation] = useState(
        globalState.merchant?.country === MerchantCountries.UNITED_STATES
    );
    const [showNZValidation, setShowNZValidation] = useState(
        globalState.merchant?.country === MerchantCountries.NEW_ZEALAND
    );
    const existingAccNumberInputRef = useRef(null);
    const newAccNumberInputRef = useRef(null);

    const [statusValidationBankAccount, setStatusValidationBankAccount] = useState(0);
    const [statusValidationIBAN, setStatusValidationIBAN] = useState(0);
    const [businessDetails, setBusinessDetails] = useState({});
    const [formattedDocumentsStats, setFormattedDocumentsStats] = useState({});
    const [validForm, setValidForm] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [bucketDocuments, setBucketDocuments] = useState([]);

    const [csrfToken, setCSRFToken] = useState('');

    useEffect(() => {
        const generateCSRFToken = async () => {
            setIsLoading(true);
            const response = await AuthService.generateCSRFToken(formTypes.UPDATE_BANK_INFO, globalState.merchant?.id);
            if (response.isSuccesfully) {
                setCSRFToken(response.data.csrfToken);
            }
            setIsLoading(false);
        };
        generateCSRFToken();
        // eslint-disable-next-line
    }, [globalState.merchant.id]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await BankService.getBankDetails(globalState.merchant?.id);
            if (response.isSuccesfully) {
                setBankData(response.data);
            }
        };
        const retrieveBusinessDetails = async () => {
            const businessDetailsResult = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);
            if (businessDetailsResult.isSuccesfully) {
                setBusinessDetails(businessDetailsResult.data.businessDetails || {});
            }
        };
        if (globalState.merchant?.id) {
            retrieveBusinessDetails();
        }
        fetchData();
    }, [globalState.merchant]);

    const doAutoFocus = (elementId) => {
        document.getElementById(elementId).focus();
    };

    // useEffect(() => {
    //     setBankUpdateDetailsInitiated(true);
    //     if (bankDetailsUpdateInitiated) {
    //         setBankUpdateDialogBox(true);
    //     }
    // }, [
    //     newAustraliaAccountNum,
    //     newAustraliaAcountNumConfirm,
    //     newBsbCode,
    //     newAcountNumber,
    //     newAcountNumberConfirm,
    //     newSortCode,
    //     confirmExistingIBAN,
    //     newIBAN,
    //     newIBANConfirm,
    //     confirmExistingSortCode,
    //     confirmExistingAcountNumber,
    //     confirmExistingBsbCode,
    //     confirmAustraliaAcountNum,
    //     bankDetailsUpdateInitiated
    // ]);

    useEffect(() => {
        async function validateAustraliaBankDetails() {
            setShowAustraliaValidation(globalState.merchant?.country === MerchantCountries.AUSTRALIA);
            validatenewAcctNumber(newAustraliaAccountNum);
            validateAcctNumber(confirmAustraliaAcountNum);
            setShowMessage(false);

            if (globalState.merchant?.country === MerchantCountries.AUSTRALIA) {
                const allfields =
                    (newAustraliaAccountNum &&
                        newAustraliaAcountNumConfirm &&
                        newAcountHolder &&
                        confirmAustraliaAcountNum &&
                        bankName) !== (null || '') &&
                    newAustraliaAccountNum === newAustraliaAcountNumConfirm &&
                    confirmAustraliaAcountNum.length >= 5 &&
                    newAustraliaAccountNum.length >= 5 &&
                    newAustraliaAcountNumConfirm.length >= 5 &&
                    newBsbCode &&
                    newBsbCode.length === 6 &&
                    confirmExistingBsbCode &&
                    confirmExistingBsbCode.length === 6;
                setDisabled(!allfields);
            }
            if (newAustraliaAccountNum === newAustraliaAcountNumConfirm) {
                setShow(false);
                if (
                    globalState.merchant?.country === MerchantCountries.AUSTRALIA &&
                    newAustraliaAccountNum &&
                    newAustraliaAccountNum.length >= 5 &&
                    newBsbCode &&
                    newBsbCode.length === 6
                ) {
                    setShowAustraliaValidation(true);
                    setStatusValidationBankAccount(1);
                    setShowMessage(false);

                    //api call for validation
                }
            } else if (
                newAustraliaAccountNum.length <= newAustraliaAcountNumConfirm.length &&
                newAustraliaAccountNum !== newAustraliaAcountNumConfirm
            ) {
                setShowMessage(true);
            } else {
                setStatusValidationBankAccount(2);
            }
        }
        validateAustraliaBankDetails();
        // eslint-disable-next-line
    }, [
        globalState.merchant,
        newAustraliaAccountNum,
        newAustraliaAcountNumConfirm,
        newBsbCode,
        confirmAustraliaAcountNum,
        newAcountHolder,
        confirmExistingBsbCode,
        bankName
    ]);

    useEffect(() => {
        if (globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
            validateAcctNumber(confirmExistingAcountNumber);
            let allFields;

            if (globalState.reseller.name === ResellerType.DATMAN) {
                allFields =
                    (newAcountNumber &&
                        confirmExistingAcountNumber &&
                        newAcountHolder &&
                        newAcountNumberConfirm &&
                        bankName) !== (null || '') &&
                    newAcountNumber === newAcountNumberConfirm &&
                    newAcountNumber.length === 8 &&
                    newAcountNumberConfirm.length === 8 &&
                    confirmExistingAcountNumber.length === 8 &&
                    newSortCode &&
                    newSortCode.length === 6 &&
                    confirmExistingSortCode &&
                    confirmExistingSortCode.length === 6;
            } else {
                allFields =
                    (newAcountNumber && newAcountHolder && newAcountNumberConfirm) !== (null || '') &&
                    newAcountNumber === newAcountNumberConfirm &&
                    newAcountNumber.length === 8 &&
                    newAcountNumberConfirm.length === 8 &&
                    newSortCode &&
                    newSortCode.length === 6;
            }
            setDisabled(!allFields);
        }
        // eslint-disable-next-line
    }, [
        globalState.merchant,
        newAcountNumber,
        newAcountNumberConfirm,
        newSortCode,
        confirmExistingAcountNumber,
        confirmExistingSortCode,
        newAcountHolder,
        bankName
    ]);
    useEffect(() => {
        async function validateUKBankDetails() {
            setShowUKValidation(globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM);
            validatenewAcctNumber(newAcountNumber);
            if (newAcountNumber === newAcountNumberConfirm) {
                setShow(false);
                let validNumber;
                if (
                    globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM &&
                    newAcountNumber &&
                    newAcountNumber.length === 8 &&
                    newSortCode &&
                    newSortCode.length === 6
                ) {
                    setShowUKValidation(true);
                    setShowMessage(false);
                    setIsLoading(true);
                    const response = await BankService.validateBankDetailsForUK({
                        accountNumber: newAcountNumber,
                        sortCode: newSortCode
                    });
                    if (response.isSuccesfully) {
                        validNumber = response.data.IsCorrect;
                    }
                    setStatusValidationBankAccount(validNumber ? 1 : 2);
                    setIsBankDetailsNotFound(!validNumber);
                    setIsLoading(false);
                    setIsDisabled(!validNumber);
                }
            } else if (
                newAcountNumber.length <= newAcountNumberConfirm.length &&
                newAcountNumber !== newAcountNumberConfirm
            ) {
                setShowMessage(true);
            } else {
                setStatusValidationBankAccount(2);
                setShowMessage(false);
            }
        }

        validateUKBankDetails();
        // eslint-disable-next-line
    }, [globalState.merchant, newAcountNumber, newAcountNumberConfirm, newSortCode]);

    useEffect(() => {
        async function validateIrelandBankDetails() {
            setShowIrelandValidation(globalState.merchant?.country === MerchantCountries.IRELAND);
            validatenewAcctNumber(newIBAN);
            validateAcctNumber(confirmExistingIBAN);
            if (globalState.merchant?.country === MerchantCountries.IRELAND) {
                const allfields =
                    (newIBAN && newIBANConfirm && newAcountHolder && confirmExistingIBAN && bankName) !==
                        (null || '') &&
                    newIBAN === newIBANConfirm &&
                    confirmExistingIBAN.length === 22 &&
                    newIBAN.length === 22 &&
                    newIBANConfirm.length === newIBAN.length;
                setDisabled(!allfields);
            }
            if (newIBAN === newIBANConfirm) {
                setShow(false);
                if (globalState.merchant?.country === MerchantCountries.IRELAND && newIBAN && newIBAN.length === 22) {
                    setShowIrelandValidation(true);
                    setStatusValidationBankAccount(1);
                    setShowMessage(false);
                    // api call to validate IBAN
                }
            } else if (newIBAN.length <= newIBANConfirm.length && newIBAN !== newIBANConfirm) {
                setShowMessage(true);
            } else {
                setShowMessage(false);
                setStatusValidationBankAccount(2);
            }
        }

        validateIrelandBankDetails();
        // eslint-disable-next-line
    }, [globalState.merchant, confirmExistingIBAN, newIBAN, newIBANConfirm, newAcountHolder, bankName]);

    useEffect(() => {
        async function validateNZBankDetails() {
            setShowUKValidation(globalState.merchant?.country === MerchantCountries.NEW_ZEALAND);
            validatenewAcctNumber(newNZAcountNumber);
            validateAcctNumber(confirmExistingNZAcountNumber);
            setShowMessage(false);
            if (globalState.merchant?.country === MerchantCountries.NEW_ZEALAND) {
                const allfields =
                    (newNZAcountNumber &&
                        newNZAcountNumberConfirm &&
                        newAcountHolder &&
                        confirmExistingNZAcountNumber &&
                        bankName) !== (null || '') &&
                    newNZAcountNumber === newNZAcountNumberConfirm &&
                    confirmExistingNZAcountNumber.length >= 15 &&
                    newNZAcountNumber.length >= 15 &&
                    newNZAcountNumberConfirm.length === newNZAcountNumber.length;
                setDisabled(!allfields);
            }
            if (newNZAcountNumber === newNZAcountNumberConfirm) {
                setShow(false);
                if (
                    globalState.merchant?.country === MerchantCountries.NEW_ZEALAND &&
                    newNZAcountNumber &&
                    newNZAcountNumber.length >= 15 &&
                    newNZAcountNumberConfirm.length === newNZAcountNumber.length
                ) {
                    setShowMessage(false);
                    setShowNZValidation(true);
                    setStatusValidationBankAccount(1);

                    //api call for validation
                }
            } else if (
                newNZAcountNumber.length <= newNZAcountNumberConfirm.length &&
                newNZAcountNumber !== newNZAcountNumberConfirm
            ) {
                setShowMessage(true);
            } else {
                setShow(false);
                setShowMessage(false);
                setStatusValidationBankAccount(2);
            }
        }
        validateNZBankDetails();
        // eslint-disable-next-line
    }, [
        globalState.merchant,
        newNZAcountNumber,
        newNZAcountNumberConfirm,
        confirmExistingNZAcountNumber,
        newAcountHolder,
        bankName
    ]);

    useEffect(() => {
        async function validateUnitedStatesBankDetails() {
            setShowUnitedStatesValidation(globalState.merchant?.country === MerchantCountries.UNITED_STATES);
            validatenewAcctNumber(newUnitedStatesAccountNum);
            validateAcctNumber(confirmUnitedStatesAcountNum);
            setShowMessage(false);

            if (globalState.merchant?.country === MerchantCountries.UNITED_STATES) {
                const allfields =
                    (newUnitedStatesAccountNum &&
                        newUnitedStatesAcountNumConfirm &&
                        newAcountHolder &&
                        confirmUnitedStatesAcountNum &&
                        bankName) !== (null || '') &&
                    newUnitedStatesAccountNum === newUnitedStatesAcountNumConfirm &&
                    confirmUnitedStatesAcountNum.length >=
                        BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
                    newUnitedStatesAccountNum.length >=
                        BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
                    newUnitedStatesAcountNumConfirm.length >=
                        BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
                    newRouteNum &&
                    newRouteNum.length === 9 &&
                    confirmExistingRouteNum &&
                    confirmExistingRouteNum.length === 9;
                setDisabled(!allfields);
            }
            if (newUnitedStatesAccountNum === newUnitedStatesAcountNumConfirm) {
                setShow(false);
                if (
                    globalState.merchant?.country === MerchantCountries.UNITED_STATES &&
                    newUnitedStatesAccountNum &&
                    newUnitedStatesAccountNum.length >=
                        BankAccountLengthConstaints[MerchantCountries.UNITED_STATES].minLength &&
                    newRouteNum &&
                    newRouteNum.length === 9
                ) {
                    setShowUnitedStatesValidation(true);
                    setStatusValidationBankAccount(1);
                    setShowMessage(false);

                    //api call for validation
                }
            } else if (
                newUnitedStatesAccountNum.length <= newUnitedStatesAcountNumConfirm.length &&
                newUnitedStatesAccountNum !== newUnitedStatesAcountNumConfirm
            ) {
                setShowMessage(true);
            } else {
                setStatusValidationBankAccount(2);
            }
        }
        validateUnitedStatesBankDetails();
        // eslint-disable-next-line
    }, [
        globalState.merchant,
        newUnitedStatesAccountNum,
        newUnitedStatesAcountNumConfirm,
        newRouteNum,
        confirmUnitedStatesAcountNum,
        newAcountHolder,
        confirmExistingRouteNum,
        bankName
    ]);

    const validateAcctNumber = (newAccountNumber) => {
        var validateAccNumber = bankValidationMap(globalState.merchant?.country, newAccountNumber);
        if (validateAccNumber) {
            setShowErrorForAccNo(!validateAccNumber.isValid && newAccountNumber.length !== 0);
            setAccNoErrorMessage(validateAccNumber.message);
        }
        return validateAccNumber.isValid;
    };
    const validatenewAcctNumber = (newAccountNumber) => {
        var validateAccNumber = bankValidationMap(globalState.merchant?.country, newAccountNumber);
        if (validateAccNumber) {
            setShowErrorForNewAccNo(!validateAccNumber.isValid && newAccountNumber.length !== 0);
            setNewAccNoErrorMessage(validateAccNumber.message);
        }
        return validateAccNumber.isValid;
    };
    const validateData = () => {
        const bankDetailsData = {
            confirmExistingSortCode,
            confirmExistingAcountNumber,
            newSortCode,
            newAcountNumber,
            newAcountNumberConfirm,
            newAcountHolder
        };

        const validationResult = validateBankDetailsData(bankDetailsData, bankData);

        setErrorMessage(validationResult.message);
        return validationResult.isValid;
    };

    const validateIrelandData = () => {
        const bankDetailsData = {
            confirmExistingIBAN,
            newIBAN,
            newIBANConfirm,
            newAcountHolder
        };

        const validationResult = validateIrelandBankDetailsData(bankDetailsData, bankData);

        setStatusValidationIBAN(validationResult);

        setErrorMessage(validationResult?.message);
        return validationResult?.isValid;
    };

    const validateNZData = () => {
        const bankDetailsData = {
            confirmExistingNZAcountNumber,
            newNZAcountNumber,
            newNZAcountNumberConfirm,
            newAcountHolder
        };
        const validationResult = validateNZBankDetailsData(bankDetailsData, bankData);
        setErrorMessage(validationResult?.message);
        return validationResult?.isValid;
    };
    const validateMypayData = () => {
        const bankDetailsData = {
            newSortCode,
            newAcountNumber,
            newAcountNumberConfirm,
            newAcountHolder
        };

        const validationResult = validateMypayBankDetailsData(bankDetailsData, bankData);
        setErrorMessage(validationResult.message);
        return validationResult.isValid;
    };

    const validateAustraliaData = () => {
        const bankDetailsData = {
            confirmExistingBsbCode,
            confirmAustraliaAcountNum,
            newBsbCode,
            newAustraliaAccountNum,
            newAustraliaAcountNumConfirm,
            newAcountHolder
        };
        const validationResult = validateAustraliaBankDetails(bankDetailsData, bankData);

        setErrorMessage(validationResult.message);
        return validationResult.isValid;
    };

    const validateUnitedStatesData = () => {
        const bankDetailsData = {
            confirmExistingRouteNum,
            confirmUnitedStatesAcountNum,
            newRouteNum,
            newUnitedStatesAccountNum,
            newUnitedStatesAcountNumConfirm,
            newAcountHolder
        };
        const validationResult = validateUnitedStatesBankDetails(bankDetailsData, bankData);

        setErrorMessage(validationResult.message);
        return validationResult.isValid;
    };

    const sendOtp = async () => {
        setBankUpdateDialogBox(false);
        setIsLoading(true);
        setLoader(true);
        const response = await OtpService.createOtp(globalState.merchant?.id, {
            method: 'phone',
            type: 'bank-update',
            portalURL: globalState.reseller?.portalURL
        });
        if (response.isSuccesfully) {
            setPhoneNumber(maskCharacter(response.data.data));
            setBankUpdateVerifyDialogBox(true);
        } else {
            setOtpError(response.data);
        }
        setIsLoading(false);
        setLoader(false);
    };

    const raiseBankDetailsUpdateRequest = async (data) => {
        setIsLoading(true);
        setLoader(true);
        data = {
            ...data,
            formType: formTypes.UPDATE_BANK_INFO
        };
        const response = await BankService.raiseNewBankDetailsUpdateRequest(globalState.merchant?.id, data, csrfToken);
        if (response.isSuccesfully) {
            setLoader(false);
            await DocumentsService.notifyAdminAboutDocumentUploading(
                globalState.merchant.id,
                globalState.reseller.id,
                true
            );
            AdminService.saveUserKycLogs(globalState.merchant.id, {
                resellerId: globalState.reseller.id,
                notes: '',
                documentId: data.newBankDocs[0].id,
                documentStatus: DocumentStatusToName.NEED_APPROVAL,
                docTypeId: data.newBankDocs[0].documentTypeId
            });
            setRoute('/settings', { redirected: true });
        } else {
            if (response.error && response.error.message) {
                setErrorMessage(response.error.message);
            } else {
                setErrorMessage('Please contact support or try again.');
            }
            setLoader(false);
        }
        setIsLoading(false);
    };

    const handleSubmit = async (event) => {
        //setIsLoading(true);
        event.preventDefault();
        setOverRideRequest(false);
        setBankUpdateDetailsInitiated(false);
        setBankUpdateDialogBox(true);

        let newBankDetails;
        if (globalState.reseller?.name === ResellerType.DATMAN) {
            if (globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
                if (!validateData() || (showUKValidation && statusValidationBankAccount !== 1)) {
                    return;
                }
                newBankDetails = {
                    newSortCode: newSortCode,
                    newAccountNumber: newAcountNumber,
                    accountHolderName: newAcountHolder,
                    nameOfBank: bankName,
                    resellerId: globalState.reseller.id
                };
            } else if (globalState.merchant?.country === MerchantCountries.IRELAND) {
                if (!validateIrelandData() || (showIrelandValidation && statusValidationBankAccount !== 1)) {
                    return;
                }

                newBankDetails = {
                    newAccountNumber: newIBAN,
                    accountHolderName: newAcountHolder,
                    nameOfBank: bankName,
                    resellerId: globalState.reseller.id
                };
            } else if (globalState.merchant?.country === MerchantCountries.NEW_ZEALAND) {
                if (!validateNZData() || (showNZValidation && statusValidationBankAccount !== 1)) {
                    return;
                }

                newBankDetails = {
                    newAccountNumber: newNZAcountNumber,
                    accountHolderName: newAcountHolder,
                    nameOfBank: bankName,
                    resellerId: globalState.reseller.id
                };
            } else if (globalState.merchant?.country === MerchantCountries.UNITED_STATES) {
                if (!validateUnitedStatesData() || (showUnitedStatesValidation && statusValidationBankAccount !== 1)) {
                    return;
                }

                newBankDetails = {
                    routingNumber: newRouteNum,
                    newAccountNumber: newUnitedStatesAccountNum,
                    accountHolderName: newAcountHolder,
                    nameOfBank: bankName,
                    resellerId: globalState.reseller.id
                };
            }
        }
        if (globalState.reseller?.name === ResellerType.OMNIPAY) {
            if (!validateMypayData() || (showUKValidation && statusValidationBankAccount !== 1)) {
                return;
            }
            newBankDetails = {
                newSortCode: newSortCode,
                newAccountNumber: newAcountNumber,
                accountHolderName: newAcountHolder,
                resellerId: globalState.reseller.id
            };
        } else if (globalState.merchant?.country === MerchantCountries.IRELAND) {
            if (!validateIrelandData() || (showIrelandValidation && statusValidationBankAccount !== 1)) {
                return;
            }

            newBankDetails = {
                newAccountNumber: newIBAN,
                accountHolderName: newAcountHolder,
                nameOfBank: bankName,
                resellerId: globalState.reseller.id
            };
        } else if (globalState.merchant?.country === MerchantCountries.AUSTRALIA) {
            if (!validateAustraliaData() || (showAustraliaValidation && statusValidationBankAccount !== 1)) {
                return;
            }

            newBankDetails = {
                newBsbCode: newBsbCode,
                newAccountNumber: newAustraliaAccountNum,
                accountHolderName: newAcountHolder,
                resellerId: globalState.reseller.id,
                nameOfBank: bankName
            };
        }
        newBankDetails = {
            ...newBankDetails,
            newBankDocs: bucketDocuments
        };
        setErrorMessage(null);
        setLoader(true);
        const response = await BankService.getNewBankUpdateRequestDetails(globalState.merchant?.id);
        setLoader(false);
        if (response.isSuccesfully) {
            setOverRideRequest(true);
            setNewBankDetailsToSave(newBankDetails);
            return;
        }
        //call api to update bank details
        if (
            globalState.reseller?.name === ResellerType.DATMAN &&
            globalState?.user?.UserType?.name !== UserType.SuperAdmin &&
            globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM
        ) {
            setNewBankDetailsToSave(newBankDetails);
            sendOtp();
        } else {
            raiseBankDetailsUpdateRequest(newBankDetails);
        }
        //setIsLoading(false);
    };
    let checkValue;
    if (globalState.merchant?.country === MerchantCountries.AUSTRALIA) {
        checkValue = () => {
            const isSame = newAustraliaAccountNum.length > newAustraliaAcountNumConfirm.length;
            setShow(false);
            if (isSame) {
                setShow(true);
            }
        };
    }
    if (globalState.merchant?.country === MerchantCountries.UNITED_STATES) {
        checkValue = () => {
            const isSame = newUnitedStatesAccountNum.length > newUnitedStatesAcountNumConfirm.length;
            setShow(false);
            if (isSame) {
                setShow(true);
            }
        };
    }
    if (globalState.merchant?.country === MerchantCountries.NEW_ZEALAND) {
        checkValue = () => {
            const isSame = newNZAcountNumber.length !== newNZAcountNumberConfirm.length;
            if (isSame) {
                setShow(true);
            }
        };
    }
    if (globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
        checkValue = () => {
            const isSame = newAcountNumber.length > newAcountNumberConfirm.length;
            if (isSame) {
                setShow(true);
            }
        };
    }
    if (globalState.merchant?.country === MerchantCountries.IRELAND) {
        checkValue = () => {
            const isSame = newIBAN.length > newIBANConfirm.length;
            if (isSame) {
                setShow(true);
            }
        };
    }
    const bankNameFieldOnChange = (e) => {
        let inputValue = e.target.value.replace(/[^A-Za-z0-9.()& -]/g, '').toUpperCase();
        if (inputValue.length <= 80) setBankName(inputValue);
    };

    const bankNameFieldOnBlur = () => {
        let trimmedValue = bankName.trim().replace(/\s+/g, ' ');
        setBankName(trimmedValue);
    };

    const bankNameFieldOnKeyDown = (e) => {
        const isCharacterAllowed = !/^[a-zA-Z0-9.()&\- ]+$/.test(e.key);
        const isBackspace = e.key === 'Backspace';
        if (isCharacterAllowed && !isBackspace) e.preventDefault();
    };

    return (
        <CheckMerchantCountries
            includedCountries={[
                MerchantCountries.UNITED_KINGDOM,
                MerchantCountries.IRELAND,
                MerchantCountries.AUSTRALIA,
                MerchantCountries.NEW_ZEALAND,
                MerchantCountries.UNITED_STATES
            ]}
        >
            <UpdateBankInfoComponent
                confirmExistingBsbCode={confirmExistingBsbCode}
                setConfirmExistingBsbCode={setConfirmExistingBsbCode}
                handleSubmit={handleSubmit}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                isLoading={isLoading}
                confirmExistingSortCode={confirmExistingSortCode}
                setConfirmExistingSortCode={setConfirmExistingSortCode}
                confirmExistingIBAN={confirmExistingIBAN}
                setConfirmExistingIBAN={setConfirmExistingIBAN}
                confirmExistingAcountNumber={confirmExistingAcountNumber}
                setConfirmExistingAcountNumber={setConfirmExistingAcountNumber}
                newSortCode={newSortCode}
                setNewSortCode={setNewSortCode}
                newBsbCode={newBsbCode}
                setNewBsbCode={setNewBsbCode}
                newIBAN={newIBAN}
                setNewIBAN={setNewIBAN}
                newAcountNumber={newAcountNumber}
                setNewAcountNumber={setNewAcountNumber}
                newAcountNumberConfirm={newAcountNumberConfirm}
                setNewAcountNumberConfirm={setNewAcountNumberConfirm}
                newNZAcountNumber={newNZAcountNumber}
                setNewNZAcountNumber={setNewNZAcountNumber}
                newNZAcountNumberConfirm={newNZAcountNumberConfirm}
                setNewNZAcountNumberConfirm={setNewNZAcountNumberConfirm}
                confirmExistingNZAcountNumber={confirmExistingNZAcountNumber}
                setConfirmExistingNZAcountNumber={setConfirmExistingNZAcountNumber}
                newIBANConfirm={newIBANConfirm}
                setNewIBANConfirm={setNewIBANConfirm}
                newAcountHolder={newAcountHolder}
                setNewAcountHolder={setNewAcountHolder}
                showUKValidation={showUKValidation}
                showIrelandValidation={showIrelandValidation}
                statusValidationBankAccount={statusValidationBankAccount}
                existingAccNumberInputRef={existingAccNumberInputRef}
                newAccNumberInputRef={newAccNumberInputRef}
                statusValidationIBAN={statusValidationIBAN}
                setStatusValidationIBAN={setStatusValidationIBAN}
                newAustraliaAccountNum={newAustraliaAccountNum}
                setNewAustraliaAccountNum={setNewAustraliaAccountNum}
                confirmAustraliaAcountNum={confirmAustraliaAcountNum}
                setConfirmAustraliaAcountNum={setConfirmAustraliaAcountNum}
                newAustraliaAcountNumConfirm={newAustraliaAcountNumConfirm}
                setNewAustraliaAcountNumConfirm={setNewAustraliaAcountNumConfirm}
                bankUpdateDialogBox={bankUpdateDialogBox}
                setBankUpdateDialogBox={setBankUpdateDialogBox}
                bankDetailsUpdateInitiated={bankDetailsUpdateInitiated}
                overRideRequest={overRideRequest}
                setOverRideRequest={setOverRideRequest}
                raiseBankDetailsUpdateRequest={raiseBankDetailsUpdateRequest}
                newBankDetailsToSave={newBankDetailsToSave}
                setIsLoading={setIsLoading}
                setBankUpdateDetailsInitiated={setBankUpdateDetailsInitiated}
                setBankData={setBankData}
                doAutoFocus={doAutoFocus}
                showMessage={showMessage}
                disabled={disabled}
                showErrorForAccNo={showErrorForAccNo}
                AccNoErrorMessage={AccNoErrorMessage}
                showErrorForNewAccNo={showErrorForNewAccNo}
                newAccNoErrorMessage={newAccNoErrorMessage}
                checkValue={checkValue}
                show={show}
                setShow={setShow}
                isBankDetailsNotFound={isBankDetailsNotFound}
                isDisabled={isDisabled}
                phoneNumber={phoneNumber}
                loader={loader}
                sendOtp={sendOtp}
                otpError={otpError}
                setOtpError={setOtpError}
                confirmExistingRouteNum={confirmExistingRouteNum}
                setConfirmExistingRouteNum={setConfirmExistingRouteNum}
                confirmUnitedStatesAcountNum={confirmUnitedStatesAcountNum}
                setConfirmUnitedStatesAcountNum={setConfirmUnitedStatesAcountNum}
                newRouteNum={newRouteNum}
                setNewRouteNum={setNewRouteNum}
                newUnitedStatesAcountNumConfirm={newUnitedStatesAcountNumConfirm}
                setNewUnitedStatesAcountNumConfirm={setNewUnitedStatesAcountNumConfirm}
                newUnitedStatesAccountNum={newUnitedStatesAccountNum}
                setNewUnitedStatesAccountNum={setNewUnitedStatesAccountNum}
                showUnitedStatesValidation={showUnitedStatesValidation}
                setShowUnitedStatesValidation={setShowUnitedStatesValidation}
                bankUpdateVerifyDialogBox={bankUpdateVerifyDialogBox}
                setBankUpdateVerifyDialogBox={setBankUpdateVerifyDialogBox}
                businessDetails={businessDetails}
                formattedDocumentsStats={formattedDocumentsStats}
                setFormattedDocumentsStats={setFormattedDocumentsStats}
                validForm={validForm}
                setValidForm={setValidForm}
                uploadSuccess={uploadSuccess}
                setUploadSuccess={setUploadSuccess}
                bucketDocuments={bucketDocuments}
                setBucketDocuments={setBucketDocuments}
                csrfToken={csrfToken}
                bankName={bankName}
                setBankName={setBankName}
                bankNameFieldOnChange={bankNameFieldOnChange}
                bankNameFieldOnBlur={bankNameFieldOnBlur}
                bankNameFieldOnKeyDown={bankNameFieldOnKeyDown}
                formType={formTypes.UPDATE_BANK_INFO}
            />
        </CheckMerchantCountries>
    );
}
