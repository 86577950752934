import { useGlobalStateContext } from 'contexts';
import React from 'react';
import { AdminService } from 'services';
import AllowWithdrawalsComponent from './allow-withdrawals.component';

export default function AllowWithdrawals() {
    const { globalState } = useGlobalStateContext();

    const onChange = (value) => {
        AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            allowWithdrawals: value,
            resellerId: globalState.reseller?.id
        });
    };

    return <AllowWithdrawalsComponent onChange={onChange} />;
}
