import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { LoadingScreen } from 'components';
import { UserType } from 'utils/enums/UserType';
import { useGlobalStateContext } from 'contexts';

export default function AddPricingTierComponent(props) {
    const { loading, feeTier, pricingTiers, updateFeeTier, setIsModalUpdateFeeTier, setUpdateFeeTierValue } = props;
    const { globalState } = useGlobalStateContext();

    return (
        <React.Fragment>
            <div>
                <FormItem>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={5}>
                            <Label text="Fee Tier" id="fee-tier"></Label>
                        </Grid>
                        <Grid item xs={5} md={4}>
                            <NativeSelect
                                input={
                                    <Input
                                        id="fee-tier-select"
                                        value={feeTier}
                                        readOnly={globalState.user?.UserType?.name !== UserType.SuperAdmin}
                                        onChange={(e) => {
                                            if (globalState.user?.UserType?.name === UserType.SuperAdmin) {
                                                setIsModalUpdateFeeTier(true);
                                                setUpdateFeeTierValue(e.target.value);
                                            }
                                        }}
                                    ></Input>
                                }
                            >
                                {globalState.user?.UserType?.name === UserType.SuperAdmin ? (
                                    pricingTiers?.map((pricingTier, index) => {
                                        return (
                                            <option key={index} value={pricingTier.id}>
                                                Tier {pricingTier.id} ({pricingTier.percentage_fee + '%'} +{' '}
                                                {pricingTier.fixed_fee})
                                            </option>
                                        );
                                    })
                                ) : (
                                    <option value={feeTier}>
                                        Tier {updateFeeTier.id} ({updateFeeTier.percentage_fee + '%'} +{' '}
                                        {updateFeeTier.fixed_fee})
                                    </option>
                                )}
                            </NativeSelect>
                        </Grid>
                    </Grid>
                </FormItem>
            </div>
            <LoadingScreen open={loading}></LoadingScreen>
        </React.Fragment>
    );
}

AddPricingTierComponent.propTypes = {
    loading: PropTypes.bool,
    handleOnChange: PropTypes.func,
    feeTier: PropTypes.string,
    pricingTiers: PropTypes.array
};
