import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import { SuperAdminEmails } from 'utils/helper';
import { UserType } from 'utils/enums/UserType';
import ModalConfirmReverseWithdrawal from '../modal-confirm-reverse-withdrawal/modal-confirm-reverse-withdrawal.container';
import LoadingScreen from 'components/loading-screen/loading-screen.container';

export default function AccountStatusComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions } = useAdminFunctionsContext();
    const { onChange, isModalOpen, setIsModalOpen, updatedMerchantData, isLoading, setIsLoading } = props;
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;

    return (
        <div>
            <FormItem>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={5}>
                        <Label>Account Status</Label>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        <NativeSelect
                            disabled={
                                isReseller ||
                                (adminFunctions.accountStatus === MerchantAccountStatus.WATCHLIST &&
                                    !SuperAdminEmails.includes(globalState.user.email))
                            }
                            input={
                                <Input
                                    id="transaction-refund-id"
                                    value={adminFunctions.accountStatus}
                                    onChange={(e) => {
                                        onChange(e.target.value);
                                    }}
                                />
                            }
                        >
                            {Object.keys(MerchantAccountStatus).map((status) => (
                                <option key={status}>{MerchantAccountStatus[status]}</option>
                            ))}
                        </NativeSelect>
                    </Grid>
                </Grid>
                <ModalConfirmReverseWithdrawal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    modalText={`Are you sure to change the status from ${
                        MerchantStatusNames[globalState.merchant?.status]
                    } to WatchList ?`}
                    updatedMerchantData={updatedMerchantData}
                    accountStatusUpdate={true}
                    setIsLoading={setIsLoading}
                />
            </FormItem>
            <LoadingScreen open={isLoading} />
        </div>
    );
}

AccountStatusComponent.propTypes = {
    onChange: PropTypes.func
};
