import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText } from '@material-ui/core';
import styles from './modal-confirm-reverse-withdrawal.module.scss';
import Divider from '@material-ui/core/Divider';

export default function ModalConfirmReverseWithdrawalComponent(props) {
    const { isModalOpen, setIsModalOpen, modalText, reverseTheWithdrawals } = props;

    return (
        <Dialog
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            className={{
                paper: styles.paper
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            max-width="false"
        >
            <DialogContent
                classes={{
                    root: styles.dialogContent
                }}
            >
                <DialogContentText id="alert-dialog-description">{modalText}</DialogContentText>
                <Divider />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    color="primary"
                    variant="outlined"
                    className={styles.leaveBtn}
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    onClick={reverseTheWithdrawals}
                    color="primary"
                    className={styles.dialogBtn}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalConfirmReverseWithdrawalComponent.propTypes = {
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    confirmationAction: PropTypes.func,
    onChange: PropTypes.func,
    modalText: PropTypes.string,
    buttonText: PropTypes.string
};
