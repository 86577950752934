import { PendingVerificationList } from 'components';
import React from 'react';
// import styles from './missing-merchant-document.module.scss'

export default function AdminPendingBankVerfication(props) {
    return (
        <div>
            <PendingVerificationList {...props} />
        </div>
    );
}
