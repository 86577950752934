import React from 'react';
import { Form, Button, Row, Col, Container, Spinner, Modal } from 'react-bootstrap';
import { OutlinedInput } from '@material-ui/core';
import NumberFormat from 'react-number-format';
// import PhoneInput from 'react-phone-input-2';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import styles from './modal-invoice-form.module.scss';
import { emailValidationPattern } from '../../../utils/enums/EmailValidationPattern';
import moment from 'moment';

function ModalInvoiceComponent(props) {
    const {
        amount,
        message,
        loading,
        success,
        handleClose,
        handleSubmit,
        formFields,
        handleInvoiceFieldsChange,
        showPayByInvoiceModal
    } = props;
    const {
        email,
        // phone,
        quantity,
        item,
        firstName,
        lastName,
        dateOfExpiry,
        // phoneError,
        emailError,
        supplyDate
    } = formFields;

    const { globalState } = useGlobalStateContext();

    const invoiceSendForm = () => {
        return !loading ? (
            <Container className=" justify-content-center  ">
                <Form onSubmit={handleSubmit}>
                    <Row className={styles.heading}>Please enter customer details</Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    id="email"
                                    placeholder="name@example.com"
                                    autoFocus
                                    name="email"
                                    defaultValue={email}
                                    value={email}
                                    autoComplete="off"
                                    disabled={loading}
                                    pattern={emailValidationPattern.regexString}
                                    onChange={(event) => {
                                        handleInvoiceFieldsChange('email', event.target.value.toLowerCase());
                                    }}
                                />
                                {emailError ? (
                                    <span
                                        style={{ color: '#f44336', fontSize: '12px' }}
                                        className="MuiFormHelperText-root"
                                    >
                                        {emailError}
                                    </span>
                                ) : (
                                    <span>&#8192;</span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <p className="text-muted text-center font-weight-bold m-0"> Or </p>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <PhoneInput
                                    id="telephone-number-input"
                                    name="phone"
                                    placeholder="Phone number"
                                    country={'gb'}
                                    value={phone}
                                    alwaysDefaultMask={true}
                                    countryCodeEditable={true}
                                    onChange={(phone) => handleInvoiceFieldsChange('phone', phone)}
                                    inputClass={styles.phoneInput}
                                    disabled={loading}
                                />
                                {phoneError ? (
                                    <span
                                        style={{ color: '#f44336', fontSize: '12px' }}
                                        className="MuiFormHelperText-root"
                                    >
                                        {phoneError}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </Form.Group>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label id="first-name">First Name</Form.Label>
                                <Form.Control
                                    required
                                    id="first-name-input"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => handleInvoiceFieldsChange('firstName', e.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label id="last-name">Last Name</Form.Label>
                                <Form.Control
                                    id="last-name"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => handleInvoiceFieldsChange('lastName', e.target.value)}
                                    required
                                    disabled={loading}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} sx={6}>
                            <Form.Group>
                                <Form.Label id="quantity">Quantity</Form.Label>
                                <NumberFormat
                                    id="quantity"
                                    placeholder="Quantity"
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric', maxLength: 7 }}
                                    pattern="\d*"
                                    thousandSeparator={false}
                                    allowLeadingZeros={false}
                                    allowNegative={false}
                                    isNumericString={true}
                                    decimalScale={0}
                                    fixedDecimalScale={0}
                                    onChange={(e) => {
                                        handleInvoiceFieldsChange('quantity', e.target.value);
                                    }}
                                    value={quantity}
                                    required
                                    disabled={loading}
                                    className={styles.quantityInput}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4} sx={6}>
                            <Form.Group>
                                <Form.Label id="date-of-expiry">Supply Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    min={new moment().format('YYYY-MM-DD')}
                                    max={new moment().add(28, 'days').format('YYYY-MM-DD')}
                                    value={supplyDate}
                                    onChange={(event) => {
                                        handleInvoiceFieldsChange('supplyDate', event.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col sm={4} sx={6}>
                            <Form.Group>
                                <Form.Label id="date-of-expiry">Date of Expiry</Form.Label>
                                <Form.Control
                                    type="date"
                                    min={new moment().format('YYYY-MM-DD')}
                                    max={new moment().add(28, 'days').format('YYYY-MM-DD')}
                                    value={dateOfExpiry}
                                    onChange={(event) => {
                                        handleInvoiceFieldsChange('dateOfExpiry', event.target.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label id="item">Item Description</Form.Label>
                                <Form.Control
                                    id="item"
                                    name="item"
                                    type="text"
                                    placeholder="item"
                                    value={item}
                                    onChange={(e) => handleInvoiceFieldsChange('item', e.target.value)}
                                    required
                                    disabled={loading}
                                    maxLength={255}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button
                            variant="primary"
                            size="lg"
                            type="submit"
                            className={styles.requestAmountButton}
                            disabled={loading}
                        >
                            <span>
                                Send Invoice {getCurrencySymbol(globalState.merchant?.country)} {amount}
                            </span>
                        </Button>
                        <div className="text-danger text-small"> {message ? message : <br />}</div>
                    </div>
                </Form>
            </Container>
        ) : (
            <div className="text-center">
                <Spinner animation="grow" className="text-center" role="status" />
                <p className="text-muted">Notifying customer please wait...</p>
            </div>
        );
    };

    return (
        <>
            <Modal show={showPayByInvoiceModal} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Pay by Invoice - request</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-1">
                    {success === false ? (
                        invoiceSendForm()
                    ) : (
                        <h1>
                            {message}
                            <span style={{ color: '#29a329', font: 'bold' }} className="fe fe-check" />
                        </h1>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalInvoiceComponent;
