import React, { useState } from 'react';
import PasswordInputComponent from './password-input.component';

function PasswordInput(props) {
    const [showPassword, setShowPassword] = useState(false);

    return <PasswordInputComponent showPassword={showPassword} setShowPassword={setShowPassword} {...props} />;
}

export default PasswordInput;
