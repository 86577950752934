export const DocumentStatusColor = {
    ACTIVE: {
        backgroundColor: '#CAF5C9',
        color: '#297A5D',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px'
    },
    NEED_APPROVAL: {
        backgroundColor: '#03A2E8',
        color: 'white',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px'
    },
    OLD: {
        backgroundColor: '#C3C3C3',
        color: '#111111',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px'
    },
    REJECTED: {
        backgroundColor: '#FFCCCB',
        color: '#e60000',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px'
    },
    DELETED: {
        backgroundColor: '#C7BFE7',
        color: '#111111',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px'
    },
    OVERRIDDEN: {
        backgroundColor: '#C7BFE7',
        color: '#111111',
        padding: '4px',
        borderRadius: '4px',
        fontSize: '12px'
    }
};
