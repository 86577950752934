import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form, Button, Row, Col, Container, Spinner } from 'react-bootstrap';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import styles from './modal-email-form.module.scss';
import { emailValidationPattern } from '../../../utils/enums/EmailValidationPattern';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fdf2ee',
        fontSize: '14px',
        color: '#43425d',
        border: '2px solid #deb4a9',
        marginTop: '5px',
        maxWidth: '100%'
    },
    arrow: { backgroundColor: '#fdecea' }
}))(Tooltip);

function ModalEmailComponent(props) {
    const {
        amount,
        email,
        message,
        setEmail,
        loading,
        success,
        handleClose,
        handleSubmit,
        inValidForm,
        emailValidationMessage,
        setEmailValidationMessage
    } = props;
    const { globalState } = useGlobalStateContext();

    const emailForm = () => {
        return !loading ? (
            <Container className=" justify-content-center  ">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col className="col">
                            <Form.Group>
                                <Form.Label>Enter Customer's Email</Form.Label>
                                <ErrorTooltip title={emailValidationMessage} open={emailValidationMessage}>
                                    <Form.Control
                                        id="email"
                                        placeholder="name@example.com"
                                        autoFocus
                                        name="email"
                                        defaultValue={email}
                                        value={email}
                                        autoComplete="off"
                                        // add regex pattern for extra formate validation
                                        pattern={emailValidationPattern.regexString}
                                        onChange={(event) => {
                                            setEmail(event.target.value.toLowerCase());
                                            setEmailValidationMessage('');
                                        }}
                                        required
                                    />
                                </ErrorTooltip>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <Button
                            variant="primary"
                            size="lg"
                            type="submit"
                            className={styles.requestAmountButton}
                            disabled={inValidForm}
                        >
                            <span>
                                Request {getCurrencySymbol(globalState.merchant?.country)} {amount}
                            </span>
                        </Button>
                    </div>
                </Form>
            </Container>
        ) : (
            <div className="text-center">
                <Spinner animation="grow" className="text-center" role="status" />
                <p className="text-muted">Notifying customer please wait...</p>
            </div>
        );
    };

    return (
        <>
            <Modal show onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Pay by link - request</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {success === null ? (
                        emailForm()
                    ) : success ? (
                        <h1>
                            Email has been sent successfully.
                            <span style={{ color: '#29a329', font: 'bold' }} className="fe fe-check" />
                        </h1>
                    ) : (
                        <h1>
                            {message}
                            <span style={{ color: '#8b0000', font: 'bold' }} className="fe fe-x" />
                        </h1>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalEmailComponent;
