import React, { useEffect, useState } from 'react';
import PayoutDetailsComponent from './payout-details.component';
import _ from 'lodash';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { formatDateTimeByFormatString, formatAmount } from 'utils/helper';
import { useGlobalStateContext, useBalanceContext } from 'contexts';
import moment from 'moment';
import { AcquirersNameMap } from 'utils/enums/Acquirers';
import { PayoutsService } from 'services';
import browserHistory from 'browserHistory';
function PayoutDetailsContainer(props) {
    const { batchId, type, date } = props;
    const { setReplaceRoute, globalState } = useGlobalStateContext();
    const [payouts, setPayouts] = useState([]);
    const [nextExpectedDate, setNextExpectedDate] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [showReportModel, setShowReportModel] = useState(false);
    const [payoutStartDate, setPayoutStartDate] = useState('');
    const [payoutEndDate, setPayoutEndDate] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [paymentId, setPaymentId] = useState(null);
    const [open, setOpen] = useState(false);
    const { refreshBalance } = useBalanceContext();
    const [snackbar, SetSnackbar] = useState({ type: '', message: '' });
    const [message, setMessage] = useState(false);

    useEffect(() => {
        const getPayout = async () => {
            if (props.payouts) {
                setPayouts(props.payouts);
                let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                const length = props.payouts.batch_items?.length;
                let payoutDetailsData = [];
                const dateLabel =
                    props.payouts?.provider === AcquirersNameMap['CARDSTREAM-CH']
                        ? 'Transaction Time'
                        : 'Date Requested';
                const dateField =
                    props.payouts?.provider === AcquirersNameMap['CARDSTREAM-CH']
                        ? 'transaction_time'
                        : type === 'IN-BATCH'
                        ? 'date_issued'
                        : 'date_requested';
                payoutDetailsData = _.map(props.payouts.batch_items, (payouts) => {
                    const dateRequested =
                        payouts.provider === AcquirersNameMap.DATMAN ||
                        props.payouts?.provider === AcquirersNameMap['CARDSTREAM-CH']
                            ? formatDateTimeByFormatString(payouts[dateField], 'DD MMM HH:mm')
                            : '--';

                    return {
                        Amount: `${currenySymbol.toString()} ${formatAmount(payouts.total)}`,
                        [dateLabel]: dateRequested
                    };
                });

                if (
                    (props.payouts?.provider === AcquirersNameMap.DATMAN ||
                        props.payouts?.provider === AcquirersNameMap['CARDSTREAM-CH']) &&
                    props.payouts.batch_items.length > 0
                ) {
                    setPayoutEndDate(props.payouts?.batch_items[0][dateField]);
                    setPayoutStartDate(props.payouts?.batch_items[length - 1][dateField]);
                } else {
                    setPayoutEndDate(moment(date).endOf('month').format('YYYY-MM-DD'));
                    setPayoutStartDate(moment(date).startOf('month').format('YYYY-MM-DD'));
                }
                setCSVData(payoutDetailsData);
                setNextExpectedDate(props.nextExpectedDate);
            } else {
                setError(true);
            }
            setLoading(false);
        };
        getPayout();
        // eslint-disable-next-line
    }, [payouts]);

    const handleCopyBatchId = () => {
        navigator.clipboard.writeText(payouts?.batch_id);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    const onCancelExport = () => {
        setShowReportModel(false);
    };
    const onConfirmExport = () => {
        setShowReportModel(false);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));

        // setRowsPerPage(parseInt(event.target.value, 20));
    };

    const onSubmit = async (paymentId) => {
        setLoading(true);

        const payload = {
            payment_id: paymentId,
            user_deleted: `${globalState?.user?.firstName} ${globalState?.user?.lastName}`
        };
        const response = await PayoutsService.DeletePayoutTransaction(payload);

        if (response.isSuccesfully) {
            refreshBalance();
            const payoutData = [...payouts.batch_items];

            let index;
            let amount;
            payoutData.forEach((e, i) => {
                if (e.paymentId === paymentId) {
                    index = i;
                    amount = e.total;
                }
            });
            index !== -1 && payoutData.splice(index, 1);
            payoutData.length === 0 && browserHistory.goBack();
            setPayouts({ ...payouts, batch_items: payoutData, total: payouts.total - amount });
            setReplaceRoute(`/payout-details/${batchId}`, {
                ...props,
                payouts: { ...props.payouts, batch_items: payoutData, total: payouts.total - amount }
            });

            SetSnackbar({ type: 'success', message: response.data?.message });
        } else {
            SetSnackbar({ type: 'error', message: response.data?.message });
        }
        setOpen(false);
        setLoading(false);
        setMessage(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setMessage(false);
        }
    };
    return (
        <PayoutDetailsComponent
            {...props}
            payouts={payouts}
            error={error}
            loading={loading}
            show={show}
            setShow={setShow}
            nextExpectedDate={nextExpectedDate}
            batchId={batchId}
            handleCopyBatchId={handleCopyBatchId}
            closeCopyAlert={closeCopyAlert}
            isCopyAlertOpen={isCopyAlertOpen}
            csvData={csvData}
            showReportModel={showReportModel}
            setShowReportModel={setShowReportModel}
            onCancelExport={onCancelExport}
            onConfirmExport={onConfirmExport}
            payoutStartDate={payoutStartDate}
            payoutEndDate={payoutEndDate}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onSubmit={onSubmit}
            open={open}
            setOpen={setOpen}
            paymentId={paymentId}
            setPaymentId={setPaymentId}
            snackbar={snackbar}
            message={message}
            handleClose={handleClose}
        ></PayoutDetailsComponent>
    );
}

export default PayoutDetailsContainer;
