import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    makeStyles,
    Paper,
    Box
} from '@material-ui/core';
import { Input, Label, FormItem, PostCodeSelector } from 'components/onboarding/onboarding-ui-components';
import styles from './update-account-mypay.module.scss';
import { LoadingScreen, ValidURLInput, ValidEmailInput } from 'components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { FormatMessage } from 'utils/languageTranslater';
function UpdateAccountMypayComponent(props) {
    const {
        disabled,
        businessDetails,
        ownerDetails,
        setBusinessDetails,
        setOwnerDetails,
        onSubmit,
        selectAddressData,
        showModal,
        modalMessage,
        isLoading,
        phoneNumberValidator,
        setShowModal
    } = props;

    const useStyles = makeStyles((theme) => ({
        disabledInput: {
            color: theme.palette.text.primary,
            backgroundColor: 'Gainsboro',
            border: '1px solid LightGray',
            borderRadius: '5px',
            fontWeight: '600',
            userSelect: 'none'
        }
    }));

    const classes = useStyles();

    return (
        <Paper className={styles.updateAccountMypayContainer}>
            <Box className={styles.pageTitle}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Update Account Details
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <div className={styles.formContainer}>
                        <FormItem>
                            <Label text="Name" id="contact-name"></Label>
                            <Input
                                placeholder="Name"
                                id="Contact-name-input"
                                value={ownerDetails.fullName}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        fullName: e.target.value
                                    });
                                }}
                                disabled
                                classes={{ disabled: classes.disabledInput }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Post Code" id="owner-post-code"></Label>
                            <PostCodeSelector
                                postCodeValue={checkEmptyValue(ownerDetails.postCode)}
                                onPostCodeChange={(postCode) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        postCode: postCode
                                    });
                                }}
                                onAddressObjectChange={(addresData) =>
                                    selectAddressData(addresData, setOwnerDetails, ownerDetails)
                                }
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Address" id="contact-address"></Label>
                            <Input
                                placeholder="Address"
                                id="contact-address-input"
                                value={ownerDetails.addressLine1}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        addressLine1: e.target.value
                                    });
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Town/City" id="city"></Label>
                            <Input
                                placeholder="City"
                                id="city-input"
                                value={ownerDetails.city}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        city: e.target.value
                                    });
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Phone Number" id="contact-phone-number"></Label>
                            <PhoneInput
                                country={'gb'}
                                id="contact-phone-number-input"
                                placeholder="Phone number"
                                value={checkEmptyValue(ownerDetails.telephoneNumber)}
                                alwaysDefaultMask={true}
                                countryCodeEditable={false}
                                onChange={(phone) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        telephoneNumber: phoneNumberValidator(phone)
                                    });
                                }}
                                inputClass={styles.phoneInput}
                                name="phone"
                            />
                        </FormItem>
                        <FormItem>
                            <ValidEmailInput
                                labelText="Email Address"
                                labelId="owner-email"
                                placeholder={FormatMessage({ id: 'owner-email', text: 'Email' })}
                                transalterEnabled="true"
                                id="owner-email-input"
                                value={ownerDetails.email}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        email: e.target.value.toLowerCase()
                                    });
                                }}
                            />
                        </FormItem>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={styles.formContainer}>
                        <FormItem>
                            <Label text="Business Name" id="business-name"></Label>
                            <Input
                                placeholder="Business name"
                                id="business-name-input"
                                value={businessDetails.legalName}
                                onChange={(e) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        legalName: e.target.value
                                    });
                                }}
                                disabled
                                classes={{ disabled: classes.disabledInput }}
                            />
                        </FormItem>
                        <FormItem>
                            <ValidURLInput
                                labelText="Business URL"
                                labelId="business-label"
                                placeholder="Business"
                                id="business"
                                value={checkEmptyValue(businessDetails.websiteUrl)}
                                onChange={(value) =>
                                    setBusinessDetails({
                                        ...businessDetails,
                                        websiteUrl: value
                                    })
                                }
                            />
                        </FormItem>
                    </div>
                </Grid>
            </Grid>
            <div className={styles.btnContainer}>
                <Button
                    disabled={disabled}
                    id="update-btn"
                    color="primary"
                    variant="contained"
                    className={styles.updateBtn}
                    onClick={onSubmit}
                >
                    Update
                </Button>
                <Dialog open={showModal}>
                    <DialogContent>{modalMessage}</DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowModal(false)}
                            id="cancelButtonCreateMerchant"
                            autoFocus
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <LoadingScreen open={isLoading}></LoadingScreen>
            </div>
        </Paper>
    );
}
UpdateAccountMypayComponent.propTypes = {
    phoneNumberValidator: PropTypes.func
};

export default UpdateAccountMypayComponent;
