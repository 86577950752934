import React, { useState, useEffect } from 'react';
import MerchantSettingsComponent from './merchant-settings.component';
import { useGlobalStateContext } from 'contexts';
import { Roles } from '../../utils/enums/Roles';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { UserType } from 'utils/enums/UserType';

function MerchantSettings(props) {
    const { globalState } = useGlobalStateContext();
    const [isOwner, setIsOwner] = useState(false);
    const [isMerchant, setIsMerchant] = useState(false);
    const [canViewAndEditTeam, setCanViewAndEditTeam] = useState(false);
    const [isNotSubmitted, setIsNotSubmitted] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isWatchList, setIsWatchList] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const redirected = props.location.state?.redirected;
    useEffect(() => {
        // eslint-disable-next-line no-prototype-builtins
        if (globalState.merchant?.hasOwnProperty('userRole')) {
            setCanViewAndEditTeam(globalState.merchant?.userRole !== Roles.User);
            setIsOwner(globalState.merchant?.userRole === Roles.Owner);
            setIsNotSubmitted(globalState.merchant?.status === MerchantStatus.NOT_SUBMITTED);
            setIsActive(globalState.merchant?.status === MerchantStatus.ACTIVE);
            setIsWatchList(globalState.merchant?.status === MerchantStatus.WATCHLIST);
            setIsAdmin(
                globalState.user?.UserType?.name === UserType.Admin ||
                    globalState.user?.UserType?.name === UserType.SuperAdmin
            );
            setIsMerchant(globalState.user.UserType.name === UserType.Merchant);
        }
    }, [globalState.merchant, globalState.user]);

    return (
        <MerchantSettingsComponent
            isOwner={isOwner}
            isMerchant={isMerchant}
            canViewAndEditTeam={canViewAndEditTeam}
            isNotSubmitted={isNotSubmitted}
            isActive={isActive}
            isAdmin={isAdmin}
            redirected={redirected}
            isWatchList={isWatchList}
        />
    );
}

MerchantSettings.propTypes = {};

export default MerchantSettings;
