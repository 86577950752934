import React from 'react';
import styles from './password-input.module.scss';
import { OutlinedInput } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {},

    withoutLabel: {
        marginTop: theme.spacing(3)
    },
    textField: {
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%'
        }
    }
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
    root: {
        '& $notchedOutline': {
            borderColor: '#D2DDEC',
            borderRadius: '0.375',
            borderWidth: '1.5px'
        },
        '&:hover $notchedOutline': {
            borderColor: '#D2DDEC'
        },
        '&$focused $notchedOutline': {
            borderColor: '#2C7BE5',
            borderWidth: '1.5px'
        },
        '& input::placeholder': {
            fontSize: '14px',
            color: 'gray'
        },
        '& input': {
            fontSize: '15px',
            fontWeight: '400',
            fontFamily: 'Source Sans Pro'
        }
    },
    focused: {},
    notchedOutline: {}
}));

function PasswordInputComponent(props) {
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();

    const {
        showPassword,
        setShowPassword,
        password,
        onChange,
        placeholder,
        title,
        minLength,
        pattern,
        required,
        autoFocus
    } = props;

    return (
        <div>
            <FormControl className={classes.textField}>
                <OutlinedInput
                    required={required}
                    inputProps={{
                        style: {
                            padding: 12
                        }
                    }}
                    classes={outlinedInputClasses}
                    className={styles.icon}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => onChange(e.target.value?.replace(/ /g, '')?.trim())}
                    placeholder={placeholder}
                    title={title}
                    minLength={minLength}
                    pattern={pattern}
                    endAdornment={
                        <InputAdornment position="end" className={styles.InputAdornment}>
                            {showPassword ? (
                                <Tooltip title="Hide Password" arrow>
                                    <VisibilityOff onClick={() => setShowPassword(false)} />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Show Password" arrow>
                                    <Visibility onClick={() => setShowPassword(true)} />
                                </Tooltip>
                            )}
                        </InputAdornment>
                    }
                    autoFocus={autoFocus}
                />
            </FormControl>
        </div>
    );
}

export default PasswordInputComponent;
