export const ErrorCodes = {
    CSRF_VALIDATION_FAILURE: {
        errorCode: 1400,
        message: 'Form session expired. Please reload page and try again'
    },
    WITHDRAWAL_ERROR: {
        message: 'An internal error occurred, please press Cancel to check your updated balance'
    },
    INTERNAL_SERVER_ERROR: {
        message: 'An error occurred. Please contact support or try again.'
    },
    REFUND_ERROR: {
        message: 'Your refund request has failed.'
    },
    DOCUMENT_UPLOAD_ERROR: {
        message: 'Uploading failed.'
    },
    INVALID_VERIFICATION_CODE: {
        message: 'Invalid verification code provided, please try again.'
    },
    INCOMPLETE_SIGNUP_PROCESS: {
        message: 'Unable to complete sign-up process.'
    },
    INCOMPLETE_VERIFICATION_PROCESS: {
        message: 'Unable to complete verification process.'
    },
    OTP_MESSAGE: {
        message: 'Unable to send OTP'
    },
    OTP_MESSAGE_LIMIT_EXCEEDED: {
        message: 'Maximum limit exceeded, please try after 30 min.'
    }
};
