import React from 'react';
import style from './list-refund.module.scss';
import PropTypes from 'prop-types';
import { getComparator, stableSort } from '../../utils/helper';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Select,
    MenuItem,
    Button,
    Grid,
    Paper,
    Container,
    TextField,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    Typography,
    Tooltip,
    FormControl,
    InputLabel
} from '@material-ui/core';
import { SnackBarAndAlert, ModalFormConfirmation } from 'components';
import { RefundStatus } from '../../utils/enums/RefundStatus';
import TableHeader from '../payments/transaction-tables/table-header/table-header.container';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ListRefundComponent = (props) => {
    const {
        rows,
        loading,
        error,
        onRefundProcess,
        refundStatusFilter,
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
        message,
        refundStatus,
        setRefundStatus,
        setRefSearchTerm,

        setSearchMerchant,
        headCells,
        refSearchTerm,
        searchMerchant,
        refundProcessConfirmation,
        setRefundProcessConfirmation,
        setRefundPaymentId,
        refundPaymentId,
        onCombinedSearch,
        setRefundStatusFilter,
        onCancelRefund,
        count
    } = props;
    return (
        <Container className={style.mainContainer} maxWidth={false}>
            <Grid
                xs={12}
                sm={12}
                md={12}
                className={style.searchArea}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
            >
                <Grid item xs={12} sm={6} md={4} className={style.searchMonth} z-index={0}>
                    <TextField
                        className={style.searchMerchants}
                        id="pending-merchants-search"
                        size="small"
                        label="Search by payment ref"
                        type="search"
                        variant="outlined"
                        autoComplete="off"
                        autoFocus
                        value={refSearchTerm}
                        onChange={(e) => setRefSearchTerm(e.target.value)}
                    />
                    <Button
                        id="pending-merchants-search-button"
                        className={style.searchButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => onCombinedSearch()}
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={style.searchDate}>
                    <TextField
                        className={style.searchMerchants}
                        id="pending-merchants-search"
                        size="small"
                        label="Search by name"
                        type="search"
                        variant="outlined"
                        autoComplete="off"
                        autoFocus
                        value={searchMerchant}
                        onChange={(e) => setSearchMerchant(e.target.value)}
                    />

                    <Button
                        id="pending-merchants-search-button"
                        className={style.searchButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => onCombinedSearch()}
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={style.searchFilter} z-index={0}>
                    <FormControl variant="outlined">
                        <InputLabel shrink className={style.label} id="query-input">
                            Filter by status
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Filter by status"
                            value={refundStatusFilter}
                            onChange={(e) => {
                                setRefundStatusFilter(e.target.value);
                            }}
                            className={style.selectInput}
                        >
                            {Object.keys(RefundStatus).map((status) => (
                                <MenuItem value={RefundStatus[status]}>{status}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Paper className={style.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={style.refundsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        <TableContainer className={style.TableContainer}>
                            <Table
                                aria-label="simple table"
                                aria-labelledby="tableTitle"
                                size="small"
                                stickyHeader
                                component={Paper}
                                sx={{ minWidth: 650 }}
                            >
                                <TableHeader headCells={headCells} />
                                {!rows || rows.length === 0 ? (
                                    <TableRow>
                                        <TableCell className={style.refundsNone} colSpan={8}>
                                            <div>No refunds</div>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <React.Fragment>
                                        <TableBody>
                                            {stableSort(rows, getComparator()).map((row, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.business_name}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.cross_reference}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.customer_firstname}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.customer_lastname}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.address}
                                                        </TableCell>
                                                        <TableCell className={style.tableRefundCell}>
                                                            {row.refund_reason ? (
                                                                <Tooltip
                                                                    title={row.refund_reason}
                                                                    placement="bottom-start"
                                                                >
                                                                    <span>{row.refund_reason}</span>
                                                                </Tooltip>
                                                            ) : (
                                                                <span>&mdash;</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {moment.utc(row.time).format('YYYY-MM-DD HH:mm')}
                                                        </TableCell>
                                                        <TableCell className={style.tableDataCell}>
                                                            {row.refund_processed_status === 'NOT-PROCESSED' ? (
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setRefundProcessConfirmation(true);
                                                                        setRefundPaymentId({
                                                                            paymentId: row.payment_id,
                                                                            refundStatus: 'REFUND-PROCESSED'
                                                                        });
                                                                    }}
                                                                >
                                                                    <span className={style.processRefundButton}>
                                                                        Refund processed?
                                                                    </span>
                                                                </Button>
                                                            ) : (
                                                                <Typography className={style.processRefundText}>
                                                                    Refund processed
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Link
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    window.open(
                                                                        row.payment_provider_mms_link,
                                                                        '_blank'
                                                                    );
                                                                }}
                                                            >
                                                                View
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </React.Fragment>
                                )}
                                <TableFooter>
                                    <TableRow>
                                        {count > 20 && (
                                            <TablePagination
                                                rowsPerPageOptions={[20, 50, 100]}
                                                count={count}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                    native: true
                                                }}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        )}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        {refundProcessConfirmation && (
                            <ModalFormConfirmation
                                isModalOpen={refundProcessConfirmation}
                                setIsModalOpen={setRefundProcessConfirmation}
                                onCancelRefund={onCancelRefund}
                                modalText="Are you sure this refund has been processed?"
                                buttonText={'Continue'}
                                confirmationAction={() =>
                                    onRefundProcess(refundPaymentId.paymentId, refundPaymentId.refundStatus)
                                }
                            />
                        )}
                        {message && (
                            <SnackBarAndAlert
                                open={refundStatus === 'success'}
                                onClose={() => setRefundStatus('')}
                                type="success"
                            >
                                {message}
                            </SnackBarAndAlert>
                        )}
                        {message && (
                            <SnackBarAndAlert
                                open={refundStatus === 'error'}
                                onClose={() => setRefundStatus('')}
                                type="error"
                            >
                                {message}
                            </SnackBarAndAlert>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </Container>
    );
};
export default ListRefundComponent;

ListRefundComponent.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array,
    error: PropTypes.bool,
    onRefundProcess: PropTypes.func,
    refundStatusFilter: PropTypes.string,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    refundFilterUpdate: PropTypes.func,
    setSearchMerchant: PropTypes.func,
    setRefundStatus: PropTypes.func,
    setRefundPaymentId: PropTypes.func
};
