import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import TableFooterComponent from '../table-footer/table-footer.component';
import TableHeader from '../table-header/table-header.container';
import FilterTabs from '../filter-tabs/filter-tabs.container';
import { PdqFilterTabs } from 'utils/enums/PdqFilterTabs';
import { formatCurrency, formatDateTimeByFormatString, getComparator, stableSort } from '../../../../utils/helper';
import styles from './pdq-list-table.module.scss';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import TransactionStatusLabel from '../transaction-status-label/transaction-status-label.container';
import { PdqStatus } from 'utils/enums/pdqTransactionStatus';

const useStyles = makeStyles((theme) => ({
    root: {
        'border-top': '1px solid #ddd',
        width: '100%'
    },
    omnipay: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    representAction: {
        'font-size': '8px',
        'margin-right': '5px',
        color: 'cornflowerblue',
        textTransform: 'none'
    },
    acceptAction: {
        'font-size': '8px',
        color: 'green',
        textTransform: 'none'
    }
}));

export default function PdqListTableComponent(props) {
    const classes = useStyles();

    const {
        rows,
        statusFilter,
        setStatusFilter,
        order,
        orderBy,
        loading,
        error,
        page,
        setPage,
        refundValue,
        total,
        isOmnipay,
        globalState,
        statusToFilter
    } = props;

    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className={isOmnipay ? classes.omnipay : classes.root}>
            {isOmnipay && (
                <FilterTabs tabFilterValue={statusFilter} setTabFilterValue={setStatusFilter} tabList={PdqFilterTabs} />
            )}
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={styles.paymentsNone}>There aren't any results for that query.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader
                                            classes={classes}
                                            numSelected={0}
                                            rowCount={rows.length}
                                            hideSelectAll={true}
                                            {...props}
                                        />
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator(order, orderBy)
                                            ).map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <React.Fragment key={index + '-fragment'}>
                                                        <TableRow
                                                            classes={{ selected: classes.selected }}
                                                            className={classes.tableRow + ' ' + styles.tableRow}
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.id}
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                            >
                                                                {row.refund_status === PdqFilterTabs.Refunded &&
                                                                isOmnipay
                                                                    ? '-'
                                                                    : null}
                                                                {formatCurrency(
                                                                    row.total,
                                                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDateTimeByFormatString(
                                                                    row.date_time,
                                                                    'DD MMM HH:mm'
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{row.auth_code}</TableCell>
                                                            {isOmnipay && statusFilter === PdqFilterTabs.All && (
                                                                <TableCell>
                                                                    {row.refund_status === PdqFilterTabs.Refunded &&
                                                                    row.transaction_status_id ===
                                                                        statusToFilter.Success ? (
                                                                        <TransactionStatusLabel status={'Refunded'} />
                                                                    ) : (
                                                                        Object.keys(PdqStatus).includes(
                                                                            row.transaction_status_id.toString()
                                                                        ) && (
                                                                            <TransactionStatusLabel
                                                                                status={
                                                                                    PdqStatus[row.transaction_status_id]
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {rows.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                                        colSpan={6}
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <TableFooterComponent
                                    path="pdq"
                                    filter={statusFilter}
                                    total={total}
                                    refund={refundValue}
                                    resultsCount={rows.length}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </div>
    );
}

PdqListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    statusFilter: PropTypes.string.isRequired,
    setStatusFilter: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    total: PropTypes.number,
    refundValue: PropTypes.number,
    page: PropTypes.number,
    setPage: PropTypes.func
};
