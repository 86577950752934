import React, { useState, useEffect } from 'react';
import UpdateAccountComponent from './update-account.component';
import { BusinessDetailsService, AuthService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { InputValidators } from 'validators';
import { BankService } from 'services';
import { UserType } from 'utils/enums/UserType';
import { formTypes } from 'utils/enums/FormTypes';
import { ErrorCodes } from 'utils/enums/ErrorCode';

function OnboardingNameAdress() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [businessDetails, setBusinessDetails] = useState({});
    const [ownerDetails, setOwnerDetails] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [BusinessName, setBusinessName] = useState(false);
    const [BusinessAddress, setBusinessAddress] = useState(false);
    const [OwnerName, setOwnerName] = useState(false);
    const [OwnerAddress, setOwnerAddress] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [BankStatement, setBankStatement] = useState('');
    const [BusinessUtility, setBusinessUtility] = useState('');
    const [Identity, setIdentity] = useState('');
    const [successfullUpdate, setSuccessfullUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accountNumber, setAccountNumber] = useState('');
    const [sortCode, setSortCode] = useState('');
    const asterisks = '*********';
    const [errorMessage, setErrorMessage] = useState('');
    const [oldBusinessDetails, setOldBusinessDetails] = useState('');
    const [oldOwnerDetails, setOldOwnerDetails] = useState('');

    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    useEffect(() => {
        const allFieldsCompleted =
            businessDetails.legalName &&
            businessDetails.postCode &&
            businessDetails.addressLine1 &&
            businessDetails.city &&
            businessDetails.telephoneNumber &&
            ownerDetails.fullName &&
            ownerDetails.postCode &&
            ownerDetails.addressLine1 &&
            ownerDetails.city &&
            ownerDetails.telephoneNumber &&
            InputValidators.isEmailValid(ownerDetails.email);

        const updatedAccount =
            businessDetails.legalName !== oldBusinessDetails.legalName ||
            ownerDetails.fullName !== oldOwnerDetails.fullName ||
            businessDetails.postCode !== oldBusinessDetails.postCode ||
            businessDetails.addressLine1 !== oldBusinessDetails.addressLine1 ||
            businessDetails.addressLine2 !== oldBusinessDetails.addressLine2 ||
            ownerDetails.addressLine1 !== oldOwnerDetails.addressLine1 ||
            ownerDetails.addressLine2 !== oldOwnerDetails.addressLine2 ||
            businessDetails.city !== oldBusinessDetails.city ||
            ownerDetails.city !== oldOwnerDetails.city ||
            businessDetails.telephoneNumber !== oldBusinessDetails.telephoneNumber ||
            ownerDetails.telephoneNumber !== oldOwnerDetails.telephoneNumber ||
            ownerDetails.postCode !== oldOwnerDetails.postCode ||
            ownerDetails.email !== oldOwnerDetails.email;
        if (allFieldsCompleted && updatedAccount) setDisabled(false);
        else setDisabled(true);
    }, [businessDetails, ownerDetails, oldOwnerDetails, oldBusinessDetails]);

    const [csrfToken, setCSRFToken] = useState('');

    useEffect(() => {
        const generateCSRFToken = async () => {
            setIsLoading(true);
            const response = await AuthService.generateCSRFToken(
                formTypes.UPDATE_ACCOUNT_INFO,
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setCSRFToken(response.data.csrfToken);
            }
            setIsLoading(false);
        };

        generateCSRFToken();
        // eslint-disable-next-line
    }, [globalState.merchant.id]);

    useEffect(() => {
        const getMerchantBusinessDetails = async () => {
            setIsLoading(true);
            const result = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);

            if (result.isSuccesfully) {
                setBusinessDetails(result.data.businessDetails);
                setOwnerDetails(result.data.ownerDetails);
                setOldBusinessDetails(result.data.businessDetails);
                setOldOwnerDetails(result.data.ownerDetails);
            }

            setIsLoading(false);
        };

        getMerchantBusinessDetails();
    }, [globalState.merchant]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await BankService.getMaskedBankDetails(globalState.merchant?.id);
            if (response.isSuccesfully) {
                setSortCode(response.data.sortCode);
                setAccountNumber(response.data.accountNumber);
            } else {
                setSortCode(asterisks);
                setAccountNumber(asterisks);
            }
        };
        fetchData();
    }, [globalState.merchant]);

    const selectAddressData = (addressData, setObject, object) => {
        if (addressData) {
            setObject({
                ...object,
                addressLine1: addressData.line_1,
                addressLine2: addressData.line_2,
                city: addressData.town_or_city,
                country: addressData.country
            });
        }
    };

    const onSubmit = async () => {
        setIsLoading(true);
        const validate = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
            globalState.merchant?.id,
            {}
        );
        if ((!validate.data.isBankAccountVerified && !validate.data.isAccountVerified) || isAdmin) {
            if (BusinessName || BusinessAddress || OwnerName || OwnerAddress) {
                setIsLoading(true);

                setIsLoading(false);
                const response = await BusinessDetailsService.updateBusinessDetails(
                    globalState.merchant?.id,
                    {
                        businessDetails,
                        ownerDetails,
                        resellerId: globalState.reseller.id,
                        formType: formTypes.UPDATE_ACCOUNT_INFO
                    },
                    csrfToken
                );
                setShowModal(true);
                if (response.isSuccesfully) {
                    setModalMessage('In order to finalize your updates we require the following information');
                    setBankStatement('Bank statement');
                    setBusinessUtility('Business utility bills');
                    setIdentity('Passport/Driving license');
                    setSuccessfullUpdate(true);
                } else {
                    const errorMessage =
                        response.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode
                            ? ErrorCodes.CSRF_VALIDATION_FAILURE.message
                            : ErrorCodes.INTERNAL_SERVER_ERROR.message;
                    setModalMessage(errorMessage);
                    setSuccessfullUpdate(false);
                }
            } else {
                await BusinessDetailsService.updateBusinessDetails(
                    globalState.merchant?.id,
                    {
                        businessDetails,
                        ownerDetails,
                        resellerId: globalState.reseller.id,
                        formType: formTypes.UPDATE_ACCOUNT_INFO
                    },
                    csrfToken
                );

                setRoute('/settings');
            }
        } else {
            setIsLoading(false);
            setShowMessage(true);
            setSuccessfullUpdate(false);
            setErrorMessage('For updating account details, please contact support!');
        }
    };

    const onModalClick = async () => {
        if (successfullUpdate) {
            setRoute('/upload-documents');
        }
        setShowModal(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setShowMessage(false);
        }
    };
    return (
        <UpdateAccountComponent
            disabled={disabled}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            ownerDetails={ownerDetails}
            setOwnerDetails={setOwnerDetails}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            showModal={showModal}
            modalMessage={modalMessage}
            BankStatement={BankStatement}
            Identity={Identity}
            BusinessUtility={BusinessUtility}
            onModalClick={onModalClick}
            isLoading={isLoading}
            BusinessName={BusinessName}
            setBusinessName={setBusinessName}
            BusinessAddress={BusinessAddress}
            setBusinessAddress={setBusinessAddress}
            OwnerName={OwnerName}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            setOwnerName={setOwnerName}
            OwnerAddress={OwnerAddress}
            setOwnerAddress={setOwnerAddress}
            sortCode={sortCode}
            accountNumber={accountNumber}
            handleClose={handleClose}
        />
    );
}

export default OnboardingNameAdress;
