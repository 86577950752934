import React from 'react';
import { useGlobalStateContext } from 'contexts';
import styles from './settings-terms-and-conditions.module.scss';
import {
    CircularProgress,
    Paper,
    Grid,
    Typography,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { formatDateTimeByFormatString } from 'utils/helper';
import { Roles } from 'utils/enums/Roles';
import { ResponsiveEmbed } from 'react-bootstrap';
function SettingTermsAndConditionsComponent(props) {
    const { globalState } = useGlobalStateContext();

    const {
        termsAndConditionsList,
        isLoading,
        submitHandler,
        isCanonicalResellerUser,
        isModal,
        onSelectTermsAndConditions,
        selectedTermsAndConditions,
        modalTermsAndConditions
    } = props;

    const isOwner = isCanonicalResellerUser
        ? globalState.canonicalReseller?.userRole === Roles.Owner
        : globalState.merchant?.userRole === Roles.Owner;

    return (
        <Paper className={isModal ? styles.termsAndConditionsInSideModal : styles.termsAndConditionContainer}>
            {isLoading && termsAndConditionsList.length === 0 ? (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            ) : (
                <Grid>
                    <Grid className={styles.pageTitle}>
                        <Typography className={isModal ? styles.centerAlignHeader : styles.termsAndConditionHeader}>
                            Terms and Conditions
                        </Typography>
                    </Grid>
                    {termsAndConditionsList.map((termsAndCondition) => {
                        //const checkedTermsAndConditions = selectedTermsAndConditions.includes(termsAndCondition.tcId);

                        return (
                            <React.Fragment>
                                <ResponsiveEmbed a16by9 key={`${termsAndCondition.TermsAndCondition.link}`}>
                                    <object
                                        data={`${termsAndCondition.TermsAndCondition.link}`}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                    >
                                        <iframe
                                            title="terms-and-condition"
                                            src={`${termsAndCondition.TermsAndCondition.link}`}
                                            width="100%"
                                            frameBorder="0"
                                            key={termsAndCondition.TermsAndCondition.link}
                                            className={styles.iframePdf}
                                        />
                                    </object>
                                </ResponsiveEmbed>
                                {termsAndCondition.activatedAt ? (
                                    <Grid item container direction="column" alignItems="center">
                                        <Typography className={styles.submitInput} color="primary">
                                            Document was signed on:{' '}
                                            {formatDateTimeByFormatString(
                                                termsAndCondition.activatedAt,
                                                'DD MMM YYYY HH:mm'
                                            )}
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid item container direction="column" alignItems="center">
                                        <Grid>
                                            <FormControl style={{ marginLeft: '20px' }}>
                                                <FormControlLabel
                                                    style={{ marginBottom: '0px' }}
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            checked={selectedTermsAndConditions.includes(
                                                                termsAndCondition.tcId
                                                            )}
                                                            onChange={(e) => {
                                                                onSelectTermsAndConditions(e);
                                                            }}
                                                            value={termsAndCondition.tcId}
                                                            disabled={!isOwner}
                                                        />
                                                    }
                                                    label="I agree with the terms and conditions above"
                                                    labelPlacement="end"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {modalTermsAndConditions === 'modalDisplay' && (
                        <Grid item container direction="column" alignItems="center">
                            <Grid>
                                <Button
                                    className={styles.submitInput}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => submitHandler(selectedTermsAndConditions)}
                                    disabled={!(isOwner && selectedTermsAndConditions.length !== 0)}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <LoadingScreen open={isLoading} />
                </Grid>
            )}
        </Paper>
    );
}

export default SettingTermsAndConditionsComponent;
