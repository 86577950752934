import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Link,
    Grid,
    TablePagination,
    Tooltip
} from '@material-ui/core';
import { DNAOnboardStatus } from '../../utils/enums/dnaOnboardStatus';

import styles from './modal-pdq-merchants.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { useGlobalStateContext } from 'contexts';
import Loader from 'components/loader/loader.container';
import DoneIcon from '@mui/icons-material/Done';

const ModalPdqMerchantsComponent = (props) => {
    const { setRoute } = useGlobalStateContext();

    const {
        modalLoading,
        open,
        setOpen,
        searchValue,
        merchants,
        setMerchants,
        count,
        setCount,
        page,
        rowsPerPage,
        setSearchValue,
        setModalLoading,
        setShowMessage,
        handleChangePage,
        handleChangeRowsPerPage,
        searchResellerMerchants
    } = props;

    return (
        <Dialog
            open={open}
            classes={{
                paper: styles.paper
            }}
            onClose={() => setShowMessage(true)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                <Grid container direction="row" alignItems="center" xs={12} sm={12} md={12}>
                    <Grid item xs={10} sm={10} md={10}>
                        All Merchants
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                        <CloseIcon
                            className={styles.closeIcon}
                            onClick={() => {
                                setOpen(false);
                                setModalLoading(false);
                                setMerchants([]);
                                setSearchValue('');
                                setCount(0);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent
                classes={{
                    root: styles.dialogContent
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    sm={12}
                    md={12}
                    className={styles.searchGrid}
                >
                    <Grid direction="row" spacing={0} item xs={12} sm={10} md={10}>
                        <div className={styles.displayDiv}>
                            <form onSubmit={searchResellerMerchants}>
                                <TextField
                                    className={styles.searchMerchants}
                                    id="pending-merchants-search"
                                    size="small"
                                    type="search"
                                    variant="outlined"
                                    placeholder="Search by name or MID"
                                    value={searchValue}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                    autoFocus
                                />
                                <Button
                                    className={styles.modalSearchButton}
                                    id="pending-merchants-search-button"
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    onClick={searchResellerMerchants}
                                >
                                    Search
                                </Button>
                            </form>
                        </div>
                    </Grid>
                </Grid>
                {modalLoading ? (
                    <div className={styles.loading}>
                        <Loader />
                    </div>
                ) : (
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        sm={12}
                        md={12}
                        className={styles.searchGrid}
                    >
                        <TableContainer className={styles.TableContainer}>
                            <Table
                                aria-label="simple table"
                                aria-labelledby="tableTitle"
                                size="small"
                                stickyHeader
                                component={Paper}
                                className={styles.rowData}
                            >
                                <TableHead className={styles.header}>
                                    <TableRow height={60}>
                                        <TableCell>Merchant Name</TableCell>
                                        <TableCell>Merchant ID</TableCell>
                                        <TableCell>Onboarded</TableCell>
                                    </TableRow>
                                </TableHead>
                                {merchants.length > 0 ? (
                                    <TableBody>
                                        {merchants.map((merchant) => {
                                            return (
                                                <TableRow hover tabIndex={-1}>
                                                    <TableCell size="medium" className={styles.nameColumn}>
                                                        <Tooltip
                                                            title={merchant.name}
                                                            className={styles.merchantName}
                                                            placement="bottom-start"
                                                        >
                                                            <Link
                                                                target="_blank"
                                                                rel="noopener"
                                                                className={styles.textInput}
                                                                onClick={() => {
                                                                    setRoute(`/pdq-order-merchant/${merchant.id}`);
                                                                    if (
                                                                        merchant.onboardingStatus !==
                                                                            DNAOnboardStatus.CANCEL_DNA_ONBOARD &&
                                                                        merchant.onboardingStatus
                                                                    ) {
                                                                        localStorage.setItem(
                                                                            'onBoardedMerchant',
                                                                            merchant.id
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {merchant.name}
                                                            </Link>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="left" size="medium" className={styles.nameColumn}>
                                                        {merchant.id}
                                                    </TableCell>
                                                    <TableCell align="left" size="medium" className={styles.nameColumn}>
                                                        {merchant.onboardingStatus ===
                                                            DNAOnboardStatus.CANCEL_DNA_ONBOARD ||
                                                        !merchant.onboardingStatus ? null : (
                                                            <DoneIcon />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow className={styles.rowData}>
                                            <TableCell colSpan={8} align="center">
                                                Make a search to show records
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}

                                <TableRow>
                                    {count > 10 && (
                                        <TablePagination
                                            rowsPerPageOptions={[10, 30, 50]}
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    )}
                                </TableRow>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ModalPdqMerchantsComponent;
