import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepButton, StepContent } from '@material-ui/core';
import styles from './onboarding-steps.module.scss';
import { translateText } from 'utils/languageTranslater';
import { OnboardingStepsMapping } from 'utils/enums/OnboardingStepsMapping';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { makeStyles } from '@material-ui/core/styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { getOnboardingStepName } from 'utils/onboarding-helper';

const useStyles = makeStyles({
    complete: {
        '& circle': {
            color: '#caf5c9'
        },
        '& text': {
            fill: '#297A5D'
        }
    },
    notCompete: {
        '& circle': {
            color: '#ffdc9d'
        },
        '& text': {
            fill: '#e67e00'
        }
    }
});
function OnboardingStepsComponent(props) {
    const classes = useStyles();
    const { onboardingForm } = useOnboardingFormContext();
    const { globalState } = useGlobalStateContext();
    const { steps, currentStep, setStep, getComponent, mediaLessThan960px, checkingSummaryCompletion } = props;
    const completedSteps = JSON.parse(JSON.stringify(onboardingForm.completedSteps));
    const merchantCountry = globalState.merchant?.country;
    const isT2SMerchant = globalState.isT2SMerchant;

    // The value of the 'summary' key in the 'completedSteps' object is determined by the completion status of other keys.
    // If every other step is marked as completed, the 'summary' will also be considered completed.
    checkingSummaryCompletion(completedSteps, isT2SMerchant);
    return (
        <>
            <Stepper nonLinear activeStep={currentStep - 1} orientation="vertical" className={styles.stepper}>
                {steps.map((label, index) => {
                    return (
                        <Step completed={false} key={label}>
                            <StepButton
                                onClick={() => setStep(index + 1)}
                                className={
                                    completedSteps[OnboardingStepsMapping[label]]
                                        ? classes.complete
                                        : classes.notCompete
                                }
                            >
                                {translateText({
                                    id: getOnboardingStepName(index, label, merchantCountry),
                                    text: getOnboardingStepName(index, label, merchantCountry)
                                })}
                                {!completedSteps[OnboardingStepsMapping[label]] && (
                                    <WarningRoundedIcon style={{ fill: '#ffdc9d', marginLeft: '10px' }} />
                                )}
                            </StepButton>
                            <StepContent>{mediaLessThan960px ? getComponent(index + 1) : ''}</StepContent>
                        </Step>
                    );
                })}
            </Stepper>
        </>
    );
}

OnboardingStepsComponent.propTypes = {
    steps: PropTypes.array,
    currentStep: PropTypes.number,
    setStep: PropTypes.func,
    getComponent: PropTypes.func,
    mediaLessThan960px: PropTypes.bool
};

export default OnboardingStepsComponent;
