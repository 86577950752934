import React, { useEffect, useState } from 'react';
import MerchantStripeIdComponent from './merchant-stripe-id.component';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import { AdminService } from 'services';

export default function MerchantStripeId(props) {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [merchantStripeId, setMerchantStripeId] = useState('');
    const [merchantIdExist, setMerchantIdExist] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showMessage, setShowMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [existingStripeId, setExistingStripeId] = useState('');
    useEffect(() => {
        const getMerchantStoreId = () => {
            setMerchantStripeId(adminFunctions.stripeId);
            setExistingStripeId(adminFunctions.stripeId);
            if (adminFunctions.stripeId) {
                setMerchantIdExist(true);
            } else {
                setMerchantIdExist(false);
            }
        };

        getMerchantStoreId();
        // eslint-disable-next-line
    }, [adminFunctions]);

    const handleCopyMerchantStripeId = () => {
        navigator.clipboard.writeText(merchantStripeId);
        setIsCopyAlertOpen(true);
    };

    const handleCloseMessage = () => {
        setShowMessage(false);
        setErrorMessage('');
        setSuccessMessage('');
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    const createStripeId = async () => {
        setShowMessage(false);
        setErrorMessage('');
        setSuccessMessage('');
        setMerchantStripeId('processing...');
        setLoading(true);
        const response = await AdminService.CreateMerchantStripeId(globalState.merchant?.id);
        setLoading(false);
        setShowMessage(true);
        if (response.isSuccesfully) {
            setMerchantStripeId(response.data.stripeId);
            setAdminFunctions({
                ...adminFunctions,
                stripeId: response.data.stripeId,
                autoWithdraw: false
            });
            setSuccessMessage('Stripe Id created successfully');
        } else {
            if (response.data.missing) {
                const missingFields = response.data.missing;
                let errorMessage = `Stripe id was not created. Missing ${missingFields[0]}`;
                if (missingFields.length === 2) {
                    errorMessage = `${errorMessage} and ${missingFields[1]}`;
                }
                setErrorMessage(errorMessage);
            } else {
                setErrorMessage('An error occurred. Try again !');
            }
        }
    };

    const updateStripeId = async () => {
        setShowMessage(false);
        setErrorMessage('');
        setSuccessMessage('');
        setLoading(true);
        const response = await AdminService.UpdateMerchantStripeId(globalState.merchant?.id, {
            updatedStripeId: merchantStripeId ? merchantStripeId : 'RESET'
        });
        setLoading(false);
        setShowMessage(true);

        if (response.isSuccesfully) {
            if (!merchantStripeId) {
                setSuccessMessage('Stripe Id removed successfully');
            } else {
                setSuccessMessage('Stripe Id updated successfully');
            }
            setAdminFunctions({
                ...adminFunctions,
                stripeId: merchantStripeId
            });
            setGlobalState((globalState) => ({
                ...globalState,
                merchant: {
                    ...globalState.merchant,
                    isStripe: merchantStripeId ? true : false
                }
            }));
        } else {
            if (response.error) {
                setErrorMessage(response?.error);
            } else {
                setErrorMessage('Stripe id was not updated');
            }
        }
    };

    return (
        <MerchantStripeIdComponent
            merchantStripeId={merchantStripeId}
            isCopyAlertOpen={isCopyAlertOpen}
            handleCopyMerchantStripeId={handleCopyMerchantStripeId}
            closeCopyAlert={closeCopyAlert}
            createStripeId={createStripeId}
            updateStripeId={updateStripeId}
            setMerchantStripeId={setMerchantStripeId}
            merchantIdExist={merchantIdExist}
            loading={loading}
            successMessage={successMessage}
            errorMessage={errorMessage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
            existingStripeId={existingStripeId}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            handleCloseMessage={handleCloseMessage}
        />
    );
}
