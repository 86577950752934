import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useGlobalStateContext } from 'contexts';
import FormForgotPassword from './forgot-password.component';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { AuthService } from 'services';
import { InputValidators } from 'validators';

//import { AuthService } from 'services';

function ForgotPassword() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [isVerificationCodeError, setIsVerificationCodeError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errorCodeMsg, setErrorCodeMsg] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [phone, setPhone] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [submitView, setSubmitView] = useState('SEND-OTP');
    const [showPopup, setShowPopup] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [redoPasswordValidation, setRedoPasswordValidation] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [verificationEmailMessage, setVerificationEmailMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [errorExceptionType, setErrorExceptionType] = useState('');
    const [disableButton, setDisableButton] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const location = useLocation();

    useEffect(() => {
        const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (queryString.email && queryString.code && queryString.firstName && queryString.lastName) {
            setSubmitView('SUBMIT-OTP');
            setEmail(queryString.email);
            setLastName(queryString.lastName);
            setFirstName(queryString.firstName);
            setVerificationCode(queryString.code);
        }
    }, [location]);

    useEffect(() => {
        if (InputValidators.isEmailValid(email)) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [email]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setIsSuccess(false);
        setMessage('');
        setIsVerificationCodeError(false);
        setErrorCodeMsg(false);

        let confirmationCode = event.target.confirmationCode?.value;

        if (submitView === 'SUBMIT-OTP' && newPassword !== confirmPassword) {
            setIsSuccess(false);
            setMessage('Passwords do not match.');
            event.preventDefault();
            event.stopPropagation();
            setLoading(false);
            return;
        }

        let via = email ? 'email' : 'phone';
        let params = {};

        if (email) {
            params = { email };
        } else if (phone) {
            params = { phone };
        }

        if (submitView === 'SEND-OTP') {
            try {
                await Auth.forgotPassword(params[via], { portalURL: globalState.reseller?.portalURL });
                setIsSuccess(true);
                setShowPopup(true);
                setMessage(
                    `If your account exist with us, you'll receive a one-time-password on your phone or email for verification; use the verification link received if your account hasn't been verified yet.`
                );
                setSubmitView('SUBMIT-OTP');
            } catch (err) {
                setErrorExceptionType(err.code);
                setIsSuccess(true);
                setShowPopup(true);
                setMessage(
                    `If your account exist with us, you'll receive a one-time-password on your phone or email for verification; use the verification link received if your account hasn't been verified yet.`
                );
                setSubmitView('SUBMIT-OTP');
            }
        } else {
            try {
                await Auth.forgotPasswordSubmit(params[via], confirmationCode, newPassword);
                await AuthService.updateChangePasswordTime({ email });
                /*try {
                    await AuthService.resetDatmanPassword(via, params[via], newPassword);
                } catch (error) {
                    console.error('Datman password cannot be updated, ignore and continue.', error);
                }*/

                setIsSuccess(true);
                setShowPopup(true);
                setMessage('Successfully changed the password. Redirecting to login page...');
                setTimeout(() => {
                    setRoute('/login');
                }, 3000);
            } catch (err) {
                setIsSuccess(false);
                let periodIfNeeded = err.message && err.message.endsWith('.') ? '' : '.';
                setMessage(`Failed to change the password. ${periodIfNeeded}`);
                setIsVerificationCodeError(true);
                setErrorCodeMsg(true);
                setLoading(false);
            }
        }

        setLoading(false);
    };
    const onNewRequest = () => {
        setSubmitView('SEND-OTP');
        setEmail('');
        setRoute('/forgot-password');
        window.location.reload();
    };

    const sendVerificationEmail = async () => {
        setShowPopup(false);
        setLoading(true);
        const response = await AuthService.sendVerificationEmail({
            email: email,
            resellerUrl: globalState.reseller.portalURL
        });
        setLoading(false);
        setShowMessage(true);
        if (response.isSuccesfully) {
            setMessageType('success');
            setVerificationEmailMessage('Verification email has been sent successfully. Please check inbox.');
        } else {
            setMessageType('error');
            setVerificationEmailMessage('An error occurred. Try again !');
        }
    };

    return (
        <FormForgotPassword
            handleSubmit={handleSubmit}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            message={message}
            setMessage={setMessage}
            submitView={submitView}
            loading={loading}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            errorCodeMsg={errorCodeMsg}
            setErrorCodeMsg={setErrorCodeMsg}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            isVerificationCodeError={isVerificationCodeError}
            setIsVerificationCodeError={setIsVerificationCodeError}
            onNewRequest={onNewRequest}
            isPasswordValid={isPasswordValid}
            setIsPasswordValid={setIsPasswordValid}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            redoPasswordValidation={redoPasswordValidation}
            setRedoPasswordValidation={setRedoPasswordValidation}
            sendVerificationEmail={sendVerificationEmail}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            verificationEmailMessage={verificationEmailMessage}
            messageType={messageType}
            errorExceptionType={errorExceptionType}
            disableButton={disableButton}
            firstName={firstName}
            lastName={lastName}
        />
    );
}

export default ForgotPassword;
