import React, { useState } from 'react';
import ModalCancelInternalTransferComponent from './modal-cancel-internal-transfer.component.jsx';
import { InternalTransferService } from 'services';

const ModalCancelInternalTransfer = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [success, setSuccess] = useState(false);

    const onSubmitCancel = async () => {
        setButtonDisabled(true);
        setLoading(true);
        const result = await InternalTransferService.cancelInternalTransfer({
            transaction_id: props.transactionId,
            action: 'cancel'
        });
        if (result.isSuccesfully) {
            props.setRefresh(true);
            setError(false);
            setSuccess(true);
            setErrorMessage('');
        } else {
            setSuccess(false);
            setError(true);
            setErrorMessage(result.data.error);
        }
        setDisableSubmit(true);
        setButtonDisabled(false);
        setLoading(false);
    };

    return (
        <ModalCancelInternalTransferComponent
            {...props}
            error={error}
            loading={loading}
            buttonDisabled={buttonDisabled}
            onSubmitCancel={onSubmitCancel}
            disableSubmit={disableSubmit}
            errorMessage={errorMessage}
            success={success}
        />
    );
};

export default ModalCancelInternalTransfer;
