import React from 'react';
import style from './merchant-dashboard-preview.module.scss';
import './stepper.scss';
import PropTypes from 'prop-types';
import { Paper, Grid, Button, Typography } from '@material-ui/core';
import { Assignment, Edit } from '@material-ui/icons';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { ResellerType } from 'utils/enums/ResellerType';
import { Roles } from 'utils/enums/Roles';
import { UserType } from 'utils/enums/UserType';
function MerchantDashboardPreviewComponent(props) {
    const { globalState, setRoute } = useGlobalStateContext();
    const isMerchantPending = globalState.merchant?.status === MerchantStatus.MERCHANT_PENDING;
    const isDatmanReseller = globalState.reseller?.name === ResellerType.DATMAN;
    const isOwner = globalState.merchant?.userRole === Roles.Owner;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    return (
        <div>
            <Paper className={style.previewContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} lg={4} container className={style.nameOnboardContainer}>
                        <Grid>
                            <Typography color="primary" className={style.welcomeName}>
                                Hi {props.userName}
                            </Typography>
                        </Grid>
                        <Grid>
                            {(isOwner || isAdmin) && (
                                <Button
                                    id="onboarding"
                                    variant="outlined"
                                    onClick={() => setRoute('/onboarding')}
                                    disabled={!isDatmanReseller && !props.isEmailConfirmed}
                                    className={`${style.onboardBtn} ${
                                        isDatmanReseller || props.isEmailConfirmed
                                            ? isMerchantPending
                                                ? style.orangBtn
                                                : style.greenBtn
                                            : style.disabledBtn
                                    }`}
                                >
                                    <Typography color="primary" component="span">
                                        {globalState.merchant &&
                                            (isMerchantPending ? (
                                                <Edit className={style.iconButton} />
                                            ) : (
                                                <Assignment className={style.iconButton} />
                                            ))}
                                    </Typography>
                                    <Typography>
                                        {globalState.merchant &&
                                            (isMerchantPending ? 'Merchant pending' : 'Onboard merchant')}
                                    </Typography>
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={8} container justify="space-between">
                        <Grid item xs={12} className={style.previewTxtContainer}>
                            <Typography component="span" color="primary">
                                <p className={style.textBold}>Getting paid your way</p>
                            </Typography>
                            <Typography component="div" color="primary">
                                {`Here at ${globalState.reseller?.name}, we have a wealth of experience in
                                providing traditional payments solutions through many other payment providers, but none
                                of them ever put you, their customer first.`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

MerchantDashboardPreviewComponent.propTypes = {
    userName: PropTypes.string,
    isEmailConfirmed: PropTypes.bool
};

export default MerchantDashboardPreviewComponent;
