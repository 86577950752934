import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchUserFormComponent from './search-user-form.component';
import { UserService, RoleService, RelationshipService, EntitiesService } from 'services';
import { Roles } from 'utils/enums/Roles';

function SearchUserForm(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [user, setUser] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [inviteFlow, setInviteFlow] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [existsRelationship, setExistsRelationship] = useState();
    const [isDisabledChangeRoleButton, setIsDisabledChangeRoleButton] = useState(false);

    useEffect(() => {
        async function getRoles() {
            const roles = await RoleService.getRoles();
            setRoles(roles.data.filter((role) => role.name !== Roles.Owner));
            setSelectedRole(roles.data[0].id);
        }
        getRoles();
    }, []);

    const onSubmit = (event) => {
        setIsLoading(true);
        setUser(null);
        setSuccess(false);
        setError(false);
        setInviteFlow(false);

        UserService.getUserByEmail(email).then((result) => {
            if (result.isSuccessfully) {
                if (result.user) {
                    RelationshipService.getRoleFromRelationship({
                        userId: result.user.id,
                        businessId: props.businessId,
                        clientId: props.clientId,
                        merchantId: props.merchantId
                    }).then((response) => {
                        if (response.isSuccesfully) {
                            setExistsRelationship(true);
                            setSelectedRole(response.data.role.id);
                            if (response.data.role.name === Roles.Owner) {
                                setIsDisabledChangeRoleButton(true);
                                if (!roles.includes(response.data.role)) {
                                    roles.push(response.data.role);
                                }
                            } else {
                                setIsDisabledChangeRoleButton(false);
                                setRoles(roles.filter((role) => role.name !== Roles.Owner));
                            }
                        } else {
                            setExistsRelationship(false);
                            setRoles(roles.filter((role) => role.name !== Roles.Owner));
                        }
                        setUser(result.user);
                        setIsLoading(false);
                    });
                } else {
                    setInviteFlow(true);
                    setIsLoading(false);
                }
            } else {
                setError(true);
                setIsLoading(false);

                const message = result.errorMessage ? result.errorMessage : 'An error has occurred';
                setErrorMessage(message);
            }
        });

        event.preventDefault();
        event.stopPropagation();
    };

    const assignClick = (userId) => {
        setSuccess(false);
        setError(false);
        setIsLoadingSubmit(true);
        EntitiesService.assignEntityAndRoleToUser({
            userId,
            businessId: props.businessId,
            clientId: props.clientId,
            merchantId: props.merchantId,
            roleId: selectedRole
        }).then((result) => {
            if (result.isSuccesfully) {
                setSuccess(true);
                setIsLoadingSubmit(false);
            } else {
                setIsLoadingSubmit(false);
                setError(true);
                const message = result.data.error ? result.data.error : 'An error occurred';
                setErrorMessage(message);
            }
        });
    };

    return (
        <SearchUserFormComponent
            {...props}
            isLoading={isLoading}
            email={email}
            setEmail={setEmail}
            formSubmit={onSubmit}
            user={user}
            assignClick={assignClick}
            error={error}
            errorMessage={errorMessage}
            success={success}
            isLoadingSubmit={isLoadingSubmit}
            inviteFlow={inviteFlow}
            roles={roles}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            existsRelationship={existsRelationship}
            setExistsRelationship={setExistsRelationship}
            isDisabledChangeRoleButton={isDisabledChangeRoleButton}
        />
    );
}

SearchUserForm.propTypes = {
    handleUserSelect: PropTypes.func,
    businessId: PropTypes.string,
    clientId: PropTypes.string,
    merchantId: PropTypes.string
};

export default SearchUserForm;
