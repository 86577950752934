export const AdyenAccountStatus = {
    ALL: 'All',
    ACTIVE: 'Active',
    INACTIVE: 'In active',
    SUSPENDED: 'Suspended',
    CLOSED: 'Closed',
    ACCOUNT_CLOSURE_REQUEST: 'AccountCloseRequest'
};

export const AdyenAccountStatusStringToEnum = {
    All: 'ALL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Suspended: 'SUSPENDED',
    Closed: 'CLOSED',
    AccountCloseRequest: 'ACCOUNT_CLOSURE_REQUEST'
};

export const AdyenPayoutStatus = {
    ALL: 'All',
    OPEN: 'Open',
    BLOCKED: 'Blocked'
};

export const AdyenTier = {
    ALL: 'All',
    1: 'Tier 1',
    2: 'Tier 2',
    3: 'Tier 3'
};

// Adyen account category based on payout volume
// 5000 > total payout volume critical
// 3000 - 5000 high payout volume but not critical
// < 3000 low payout volume

export const AdyenAccountCategory = {
    ALL: 'All',
    CRITICAL: 'Critical',
    HIGH: 'High',
    LOW: 'Low'
};
