export class ValidationMessage {
    /**
     *
     * @param {*} message
     * @param {boolean} isValid
     */
    constructor(message, isValid) {
        this.message = message;
        this.isValid = isValid;
    }
}
