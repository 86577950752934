import React, { useState, useCallback, useEffect } from 'react';
import ModalEmailComponent from './modal-email-form.component.jsx';
import { debounce } from 'lodash';
import { VirtualTerminalService, InitialiseSaleService } from 'services';
import { getCurrencySymbol, getCurrencyCode } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import { validateEmailAddress } from 'utils/helper';

export default function ModalEmail(props) {
    const { globalState } = useGlobalStateContext();
    const { setShow, amount, description = '', setAmount, setDescription, userEmail, setUserEmail } = props;
    const [email, setEmail] = useState(userEmail);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [message, setMessage] = useState('');
    const [inValidForm, setInValidForm] = useState(true);
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const handleClose = () => {
        setShow(false);

        if (success) {
            setAmount('');
            setDescription('');
            setUserEmail('');
        }
    };
    async function getDnaData(params) {
        try {
            const response = await InitialiseSaleService.InitialiseDNASale(params);
            const { uuid } = response.data;
            return uuid;
        } catch (E) {
            console.log('E ', E);
            setLoading(false);
            return null;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const { merchantAcquirer } = globalState;
        let sessionId = '';

        const data = {
            email: email,
            amount: amount,
            description: description.trim(),
            portalURL: globalState.reseller?.portalURL,
            merchantName: globalState.merchant?.name,
            currencySymbol: getCurrencySymbol(globalState.merchant?.country),
            currencyCode: getCurrencyCode(globalState.merchant?.country),
            payvia: merchantAcquirer?.acquirer ? 'DNA_EMAIL' : 'EMAIL',
            sessionId: ''
        };

        if (merchantAcquirer.acquirer) {
            let params = { amount: amount, currency_code: 826, description: description.trim(), email: userEmail };
            sessionId = await getDnaData(params);
            if (!sessionId) {
                setMessage('Something went wrong!');
                return;
            }
            data.sessionId = sessionId;
        }

        let result = await VirtualTerminalService.sendPayByLinkEmail(data);
        setLoading(false);
        setSuccess(result.isSuccessfully);
        setMessage(result.message);
    };

    const validation = (email) => {
        const errorMessage = validateEmailAddress(email);
        setEmailValidationMessage(errorMessage);
        setInValidForm(errorMessage);
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 1000), []);

    useEffect(() => {
        if (email) {
            doValidation(email);
        }
        // eslint-disable-next-line
    }, [email]);

    return (
        <ModalEmailComponent
            {...props}
            email={email}
            setEmail={setEmail}
            loading={loading}
            success={success}
            message={message}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            inValidForm={inValidForm}
            doValidation={doValidation}
            emailValidationMessage={emailValidationMessage}
            setEmailValidationMessage={setEmailValidationMessage}
        />
    );
}
