import React from 'react';
import styles from './stripe-payout-details.module.scss';
import { Grid, Typography, Box } from '@material-ui/core';
import TransactionStatusLabel from '../transaction-tables/transaction-status-label/transaction-status-label.container';
import { formatCurrency, formatDateTimeByFormatString } from 'utils/helper';
import moment from 'moment';
import PropTypes from 'prop-types';
import StripePayoutsSummaryAndTransactions from './stripe-payouts-summary-and-transactions/stripe-payouts-summary-and-transactions.container';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';

export default function StripePayoutDetailsComponent(props) {
    const { globalState } = useGlobalStateContext();

    const { payouts, overViewFee, transactions, loading, date } = props;
    return (
        <React.Fragment>
            <div className={styles.containerDiv}>
                <div className={styles.containerSectionDiv}>
                    <div className={styles.headerDetails}>
                        <div>
                            <div className={styles.payout}>
                                <Typography component="span" color="primary" className={styles.title}>
                                    <span>Payout</span>
                                </Typography>
                            </div>
                            <Typography component="span" color="primary" className={styles.payoutAmt}>
                                {formatCurrency(
                                    payouts.amount / 100,
                                    getCurrencyThreeAlpha(globalState.merchant?.country)
                                )}
                            </Typography>
                            <span className={styles.transLabel}>
                                <TransactionStatusLabel
                                    status={payouts.status === 'in_transit' ? 'In Transit' : payouts.status}
                                />
                            </span>
                        </div>
                        <div>
                            <div className={styles.date_expected}>
                                <Box px={2}>
                                    <div>Date expected</div>
                                    <div style={{ color: 'black' }}>
                                        {formatDateTimeByFormatString(
                                            moment.unix(payouts.arrival_date),
                                            'DD MMM HH:mm'
                                        )}
                                    </div>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.containerDetailsDiv}>
                    <div className={styles.sectionTitle}>
                        <Typography component="span" color="primary" className={styles.title}>
                            Overview
                        </Typography>
                    </div>
                    <div>
                        <Grid container xs={12} sm={12}>
                            <Grid item xs={10} sm={10}>
                                <Grid container xs={12} sm={12}>
                                    <Grid item>
                                        <Typography className={styles.attribute}>Date Paid</Typography>
                                        <Typography className={styles.attribute}>Amount</Typography>
                                        <Typography className={styles.attribute}>Fee</Typography>
                                    </Grid>
                                    <Grid item className={styles.attributeValues}>
                                        <Typography className={styles.attributedata}>
                                            {formatDateTimeByFormatString(moment.unix(payouts.created), 'DD MMM HH:mm')}
                                        </Typography>
                                        <Typography className={styles.attributedata}>
                                            {formatCurrency(
                                                payouts.amount / 100,
                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                            )}
                                        </Typography>
                                        <Typography className={styles.attributedata}>
                                            {formatCurrency(
                                                overViewFee / 100,
                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <StripePayoutsSummaryAndTransactions
                        transactions={transactions}
                        loading={loading}
                        id={payouts.id}
                        date={date}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

StripePayoutDetailsComponent.propTypes = {
    payouts: PropTypes.object,
    overViewFee: PropTypes.number
};
