import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, List, ListItem, Typography, Container, Box, Grid, Badge } from '@material-ui/core';
import style from './merchant-settings.module.scss';
import { useGlobalStateContext } from 'contexts';
import { ModalCloseMerchantAccount, ModalDeleteMerchantAccount, SnackBarAndAlert } from 'components';
import { CheckMerchantCountries } from '../onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckCircleSharp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles({
    badge: {
        background: '#8783b5',
        color: '#f6f7fa',
        transform: 'scale(1) translate(85%, -45%)'
    }
})(Badge);
function MerchantSettingsComponent(props) {
    const { globalState, setRoute, getRoute } = useGlobalStateContext();
    const [isModalDeleteAccount, setIsModalDeleteAccount] = useState(false);
    const [isModalCloseAccount, setIsModalCloseAccount] = useState(false);
    const [isModalConfirmationCloseAccount, setIsModalConfirmationCloseAccount] = useState(false);
    const isClosureRequested = globalState.merchant?.isClosureRequested;
    const {
        canViewAndEditTeam,
        isOwner,
        isNotSubmitted,
        isActive,
        isAdmin,
        isMerchant,
        redirected,
        isWatchList
    } = props;
    const isCanonicalResellerMerchant = ![1, 2].includes(globalState.merchant?.canonicalResellerId);
    const noOfSignedTermsAndConditions = globalState.merchant?.noOfSignedTermsAndConditions;
    const noOfNotSignedTermsAndConditions = globalState.merchant?.noOfNotSignedTermsAndConditions;
    const noOfNotSignedAcquirerTermsAndConditions = globalState.merchant?.noOfNotSignedAcquirerTermsAndConditions;

    return (
        <Container className={style.mainContainer}>
            {redirected && (
                <Grid container alignItems="center" className={style.notificationContainer}>
                    <Grid item xs={1} container justify="center">
                        <CheckCircleSharp className={style.success_icon} />
                    </Grid>
                    <Grid item xs={11} className={style.merchant__creation__container}>
                        <Typography color="primary">
                            <span className={style.notification}>
                                Your account verification documents have been uploaded successfully, our teams will
                                review this within 48Hrs.
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Paper className={style.settingsContainer}>
                <Box>
                    <Box className={style.divTitle}>
                        <Typography color="primary" component="span">
                            <span className={style.settingsTitle}>Merchant settings</span>
                        </Typography>
                    </Box>
                    <Grid className={style.container}>
                        <Grid item xs={12} sm={4}>
                            <Box className={style.navContainer}>
                                <h3 className="font-weight-bold">Your business</h3>
                                <List component="nav">
                                    {/* {isAdmin && (
                                        <ListItem button onClick={() => setRoute('/account-info')}>
                                            <Box>
                                                <Typography color="primary" component="span">
                                                    Account information
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    )} */}
                                    {(isOwner || isAdmin) && (
                                        <React.Fragment>
                                            {(isActive || isWatchList) && (
                                                <React.Fragment>
                                                    <ListItem
                                                        button
                                                        onClick={() => setRoute('/update-account')}
                                                        className={style.listItemStyle}
                                                    >
                                                        <Box>
                                                            <Typography color="primary" component="span">
                                                                Update account details
                                                            </Typography>
                                                        </Box>
                                                    </ListItem>

                                                    {isAdmin && (
                                                        <React.Fragment>
                                                            <CheckMerchantCountries
                                                                includedCountries={[
                                                                    MerchantCountries.UNITED_KINGDOM,
                                                                    MerchantCountries.IRELAND,
                                                                    MerchantCountries.AUSTRALIA,
                                                                    MerchantCountries.NEW_ZEALAND,
                                                                    MerchantCountries.UNITED_STATES
                                                                ]}
                                                            >
                                                                <ListItem
                                                                    button
                                                                    onClick={() => setRoute('/update-bank')}
                                                                    className={style.listItemStyle}
                                                                >
                                                                    <Box>
                                                                        <Typography color="primary" component="span">
                                                                            Update bank details
                                                                        </Typography>
                                                                    </Box>
                                                                </ListItem>
                                                            </CheckMerchantCountries>
                                                            <ListItem
                                                                button
                                                                onClick={() => setRoute('/upload-documents')}
                                                                className={style.listItemStyle}
                                                            >
                                                                <Box>
                                                                    <Typography color="primary" component="span">
                                                                        Upload documents
                                                                    </Typography>
                                                                </Box>
                                                            </ListItem>
                                                        </React.Fragment>
                                                    )}

                                                    {isMerchant && !isWatchList && (
                                                        <React.Fragment>
                                                            <CheckMerchantCountries
                                                                includedCountries={[
                                                                    MerchantCountries.UNITED_KINGDOM,
                                                                    MerchantCountries.IRELAND,
                                                                    MerchantCountries.AUSTRALIA,
                                                                    MerchantCountries.NEW_ZEALAND,
                                                                    MerchantCountries.UNITED_STATES
                                                                ]}
                                                            >
                                                                <ListItem
                                                                    button
                                                                    onClick={() => setRoute('/update-bank')}
                                                                    className={style.listItemStyle}
                                                                >
                                                                    <Box>
                                                                        <Typography color="primary" component="span">
                                                                            Update bank details
                                                                        </Typography>
                                                                    </Box>
                                                                </ListItem>
                                                            </CheckMerchantCountries>
                                                            <ListItem
                                                                button
                                                                onClick={() => setRoute('/upload-documents')}
                                                                className={style.listItemStyle}
                                                            >
                                                                <Box>
                                                                    <Typography color="primary" component="span">
                                                                        Upload documents
                                                                    </Typography>
                                                                </Box>
                                                            </ListItem>
                                                        </React.Fragment>
                                                    )}
                                                    <ListItem
                                                        className={style.settings__buttons}
                                                        onClick={() => setIsModalCloseAccount(true)}
                                                    >
                                                        <Box>
                                                            <Typography color="primary" component="span">
                                                                Close account
                                                            </Typography>
                                                        </Box>
                                                    </ListItem>
                                                </React.Fragment>
                                            )}
                                            {isNotSubmitted && (
                                                <ListItem
                                                    className={style.settings__buttons}
                                                    onClick={() => setIsModalDeleteAccount(true)}
                                                >
                                                    <Box>
                                                        <Typography color="primary" component="span">
                                                            Delete account
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            )}
                                        </React.Fragment>
                                    )}
                                </List>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={style.navContainer}>
                                <h3 className="font-weight-bold">Ways to get paid</h3>
                                <List component="nav">
                                    <ListItem
                                        button
                                        onClick={() => setRoute('/manage-products')}
                                        className={style.listItemStyle}
                                    >
                                        <Box>
                                            <Typography color="primary" component="span">
                                                Manage my products
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={style.navContainer}>
                                <h3 className="font-weight-bold">Team and security</h3>
                                <List component="nav">
                                    {canViewAndEditTeam ? (
                                        <ListItem className={style.settings__buttons} onClick={() => setRoute('/team')}>
                                            <Box>
                                                <Typography color="primary" component="span">
                                                    User management
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                    ) : null}
                                    <ListItem
                                        className={style.settings__buttons}
                                        onClick={() => setRoute('/update-password')}
                                    >
                                        <Box>
                                            <Typography color="primary" component="span">
                                                Update password
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <ListItem
                                        className={style.settings__buttons}
                                        onClick={() => {
                                            isCanonicalResellerMerchant
                                                ? noOfSignedTermsAndConditions ||
                                                  noOfNotSignedTermsAndConditions ||
                                                  noOfNotSignedAcquirerTermsAndConditions
                                                    ? setRoute('/setting-terms-and-conditions')
                                                    : window.open(getRoute(`/terms-and-conditions`), '_blank')
                                                : window.open(getRoute(`/terms-and-conditions`), '_blank');
                                        }}
                                    >
                                        <StyledBadge
                                            id="badge"
                                            color="default"
                                            badgeContent={noOfSignedTermsAndConditions}
                                            data-tooltip="hello"
                                        >
                                            <Box>
                                                <Typography color="primary" component="span">
                                                    Terms and conditions
                                                </Typography>
                                            </Box>
                                        </StyledBadge>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
            {isModalDeleteAccount && <ModalDeleteMerchantAccount setOpen={setIsModalDeleteAccount} />}
            {isModalCloseAccount && (
                <React.Fragment>
                    {isClosureRequested ? (
                        <SnackBarAndAlert
                            open={isModalCloseAccount && isClosureRequested}
                            onClose={() => setIsModalCloseAccount(false)}
                            type="warning"
                        >
                            You already requested to close the account {globalState.merchant?.name} and a member of our
                            team should be in touch to walk you through the process.
                        </SnackBarAndAlert>
                    ) : (
                        <ModalCloseMerchantAccount
                            setOpen={setIsModalCloseAccount}
                            setOpenConfirmation={setIsModalConfirmationCloseAccount}
                        />
                    )}
                </React.Fragment>
            )}
            {isModalConfirmationCloseAccount && (
                <SnackBarAndAlert
                    open={isModalConfirmationCloseAccount}
                    onClose={() => setIsModalConfirmationCloseAccount(false)}
                    type="success"
                >
                    Your request to Close account {globalState.merchant?.name} has been recieved. A member of our team
                    will be in touch with you.
                </SnackBarAndAlert>
            )}
        </Container>
    );
}

MerchantSettingsComponent.propTypes = {
    canViewAndEditTeam: PropTypes.bool,
    isOwner: PropTypes.bool,
    isNotSubmitted: PropTypes.bool,
    isActive: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isMerchant: PropTypes.bool
};

export default MerchantSettingsComponent;
