import React from 'react';
import PropTypes from 'prop-types';
import styles from './payment-action.module.scss';
import {
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
    Dialog,
    DialogContent,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext, useBalanceContext } from 'contexts';
import { SnackBarAndAlert, DotFlashingLoading } from 'components';
import { numberFormatAllowValuesForPayments } from 'utils/helper';
import { ResellerType } from 'utils/enums/ResellerType';
import { withdrawalStatus } from 'utils/enums/Status';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { merchantTypes, formatCurrency } from 'utils/helper.js';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    root: {
        height: '40px',
        '&.MuiOutlinedInput-root': {
            'border-radius': '4px 0px 0px 4px !important',
            '& input': {
                fontSize: '20px',
                fontWeight: 'bold'
            }
        },
        '& fieldset': {
            border: '1px solid #e8e9ec'
        }
    }
}));

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 12,
        fontWeight: 400,
        border: '2px solid #d3eff5'
    },
    popper: {
        top: '-5px !important',
        left: '-2px !important'
    }
}))(Tooltip);

/**
 *
 * @param {{
    textPlaceHolder: string,
    inputValue: number,
    balanceValue?: number,
    textInСircle: string,
    onInput: (event: Event) => {},
    btnText: string,
    onBtnClick: () => {}
 * }} props
 */
function PaymentActionComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { balance, balanceIsLoading } = useBalanceContext();
    const {
        textPlaceHolder,
        inputValue,
        btnText,
        onBtnClick,
        textInСircle,
        showActionError,
        cantWithdraw,
        paymentActionValidation,
        renderPopup,
        paymentType,
        isSuccessWithdraw,
        setIsSuccessWithdraw,
        successMessage,
        amount,
        setAmount,
        cantWithdrawMessage,
        setOpenView,
        openView
    } = props;
    const classes = useStyles();
    const hidePaymentActions = globalState.merchant?.userRole === 'User';
    const datmanMerchant = globalState.reseller?.name === ResellerType.DATMAN;
    const notShowWithdrawals = merchantTypes.includes(globalState.merchant?.merchantType);
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    const manualWithdrawalStatus = sessionStorage.getItem('manual_withdrawal_status');
    return (
        <div className={`${styles.wrapper} ${hidePaymentActions ? styles.wrapperSingle : ''}`}>
            <div className={styles.nameContainer}>
                <div className={styles.nameLogo}>
                    <div className={styles.logo}>{textInСircle}</div>
                    <Typography color={'primary'} className={styles.userName}>
                        {textPlaceHolder}
                    </Typography>
                </div>
            </div>
            {!notShowWithdrawals && (
                <div className={styles.newContainer}>
                    {datmanMerchant && (
                        <div className={styles.viewBalanceContainer}>
                            <div className={styles.balance}>
                                {balanceIsLoading ? (
                                    <div className={styles.balanceLoading}>
                                        <DotFlashingLoading leftAndAbove />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <span className={styles.balanceCurrencySign}>
                                            {getCurrencySymbol(globalState.merchant?.country)}
                                        </span>
                                        <NumberFormat
                                            customInput={OutlinedInput}
                                            inputMode="numeric"
                                            pattern="\d*"
                                            thousandSeparator={true}
                                            allowLeadingZeros={true}
                                            allowNegative={true}
                                            isNumericString={true}
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            displayType={'text'}
                                            value={balance}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}
                    {datmanMerchant && (
                        <div className={styles.inputAndButtonContainer}>
                            {/* {!hidePaymentActions && (
                                <div className={styles.inputContainer}>
                                    <ErrorTooltip
                                        open={showActionError}
                                        arrow
                                        title={
                                            <Alert severity="error">{+balance > 0 && paymentActionValidation()}</Alert>
                                        }
                                    >
                                        <div className={styles.inputWrapper}>
                                            <NumberFormat
                                                autoComplete="off"
                                                customInput={OutlinedInput}
                                                inputProps={{ inputMode: 'numeric' }}
                                                pattern="\d*"
                                                thousandSeparator={true}
                                                allowLeadingZeros={true}
                                                allowNegative={false}
                                                isNumericString={true}
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                disabled={+balance <= 0}
                                                id="outlined-adornment-weight"
                                                className={classes.root + ' ' + styles.inputBalance}
                                                placeholder={'0.00'}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <span
                                                            className={styles.balanceCurrencySign + ' ' + styles.margin}
                                                        >
                                                            {getCurrencySymbol(globalState.merchant?.country)}
                                                        </span>
                                                    </InputAdornment>
                                                }
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setAmount(value);
                                                }}
                                                isAllowed={(values) => numberFormatAllowValuesForPayments(values)}
                                                aria-describedby="outlined-weight-helper-text"
                                                labelWidth={0}
                                                value={amount}
                                                required
                                            />
                                        </div>
                                    </ErrorTooltip>
                                </div>
                            )}
                            <ErrorTooltip
                                open={inputValue && inputValue > 0 && cantWithdraw}
                                arrow
                                title={<Alert severity="error">{cantWithdrawMessage}</Alert>}
                            >
                                <div className={styles.buttonActionContainer}>
                                    <Button
                                        // eslint-disable-next-line
                                        disabled={
                                            +balance <= 0 ||
                                            showActionError ||
                                            !inputValue ||
                                            +inputValue === 0 ||
                                            cantWithdraw ||
                                            globalState.merchant.status === MerchantStatus.WATCHLIST
                                        }
                                        onClick={onBtnClick}
                                        className={styles.buttonAction}
                                    >
                                        <div className={styles.buttonText}>
                                            <AccountBalanceWalletOutlinedIcon />
                                            <p className={styles.nameButton}>{btnText}</p>
                                        </div>
                                    </Button>
                                    {renderPopup(paymentType)}
                                </div>
                            </ErrorTooltip> */}
                            <div className={styles.buttonActionContainer}>
                                <Button
                                    // eslint-disable-next-line
                                    onClick={() => setOpenView(true)}
                                    className={styles.buttonAction}
                                    disabled={isMerchantAccountClosed}
                                >
                                    <div className={styles.buttonText}>
                                        <p className={styles.nameButton}>View</p>
                                    </div>
                                </Button>
                                {/* {renderPopup(paymentType)} */}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Dialog
                open={openView}
                onClose={() => {
                    setOpenView(false);
                    setAmount(null);
                }}
                className={{
                    paper: styles.paper
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                max-width="false"
            >
                <DialogContent
                    classes={{
                        root: styles.dialogContent
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                        <div>
                            <Typography color={'primary'}>
                                <p className={styles.dialogTitle}>Balance</p>
                            </Typography>
                        </div>
                        <div>
                            <Button
                                style={{ padding: '0px' }}
                                onClick={() => {
                                    setOpenView(false);
                                    setAmount(null);
                                }}
                            >
                                <CloseIcon />
                            </Button>
                        </div>
                    </div>

                    <Divider />
                    <TableContainer className={classes.container}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="small"
                            stickyHeader
                            aria-label="enhanced table"
                        ></Table>
                        <TableBody>
                            {/* <TableRow>
                                <TableCell>
                                    <Typography>Total Balance</Typography>
                                </TableCell>
                                <TableCell>
                                    {formatCurrency(balance, getCurrencyThreeAlpha(globalState.merchant?.country))}
                                </TableCell>
                            </TableRow> */}
                            {/* <TableRow>
                                <TableCell>
                                    <Typography>In transit Balance</Typography>
                                    <small style={{ color: 'grey' }}>
                                        Payouts in Transit will be deposited in to your account every Tuesday and Friday
                                        automatically.
                                    </small>
                                </TableCell>
                                <TableCell>
                                    {formatCurrency(
                                        transitBalance,
                                        getCurrencyThreeAlpha(globalState.merchant?.country)
                                    )}
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell style={{ paddingLeft: 0, borderBottom: 0 }}>
                                    <Typography>
                                        <strong>Available Balance</strong>
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ borderBottom: 0 }}>
                                    <strong>
                                        {formatCurrency(balance, getCurrencyThreeAlpha(globalState.merchant?.country))}
                                    </strong>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </TableContainer>
                    <Divider />
                    {Number(manualWithdrawalStatus) === withdrawalStatus.ALLOW_WITHDRAWAL ? (
                        <div style={{ display: 'flex', margin: '20px 0px' }}>
                            {!hidePaymentActions && (
                                <div className={styles.newInputContainer}>
                                    <ErrorTooltip
                                        open={showActionError}
                                        arrow
                                        title={
                                            <Alert severity="error">{+balance > 0 && paymentActionValidation()}</Alert>
                                        }
                                    >
                                        <div className={styles.newInputWrapper}>
                                            <NumberFormat
                                                autoComplete="off"
                                                customInput={OutlinedInput}
                                                inputProps={{ inputMode: 'numeric' }}
                                                pattern="\d*"
                                                thousandSeparator={true}
                                                allowLeadingZeros={true}
                                                allowNegative={false}
                                                isNumericString={true}
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                disabled={+balance <= 0}
                                                id="outlined-adornment-weight"
                                                className={classes.root + ' ' + styles.inputBalance}
                                                placeholder={'0.00'}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <span
                                                            className={styles.balanceCurrencySign + ' ' + styles.margin}
                                                        >
                                                            {getCurrencySymbol(globalState.merchant?.country)}
                                                        </span>
                                                    </InputAdornment>
                                                }
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setAmount(value);
                                                }}
                                                isAllowed={(values) => numberFormatAllowValuesForPayments(values)}
                                                aria-describedby="outlined-weight-helper-text"
                                                labelWidth={0}
                                                value={amount}
                                                required
                                            />
                                        </div>
                                    </ErrorTooltip>
                                </div>
                            )}
                            <ErrorTooltip
                                open={inputValue && inputValue > 0 && cantWithdraw}
                                arrow
                                title={<Alert severity="error">{cantWithdrawMessage}</Alert>}
                            >
                                <div className={styles.newButtonActionContainer}>
                                    <Button
                                        // eslint-disable-next-line
                                        disabled={
                                            +balance <= 0 ||
                                            showActionError ||
                                            !inputValue ||
                                            +inputValue === 0 ||
                                            cantWithdraw ||
                                            globalState.merchant.status === MerchantStatus.WATCHLIST
                                        }
                                        onClick={onBtnClick}
                                        className={styles.buttonAction}
                                    >
                                        <div className={styles.buttonText}>
                                            <AccountBalanceWalletOutlinedIcon />
                                            <p className={styles.nameButton}>{btnText}</p>
                                        </div>
                                    </Button>
                                    {renderPopup(paymentType)}
                                </div>
                            </ErrorTooltip>
                        </div>
                    ) : (
                        <div className={styles.withdrawalMessage}>
                            We're simplifying your payouts.
                            <br />
                            No more manual withdrawal required!
                        </div>
                    )}
                </DialogContent>
            </Dialog>

            {isSuccessWithdraw && (
                <SnackBarAndAlert open={isSuccessWithdraw} onClose={() => setIsSuccessWithdraw(false)} type="success">
                    {successMessage}
                </SnackBarAndAlert>
            )}
        </div>
    );
}

PaymentActionComponent.propTypes = {
    textPlaceHolder: PropTypes.string,
    inputValue: PropTypes.number,
    btnText: PropTypes.string,
    onBtnClick: PropTypes.func,
    textInСircle: PropTypes.string,
    showActionError: PropTypes.bool,
    cantWithdraw: PropTypes.bool,
    paymentActionValidation: PropTypes.func,
    renderPopup: PropTypes.func,
    paymentType: PropTypes.number,
    setAmount: PropTypes.func,
    isSuccessWithdraw: PropTypes.bool,
    setIsSuccessWithdraw: PropTypes.func,
    successMessage: PropTypes.string,
    cantWithdrawMessage: PropTypes.string,
    calculateFontSize: PropTypes.func
};

export default PaymentActionComponent;
