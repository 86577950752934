import React from 'react';
import {
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    Badge,
    withStyles
} from '@material-ui/core';
import style from './admin-dashboard-home.module.scss';
import { ChevronRight, ExpandMore, CheckCircleSharp, CloseSharp } from '@material-ui/icons';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 9,
        top: -1,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}))(Badge);

function AdminDashboardHomeComponent() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Paper className={style.stepsContainer}>
            <List className={style.list}>
                <ListItem className={`${style.listItem}`}>
                    <Accordion
                        className={style.accardionItem}
                        expanded={expanded === 'panel'}
                        onChange={handleChange('panel')}
                    >
                        <AccordionSummary>
                            <div className={style.summaryContainer}>
                                {expanded === 'panel' ? <ExpandMore /> : <ChevronRight />}
                                <Typography color="primary">
                                    <span className={`${style.textBold} ${style.itemContainerText}`}>
                                        System Notification downtime alert
                                    </span>
                                </Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={style.accordionDetails}>
                            <Typography color="primary">
                                <span>nan..(n.n)</span>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <div className={style.closeBtn}>
                        <CloseSharp />
                    </div>
                </ListItem>

                <ListItem className={style.listItem}>
                    <div className={style.listItemContainer}>
                        <CheckCircleSharp />

                        <Typography color="primary">
                            <span className={`${style.textBold} ${style.itemContainerText}`}>
                                <StyledBadge badgeContent={12} color="primary" /> customers awaiting reviews{' '}
                            </span>
                        </Typography>
                    </div>
                    <div className={style.closeBtn}>
                        <CloseSharp />
                    </div>
                </ListItem>
                <ListItem className={style.listItem}>
                    <div className={style.listItemContainer}>
                        <CheckCircleSharp />

                        <Typography color="primary">
                            <span className={`${style.textBold} ${style.itemContainerText}`}>
                                <StyledBadge badgeContent={2} color="primary" /> account verification pending
                            </span>
                        </Typography>
                    </div>
                    <div className={style.closeBtn} onClick={() => console.log('close this shit.')}>
                        <CloseSharp />
                    </div>
                </ListItem>
            </List>
        </Paper>
    );
}

AdminDashboardHomeComponent.propTypes = {};

export default AdminDashboardHomeComponent;
