import moment from 'moment';
import { REACT_APP_CARD_PAYMENT_ARCHIVE_DATE } from 'utils/constants';

export const isDateBeforeCutOffDate = (date) => {
    const inputDate = moment(date, 'YYYY-MM-DD');
    const thresholdDate = moment(REACT_APP_CARD_PAYMENT_ARCHIVE_DATE, 'MM-DD-YYYY').format('YYYY-MM-DD');
    return inputDate.isBefore(thresholdDate);
};

export const dateRangeArchived = (startDate, endDate) =>
    isDateBeforeCutOffDate(startDate) && isDateBeforeCutOffDate(endDate);

export const getExportDate = (url) => {
    return url.split('/').pop();
};
