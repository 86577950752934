import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PdqTransactionsListComponent from './pdq-transactions-list.component';
import { useGlobalStateContext } from 'contexts';
import { PdqTransactionsService } from 'services';
import _ from 'lodash';

const PdqTransactionList = (props) => {
    const [pdqlist, setPdqList] = useState([]);
    const [error, setError] = useState(false);
    const { loading, setLoading } = props;
    const { globalState } = useGlobalStateContext();
    const { date, via } = props.match.params;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setPage(0);
        async function GetPdqTransactionsList() {
            let pdqTransactionsRequest = {
                merchantId: globalState.merchant?.id,
                year: moment(date, 'YYYY-MM-DD').year(),
                month: moment(date).month() + 1,
                day: moment(date, 'YYYY-MM-DD').date()
            };
            let pdqTransactions;
            let startMonth;
            let endMonth;
            if (via === 'week') {
                startMonth = moment(moment(date).subtract(0, 'week').startOf('week').format('YYYY MM DD')).month() + 1;
                endMonth = moment(moment(date).subtract(0, 'week').endOf('week').format('YYYY MM DD')).month() + 1;
            }

            let pdqTransactionsAnotherMonth;
            if (startMonth !== endMonth && via === 'week') {
                const startMonthPdqRequest = {
                    ...pdqTransactionsRequest,
                    month: startMonth
                };
                const endMonthPdqRequest = {
                    ...pdqTransactionsRequest,
                    month: endMonth
                };
                pdqTransactions = await PdqTransactionsService.GetPdqTransactionsList(startMonthPdqRequest);
                pdqTransactionsAnotherMonth = await PdqTransactionsService.GetPdqTransactionsList(endMonthPdqRequest);
            } else {
                pdqTransactions = await PdqTransactionsService.GetPdqTransactionsList(pdqTransactionsRequest);
            }

            if (pdqTransactions.isSuccesfully) {
                let pdqTransactionsData = [...pdqTransactions.data.data];
                let startingTimestamp = moment(
                    `${pdqTransactionsRequest.day}-${pdqTransactionsRequest.month}-${pdqTransactionsRequest.year}`,
                    'DD-MM-YYYY'
                )
                    .startOf(via)
                    .valueOf();
                let endingTimestamp = moment(
                    `${pdqTransactionsRequest.day}-${pdqTransactionsRequest.month}-${pdqTransactionsRequest.year}`,
                    'DD-MM-YYYY'
                )
                    .endOf(via)
                    .valueOf();

                if (pdqTransactionsAnotherMonth?.isSuccesfully) {
                    pdqTransactionsData = [...pdqTransactionsData, ...pdqTransactionsAnotherMonth.data.data];
                }

                let transactionInTimeFrame = _.filter(pdqTransactionsData, function (internalTransfer) {
                    let date_time = moment(moment.utc(internalTransfer.date_time).format('YYYY-MM-DD')).valueOf();
                    return date_time >= startingTimestamp && date_time <= endingTimestamp;
                });
                setPdqList(transactionInTimeFrame);
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
        }
        setLoading(true);
        globalState.merchant && GetPdqTransactionsList();
        // eslint-disable-next-line
    }, [date, via, globalState.merchant]);

    const totalPDQ = () => {
        let total = pdqlist.reduce((accumulator, pdq) => {
            return accumulator + Number(pdq.total);
        }, 0);

        return total;
    };

    return (
        <PdqTransactionsListComponent
            {...props}
            list={pdqlist}
            error={error}
            loading={loading}
            totalPDQ={totalPDQ}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );
};

export default PdqTransactionList;
