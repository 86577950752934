import React, { useReducer, useRef, useEffect, useState } from 'react';
import CardDetailsComponent from './card-details.component';
import reducer from './reducer';
import { Redirect } from 'react-router-dom';
import { InitialiseSaleService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { v4 as uuidv4 } from 'uuid';

const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
};

function CardDetails(props) {
    const { globalState, getRoute } = useGlobalStateContext();

    const userRef = `user_${uuidv4()}`;

    const { state: propState } = props.location;

    const amount = propState?.amount;
    const email = propState?.email;
    const phoneNumber = propState?.phoneInput;
    const descriptionInfo = propState?.description;

    const { initialState, TakePaymentsReducer } = reducer;

    const [state, dispatch] = useReducer(TakePaymentsReducer, initialState);
    const [loading, setLoading] = useState(false);

    const prevState = usePrevious(state);

    const formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardMonth: useRef(),
        cardYear: useRef(),
        cardCvv: useRef()
    };

    const focusFormFieldByKey = (key) => {
        formFieldsRefObj[key].current.focus();
    };

    // This are the references for the Card DIV elements.
    const cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    const onCardFormInputFocus = (_event, inputName) => {
        let refByName = cardElementsRef[inputName];
        if (inputName === 'cardMonth' || inputName === 'cardYear') refByName = cardElementsRef.carddate;

        const formFieldRef = formFieldsRefObj[inputName];
        formFieldRef.current.style.borderColor = '';

        dispatch({
            type: 'SET_CURRENT_FOCUSED_ELEMENT',
            currentFocusedElm: refByName
        });
    };

    const onCardInputBlur = () => {
        const { isFormDataValid } = state;

        for (const [key, value] of Object.entries(isFormDataValid)) {
            if (value !== null && !value) formFieldsRefObj[key].current.style.borderColor = 'red';
        }

        dispatch({
            type: 'SET_CURRENT_FOCUSED_ELEMENT',
            currentFocusedElm: null
        });
    };

    const initiateSale = async (cardDetails, amount, descriptionInfo) => {
        try {
            const {
                card_number,
                card_exp_year,
                card_exp_month,
                card_cvv,
                cardHolderName,
                cardHolderAddress: address
            } = cardDetails;

            const formattedName = cardHolderName
                .split(' ')
                .map((name) => name.charAt(0).toUpperCase() + name.substr(1))
                .join(' ');

            const data = {
                amount: parseInt(amount * 100),
                currency_code: 826,
                user_order_ref: userRef,
                description: descriptionInfo,
                items: [
                    {
                        '21': 4
                    }
                ],
                shoppers: {
                    first_name: formattedName,
                    last_name: '',
                    email: email,
                    phone_number: phoneNumber,
                    address
                },
                card_number,
                card_exp_month,
                card_exp_year,
                card_cvv,
                merchant_id: globalState.merchant?.id
            };

            const response = await InitialiseSaleService.InitialiseSale(data);

            if (response.status === 201) {
                const { acsUrl, md, paReq } = response.data.data.threeDSreq;
                return dispatch({
                    type: 'SET_3D_SECURE_DETAILS',
                    acsUrl,
                    paReq,
                    md
                });
            }

            // if status === 200, non 3ds txn, update modal status accordingly.
            if (response.status === 200) {
                return dispatch({
                    type: 'SET_MODAL_STATE',
                    showModal: true,
                    paymentSuccess: true,
                    showLoadingSpinner: false
                });
            }

            if (!response.isSuccesfully) {
                return dispatch({
                    type: 'SET_MODAL_STATE',
                    showModal: true,
                    paymentSuccess: false,
                    showLoadingSpinner: false
                });
            }

            return null;
        } catch (error) {
            // Set error state
            dispatch({
                type: 'SET_MODAL_STATE',
                showModal: true,
                paymentSuccess: false,
                showLoadingSpinner: false
            });
            return null;
        }
    };

    const handleIframeTask = (e) => {
        const { data } = e;
        if (!data) return null;
        const { md, paRes } = data;
        if (!md || !paRes) return null;

        return dispatch({
            type: 'INIT_FINAL_SALE',
            showModal: true,
            showLoadingSpinner: true,
            paRes,
            md
        });
    };

    useEffect(() => {
        const { merchantAcquirer } = globalState;

        async function getDnaData() {
            try {
                setLoading(true);
                let params = {
                    amount: amount,
                    currency_code: 826,
                    description: propState.description,
                    email: email,
                    phone_number: phoneNumber
                };
                const response = await InitialiseSaleService.InitialiseDNASale(params);
                const { url } = response.data;
                window.open(url, '_parent');
                setLoading(false);
                //for iframe
                // document.getElementById('dnaForm').contentWindow.document.write(response.data);
            } catch (E) {
                console.log('E ', E);
                setLoading(false);
            }
        }
        merchantAcquirer?.acquirer && getDnaData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const finalise3DSSale = async () => {
            try {
                const {
                    cardNumber: card_number,
                    cardMonth: card_exp_month,
                    cardYear: card_exp_year,
                    cardCvv: card_cvv,
                    md,
                    paRes,
                    cardHolder: cardHolderName,
                    cardHolderAddress: address
                } = state;

                // const { cardHolder } = state;
                const formattedName = cardHolderName
                    .split(' ')
                    .map((name) => name.charAt(0).toUpperCase() + name.substr(1))
                    .join(' ');

                const data = {
                    amount: parseInt(amount * 100),
                    currency_code: 826,
                    user_order_ref: userRef,
                    description: descriptionInfo,
                    items: [
                        {
                            '21': 4
                        }
                    ],
                    shoppers: {
                        first_name: formattedName,
                        last_name: '',
                        email: 'mew@mypay.com',
                        address
                    },
                    card_number,
                    card_exp_month,
                    card_exp_year,
                    card_cvv,
                    md,
                    paRes,
                    merchant_id: globalState.merchant?.id
                };

                await InitialiseSaleService.InitialiseSale(data);

                return dispatch({
                    type: 'SET_MODAL_STATE',
                    showModal: true,
                    paymentSuccess: true,
                    showLoadingSpinner: false
                });
            } catch (e) {
                dispatch({
                    type: 'SET_MODAL_STATE',
                    showModal: true,
                    paymentSuccess: false,
                    showLoadingSpinner: false
                });
                return null;
            }
        };
        if (prevState && !prevState.paRes && state.paRes) finalise3DSSale();
        return () => {
            window.onbeforeunload = null;
            return null;
        };
        // eslint-disable-next-line
    }, [state.paRes, prevState, amount, state, globalState.merchant]);

    // Redirect to enter amount page if there is no amount or description available
    if (!propState) return <Redirect to={getRoute(`/virtual-terminal`)} />;

    return (
        <CardDetailsComponent
            {...props}
            userRef={userRef}
            amount={amount}
            state={state}
            dispatch={dispatch}
            prevState={prevState}
            formFieldsRefObj={formFieldsRefObj}
            focusFormFieldByKey={focusFormFieldByKey}
            cardElementsRef={cardElementsRef}
            propState={propState}
            onCardFormInputFocus={onCardFormInputFocus}
            onCardInputBlur={onCardInputBlur}
            handleIframeTask={handleIframeTask}
            initiateSale={initiateSale}
            globalState={globalState}
            loading={loading}
        />
    );
}

export default CardDetails;
