export const DocumentReviewReasons = {
    ALL: 'All',
    ONBOARDING: 'Onboarding',
    BANK_VERIFY: 'Bank details update',
    ACCOUNT_VERIFY: 'Account details update'
};

export const DocumentReviewReasonFlag = {
    All: 'ALL',
    Onboarding: 'ONBOARDING',
    'Bank details update': 'BANK_VERIFY',
    'Account details update': 'ACCOUNT_VERIFY'
};
