import React from 'react';
import OnboardingStepsComponent from './onboarding-steps.component';

const checkingSummaryCompletion = (completedSteps, isT2SMerchant) => {
    delete completedSteps.summary;
    isT2SMerchant && (completedSteps.summary = Object.values(completedSteps).every(Boolean));
};
function OnboardingSteps(props) {
    return <OnboardingStepsComponent {...props} checkingSummaryCompletion={checkingSummaryCompletion} />;
}

export default OnboardingSteps;
