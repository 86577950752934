import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, OutlinedInput } from '@material-ui/core';
import { FormItem, Label } from '../../../onboarding/onboarding-ui-components';
import { useAdminFunctionsContext } from 'contexts';
import NumberFormat from 'react-number-format';
import styles from './merchnat-store-id.module.scss';
import { checkEmptyArray } from 'validators/onboarding/onboarding-check-value.validator';

export default function MerchantStoreIdComponent(props) {
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const { indexId, onChange } = props;

    return (
        <FormItem>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Label>Merchant store id</Label>
                </Grid>
                <Grid item xs={6} sm={5} md={6}>
                    <NumberFormat
                        className={styles.inputMerchantStoreId}
                        customInput={OutlinedInput}
                        inputProps={{ inputMode: 'numeric' }}
                        pattern="\d*"
                        allowLeadingZeros={true}
                        decimalSeparator={false}
                        allowNegative={false}
                        isNumericString={true}
                        id="merchant-store-id-input"
                        value={checkEmptyArray(adminFunctions.merchantStoreIds)[indexId] ?? ''}
                        onValueChange={(values) => {
                            const { value } = values;
                            const data = [...adminFunctions.merchantStoreIds];
                            data[indexId] = value;
                            setAdminFunctions({
                                ...adminFunctions,
                                merchantStoreIds: data
                            });
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                        required
                    />
                </Grid>
                <Grid item xs={2} sm={3} md={2}>
                    <Button variant="contained" color="primary" onClick={onChange}>
                        Add
                    </Button>
                </Grid>
            </Grid>
        </FormItem>
    );
}

MerchantStoreIdComponent.propTypes = {
    indexId: PropTypes.number,
    onChange: PropTypes.func
};
