import React, { useState, useEffect } from 'react';
import OnboardingSummaryComponent from './onboarding-summary.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { DocumentsService, NonResellerMerchantService, AuthService } from 'services';
import { OnboardingFormStatus } from 'utils/enums/OnboardingFormStatus';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';
import { getFormattedDocumentsStatsForMerchant } from 'utils/helper';
import { formTypes } from 'utils/enums/FormTypes';
import { isTokenGoingToExpire } from 'utils/helper';

export default function OnboardingSummary(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { globalState, setRoute, setGlobalState } = useGlobalStateContext();
    const isT2SMerchant = globalState.isT2SMerchant;
    const [telephoneDisabled] = useState(true);
    const [isSoleTrader, setIsSoleTrader] = useState(false);
    const [csrfToken, setCSRFToken] = useState('');

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(formTypes.SUBMIT_ONBOARDING, globalState.merchant?.id);
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { businessTypeId } = onboardingForm.businessDetails;
        const fetchData = async () => {
            setFetchingData(true);
            if (isT2SMerchant) {
                await NonResellerMerchantService.completeOnboardingStep(
                    globalState.merchant?.id,
                    5,
                    {
                        businessDescriptions: [8],
                        isBusinessMakingProducts: false,
                        isStockSufficient: false,
                        formType: formTypes.SUBMIT_ONBOARDING
                    },
                    csrfToken
                );

                await NonResellerMerchantService.completeOnboardingStep(
                    globalState.merchant.id,
                    6,
                    {
                        transactionProfileData: {
                            isDepositsTaken: false,
                            isPrePayment: false,
                            isMotoPayment: false,
                            isMaxTicketApplied: false,
                            isAutoRenewTransactions: false,
                            companyTurnOverActual: 0
                        },
                        formType: formTypes.SUBMIT_ONBOARDING
                    },
                    csrfToken
                );

                const data = {
                    productsRequiredIds: [1, 5],
                    resellerId: globalState.reseller.id,
                    formType: formTypes.SUBMIT_ONBOARDING
                };
                await NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 7, data, csrfToken);
            }

            const formStatusRes = await NonResellerMerchantService.incompleteBoardingFormCheck(
                globalState.merchant?.id
            );
            const documentsResponse = await DocumentsService.listObjects(
                'merchant',
                globalState.merchant?.id,
                DocumentFlag.ONBOARDING
            );

            if (isT2SMerchant) {
            }

            const { formStatus } = formStatusRes.data;
            const checkStatus =
                formStatusRes.isSuccesfully && documentsResponse.isSuccesfully && onboardingForm.validOnboardingFrom;
            const checkResponse =
                formStatus === OnboardingFormStatus.IN_PROGRESS &&
                documentsResponse.data &&
                getFormattedDocumentsStatsForMerchant({
                    merchantBusinessTypeId: onboardingForm.businessDetails.businessTypeId,
                    merchantCountry: globalState.merchant?.country,
                    merchantDocuments: documentsResponse.data
                }).hasAllMandatoryDocsUploaded;

            const checkValues = checkStatus && checkResponse;
            setIsDisabled(!checkValues);
            setFetchingData(false);
        };

        if (onboardingForm.validOnboardingFrom && csrfToken) {
            fetchData();
        }
        if (BusinessTypeEnumId.Sole_Trader === parseInt(businessTypeId)) {
            setIsSoleTrader(true);
        }
        // eslint-disable-next-line
    }, [
        globalState.merchant,
        isT2SMerchant,
        onboardingForm.businessDetails.businessTypeId,
        setOnboardingForm,
        onboardingForm.validOnboardingFrom,
        csrfToken
    ]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                summary: onboardingForm.validOnboardingFrom
            }
        }));
    }, [onboardingForm.validOnboardingFrom, setOnboardingForm]);

    const onSubmit = async () => {
        setIsLoading(true);
        const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
        let latestCSRFToken = csrfToken;
        if (isCSRFGoingToExpire) {
            latestCSRFToken = await generateCSRFToken();
        }

        const response = await NonResellerMerchantService.completeOnboarding(globalState.merchant?.id, latestCSRFToken);
        if (response.isSuccesfully) {
            setShowModal(true);
        } else {
            // error
        }

        setIsLoading(false);
    };

    const onModalClose = () => {
        setGlobalState((globalState) => ({
            ...globalState,
            completedOnboarding: true
        }));
        setRoute('/home');
    };

    return (
        <OnboardingSummaryComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            isLoading={isLoading}
            fetchingData={fetchingData}
            showModal={showModal}
            onModalClose={onModalClose}
            telephoneDisabled={telephoneDisabled}
            isSoleTrader={isSoleTrader}
        />
    );
}
