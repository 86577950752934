import React, { useState, useEffect } from 'react';
import { AdminService } from 'services';
import MerchantVerificationCheckListComponent from './merchant-verification-checklist.component';
import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';

const MerchantVerificationCheckList = (props) => {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const {
        isMerchantVerificationCheckListOpen,
        setIsMerchantVerificationCheckListOpen,
        setMerchantVerificationAcceptRejectMessage,
        setMessageType,
        setShowMessage,
        setOrangeMessageStyle
    } = props;
    const [checkList, setCheckList] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isPep, setIsPep] = useState(true);
    const [notes, setNotes] = useState('');
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
    const [showSendANote, setShowSendANote] = useState(false);
    const [rejectDisableButton, setRejectDisableButton] = useState(false);
    const pepIndex = 6;
    const errorMessage = 'An error occurred. Try again !';

    useEffect(() => {
        const getCheckList = () => {
            const checkListPoints = [
                {
                    text: `1. The name on the Bank Statement matches the name on the Datman account.`,
                    checked: false
                },
                {
                    text: `2. The address on the Utility bills matches details provided on the account.`,
                    checked: false
                },
                {
                    text: `3. The personal identification document provided is genuine and matches the account.`,
                    checked: false
                },
                {
                    text: `4. The business actually exists on Companies house or Core for Irish payees if it's Limited company.`,
                    checked: false
                },
                {
                    text: `5. The business has an online presence if any.`,
                    checked: false
                },
                {
                    text: `6. Research to see if the individual appears on New article online, to see whether they are associated with any publicly reported criminal offences.`,
                    checked: false
                },
                {
                    text: `7. Do the Lexis Nexis (UK) or Veriphy (Ireland) checks confirm that the merchant is not recognized as a PEP?`,
                    checked: false
                },
                {
                    text: `8. I confirm that I have no connection with this merchant.`,
                    checked: false
                }
            ];
            setCheckList(checkListPoints);
            setIsPep(true);
            setNotes('');
            setSendButtonDisabled(false);
            setShowSendANote(false);
            setRejectDisableButton(false);
        };
        getCheckList();
    }, [isMerchantVerificationCheckListOpen]);

    useEffect(() => {
        if (showSendANote && isPep) {
            setShowSendANote(false);
            setRejectDisableButton(false);
        }
        // eslint-disable-next-line
    }, [isPep]);

    const toggleChecked = (index) => {
        const checkListPoints = [...checkList];
        checkListPoints[index].checked = !checkListPoints[index].checked;
        setCheckList(checkListPoints);
        if (index === pepIndex) {
            setIsPep(!isPep);
        }
    };

    useEffect(() => {
        const unchecked = checkList.find((checkListPoint) => !checkListPoint.checked);
        if (unchecked === undefined) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [checkList]);

    const updateMerchantData = async (merchantStatus) => {
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            status: merchantStatus,
            resellerId: globalState.reseller?.id,
            rejectThroughMerchantVerification: true
        });
        return response;
    };

    const sendMerchantRejectionMail = async (data) => {
        const updateMerchantDataResponse = await updateMerchantData(MerchantStatus.REJECTED);
        if (updateMerchantDataResponse?.isSuccesfully) {
            const response = await AdminService.adminRejectMerchantEmailNotification(data);
            setShowMessage(true);
            if (response.isSuccesfully) {
                setAdminFunctions({
                    ...adminFunctions,
                    accountStatus: MerchantAccountStatus.REJECTED
                });
                setOrangeMessageStyle(true);
                setMessageType('success');
                setMerchantVerificationAcceptRejectMessage('Account rejected and notified client');
            } else {
                setMessageType('error');
                setMerchantVerificationAcceptRejectMessage(errorMessage);
            }
        } else {
            setShowMessage(true);
            setMessageType('error');
            setMerchantVerificationAcceptRejectMessage(errorMessage);
        }
    };

    const rejectMerchant = async () => {
        if (!isPep) {
            setRejectDisableButton(true);
            setShowSendANote(true);
        } else {
            setLoading(true);
            const data = { resellerId: globalState.reseller.id, merchantId: globalState.merchant.id, isPep: isPep };
            await sendMerchantRejectionMail(data);
            setLoading(false);
            setIsMerchantVerificationCheckListOpen(false);
        }
    };

    const sendNotes = async () => {
        setLoading(true);
        const data = {
            merchantId: globalState.merchant?.id,
            resellerId: globalState.reseller?.id,
            isPep: isPep,
            notes: notes
        };
        setSendButtonDisabled(true);
        await sendMerchantRejectionMail(data);
        setLoading(false);
        setIsMerchantVerificationCheckListOpen(false);
    };

    const acceptMerchantAccountByAdmin = async () => {
        setLoading(true);
        const response = await updateMerchantData(MerchantStatus.ACTIVE);
        setShowMessage(true);
        if (response.isSuccesfully) {
            setAdminFunctions({
                ...adminFunctions,
                accountStatus: MerchantAccountStatus.ACTIVE
            });
            setMessageType('success');
            setMerchantVerificationAcceptRejectMessage('Approved account successfully !');
        } else {
            setMessageType('error');
            setMerchantVerificationAcceptRejectMessage(errorMessage);
        }
        setLoading(false);
        setIsMerchantVerificationCheckListOpen(false);
    };

    return (
        <MerchantVerificationCheckListComponent
            isMerchantVerificationCheckListOpen={isMerchantVerificationCheckListOpen}
            setIsMerchantVerificationCheckListOpen={setIsMerchantVerificationCheckListOpen}
            checkList={checkList}
            toggleChecked={toggleChecked}
            disabled={disabled}
            rejectMerchant={rejectMerchant}
            loading={loading}
            setMessageType={setMessageType}
            setMerchantVerificationAcceptRejectMessage={setMerchantVerificationAcceptRejectMessage}
            setShowMessage={setShowMessage}
            notes={notes}
            setNotes={setNotes}
            sendButtonDisabled={sendButtonDisabled}
            sendNotes={sendNotes}
            showSendANote={showSendANote}
            acceptMerchantAccountByAdmin={acceptMerchantAccountByAdmin}
            rejectDisableButton={rejectDisableButton}
        />
    );
};

export default MerchantVerificationCheckList;
