import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from './url-input-field.module.scss';
const ValidUrlInputComponent = (props) => {
    const {
        onChange,
        doValidation,
        showDomainValidationMessage,
        setShowDomainValidationMessage,
        value,
        inputProps,
        setIsFocused
    } = props;

    return (
        <div>
            <TextField
                className={styles.inputMessage}
                autoComplete="off"
                label="URL"
                inputProps={inputProps}
                variant="outlined"
                value={value}
                onChange={(e) => {
                    const url = e.target.value.replace(/\s\s+/g, '');
                    doValidation(url ? `https://${url}` : '');
                    onChange(url);
                    setShowDomainValidationMessage(false);
                }}
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" style={{ marginRight: '0px' }}>
                            https://
                        </InputAdornment>
                    )
                }}
            />
            {showDomainValidationMessage && (
                <div>
                    <Typography color="error" style={{ marginLeft: '10px', fontSize: '14px' }}>
                        Link is invalid
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default ValidUrlInputComponent;
