export const MerchantCountries = {
    UNITED_KINGDOM: 'United Kingdom',
    IRELAND: 'Ireland',
    AUSTRALIA: 'Australia',
    NEW_ZEALAND: 'New Zealand',
    CANADA: 'Canada',
    UNITED_STATES: 'United States',
    MEXICO: 'Mexico',
    ANGUILLA: 'Anguilla',
    INDIA: 'India',
    NIGERIA: 'Nigeria'
};

export const MerchantCountriesDialCode = {
    'United Kingdom': '+44',
    Ireland: '+353',
    Australia: '+61',
    'New Zealand': '+64',
    Canada: '+1',
    'United States': '+1',
    Mexico: '+52',
    India: '+91',
    Nigeria: '+234'
};
