import React from 'react';
import AntSwitchComponent from './ant-switch.component';

function AntSwitch(props) {
    return <AntSwitchComponent {...props}></AntSwitchComponent>;
}

AntSwitch.propTypes = {};

export default AntSwitch;
