//#region  Backen-API

const USER_SERVICE = process.env.REACT_APP_USER_SERVICE_ENDPOINT;
const AUTH_SERVICE = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT;
const CUSTOMER_SERVICE = process.env.REACT_APP_CUSTOMER_SERVICE_ENDPOINT;
const MERCHANTS_SERVICE = process.env.REACT_APP_MERCHANTS_SERVICE_ENDPOINT;
const SETTINGS_SERVICE = process.env.REACT_APP_SETTINGS_SERVICE_ENDPOINT;
const DOCUMENTS_SERVICE = process.env.REACT_APP_DOCUMENTS_SERVICE_ENDPOINT;
const TRANSACTIONS_ENDPOINT = process.env.REACT_APP_TRANSACTIONS_ENDPOINT;
const PAYMENTS_ENDPOINT = process.env.REACT_APP_PAYMENTS_ENDPOINT;
const VIRTUAL_TERMINAL_ENDPOINT = process.env.REACT_APP_VIRTUAL_TERMINAL;
const ADMIN_SERVICE = process.env.REACT_APP_ADMIN_SERVICE_ENDPOINT;
const NOTIFICATIONS_SERVICE = process.env.REACT_APP_NOTIFICATIONS_SERVICE_ENDPOINT;
const TAXONOMY_SERVICE = process.env.REACT_APP_TAXONOMY_SERVICE_ENDPOINT;
const ADYEN_SERVICE = process.env.REACT_APP_ADYEN_SERVICE_ENDPOINT;
const RESELLER_SERVICE = process.env.REACT_APP_RESELLER_SERVICE_ENDPOINT;
const DNA_SERVICE = process.env.REACT_APP_DNA_SERVICE_ENDPOINT;

export const ALIAS = process.env.REACT_APP_ALIAS;

export const UserServiceEndpoint = USER_SERVICE + '/' + ALIAS + '/api/v1/users';
export const AuthServiceEndpoint = AUTH_SERVICE + '/' + ALIAS + '/api/v1/auth';
export const CustomerServiceEndpoint = CUSTOMER_SERVICE + '/' + ALIAS + '/api/v1/customers';
export const NonResellerMerchantServiceEndpoint = MERCHANTS_SERVICE + '/' + ALIAS + '/api/v1/merchants';
export const NotificationsServiceEndpoint = NOTIFICATIONS_SERVICE + '/' + ALIAS + '/api/v1/notifications';

export const TransactionEndpoint = `${TRANSACTIONS_ENDPOINT}/${ALIAS}/api/v1/transactions`;
export const GetPaymentsEndpoint = `${PAYMENTS_ENDPOINT}/${ALIAS}/api/v1/payments`;
export const VirtualTerminalEndpoint = `${VIRTUAL_TERMINAL_ENDPOINT}/${ALIAS}/api/v1/virtual_terminal`;

export const DatmanAPIEndpoint = process.env.REACT_APP_DATMAN_API_ENDPOINT;

export const RefundPaymentEndpoint = `${GetPaymentsEndpoint}/sale/refund`;
export const InitaliseSaleEndpoint = `${VirtualTerminalEndpoint}/vt/sale/create`;

export const SettingsServiceEndpoint = SETTINGS_SERVICE + '/' + ALIAS + '/api/v1/settings';
export const DocumentServiceEndpoint = DOCUMENTS_SERVICE + '/' + ALIAS + '/api/v1/documents';
export const AdminServiceEndpoint = ADMIN_SERVICE + '/' + ALIAS + '/api/v1/admin';
export const TaxonomyServiceEndpoint = TAXONOMY_SERVICE + '/' + ALIAS + '/api/v1/taxonomy';
export const AdyenServiceEndpoint = ADYEN_SERVICE + '/' + ALIAS + '/api/v1/adyen';
export const DnaServiceEndpoint = DNA_SERVICE + '/' + ALIAS + '/api/v1/dna';
export const ResellerServiceEndpoint = RESELLER_SERVICE + '/' + ALIAS + '/api/v1/reseller';
export const WA_CHATBOT = process.env.REACT_APP_WA_CHATBOT;
export const WA_CHATBOT_VISIBILITY = process.env.REACT_APP_WA_CHATBOT_VISIBILITY;

//#endregion

//#region Amplify Config
//
//

export const Region = process.env.REACT_APP_REGION;
export const UserPollWebClientId = process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID;
export const UserPollId = process.env.REACT_APP_USER_POOL_ID;
export const IdentityPollId = process.env.REACT_APP_IDENTITY_POOL_ID;
export const Domain = process.env.REACT_APP_DOMAIN;

//#endregion

//#region Base constants

export const LoqateApiKey = process.env.REACT_APP_LOQATE_API_KEY;

//#endregion
export const CookieUserPValue = 'p_value';
export const threeDSRedirectUrl = 'https://r80k37vvl6.execute-api.us-east-1.amazonaws.com/dev/threeDSRedirectWeb';
export const DATMAN_API_BASE_URL = 'https://api.datmancrm.com/p2';
export const DATMAN_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjaGFudF9pZCI6NjYzMTYxNDI5LCJob3N0IjoidGVzdGluZy5jb20iLCJzY29wZXMiOlsiY2xpZW50Iiwid2l0aGRyYXcvIiwiYWNjb3VudF9iYWxhbmNlLyJdLCJpYXQiOjE2MDA5MzkyMjAsImV4cCI6MTYwMDk0MDEyMH0.VHWSc6Va2X6hjVxEOISO2g4GNpwri7IvQA-gduTjBGY';
export const CLEVERTAP_ID = '449-WKK-946Z';
export const CHATBOT_SCRIPT_ID = 'zsiqscript';
export const CHATBOT_DATA_HANDLE_ATTRIBUTE = 'datman';
export const CHATBOT_SCRIPT_URL = 'https://salesiq.zoho.in/widget';
export const PORTAL_API_BASE_URL = 'portal-api.datmancrm.com';
export const supportedLanguages = [
    { locale: 'en', name: 'English' },
    { locale: 'es-mx', name: 'Spanish' }
];

export const UNDERMAINTAINANCE = process.env.REACT_APP_MAINTAINANCE === 'true' ? true : false;
export const AdminLogoutTime = process.env.REACT_APP_TIME_LIMIT_ADMIN;

export const REACT_APP_DOWNLOAD_LINK = process.env.REACT_APP_DOWNLOAD_LINK;

// Specifying the cutoff date for archiving data in the card_payment table.
export const REACT_APP_CARD_PAYMENT_ARCHIVE_DATE = process.env.REACT_APP_CARD_PAYMENT_ARCHIVE_DATE;

export const REACT_APP_ERROR_IMAGE = process.env.REACT_APP_ERROR_IMAGE;

export const REACT_APP_SIGUP_EXPIRY_TIME = process.env.REACT_APP_SIGUP_EXPIRY_TIME;

export const REACT_APP_SIGNUP_LINK_REACTIVATION_PERIOD = process.env.REACT_APP_SIGNUP_LINK_REACTIVATION_PERIOD;

export const REACT_APP_TERMS_AND_CONDITION_URL = process.env.REACT_APP_TERMS_AND_CONDITION_URL;
