import React from 'react';
import styles from './signup-form.module.scss';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { PasswordValidation, SnackBarAndAlert, ValidEmailInput, PasswordInput, CustomLoadingScreen } from 'components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { REACT_APP_ERROR_IMAGE } from 'utils/constants';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
function SignUpComponent(props) {
    const { getRoute, globalState, setRoute } = useGlobalStateContext();
    const {
        onSubmit,
        email,
        setEmail,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        forceChangePassword,
        phone,
        setPhone,
        isT2SUser,
        setIsPasswordValid,
        isPasswordValid,
        setPhoneCountryIdentificator,
        redoPasswordValidation,
        setRedoPasswordValidation,
        verificationCode,
        setVerificationCode,
        isLinkExpired,
        loader,
        snackbar,
        setSnackbar,
        sendOTPFunction,
        isButtonDisabled,
        countdown,
        isSubmitTriggered
    } = props;

    const useStyles = makeStyles((theme) => ({
        disabledInput: {
            color: theme.palette.text.primary,
            backgroundColor: 'Gainsboro',
            border: '1px solid LightGray',
            borderRadius: '5px',
            fontWeight: '600',
            userSelect: 'none'
        }
    }));
    const inputStyle = {
        backgroundColor: '#f0f0f0', // Your desired background color
        borderRadius: '4px',
        border: '1px solid #ccc'
    };

    const classes = useStyles();

    return (
        <div className={styles.signUpContainer}>
            {loader ? (
                <div className={styles.loader}>
                    <CustomLoadingScreen
                        open={true}
                        message={
                            isSubmitTriggered
                                ? 'Hi, thanks for signing up. Now we need to get some more detailed information before your account can be reviewed by our team.'
                                : ''
                        }
                    />
                </div>
            ) : isLinkExpired ? (
                <div className={styles.expiryPageContainer}>
                    <div className={styles.leftContainer}>
                        <img src={globalState.reseller?.logo} alt="Logo" />
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.rightContainerData}>
                            <div className={styles.circle}>
                                <img src={REACT_APP_ERROR_IMAGE} alt="Image inside circle" />
                            </div>
                            <h4>Sign-up link has expired</h4>
                            <h6>
                                For assistance, please reach out to our support team at{' '}
                                <span>
                                    <a href="mailto:info@datman.je">info@datman.je</a>.
                                </span>
                            </h6>
                        </div>
                        <div className={styles.footer}>
                            <h6 onClick={() => setRoute('/terms-and-conditions')}>Terms and conditions</h6>
                            <h5>© Datman. All rights reserved</h5>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={styles.homeLogo}>
                        <img src={globalState.reseller?.logo} alt="Logo" />
                    </div>
                    <div className={styles.signUpForm}>
                        <Form onSubmit={onSubmit}>
                            <Grid container spacing={2} className={styles.gridContainer}>
                                <Grid item xs={12} sm={6}>
                                    <Form.Label>First name*</Form.Label>
                                    <Form.Control
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        placeholder="First name"
                                        autoFocus={true}
                                        disabled
                                        className={styles.formControl}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Form.Label>Last name*</Form.Label>
                                    <Form.Control
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        placeholder="Last name"
                                        disabled={true}
                                        className={styles.formControl}
                                    />
                                </Grid>
                                <Grid item xs={13} container spacing={2} alignItems="flex-end">
                                    <Grid item xs={8}>
                                        <ValidEmailInput
                                            labelText="Email address*"
                                            labelId="email-address*"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase().replace(/ /g, ''))}
                                            onKeyPress={(e) => {
                                                if (e.key === ' ') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            required
                                            readOnly={forceChangePassword}
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            disabled
                                            classes={{ disabled: classes.disabledInput }}
                                            noClassname={true}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            className={
                                                !isButtonDisabled ? styles.otpButtonEnabled : styles.otpButtonDisabled
                                            }
                                            id="getOtpBtn"
                                            color="primary"
                                            variant="contained"
                                            disabled={isButtonDisabled}
                                            onClick={() => sendOTPFunction(email)}
                                            fullWidth
                                        >
                                            {isButtonDisabled ? `${countdown} s` : 'Get OTP'}
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Form.Label>Phone number *</Form.Label>
                                    <div className={styles.getOtpButton}>
                                        <div className={styles.flexContainer}>
                                            <PhoneInput
                                                value={phone}
                                                placeholder={'Phone'}
                                                alwaysDefaultMask={true}
                                                onChange={(phone, data) => {
                                                    setPhone(phone);
                                                    setPhoneCountryIdentificator(data.dialCode);
                                                }}
                                                inputClass={styles.phoneInput}
                                                inputStyle={inputStyle}
                                                inputProps={{ required: isT2SUser }}
                                                name="phone"
                                                countryCodeEditable={false}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Form.Label>Verification Code *</Form.Label>
                                    <Form.Control
                                        value={verificationCode}
                                        placeholder={'Verification Code'}
                                        alwaysDefaultMask={true}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numbers = value.replace(/[^0-9]/g, '');
                                            setVerificationCode(numbers);
                                        }}
                                        inputClass={styles.phoneInput}
                                        name="otp"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Form.Label>Password*</Form.Label>
                                    <Grid container className={styles.validaion__messages}>
                                        <PasswordValidation
                                            honorCurrentPassword={false}
                                            newPassword={password}
                                            confirmPassword={confirmPassword}
                                            setIsPasswordValid={setIsPasswordValid}
                                            redoPasswordValidation={redoPasswordValidation}
                                            email={email}
                                            firstName={firstName}
                                            lastName={lastName}
                                        />
                                    </Grid>
                                    <PasswordInput
                                        password={password}
                                        onChange={(value) => {
                                            setPassword(value);
                                            setRedoPasswordValidation(true);
                                        }}
                                        required
                                        minLength="8"
                                        pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
                                        title="Password must contain at least 8 characters including a number and a letter"
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Form.Label>Confirm password*</Form.Label>
                                    <PasswordInput
                                        required
                                        password={confirmPassword}
                                        onChange={(value) => {
                                            setConfirmPassword(value);
                                            setRedoPasswordValidation(true);
                                        }}
                                        type="password"
                                        placeholder="Confirm password"
                                    />
                                </Grid>
                            </Grid>
                            <SnackBarAndAlert
                                open={snackbar.message}
                                onClose={() => setSnackbar({ type: '', message: '' })}
                                type={snackbar.type}
                            >
                                {snackbar.message}
                            </SnackBarAndAlert>

                            <div className={styles.signUpFormButton}>
                                <Button
                                    className={
                                        isPasswordValid && verificationCode && !loader
                                            ? styles.signUpButton
                                            : styles.signUpButtonDisabled
                                    }
                                    id="signUpSubmitBtn"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!(isPasswordValid && verificationCode && !loader)}
                                >
                                    Complete verification
                                </Button>
                            </div>
                            <Form.Group className={styles.linkToSignUp}>
                                <Form.Label>
                                    Do you already have an account? <Link to={getRoute(`/login`)}>Log in</Link>
                                </Form.Label>
                            </Form.Group>
                        </Form>
                    </div>
                </>
            )}
        </div>
    );
}

SignUpComponent.propTypes = {
    onSubmit: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    password: PropTypes.string,
    messages: PropTypes.object,
    setPassword: PropTypes.func,
    confirmPassword: PropTypes.string,
    setConfirmPassword: PropTypes.func,
    verifyEmail: PropTypes.bool,
    setVerifyEmail: PropTypes.func,
    firstName: PropTypes.string,
    setFirstName: PropTypes.func,
    lastName: PropTypes.string,
    setLastName: PropTypes.func,
    isLoading: PropTypes.bool,
    forceChangePassword: PropTypes.bool,
    oldPassword: PropTypes.string,
    setOldPassword: PropTypes.func,
    successSignupInvite: PropTypes.bool,
    setSuccessSignupInvite: PropTypes.func,
    isUserInvited: PropTypes.bool,
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    isT2SUser: PropTypes.bool,
    isPasswordValid: PropTypes.bool,
    setIsPasswordValid: PropTypes.func,
    isPhoneValid: PropTypes.bool,
    setPhoneCountryIdentificator: PropTypes.string,
    redoPasswordValidation: PropTypes.bool
};

export default SignUpComponent;
