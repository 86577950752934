import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { Button, Dialog, Grid, TextField, Tooltip, Paper, Divider } from '@material-ui/core';
import { Alert as MaterialAlert } from '@material-ui/lab';
import styles from './dialog-view-documents.module.scss';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { UserType } from 'utils/enums/UserType';
import ReactCodeInput from 'react-verification-code-input';
import { useMediaQuery, Typography } from '@material-ui/core';
import { BankSortCodeNumberContainer, SnackBarAndAlert } from 'components';
import _ from 'lodash';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { getArrayFromString, validateCardNumber } from 'validators/onboarding/onboarding-check-value.validator';
import { Input, Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import { CheckMerchantCountries } from '../../../../../components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries.js';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { DocumentReviewStatus, DocumentStatusToName } from 'utils/enums/DocumentReviewStatus';
import { DocumentStatusColor } from 'utils/enums/DocumentStatusColors';
import { CountryBankAccountNumberLength } from 'utils/enums/CountryBankAccountNumberLength';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import RejectedReason from '../../rejected-reason/rejected-reason.container';
import { FormatMessage } from 'utils/languageTranslater';
export default function DialogViewDocumentsComponent(props) {
    const {
        isLoading,
        isModalOpen,
        setIsModalOpen,
        notes,
        sendButtonDisabled,
        accountBtnDisabled,
        bankAccountBtnDisabled,
        setNotes,
        sendNotes,
        sendNotesError,
        verifyAccount,
        verifyBankAccount,
        prevDisabled,
        nextDisabled,
        navPrev,
        navNext,
        documentToView,
        sendButtonText,
        accountBtnText,
        bankAccountBtnText,
        currentDocumentNumber,
        nrOfDocuments,
        setIsMerchantVerificationCheckListOpen,
        setNewBankDetailsConfirmation,
        newBankDetailsConfirmation,
        bankData,
        accNumberInputRef,
        bankDetailsValidation,
        codeValidationMessage,
        accNumberValidationMessage,
        approveBtnDisabled,
        handleSubmit,
        updatedBankData,
        downloadDocument,
        updateDocumentStatus,
        showAcceptReject,
        showRestore,
        isBankUpdateRequest,
        setBankUpdateRejected,
        bankUpdateRejected,
        disableNewFields,
        hideDelete,
        showMerchantVerification,
        message,
        setMessage,
        messageType,
        showMessage,
        setShowMessage,
        openInNewTab,
        finalVal,
        FormatNumber,
        transitNumberMsg,
        bankName,
        bankNameValidation,
        bankNameFieldOnChange,
        bankNameFieldOnBlur,
        bankNameFieldOnKeyDown,
        showNotesBlock,
        setShowNotesBlock,
        fetchRejectReasonLoader,
        reset,
        idNumber,
        setIdNumber,
        updateIdNumber,
        cardNumber,
        setCardNumber,
        updateCardNumber,
        isValidIdNumber,
        isValidCardNumber
    } = props;
    const { globalState } = useGlobalStateContext();
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const mediaLessThan750px = useMediaQuery('(max-width:749px)');
    const mediaLessThan400px = useMediaQuery('(max-width:399px)');
    const isAusIdNum =
        (documentToView?.documentTypeName === 'Driving licence' || documentToView?.documentTypeName === 'Passport') &&
        globalState?.merchant?.country === MerchantCountries.AUSTRALIA;
    const isUSDoc =
        globalState?.merchant?.country === MerchantCountries.UNITED_STATES &&
        documentToView?.documentTypeName === 'Driving licence';

    return (
        <Dialog
            open={isModalOpen}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={() => {
                reset();
                setIsModalOpen(false);
            }}
            maxWidth="xl"
            fullWidth
            className={styles.dialogWrapper}
        >
            <div className={styles.dialogWrapper__dialogBody}>
                <Grid
                    container
                    xs={12}
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={styles.dialogWrapper__dialogBody__titleBar}
                >
                    <Grid item xs={11} md={11} container>
                        <span className={styles.dialogWrapper__dialogBody__titleBar__merchantName}>
                            {'<'}
                            {documentToView.flag}
                            {'>'}
                        </span>

                        <span className={styles.dialogWrapper__dialogBody__titleBar__fileName}>
                            {'<'}
                            {documentToView?.filename ? `${documentToView?.filename}.` : 'File name'}
                            {'>'}
                            {'('}
                            {documentToView?.documentTypeName
                                ? `${documentToView?.documentTypeName}`
                                : 'Unknown document type'}
                            {')'}
                        </span>
                        <span style={DocumentStatusColor[documentToView.documentStatus]}>
                            {DocumentReviewStatus[documentToView.documentStatus]}
                        </span>

                        <span className={styles.dialogWrapper__dialogBody__titleBar__documentNumber}>
                            {currentDocumentNumber + 1} of {nrOfDocuments}
                        </span>
                        {isAusIdNum || isUSDoc ? (
                            <span className={styles.submitButton}>
                                <Input
                                    placeholder={FormatMessage({
                                        id: 'upload-document-id-number',
                                        text: 'ID Number'
                                    })}
                                    id="upload-document-id-number"
                                    inputProps={{ maxLength: isAusIdNum ? 11 : 15 }}
                                    value={idNumber}
                                    onChange={(e) => {
                                        if (isAusIdNum) {
                                            let value = /^[a-zA-Z0-9]{0,10}$/.test(e.target.value);
                                            if (value) {
                                                setIdNumber(e.target.value.toUpperCase());
                                            }
                                        } else {
                                            let value = /^[0-9]{0,15}$/.test(e.target.value);
                                            if (value) {
                                                setIdNumber(e.target.value.toUpperCase());
                                            }
                                        }
                                    }}
                                ></Input>
                                <Button
                                    onClick={() => {
                                        updateIdNumber();
                                    }}
                                    style={{ fontSize: '12px' }}
                                    disabled={documentToView.documentStatus != 'NEED_APPROVAL' || !isValidIdNumber}
                                >
                                    {'Submit id no'}
                                </Button>
                            </span>
                        ) : (
                            <div></div>
                        )}

                        <Grid container xs={1} md={1} justify="space-evenly" alignItems="center">
                            <a
                                href
                                id="document_url"
                                onClick={() => openInNewTab(documentToView)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.dialogWrapper__dialogBody__titleBar__merchantName__link}
                            >
                                open
                            </a>

                            <GetAppOutlinedIcon style={{ cursor: 'pointer' }} onClick={downloadDocument} />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Button
                            onClick={() => {
                                reset();
                                setIsModalOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                    {documentToView?.documentTypeName === 'Driving licence' &&
                    globalState?.merchant?.country === MerchantCountries.AUSTRALIA ? (
                        <span className={styles.cardNumber}>
                            <Input
                                placeholder={FormatMessage({
                                    id: 'upload-document-card-number',
                                    text: 'Card number'
                                })}
                                id="upload-document-card-number"
                                value={cardNumber}
                                inputProps={{ maxLength: 11 }}
                                onChange={(e) => {
                                    let value = /^[a-zA-Z0-9]{0,10}$/.test(e.target.value);
                                    if (value) {
                                        setCardNumber(e.target.value.toUpperCase());
                                    }
                                }}
                            ></Input>
                            <Button
                                onClick={() => {
                                    updateCardNumber();
                                }}
                                style={{ fontSize: '12px', borderColor: 'black', borderWidth: 1 }}
                                disabled={documentToView.documentStatus != 'NEED_APPROVAL' || !isValidCardNumber}
                            >
                                {'Submit card no'}
                            </Button>
                        </span>
                    ) : (
                        <div></div>
                    )}
                </Grid>
                <Grid
                    container
                    xs={12}
                    alignItems="center"
                    alignContent="center"
                    className={styles.dialogWrapper__dialogBody__imageWrapper}
                >
                    <Grid item xs={1} className={styles.dialogWrapper__dialogBody__imageWrapper__navArrow}>
                        <Button
                            fullWidth
                            onClick={navPrev}
                            disabled={prevDisabled}
                            className={styles.dialogWrapper__dialogBody__imageWrapper__navArrow__button}
                        >
                            <ArrowBackIosIcon />
                        </Button>
                    </Grid>

                    {updatedBankData.approvalStatus === 'WAITING_APPROVAL' &&
                        documentToView.flag === DocumentFlag.BANK_VERIFY &&
                        documentToView.documentStatus === DocumentStatusToName.NEED_APPROVAL && (
                            <Grid item xs={3} sm={3}>
                                <Grid container xs={12} direction="row" justify="flex-start" alignItems="center">
                                    <Paper className={styles.accountPaper}>
                                        <Grid container xs={12} alignItems="center" justify="center">
                                            <Grid className={styles.dialogWrapper__dialogBody__titleBar__fileName}>
                                                {!_.isEmpty(bankData) && (
                                                    <React.Fragment>
                                                        <FormItem>
                                                            <Label text="Name on account" id="name-on-account" />
                                                            <Input
                                                                value={bankData.accountHolderName}
                                                                disabled
                                                                placeholder="Name on account"
                                                                id="name-on-account"
                                                            />
                                                        </FormItem>
                                                        <CheckMerchantCountries
                                                            includedCountries={[
                                                                MerchantCountries.CANADA,
                                                                MerchantCountries.AUSTRALIA,
                                                                MerchantCountries.UNITED_STATES,
                                                                MerchantCountries.UNITED_KINGDOM,
                                                                MerchantCountries.MEXICO
                                                            ]}
                                                        >
                                                            <FormItem>
                                                                <Label text="Account number" id="account-number" />
                                                                <Input
                                                                    value={bankData.newAccountNumber}
                                                                    disabled
                                                                    placeholder="Account number"
                                                                    id="account-number-input"
                                                                />
                                                            </FormItem>
                                                        </CheckMerchantCountries>
                                                        <CheckMerchantCountries
                                                            includedCountries={MerchantCountries.NEW_ZEALAND}
                                                        >
                                                            <FormItem>
                                                                <Label text="Account number" id="account-number" />
                                                                <Input
                                                                    value={finalVal}
                                                                    disabled
                                                                    placeholder="Account number"
                                                                    id="account-number-input"
                                                                />
                                                            </FormItem>
                                                        </CheckMerchantCountries>
                                                        <CheckMerchantCountries
                                                            includedCountries={[MerchantCountries.IRELAND]}
                                                        >
                                                            <FormItem>
                                                                <Label text="Bank IBAN" id="bank-IBAN-ireland-label" />
                                                                <Input
                                                                    className={
                                                                        styles.codeInput + ' ' + styles.codeInputIreland
                                                                    }
                                                                    fieldHeight={
                                                                        (!mediaLessThan600px && mediaLessThan750px) ||
                                                                        mediaLessThan400px
                                                                            ? 22
                                                                            : 25
                                                                    }
                                                                    fieldWidth={
                                                                        (!mediaLessThan600px && mediaLessThan750px) ||
                                                                        mediaLessThan400px
                                                                            ? 22
                                                                            : 25
                                                                    }
                                                                    autoFocus={false}
                                                                    fields={22}
                                                                    type="text"
                                                                    disabled
                                                                    value={bankData.newAccountNumber}
                                                                />
                                                                <Grid></Grid>
                                                            </FormItem>
                                                        </CheckMerchantCountries>

                                                        <CheckMerchantCountries
                                                            includedCountries={[MerchantCountries.AUSTRALIA]}
                                                        >
                                                            <FormItem>
                                                                <Label text="BSB" id="bsb-australia-label" />
                                                                <ReactCodeInput
                                                                    values={getArrayFromString(bankData.bsb)}
                                                                    className={styles.codeInput}
                                                                    fieldHeight={mediaLessThan600px ? 30 : 34}
                                                                    fieldWidth={mediaLessThan600px ? 30 : 34}
                                                                    autoFocus={false}
                                                                    fields={6}
                                                                    type="string"
                                                                    pattern="\d*"
                                                                    disabled
                                                                    style={{ backgroundColor: 'gainsboro' }}
                                                                    inputStyle={{ backgroundColor: 'gainsboro' }}
                                                                />
                                                            </FormItem>
                                                        </CheckMerchantCountries>
                                                        <CheckMerchantCountries
                                                            includedCountries={[MerchantCountries.UNITED_STATES]}
                                                        >
                                                            <FormItem>
                                                                <Label text="Routing number" id="routing-number" />
                                                                <ReactCodeInput
                                                                    values={bankData.routingNumber}
                                                                    className={styles.codeInput}
                                                                    fieldHeight={
                                                                        mediaLessThan600px
                                                                            ? mediaLessThan400px
                                                                                ? 23
                                                                                : 30
                                                                            : 34
                                                                    }
                                                                    fieldWidth={
                                                                        mediaLessThan600px
                                                                            ? mediaLessThan400px
                                                                                ? 23
                                                                                : 30
                                                                            : 34
                                                                    }
                                                                    autoFocus={false}
                                                                    fields={9}
                                                                    type="string"
                                                                    pattern="\d*"
                                                                    disabled
                                                                />
                                                            </FormItem>
                                                        </CheckMerchantCountries>
                                                        <CheckMerchantCountries
                                                            includedCountries={[MerchantCountries.CANADA]}
                                                        >
                                                            <FormItem>
                                                                <Label text="Transit number" id="transit-number" />
                                                                <ReactCodeInput
                                                                    values={bankData.transitNumber}
                                                                    className={styles.codeInput}
                                                                    fieldHeight={mediaLessThan600px ? 30 : 34}
                                                                    fieldWidth={mediaLessThan600px ? 30 : 34}
                                                                    autoFocus={false}
                                                                    fields={5}
                                                                    type="string"
                                                                    pattern="\d*"
                                                                    disabled
                                                                />
                                                            </FormItem>
                                                            <FormItem>
                                                                <Label
                                                                    text="Financial institution number"
                                                                    id="financial-institution-number"
                                                                />
                                                                <ReactCodeInput
                                                                    values={bankData.financialInstitutionNumber}
                                                                    className={styles.codeInput}
                                                                    fieldHeight={mediaLessThan600px ? 30 : 34}
                                                                    fieldWidth={mediaLessThan600px ? 30 : 34}
                                                                    autoFocus={false}
                                                                    fields={3}
                                                                    type="string"
                                                                    pattern="\d*"
                                                                    disabled
                                                                />
                                                            </FormItem>
                                                        </CheckMerchantCountries>
                                                        <CheckMerchantCountries
                                                            includedCountries={[MerchantCountries.UNITED_KINGDOM]}
                                                        >
                                                            <FormItem>
                                                                <Label text="Sort code" id="bank-sort-code" />
                                                                <BankSortCodeNumberContainer
                                                                    hideDashes={mediaLessThan600px}
                                                                    values={bankData.sortCode}
                                                                    disabled={true}
                                                                />
                                                            </FormItem>
                                                        </CheckMerchantCountries>
                                                    </React.Fragment>
                                                )}
                                                <CheckMerchantCountries
                                                    includedCountries={[
                                                        MerchantCountries.CANADA,
                                                        MerchantCountries.AUSTRALIA,
                                                        MerchantCountries.UNITED_STATES,
                                                        MerchantCountries.UNITED_KINGDOM,
                                                        MerchantCountries.MEXICO
                                                    ]}
                                                >
                                                    <FormItem>
                                                        <Label text="New account number" id="new-account-number" />
                                                        <Input
                                                            value={newBankDetailsConfirmation.newAccountNumber}
                                                            placeholder="Account number"
                                                            id="-new-account-number-inpt"
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target.value.length <=
                                                                    CountryBankAccountNumberLength[
                                                                        globalState.merchant?.country
                                                                    ]
                                                                ) {
                                                                    bankDetailsValidation(
                                                                        e.target.value,
                                                                        'accountNumber'
                                                                    );
                                                                    setNewBankDetailsConfirmation({
                                                                        ...newBankDetailsConfirmation,
                                                                        newAccountNumber: e.target.value
                                                                    });
                                                                }
                                                            }}
                                                            disabled={disableNewFields}
                                                            autoFocus={true}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {accNumberValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {accNumberValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>
                                                <CheckMerchantCountries
                                                    includedCountries={[MerchantCountries.NEW_ZEALAND]}
                                                >
                                                    <FormItem>
                                                        <Label text="New account number" id="new-account-number" />
                                                        <Input
                                                            onKeyDown={(e) =>
                                                                e.keyCode !== 45 &&
                                                                e.keyCode > 31 &&
                                                                (e.keyCode < 48 || e.keyCode > 57) &&
                                                                e.preventDefault()
                                                            }
                                                            pattern="\d+"
                                                            //type="number"
                                                            value={FormatNumber()}
                                                            placeholder="Account number"
                                                            id="new-account-number-inpt"
                                                            inputProps={{
                                                                inputMode: 'numeric'
                                                            }}
                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 18) {
                                                                    bankDetailsValidation(
                                                                        e.target.value,
                                                                        'accountNumber'
                                                                    );
                                                                    setNewBankDetailsConfirmation({
                                                                        ...newBankDetailsConfirmation,
                                                                        newAccountNumber: e.target.value
                                                                            .split('-')
                                                                            .join('')
                                                                    });
                                                                }
                                                            }}
                                                            disabled={disableNewFields}
                                                            autoFocus={true}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {accNumberValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {accNumberValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>
                                                <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                                                    <FormItem>
                                                        <Label text="New bank IBAN" id="new-bank-IBAN-ireland-label" />
                                                        <Input
                                                            id="new-bank-IBAN-input"
                                                            className={styles.codeInput + ' ' + styles.codeInputIreland}
                                                            fieldHeight={
                                                                (!mediaLessThan600px && mediaLessThan750px) ||
                                                                mediaLessThan400px
                                                                    ? 22
                                                                    : 25
                                                            }
                                                            fieldWidth={
                                                                (!mediaLessThan600px && mediaLessThan750px) ||
                                                                mediaLessThan400px
                                                                    ? 22
                                                                    : 25
                                                            }
                                                            autoFocus={true}
                                                            fields={22}
                                                            type="text"
                                                            disabled={disableNewFields}
                                                            value={
                                                                newBankDetailsConfirmation.newAccountNumber.length > 2
                                                                    ? newBankDetailsConfirmation.newAccountNumber
                                                                    : 'IE'
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target.value.length >= 2 &&
                                                                    e.target.value.length <=
                                                                        CountryBankAccountNumberLength[
                                                                            globalState.merchant?.country
                                                                        ]
                                                                ) {
                                                                    bankDetailsValidation(
                                                                        e.target.value,
                                                                        'accountNumber'
                                                                    );
                                                                    setNewBankDetailsConfirmation({
                                                                        ...newBankDetailsConfirmation,
                                                                        newAccountNumber: e.target.value
                                                                    });
                                                                }
                                                                if (
                                                                    e.target.value.length ===
                                                                    CountryBankAccountNumberLength[
                                                                        globalState.merchant?.country
                                                                    ]
                                                                ) {
                                                                    document
                                                                        .getElementById('new-bank-IBAN-input')
                                                                        .blur();
                                                                }
                                                            }}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {accNumberValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {accNumberValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>

                                                <CheckMerchantCountries
                                                    includedCountries={[MerchantCountries.AUSTRALIA]}
                                                >
                                                    <FormItem>
                                                        <Label text="New BSB" id="bsb-australia-label" />
                                                        <ReactCodeInput
                                                            className={styles.codeInput}
                                                            fieldHeight={mediaLessThan600px ? 30 : 34}
                                                            fieldWidth={mediaLessThan600px ? 30 : 34}
                                                            disabled={disableNewFields}
                                                            fields={6}
                                                            type="number"
                                                            pattern="\d*"
                                                            values={getArrayFromString(
                                                                newBankDetailsConfirmation.newBsbCode
                                                            )}
                                                            onChange={(value) => {
                                                                bankDetailsValidation(value, 'bsbcode');
                                                                setNewBankDetailsConfirmation({
                                                                    ...newBankDetailsConfirmation,
                                                                    newBsbCode: value
                                                                });
                                                            }}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {codeValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {codeValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>
                                                <CheckMerchantCountries
                                                    includedCountries={[MerchantCountries.UNITED_STATES]}
                                                >
                                                    <FormItem>
                                                        <Label text="New routing number" id="new-routing-number" />
                                                        <ReactCodeInput
                                                            className={styles.codeInput}
                                                            fieldHeight={
                                                                mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34
                                                            }
                                                            fieldWidth={
                                                                mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34
                                                            }
                                                            autoFocus={false}
                                                            fields={9}
                                                            type="number"
                                                            pattern="\d*"
                                                            values={getArrayFromString(
                                                                newBankDetailsConfirmation.routingNumber
                                                            )}
                                                            onChange={(value) => {
                                                                bankDetailsValidation(value, 'routingnumber');
                                                                setNewBankDetailsConfirmation({
                                                                    ...newBankDetailsConfirmation,
                                                                    routingNumber: value
                                                                });
                                                            }}
                                                            disabled={disableNewFields}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {codeValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {codeValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>
                                                <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                                                    <FormItem>
                                                        <Label text="New transit number" id="new-transit-number" />
                                                        <ReactCodeInput
                                                            className={styles.codeInput}
                                                            fieldHeight={mediaLessThan600px ? 30 : 34}
                                                            fieldWidth={mediaLessThan600px ? 30 : 34}
                                                            autoFocus={false}
                                                            fields={5}
                                                            type="number"
                                                            pattern="\d*"
                                                            values={getArrayFromString(
                                                                newBankDetailsConfirmation.transitNumber
                                                            )}
                                                            onChange={(value) => {
                                                                bankDetailsValidation(value, 'transitnumber');
                                                                setNewBankDetailsConfirmation({
                                                                    ...newBankDetailsConfirmation,
                                                                    transitNumber: value
                                                                });
                                                            }}
                                                            disabled={disableNewFields}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {transitNumberMsg && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {transitNumberMsg}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>

                                                    <FormItem>
                                                        <Label
                                                            text="New financial institution number"
                                                            id="new-financial-institution-number"
                                                        />
                                                        <ReactCodeInput
                                                            className={styles.codeInput}
                                                            fieldHeight={mediaLessThan600px ? 30 : 34}
                                                            fieldWidth={mediaLessThan600px ? 30 : 34}
                                                            autoFocus={false}
                                                            fields={3}
                                                            type="number"
                                                            pattern="\d*"
                                                            values={getArrayFromString(
                                                                newBankDetailsConfirmation.financialInstitutionNumber
                                                            )}
                                                            onChange={(value) => {
                                                                bankDetailsValidation(value, 'financenumber');
                                                                setNewBankDetailsConfirmation({
                                                                    ...newBankDetailsConfirmation,
                                                                    financialInstitutionNumber: value
                                                                });
                                                            }}
                                                            disabled={disableNewFields}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {codeValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {codeValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>
                                                <CheckMerchantCountries
                                                    includedCountries={[MerchantCountries.UNITED_KINGDOM]}
                                                >
                                                    <FormItem>
                                                        <Label text="New bank sort code" id="new-bank-sort-code" />
                                                        <BankSortCodeNumberContainer
                                                            hideDashes={mediaLessThan600px}
                                                            values={newBankDetailsConfirmation.newSortCode}
                                                            nextElementRef={accNumberInputRef}
                                                            onChange={(value) => {
                                                                bankDetailsValidation(value, 'sortcode');
                                                                setNewBankDetailsConfirmation({
                                                                    ...newBankDetailsConfirmation,
                                                                    newSortCode: value
                                                                });
                                                            }}
                                                            disabled={disableNewFields}
                                                        />
                                                    </FormItem>
                                                    <Grid>
                                                        {codeValidationMessage && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {codeValidationMessage}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </CheckMerchantCountries>
                                                <FormItem>
                                                    <Label text="Name of bank" id="name-of-bank" />
                                                    <Input
                                                        inputProps={{ className: styles.holderName }}
                                                        placeholder="Name of bank"
                                                        id="name-of-bank-input"
                                                        value={bankName}
                                                        onChange={(e) => {
                                                            bankNameFieldOnChange(e);
                                                        }}
                                                        onBlur={() => {
                                                            bankNameFieldOnBlur();
                                                        }}
                                                        onKeyDown={(e) => {
                                                            bankNameFieldOnKeyDown(e);
                                                        }}
                                                    />
                                                    <Grid>
                                                        {bankNameValidation && (
                                                            <div className={styles.errorMessageContainer}>
                                                                <Typography
                                                                    color="error"
                                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                                >
                                                                    {bankNameValidation}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </FormItem>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        )}

                    <Grid
                        item
                        xs={
                            updatedBankData.approvalStatus === 'WAITING_APPROVAL' &&
                            documentToView.flag === DocumentFlag.BANK_VERIFY &&
                            documentToView.documentStatus === DocumentStatusToName.NEED_APPROVAL
                                ? '7'
                                : '10'
                        }
                        className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer}
                    >
                        {isLoading && (
                            <div>
                                <p className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer__text}>
                                    Admin document viewer
                                </p>
                                <p className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer__loading}>
                                    Loading...
                                </p>
                            </div>
                        )}
                        {!isLoading && nrOfDocuments === 0 && (
                            <div>
                                <p className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer__text}>
                                    Admin document viewer
                                </p>
                                <p className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer__loading}>
                                    Nothing to show
                                </p>
                            </div>
                        )}
                        {!isLoading && nrOfDocuments !== 0 && documentToView.extension !== 'pdf' && (
                            <div className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer__imageDiv}>
                                <img src={documentToView.url} alt="document view" className={styles.imageStyle} />
                            </div>
                        )}
                        {!isLoading && nrOfDocuments !== 0 && documentToView.extension === 'pdf' && (
                            <iframe
                                src={documentToView.url}
                                title="Web viewer"
                                frameBorder="0"
                                // width="500"
                                // height="600"
                                className={styles.dialogWrapper__dialogBody__imageWrapper__imageContainer__iframe}
                            />
                        )}
                    </Grid>
                    <Grid item xs={1} className={styles.dialogWrapper__dialogBody__imageWrapper__navArrow}>
                        <Button
                            fullWidth
                            onClick={navNext}
                            disabled={nextDisabled}
                            className={styles.dialogWrapper__dialogBody__imageWrapper__navArrow__button}
                        >
                            <ArrowForwardIosIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify="space-between"
                    xs={12}
                    sm={12}
                    alignItems="center"
                    className={styles.dialogWrapper__dialogBody__actionsWrapper}
                >
                    {(!isBankUpdateRequest ||
                        (documentToView.flag === DocumentFlag.BANK_VERIFY && !isBankUpdateRequest)) && (
                        <React.Fragment>
                            <Grid item xs={4} sm={4} container justify="space-evenly" alignItems="center">
                                {showAcceptReject && (
                                    <React.Fragment>
                                        <Grid item xs={3} sm={3} container justify="space-evenly" alignItems="center">
                                            <CheckCircleOutlinedIcon style={{ color: '#74D58F' }} />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ fontSize: '12px' }}
                                                onClick={() => {
                                                    updateDocumentStatus(DocumentStatusToName.ACTIVE);
                                                    if (
                                                        documentToView.docTypeId !== 12 &&
                                                        documentToView.docTypeId !== 13
                                                    ) {
                                                        sendNotes(DocumentStatusToName.ACTIVE);
                                                    }
                                                }}
                                                disabled={
                                                    documentToView.documentStatus !==
                                                        DocumentStatusToName.NEED_APPROVAL ||
                                                    globalState.merchant.status === MerchantStatus.WATCHLIST
                                                }
                                            >
                                                Accept
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3} sm={3} container justify="space-evenly" alignItems="center">
                                            <ThumbDownOutlinedIcon style={{ color: '#FFCCCB ' }} />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ fontSize: '12px' }}
                                                onClick={() => {
                                                    updateDocumentStatus(DocumentStatusToName.REJECTED);
                                                    if (
                                                        documentToView.docTypeId !== 12 &&
                                                        documentToView.docTypeId !== 13
                                                    ) {
                                                        sendNotes(DocumentStatusToName.REJECTED);
                                                    }
                                                }}
                                                disabled={
                                                    documentToView.documentStatus !==
                                                        DocumentStatusToName.NEED_APPROVAL ||
                                                    notes?.trim().length < 10 ||
                                                    sendButtonDisabled
                                                }
                                            >
                                                Reject
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {showRestore && (
                                    <Grid item xs={3} sm={3} container justify="space-evenly" alignItems="center">
                                        <RestoreOutlinedIcon style={{ color: '#7092BE' }} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ fontSize: '12px' }}
                                            onClick={() => updateDocumentStatus(DocumentStatusToName.NEED_APPROVAL)}
                                            disabled={
                                                documentToView.documentStatus !== DocumentStatusToName.OLD &&
                                                documentToView.documentStatus !== DocumentStatusToName.DELETED
                                            }
                                        >
                                            Restore
                                        </Button>
                                    </Grid>
                                )}

                                {!hideDelete && (
                                    <Grid item xs={3} sm={3} container justify="space-evenly" alignItems="center">
                                        <DeleteOutlineOutlinedIcon style={{ color: '#C7BFE7' }} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ fontSize: '12px' }}
                                            onClick={() => updateDocumentStatus(DocumentStatusToName.DELETED)}
                                            disabled={
                                                documentToView.documentStatus === DocumentStatusToName.DELETED ||
                                                documentToView.documentStatus === DocumentStatusToName.ACTIVE ||
                                                documentToView.documentStatus === DocumentStatusToName.NEED_APPROVAL ||
                                                documentToView.documentStatus === DocumentStatusToName.REJECTED
                                            }
                                        >
                                            Delete
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                        </React.Fragment>
                    )}
                    <Grid item xs={isBankUpdateRequest ? '12' : '7'} sm={isBankUpdateRequest ? '12' : '7'}>
                        <Grid item container justify={isBankUpdateRequest ? 'center' : 'space-between'} xs={12} sm={12}>
                            {updatedBankData.approvalStatus === 'WAITING_APPROVAL' &&
                            documentToView.flag === DocumentFlag.BANK_VERIFY &&
                            documentToView.documentStatus === DocumentStatusToName.NEED_APPROVAL ? (
                                <Grid item container justify="space-around" xs={8} sm={8}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={bankUpdateRejected || notes?.trim().length < 10}
                                        onClick={() => {
                                            setBankUpdateRejected(true);
                                            sendNotes(DocumentStatusToName.REJECTED, true);
                                        }}
                                        className={styles.dialogWrapper__dialogBody__actionsWrapper__button}
                                    >
                                        Reject Bank Update
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={(event) => {
                                            handleSubmit(event);
                                            sendNotes(DocumentStatusToName.ACTIVE);
                                        }}
                                        disabled={
                                            approveBtnDisabled ||
                                            globalState.merchant.status === MerchantStatus.WATCHLIST
                                        }
                                        className={styles.dialogWrapper__dialogBody__actionsWrapper__button}
                                    >
                                        Approve Bank Update
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item container>
                                    <Grid item xs={12} sm={12} container justify="space-between">
                                        {!isReseller && (
                                            <React.Fragment>
                                                <Tooltip title="It will set account to verified!">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={verifyAccount}
                                                        disabled={
                                                            accountBtnDisabled ||
                                                            globalState.merchant.status === MerchantStatus.WATCHLIST
                                                        }
                                                        className={
                                                            styles.dialogWrapper__dialogBody__actionsWrapper__button
                                                        }
                                                    >
                                                        {accountBtnText}
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="It will set bank account to verified!">
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={verifyBankAccount}
                                                        disabled={
                                                            bankAccountBtnDisabled ||
                                                            globalState.merchant.status === MerchantStatus.WATCHLIST
                                                        }
                                                        className={
                                                            styles.dialogWrapper__dialogBody__actionsWrapper__button
                                                        }
                                                    >
                                                        {bankAccountBtnText}
                                                    </Button>
                                                </Tooltip>
                                            </React.Fragment>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                reset();
                                                setIsModalOpen(false);
                                                if (
                                                    documentToView.flag === DocumentFlag.ONBOARDING &&
                                                    (documentToView.documentStatus === DocumentStatusToName.REJECTED ||
                                                        documentToView.documentStatus ===
                                                            DocumentStatusToName.ACTIVE) &&
                                                    showMerchantVerification
                                                ) {
                                                    setIsMerchantVerificationCheckListOpen(true);
                                                }
                                            }}
                                            className={styles.dialogWrapper__dialogBody__actionsWrapper__button}
                                        >
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {!isReseller && (
                    <Grid
                        container
                        direction="column"
                        sm={12}
                        md={8}
                        lg={6}
                        className={styles.dialogWrapper__dialogBody__notesWrapper}
                    >
                        {documentToView.documentStatus === DocumentStatusToName.REJECTED ? (
                            !fetchRejectReasonLoader && (
                                <Typography variant="h8" style={{ color: 'black' }}>
                                    Rejected reason: {notes ? notes : 'No reason provided'}
                                </Typography>
                            )
                        ) : (
                            <>
                                <RejectedReason onChange={setNotes} setShowNotesBlock={setShowNotesBlock} />
                                {showNotesBlock ? (
                                    <TextField
                                        label="Rejection reason"
                                        id="send-not-text-field"
                                        placeholder="Enter the Rejection Reason"
                                        multiline
                                        inputProps={{ maxLength: 200 }}
                                        rows={3}
                                        variant="outlined"
                                        value={notes}
                                        required
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                ) : (
                                    <></>
                                )}
                                {/* <Tooltip title="This will send an email to the merchant owner.">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.dialogWrapper__dialogBody__notesWrapper__button}
                                    disabled={notes.trim().length < 1 || sendButtonDisabled}
                                    onClick={sendNotes}
                                >
                                    {sendButtonText}
                                </Button>
                            </Tooltip> */}
                                {sendNotesError && (
                                    <MaterialAlert
                                        severity="error"
                                        color="error"
                                        className={styles.dialogWrapper__dialogBody__notesWrapper__errorMsg}
                                    >
                                        An error was occurred when sending the email. Please contact support.
                                    </MaterialAlert>
                                )}
                            </>
                        )}
                    </Grid>
                )}
            </div>
            {showMessage && (
                <SnackBarAndAlert
                    open={showMessage}
                    onClose={() => {
                        setMessage('');
                        setShowMessage(false);
                    }}
                    type={messageType}
                >
                    {message}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={isLoading} />
        </Dialog>
    );
}

DialogViewDocumentsComponent.propTypes = {
    isLoading: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    setIsModalOpen: PropTypes.func,
    sendButtonDisabled: PropTypes.bool,
    sendButtonText: PropTypes.string,
    accountBtnText: PropTypes.string,
    accountBtnDisabled: PropTypes.bool,
    bankAccountBtnDisabled: PropTypes.bool,
    bankAccountBtnText: PropTypes.string,
    notes: PropTypes.string,
    setNotes: PropTypes.func,
    sendNotes: PropTypes.func,
    sendNotesError: PropTypes.bool,
    verifyAccount: PropTypes.func,
    verifyBankAccount: PropTypes.func,
    prevDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    merchantName: PropTypes.string,
    navPrev: PropTypes.func,
    navNext: PropTypes.func,
    documentToView: PropTypes.object,
    currentDocumentNumber: PropTypes.number,
    nrOfDocuments: PropTypes.number,
    setNewBankDetailsConfirmation: PropTypes.func,
    bankDetailsValidation: PropTypes.func,
    codeValidationMessage: PropTypes.string,
    accNumberValidationMessage: PropTypes.string,
    rejectBankUpdate: PropTypes.func,
    handleSubmit: PropTypes.func
};
