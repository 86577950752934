import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { BankRejectedReasons } from 'utils/enums/RejectReasons';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';

export default function RejectedReasonComponent(props) {
    const { onChange, setShowNotesBlock } = props;

    return (
        <div style={{ marginBottom: 12 }}>
            <FormItem>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <NativeSelect
                            input={
                                <Input
                                    id="transaction-refund-id"
                                    onChange={(e) => {
                                        console.log(
                                            'e.target.selectedIndex : ',
                                            e.target.selectedIndex,
                                            e.target.value
                                        );

                                        if (e.target.selectedIndex === 0) {
                                            onChange('');
                                            setShowNotesBlock(false);
                                        } else if (
                                            e.target.selectedIndex !==
                                            Object.keys(BankRejectedReasons).length - 1
                                        ) {
                                            onChange(e.target.value);
                                            setShowNotesBlock(false);
                                        } else {
                                            onChange('');
                                            setShowNotesBlock(true);
                                        }
                                    }}
                                />
                            }
                        >
                            {Object.keys(BankRejectedReasons).map((element) => (
                                <option
                                    itemID={`${BankRejectedReasons[element]}`}
                                    value={BankRejectedReasons[element]}
                                    key={`${element}`}
                                >
                                    {BankRejectedReasons[element]}
                                </option>
                            ))}
                        </NativeSelect>
                    </Grid>
                </Grid>
            </FormItem>
        </div>
    );
}

RejectedReasonComponent.propTypes = {
    onChange: PropTypes.func
};
