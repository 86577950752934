import React from 'react';
import AuthWithSocialMediaComponent from './auth-with-social-media.component';
import { Auth } from 'aws-amplify';
import { useGlobalStateContext } from 'contexts';

function AuthWithSocialMedia(props) {
    const { isT2SUser, thirdPartyResellerUrlParameter } = props;
    const { globalState } = useGlobalStateContext();

    const customState = isT2SUser
        ? `${globalState.reseller?.portalURL}&&&${thirdPartyResellerUrlParameter}`
        : globalState.reseller?.portalURL;

    const googleSignUp = () => {
        Auth.federatedSignIn({ provider: 'Google', customState: customState });
    };
    const facebookSignUp = () => {
        Auth.federatedSignIn({ provider: 'Facebook', customState: customState });
    };
    return <AuthWithSocialMediaComponent googleSignUp={googleSignUp} facebookSignUp={facebookSignUp} />;
}

export default AuthWithSocialMedia;
