import React from 'react';
import OnboardingTransactionProfileStepOneComponent from './onboarding-transaction-profile-step-one/onboarding-transaction-profile-step-one.container';
import OnboardingTransactionProfileStepTwoComponent from './onboarding-transaction-profile-step-two/onboarding-transaction-profile-step-two.container';
import OnboardingTransactionProfileStepThreeComponent from './onboarding-transaction-profile-step-three/onboarding-transaction-profile-step-three.container';
import { OnboardingTitle } from 'components/onboarding/onboarding-ui-components';
import PropTypes from 'prop-types';

// import styles from './onboarding-transaction-profile-steps-container.module.scss';

function OnboardingTransactionProfileComponent(props) {
    const { nextStep, subStep, nextSubStep, oneHundredValues } = props;

    const title = (
        <OnboardingTitle
            title="Transaction profile"
            subTitle="Please provide the transaction information"
        ></OnboardingTitle>
    );

    const GetSubStepComponent = () => {
        switch (subStep) {
            case 1:
                return (
                    <OnboardingTransactionProfileStepOneComponent
                        title={title}
                        nextStep={nextSubStep}
                        oneHundredValues={oneHundredValues}
                    />
                );
            case 2:
                return <OnboardingTransactionProfileStepTwoComponent title={title} nextStep={nextSubStep} />;
            case 3:
                return (
                    <OnboardingTransactionProfileStepThreeComponent
                        title={title}
                        nextStep={nextStep}
                        oneHundredValues={oneHundredValues}
                    />
                );
            default:
                return (
                    <OnboardingTransactionProfileStepOneComponent
                        title={title}
                        nextStep={nextSubStep}
                        oneHundredValues={oneHundredValues}
                    />
                );
        }
    };
    return <React.Fragment>{GetSubStepComponent()}</React.Fragment>;
}

OnboardingTransactionProfileComponent.propTypes = {
    nextStep: PropTypes.func,
    subStep: PropTypes.number,
    nextSubStep: PropTypes.func,
    oneHundredValues: PropTypes.array
};

export default OnboardingTransactionProfileComponent;
