import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalChargebackActionComponent from './modal-chargeback-list-action.component';
import { AdminService } from 'services';

export default function ModalChargebackAction(props) {
    const {
        chargebackStatus,
        setChargebackStatus,
        chargebackTransactionId,
        setOpenModal,
        setChargebackListStatus,
        chargebackListStatus,
        setSnackbarMessage,
        setSnackbarMessageType,
        setShowSnackbarStatus,
        defaultchargeback,
        showChargeback,
        setShowChargeback,
        chargebackExists
    } = props;

    const [disabled, setDisabled] = useState(true);
    const [chargebackDropdown, setChargebackDropdown] = useState('');
    const [chargebackDate, setChargebackDate] = useState(new Date().toISOString().slice(0, 10));
    const [chargebackComments, setChargebackComments] = useState('');
    const [chargebackOutcome, setChargebackOutcome] = useState('');
    const [chargebackBtn, setChargebackBtn] = useState(true);
    const [chargebackCreated, setChargebackCreated] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);

    const wrapperRef = useRef(null);

    let headCells = [
        {
            id: 'chargeBackAddedOn',
            numeric: false,
            disablePadding: false,
            label: 'Added On',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'reason',
            numeric: false,
            disablePadding: false,
            label: 'Reason',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'comments',
            numeric: false,
            disablePadding: false,
            label: 'Comments',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'outcome',
            numeric: false,
            disablePadding: false,
            label: 'Outcome',
            hideSortingIcon: true,
            alignCenter: true
        }
    ];
    const chargebackDetails = () => {
        setChargebackStatus({});
        setChargebackDropdown('');
        setChargebackDate(new Date().toISOString().slice(0, 10));
        setChargebackComments('');
        setChargebackOutcome('');
    };

    useEffect(() => {
        if (
            chargebackDropdown === '' ||
            chargebackDate === '' ||
            chargebackComments === '' ||
            chargebackOutcome === ''
        ) {
            setChargebackBtn(true);
        } else {
            setChargebackBtn(false);
        }
    }, [chargebackDropdown, chargebackDate, chargebackComments, chargebackOutcome]);
    const chargebackBtnHandler = async () => {
        setLoading(true);
        const response = await AdminService.UpdateChargebackList({
            transaction_id: chargebackTransactionId,
            date: chargebackDate,
            reason: chargebackDropdown,
            status: chargebackOutcome,
            comments: chargebackComments
        });
        if (response.isSuccesfully) {
            setLoading(true);
            setChargebackListStatus(response.status);
            setSnackbarMessage('New chargeback created successfully!');
            setOpenModal(false);
            setChargebackCreated(true);
            setSnackbarMessageType && setSnackbarMessageType('success');
        } else {
            setLoading(true);
            setChargebackListStatus('');
            setSnackbarMessage(response.data.message);
            setOpenModal(false);
            setChargebackCreated(false);
            setSnackbarMessageType && setSnackbarMessageType('error');
        }
        setShowSnackbarStatus(true);
        setChargebackStatus({});
        setLoading(false);
    };

    const showingReceiptData = (a, b) => {
        let response = a ? a : b ? b : <span>&mdash;</span>;
        if (response === 'NOTRESOLVED') {
            response = 'NOT RESOLVED';
        }
        return response;
    };

    return (
        <ModalChargebackActionComponent
            {...props}
            disabled={disabled}
            wrapperRef={wrapperRef}
            headCells={headCells}
            chargebackDropdown={chargebackDropdown}
            setChargebackDropdown={setChargebackDropdown}
            chargebackDate={chargebackDate}
            chargebackComments={chargebackComments}
            chargebackOutcome={chargebackOutcome}
            setChargebackDate={setChargebackDate}
            setChargebackComments={setChargebackComments}
            setChargebackOutcome={setChargebackOutcome}
            chargebackBtn={chargebackBtn}
            setChargebackBtn={setChargebackBtn}
            chargebackBtnHandler={chargebackBtnHandler}
            chargebackListStatus={chargebackListStatus}
            openSnackbar={openSnackbar}
            setOpenSnackbar={setOpenSnackbar}
            chargebackStatus={chargebackStatus}
            setChargebackStatus={setChargebackStatus}
            defaultchargeback={defaultchargeback}
            loading={loading}
            setDisabled={setDisabled}
            showChargeback={showChargeback}
            setShowChargeback={setShowChargeback}
            chargebackExists={chargebackExists}
            chargebackCreated={chargebackCreated}
            chargebackDetails={chargebackDetails}
            showingReceiptData={showingReceiptData}
            setChargebackCreated={setChargebackCreated}
        ></ModalChargebackActionComponent>
    );
}

ModalChargebackAction.propTypes = {
    setLoading: PropTypes.func,
    setShow: PropTypes.func,
    getTeamMembers: PropTypes.func,
    doValidation: PropTypes.func
};
