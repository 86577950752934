import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import { SearchUserForm } from 'components';
import styles from './home-item-details.module.scss';
import { ModalConfirmation } from 'components';
import { ModalClientCreation, ModalMerchantCreation } from 'components';
import { EntityType } from 'utils/enums/EntityType';
import { Permissions } from 'utils/enums/Permissions';
import { checkPermissions } from 'utils/roles-and-permissions';

function HomeItemDetailsComponent(props) {
    //#region Business region
    function renderBusinessDetails() {
        if (props.isUpdateDetailsMode) {
            return (
                <React.Fragment>
                    <Form.Control
                        className={styles.cardHeadertitleEdit}
                        type="text"
                        value={props.data.name}
                        onChange={props.updateBusinessNameHandler}
                        isValid={props.businessNameValidation.isValid}
                        isInvalid={!props.businessNameValidation.isValid}
                    ></Form.Control>
                    <Form.Text className="invalid-feedback">{props.businessNameValidation.message}</Form.Text>
                </React.Fragment>
            );
        } else {
            return <div className={styles.title + ' card-header-title'}>{props.data.name}</div>;
        }
    }

    function renderUpdateButtons() {
        if (props.isUpdateDetailsMode) {
            return (
                <Button
                    className="ml-2 btn-sm"
                    variant="success"
                    onClick={props.saveBusiness}
                    disabled={!props.businessNameValidation.isValid}
                >
                    Save
                </Button>
            );
        } else {
            return (
                <Button
                    className="ml-2 btn-sm"
                    variant="success"
                    onClick={() => props.setIsUpdateDetailsMode((state) => !state)}
                >
                    Edit
                </Button>
            );
        }
    }

    const renderBusiness = () => {
        return (
            <React.Fragment>
                {props.data && props.selectedElement && props.selectedElement.type === EntityType.BUSINESS ? (
                    <Card.Header className="d-flex justify-content-between">
                        <div className={styles.disableFlex}> {renderBusinessDetails()}</div>
                        <div className={styles.disableFlex}>
                            {props.data.id && (
                                <div>
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) && (
                                        <Button className="btn-sm btn-white" onClick={props.showClientModal}>
                                            Add Client
                                        </Button>
                                    )}
                                    {checkPermissions(props.selectedElement.role, Permissions.AssignUser) && (
                                        <Button
                                            className="btn-sm btn-white"
                                            onClick={() => props.setShowAssignUser(true)}
                                        >
                                            Assign a user to business
                                        </Button>
                                    )}
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) &&
                                        renderUpdateButtons()}
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) && (
                                        <Button
                                            className="ml-2 btn-sm"
                                            variant="warning"
                                            onClick={() => props.setIsShowDeleteWarning((state) => !state)}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </Card.Header>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    const renderBusinessModal = () => {
        return (
            <React.Fragment>
                {props.data && props.selectedElement && props.selectedElement.type === EntityType.BUSINESS ? (
                    <React.Fragment>
                        <ModalConfirmation
                            show={props.isShowDeleteWarning}
                            setShow={props.setIsShowDeleteWarning}
                            onSubmit={props.deleteBusiness}
                        >
                            {`Are you sure you want to delete business ${props.data.name}?`}
                        </ModalConfirmation>

                        <SearchUserForm
                            show={props.showAssignUser}
                            setShow={props.setShowAssignUser}
                            businessId={props.data.id}
                            clientId={null}
                            merchantId={null}
                        />

                        {/* userId: selectedUser.id,
                businessId,
                clientId,
                merchantId,
                roleId: selectedRole */}

                        <ModalClientCreation
                            showModal={props.isClientModalOpen}
                            hideModal={props.showClientModal}
                            businessId={props.data.id}
                            setUpdateWorkItems={props.setUpdateWorkItems}
                        />
                    </React.Fragment>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    //#endregion

    //#region Client
    function renderClientDetails() {
        if (props.isUpdateDetailsMode) {
            return (
                <React.Fragment>
                    <Form.Control
                        className={styles.cardHeadertitleEdit}
                        type="text"
                        value={props.data.name}
                        onChange={props.updateClientNameHandler}
                        isValid={props.clientNameValidation.isValid}
                        isInvalid={!props.clientNameValidation.isValid}
                    ></Form.Control>
                    <Form.Text className="invalid-feedback">{props.clientNameValidation.message}</Form.Text>
                </React.Fragment>
            );
        } else {
            return <div className={styles.title + ' card-header-title'}>{props.data.name}</div>;
        }
    }

    function renderClientUpdateButtons() {
        if (props.isUpdateDetailsMode) {
            return (
                <Button
                    className="ml-2 btn-sm"
                    variant="success"
                    onClick={props.saveClient}
                    disabled={!props.clientNameValidation.isValid}
                >
                    Save
                </Button>
            );
        } else {
            return (
                <Button
                    className="ml-2 btn-sm"
                    variant="success"
                    onClick={() => props.setIsUpdateDetailsMode((state) => !state)}
                >
                    Edit
                </Button>
            );
        }
    }

    const renderClient = () => {
        return (
            <React.Fragment>
                {props.data && props.selectedElement && props.selectedElement.type === EntityType.CLIENT ? (
                    <Card.Header className="d-flex justify-content-between">
                        <div className={styles.disableFlex}> {renderClientDetails()}</div>
                        <div className={styles.disableFlex}>
                            {props.data.id && (
                                <div>
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) && (
                                        <Button className="btn-sm btn-white" onClick={props.showMerchantModal}>
                                            Add merchant
                                        </Button>
                                    )}
                                    {checkPermissions(props.selectedElement.role, Permissions.AssignUser) && (
                                        <Button
                                            className="btn-sm btn-white"
                                            onClick={() => props.setShowAssignUser(true)}
                                        >
                                            Assign a user to client
                                        </Button>
                                    )}
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) &&
                                        renderClientUpdateButtons()}
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) && (
                                        <Button
                                            className="ml-2 btn-sm"
                                            variant="warning"
                                            onClick={() => props.setIsShowDeleteWarningClient((state) => !state)}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </Card.Header>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    const renderClientModal = () => {
        return (
            <React.Fragment>
                {props.data && props.selectedElement && props.selectedElement.type === EntityType.CLIENT ? (
                    <React.Fragment>
                        <ModalConfirmation
                            show={props.isShowDeleteWarningClient}
                            setShow={props.setIsShowDeleteWarningClient}
                            onSubmit={props.deleteClient}
                        >
                            {`Are you sure you want to delete client ${props.data.name}?`}
                        </ModalConfirmation>

                        <SearchUserForm
                            show={props.showAssignUser}
                            setShow={props.setShowAssignUser}
                            businessId={null}
                            clientId={props.data.id}
                            merchantId={null}
                        />

                        <ModalMerchantCreation
                            showModal={props.isMerchantModalOpen}
                            hideModal={props.showMerchantModal}
                            businessId={props.data.businessId}
                            clientId={props.data.id}
                            setUpdateWorkItems={props.setUpdateWorkItems}
                        />
                    </React.Fragment>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    //#endregion

    //#region Client
    function renderMerchantDetails() {
        if (props.isUpdateDetailsMode) {
            return (
                <React.Fragment>
                    <Form.Control
                        className={styles.cardHeadertitleEdit}
                        type="text"
                        value={props.data.name}
                        onChange={props.updateMerchantNameHandler}
                        isValid={props.merchantNameValidation.isValid}
                        isInvalid={!props.merchantNameValidation.isValid}
                    ></Form.Control>
                    <Form.Text className="invalid-feedback">{props.merchantNameValidation.message}</Form.Text>
                </React.Fragment>
            );
        } else {
            return <div className={styles.title + ' card-header-title'}>{props.data.name}</div>;
        }
    }

    function renderMerchantUpdateButtons() {
        if (props.isUpdateDetailsMode) {
            return (
                <Button
                    className="ml-2 btn-sm"
                    variant="success"
                    onClick={props.saveMerchant}
                    disabled={!props.merchantNameValidation.isValid}
                >
                    Save
                </Button>
            );
        } else {
            return (
                <Button
                    className="ml-2 btn-sm"
                    variant="success"
                    onClick={() => props.setIsUpdateDetailsMode((state) => !state)}
                >
                    Edit
                </Button>
            );
        }
    }

    const renderMerchant = () => {
        return (
            <React.Fragment>
                {props.data && props.selectedElement && props.selectedElement.type === EntityType.MERCHANT ? (
                    <Card.Header className="d-flex justify-content-between">
                        <div className={styles.disableFlex}> {renderMerchantDetails()}</div>
                        <div className={styles.disableFlex}>
                            {props.data.id && (
                                <div>
                                    {checkPermissions(props.selectedElement.role, Permissions.AssignUser) && (
                                        <Button
                                            className="btn-sm btn-white"
                                            onClick={() => props.setShowAssignUser(true)}
                                        >
                                            Assign a user to merchant
                                        </Button>
                                    )}
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) &&
                                        renderMerchantUpdateButtons()}
                                    {checkPermissions(props.selectedElement.role, Permissions.BusinessCRUD) && (
                                        <Button
                                            className="ml-2 btn-sm"
                                            variant="warning"
                                            onClick={() => props.setIsShowDeleteWarningMerchant((state) => !state)}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </Card.Header>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    const renderMerchantModal = () => {
        return (
            <React.Fragment>
                {props.data && props.selectedElement && props.selectedElement.type === EntityType.MERCHANT ? (
                    <React.Fragment>
                        <ModalConfirmation
                            show={props.isShowDeleteWarningMerchant}
                            setShow={props.setIsShowDeleteWarningMerchant}
                            onSubmit={props.deleteMerchant}
                        >
                            {`Are you sure you want to delete merchant ${props.data.name}?`}
                        </ModalConfirmation>
                        <SearchUserForm
                            show={props.showAssignUser}
                            setShow={props.setShowAssignUser}
                            businessId={null}
                            clientId={null}
                            merchantId={props.data.id}
                        />
                    </React.Fragment>
                ) : (
                    <div></div>
                )}
            </React.Fragment>
        );
    };

    //#endregion

    return (
        <React.Fragment>
            {props.data ? (
                <React.Fragment>
                    <Card className={styles.businessDetailsContainer}>
                        {renderBusiness()}
                        {renderClient()}
                        {renderMerchant()}
                        <Card.Body>
                            <Col>
                                <Row></Row>
                            </Col>
                        </Card.Body>
                    </Card>

                    {/* Separate Modal Code */}
                    {renderBusinessModal()}
                    {renderClientModal()}
                    {renderMerchantModal()}
                </React.Fragment>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
}

HomeItemDetailsComponent.propTypes = {
    data: PropTypes.object,
    assignEntityAndRoleToUser: PropTypes.func,
    isUpdateDetailsMode: PropTypes.bool,
    setIsUpdateDetailsMode: PropTypes.func,
    saveBusiness: PropTypes.func,
    updateBusinessNameHandler: PropTypes.func,
    businessNameValidation: PropTypes.object,
    setIsShowDeleteWarning: PropTypes.func,
    isShowDeleteWarning: PropTypes.bool,
    deleteBusiness: PropTypes.func,
    selectedElement: PropTypes.object,
    isClientModalOpen: PropTypes.bool,
    showClientModal: PropTypes.func,
    refreshClientList: PropTypes.func,
    setUpdateWorkItems: PropTypes.func,

    setIsShowDeleteWarningClient: PropTypes.func,
    isShowDeleteWarningClient: PropTypes.bool,
    updateClientNameHandler: PropTypes.func,
    clientNameValidation: PropTypes.object,
    saveClient: PropTypes.func,
    showMerchantModal: PropTypes.func,
    deleteClient: PropTypes.func,
    isMerchantModalOpen: PropTypes.bool,

    setIsShowDeleteWarningMerchant: PropTypes.func,
    isShowDeleteWarningMerchant: PropTypes.bool,
    updateMerchantNameHandler: PropTypes.func,
    merchantNameValidation: PropTypes.object,
    saveMerchant: PropTypes.func,
    deleteMerchant: PropTypes.func
};

export default HomeItemDetailsComponent;
