import React from 'react';
import style from './payment-qr-code-info.module.scss';
import { Typography, Container, Grid, Button, CircularProgress, useMediaQuery, Link } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SnackBarAndAlert } from 'components';
const PaymentQrCodeInfoComponent = (props) => {
    const {
        isLoading,
        qrImgSource,
        imageContentRef,
        handlePrint,
        paymentUrl,
        handleCopyPaymentUrl,
        isCopyAlertOpen,
        setIsCopyAlertOpen
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    return (
        <Container className={style.updateBankInfoContainer}>
            <div className={style.container}>
                <Grid container direction="column" wrap="nowrap">
                    <div className={style.inputs}>
                        <div className={style.pageTitle}>
                            <Typography color="primary" className={style.container_title}>
                                QR Code
                            </Typography>
                        </div>
                        {isLoading ? (
                            <div className={style.loading}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div id="divcontents" className={style.qrCode}>
                                <Grid
                                    container
                                    xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                    direction={mediaLessThan600px ? 'column' : 'row'}
                                    wrap="nowrap"
                                >
                                    <Grid
                                        className={style.imagecontents}
                                        id="QRCodeContainer"
                                        item
                                        xs={mediaLessThan600px ? 12 : 8}
                                    >
                                        <Grid>
                                            <img
                                                className={style.qrImage}
                                                id="qrCodeImage"
                                                src={qrImgSource}
                                                alt="Logo"
                                                ref={imageContentRef}
                                            ></img>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={mediaLessThan600px ? 12 : 8}
                                            container
                                            className={style.paymentUrl}
                                            justify="space-between"
                                        >
                                            <Grid item xs={10}>
                                                <Link
                                                    href={paymentUrl}
                                                    target="_blank"
                                                    className={style.textInput}
                                                    rel="noopener"
                                                >
                                                    {paymentUrl}
                                                </Link>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <FileCopyIcon
                                                    className={style.copyIcon}
                                                    onClick={handleCopyPaymentUrl}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={mediaLessThan600px ? 12 : 2}>
                                        <div className={style.btncontainer}>
                                            <Button
                                                onClick={handlePrint}
                                                className={style.qrBtn}
                                                variant="contained"
                                                color="primary"
                                            >
                                                &nbsp;Print &nbsp;
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                </Grid>
                {isCopyAlertOpen && (
                    <SnackBarAndAlert
                        open={isCopyAlertOpen}
                        onClose={() => {
                            setIsCopyAlertOpen(false);
                        }}
                        type={'success'}
                    >
                        Successfully copied payment url.
                    </SnackBarAndAlert>
                )}
            </div>
        </Container>
    );
};

export default PaymentQrCodeInfoComponent;
