import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, MenuItem, Checkbox, ListItemText, makeStyles } from '@material-ui/core';
import styles from './modal-acquirer-filter.module.scss';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        right: 190,
        top: 120
    }
});

function ModalAcquirerFilterComponent(props) {
    const { setAcquirerFilter, acquirerFilter, acquirerName, acquirer, handleAcquirerChange } = props;
    const classes = useStyles();

    return (
        <Dialog
            onClose={() => setAcquirerFilter(false)}
            aria-labelledby="customized-dialog-title"
            open={acquirerFilter}
            classes={{
                paper: classes.dialog
            }}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            {acquirerName?.map((name) => (
                <MenuItem
                    key={name}
                    value={name}
                    className={styles.menuItem}
                    onClick={(e) => handleAcquirerChange(name, e)}
                >
                    <Checkbox checked={acquirer?.indexOf(name) > -1} size="small" color="primary" />
                    <ListItemText>{name}</ListItemText>
                </MenuItem>
            ))}
        </Dialog>
    );
}

ModalAcquirerFilterComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.string
};

export default ModalAcquirerFilterComponent;
