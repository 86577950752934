import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Clear, Done } from '@material-ui/icons';
import styles from './password-validation.module.scss';
import PropTypes from 'prop-types';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const PasswordValidationComponent = (props) => {
    const {
        hasEightCharacters,
        // hasUpperCaseCharacters,
        // hasLowerCaseCharacters,
        //hasSpecialCharacters,
        hasDigitCharacters,
        newPasswordMatchesConfirmPassword,
        honorCurrentPassword,
        notSameAsCurrentPassword,
        newPasswordChange,
        redoPasswordValidation,
        userNameValidation
    } = props;

    return (
        <Grid container>
            <Grid item xs={1}>
                {redoPasswordValidation && !hasEightCharacters ? (
                    <Clear className={styles.crossIcon} />
                ) : hasEightCharacters ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 15 }} className={styles.bulletIcon} />
                )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer}>
                <Typography className={styles.validationText}>Must contain a minimum of 8 characters</Typography>
            </Grid>
            {/* <Grid item xs={1}>
                {hasUpperCaseCharacters ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                        <Clear className={styles.crossIcon} />
                    )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer}>
                <Typography className={styles.validationText}>Contain uppercase letters</Typography>
            </Grid>
            <Grid item xs={1}>
                {hasLowerCaseCharacters ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                        <Clear className={styles.crossIcon} />
                    )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer}>
                <Typography className={styles.validationText}>Contain lowercase letters</Typography>
            </Grid>
            <Grid item xs={1}>
                {hasSpecialCharacters ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                        <Clear className={styles.crossIcon} />
                    )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer}>
                <Typography className={styles.validationText}>Contain special characters</Typography>
            </Grid> */}
            {/* <Grid item xs={1} hidden={hasLowerCaseCharacters || hasUpperCaseCharacters}>
                {hasLowerCaseCharacters || hasUpperCaseCharacters ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                    <Clear className={styles.crossIcon} />
                )}
            </Grid>
            <Grid
                item
                xs={11}
                className={styles.validationTextContainer}
                hidden={hasLowerCaseCharacters || hasUpperCaseCharacters}
            >
                <Typography className={styles.validationText}>Contain letters</Typography>
            </Grid> */}

            <Grid item xs={1}>
                {redoPasswordValidation && !hasDigitCharacters ? (
                    <Clear className={styles.crossIcon} />
                ) : hasDigitCharacters ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 15 }} className={styles.bulletIcon} />
                )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer}>
                <Typography className={styles.validationText}>Must contain a minimum of 1 number(s)</Typography>
            </Grid>

            <Grid item xs={1}>
                {redoPasswordValidation && !userNameValidation ? (
                    <Clear className={styles.crossIcon} />
                ) : userNameValidation ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 15 }} className={styles.bulletIcon} />
                )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer}>
                <Typography className={styles.validationText}>
                    Password must not include username or email for security
                </Typography>
            </Grid>

            <Grid item xs={1} hidden={!honorCurrentPassword}>
                {redoPasswordValidation && !notSameAsCurrentPassword && !newPasswordChange ? (
                    <Clear className={styles.crossIcon} />
                ) : notSameAsCurrentPassword && newPasswordChange ? (
                    <Done className={styles.doneIcon}></Done>
                ) : (
                    <FiberManualRecordIcon style={{ fontSize: 15 }} className={styles.bulletIcon} />
                )}
            </Grid>
            <Grid item xs={11} className={styles.validationTextContainer} hidden={!honorCurrentPassword}>
                <Typography className={styles.validationText}>Must differ from the current password</Typography>
            </Grid>
            <Grid item xs={1}>
                {redoPasswordValidation && !newPasswordMatchesConfirmPassword ? (
                    <Clear className={styles.crossIcon} />
                ) : redoPasswordValidation && newPasswordMatchesConfirmPassword ? (
                    <Done className={styles.doneIcon}></Done>
                ) : null}
            </Grid>

            {redoPasswordValidation ? (
                <Grid item xs={11} className={styles.validationTextContainer}>
                    <Typography className={styles.validationText}> Confirmation password must match </Typography>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default PasswordValidationComponent;

PasswordValidationComponent.propTypes = {
    hasEightCharacters: PropTypes.bool.isRequired,
    hasUpperCaseCharacters: PropTypes.bool.isRequired,
    hasLowerCaseCharacters: PropTypes.bool.isRequired,
    //hasSpecialCharacters: PropTypes.bool.isRequired,
    hasDigitCharacters: PropTypes.bool.isRequired,
    newPasswordMatchesConfirmPassword: PropTypes.bool.isRequired,
    hideMatchConfirmPassword: PropTypes.bool.isRequired,
    honorCurrentPassword: PropTypes.bool.isRequired,
    notSameAsCurrentPassword: PropTypes.bool,
    redoPasswordValidation: PropTypes.bool,
    CrossIcon: PropTypes.bool
};
