import React, { useState } from 'react';
import InternalTransfersListTableComponent from './internal-transfers-list-table.component.jsx';
import PropTypes from 'prop-types';

const InternalTransfersListTable = (props) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [selected, setSelected] = useState([]);
    const [supplier, setSupplier] = useState();
    const [amount, setAmount] = useState();
    const [transactionId, setTransactionId] = useState();
    const [sender, setSender] = useState('');
    const [showCancelModel, setShowCancelModel] = useState(false);
    const [showRefundModel, setShowRefundModel] = useState(false);
    const { internalTransfers, loading, error, total, transferType, setRefresh, page, setPage } = props;

    const showCancelTransaction = async (transaction) => {
        setShowCancelModel(true);
        setSupplier(transaction.supplier);
        setAmount(transaction.amount);
        setTransactionId(transaction.id);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        setPage(0);
        if (event.target.checked) {
            const newSelecteds = internalTransfers.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const headCells = [
        { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
        {
            id: transferType === 'supplier' ? 'sender' : `supplier`,
            numeric: true,
            disablePadding: false,
            label: transferType === 'supplier' ? 'Sender' : `Supplier`
        },
        { id: 'created_at', numeric: false, disablePadding: false, label: 'Date' },
        { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'action', numeric: false, disablePadding: false, label: '' }
    ];

    const showRefundTransaction = async (transaction) => {
        setAmount(transaction.amount);
        setTransactionId(transaction.id);
        setSender(transaction.supplier);
        setShowRefundModel(true);
    };

    return (
        <InternalTransfersListTableComponent
            rows={internalTransfers}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            selected={selected}
            setSelected={setSelected}
            isSelected={isSelected}
            handleClick={handleClick}
            handleSelectAllClick={handleSelectAllClick}
            handleRequestSort={handleRequestSort}
            loading={loading}
            error={error}
            total={total}
            transferType={transferType}
            showCancelTransaction={showCancelTransaction}
            showCancelModel={showCancelModel}
            setShowCancelModel={setShowCancelModel}
            supplier={supplier}
            transactionId={transactionId}
            amount={amount}
            setRefresh={setRefresh}
            showRefundTransaction={showRefundTransaction}
            showRefundModel={showRefundModel}
            setShowRefundModel={setShowRefundModel}
            sender={sender}
            page={page}
            setPage={setPage}
        />
    );
};

export default InternalTransfersListTable;

InternalTransfersListTable.propTypes = {
    internalTransfers: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    total: PropTypes.string,
    transferType: PropTypes.string,
    setRefresh: PropTypes.func
};
