import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { useAdminFunctionsContext } from 'contexts';
import PaymentConfigurationModesDialog from './payment-configuration-mode-dialog/payment-configuration-modes-dialog.container';
import { LoadingScreen } from 'components';

export default function PaymentConfigurationModesComponent(props) {
    const { adminFunctions } = useAdminFunctionsContext();
    const {
        testModeUpdate,
        setTestModeUpdate,
        threeDSecureUpdate,
        setThreeDSecureUpdate,
        testModeUpdateDialogBox,
        setTestModeUpdateDialogBox,
        loading,
        setLoading,
        testModeStatus,
        setTestModeStatus,
        setThreeDSecureStatus,
        threeDSecureStatus
    } = props;

    return (
        <React.Fragment>
            <div>
                <FormItem>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={5}>
                            <Label text="Test Mode" id="test-mode"></Label>
                        </Grid>
                        <Grid item xs={5} md={4}>
                            <NativeSelect
                                input={
                                    <Input
                                        id="test-mode"
                                        value={adminFunctions.testMode ? 'true' : 'false'}
                                        onChange={(e) => {
                                            setThreeDSecureUpdate(false);
                                            setTestModeUpdateDialogBox(true);
                                            setTestModeUpdate(true);
                                            if (e.target.value === 'true') {
                                                setTestModeStatus(true);
                                            } else {
                                                setTestModeStatus(false);
                                            }
                                        }}
                                    ></Input>
                                }
                            >
                                <option value={true}>Y</option>
                                <option value={false}>N</option>
                            </NativeSelect>
                        </Grid>
                    </Grid>
                </FormItem>
            </div>
            <div>
                <FormItem>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={5}>
                            <Label text="3D Secure" id="3d-secure"></Label>
                        </Grid>
                        <Grid item xs={5} md={4}>
                            <NativeSelect
                                input={
                                    <Input
                                        id="owner-select-ownershiptype"
                                        value={adminFunctions.threeDSecure === false ? 'false' : 'true'}
                                        onChange={(e) => {
                                            //
                                            // setTestModeReject(false);
                                            setTestModeUpdate(false);
                                            setTestModeUpdateDialogBox(true);
                                            setThreeDSecureUpdate(true);
                                            if (e.target.value === 'true') {
                                                setThreeDSecureStatus(true);
                                            } else {
                                                setThreeDSecureStatus(false);
                                            }
                                        }}
                                    ></Input>
                                }
                            >
                                <option value={true}>Y</option>
                                <option value={false}>N</option>
                            </NativeSelect>
                        </Grid>
                    </Grid>
                </FormItem>
            </div>
            <PaymentConfigurationModesDialog
                testModeUpdate={testModeUpdate}
                setTestModeUpdate={setTestModeUpdate}
                threeDSecureUpdate={threeDSecureUpdate}
                setThreeDSecureUpdate={setThreeDSecureUpdate}
                testModeUpdateDialogBox={testModeUpdateDialogBox}
                setTestModeUpdateDialogBox={setTestModeUpdateDialogBox}
                setLoading={setLoading}
                testModeStatus={testModeStatus}
                threeDSecureStatus={threeDSecureStatus}
            />
            <LoadingScreen open={loading}></LoadingScreen>
        </React.Fragment>
    );
}

PaymentConfigurationModesComponent.propTypes = {
    onChange: PropTypes.func,
    testModeUpdate: PropTypes.bool,
    threeDSecureUpdate: PropTypes.bool,
    setTestModeUpdate: PropTypes.func,
    setThreeDSecureUpdate: PropTypes.func,
    testModeUpdateDialogBox: PropTypes.bool,
    setTestModeUpdateDialogBox: PropTypes.func,
    setLoading: PropTypes.func
};
