import React from 'react';
import PropTypes from 'prop-types';
import {
    OnboardingTitle,
    NextButton,
    OnboardingFormsContainer,
    CardMultiSelect
} from 'components/onboarding/onboarding-ui-components';

function ProductsRequiredComponent(props) {
    const { nextStep, onboardingForm, setOnboardingForm, isDisabled, onSubmit } = props;

    const title = (
        <OnboardingTitle title="Products required" subTitle="Please select products you require"></OnboardingTitle>
    );

    function formBody() {
        return (
            <React.Fragment>
                <CardMultiSelect
                    list={onboardingForm.productsRequired}
                    onChange={(value) =>
                        setOnboardingForm({
                            ...onboardingForm,
                            selectedProducts: value
                        })
                    }
                    value={onboardingForm.selectedProducts}
                />
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

ProductsRequiredComponent.propspropTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default ProductsRequiredComponent;
