import React from 'react';
import { PaymentsListTable } from '../transaction-tables';
import { SnackBarAndAlert } from 'components';

const PaymentsListComponent = (props) => {
    const {
        isRefundHappen,
        setIsRefundHappen,
        showAcquirer,
        selectedAcquirer,
        acquirerName,
        paymentVoidSnackbar,
        setPaymentVoidSnackbar,
        handleVoidTransaction,
        isDateRangeArchived
    } = props;
    return (
        <div>
            <PaymentsListTable
                {...props}
                showAcquirer={showAcquirer}
                selectedAcquirer={selectedAcquirer}
                acquirerName={acquirerName}
                handleVoidTransaction={handleVoidTransaction}
                isDateRangeArchived={isDateRangeArchived}
            />
            {isRefundHappen !== null && (
                <SnackBarAndAlert
                    open={true}
                    onClose={() => setIsRefundHappen(null)}
                    type={isRefundHappen ? 'success' : 'error'}
                >
                    {isRefundHappen
                        ? 'Your refund request has been sent successfully.'
                        : 'Your refund request has failed.'}
                </SnackBarAndAlert>
            )}
            {paymentVoidSnackbar.type !== '' && (
                <SnackBarAndAlert
                    open={true}
                    onClose={() => setPaymentVoidSnackbar({ type: '', message: '' })}
                    type={paymentVoidSnackbar.type}
                >
                    {paymentVoidSnackbar.message}
                </SnackBarAndAlert>
            )}
        </div>
    );
};

export default PaymentsListComponent;
