import React, { useState, useEffect } from 'react';
import OnboardingTransactionProfileStepOneComponent from './onboarding-transaction-profile-step-one.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';

function OnboardingTransactionProfileStepOne(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const {
            isDepositsTaken,
            goods,
            cardTurnover,
            depositFarDays,
            noDeliveryDays,
            isPrePayment,
            fullPrePayments,
            advanceFullPaymentsDays
        } = onboardingForm.transactionProfile.stepOne;
        const checkValuesForDepositsTaken =
            goods !== null && cardTurnover !== null && depositFarDays !== null && noDeliveryDays !== null;
        const checkValuesForPrePayment = fullPrePayments !== null && advanceFullPaymentsDays !== null;
        const checkValues =
            (isDepositsTaken && checkValuesForDepositsTaken && isPrePayment && checkValuesForPrePayment) ||
            (isDepositsTaken && checkValuesForDepositsTaken && !isPrePayment) ||
            (!isDepositsTaken && isPrePayment && checkValuesForPrePayment) ||
            (!isDepositsTaken && !isPrePayment);
        setIsDisabled(!checkValues);
    }, [onboardingForm.transactionProfile.stepOne]);

    const onSubmit = () => {
        var data = { ...onboardingForm.transactionProfile.stepOne };
        if (!onboardingForm.transactionProfile.stepOne.isDepositsTaken) {
            data.goods = 0;
            data.cardTurnover = 0;
            data.depositFarDays = 0;
            data.noDeliveryDays = 0;
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                transactionProfile: {
                    ...onboardingForm.transactionProfile,
                    stepOne: {
                        ...onboardingForm.transactionProfile.stepOne,
                        goods: 0,
                        cardTurnover: 0,
                        depositFarDays: 0,
                        noDeliveryDays: 0
                    }
                }
            }));
        }

        if (!onboardingForm.transactionProfile.stepOne.isPrePayment) {
            data.fullPrePayments = 0;
            data.advanceFullPaymentsDays = 0;
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                transactionProfile: {
                    ...onboardingForm.transactionProfile,
                    stepOne: {
                        ...onboardingForm.transactionProfile.stepOne,
                        fullPrePayments: 0,
                        advanceFullPaymentsDays: 0
                    }
                }
            }));
        }

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant.id, 6, {
            transactionProfileData: data
        });
    };

    return (
        <OnboardingTransactionProfileStepOneComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
        />
    );
}

export default OnboardingTransactionProfileStepOne;
