import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import InternalTransfersListComponent from './internal-transfers-list.component.jsx';

import { InternalTransfersService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { useBalanceContext } from 'contexts/balance-context/balance-context.js';

const InternalTransfersList = (props) => {
    const [error, setError] = useState(false);
    const [total, setTotal] = useState(0);
    const [internalTransfers, setInternalTransfers] = useState([]);
    const [transferType, setTransferType] = useState();
    const [refresh, setRefresh] = useState(false);
    const { globalState } = useGlobalStateContext();
    const { balance } = useBalanceContext();
    const [page, setPage] = useState(0);

    const { date, via } = props.match.params;
    const { setTotalPendingAmount, show, setEnableWithdrawal, loading, setLoading } = props;

    useEffect(() => {
        setPage(0);
        const getInternalTransfersList = async () => {
            const merchantId = globalState.merchant?.id;
            const day = moment(date, 'YYYY-MM-DD').date();
            const month = moment(date, 'YYYY-MM-DD').month() + 1;
            const year = moment(date, 'YYYY-MM-DD').year();
            const week = moment(date).isoWeek();

            const internalTransfersList = await InternalTransfersService.getInternalTransfersList({
                merchantId,
                day,
                month,
                year,
                week,
                via: via.toUpperCase()
            });

            if (internalTransfersList.isSuccesfully) {
                setInternalTransfers(internalTransfersList.data.data.internalTransfers);
                setTotalPendingAmount(internalTransfersList.data.data.totalPendingAmount);
                setTotal(internalTransfersList.data.data.total);
                setTransferType(internalTransfersList.data.data.transfer_type);
                setEnableWithdrawal(internalTransfersList.data.data.transfer_type === 'supplier' ? true : false);
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
            setRefresh(false);
        };
        if (!show || refresh) {
            setLoading(true);
            getInternalTransfersList();
        }
        // eslint-disable-next-line
    }, [
        props.location.pathname,
        date,
        via,
        globalState.merchant,
        setTotalPendingAmount,
        show,
        setEnableWithdrawal,
        refresh,
        balance
    ]);

    return (
        <InternalTransfersListComponent
            {...props}
            loading={loading}
            error={error}
            internalTransfers={internalTransfers}
            total={total}
            transferType={transferType}
            setRefresh={setRefresh}
            page={page}
            setPage={setPage}
        />
    );
};

export default InternalTransfersList;

InternalTransfersList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string.isRequired,
            via: PropTypes.string.isRequired
        })
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
    setTotalPendingAmount: PropTypes.func.isRequired,
    show: PropTypes.bool,
    setEnableWithdrawal: PropTypes.func
};
