import React from 'react';
import style from './admin-dashboard-navigation-header.module.scss';

function AdminDashBoardNavigationHeaderComponent(props) {
    const { header_title } = props;
    return (
        <div className={style.navigationBar}>
            <div className={style.navigationItem}>
                {props.children}
                <div className={style.navigationText}>{header_title}</div>
            </div>
        </div>
    );
}

AdminDashBoardNavigationHeaderComponent.propTypes = {};

export default AdminDashBoardNavigationHeaderComponent;
