import React from 'react';
import PropTypes from 'prop-types';
import styles from './create-urgent-message.module.scss';
import { Button, Grid, TextField, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { SnackBarAndAlert } from 'components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Input } from 'components/onboarding/onboarding-ui-components';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ValidUrlInput from '../url-input-field/url-input-field.container';

function CreateUrgentMessageComponent(props) {
    const {
        setShow,
        onSubmit,
        formItems,
        setFormItems,
        isDisable,
        error,
        setError,
        characterCount,
        setCharacterCount,
        setShowMid,
        showMid,
        onChange,
        setSelectedStartDate,
        setSelectedEndDate,
        dateError,
        showDomainValidationMessage,
        setShowDomainValidationMessage,
        setIsFocused
    } = props;
    return (
        <React.Fragment>
            <Grid container spacing={2} className={styles.formContainer}>
                <div className={styles.datePickerContainer}>
                    <div className={styles.startDatePick}>
                        <div>Start date:</div>
                        <div className={styles.datePicker}>
                            <Input
                                id="date-time-picker-inline"
                                name="startDate"
                                type="datetime-local"
                                inputVariant="outlined"
                                required={true}
                                disableToolbar={true}
                                onChange={(e) => {
                                    setSelectedStartDate(e.target.value);
                                    setFormItems({
                                        ...formItems,
                                        startDate: e.target.value
                                    });
                                }}
                                inputProps={{
                                    max: '2099-12-31',
                                    min: new Date().toISOString().slice(0, 16)
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.endDateDatePick}>
                        <div>End date:</div>
                        <div className={styles.datePicker}>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <Input
                                    id="date-time-picker-inline"
                                    name="endDate"
                                    type="datetime-local"
                                    inputVariant="outlined"
                                    required={true}
                                    disableToolbar={true}
                                    onChange={(e) => {
                                        setSelectedEndDate(e.target.value);
                                        setFormItems({
                                            ...formItems,
                                            endDate: e.target.value
                                        });
                                    }}
                                    inputProps={{
                                        max: '2099-12-31T23:59',
                                        min: new Date().toISOString().slice(0, 16)
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>

                    <div className={styles.checkBoxContainer}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={showMid}
                                    onChange={(e) => {
                                        setShowMid(e.target.checked);
                                    }}
                                />
                            }
                            label="Enter MID"
                        />
                    </div>
                </div>
                {dateError && (
                    <Grid item xs={12} sm={10}>
                        <div className={styles.notificationMessageContainer}>
                            <Typography color="error" style={{ marginTop: '-10px', fontSize: '14px' }}>
                                {dateError}
                            </Typography>
                        </div>
                    </Grid>
                )}

                {showMid && (
                    <Grid item xs={12} sm={10}>
                        <TextField
                            className={styles.tag_input}
                            value={formItems.merchantIds}
                            multiline
                            rows={4}
                            label="MID"
                            variant="outlined"
                            required
                            autoFocus
                            onChange={onChange}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={10}>
                    <TextField
                        className={styles.inputMessage}
                        value={formItems.subject}
                        label="Subject"
                        inputProps={{ maxLength: 50 }}
                        autoFocus
                        variant="outlined"
                        onChange={(e) => {
                            setFormItems({
                                ...formItems,
                                subject: e.target.value.replace(/\s\s+/g, '')
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={10} style={{ display: 'flex', gap: '10px' }}>
                    <Grid item xs={8} sm={8}>
                        <ValidUrlInput
                            labelText="URL"
                            labelId="website-url"
                            id="website-url-input"
                            value={formItems.url}
                            setIsFocused={setIsFocused}
                            onChange={(value) => {
                                setFormItems({
                                    ...formItems,
                                    url: value
                                });
                                if (value.length === 0) {
                                    setFormItems({
                                        ...formItems,
                                        btnText: '',
                                        url: value
                                    });
                                }
                            }}
                            inputProps={{ maxLength: '1024', inputMode: 'text' }}
                            showDomainValidationMessage={showDomainValidationMessage}
                            setShowDomainValidationMessage={setShowDomainValidationMessage}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            className={styles.inputMessage}
                            value={formItems.btnText}
                            label="Button Label"
                            inputProps={{ maxLength: 20 }}
                            variant="outlined"
                            disabled={formItems?.url?.length === 0}
                            onChange={(e) => {
                                setFormItems({
                                    ...formItems,
                                    btnText: e.target.value.replace(/\s\s+/g, '')
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        className={styles.inputMessage}
                        value={formItems.message}
                        label="Message"
                        inputProps={{ maxLength: 250 }}
                        multiline
                        required
                        rows={3}
                        variant="outlined"
                        onChange={(e) =>
                            setFormItems(
                                {
                                    ...formItems,
                                    message: e.target.value.replace(/\s\s+/g, '')
                                },
                                setCharacterCount(e.target.value.length)
                            )
                        }
                    />
                    <h4 className={styles.charCount}>{characterCount}/250</h4>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        id="submitAddNotificationButton"
                        className={styles.button}
                        disabled={isDisable}
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                    >
                        Add
                    </Button>
                    <Button
                        id="cancelAddNotificationButton"
                        className={styles.button}
                        onClick={() => setShow(false)}
                        variant="contained"
                        color="primary"
                    >
                        Close
                    </Button>
                </Grid>
            </Grid>
            {error && (
                <SnackBarAndAlert open={error} onClose={() => setError('')} type="error">
                    {error}
                </SnackBarAndAlert>
            )}
        </React.Fragment>
    );
}

CreateUrgentMessageComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onSubmit: PropTypes.func,
    formItems: PropTypes.object,
    setFormItems: PropTypes.func,
    isDisable: PropTypes.bool,
    error: PropTypes.bool,
    setError: PropTypes.func,
    onChange: PropTypes.func,
    setTagData: PropTypes.array,
    removeTagData: PropTypes.func,
    addTagData: PropTypes.func,
    tagData: PropTypes.func,
    setShowMid: PropTypes.bool,
    setCharacterCount: PropTypes.number
};

export default CreateUrgentMessageComponent;
