import React from 'react';
import {
    Button,
    Dialog,
    Grid,
    Typography,
    Switch,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    TextField,
    Tooltip
} from '@material-ui/core';
import styles from './merchant-verification-checklist.module.scss';
import { withStyles } from '@material-ui/core/styles';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import CloseIcon from '@material-ui/icons/Close';

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 50,
        height: 26,
        padding: 0,
        margin: theme.spacing(1)
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(24px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none'
            }
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff'
        }
    },
    thumb: {
        width: 24,
        height: 24
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border'])
    },
    checked: {},
    focusVisible: {}
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
            }}
            {...props}
        />
    );
});
const MerchantVerificationCheckListComponent = (props) => {
    const {
        isMerchantVerificationCheckListOpen,
        setIsMerchantVerificationCheckListOpen,
        checkList,
        toggleChecked,
        disabled,
        rejectMerchant,
        loading,
        setNotes,
        notes,
        sendButtonDisabled,
        sendNotes,
        showSendANote,
        acceptMerchantAccountByAdmin,
        rejectDisableButton
    } = props;
    return (
        <Dialog
            open={isMerchantVerificationCheckListOpen}
            disableBackdropClick
            disableEscapeKeyDown
            onClose={() => {
                setIsMerchantVerificationCheckListOpen(false);
            }}
            maxWidth="xl"
            fullWidth
            className={styles.dialogWrapper}
        >
            <div className={styles.checkListContainer}>
                <Grid className={styles.pageTitle} item xs={12} sm={12} container>
                    <Grid item xs={11} sm={11}>
                        <Typography color="primary" className={styles.title}>
                            Merchant Verification - Checklist
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <Button
                            className={styles.closeButton}
                            onClick={() => {
                                setIsMerchantVerificationCheckListOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Grid className={styles.checkList}>
                    <List>
                        {checkList?.map((checkListPoint, index) => (
                            <ListItem className={styles.checkListItem} key={index}>
                                <ListItemText primary={checkListPoint.text} />
                                <ListItemSecondaryAction>
                                    <IOSSwitch value={checkListPoint.checked} onChange={() => toggleChecked(index)} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid className={styles.actionButtons}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={styles.buttonStyle}
                        onClick={() => rejectMerchant()}
                        disabled={rejectDisableButton}
                    >
                        Reject
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={styles.buttonStyle}
                        disabled={disabled}
                        onClick={acceptMerchantAccountByAdmin}
                    >
                        Accept
                    </Button>
                </Grid>
            </div>
            {showSendANote && (
                <Grid container direction="column" sm={12} md={8} lg={6} className={styles.sendNotesContainer}>
                    <TextField
                        label="Send note to customer"
                        placeholder="Note text"
                        multiline
                        rows={3}
                        variant="outlined"
                        value={notes}
                        required
                        onChange={(e) => setNotes(e.target.value)}
                    />
                    <Tooltip title="This will send an email to the merchant owner.">
                        <Button
                            variant="contained"
                            color="primary"
                            className={styles.sendNotesButton}
                            disabled={notes.trim().length < 1 || sendButtonDisabled}
                            onClick={sendNotes}
                        >
                            Send & Close
                        </Button>
                    </Tooltip>
                </Grid>
            )}
            <LoadingScreen open={loading}></LoadingScreen>
        </Dialog>
    );
};

export default MerchantVerificationCheckListComponent;
