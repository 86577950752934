import React, { useState, useEffect } from 'react';
import PasswordValidationComponent from './password-validation.component';
import PropTypes from 'prop-types';
import { isValidPassword } from 'utils/helper';
// import { es } from 'date-fns/locale';

const PasswordValidation = (props) => {
    const {
        currentPassword,
        newPassword,
        confirmPassword,
        setIsPasswordValid,
        honorCurrentPassword,
        newPasswordChange,
        redoPasswordValidation,
        email,
        firstName,
        lastName
    } = props;

    const [hasEightCharacters, setHasEightCharacters] = useState(false);
    const [hasUpperCaseCharacters, setHasUpperCaseCharacters] = useState(false);
    const [hasLowerCaseCharacters, setHasLowerCaseCharacters] = useState(false);
    //const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasDigitCharacters, setHasDigitCharacters] = useState(false);
    const [newPasswordMatchesConfirmPassword, setNewPasswordMatchesConfirmPassword] = useState(
        newPassword === confirmPassword
    );
    const [notSameAsCurrentPassword, setNotSameAsCurrentPassword] = useState(false);
    const [userNameValidation, setUserNameValidation] = useState(false);

    const lengthRegEx = new RegExp('(?=.{8,})');
    const uppercaseRegEx = new RegExp('(?=.*[A-Z])');
    const lowercaseRegEx = new RegExp('(?=.*[a-z])');
    //const specialCharacterRegEx = new RegExp('(?=.*[_!@#$%^&*-/()?+<>^|])');
    const digitRegEx = new RegExp('(?=.*[0-9])');
    const confirmPasswordValidation = newPassword && confirmPassword && newPassword === confirmPassword;
    const currentPasswordValidation = currentPassword && newPassword && currentPassword !== newPassword;

    useEffect(() => {
        setHasEightCharacters(lengthRegEx.test(newPassword));
        setHasUpperCaseCharacters(uppercaseRegEx.test(newPassword));
        setHasLowerCaseCharacters(lowercaseRegEx.test(newPassword));
        //setHasSpecialCharacters(specialCharacterRegEx.test(newPassword));
        setHasDigitCharacters(digitRegEx.test(newPassword));
        setNewPasswordMatchesConfirmPassword(confirmPasswordValidation);
        setNotSameAsCurrentPassword(currentPasswordValidation);

        newPassword.length >= 8
            ? setUserNameValidation(isValidPassword(newPassword, firstName, lastName, email))
            : setUserNameValidation(false);

        if (
            (!honorCurrentPassword || currentPassword) &&
            (!honorCurrentPassword || currentPassword.length >= 8) &&
            newPassword &&
            newPassword.length >= 8 &&
            confirmPassword &&
            confirmPassword.length >= 8 &&
            (!honorCurrentPassword || newPassword !== currentPassword) &&
            newPassword === confirmPassword &&
            hasEightCharacters &&
            (hasUpperCaseCharacters || hasLowerCaseCharacters) &&
            //hasSpecialCharacters &&
            hasDigitCharacters &&
            userNameValidation
        ) {
            setIsPasswordValid && setIsPasswordValid(true);
        } else {
            setIsPasswordValid && setIsPasswordValid(false);
        }
        // eslint-disable-next-line
    }, [
        currentPassword,
        newPassword,
        confirmPassword,
        honorCurrentPassword,
        notSameAsCurrentPassword,
        newPasswordChange,
        hasDigitCharacters,
        hasEightCharacters
    ]);

    return (
        <PasswordValidationComponent
            hasEightCharacters={hasEightCharacters}
            hasUpperCaseCharacters={hasUpperCaseCharacters}
            hasLowerCaseCharacters={hasLowerCaseCharacters}
            //hasSpecialCharacters={hasSpecialCharacters}
            hasDigitCharacters={hasDigitCharacters}
            newPasswordMatchesConfirmPassword={newPasswordMatchesConfirmPassword}
            hideMatchConfirmPassword={!newPassword && !confirmPassword}
            honorCurrentPassword={honorCurrentPassword}
            notSameAsCurrentPassword={notSameAsCurrentPassword}
            newPasswordChange={newPasswordChange}
            redoPasswordValidation={redoPasswordValidation}
            userNameValidation={userNameValidation}
        />
    );
};

export default PasswordValidation;

PasswordValidation.propTypes = {
    currentPassword: PropTypes.string,
    honorCurrentPassword: PropTypes.bool.isRequired,
    newPassword: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    setIsPasswordValid: PropTypes.func,
    newPasswordChange: PropTypes.func,
    redoPasswordValidation: PropTypes.bool
};
