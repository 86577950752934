import React, { useEffect, useState } from 'react';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import T2SOnboardingNameAddressComponent from './t2s-onboarding-name-address.component';
import { NonResellerMerchantService, CompaniesHouseService, AuthService } from 'services';
import { validateVatNumber, InputValidators } from 'validators';
import moment from 'moment';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';
import { OwnerTitle } from 'utils/enums/OwnerTitle';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { validateRegisteredNumber } from 'validators';
import { formTypes } from 'utils/enums/FormTypes';
import { isTokenGoingToExpire } from 'utils/helper';
import { validBusinessTypeIdsMap } from 'utils/onboarding-helper';

function T2SOnboardingNameAddress(props) {
    // const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();

    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();

    const [isBankAccountNameSwitchOn, setIsBankAccountNameSwitchOn] = useState(false);
    const [showErrorForRegisteredNumber, setShowErrorForRegisteredNumber] = useState(false);
    const [registeredNumberErrorMessage, setRegisteredNumberErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSoleTrader, setIsSoleTrader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const { globalState } = useGlobalStateContext();
    const [selectedCompany, setSelectedCompany] = useState({});
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState(null);
    const [lengthIssue, setLengthIssue] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const isUKMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const regNumberCheckRegexUK = new RegExp('^[0-9a-zA-Z]+$');
    const regNumberCheckRegexMx = new RegExp('^[a-zA-Z]{1,4}');
    const isMexicanmerchant = globalState.merchant?.country === MerchantCountries.MEXICO;
    const isIrelandmerchant = globalState.merchant?.country === MerchantCountries.IRELAND;
    const isCanadaMerchant = globalState.merchant?.country === MerchantCountries.CANADA;
    const isUSAMerchant = globalState.merchant?.country === MerchantCountries.UNITED_STATES;
    const isAUSMerchant = globalState.merchant?.country === MerchantCountries.AUSTRALIA;
    const [dialCode, setDialCode] = useState('');
    const [csrfToken, setCSRFToken] = useState('');
    const [isTrust, setIsTrust] = useState(false);

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(
            formTypes.ONBOARDING_NAME_AND_ADDRESS,
            globalState.merchant?.id
        );
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    let newBusinessTypes = [];

    switch (true) {
        // If the merchant is from USA
        case isUSAMerchant:
            newBusinessTypes = onboardingForm.businessTypes
                .filter((business) => validBusinessTypeIdsMap.USA.includes(business.id))
                .sort((a, b) =>
                    a.id === BusinessTypeEnumId.Limited_LLC
                        ? -1
                        : b.id === BusinessTypeEnumId.Limited_LLC
                        ? 1
                        : a.id === BusinessTypeEnumId.Other
                        ? 1
                        : b.id === BusinessTypeEnumId.Other
                        ? -1
                        : 0
                );
            break;

        // If the merchant is from Canada
        case isCanadaMerchant:
            newBusinessTypes = onboardingForm.businessTypes.filter((business) =>
                validBusinessTypeIdsMap.CANADA.includes(business.id)
            );
            break;
        // If the merchant is from Australia
        case isAUSMerchant:
            newBusinessTypes = onboardingForm.businessTypes.filter((business) =>
                validBusinessTypeIdsMap.AUS.includes(business.id)
            );
            break;

        // If the merchant is from UK or Ireland
        case isUKMerchant || isIrelandmerchant:
            newBusinessTypes = onboardingForm.businessTypes.filter((business) =>
                validBusinessTypeIdsMap.UKorIRELAND.includes(business.id)
            );
            break;

        // Default case
        default:
            newBusinessTypes = onboardingForm.businessTypes.filter((business) =>
                validBusinessTypeIdsMap.DEFAULT.includes(business.id)
            );
    }

    const validateRegNumber = (registeredNumber) => {
        var validateRN = validateRegisteredNumber(globalState.merchant?.country, registeredNumber);
        if (registeredNumber) {
            setShowErrorForRegisteredNumber(!validateRN.isValid);
            setRegisteredNumberErrorMessage(validateRN.message);
        }
        return validateRN.isValid;
    };

    useEffect(() => {
        const { registeredNumber, postCode, addressLine1, country, state, city } = onboardingForm.nameAndAddress;
        const countryCheck = globalState.merchant.country === MerchantCountries.MEXICO ? true : country;
        const { businessTypeId } = onboardingForm.businessDetails;
        const checkRegBusinessNameVal = onboardingForm.businessDetails.tradingName
            ? onboardingForm.businessDetails.tradingName?.trim()
            : true;
        const checkBankAccountNameVal = onboardingForm.tradingAddress.accountHolderName
            ? onboardingForm.tradingAddress.accountHolderName?.trim()
            : true;
        let checkValues =
            validateRegNumber(registeredNumber) &&
            checkRegBusinessNameVal &&
            checkBankAccountNameVal &&
            onboardingForm.nameAndAddress.legalName?.trim() &&
            postCode &&
            city &&
            addressLine1 &&
            countryCheck &&
            businessTypeId &&
            businessTypeId !== '--Please select--';

        if (isAUSMerchant || isUSAMerchant) {
            checkValues = checkValues && state;
        }

        if (isAUSMerchant || isUSAMerchant || isUKMerchant) {
            checkValues =
                checkValues &&
                onboardingForm.businessDetails.businessCategory?.trim() &&
                onboardingForm.businessDetails.businessCategory?.trim() !== '--Please select--';
        }

        const { vatNumber, tradingName, phoneNumber, email } = onboardingForm.businessDetails;
        const checkVatNumber = validateVatNumber(globalState.merchant?.country, vatNumber);
        const isEmailValid = email ? InputValidators.isEmailValid(email) : true;
        if (globalState.isT2SMerchant) {
            checkValues = checkValues && phoneNumber && isEmailValid;
        } else {
            checkValues = checkValues && checkVatNumber && tradingName && phoneNumber && isEmailValid;
        }

        if (isSoleTrader) {
            checkValues =
                onboardingForm.businessDetails.tradingName?.trim() ||
                onboardingForm.tradingAddress.accountHolderName?.trim() ||
                isBankAccountNameSwitchOn;
            setIsDisabled(!checkValues);
        } else {
            setIsDisabled(!checkValues);
        }

        // eslint-disable-next-line
    }, [
        onboardingForm.nameAndAddress,
        onboardingForm.businessDetails.businessTypeId,
        onboardingForm.businessDetails,
        isBankAccountNameSwitchOn,
        globalState.merchant.country,
        isSoleTrader,
        globalState.isT2SMerchant
    ]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                nameAndAddress: !isDisabled ? true : false
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    useEffect(() => {
        setIsSoleTrader(onboardingForm.businessDetails.businessTypeId?.toString() === '2');
        setIsTrust(onboardingForm.businessDetails.businessTypeId?.toString() === '12');
    }, [onboardingForm.businessDetails.businessTypeId]);

    const getCompanyDetails = async () => {
        const { companyName } = onboardingForm.nameAndAddress;
        setIsLoading(true);
        const result = await CompaniesHouseService.getCompaniesDetail(companyName, 15);

        if (result.isSuccesfully) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    companies: result.data.items
                },
                companies: result.data.items
            }));
            if (result.data.items.length) setOpenDropdown(true);
        }

        setIsLoading(false);
    };

    const extractAddressLines = (companyObj) => {
        if (companyObj.address_snippet && companyObj.address) {
            let finalAddressLine1 = companyObj.address_snippet
                .replace(`, ${companyObj.address.postal_code}`, '')
                .replace(`, ${companyObj.address.country}`, '')
                .replace(`, ${companyObj.address.locality}`, '');

            if (companyObj.address.address_line_2) {
                finalAddressLine1 = finalAddressLine1.replace(`, ${companyObj.address.address_line_2}`, '');
            }
            return {
                address_line_1: finalAddressLine1,
                address_line_2: companyObj.address.address_line_2
            };
        }
        return {
            address_line_1: companyObj.address?.address_line_1,
            address_line_2: companyObj.address?.address_line_2
        };
    };
    const handleSelectedCompany = async (selectedValue) => {
        setSelectedCompany(selectedValue);
        let businessProfile = {};
        if (selectedValue && Object.keys(selectedValue).length) {
            if (selectedValue.date_of_creation) {
                const MONTHS_IN_A_YEAR = 12;
                businessProfile = {
                    ...onboardingForm.businessProfile,
                    timeInBusinessYears: moment().diff(selectedValue.date_of_creation, 'years', false),
                    timeInBusinessMonths:
                        moment().diff(selectedValue.date_of_creation, 'months', false) % MONTHS_IN_A_YEAR
                };
            }

            const ownersDetails = await getOwnerDetails(selectedValue.company_number);

            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: ownersDetails ? ownersDetails : onboardingForm.ownersDetails,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    legalName: selectedValue.title,
                    companyName: selectedValue.title,
                    registeredNumber: selectedValue.company_number,
                    postCode: selectedValue.address.postal_code,
                    addressLine1: extractAddressLines(selectedValue).address_line_1,
                    addressLine2: extractAddressLines(selectedValue).address_line_2,
                    city: selectedValue.address.locality,
                    country: selectedValue.address.country
                },
                businessDetails: {
                    ...onboardingForm.businessDetails
                },
                businessProfile: {
                    ...onboardingForm.businessProfile,
                    timeInBusinessYears:
                        businessProfile && businessProfile.timeInBusinessYears > 0
                            ? businessProfile.timeInBusinessYears
                            : '0',
                    timeInBusinessMonths: businessProfile ? businessProfile.timeInBusinessMonths : ''
                }
            }));
        }
    };
    const getOwnerDetails = async (registeredNumber) => {
        let ownersDetails;
        const resultPersonsDetails = await CompaniesHouseService.getPersonsWithSignificantControl(registeredNumber);

        if (resultPersonsDetails.isSuccesfully) {
            let ownersWithSignificantPower = resultPersonsDetails.data.items || [];
            ownersWithSignificantPower.forEach((person) => {
                person.date_of_birth = person.date_of_birth || {};
                let dateOfBirth = person.date_of_birth;

                dateOfBirth.day = dateOfBirth.day ? dateOfBirth.day.toString().padStart(2, '0') : '';
                dateOfBirth.month = dateOfBirth.month ? dateOfBirth.month.toString().padStart(2, '0') : '';
                dateOfBirth.year = dateOfBirth.year ? dateOfBirth.year.toString().padStart(4, '0') : '';

                const ownership = person.natures_of_control.filter((item) => item.startsWith('ownership-of-shares'));

                if (ownership.length > 0) {
                    const words = ownership[0].split('-').filter((word) => !isNaN(word));
                    person.ownershipDetails = {};
                    if (words.length === 1) {
                        person.ownership = words[0];
                    } else if (words.length === 2) {
                        person.ownership = words[1];
                    }
                }

                var name = person.name;
                var titlePerson = person.name_elements?.title;
                if (titlePerson) {
                    if (titlePerson[titlePerson.length - 1] === '.') {
                        titlePerson = titlePerson.substring(0, titlePerson.length - 1);
                    }
                    titlePerson = Object.keys(OwnerTitle).filter((key) => OwnerTitle[key] === titlePerson);
                    if (titlePerson.length > 0) {
                        name = name.substring(titlePerson[0].length + 1);
                    }
                    titlePerson = OwnerTitle[titlePerson[0]];
                }
                person.names = {
                    fullName: name,
                    title: titlePerson ?? titlePerson
                };
            });

            const personDetails = resultPersonsDetails.data.items[0];
            ownersDetails = {
                ...onboardingForm.ownersDetails,
                title: personDetails.names.title ?? onboardingForm.ownersDetails.title,
                fullName: personDetails.names.fullName,
                dateOfBirth: personDetails.date_of_birth,
                nationality: personDetails.nationality,
                ownership: personDetails.ownership ?? onboardingForm.ownersDetails.ownership
            };
            if (resultPersonsDetails.data.total_results === 1) {
                ownersDetails = {
                    ...ownersDetails,
                    persons: []
                };
            } else if (resultPersonsDetails.data.total_results > 1) {
                ownersDetails = {
                    ...ownersDetails,
                    persons: resultPersonsDetails.data.items
                };
            }
        }

        return ownersDetails;
    };

    const onSubmit = async () => {
        const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
        let latestCSRFToken = csrfToken;
        if (isCSRFGoingToExpire) {
            latestCSRFToken = await generateCSRFToken();
        }

        let soleTradertName = globalState.merchant.name;
        if (!isBankAccountNameSwitchOn) {
            soleTradertName = onboardingForm.businessDetails.tradingName
                ? onboardingForm.businessDetails.tradingName.trim()
                : onboardingForm.tradingAddress.accountHolderName;
        }
        if (!onboardingForm.businessDetails.isRegisteredBusiness && !isBankAccountNameSwitchOn) {
            soleTradertName = onboardingForm.tradingAddress.accountHolderName;
        }

        setOnboardingForm({
            ...onboardingForm,
            nameAndAddress: {
                ...onboardingForm.nameAndAddress,
                legalName: isSoleTrader ? soleTradertName : onboardingForm.nameAndAddress.legalName
            }
        });
        const personalData = { ...onboardingForm.nameAndAddress, formType: formTypes.ONBOARDING_NAME_AND_ADDRESS };

        if (isMexicanmerchant) {
            setOnboardingForm({
                ...onboardingForm,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    legalName: isSoleTrader ? soleTradertName : onboardingForm.nameAndAddress.legalName,
                    country: MerchantCountries.MEXICO
                }
            });
            personalData.country = MerchantCountries.MEXICO;
        }

        setOnboardingForm({
            ...onboardingForm,
            tradingAddress: {
                ...onboardingForm.tradingAddress,
                accountHolderName: isSoleTrader ? soleTradertName : onboardingForm.nameAndAddress.legalName
            },
            businessDetails: {
                ...onboardingForm.businessDetails,
                phoneNumber: onboardingForm.businessDetails.phoneNumber //Only 10 characters should be taken as phone number
            }
        });

        const data = {
            ...onboardingForm.tradingAddress,
            accountHolderName: isSoleTrader ? soleTradertName : onboardingForm.nameAndAddress.legalName,
            step: 2,
            formType: formTypes.ONBOARDING_NAME_AND_ADDRESS
        };
        const businessdata = {
            ...onboardingForm.businessDetails,
            formType: formTypes.ONBOARDING_NAME_AND_ADDRESS,
            phoneNumber: onboardingForm.businessDetails.phoneNumber
        };
        personalData.legalName = isSoleTrader ? soleTradertName : onboardingForm.nameAndAddress.legalName;
        personalData.businessTypeId = onboardingForm.businessDetails.businessTypeId ?? BusinessTypeEnumId.Limited;
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 1, personalData, latestCSRFToken);
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 2, businessdata, latestCSRFToken);
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 3, data, latestCSRFToken);
    };

    const setRegisteredNumberHandler = (e) => {
        let stringValue = e.target.value;
        if (isUKMerchant) {
            if (stringValue.match(regNumberCheckRegexUK) || stringValue === '') {
                setOnboardingForm({
                    ...onboardingForm,
                    nameAndAddress: {
                        ...onboardingForm.nameAndAddress,
                        registeredNumber: stringValue
                    }
                });
            }
        } else if (isMexicanmerchant) {
            if (stringValue.match(regNumberCheckRegexMx) || stringValue === '') {
                setShowErrorForRegisteredNumber(false);
                setOnboardingForm({
                    ...onboardingForm,
                    nameAndAddress: {
                        ...onboardingForm.nameAndAddress,
                        registeredNumber: stringValue
                    }
                });
            } else {
                setShowErrorForRegisteredNumber(true);
                setRegisteredNumberErrorMessage(
                    'The format of the information input does not match the expected result'
                );
            }
        } else {
            setOnboardingForm({
                ...onboardingForm,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    registeredNumber: stringValue
                }
            });
        }
    };

    return (
        <T2SOnboardingNameAddressComponent
            {...props}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            isTrust={isTrust}
            setIsTrust={setIsTrust}
            isSoleTrader={isSoleTrader}
            setIsSoleTrader={setIsSoleTrader}
            newBusinessTypes={newBusinessTypes}
            getCompanyDetails={getCompanyDetails}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            openDropdown={openDropdown}
            selectedCompany={selectedCompany}
            setInputValue={setInputValue}
            handleSelectedCompany={handleSelectedCompany}
            inputValue={inputValue}
            value={value}
            setValue={setValue}
            setOpenDropdown={setOpenDropdown}
            setRegisteredNumberHandler={setRegisteredNumberHandler}
            lengthIssue={lengthIssue}
            setLengthIssue={setLengthIssue}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            isBankAccountNameSwitchOn={isBankAccountNameSwitchOn}
            setIsBankAccountNameSwitchOn={setIsBankAccountNameSwitchOn}
            showErrorForRegisteredNumber={showErrorForRegisteredNumber}
            registeredNumberErrorMessage={registeredNumberErrorMessage}
            setDialCode={setDialCode}
            dialCode={dialCode}
        />
    );
}

export default T2SOnboardingNameAddress;
