import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    InputAdornment,
    Grid,
    OutlinedInput,
    useMediaQuery
} from '@material-ui/core';
import { Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import styles from './modal-cancel-internal-transfer.module.scss';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';

const ModalCancelInternalTransferComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { loading, buttonDisabled, error, errorMessage, disableSubmit, success } = props;

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    return (
        <React.Fragment>
            <Dialog
                maxWidth="sm"
                open={props.showCancelModel}
                onClose={() => props.setShowCancelModel(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" onClose={() => props.setShowCancelModel(false)}>
                    <span className={styles.title}>Cancel Confirmation </span>
                    <div className={styles.headerDivider}></div>
                </DialogTitle>

                <DialogContent>
                    <Box width={400}>
                        {error ? (
                            <>
                                <div className="payments-none text-center">
                                    <p className={styles.spanErrorMessage}>
                                        {errorMessage ? errorMessage : `Something went wrong... Try again later.`}
                                    </p>
                                </div>
                            </>
                        ) : loading ? (
                            <>
                                <div className="payments-none text-center">
                                    <Box textAlign="center" py={2}>
                                        <CircularProgress />
                                    </Box>{' '}
                                    <p className={styles.spanMessage}>Please wait, we are processing your request...</p>
                                </div>
                            </>
                        ) : success ? (
                            <>
                                <div className="payments-none text-center">
                                    <p className="text-muted">Transaction has been cancelled successfully</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <span className={styles.withdrawalArrivingText}>
                                    You are about to cancel pending transaction{' '}
                                </span>

                                <div>
                                    <FormItem>
                                        <Grid
                                            container
                                            xs={12}
                                            md={12}
                                            style={{ marginBottom: 10 }}
                                            spacing={!mediaLessThan600px ? 3 : 2}
                                        >
                                            <Grid item xs={4} md="auto">
                                                <Label>Amount</Label>
                                            </Grid>
                                            <Grid item xs={8} md="auto">
                                                <OutlinedInput
                                                    className={styles.customOutlineInput}
                                                    value={props.amount}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            {getCurrencyThreeAlpha(globalState.merchant?.country)}
                                                        </InputAdornment>
                                                    }
                                                    inputMode="numeric"
                                                    type="number"
                                                    min="0"
                                                    required
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={3}>
                                            <Grid item xs={4} md="auto">
                                                <Label>Supplier</Label>
                                            </Grid>
                                            <Grid item xs={8} md="auto">
                                                <OutlinedInput
                                                    className={styles.customOutlineInput}
                                                    id="supplier"
                                                    value={props.supplier}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormItem>
                                </div>

                                <span className={styles.withdrawalArrivingText}>
                                    Are you sure you want to proceed ?{' '}
                                </span>
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions className={styles.buttonsContainer}>
                    {!loading && !disableSubmit ? (
                        <React.Fragment>
                            <Button
                                className={styles.button}
                                color="primary"
                                variant="outlined"
                                onClick={() => props.setShowCancelModel(false)}
                                disabled={buttonDisabled}
                            >
                                Cancel
                            </Button>

                            <Button
                                className={styles.button}
                                variant="contained"
                                color="primary"
                                autoFocus
                                onClick={props.onSubmitCancel}
                                disabled={buttonDisabled}
                            >
                                Proceed
                            </Button>
                        </React.Fragment>
                    ) : (
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            autoFocus
                            onClick={() => props.setShowCancelModel(false)}
                            disabled={buttonDisabled}
                        >
                            Close
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalCancelInternalTransferComponent;

ModalCancelInternalTransferComponent.propTypes = {
    loading: PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    disableSubmit: PropTypes.bool,
    success: PropTypes.bool
};
