import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, makeStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

const styles = makeStyles((theme) => ({
    card: {
        height: '76px',
        marginBottom: '12px',
        marginTop: '12px',
        width: '100%',
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: '#f2f2f2'
        }
    },
    container: {
        height: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        height: '100%',
        '& img': {
            width: '25px',
            height: '25px'
        }
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '1.3em'
    },
    titlee: {
        color: theme.palette.primary.main,
        fontSize: '0.8em',
        marginLeft: '8px'
    },
    description: {
        color: 'gray',
        fontSize: '1em'
    },
    imgLogo: {
        marginTop: '27px',
        marginLeft: '10px'
    }
}));

function ProductMultiSelectComponent(props) {
    const classes = new styles();
    const { selectedEntities, setSelectedEntitites, list, onChange, selectedProduct, setSelectedProduct } = props;

    const onCardClick = (element) => {
        const elementId = element.id;

        if (selectedEntities.includes(elementId)) {
            const index = selectedEntities.indexOf(elementId);
            setSelectedProduct(!selectedProduct);

            if (index > -1) {
                selectedEntities.splice(index, 1);
            }
        } else {
            selectedEntities.push(elementId);
        }

        onChange([...selectedEntities]);
        setSelectedEntitites([...selectedEntities]);
    };

    return (
        <React.Fragment>
            {list.map((element) => {
                return (
                    <div>
                        {element.name !== 'MyEcomm' ? (
                            <Card key={element.id} className={classes.card}>
                                <Grid container className={classes.container}>
                                    <Grid item xs={9} sm={9} className={classes.iconContainer}>
                                        <Grid container className={classes.imgLogo}>
                                            <Grid item xs={2} sm={2}>
                                                <img alt="" src={element.imgPath}></img>
                                            </Grid>
                                            <Grid item xs={10} sm={10}>
                                                <span className={classes.titlee}>{element.name}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3} sm={3}>
                                        <Radio
                                            onClick={() => onCardClick(element)}
                                            checked={selectedEntities.includes(element.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </Card>
                        ) : null}
                    </div>
                );
            })}
        </React.Fragment>
    );
}

ProductMultiSelectComponent.propTypes = {
    selectedEntities: PropTypes.array,
    setSelectedEntitites: PropTypes.func,
    list: PropTypes.array,
    onChange: PropTypes.func,
    handleChange: PropTypes.func
};

export default ProductMultiSelectComponent;
