import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Container,
    Card,
    Box,
    CircularProgress,
    Grid,
    useMediaQuery,
    Typography,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    Tooltip,
    TableFooter,
    TablePagination
} from '@material-ui/core';
import styles from './team-members.module.scss';
import { ModalRemoveUser, ModalInviteUser } from 'components';
import { Alert } from '@material-ui/lab';
import { AddRounded } from '@material-ui/icons';
import moment from 'moment';
import { UserType } from 'utils/enums/UserType';
import { useGlobalStateContext } from 'contexts';
import { SnackBarAndAlert } from 'components';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { makeStyles } from '@material-ui/core/styles';
import TableHeader from 'components/payments/transaction-tables/table-header/table-header.container';
import { getComparator, stableSort } from '../../utils/helper';
import { Roles } from 'utils/enums/Roles';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        'overflow-y': 'visible'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    checkbox: {
        color: '#8783b5'
    },
    'MuiIconButton-root': {
        padding: '0px 0px'
    }
}));

function TeamMembersComponent(props) {
    const reseller = JSON.parse(localStorage.getItem('reseller'));
    const { globalState } = useGlobalStateContext();
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    const currentMoment = moment(currentTime, 'YYYY-MM-DD HH:mm:ss');
    const classes = useStyles();
    const {
        members,
        onSearch,
        onRoleFilter,
        onStatusFilter,
        loading,
        userEmail,
        isDisable,
        userRole,
        error,
        setError,
        onRemoveMember,
        removeMember,
        open,
        setOpen,
        getTeamMembers,
        inviteMember,
        show,
        setLoading,
        setShow,
        onResend,
        resendSuccess,
        setResendSuccess,
        messageType,
        validationMessage,
        count,
        setCount,
        isAlreadyMaxLimit,
        maxUserCount,
        isLoading,
        disableInvite,
        page,
        setPage,
        handleChangeRowsPerPage,
        handleChangePage,
        rowsPerPage,
        order,
        orderBy,
        setSelectedRole,
        setSelectedStatus,
        setSearchString,
        selectedRole,
        selectedStatus,
        searchString,
        agentDropdownToBeVisible
    } = props;

    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isCanonicalReseller = globalState.user?.UserType?.name === UserType.Reseller;
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <React.Fragment>
            <Container className={styles.teamContainer}>
                {error ? (
                    <Alert onClose={() => setError(false)} severity="error">
                        An error occurred. Please contact support or try again.
                    </Alert>
                ) : (
                    <Card>
                        <div className={styles.headerDiv}>
                            <Typography color={'primary'}>
                                <h1 className={styles.pageTitle}>User Management</h1>
                            </Typography>
                        </div>
                        <Grid container spacing={mediaLessThan600px ? 0 : 2} className={styles.gridContainer}>
                            <Grid item xs={12} sm={3} md={4} className={styles.itemContainer}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search by name or email …"
                                    aria-label="Search"
                                    value={searchString}
                                    onChange={(e) => onSearch(e.target.value)}
                                    autoComplete="nope"
                                />
                            </Grid>
                            <Grid item xs={5} sm={3} md={2} className={styles.itemContainer}>
                                <select
                                    className="form-control"
                                    name="role"
                                    onChange={(e) => {
                                        onRoleFilter(e.target.value);
                                        setPage(0);
                                    }}
                                    value={selectedRole}
                                    defaultValue="All roles"
                                >
                                    {!agentDropdownToBeVisible
                                        ? Object.keys(Roles).map(
                                              (status) =>
                                                  status !== 'Agent' && <option key={status}>{Roles[status]}</option>
                                          )
                                        : Object.keys(Roles).map((status) => (
                                              <option key={status}>{Roles[status]}</option>
                                          ))}
                                </select>
                            </Grid>
                            {mediaLessThan600px && <Grid item xs={1} />}
                            <Grid item xs={5} sm={3} md={2} className={styles.itemContainer}>
                                <select
                                    className="form-control"
                                    name="status"
                                    onChange={(e) => {
                                        onStatusFilter(e.target.value);
                                        setPage(0);
                                    }}
                                    defaultValue="All"
                                    value={selectedStatus}
                                >
                                    <option value="All">All </option>
                                    <option value="active">Active</option>
                                    <option value="pending">Pending</option>
                                </select>
                            </Grid>
                            {(isAdmin || reseller.id !== 2 || isCanonicalReseller) && (
                                <Grid item xs={12} sm={3} className={styles.itemContainer}>
                                    <Tooltip
                                        title={
                                            disableInvite
                                                ? 'You have reached the maximum number of members allowed on your account.'
                                                : ''
                                        }
                                        placement="top"
                                    >
                                        <Button
                                            className={
                                                disableInvite ? styles.buttonNewMemberDisabled : styles.buttonNewMember
                                            }
                                            id="inviteBtn"
                                            color="primary"
                                            variant="outlined"
                                            onClick={!disableInvite && inviteMember}
                                            disabled={isMerchantAccountClosed}
                                        >
                                            <AddRounded />
                                            New member
                                        </Button>
                                    </Tooltip>
                                    <ModalInviteUser
                                        setShow={setShow}
                                        count={count}
                                        setCount={setCount}
                                        show={show}
                                        setLoading={setLoading}
                                        getTeamMembers={getTeamMembers}
                                        isAlreadyMaxLimit={isAlreadyMaxLimit}
                                        maxUserCount={maxUserCount}
                                        totalTeamMembers={members.length}
                                        members={members}
                                        setSelectedRole={setSelectedRole}
                                        setSelectedStatus={setSelectedStatus}
                                        setSearchString={setSearchString}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {loading ? (
                            <Box textAlign="center" py={5}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <TableContainer className={classes.container}>
                                    <Table hover responsive stickyHeader size="small" style={{ marginBottom: '20px' }}>
                                        <TableHeader classes={classes} {...props} />
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? members.slice(
                                                          page * rowsPerPage,
                                                          page * rowsPerPage + rowsPerPage
                                                      )
                                                    : members,
                                                getComparator(order, orderBy)
                                            ).map((member) => {
                                                return (
                                                    <TableRow key={member.id}>
                                                        <TableCell
                                                            className="align-middle"
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            <Box fontWeight="fontWeightBold">
                                                                {(member.firstName ? member.firstName : '') +
                                                                    ' ' +
                                                                    (member.lastName ? member.lastName : '')}

                                                                {member.email === userEmail ? (
                                                                    <span className={styles.Badge}>You</span>
                                                                ) : null}
                                                            </Box>
                                                            <Box>
                                                                <span>{member.email}</span>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box>
                                                                {member.signupStatus ? (
                                                                    <span className={styles.activeStatus}>Active</span>
                                                                ) : (
                                                                    <span className={styles.pendingStatus}>
                                                                        Pending
                                                                    </span>
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            className="align-middle"
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            {member.Relationships.Role.name}
                                                        </TableCell>

                                                        <TableCell>
                                                            {userRole !== 'User' && isDisable(member) ? (
                                                                <Button
                                                                    id="memberRemoveBtn"
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    onClick={() => onRemoveMember(member)}
                                                                >
                                                                    Remove..
                                                                </Button>
                                                            ) : null}
                                                        </TableCell>
                                                        <TableCell>
                                                            {currentMoment.diff(
                                                                moment(member.updated_at, 'YYYY-MM-DD HH:mm:ss'),
                                                                'hours'
                                                            ) > 24 ? (
                                                                <Button
                                                                    id="memberRemoveBtn"
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    onClick={() => onResend(member)}
                                                                    disabled={isMerchantAccountClosed}
                                                                >
                                                                    Resend
                                                                </Button>
                                                            ) : null}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {members.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, { label: 'All', value: -1 }]}
                                                        count={members.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                                <Box mb={3} ml={2}>
                                    {members.length === 1 ? (
                                        <span>{members.length} member</span>
                                    ) : (
                                        <span>{members.length} members</span>
                                    )}
                                </Box>
                            </>
                        )}
                    </Card>
                )}
            </Container>
            <ModalRemoveUser
                open={open}
                removeMember={removeMember}
                setOpen={setOpen}
                getTeamMembers={getTeamMembers}
                setCount={setCount}
                count={count}
                setSelectedRole={setSelectedRole}
                setSelectedStatus={setSelectedStatus}
                setSearchString={setSearchString}
            />
            {validationMessage && (
                <SnackBarAndAlert open={resendSuccess} onClose={() => setResendSuccess(false)} type={messageType}>
                    {validationMessage}
                </SnackBarAndAlert>
            )}
            <LoadingScreen open={isLoading} />
        </React.Fragment>
    );
}

TeamMembersComponent.propTypes = {
    members: PropTypes.array.isRequired,
    onSearch: PropTypes.func,
    onRoleFilter: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    userEmail: PropTypes.string,
    inviteMember: PropTypes.func,
    setShow: PropTypes.func,
    show: PropTypes.bool,
    isDisable: PropTypes.func,
    userRole: PropTypes.string,
    error: PropTypes.bool,
    setError: PropTypes.func,
    onRemoveMember: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    removeMember: PropTypes.object,
    getTeamMembers: PropTypes.func,
    onResend: PropTypes.func,
    messageType: PropTypes.string,
    setSelectedRole: PropTypes.func,
    setSelectedStatus: PropTypes.func,
    setSearchString: PropTypes.func,
    selectedRole: PropTypes.string,
    selectedStatus: PropTypes.string,
    searchString: PropTypes.string,
    page: PropTypes.number,
    setPage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangePage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string
};

export default TeamMembersComponent;
