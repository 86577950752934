import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeaderComponent from './page-header.component';
import { useGlobalStateContext } from 'contexts';
import { cleverTapClick } from 'utils/helper';
import moment from 'moment';
import { dateRangeArchived } from 'utils/calender-helpers.js';
const PageHeader = (props) => {
    const [showReportModel, setShowReportModel] = useState(false);
    const [acquirerFilter, setAcquirerFilter] = useState(false);
    const [exportSnackBar, setExportSnackBar] = useState({ open: false, message: '', type: '' });
    const [showReportScheduleModel, setShowReportScheduleModel] = useState(false);
    const [sendReportModal, setSendReportModal] = useState(false);
    const [btn, setBtn] = React.useState('export-view');

    const { setRoute, globalState } = useGlobalStateContext();
    const {
        path,
        date,
        onChangeDateRange,
        onAcquirerChange,
        setOffrange = () => {},
        transactionListingOptions,
        setTransactionsListingOptions,
        locationInApp,
        setSelectedProvider,
        selectedProvider,
        payoutAcquireFilter,
        daysRange,
        setDaysRange,
        openMerchantModal,
        setOpenMerchantModal,
        selectedAcquirer
    } = props;
    const [via, setVia] = useState(props.via);
    const acquirerName = globalState?.merchant.paymentProviders;

    const handleAcquirerChange = (name) => {
        let prevSelectedAcquirers = [...selectedAcquirer];
        let selectedAcquirers = [...selectedAcquirer];
        let notSelectedAcquirers = [...acquirerName.filter((acquirer) => !prevSelectedAcquirers.includes(acquirer))];

        if (notSelectedAcquirers.includes(name)) {
            if (name === 'SELECT ALL') {
                selectedAcquirers = [...acquirerName];
            } else {
                selectedAcquirers = [...selectedAcquirers, name];
                if (selectedAcquirers.length === acquirerName.length - 1) {
                    selectedAcquirers = [...selectedAcquirers, 'SELECT ALL'];
                }
            }
        } else {
            if (name === 'SELECT ALL') {
                selectedAcquirers = [];
            } else {
                const remainingAcquirer = prevSelectedAcquirers.filter(
                    (acquirer) => !['SELECT ALL', name].includes(acquirer)
                );
                selectedAcquirers = [...remainingAcquirer];
            }
        }

        onAcquirerChange(selectedAcquirers);
        sessionStorage.setItem('selectedAcquirer', JSON.stringify(selectedAcquirers));
    };
    const handleClearFilter = (event) => {
        onAcquirerChange(acquirerName);
        sessionStorage.setItem('selectedAcquirer', JSON.stringify([]));
    };
    const handleBtnChange = (event) => {
        setBtn(event.target.value);
    };

    useEffect(() => {
        if (locationInApp === 'merchant-details') {
            setTransactionsListingOptions({ ...transactionListingOptions, date: date, via: via });
            return;
        }

        switch (via) {
            case 'all':
                setRoute(`/${path}/all/${date}`);
                break;
            case 'day':
                setRoute(`/${path}/day/${date}`);
                break;
            case 'week':
                setRoute(`/${path}/week/${date}`);
                break;
            case 'year':
                setRoute(`/${path}/year/${date}`);
                break;
            default:
                setRoute(`/${path}/month/${date}`);
        }
    }, [via, path, date, setRoute, transactionListingOptions, setTransactionsListingOptions, locationInApp]);

    const onCancelExport = () => {
        setShowReportModel(false);
    };
    const onConfirmExport = () => {
        setShowReportModel(false);
    };
    const handleMerchantModal = () => {
        setOpenMerchantModal(true);
    };
    const onCancelScheduleExport = () => {
        setShowReportScheduleModel(false);
    };
    const onConfirmScheduleExport = () => {
        setShowReportScheduleModel(false);
    };

    const callClevertap = (via) => {
        let dateStart;
        let dateEnd;
        switch (via) {
            case 'week':
                dateStart = moment(date).startOf('week').format('DD-MM-YYYY');
                dateEnd = moment(date).endOf('week').format('DD-MM-YYYY');
                break;
            case 'month':
                dateStart = moment(date).startOf('month').format('DD-MM-YYYY');
                dateEnd = moment(date).endOf('month').format('DD-MM-YYYY');
                break;
            default:
                dateStart = moment(date).format('DD-MM-YYYY');
                dateEnd = moment(date).format('DD-MM-YYYY');
        }
        const data = { date_start: dateStart, date_end: dateEnd, Interval: via, type_of_payment: path };

        cleverTapClick('Txn_range_click', globalState, data);
    };

    const cutoffdateForExportBreached = (startDate, endDate) => {
        return dateRangeArchived(
            moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        );
    };

    return (
        <PageHeaderComponent
            {...props}
            via={via}
            date={date}
            setVia={setVia}
            showReportModel={showReportModel}
            setShowReportModel={setShowReportModel}
            onCancelExport={onCancelExport}
            onConfirmExport={onConfirmExport}
            onAcquirerChange={onAcquirerChange}
            onChangeDateRange={onChangeDateRange}
            setOffrange={setOffrange}
            locationInApp={locationInApp}
            acquirer={selectedAcquirer}
            setSelectedProvider={setSelectedProvider}
            selectedProvider={selectedProvider}
            payoutAcquireFilter={payoutAcquireFilter}
            callClevertap={callClevertap}
            daysRange={daysRange}
            setDaysRange={setDaysRange}
            openMerchantModal={openMerchantModal}
            setOpenMerchantModal={setOpenMerchantModal}
            handleMerchantModal={handleMerchantModal}
            handleAcquirerChange={handleAcquirerChange}
            acquirerName={acquirerName}
            handleClearFilter={handleClearFilter}
            acquirerFilter={acquirerFilter}
            setAcquirerFilter={setAcquirerFilter}
            exportSnackBar={exportSnackBar}
            setExportSnackBar={setExportSnackBar}
            onCancelScheduleExport={onCancelScheduleExport}
            onConfirmScheduleExport={onConfirmScheduleExport}
            handleBtnChange={handleBtnChange}
            showReportScheduleModel={showReportScheduleModel}
            setShowReportScheduleModel={setShowReportScheduleModel}
            btn={btn}
            setBtn={setBtn}
            sendReportModal={sendReportModal}
            setSendReportModal={setSendReportModal}
            cutoffdateForExportBreached={cutoffdateForExportBreached}
        />
    );
};

export default PageHeader;

PageHeader.propTypes = {
    path: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    setOffrange: PropTypes.func,
    locationInApp: PropTypes.string,
    onAcquirerChange: PropTypes.func,
    setOpenMerchantModal: PropTypes.func
};
