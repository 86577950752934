import React, { useState, useEffect, useRef } from 'react';
import OnboardingOwnerDetailsComponent from './onboarding-owner-details.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';
import moment from 'moment';
import _ from 'lodash';

import { validateSocialSecurity, InputValidators, getInvalidDobFields } from 'validators';

function OnboardingOwnerDetails(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const [shouldNextStep, setShouldNextStep] = useState(false);
    const [dobValidationFailed, setDobValidationFailed] = useState(false);
    const [dobInvalidFields, setDobInvalidFields] = useState([]);
    const { globalState } = useGlobalStateContext();
    const oneHundredValues = [...Array(100).keys()].map((item) => item + 1);
    const isT2SMerchant = globalState.isT2SMerchant;

    const monthInputElementRef = useRef(null);
    const yearInputElementRef = useRef(null);
    const emailInputElementRef = useRef(null);

    useEffect(() => {
        const {
            fullName,
            email,
            contactTel,
            businessTitle,
            nationality,
            dateOfBirth,
            ownership,
            ssnLastDigits
        } = onboardingForm.ownersDetails;

        const dateOfBirthMoment =
            dateOfBirth && moment([dateOfBirth.day, dateOfBirth.month, dateOfBirth.year].join('-'), 'DD-MM-YYYY');
        const isDateOfBirthProper =
            dateOfBirth &&
            dateOfBirthMoment.isValid() &&
            +dateOfBirth.day >= 0 &&
            +dateOfBirth.month >= 0 &&
            +dateOfBirth.year >= 1900 &&
            dateOfBirthMoment.valueOf() <= moment().valueOf();

        const dobFieldsWithIssues = getInvalidDobFields(dateOfBirth);
        setDobInvalidFields(dobFieldsWithIssues);

        const socialSecurityValidator = validateSocialSecurity(globalState.merchant?.country, ssnLastDigits);
        const checkValues =
            fullName &&
            InputValidators.isEmailValid(email) &&
            contactTel &&
            businessTitle &&
            nationality !== '--Please select--' &&
            (isT2SMerchant || ownership) &&
            isDateOfBirthProper &&
            ownership &&
            _.isEmpty(dobFieldsWithIssues) &&
            socialSecurityValidator;

        setDobValidationFailed(!isDateOfBirthProper);
        setIsDisabled(!checkValues);
        // eslint-disable-next-line
    }, [onboardingForm.ownersDetails, globalState.merchant.country]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                ownerDetails: isDisabled ? false : true
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    const onSubmit = () => {
        //Cloning to not impact onBoardingForm in case of dateOfBirth change
        const ownersDetails = { ...onboardingForm.ownersDetails };
        ownersDetails.dateOfBirth = { ...ownersDetails.dateOfBirth };

        const fullData = {
            ownersDetails: ownersDetails,
            subStepNumber: 1
        };

        fullData.ownersDetails.dateOfBirth = { ...fullData.ownersDetails.dateOfBirth };
        const dateOfBirth = fullData.ownersDetails.dateOfBirth;
        fullData.ownersDetails.dateOfBirth = moment(
            [dateOfBirth.day, dateOfBirth.month, dateOfBirth.year].join('-'),
            'DD-MM-YYYY'
        ).format('YYYY-MM-DD');

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 4, fullData);
    };

    const setPersonDetails = (name) => {
        const personDetails = onboardingForm.ownersDetails.persons.filter(
            (person) => person.names.fullName === name
        )[0];
        const dateOfBirth = personDetails.date_of_birth;

        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                title: personDetails.names.title,
                fullName: personDetails.names.fullName,
                dateOfBirth: dateOfBirth,
                nationality: personDetails.nationality,
                ownership: personDetails.ownership
            }
        }));
    };

    const dayChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = onboardingForm.ownersDetails.dateOfBirth?.day || '';
        }

        if (value.length > 1 || (value.length === 1 && value > 3)) {
            setTimeout(() => {
                monthInputElementRef.current.focus();
            });
        }

        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                dateOfBirth: {
                    ...onboardingForm.ownersDetails.dateOfBirth,
                    day: value
                }
            }
        });
    };

    const monthChangeHandler = ({ value }) => {
        if (value.length > 2) {
            value = onboardingForm.ownersDetails.dateOfBirth?.month || '';
        }

        if (value.length > 1 || (value.length === 1 && value > 1)) {
            setTimeout(() => {
                yearInputElementRef.current.focus();
            });
        }

        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                dateOfBirth: {
                    ...onboardingForm.ownersDetails.dateOfBirth,
                    month: value
                }
            }
        });
    };

    const yearChangeHandler = ({ value }) => {
        if (value.length > 4) {
            value = onboardingForm.ownersDetails.dateOfBirth?.year || '';
        }

        if (value.length > 3) {
            setTimeout(() => {
                emailInputElementRef.current.focus();
            });
        }

        setOnboardingForm({
            ...onboardingForm,
            ownersDetails: {
                ...onboardingForm.ownersDetails,
                dateOfBirth: {
                    ...onboardingForm.ownersDetails.dateOfBirth,
                    year: value
                }
            }
        });
    };

    return (
        <OnboardingOwnerDetailsComponent
            {...props}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            shouldNextStep={shouldNextStep}
            setShouldNextStep={setShouldNextStep}
            onSubmit={onSubmit}
            setPersonDetails={setPersonDetails}
            oneHundredValues={oneHundredValues}
            dobValidationFailed={dobValidationFailed}
            isT2SMerchant={isT2SMerchant}
            dobInvalidFields={dobInvalidFields}
            monthInputElementRef={monthInputElementRef}
            yearInputElementRef={yearInputElementRef}
            emailInputElementRef={emailInputElementRef}
            dayChangeHandler={dayChangeHandler}
            monthChangeHandler={monthChangeHandler}
            yearChangeHandler={yearChangeHandler}
        />
    );
}

export default OnboardingOwnerDetails;
