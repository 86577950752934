import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import PaymentsListComponent from './payments-list.component';
import { PaymentsService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { errorCodes } from 'utils/internalStatusCode';
import { isDateBeforeCutOffDate, dateRangeArchived } from 'utils/calender-helpers.js';
import { REACT_APP_CARD_PAYMENT_ARCHIVE_DATE } from 'utils/constants';

const PaymentsList = (props) => {
    const [error, setError] = useState(false);
    const [payments, setPayments] = useState([]);
    const [total, setTotal] = useState(null);
    const [isRefundHappen, setIsRefundHappen] = useState(null);
    const [paymentVoidSnackbar, setPaymentVoidSnackbar] = useState({ type: '', message: '' });
    const { loading, setLoading, dates, daterange, showAcquirer, selectedAcquirer, acquirerName } = props;
    const { date, via } = props.transactionsListingOptions ? props.transactionsListingOptions : props.match.params;
    const { globalState } = useGlobalStateContext();

    const getPaymentsList = async () => {
        const isWeekly = via === 'week' ? true : false;
        const weekStart = moment(date).startOf('week').format('YYYY-MM-DD');
        let from = isWeekly ? weekStart : date;
        if (via === 'year' || via === 'all') {
            setPayments([]);
            setTotal(0);
        } else {
            if (!isDateBeforeCutOffDate(from)) {
                const paymentsList = await PaymentsService.GetPayments(globalState.merchant?.id, {
                    //merchantId,
                    from,
                    via: via.toUpperCase()
                });
                if (paymentsList.isSuccesfully) {
                    const filteredPayments = selectedAcquirer
                        ? selectedAcquirer.length === 0 || selectedAcquirer.includes('SELECT ALL')
                            ? paymentsList.data.payments
                            : paymentsList.data.payments.filter(({ payment_provider }) =>
                                  selectedAcquirer.includes(payment_provider)
                              )
                        : paymentsList.data.payments;
                    setPayments(filteredPayments);
                    setTotal(paymentsList.data.total);
                    setError(false);
                } else {
                    setError(true);
                }
            } else {
                setPayments([]);
                setTotal(0);
            }
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        if (!isDateBeforeCutOffDate(date.from)) {
            globalState.merchant && getPaymentsList();
        }
        // eslint-disable-next-line
    }, [props.location.pathname, date, via, globalState.merchant, isRefundHappen, props.selectedAcquirer]);

    useEffect(() => {
        const getTransactions = async () => {
            try {
                let getTxnList = {
                    data: { payments: [] },
                    isSuccesfully: true,
                    props: false
                };

                if (dates) {
                    const promises = dates.map(async (date) => await getAllPaymentsForRange(date, getTxnList));
                    await Promise.all(promises);

                    getTxnList.data.payments = getTxnList.data.payments.filter((a) => {
                        return (
                            moment(a.time).isAfter(moment(daterange.start)) &&
                            moment(a.time).isBefore(moment(daterange.end).add(1, 'day'))
                        );
                    });

                    getTxnList.data.payments.sort((a, b) => (moment(a.time) < moment(b.time) ? 1 : -1));
                    setPayments(getTxnList.data.payments);
                    setTotal(getTxnList.data.total);
                }
            } catch {
                setError(true);
            }
            setLoading(false);
        };
        setLoading(true);
        getTransactions();
        // eslint-disable-next-line
    }, [dates, daterange]);

    async function getAllPaymentsForRange(date, getTxnList) {
        if (dateRangeArchived(date.from, date.to)) {
            setPayments([]);
        } else {
            if (isDateBeforeCutOffDate(date.from)) {
                date.from = moment(REACT_APP_CARD_PAYMENT_ARCHIVE_DATE, 'DD-MM-YYYY').format('YYYY-MM-DD');
            }
            const data = {
                from: date.from,
                to: date.to,
                via: 'RANGE'
            };
            const response = await PaymentsService.GetPayments(globalState.merchant?.id, data);
            if (!response.isSuccesfully) {
                getTxnList.isSuccesfully = response.isSuccesfully;
            }
            getTxnList.data.payments = [...getTxnList.data.payments, ...response.data.payments];
        }
    }

    const handleVoidTransaction = async (row) => {
        setLoading(true);
        let paymentId = row.id;
        const response = await PaymentsService.VoidIncorrectTransaction(paymentId);
        if (response.isSuccesfully) {
            if (response?.data?.status === errorCodes.SUCCESSFUL_VOID_TRANSACTION.statusCode) {
                setPaymentVoidSnackbar({ type: 'success', message: response.data.message });
                getPaymentsList();
            } else setPaymentVoidSnackbar({ type: 'error', message: response?.data?.message });
        } else {
            setPaymentVoidSnackbar({ type: 'error', message: 'An error occurred' });
        }
        setLoading(false);
    };

    return (
        <PaymentsListComponent
            {...props}
            loading={loading}
            error={error}
            payments={payments}
            total={total}
            setIsRefundHappen={setIsRefundHappen}
            isRefundHappen={isRefundHappen}
            showAcquirer={showAcquirer}
            selectedAcquirer={selectedAcquirer}
            acquirerName={acquirerName}
            via={via}
            setPayments={setPayments}
            paymentVoidSnackbar={paymentVoidSnackbar}
            setPaymentVoidSnackbar={setPaymentVoidSnackbar}
            handleVoidTransaction={handleVoidTransaction}
        />
    );
};

export default PaymentsList;

PaymentsList.propTypes = {
    selectedAcquirer: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string.isRequired,
            via: PropTypes.string.isRequired
        })
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    })
};
