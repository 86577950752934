import React, { useState, useEffect } from 'react';
import ModalExportReportComponent from './modal-export-report.component.jsx';
import moment from 'moment';
import _ from 'lodash';
import {
    PaymentsService,
    PdqTransactionsService,
    MerchantChargesService,
    PayoutsService,
    InternalTransfersService,
    ChargebacksService,
    TaxonomyService,
    BusinessDetailsService
} from 'services';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import {
    formatDateTimeByFormatString,
    formatAmount,
    toTitleCase,
    merchantTypes,
    filterDescription,
    filterRefundDescription
} from 'utils/helper';
import { useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import { PdqStatus } from 'utils/enums/pdqTransactionStatus.js';
import { PdqFilterTabs } from 'utils/enums/PdqFilterTabs.js';
import { statusToFilter } from 'utils/enums/pdqTransactionStatus.js';
import { AcquirersNameMap } from 'utils/enums/Acquirers';
import { AcquirersToAcquirerName, ReverseAcquirersToAcquirerName } from 'utils/enums/Acquirers';
import { PaymentStatus } from 'utils/enums/PaymentStatus.js';
import { isDateBeforeCutOffDate } from 'utils/calender-helpers.js';
import { REACT_APP_CARD_PAYMENT_ARCHIVE_DATE } from 'utils/constants';
const ModalExportReport = (props) => {
    const {
        path,
        timeFrameDetails,
        reportType,
        csvData,
        payOutDetails,
        payoutStartDate,
        payoutEndDate,
        setShowReportModel,
        selectedAcquirer,
        showReportModel,
        daysRange,
        setDaysRange,
        isAdmin
    } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [intervalStart, setIntervalStart] = useState(null);
    const [intervalEnd, setIntervalEnd] = useState(null);
    const [enableDownload, setEnableDownload] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [longRun, setLongRun] = useState(false);
    const [buttonLoad, setButtonLoad] = useState(false);
    const { globalState } = useGlobalStateContext();
    const merchantId = globalState?.merchant?.id;
    const [merchantTradingAddress, setMerchantTradingAddress] = useState({});
    const [merchantLegalName, setMerchantLegalName] = useState('');
    const [merchantMobile, setMerchantMobile] = useState(null);
    const [companyAddress, setCompanyAddress] = useState({});
    const [showCharges, setShowCharges] = useState(false);
    const [withChargeData, setWithChargeData] = useState([]);
    const [showGFOTransaction, setShowGFOTransaction] = useState(false);
    const [paymentsFinanceDetails, setPaymentsFinanceDetails] = useState({ total: 0, net: 0, fee: 0, number: 0 });
    const [GFOPaymentsFinanceDetails, setGFOPaymentsFinanceDetails] = useState({ total: 0, net: 0, fee: 0, number: 0 });
    const [allPayments, setAllPayments] = useState([]);
    const paymentProvider = ['CHECKOUT-GFO', 'ADYEN'];
    let totalGrossAmount = 0;
    let totalNetAmount = 0;
    let totalFeesAmount = 0;
    let totalTransactionNumber = 0;

    // PDQ transactions

    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);

            if (path === 'pdq-transactions') {
                let pdqTransactionsRequest = {
                    merchantId: merchantId,
                    year: moment(timeFrameDetails.date, 'YYYY-MM-DD').year(),
                    month: moment(timeFrameDetails.date).month() + 1,
                    day: moment(timeFrameDetails.date, 'YYYY-MM-DD').date()
                };
                const pdqTransactions = await PdqTransactionsService.GetPdqTransactionsList(pdqTransactionsRequest);
                if (pdqTransactions.isSuccesfully) {
                    let startingTimestamp = moment(
                        `${pdqTransactionsRequest.day}-${pdqTransactionsRequest.month}-${pdqTransactionsRequest.year}`,
                        'DD-MM-YYYY'
                    )
                        .startOf(timeFrameDetails.via)
                        .valueOf();
                    let endingTimestamp = moment(
                        `${pdqTransactionsRequest.day}-${pdqTransactionsRequest.month}-${pdqTransactionsRequest.year}`,
                        'DD-MM-YYYY'
                    )
                        .endOf(timeFrameDetails.via)
                        .valueOf();
                    let transactionInTimeFrame = _.filter(pdqTransactions.data.data, function (pdqRecord) {
                        let date_time = moment(moment.utc(pdqRecord.date_time).format('YYYY-MM-DD')).valueOf();
                        return date_time >= startingTimestamp && date_time <= endingTimestamp;
                    });
                    transactionInTimeFrame = _.map(transactionInTimeFrame, (pdqRecord) => {
                        let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                        let tempPDQObj = {
                            Amount: `${currenySymbol.toString()} ${formatAmount(pdqRecord.total)}`,
                            Date: formatDateTimeByFormatString(pdqRecord.date_time, 'DD MMM HH:mm'),
                            'Auth code': pdqRecord.auth_code
                        };
                        if (globalState.reseller?.name === ResellerType.OMNIPAY) {
                            tempPDQObj = {
                                ...tempPDQObj,
                                Status: toTitleCase(
                                    pdqRecord.refund_status === PdqFilterTabs.Refunded &&
                                        pdqRecord.transaction_status_id === statusToFilter.Success
                                        ? 'Refunded'
                                        : PdqStatus[pdqRecord.transaction_status_id]
                                ),
                                Amount:
                                    pdqRecord.refund_status === PdqFilterTabs.Refunded
                                        ? `${currenySymbol.toString()} -${formatAmount(pdqRecord.total)}`
                                        : `${currenySymbol.toString()} ${formatAmount(pdqRecord.total)}`
                            };
                        }

                        return tempPDQObj;
                    });

                    setReportData(transactionInTimeFrame);
                    setIntervalStart(moment(startingTimestamp).format('YYYY-MM-DD'));
                    setIntervalEnd(moment(endingTimestamp).format('YYYY-MM-DD'));
                    setError(false);
                } else {
                    setError(true);
                }
                setLoading(false);
                setEnableDownload(true);
            }
        };

        if (path === 'pdq-transactions') {
            retrieveAndSetReportData();
        }
        // eslint-disable-next-line
    }, [path]);

    // Merchant Charges
    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);

            if (path === 'merchant-charges') {
                let merchantChargesRequest = {
                    merchantId: merchantId,
                    day: moment(timeFrameDetails.date, 'YYYY-MM-DD').date(),
                    month: moment(timeFrameDetails.date, 'YYYY-MM-DD').month() + 1,
                    year: moment(timeFrameDetails.date, 'YYYY-MM-DD').year(),
                    week: moment(timeFrameDetails.date, 'YYYY-MM-DD').isoWeek(),
                    via: timeFrameDetails.via.toUpperCase()
                };
                const merchantChargesList = await MerchantChargesService.getMerchantChargesList(merchantChargesRequest);
                if (merchantChargesList.isSuccesfully) {
                    const paidStatus = function (merchantCharge) {
                        return merchantCharge.paid_status === 1 ? 'Paid' : 'Pending';
                    };
                    let startingTimestamp = moment(
                        `${merchantChargesRequest.day}-${merchantChargesRequest.month}-${merchantChargesRequest.year}`,
                        'DD-MM-YYYY'
                    )
                        .startOf(timeFrameDetails.via)
                        .valueOf();
                    let endingTimestamp = moment(
                        `${merchantChargesRequest.day}-${merchantChargesRequest.month}-${merchantChargesRequest.year}`,
                        'DD-MM-YYYY'
                    )
                        .endOf(timeFrameDetails.via)
                        .valueOf();

                    let transactionInTimeFrame = _.filter(merchantChargesList.data.data.data, function (
                        merchantCharge
                    ) {
                        let date_sent = moment.utc(merchantCharge.date_sent).valueOf();
                        return date_sent >= startingTimestamp && date_sent <= endingTimestamp;
                    });

                    transactionInTimeFrame = _.map(transactionInTimeFrame, (merchantCharge) => {
                        let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                        return {
                            'Invoice Ref': merchantCharge.id,
                            'Service provided': merchantCharge.service_description
                                ? merchantCharge.service_description
                                : '--',
                            Date: formatDateTimeByFormatString(merchantCharge.date_sent, 'DD MMM YYYY'),

                            Status: paidStatus(merchantCharge),
                            Amount: `${currenySymbol.toString()} ${formatAmount(merchantCharge.amount)}`
                        };
                    });

                    setReportData(transactionInTimeFrame);
                    setIntervalStart(moment(startingTimestamp).format('YYYY-MM-DD'));
                    setIntervalEnd(moment(endingTimestamp).format('YYYY-MM-DD'));
                    setError(false);
                } else {
                    setError(true);
                }

                setLoading(false);
                setEnableDownload(true);
            }
        };

        // getMerchantChargesList();
        if (path === 'merchant-charges') {
            retrieveAndSetReportData();
        }
        // eslint-disable-next-line
    }, [path]);
    useEffect(() => {
        const getContract = async () => {
            setLoading(true);
            const merchantResponse = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);
            if (merchantResponse.isSuccesfully) {
                setMerchantLegalName(globalState.merchant.legalName);
                if (
                    [1, 2].includes(globalState.merchant?.canonicalResellerId) &&
                    globalState.reseller?.name === ResellerType.OMNIPAY
                ) {
                    setMerchantTradingAddress(merchantResponse.data.ownerDetails);
                    setMerchantMobile(merchantResponse.data?.ownerDetails?.telephoneNumber);
                } else {
                    setMerchantTradingAddress(merchantResponse.data.businessDetails);
                    setMerchantMobile(merchantResponse.data?.businessDetails?.telephoneNumber);
                }
            }
            setLoading(false);
        };
        getContract();
        // eslint-disable-next-line
    }, [globalState.merchant]);

    // Payouts
    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);
            if (path === 'payouts') {
                let payoutsTransactionsRequest = {
                    merchantId: merchantId,
                    day: moment(timeFrameDetails.date, 'YYYY-MM-DD').date(),
                    month: moment(timeFrameDetails.date, 'YYYY-MM-DD').month() + 1,
                    year: moment(timeFrameDetails.date, 'YYYY-MM-DD').year(),
                    week: moment(timeFrameDetails.date).isoWeek(),
                    via: 'MONTH'
                };
                const payoutsList = await PayoutsService.getAllPayouts(merchantId, {
                    selectedDate: timeFrameDetails.date
                });

                if (payoutsList.isSuccesfully) {
                    const selectedProvider = sessionStorage.getItem('selectedProvider');
                    let startingTimestamp = moment(
                        `${payoutsTransactionsRequest.day}-${payoutsTransactionsRequest.month}-${payoutsTransactionsRequest.year}`,
                        'DD-MM-YYYY'
                    )
                        .startOf(timeFrameDetails.via)
                        .valueOf();
                    let endingTimestamp = moment(
                        `${payoutsTransactionsRequest.day}-${payoutsTransactionsRequest.month}-${payoutsTransactionsRequest.year}`,
                        'DD-MM-YYYY'
                    )
                        .endOf(timeFrameDetails.via)
                        .valueOf();
                    let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                    let transactionInTimeFrame = [];

                    const filterInBatch = filterPayouts(selectedProvider, payoutsList.data.transaction_data.in_batch);
                    const inBatchPayouts = filterInBatch.map((payout) => {
                        const dateExpected = !payout.expected_date
                            ? '--'
                            : payout.provider === AcquirersNameMap.STRIPE
                            ? formatDateTimeByFormatString(moment.unix(payout.arrival_date), 'DD MMM HH:mm')
                            : formatDateTimeByFormatString(payout.expected_date, 'DD MMM HH:mm');

                        let tempObj = {
                            Amount: `${currenySymbol.toString()} ${formatAmount(
                                payout.provider === AcquirersNameMap.STRIPE ? payout.total / 100 : payout.total
                            )}`,
                            'Date Expected': dateExpected,
                            Status: payout.not_received === 1 ? 'Not Received ' : toTitleCase(payout.status)
                        };
                        return tempObj;
                    });

                    let inNotInBatchTimeFrame = [];
                    if (payoutsList.data.transaction_data.not_in_batch.length !== 0) {
                        const notInBatchPayouts = filterPayouts(
                            selectedProvider,
                            payoutsList.data.transaction_data.not_in_batch
                        );
                        inNotInBatchTimeFrame = notInBatchPayouts.map((payouts) => {
                            let tempObj = {
                                Amount: `${currenySymbol.toString()} ${formatAmount(payouts.total)}`,
                                'Date Expected': formatDateTimeByFormatString(payouts.expected_date, 'DD MMM HH:mm'),
                                Status: 'Pending'
                            };
                            return tempObj;
                        });
                    }
                    transactionInTimeFrame = [...inBatchPayouts, ...inNotInBatchTimeFrame];

                    setReportData(transactionInTimeFrame);
                    setIntervalStart(moment(startingTimestamp).format('YYYY-MM-DD'));
                    setIntervalEnd(moment(endingTimestamp).format('YYYY-MM-DD'));
                    setError(false);
                } else {
                    setError(true);
                }

                setLoading(false);
                setEnableDownload(true);
            }
        };
        if (path === 'payouts') {
            retrieveAndSetReportData();
        }

        // eslint-disable-next-line
    }, [path]);
    const filterPayouts = (selectedProvider, payoutList = []) => {
        let filteredList = [...payoutList];
        if (selectedProvider && selectedProvider !== AcquirersToAcquirerName.ALL) {
            filteredList = payoutList.filter((payout) => {
                return payout.provider === ReverseAcquirersToAcquirerName[selectedProvider];
            });
        }
        return filteredList;
    };

    const formatRefundReasonAndAmountString = (str) => {
        str = str.replace('&pound;', '');
        str = str.replace('\n', '');
        str = str.replace('<hr>', '');
        str = str.replace('<hr>', '');
        str = str.replace('because', ' - ');
        str = str.replace(' ', '');
        let arr = str.split('.');
        let str2 = `${arr[0]}.${arr[1] ? arr[1] : ''}`;
        return str2;
    };
    // Payments
    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);
            let from = timeFrameDetails.date;
            let to = timeFrameDetails.date;
            let paymentFrom = moment(timeFrameDetails.date).format('YYYY-MM-DD');
            let paymentTo = moment(timeFrameDetails.date).format('YYYY-MM-DD');
            let transactionsRequest = {
                from,
                via: timeFrameDetails.via.toUpperCase()
            };
            if (!_.isEmpty(daysRange)) {
                paymentFrom = moment(daysRange.startDate).format('YYYY-MM-DD');
                paymentTo = moment(daysRange.endDate).add(1, 'days').format('YYYY-MM-DD');
                transactionsRequest = {
                    from: paymentFrom,
                    to: paymentTo,
                    via: 'RANGE'
                };
            } else if (timeFrameDetails.via === 'month') {
                paymentFrom = moment(timeFrameDetails.date).startOf('month').format('YYYY-MM-DD');
                paymentTo = moment(timeFrameDetails.date).endOf('month').format('YYYY-MM-DD');
                transactionsRequest = {
                    from: paymentFrom,
                    via: timeFrameDetails.via.toUpperCase()
                };
            } else if (timeFrameDetails.via === 'week') {
                from = moment(timeFrameDetails.date).startOf('week').format('YYYY-MM-DD');
                to = moment(timeFrameDetails.date).endOf('week').format('YYYY-MM-DD');
                paymentFrom = from;
                paymentTo = to;
                transactionsRequest = {
                    from: paymentFrom,
                    via: timeFrameDetails.via.toUpperCase()
                };
            }
            if (isDateBeforeCutOffDate(transactionsRequest.from)) {
                transactionsRequest.from = moment(REACT_APP_CARD_PAYMENT_ARCHIVE_DATE, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                );
            }

            let transactions;
            if (!allPayments || allPayments.length === 0) {
                transactions = await PaymentsService.GetPayments(globalState.merchant?.id, transactionsRequest);
            }

            if (transactions?.isSuccesfully || allPayments.length > 0) {
                let filteredPayments = transactions?.data?.payments || allPayments;
                setAllPayments(filteredPayments);
                if (!showGFOTransaction && isAdmin) {
                    filteredPayments =
                        filteredPayments?.filter(({ payment_provider }) =>
                            isAdmin &&
                            globalState?.reseller?.name === ResellerType.DATMAN &&
                            selectedAcquirer?.length !== 0
                                ? selectedAcquirer?.includes(payment_provider) &&
                                  !paymentProvider?.includes(payment_provider)
                                : !paymentProvider?.includes(payment_provider)
                        ) || [];
                } else {
                    filteredPayments =
                        isAdmin &&
                        globalState.reseller?.name === ResellerType.DATMAN &&
                        selectedAcquirer?.length !== 0 &&
                        filteredPayments.length > 0
                            ? filteredPayments.filter(({ payment_provider }) =>
                                  selectedAcquirer.includes(payment_provider)
                              )
                            : filteredPayments;
                }
                let transactionInTimeFrame = tempData(filteredPayments);
                let withChargesData = tempData(filteredPayments, true);
                let headerCellsExport = {
                    Amount: `${getCurrencyThreeAlpha(globalState.merchant?.country)} ${totalGrossAmount.toFixed(2)}`,
                    'Transaction details': `Total of ${totalTransactionNumber} transactions`
                };

                let headerCellsWithChargesExport = {
                    Amount: `${getCurrencyThreeAlpha(globalState.merchant?.country)} ${totalGrossAmount.toFixed(2)}`,
                    Fee: `${totalFeesAmount.toFixed(2)}`,
                    Net: `${totalNetAmount.toFixed(2)}`,
                    'Transaction details': `Total of ${totalTransactionNumber} transactions`
                };
                withChargesData.push({});
                transactionInTimeFrame.push({});
                withChargesData.push(headerCellsWithChargesExport);
                transactionInTimeFrame.push(headerCellsExport);
                setReportData(transactionInTimeFrame);
                setWithChargeData(withChargesData);
                setIntervalStart(moment(paymentFrom).format('YYYY-MM-DD'));
                setIntervalEnd(moment(paymentTo).format('YYYY-MM-DD'));
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
            setEnableDownload(true);
            setPaymentsFinanceDetails({
                total: totalGrossAmount,
                net: totalNetAmount,
                fee: totalFeesAmount,
                number: totalTransactionNumber
            });
        };
        if (path === 'payments' && timeFrameDetails.via !== 'year' && timeFrameDetails.via !== 'all') {
            retrieveAndSetReportData();
        }
        // eslint-disable-next-line
    }, [path, timeFrameDetails, showGFOTransaction]);
    const tempData = (filteredPayments, charges) => {
        totalGrossAmount = 0;
        totalFeesAmount = 0;
        totalNetAmount = 0;
        totalTransactionNumber = 0;
        let data = _.map(filteredPayments, (records) => {
            let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
            let tempDetails = _.compact([
                _.compact([records.firstname?.trim(), records.lastname?.trim()]).join(' '),
                records.address?.trim()
            ]).join(' - ');
            totalTransactionNumber++;
            totalGrossAmount = parseFloat(totalGrossAmount) + parseFloat(records.total);
            totalFeesAmount = parseFloat(totalFeesAmount) + parseFloat(records.fees);
            totalNetAmount = parseFloat(totalNetAmount) + parseFloat(records.total) - parseFloat(records.fees);
            let transactionDetails = records.refund
                ? `${formatRefundReasonAndAmountString(records.refund)} ${tempDetails}`
                : tempDetails;
            transactionDetails = transactionDetails.replace('GMT+0000 (Coordinated Universal Time)', '');
            let tempPaymentStartObj = {
                Amount: `${currenySymbol.toString()} ${formatAmount(records.total)}`
            };

            charges &&
                (tempPaymentStartObj = {
                    ...tempPaymentStartObj,
                    Fee: formatAmount(records.fees),
                    Net: formatAmount(records.payed)
                });

            tempPaymentStartObj = {
                ...tempPaymentStartObj,
                'Merchant name': records.merchant_name,
                'Transaction details': transactionDetails ? transactionDetails : '--'
            };
            if (globalState.reseller?.name === ResellerType.OMNIPAY) {
                tempPaymentStartObj = {
                    ...tempPaymentStartObj,
                    Description:
                        +records.total < 0
                            ? filterRefundDescription(records.refund) || '-'
                            : records.more_info
                            ? filterDescription(records.more_info)
                            : records.description
                            ? records.description
                            : '--'
                };
            }
            let status = records.payment_status
                ? Number(records.total) > 0
                    ? toTitleCase(PaymentStatus[records.payment_status])
                    : 'Refunded'
                : (Number(records.total) <= 0 && records.refund) || records.firstname.includes('Charge back')
                ? 'Refunded'
                : 'Success';
            const showMerchantName = merchantTypes.includes(globalState.merchant?.merchantType);
            if (!showMerchantName) delete tempPaymentStartObj[`Merchant name`];

            let tempPaymentEndObj = {
                Date: moment(records.time).format('DD-MM-YYYY HH:mm:ss'),
                Status: status
            };
            let tempPaymentObj = { ...tempPaymentStartObj, ...tempPaymentEndObj };
            return tempPaymentObj;
        });
        return data;
    };
    // GFO Payments
    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);

            let from = timeFrameDetails.date;
            let to = timeFrameDetails.date;
            let gfoPaymentFrom = moment(timeFrameDetails.date).format('YYYY-MM-DD');
            let gfoPaymentTo = moment(timeFrameDetails.date).format('YYYY-MM-DD');
            let gfoTransactionsRequest = {
                from,
                via: timeFrameDetails.via.toUpperCase()
            };
            if (!_.isEmpty(daysRange)) {
                gfoPaymentFrom = moment(daysRange.startDate).format('YYYY-MM-DD');
                gfoPaymentTo = moment(daysRange.endDate).add(1, 'days').format('YYYY-MM-DD');
                gfoTransactionsRequest = {
                    from: gfoPaymentFrom,
                    to: gfoPaymentTo,
                    via: 'RANGE'
                };
            } else if (timeFrameDetails.via === 'month') {
                gfoPaymentFrom = moment(timeFrameDetails.date).startOf('month');
                gfoPaymentTo = moment(timeFrameDetails.date).endOf('month');
            } else if (timeFrameDetails.via === 'week') {
                from = moment(timeFrameDetails.date).startOf('week').format('YYYY-MM-DD');
                to = moment(timeFrameDetails.date).endOf('week').format('YYYY-MM-DD');
                gfoPaymentFrom = from;
                gfoPaymentTo = to;
            }
            const gfoTranscations = await PaymentsService.GetPayments(globalState.merchant?.id, gfoTransactionsRequest);
            if (gfoTranscations.isSuccesfully) {
                let transactionInTimeFrame = _.filter(gfoTranscations.data.payments, function (gfoRecord) {
                    return paymentProvider.includes(gfoRecord.payment_provider);
                });
                const data = tempGfoData(transactionInTimeFrame);
                const datawithCharges = tempGfoData(transactionInTimeFrame, true);
                transactionInTimeFrame = _.map(transactionInTimeFrame, (gfoRecord) => {
                    let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                    return {
                        Amount: `${currenySymbol.toString()} ${formatAmount(gfoRecord.total)}`,
                        Date: gfoRecord.time,
                        Status: !gfoRecord.refund && Number(gfoRecord.total) !== 0 ? 'Success' : 'Refunded'
                    };
                });
                let headerCellsExport = {
                    Amount: `${getCurrencyThreeAlpha(globalState.merchant?.country)} ${totalGrossAmount.toFixed(2)}`,
                    'Transaction details': `Total of ${totalTransactionNumber} transactions`
                };

                let headerCellsWithChargesExport = {
                    Amount: `${getCurrencyThreeAlpha(globalState.merchant?.country)} ${totalGrossAmount.toFixed(2)}`,
                    Fee: `${totalFeesAmount.toFixed(2)}`,
                    Net: `${totalNetAmount.toFixed(2)}`,
                    'Transaction details': `Total of ${totalTransactionNumber} transactions`
                };
                datawithCharges.push({});
                datawithCharges.push(headerCellsWithChargesExport);
                data.push({});
                data.push(headerCellsExport);

                setWithChargeData(datawithCharges);

                setReportData(data);
                setGFOPaymentsFinanceDetails({
                    total: totalGrossAmount,
                    net: totalNetAmount,
                    fee: totalFeesAmount,
                    number: totalTransactionNumber
                });
                setIntervalStart(moment(gfoPaymentFrom).format('YYYY-MM-DD'));
                setIntervalEnd(moment(gfoPaymentTo).format('YYYY-MM-DD'));
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
            setEnableDownload(true);
        };

        if (path === 'gfo-payments') {
            retrieveAndSetReportData();
        }
        // eslint-disable-next-line
    }, [path]);
    const tempGfoData = (transactionInTimeFrame, charges) => {
        totalGrossAmount = 0;
        totalFeesAmount = 0;
        totalNetAmount = 0;
        totalTransactionNumber = 0;
        let data;
        charges
            ? (data = _.map(transactionInTimeFrame, (gfoRecord) => {
                  let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                  let tempDetails = _.compact([
                      _.compact([gfoRecord.firstname?.trim(), gfoRecord.lastname?.trim()]).join(' '),
                      gfoRecord.address?.trim()
                  ]).join(' - ');
                  totalTransactionNumber++;
                  totalGrossAmount = parseFloat(totalGrossAmount) + parseFloat(gfoRecord.total);
                  totalFeesAmount = parseFloat(totalFeesAmount) + parseFloat(gfoRecord.fees);
                  totalNetAmount =
                      parseFloat(totalNetAmount) + parseFloat(gfoRecord.total) - parseFloat(gfoRecord.fees);
                  let transactionDetails = gfoRecord.refund
                      ? `${formatRefundReasonAndAmountString(gfoRecord.refund)} ${tempDetails}`
                      : tempDetails;
                  transactionDetails = transactionDetails.replace('GMT+0000 (Coordinated Universal Time)', '');
                  return {
                      Amount: `${currenySymbol.toString()} ${formatAmount(gfoRecord.total)}`,
                      Fee: formatAmount(gfoRecord.fees),
                      Net: formatAmount(gfoRecord.payed),
                      'Transaction details': transactionDetails,
                      Date: gfoRecord.time,
                      Status: !gfoRecord.refund && Number(gfoRecord.total) !== 0 ? 'Success' : 'Refunded'
                  };
              }))
            : (data = _.map(transactionInTimeFrame, (gfoRecord) => {
                  let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                  let tempDetails = _.compact([
                      _.compact([gfoRecord.firstname?.trim(), gfoRecord.lastname?.trim()]).join(' '),
                      gfoRecord.address?.trim()
                  ]).join(' - ');
                  totalTransactionNumber++;
                  totalGrossAmount = parseFloat(totalGrossAmount) + parseFloat(gfoRecord.total);
                  totalFeesAmount = parseFloat(totalFeesAmount) + parseFloat(gfoRecord.fees);
                  totalNetAmount =
                      parseFloat(totalNetAmount) + parseFloat(gfoRecord.total) - parseFloat(gfoRecord.fees);
                  let transactionDetails = gfoRecord.refund
                      ? `${formatRefundReasonAndAmountString(gfoRecord.refund)} ${tempDetails}`
                      : tempDetails;
                  transactionDetails = transactionDetails.replace('GMT+0000 (Coordinated Universal Time)', '');
                  return {
                      Amount: `${currenySymbol.toString()} ${formatAmount(gfoRecord.total)}`,
                      'Transaction details': transactionDetails,
                      Date: gfoRecord.time,
                      Status: !gfoRecord.refund && Number(gfoRecord.total) !== 0 ? 'Success' : 'Refunded'
                  };
              }));
        return data;
    };
    // Chargebacks
    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);
            let chargebacksTransactionsRequest = {
                merchantId: merchantId,
                day: moment(timeFrameDetails.date, 'YYYY-MM-DD').date(),
                month: moment(timeFrameDetails.date).month() + 1,
                week: moment(timeFrameDetails.date).isoWeek(),
                year: moment(timeFrameDetails.date, 'YYYY-MM-DD').year(),
                via: 'MONTH'
            };
            const chargebacksList = await ChargebacksService.getChargebacksList(chargebacksTransactionsRequest);
            if (chargebacksList.isSuccesfully) {
                let startingTimestamp = moment(
                    `${chargebacksTransactionsRequest.day}-${chargebacksTransactionsRequest.month}-${chargebacksTransactionsRequest.year}`,
                    'DD-MM-YYYY'
                )
                    .startOf(timeFrameDetails.via)
                    .valueOf();
                let endingTimestamp = moment(
                    `${chargebacksTransactionsRequest.day}-${chargebacksTransactionsRequest.month}-${chargebacksTransactionsRequest.year}`,
                    'DD-MM-YYYY'
                )
                    .endOf(timeFrameDetails.via)
                    .valueOf();

                let transactionInTimeFrame = _.filter(chargebacksList.data.data);
                transactionInTimeFrame = _.map(transactionInTimeFrame, (chargebacks) => {
                    let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                    let status =
                        chargebacks.outcome === 'NOTRESOLVED'
                            ? chargebacks.outcome.replace(/^(.{3})(.*)$/, '$1 $2')
                            : chargebacks.outcome;
                    return {
                        Amount: `${currenySymbol.toString()} ${formatAmount(chargebacks.total)}`,
                        Date: formatDateTimeByFormatString(chargebacks.time, 'DD MMM HH:mm'),
                        Address: chargebacks.address,
                        'Chargeback reason': chargebacks.cb_reason ? toTitleCase(chargebacks.cb_reason) : '--',
                        Status: toTitleCase(status)
                    };
                });
                setReportData(transactionInTimeFrame);
                setIntervalStart(moment(startingTimestamp).format('YYYY-MM-DD'));
                setIntervalEnd(moment(endingTimestamp).format('YYYY-MM-DD'));
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
            setEnableDownload(true);
        };
        if (path === 'chargebacks') {
            retrieveAndSetReportData();
        }
        // eslint-disable-next-line
    }, [path]);

    // Payout details
    useEffect(() => {
        if (payOutDetails) {
            setReportData(csvData);
            setEnableDownload(true);
            setIntervalStart(payoutStartDate);
            setIntervalEnd(payoutEndDate);
        }
        // eslint-disable-next-line
    }, [payOutDetails]);

    // Internal Transfers
    useEffect(() => {
        const retrieveAndSetReportData = async () => {
            setLoading(true);
            let internalTransferTransactions = {
                merchantId: merchantId,
                day: moment(timeFrameDetails.date, 'YYYY-MM-DD').date(),
                month: moment(timeFrameDetails.date).month() + 1,
                week: moment(timeFrameDetails.date).isoWeek(),
                year: moment(timeFrameDetails.date, 'YYYY-MM-DD').year(),
                via: 'MONTH'
            };
            const internalTransfersData = await InternalTransfersService.getInternalTransfersList(
                internalTransferTransactions
            );
            if (internalTransfersData.isSuccesfully) {
                let startingTimestamp = moment(
                    `${internalTransferTransactions.day}-${internalTransferTransactions.month}-${internalTransferTransactions.year}`,
                    'DD-MM-YYYY'
                )
                    .startOf(timeFrameDetails.via)
                    .valueOf();
                let endingTimestamp = moment(
                    `${internalTransferTransactions.day}-${internalTransferTransactions.month}-${internalTransferTransactions.year}`,
                    'DD-MM-YYYY'
                )
                    .endOf(timeFrameDetails.via)
                    .valueOf();
                let transactionInTimeFrame = _.filter(internalTransfersData.data.data.internalTransfers);
                transactionInTimeFrame = _.map(transactionInTimeFrame, (iTRecord) => {
                    let currenySymbol = getCurrencyThreeAlpha(globalState?.merchant?.country);
                    return {
                        Amount: `${currenySymbol.toString()} ${formatAmount(iTRecord.amount)}`,
                        Supplier: iTRecord.supplier,
                        Date: formatDateTimeByFormatString(iTRecord.created_at, 'DD MMM HH:mm'),
                        Description: iTRecord.description ? iTRecord.description : '--',
                        Status: toTitleCase(iTRecord.status)
                    };
                });

                setReportData(transactionInTimeFrame);
                setIntervalStart(moment(startingTimestamp).format('YYYY-MM-DD'));
                setIntervalEnd(moment(endingTimestamp).format('YYYY-MM-DD'));
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
            setEnableDownload(true);
        };
        if (path === 'internal-transfers') {
            retrieveAndSetReportData();
        }
        // eslint-disable-next-line
    }, [path]);

    // Address Fetch
    useEffect(() => {
        const fetchAddress = async () => {
            if (showReportModel) {
                const response = await TaxonomyService.getResellersAPI();
                let filteredAddress = response.filter((data) => data.id === globalState.reseller?.id);
                _.map(filteredAddress, (getAddress) => {
                    setCompanyAddress(getAddress.address);
                });
            }
        };
        fetchAddress();
    }, [showReportModel, globalState.reseller.id]);

    async function fetchReport(reportId) {
        try {
            const result = await PaymentsService.fetchStripePaymentReport(merchantId, reportId);
            return result;
        } catch (e) {
            let result = { status: 503 };
            return result;
        }
    }

    const onRetryStripeReport = async (reportId) => {
        setLoading(true);
        setEnableDownload(false);
        const result = await fetchReport(reportId);
        if (result.isSuccesfully) {
            const lineCount = result.data?.split('\n').length;

            if (lineCount > 2) {
                setReportData(result.data);
            }
        } else setLongRun(true);
        setLoading(false);
        setEnableDownload(true);
    };

    return (
        <ModalExportReportComponent
            {...props}
            error={error}
            loading={loading}
            download={enableDownload}
            longRun={longRun}
            reportData={reportData}
            intervalStart={intervalStart}
            intervalEnd={intervalEnd}
            onRetryStripeReport={onRetryStripeReport}
            reportType={reportType}
            setButtonLoad={setButtonLoad}
            buttonLoad={buttonLoad}
            merchant={globalState.merchant}
            merchantTradingAddress={merchantTradingAddress}
            merchantLegalName={merchantLegalName}
            merchantMobile={merchantMobile}
            setShowReportModel={setShowReportModel}
            showReportModel={showReportModel}
            companyAddress={companyAddress}
            daysRange={daysRange}
            setDaysRange={setDaysRange}
            timeFrameDetails={timeFrameDetails}
            setShowCharges={setShowCharges}
            showCharges={showCharges}
            withChargeData={withChargeData}
            isAdmin={isAdmin}
            path={path}
            paymentsFinanceDetails={paymentsFinanceDetails}
            GFOPaymentsFinanceDetails={GFOPaymentsFinanceDetails}
            showGFOTransaction={showGFOTransaction}
            setShowGFOTransaction={setShowGFOTransaction}
            allPayments={allPayments}
        />
    );
};

export default ModalExportReport;
