import React from 'react';
import PropTypes from 'prop-types';
import styles from './onboarding-transaction-profile-step-three.module.scss';
import {
    Label,
    NextButton,
    OnboardingFormsContainer,
    SelectedDropDown,
    Input,
    FormItem
} from 'components/onboarding/onboarding-ui-components';
import { AntSwitch } from 'components';
import { Grid, OutlinedInput, Typography } from '@material-ui/core';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { useGlobalStateContext } from 'contexts';
import NumberFormat from 'react-number-format';
import { numberFormatAllowValuesForPayments } from 'utils/helper';

function OnboardingTransactionProfileComponentStepThree(props) {
    const { globalState } = useGlobalStateContext();
    const { title, nextStep, onboardingForm, setOnboardingForm, isDisabled, oneHundredValues, onSubmit } = props;

    const isT2SMerchant = globalState.isT2SMerchant;

    function formBody() {
        return (
            <div className={styles.stepThreeContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Label>
                            <span style={{ fontWeight: 'bold' }}>Deposits</span>
                        </Label>
                    </Grid>
                    {!isT2SMerchant && (
                        <>
                            <Grid item xs={12} sm={8}>
                                <Typography color="primary" component="span">
                                    Do you take MOTO payments?
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={styles.yes_no_Switch}>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        No
                                    </Typography>
                                    <AntSwitch
                                        id="isMotoPayment"
                                        checked={onboardingForm.transactionProfile.stepThree.isMotoPayment}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepThree: {
                                                        ...onboardingForm.transactionProfile.stepThree,
                                                        isMotoPayment: e.target.checked
                                                    }
                                                }
                                            })
                                        }
                                    ></AntSwitch>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        Yes
                                    </Typography>
                                </div>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={8}>
                        <Typography color="primary" component="span">
                            Apply a max ticket value?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <div className={styles.yes_no_Switch}>
                            <Typography color="primary" component="span" className={styles.checkBoxText}>
                                No
                            </Typography>
                            <AntSwitch
                                id="isMaxTicketApplied"
                                checked={onboardingForm.transactionProfile.stepThree.isMaxTicketApplied}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        transactionProfile: {
                                            ...onboardingForm.transactionProfile,
                                            stepThree: {
                                                ...onboardingForm.transactionProfile.stepThree,
                                                isMaxTicketApplied: e.target.checked
                                            }
                                        }
                                    })
                                }
                            ></AntSwitch>
                            <Typography color="primary" component="span" className={styles.checkBoxText}>
                                Yes
                            </Typography>
                        </div>
                    </Grid>
                    {onboardingForm.transactionProfile.stepThree.isMaxTicketApplied && (
                        <Grid item xs={12}>
                            <Label text="Max Ticket Value"></Label>
                            <NumberFormat
                                customInput={OutlinedInput}
                                inputMode="numeric"
                                pattern="\d*"
                                thousandSeparator={true}
                                allowLeadingZeros={true}
                                allowNegative={false}
                                isNumericString={true}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                className={styles.customInput}
                                id="outlined-adornment-weight"
                                placeholder="Max ticket value"
                                value={checkEmptyValue(onboardingForm.transactionProfile.stepThree.maxTicketValue)}
                                onValueChange={(values) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        transactionProfile: {
                                            ...onboardingForm.transactionProfile,
                                            stepThree: {
                                                ...onboardingForm.transactionProfile.stepThree,
                                                maxTicketValue: values.value
                                            }
                                        }
                                    })
                                }
                                isAllowed={(values) => numberFormatAllowValuesForPayments(values)}
                                aria-describedby="outlined-weight-helper-text"
                                labelWidth={0}
                                required
                            />
                        </Grid>
                    )}
                    {onboardingForm.transactionProfile.stepThree.isMotoPayment && (
                        <React.Fragment>
                            <Grid item xs={12} sm={6}>
                                <span className={styles.boldText}>What % total card turnover is Moto?</span>
                                <SelectedDropDown
                                    id="totalCardTurnoverIsMoto"
                                    options={oneHundredValues}
                                    getOptionLabel={(option) => option}
                                    listPrefix={' %'}
                                    value={onboardingForm.transactionProfile.stepThree.totalCardTurnoverIsMoto}
                                    onChange={(e, newValue) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            transactionProfile: {
                                                ...onboardingForm.transactionProfile,
                                                stepThree: {
                                                    ...onboardingForm.transactionProfile.stepThree,
                                                    totalCardTurnoverIsMoto: newValue
                                                }
                                            }
                                        })
                                    }
                                ></SelectedDropDown>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <span className={styles.boldText}>
                                    How far in advance of goods provided is MOTO &#8356; taken?
                                </span>
                                <SelectedDropDown
                                    id="advanceGoodsMotoProvidedDays"
                                    options={oneHundredValues}
                                    getOptionLabel={(option) => option}
                                    listPrefix={' Days'}
                                    value={onboardingForm.transactionProfile.stepThree.advanceGoodsMotoProvidedDays}
                                    onChange={(e, newValue) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            transactionProfile: {
                                                ...onboardingForm.transactionProfile,
                                                stepThree: {
                                                    ...onboardingForm.transactionProfile.stepThree,
                                                    advanceGoodsMotoProvidedDays: newValue
                                                }
                                            }
                                        })
                                    }
                                ></SelectedDropDown>
                            </Grid>
                            <Grid item xs={12} sm={8} className={styles.autoRenewalLabel}>
                                <Typography color="primary" component="span">
                                    <span>Auto renew/recurring transactions?</span>
                                </Typography>
                            </Grid>
                            <Grid item className={styles.autoRenewalSwitch}>
                                <div className={styles.yes_no_Switch}>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        <span>No</span>
                                    </Typography>
                                    <AntSwitch
                                        id="isAutoRenewTransactions"
                                        checked={
                                            onboardingForm.transactionProfile.stepThree.isAutoRenewTransactions &&
                                            onboardingForm.transactionProfile.stepThree.isMotoPayment
                                        }
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepThree: {
                                                        ...onboardingForm.transactionProfile.stepThree,
                                                        isAutoRenewTransactions: e.target.checked
                                                    }
                                                }
                                            })
                                        }
                                    ></AntSwitch>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        <span>Yes</span>
                                    </Typography>
                                </div>
                            </Grid>
                            {onboardingForm.transactionProfile.stepThree.isAutoRenewTransactions && (
                                <Grid item xs={12}>
                                    <div className={styles.motoReasonContainer}>
                                        <FormItem>
                                            <Label
                                                text="Reason for Moto Renewal"
                                                id="moto-renewal-reason-label-id"
                                            ></Label>
                                            <Input
                                                placeholder="Reason for Moto Renewal"
                                                id="moto-renewal-reason-id"
                                                disabled={
                                                    !onboardingForm.transactionProfile.stepThree
                                                        .isAutoRenewTransactions &&
                                                    !onboardingForm.transactionProfile.stepThree.isMotoPayment
                                                }
                                                value={onboardingForm.transactionProfile.stepThree.motoRenewalReason}
                                                onChange={(e) =>
                                                    setOnboardingForm({
                                                        ...onboardingForm,
                                                        transactionProfile: {
                                                            ...onboardingForm.transactionProfile,
                                                            stepThree: {
                                                                ...onboardingForm.transactionProfile.stepThree,
                                                                motoRenewalReason: e.target.value
                                                            }
                                                        }
                                                    })
                                                }
                                            ></Input>
                                        </FormItem>
                                    </div>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Grid>
            </div>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

OnboardingTransactionProfileComponentStepThree.propTypes = {
    title: PropTypes.element,
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    oneHundredValues: PropTypes.array,
    onSubmit: PropTypes.func
};

export default OnboardingTransactionProfileComponentStepThree;
