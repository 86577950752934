import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { DnaService } from 'services';
import MerchantPdqListComponent from './merchant-pdq-list.component';
import { pdqStatusChecked } from 'utils/enums/pdqOrderStatus';

function MerchantPdqList() {
    const { globalState } = useGlobalStateContext();
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [canonicalResellerNameAndID, setCanonicalResellerNameAndId] = useState('');
    const [filter, setFilter] = useState(['all']);
    const [openSearchAllMerchants, setOpenSearchAllMerchants] = useState(false);
    const [dnaOnboardedMerchants, setDnaOnboardedMerchants] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [dnaMerchantsList, setDnaMerchantsList] = useState();
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [showFilterIcon, setShowFilterIcon] = useState(false);
    const [statusFilter, setStatusFilter] = useState(false);
    const [agentFilter, setAgentFilter] = useState(false);
    const [isPdqStatusChecked, setPdqStatusChecked] = useState(pdqStatusChecked);
    const [allAgentEmails, setAllAgentEmails] = useState(['all']);
    const [filteredAgentEmails, setFilteredAgentEmails] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');

    const handleFilter = (id) => {
        setShowFilterIcon(true);
        if (id === 'status') {
            setStatusFilter(true);
        } else {
            setAgentFilter(true);
        }
    };

    const headCells = [
        {
            id: 'MerchantName',
            numeric: false,
            disablePadding: false,
            label: 'Merchant Name',
            hideSortingIcon: true
        },
        {
            id: 'MerchantId',
            numeric: true,
            disablePadding: false,
            label: 'Merchant ID',
            hideSortingIcon: true
        },
        {
            id: 'ApplicationId',
            numeric: false,
            disablePadding: false,
            label: 'Application ID',
            hideSortingIcon: true
        },
        { id: 'date', numeric: false, disablePadding: false, label: 'Date' },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            showFilterIcon: true,
            isFilterActive: true
        },
        {
            id: 'AgentEmail',
            numeric: false,
            disablePadding: false,
            label: 'Agent Email',
            showFilterIcon: true,
            isFilterActive: true
        },
        {
            id: 'ApplicationReason',
            numeric: false,
            disablePadding: false,
            label: 'Application Reason',
            hideSortingIcon: true
        }
    ];

    useEffect(() => {
        if (globalState.canonicalReseller?.id) {
            setIsLoading(true);
            setCanonicalResellerNameAndId(
                `${globalState.canonicalReseller?.id} - ${globalState.canonicalReseller?.companyName}`
            );
            getDnaAccounts();
        }
        // eslint-disable-next-line
    }, [globalState.canonicalReseller, filter, isPdqStatusChecked, filteredAgentEmails]);

    useEffect(() => {
        if (globalState.canonicalReseller?.id) {
            getAgentEmails();
        }
    }, [globalState.canonicalReseller]);

    const handleChangePage = (event, newPage) => {
        setIsLoading(true);
        setPage(newPage);
        getDnaAccounts(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setIsLoading(true);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getDnaAccounts(0, Number(event.target.value, 10));
    };

    const getAgentEmails = async () => {
        setIsLoading(true);
        const response = await DnaService.getUniqueAgentEmails();
        setIsLoading(false);
        const uniqueEmails = response.data;
        let index = 0;
        let listOfAgentEmails = [{ id: index, name: 'Select all', checked: true }];
        uniqueEmails.forEach((email) => {
            const agentEmail = email.email;
            if (agentEmail) {
                listOfAgentEmails.push({
                    id: ++index,
                    name: agentEmail,
                    value: agentEmail,
                    checked: true
                });
            }
        });
        setFilteredAgentEmails(listOfAgentEmails);
    };

    const getDnaAccounts = async (newPage, newRowsPerPage) => {
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const response = await DnaService.getDnaAccount({
            canonicalResellerId: globalState.canonicalReseller?.id,
            searchValue: searchValue.length > 2 ? searchValue : 'no',
            statusFilter: filter,
            agentEmailFilter: allAgentEmails,
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage
        });
        if (response.isSuccesfully) {
            const dnaAccounts = response.data.dnaAccounts;
            setDnaOnboardedMerchants(dnaAccounts);
            setCount(response.data.count);
            if (!filter.includes('all') && searchValue.length < 2) {
                const result = dnaAccounts.map((merchant) => merchant.merchantId);
                setDnaMerchantsList(result);
            }
        } else {
            setDnaOnboardedMerchants([]);
        }
        setIsLoading(false);
    };

    const searchPdqOrders = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        getDnaAccounts();
    };

    const handleCopyId = (row) => {
        navigator.clipboard.writeText(row.applicationId);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    const hadnleFilter = (id, tempArray, tempElement, originalArray, setFunction) => {
        const selectALlId = 0;
        let selectedElement = { ...tempElement[id] };
        selectedElement.checked = !selectedElement.checked;
        tempElement[id] = selectedElement;
        let checkedElement = [...tempElement.filter((data) => data.checked && data.id)];

        let selectedValue =
            id === selectALlId && selectedElement.checked
                ? originalArray.map((e) => e.value)
                : checkedElement.map((e) => e.value);

        tempArray.push(selectedValue);
        setFunction([...selectedValue]);

        const selectAllElement = { ...tempElement[0] };
        if (id === selectALlId) {
            setFunction(selectAllElement.checked ? ['all'] : []);
            tempElement = tempElement.map((status) => {
                return {
                    ...status,
                    checked: selectAllElement.checked
                };
            });
        } else {
            if (checkedElement.length === originalArray.length - 1) {
                selectAllElement.checked = true;
            } else if (checkedElement.length !== 0) {
                selectAllElement.checked = false;
            } else {
                setFunction(selectAllElement.checked ? ['all'] : []);
                tempElement = tempElement.map((status) => {
                    return {
                        ...status,
                        checked: selectAllElement.checked
                    };
                });
            }
        }
        tempElement[0] = selectAllElement;

        return tempElement;
    };

    const handleItemChange = (id) => {
        let tempArray = [...filter];
        let tempStatus = [...isPdqStatusChecked];
        const selectedFilters = hadnleFilter(id, tempArray, tempStatus, isPdqStatusChecked, setFilter);
        setPdqStatusChecked([...selectedFilters]);
    };

    const toggleAgentCheck = (id) => {
        let tempArray = [...allAgentEmails];
        let tempStatus = [...filteredAgentEmails];
        const selectedFilters = hadnleFilter(id, tempArray, tempStatus, filteredAgentEmails, setAllAgentEmails);
        setFilteredAgentEmails([...selectedFilters]);
    };

    const handleClearFilter = (event) => {
        setAllAgentEmails(['all']);
        setFilter(['all']);
        setFilteredAgentEmails(filteredAgentEmails.map((agentEmail) => ({ ...agentEmail, checked: true })));
        setPdqStatusChecked(pdqStatusChecked);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <MerchantPdqListComponent
            isLoading={isLoading}
            setSearchValue={setSearchValue}
            canonicalReseller={globalState.canonicalReseller}
            canonicalResellerNameAndID={canonicalResellerNameAndID}
            openSearchAllMerchants={openSearchAllMerchants}
            setOpenSearchAllMerchants={setOpenSearchAllMerchants}
            dnaOnboardedMerchants={dnaOnboardedMerchants}
            searchPdqOrders={searchPdqOrders}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setIsLoading={setIsLoading}
            dnaMerchantsList={dnaMerchantsList}
            setCount={setCount}
            count={count}
            handleCopyId={handleCopyId}
            isCopyAlertOpen={isCopyAlertOpen}
            closeCopyAlert={closeCopyAlert}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            setAgentFilter={setAgentFilter}
            agentFilter={agentFilter}
            handleItemChange={handleItemChange}
            isPdqStatusChecked={isPdqStatusChecked}
            listOfAgentEmails={allAgentEmails}
            toggleAgentCheck={toggleAgentCheck}
            filteredAgentEmails={filteredAgentEmails}
            filter={filter}
            handleClearFilter={handleClearFilter}
            headCells={headCells}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            handleFilter={handleFilter}
            showFilterIcon={showFilterIcon}
        />
    );
}

export default MerchantPdqList;
