import { PaymentProvider } from './enums/PaymentProvider';
import { PaymentStatus } from './enums/PaymentStatus';

export const getChipLabel = (giftCardEnable) => {
    if (!giftCardEnable) return { chipLable: '', titleText: '' };
    const { TxnReference, provider, origin, payment_status, total } = giftCardEnable;
    const negativeAmt = total < 0;

    if (provider == PaymentProvider.GIFT_CARD) {
        switch (origin) {
            case 26:
                if (payment_status === PaymentStatus.REFUND) {
                    return {
                        chipLable: 'Gift Card Refund',
                        titleText: 'This gift card amount has been refunded to the original payment method.'
                    };
                }
                if (negativeAmt) {
                    return {
                        chipLable: 'Gift Card Redeem',
                        titleText:
                            'The gift card amount is already added upon purchase, amount below is fees deducted from your balance.'
                    };
                } else {
                    return {
                        chipLable: 'Gift Card Redeem',
                        titleText: 'The gift card amount has already been added to your account balance.'
                    };
                }
            case 27:
                return {
                    chipLable: 'Gift Card Redeem',
                    titleText:
                        'The gift card amount is already added upon purchase, amount below is fees deducted from your balance.'
                };
            default:
                break;
        }
    }

    if (TxnReference?.startsWith('OGC') || TxnReference?.startsWith('GC')) {
        return {
            chipLable: 'Gift Card Purchased',
            titleText: 'This is a gift card purchase order, the amount is added to your balance.'
        };
    }

    return { chipLable: '', titleText: '' };
};
