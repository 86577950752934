import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core/';

import styles from './modal-remove-member.module.scss';

export default function ModalRemoveUserComponent(props) {
    const { open, setOpen, onRemoveConfirm, removeMember, loading, error } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Remove user from team</DialogTitle>
            {loading ? (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {error ? (
                                `something went wrong.. try again later`
                            ) : (
                                <Box>
                                    <span className={styles.SpanStyles}>
                                        {removeMember ? removeMember.email : 'User'}
                                    </span>{' '}
                                    <span>will no longer be able to access this account.</span>
                                </Box>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions p={3}>
                        <Button onClick={() => setOpen(false)} variant="outlined" color="primary">
                            {error ? `Close` : `Cancel`}
                        </Button>
                        {!error && (
                            <Button
                                onClick={() => onRemoveConfirm(removeMember.Relationships.id)}
                                variant="outlined"
                                color="secondary"
                            >
                                Remove user
                            </Button>
                        )}
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

ModalRemoveUserComponent.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    removeMember: PropTypes.object,
    onRemoveConfirm: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.bool
};
