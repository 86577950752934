import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalInviteUserComponent from './modal-invite-user.component';
import { ValidationMessage } from 'models';
import { TeamService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { debounce } from 'lodash';
import { validateEmailAddress } from 'utils/helper';
import { Roles } from 'utils/enums/Roles';
import _ from 'lodash';

export default function ModalInviteUser(props) {
    const initialValidationMessage = new ValidationMessage();
    const { globalState } = useGlobalStateContext();
    const [validationMessage, setValidationMessage] = useState(initialValidationMessage);
    const [emailsAndRoles, setEmailsAndRoles] = useState([{ email: '', role: null }]);
    const {
        setShow,
        setLoading,
        getTeamMembers,
        show,
        count,
        setCount,
        maxUserCount,
        members,
        totalTeamMembers,
        setSelectedRole,
        setSelectedStatus,
        setSearchString
    } = props;
    const [duplicateEmailMessage, setDuplicateEmailMessage] = useState('');
    const [emailValidationMessage, setEmailValidationMessage] = useState('');
    const [emailValidationMessagesArray, setEmailValidationMessagesArray] = useState([]);
    const [allUsersEmail, setAllUserEmails] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const wrapperRef = useRef(null);
    const isAgent = _.isArray(globalState.user?.Relationships)
        ? globalState.user?.Relationships[0]?.Role.name === Roles.Agent
        : false;

    useEffect(() => {
        setEmailsAndRoles([{ email: '', role: null }]);
        if (isAgent) {
            setEmailsAndRoles([{ email: '', role: Roles.Agent }]);
        }
        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {
        if (show) {
            const allEmail = members.map((member) => member.email);
            setAllUserEmails(allEmail);
        }
        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {}, [count]);

    useEffect(() => {
        const uniqueEmailsWithoutBoolean = emailsAndRoles.map((emailsAndRole) => emailsAndRole.email).filter(Boolean);

        const removeDuplicates = (uniqueEmailsWithoutBoolean) => {
            const duplicate = [...new Set(uniqueEmailsWithoutBoolean)];
            return duplicate.length;
        };
        const uniqueEmailsFinal = removeDuplicates(uniqueEmailsWithoutBoolean);
        const uniqueEmailsWithoutBooleanFinal = uniqueEmailsWithoutBoolean.length;

        let alreadyExist = false;
        for (let i = 0; i < emailsAndRoles.length; i++) {
            const email = emailsAndRoles[i].email;
            if (allUsersEmail.includes(email)) {
                alreadyExist = true;
                break;
            }
        }

        if (uniqueEmailsWithoutBooleanFinal !== uniqueEmailsFinal || alreadyExist) {
            setDuplicateEmailMessage('You have entered duplicate email');
        } else {
            setDuplicateEmailMessage('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailsAndRoles]);

    const inviteMembers = async () => {
        setShow(false);
        setLoading(true);

        if (isCanonicalResellerUser) {
            await TeamService.inviteCanonicalResellerMembers(
                emailsAndRoles,
                globalState.canonicalReseller?.id,
                globalState.reseller?.id
            ).then((result) => {
                if (!result.isSuccesfully) {
                    const errorMessage = result.data.error ?? 'An error has occurred';
                    setValidationMessage(errorMessage);
                }
            });
        } else {
            await TeamService.inviteMembers(emailsAndRoles, globalState.merchant?.id, globalState.reseller?.id).then(
                (result) => {
                    if (!result.isSuccesfully) {
                        const errorMessage = result.data.error ?? 'An error has occurred';
                        setValidationMessage(errorMessage);
                    }
                }
            );
        }

        setEmailsAndRoles([{ email: '', role: null }]);
        getTeamMembers();
        setLoading(false);
        setSelectedRole('All roles');
        setSelectedStatus('All');
        setSearchString('');
    };

    const validateEmptyField = () => {
        const allEmailsAndRolesAreValid = emailsAndRoles.every((emailAndRole) => {
            if (emailAndRole.email && emailAndRole.role) {
                return true;
            }
            return false;
        });

        if (emailsAndRoles.length && allEmailsAndRolesAreValid) {
            return true;
        }

        return false;
    };

    const setEmail = (index, value) => {
        setEmailsAndRoles(() => {
            let tempEmailsAndRoles = emailsAndRoles.slice();
            tempEmailsAndRoles[index] = {
                ...tempEmailsAndRoles[index],
                email: value.trim()
            };
            return tempEmailsAndRoles;
        });
        setDisabled(true);
        setEmailValidationMessagesArray(() => {
            let tempArray = emailValidationMessagesArray.slice();
            tempArray[index] = {
                ...tempArray[index],
                show: false
            };
            return tempArray;
        });
    };

    const setRole = (index, value) => {
        setEmailsAndRoles(() => {
            let tempEmailsAndRoles = emailsAndRoles.slice();
            tempEmailsAndRoles[index] = {
                ...tempEmailsAndRoles[index],
                role: value
            };
            return tempEmailsAndRoles;
        });
    };
    const addNewUserEmailAndRole = () => {
        if (count <= maxUserCount) {
            setEmailsAndRoles(emailsAndRoles.concat({}));
            setEmailValidationMessagesArray(emailValidationMessagesArray.concat({}));
            setCount(() => count + 1);
        }
        // setRole(emailsAndRoles.length, Roles.Agent);
    };

    const removeNewUserEmailAndRole = (index) => {
        let tempEmailsAndRoles = emailsAndRoles.slice();
        tempEmailsAndRoles.splice(index, 1);
        setEmailsAndRoles(tempEmailsAndRoles);
        setCount(() => count - 1);
    };

    const validation = (index, email, emailValidationMessagesArray) => {
        const errorMessage = validateEmailAddress(email);
        setEmailValidationMessage(errorMessage);
        let tempArray = emailValidationMessagesArray.slice();
        setEmailValidationMessagesArray(() => {
            tempArray[index] = {
                ...tempArray[index],
                show: errorMessage ? true : false,
                emailValidationMessage: errorMessage
            };
            return tempArray;
        });

        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].show) {
                setDisabled(true);
                break;
            } else {
                setDisabled(false);
            }
        }
    };

    // trigger validation function when user stop typing
    const doValidation = useCallback(debounce(validation, 1000), []);

    const handleHideDropdown = (event) => {
        if (event.key === 'Escape') {
            setShow(false);
            setCount(totalTeamMembers);
        }
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(false);
            setCount(totalTeamMembers);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <ModalInviteUserComponent
            {...props}
            validationMessage={validationMessage}
            inviteMembers={inviteMembers}
            emailsAndRoles={emailsAndRoles}
            setEmailsAndRoles={setEmailsAndRoles}
            validateEmptyField={validateEmptyField}
            addNewUserEmailAndRole={addNewUserEmailAndRole}
            setEmail={setEmail}
            setRole={setRole}
            removeNewUserEmailAndRole={removeNewUserEmailAndRole}
            duplicateEmailMessage={duplicateEmailMessage}
            doValidation={doValidation}
            emailValidationMessage={emailValidationMessage}
            emailValidationMessagesArray={emailValidationMessagesArray}
            disabled={disabled}
            wrapperRef={wrapperRef}
            isAgent={isAgent}
        ></ModalInviteUserComponent>
    );
}

ModalInviteUser.propTypes = {
    setLoading: PropTypes.func,
    setShow: PropTypes.func,
    getTeamMembers: PropTypes.func,
    doValidation: PropTypes.func
};
