import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';

import OnboardingBusinessDetailsStepComponent from './onboarding-busines-details.component';
import { NonResellerMerchantService } from 'services';
import { validateVatNumber, InputValidators } from 'validators';

function OnboardingBusinessDetailsStep(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();
    const isSoleTrader = onboardingForm.businessDetails.businessTypeId?.toString() === '2';

    useEffect(() => {
        const { vatNumber, tradingName, phoneNumber, email } = onboardingForm.businessDetails;
        const checkVatNumber = validateVatNumber(globalState.merchant?.country, vatNumber);
        const isEmailValid = email ? validator.isEmail(email) : true;
        let checkValues = checkVatNumber && tradingName && phoneNumber && isEmailValid;
        if (globalState.isT2SMerchant) {
            checkValues = phoneNumber && isEmailValid;
        } else {
            checkValues = checkVatNumber && tradingName && phoneNumber && isEmailValid;
        }

        setIsDisabled(!checkValues);
        // eslint-disable-next-line
    }, [onboardingForm.businessDetails, globalState.merchant.country]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                businessDetails: isDisabled ? false : true
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    const onSubmit = () => {
        if (isSoleTrader) {
            setOnboardingForm({
                ...onboardingForm,
                businessDetails: {
                    ...onboardingForm.businessDetails,
                    tradingName: onboardingForm.businessDetails.tradingName
                }
            });
        } else {
            setOnboardingForm({
                ...onboardingForm,
                businessDetails: {
                    ...onboardingForm.businessDetails,
                    tradingName: globalState.merchant.name
                }
            });
        }

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 2, onboardingForm.businessDetails);
    };

    return (
        <OnboardingBusinessDetailsStepComponent
            {...props}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
        />
    );
}

export default OnboardingBusinessDetailsStep;
