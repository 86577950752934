//importing enums
import { MerchantCountries } from './enums/MerchantCountries';
import { OnboardingStepsMapping, OnboardingSteps } from './enums/OnboardingStepsMapping';
import { BusinessTypeEnumId } from './enums/BusinessType';

// Function to map Business Type id with country
export const validBusinessTypeIdsMap = {
    USA: [
        BusinessTypeEnumId.Sole_Trader,
        BusinessTypeEnumId.Partnership,
        BusinessTypeEnumId.Corporation,
        BusinessTypeEnumId.Limited_LLC,
        BusinessTypeEnumId.Other
    ],
    CANADA: [BusinessTypeEnumId.Limited, BusinessTypeEnumId.Sole_Trader],
    UKorIRELAND: [BusinessTypeEnumId.Limited, BusinessTypeEnumId.Sole_Trader, BusinessTypeEnumId.Partnership],
    AUS: [
        BusinessTypeEnumId.Limited,
        BusinessTypeEnumId.Partnership,
        BusinessTypeEnumId.Sole_Trader,
        BusinessTypeEnumId.Trust,
        BusinessTypeEnumId.Other
    ],
    DEFAULT: [
        BusinessTypeEnumId.Limited,
        BusinessTypeEnumId.Sole_Trader,
        BusinessTypeEnumId.Partnership,
        BusinessTypeEnumId.Other
    ]
};

// for limited and partnership business type && aus||usa merchants
export const dropdownTypeOrganisations = [
    { id: '', name: '--Please select--' },
    { id: 'associationIncorporated', name: 'Association Incorporated' },
    { id: 'governmentalOrganization', name: 'Governmental Organisation' },
    { id: 'nonProfit', name: 'Non Profit' },
    { id: 'partnershipIncorporated', name: 'Partnership Incorporated' },
    { id: 'privateCompany', name: 'Private Company' }
];

// aus||usa merchants && trust type
export const dropdownTypeForTrust = [
    { id: '', name: '--Please select--' },
    { id: 'cashManagementTrust', name: 'Cash Management Trust' },
    { id: 'corporateUnitTrust', name: 'Corporate Unit Trust' },
    { id: 'deceasedEstate', name: 'Deceased Estate' },
    { id: 'discretionaryInvestmentTrust', name: 'Discretionary Investment Trust' },
    { id: 'discretionaryServicesManagementTrust', name: 'Discretionary Services Management Trust' },
    { id: 'discretionaryTradingTrust', name: 'Discretionary Trading Trust' },
    { id: 'firstHomeSaverAccountsTrust', name: 'First Home Saver Accounts Trust' },
    { id: 'fixedTrust', name: 'Fixed Trust' },
    { id: 'fixedUnitTrust', name: 'Fixed Unit Trust' },
    { id: 'hybridTrust', name: 'Hybrid Trust' },
    { id: 'listedPublicUnitTrust', name: 'Listed Public Unit Trust' },
    { id: 'otherTrust', name: 'Other Trust' },
    { id: 'pooledSuperannuationTrust', name: 'Pooled Superannuation Trust' },
    { id: 'publicTradingTrust', name: 'Public Trading Trust' },
    { id: 'unlistedPublicUnitTrust', name: 'Unlisted Public Unit Trust' }
];

// aus||usa merchants
export const AUSStateCodes = [
    { id: '', name: '--Please select--' },
    { id: 'ACT', name: 'Australian Capital Territory' },
    { id: 'NSW', name: 'New South Wales' },
    { id: 'NT', name: 'Northern Territory' },
    { id: 'QLD', name: 'Queensland' },
    { id: 'SA', name: 'South Australia' },
    { id: 'TAS', name: 'Tasmania' },
    { id: 'VIC', name: 'Victoria' },
    { id: 'WA', name: 'Western Australia' }
];

export const USAStateCodes = [
    { id: '', name: '--Please select--' },
    { id: 'AL', name: 'Alabama, US' },
    { id: 'AK', name: 'Alaska, US' },
    { id: 'AZ', name: 'Arizona, US' },
    { id: 'AR', name: 'Arkansas, US' },
    { id: 'AA', name: 'Armed Forces America' },
    { id: 'AE', name: 'Armed Forces Europe' },
    { id: 'AP', name: 'Armed Forces pacific' },
    { id: 'CA', name: 'California, US' },
    { id: 'CO', name: 'Colorado, US' },
    { id: 'CT', name: 'Connecticut, US' },
    { id: 'DE', name: 'Delaware, US' },
    { id: 'DC', name: 'District of Columbia, US' },
    { id: 'FL', name: 'Florida, US' },
    { id: 'GA', name: 'Georgia, US' },
    { id: 'HI', name: 'Hawaii, US' },
    { id: 'ID', name: 'Idaho, US' },
    { id: 'IL', name: 'Illinois, US' },
    { id: 'IN', name: 'Indiana, US' },
    { id: 'IA', name: 'Iowa, US' },
    { id: 'KS', name: 'Kansas, US' },
    { id: 'KY', name: 'Kentucky, US' },
    { id: 'LA', name: 'Louisiana, US' },
    { id: 'ME', name: 'Maine, US' },
    { id: 'MD', name: 'Maryland, US' },
    { id: 'MA', name: 'Massachusetts, US' },
    { id: 'MI', name: 'Michigan, US' },
    { id: 'MN', name: 'Minnesota, US' },
    { id: 'MS', name: 'Mississippi, US' },
    { id: 'MO', name: 'Missouri, US' },
    { id: 'MT', name: 'Montana, US' },
    { id: 'NE', name: 'Nebraska, US' },
    { id: 'NV', name: 'Nevada, US' },
    { id: 'NH', name: 'New Hampshire, US' },
    { id: 'NJ', name: 'New Jersey, US' },
    { id: 'NM', name: 'New Mexico, US' },
    { id: 'NY', name: 'New York, US' },
    { id: 'NC', name: 'North Carolina, US' },
    { id: 'ND', name: 'North Dakota, US' },
    { id: 'OH', name: 'Ohio, US' },
    { id: 'OK', name: 'Oklahoma, US' },
    { id: 'OR', name: 'Oregon, US' },
    { id: 'PA', name: 'Pennsylvania, US' },
    { id: 'RI', name: 'Rhode Island, US' },
    { id: 'SC', name: 'South Carolina, US' },
    { id: 'SD', name: 'South Dakota, US' },
    { id: 'TN', name: 'Tennessee, US' },
    { id: 'TX', name: 'Texas, US' },
    { id: 'UT', name: 'Utah, US' },
    { id: 'VT', name: 'Vermont, US' },
    { id: 'VA', name: 'Virginia, US' },
    { id: 'WA', name: 'Washington, US' },
    { id: 'WV', name: 'West Virginia, US' },
    { id: 'WI', name: 'Wisconsin, US' },
    { id: 'WY', name: 'Wyoming, US' }
];

// List of month for dropdown
export const months = ['MM', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

//Month mapping with month number
export const monthEnum = {
    0: 'MM',
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};

//Month number mapping with month
export const reverseMonthEnum = Object.fromEntries(months.map((month, index) => [month, index]));

//Function to get the onboarding step name on basis of country
//If the country is the United States and the index is 0, changes the name for the "Name and address" field.
//This adjustment is currently specific to US merchants.
export const getOnboardingStepName = (index, label, country) => {
    switch (true) {
        case country === MerchantCountries.UNITED_STATES && index === 0:
            return OnboardingSteps.NAME_AND_ADDRESS;
        case country === MerchantCountries.UNITED_STATES && index === 2:
            return OnboardingSteps.BUSINESS_ADDRESS;
        default:
            return label;
    }
};

//Function to get onboardingTitle on basis of country
//If the country is the United States and the text is "Name and address," the title is changed to "Name and business address."
//This adjustment is currently specific to US merchants.
export const getOnboardingTitle = (text, country) => {
    switch (true) {
        case country === MerchantCountries.UNITED_STATES && text === OnboardingSteps.NAME_AND_ADDRESS:
            return OnboardingSteps.NAME_AND_ADDRESS;
        case country === MerchantCountries.UNITED_STATES && text === OnboardingSteps.TRADING_ADDRESS:
            return OnboardingSteps.BUSINESS_ADDRESS;
        default:
            return text;
    }
};
