import React, { useState, useEffect } from 'react';
import OnboardingNameAdressComponent from './onboarding-name-adress.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService, CompaniesHouseService } from 'services';
import { BusinessType, BusinessTypeName, BusinessTypeEnumId, BusinessTypeNameAndID } from 'utils/enums/BusinessType';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { OwnerTitle } from 'utils/enums/OwnerTitle';
import moment from 'moment';
import { validateRegisteredNumber } from 'validators';
import _ from 'lodash';

function OnboardingNameAdress(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [legalNameVal, setLegalNameVal] = useState(onboardingForm.nameAndAddress.legalName);
    const [regBusinessNameVal, setRegBusinessNameVal] = useState(onboardingForm.businessDetails.tradingName);
    const [bankAccountNameVal, setBankAccountNameVal] = useState(onboardingForm.tradingAddress.accountHolderName);
    const [isBankAccountNameSwitchOn, setIsBankAccountNameSwitchOn] = useState(false);
    const [regBusinessName, setRegBusinessName] = useState(onboardingForm.businessDetails.isRegisteredBusiness);
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();
    const [selectedCompany, setSelectedCompany] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [showErrorForRegisteredNumber, setShowErrorForRegisteredNumber] = useState(false);
    const [registeredNumberErrorMessage, setRegisteredNumberErrorMessage] = useState('');
    const [isSoleTrader, setIsSoleTrader] = useState(false);
    const isUKMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const regNumberCheckRegexUK = new RegExp('^[0-9a-zA-Z]+$');
    const country = globalState.merchant?.country;
    const bankSwitch = localStorage.getItem('isBankAccountNameSwitchOn');

    const getBusinessTypeId = (type) => {
        let typeName = '';
        switch (type) {
            case BusinessType.Limited:
                typeName = BusinessTypeName.Limited;
                break;
            case BusinessType.PLC:
                typeName = BusinessTypeName.PLC;
                break;
            case BusinessType.LLP:
                typeName = BusinessTypeName.LLP;
                break;
            case BusinessType.Partnership:
                typeName = BusinessTypeName.Partnership;
                break;
            default:
                typeName = '';
        }
        const businessType = BusinessTypeNameAndID.find((type) => type.name === typeName);
        return businessType ? businessType.id : '--Please select--';
    };

    const countryBusinessTypes = () => {
        const newBusinessTypes = _.filter(BusinessTypeNameAndID, function (business) {
            return (
                [
                    BusinessTypeEnumId.Limited,
                    BusinessTypeEnumId.Partnership,
                    BusinessTypeEnumId.Sole_Trader,
                    BusinessTypeEnumId.Other
                ].includes(business.id) ||
                (country !== MerchantCountries.UNITED_KINGDOM &&
                    country !== MerchantCountries.IRELAND &&
                    business.id === BusinessTypeEnumId.Other)
            );
        });
        setBusinessTypes(newBusinessTypes);
    };

    useEffect(() => {
        const { registeredNumber, postCode, addressLine1 } = onboardingForm.nameAndAddress;
        const { businessTypeId } = onboardingForm.businessDetails;
        const checkRegBusinessNameVal = regBusinessNameVal ? regBusinessNameVal && regBusinessNameVal.trim() : true;
        const checkBankAccountNameVal = bankAccountNameVal ? bankAccountNameVal && bankAccountNameVal.trim() : true;
        const checkValues =
            validateRegNumber(registeredNumber) &&
            checkRegBusinessNameVal &&
            checkBankAccountNameVal &&
            legalNameVal &&
            legalNameVal.trim() &&
            postCode &&
            addressLine1 &&
            businessTypeId !== '--Please select--';

        if (isSoleTrader) {
            setIsDisabled(
                !(
                    (regBusinessNameVal && regBusinessNameVal.trim()) ||
                    (bankAccountNameVal && bankAccountNameVal.trim()) ||
                    isBankAccountNameSwitchOn
                )
            );
        } else {
            setIsDisabled(!checkValues);
        }

        // eslint-disable-next-line
    }, [
        onboardingForm.nameAndAddress,
        onboardingForm.businessDetails.businessTypeId,
        onboardingForm.businessDetails,
        bankAccountNameVal,
        regBusinessNameVal,
        isBankAccountNameSwitchOn,
        bankSwitch
    ]);
    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                nameAndAddress: !isDisabled ? true : false
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    useEffect(() => {
        countryBusinessTypes();
        if (bankSwitch) {
            setIsBankAccountNameSwitchOn(true);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsSoleTrader(onboardingForm.businessDetails.businessTypeId?.toString() === '2');
    }, [onboardingForm.businessDetails.businessTypeId]);

    const validateRegNumber = (registeredNumber) => {
        var validateRN = validateRegisteredNumber(globalState.merchant?.country, registeredNumber);
        setShowErrorForRegisteredNumber(!validateRN.isValid);
        setRegisteredNumberErrorMessage(validateRN.message);
        return validateRN.isValid;
    };

    const getCompanyDetails = async () => {
        const { companyName } = onboardingForm.nameAndAddress;
        setIsLoading(true);
        const result = await CompaniesHouseService.getCompaniesDetail(companyName, 10);
        if (result.isSuccesfully) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,

                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    companies: result.data.items
                },
                companies: result.data.items
            }));
            if (result.data.items.length) setOpenDropdown(true);
        }

        setIsLoading(false);
    };

    const getOwnerDetails = async (registeredNumber) => {
        let ownersDetails;
        const resultPersonsDetails = await CompaniesHouseService.getPersonsWithSignificantControl(registeredNumber);

        if (resultPersonsDetails.isSuccesfully) {
            let ownersWithSignificantPower = resultPersonsDetails.data.items || [];
            ownersWithSignificantPower.forEach((person) => {
                person.date_of_birth = person.date_of_birth || {};
                let dateOfBirth = person.date_of_birth;

                dateOfBirth.day = dateOfBirth.day ? dateOfBirth.day.toString().padStart(2, '0') : '';
                dateOfBirth.month = dateOfBirth.month ? dateOfBirth.month.toString().padStart(2, '0') : '';
                dateOfBirth.year = dateOfBirth.year ? dateOfBirth.year.toString().padStart(4, '0') : '';

                const ownership = person.natures_of_control.filter((item) => item.startsWith('ownership-of-shares'));

                if (ownership.length > 0) {
                    const words = ownership[0].split('-').filter((word) => !isNaN(word));
                    person.ownershipDetails = {};
                    if (words.length === 1) {
                        person.ownership = words[0];
                    } else if (words.length === 2) {
                        person.ownership = words[1];
                    }
                }

                var name = person.name;
                var titlePerson = person.name_elements?.title;
                if (titlePerson) {
                    if (titlePerson[titlePerson.length - 1] === '.') {
                        titlePerson = titlePerson.substring(0, titlePerson.length - 1);
                    }
                    titlePerson = Object.keys(OwnerTitle).filter((key) => OwnerTitle[key] === titlePerson);
                    if (titlePerson.length > 0) {
                        name = name.substring(titlePerson[0].length + 1);
                    }
                    titlePerson = OwnerTitle[titlePerson[0]];
                }
                person.names = {
                    fullName: name,
                    title: titlePerson ?? titlePerson
                };
            });

            const personDetails = resultPersonsDetails.data.items[0];
            ownersDetails = {
                ...onboardingForm.ownersDetails,
                title: personDetails.names.title ?? onboardingForm.ownersDetails.title,
                fullName: personDetails.names.fullName,
                dateOfBirth: personDetails.date_of_birth,
                nationality: personDetails.nationality,
                ownership: personDetails.ownership ?? onboardingForm.ownersDetails.ownership
            };
            if (resultPersonsDetails.data.total_results === 1) {
                ownersDetails = {
                    ...ownersDetails,
                    persons: []
                };
            } else if (resultPersonsDetails.data.total_results > 1) {
                ownersDetails = {
                    ...ownersDetails,
                    persons: resultPersonsDetails.data.items
                };
            }
        }

        return ownersDetails;
    };

    const handleSelectedCompany = async (selectedValue) => {
        setSelectedCompany(selectedValue);
        let businessProfile = {};
        if (selectedValue && Object.keys(selectedValue).length) {
            if (selectedValue.date_of_creation) {
                const MONTHS_IN_A_YEAR = 12;
                businessProfile = {
                    ...onboardingForm.businessProfile,
                    timeInBusinessYears: moment().diff(selectedValue.date_of_creation, 'years', false),
                    timeInBusinessMonths:
                        moment().diff(selectedValue.date_of_creation, 'months', false) % MONTHS_IN_A_YEAR
                };
            }

            const ownersDetails = await getOwnerDetails(selectedValue.company_number);

            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownersDetails: ownersDetails ? ownersDetails : onboardingForm.ownersDetails,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    legalName: selectedValue.title,
                    companyName: selectedValue.title,
                    registeredNumber: selectedValue.company_number,
                    postCode: selectedValue.address.postal_code,
                    addressLine1: selectedValue.address.address_line_1,
                    addressLine2: selectedValue.address.address_line_2,
                    city: selectedValue.address.locality,
                    country: selectedValue.address.country
                },
                businessDetails: {
                    ...onboardingForm.businessDetails,
                    businessTypeId: selectedValue.company_type
                        ? getBusinessTypeId(selectedValue.company_type)
                        : '--Please select--'
                },
                businessProfile: {
                    ...onboardingForm.businessProfile,
                    timeInBusinessYears:
                        businessProfile && businessProfile.timeInBusinessYears > 0
                            ? businessProfile.timeInBusinessYears
                            : '0',
                    timeInBusinessMonths: businessProfile ? businessProfile.timeInBusinessMonths : ''
                }
            }));
        }
    };

    const selectAddressData = (addressData) => {
        if (addressData) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    addressLine1: addressData.line_1,
                    addressLine2: addressData.line_2,
                    city: addressData.town_or_city,
                    country: addressData.country
                }
            }));
        }
    };

    const onSubmit = () => {
        let soleTradertName;
        if (isBankAccountNameSwitchOn) {
            soleTradertName = globalState.merchant.name;
        } else {
            soleTradertName = regBusinessNameVal ? regBusinessNameVal : bankAccountNameVal;
        }
        if (!regBusinessName && !isBankAccountNameSwitchOn) {
            soleTradertName = bankAccountNameVal;
        }

        setOnboardingForm({
            ...onboardingForm,
            nameAndAddress: {
                ...onboardingForm.nameAndAddress,
                legalName: isSoleTrader ? soleTradertName : legalNameVal
            }
        });

        const data = { ...onboardingForm.nameAndAddress };
        data.legalName = isSoleTrader ? soleTradertName : legalNameVal;
        data.city = null;
        data.country = null;
        data.businessTypeId = onboardingForm.businessDetails.businessTypeId ?? BusinessTypeEnumId.Limited;

        const tradingdata = { ...onboardingForm.tradingAddress, step: 2 };

        const businessdata = { ...onboardingForm.businessDetails };

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 1, data);
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 2, businessdata);
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 3, tradingdata);
    };

    const setRegisteredNumberHandler = (e) => {
        let stringValue = e.target.value;
        if (isUKMerchant) {
            if (stringValue.match(regNumberCheckRegexUK) || stringValue === '') {
                setOnboardingForm({
                    ...onboardingForm,
                    nameAndAddress: {
                        ...onboardingForm.nameAndAddress,
                        registeredNumber: stringValue
                    }
                });
            }
        } else {
            setOnboardingForm({
                ...onboardingForm,
                nameAndAddress: {
                    ...onboardingForm.nameAndAddress,
                    registeredNumber: stringValue
                }
            });
        }
    };

    return (
        <OnboardingNameAdressComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            selectedCompany={selectedCompany}
            handleSelectedCompany={handleSelectedCompany}
            getCompanyDetails={getCompanyDetails}
            value={value}
            setValue={setValue}
            setLegalNameVal={setLegalNameVal}
            legalNameVal={legalNameVal}
            inputValue={inputValue}
            setInputValue={setInputValue}
            isLoading={isLoading}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            showErrorForRegisteredNumber={showErrorForRegisteredNumber}
            registeredNumberErrorMessage={registeredNumberErrorMessage}
            isSoleTrader={isSoleTrader}
            setIsSoleTrader={setIsSoleTrader}
            setRegisteredNumberHandler={setRegisteredNumberHandler}
            businessTypes={businessTypes}
            regBusinessName={regBusinessName}
            setRegBusinessName={setRegBusinessName}
            bankAccountNameVal={bankAccountNameVal}
            setBankAccountNameVal={setBankAccountNameVal}
            regBusinessNameVal={regBusinessNameVal}
            setRegBusinessNameVal={setRegBusinessNameVal}
            isBankAccountNameSwitchOn={isBankAccountNameSwitchOn}
            setIsBankAccountNameSwitchOn={setIsBankAccountNameSwitchOn}
        />
    );
}

export default OnboardingNameAdress;
