import React, { useState, useEffect } from 'react';
import UpdateAccountResellerMerchantComponent from './update-account-reseller-merchant.component';
import { BusinessDetailsService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { InputValidators } from 'validators';
import { difference, updateAccountMapKeyWord } from 'utils/helper';

function UpdateAccountResellerMerchant() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [businessDetails, setBusinessDetails] = useState({});
    const [ownerDetails, setOwnerDetails] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [oldOwnerDetails, setOldOwnerDetails] = useState({});
    const [oldBusinessDetails, setOldBusinessDetails] = useState({});
    const [phoneCountryDialCode, setPhoneCountryDialCode] = useState('');

    useEffect(() => {
        const allFieldsCompleted =
            businessDetails.legalName &&
            InputValidators.isWebsiteUrlValid(businessDetails.websiteUrl) &&
            ownerDetails.fullName &&
            businessDetails.postCode &&
            businessDetails.addressLine1 &&
            businessDetails.city &&
            businessDetails.county &&
            businessDetails.telephoneNumber &&
            ownerDetails.email &&
            businessDetails.telephoneNumber !== phoneCountryDialCode;

        const updatedAccount =
            businessDetails.legalName !== oldBusinessDetails.legalName ||
            businessDetails.websiteUrl !== oldBusinessDetails.websiteUrl ||
            ownerDetails.fullName !== oldOwnerDetails.fullName ||
            businessDetails.postCode !== oldBusinessDetails.postCode ||
            businessDetails.addressLine1 !== oldBusinessDetails.addressLine1 ||
            businessDetails.addressLine2 !== oldBusinessDetails.addressLine2 ||
            businessDetails.city !== oldBusinessDetails.city ||
            businessDetails.county !== oldBusinessDetails.county ||
            businessDetails.telephoneNumber !== oldBusinessDetails.telephoneNumber ||
            ownerDetails.email !== oldOwnerDetails.email;

        if (allFieldsCompleted && updatedAccount) setDisabled(false);
        else setDisabled(true);

        // eslint-disable-next-line
    }, [businessDetails, ownerDetails, oldOwnerDetails, oldBusinessDetails]);

    useEffect(() => {
        const getMerchantBusinessDetails = async () => {
            setIsLoading(true);
            const result = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);

            if (result.isSuccesfully) {
                setBusinessDetails(result.data.businessDetails);
                setOwnerDetails(result.data.ownerDetails);
                setOldBusinessDetails(result.data.businessDetails);
                setOldOwnerDetails(result.data.ownerDetails);
            }

            setIsLoading(false);
        };

        getMerchantBusinessDetails();
    }, [globalState.merchant]);

    const selectAddressData = (addressData, setObject, object) => {
        if (addressData) {
            setObject({
                ...object,
                addressLine1: addressData.line_1 || '',
                addressLine2: addressData.line_2 || '',
                city: addressData.town_or_city || '',
                county: addressData.county || ''
            });
        }
    };

    const onSubmit = async () => {
        const updatedOwnerDetails = updateAccountMapKeyWord(difference(ownerDetails, oldOwnerDetails), oldOwnerDetails);
        const updatedBusinessDetails = updateAccountMapKeyWord(
            difference(businessDetails, oldBusinessDetails),
            oldBusinessDetails
        );
        const updatedAccountDetails = {
            ...updatedBusinessDetails,
            ...updatedOwnerDetails
        };

        setIsLoading(true);
        const response = await BusinessDetailsService.updateBusinessDetails(globalState.merchant?.id, {
            businessDetails,
            ownerDetails,
            resellerId: globalState.reseller.id,
            updatedAccountDetails
        });

        setIsLoading(false);
        if (response.isSuccesfully) {
            sessionStorage.setItem('accountUpdated', true);
            setRoute('/home');
        } else {
            setShowModal(true);
            setModalMessage('An error occurred. Please contact support or try again.');
        }
    };

    return (
        <UpdateAccountResellerMerchantComponent
            disabled={disabled}
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            ownerDetails={ownerDetails}
            setOwnerDetails={setOwnerDetails}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            showModal={showModal}
            setShowModal={setShowModal}
            modalMessage={modalMessage}
            isLoading={isLoading}
            setPhoneCountryDialCode={setPhoneCountryDialCode}
        />
    );
}

export default UpdateAccountResellerMerchant;
