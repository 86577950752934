import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UrgentMessagesService } from 'services';
import UrgentMessageComponent from './urgent-message.component';
import { useGlobalStateContext } from 'contexts';
import moment from 'moment';
import _ from 'lodash';
function UrgentMessage(props) {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const { urgentMessage } = props;
    const [showDeleteConfirmationDialog, setshowDeleteConfirmationDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formItems, setFormItems] = useState({});
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [isDisable, setIsDisable] = useState(false);
    const [characterCount, setCharacterCount] = useState(urgentMessage?.message?.length);
    const [isShowForm, setIsShowForm] = useState(false);
    const [dateError, setDateError] = useState('');
    const [isButtonDisable, setButtonDisable] = useState(false);
    const [isDeleteBtnDisable, setIsDeleteBtnDisable] = useState(false);
    const [showDomainValidationMessage, setShowDomainValidationMessage] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const formatStartDate = moment(formItems.startDate).format('yyyy/MM/DD HH:mm:ss');
    const formatEndDate = moment(formItems.endDate).format('yyyy/MM/DD HH:mm:ss');
    let currentDate = new Date();
    let timeDifference = moment(formItems?.endDate).diff(moment(currentDate), 'seconds');

    useEffect(() => {
        let showMessage = true;
        if (moment(formatEndDate).isBefore(moment(new Date()))) {
            setDateError('End date should not be less than current date');
        } else if (moment(formatStartDate).isAfter(moment(formatEndDate))) {
            setDateError('Selected start date is greater than the end date.');
        } else if (moment(formatStartDate).isSame(moment(formatEndDate))) {
            setDateError('Start date and end date should not be same.');
        } else if (timeDifference <= 5 * 60) {
            setDateError('Selected end date time should be minimum 5 minutes greater than current time');
        } else {
            showMessage = false;
            setDateError('');
        }
        const btnTextValidation = formItems?.url?.length > 0 ? formItems.btnText?.trim().length === 0 : false;

        setIsDisable(
            _.isEmpty(formItems) ||
                formItems.message?.trim().length === 0 ||
                showMessage ||
                showDomainValidationMessage ||
                isFocused ||
                btnTextValidation
        );
        //eslint-disable-next-line
    }, [formItems, formatEndDate, formatStartDate, selectedEndDate, showDomainValidationMessage, isFocused]);

    const onEditUrgentMessage = async () => {
        setIsDisable(true);
        setIsEdit(true);
        setButtonDisable(true);
        const response = await UrgentMessagesService.updateUrgentMessage(urgentMessage.id, {
            ...formItems,
            subject: formItems.subject,
            message: formItems.message,
            startDate: formItems.startDate,
            url: formItems.url ? `https://${formItems.url}` : '',
            endDate: moment(formItems.endDate).utc().format('YYYY-MM-DD HH:mm:ss')
        });
        if (response.isSuccesfully) {
            setGlobalState({
                ...globalState,
                shouldCheckForUrgentMessages: !globalState.shouldCheckForUrgentMessages
            });
            setSuccess(true);
            setIsShowForm(false);
            setIsDisable(false);
            setButtonDisable(false);
        } else {
            setError('An error was occurred when checking the email. Please contact support or try again.');
            setSuccess(false);
        }
    };

    const onDeleteUrgentMessage = async () => {
        setIsDisable(true);
        setIsEdit(false);
        setIsDeleteBtnDisable(true);
        const response = await UrgentMessagesService.deleteUrgentMessage(urgentMessage.id);
        if (response.isSuccesfully) {
            setGlobalState({
                ...globalState,
                shouldCheckForUrgentMessages: !globalState.shouldCheckForUrgentMessages
            });
            setshowDeleteConfirmationDialog(false);
            setError(false);
            setSuccess(true);
        } else {
            setSuccess(false);
            setError(true);
        }
        setIsDeleteBtnDisable(false);
        setIsDisable(false);
    };

    return (
        <UrgentMessageComponent
            {...props}
            onEditUrgentMessage={onEditUrgentMessage}
            onDeleteUrgentMessage={onDeleteUrgentMessage}
            showDeleteConfirmationDialog={showDeleteConfirmationDialog}
            setshowDeleteConfirmationDialog={setshowDeleteConfirmationDialog}
            success={success}
            setSuccess={setSuccess}
            error={error}
            setError={setError}
            formItems={formItems}
            setFormItems={setFormItems}
            isEdit={isEdit}
            setSelectedEndDate={setSelectedEndDate}
            selectedEndDate={selectedEndDate}
            isDisable={isDisable}
            setCharacterCount={setCharacterCount}
            characterCount={characterCount}
            setIsShowForm={setIsShowForm}
            isShowForm={isShowForm}
            dateError={dateError}
            isButtonDisable={isButtonDisable}
            isDeleteBtnDisable={isDeleteBtnDisable}
            showDomainValidationMessage={showDomainValidationMessage}
            setShowDomainValidationMessage={setShowDomainValidationMessage}
            setIsFocused={setIsFocused}
        />
    );
}

UrgentMessage.propTypes = {
    urgentMessage: PropTypes.object
};

export default UrgentMessage;
