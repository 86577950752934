import React from 'react';
import styles from './table-project.module.scss';
import PropTypes from 'prop-types';
import { Table, Spinner } from 'react-bootstrap';

function TableProjectComponent(props) {
    const { dataList, columns, indexColumn, classesForRow, size, variant, loading } = props;

    return (
        <React.Fragment>
            {loading ? (
                <div className={styles.loading}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            ) : (
                <Table className={styles.table} striped bordered hover responsive variant={variant} size={size}>
                    <thead>
                        <tr>
                            {indexColumn && <th>#</th>}
                            {columns.map((column, index) => (
                                <th key={index}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.map((row, index) => (
                            <tr key={index} className={classesForRow}>
                                {indexColumn && <td>{index + 1}</td>}
                                {row}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    );
}

TableProjectComponent.propTypes = {
    columns: PropTypes.array.isRequired, // columns for the table
    dataList: PropTypes.array.isRequired, // every column for every row and column by type array of arrays
    // dataList = [[<td>row1column1</td>, <td>row1column2</td>], [<td>row2column1<td>], [<td>row2column2</td>]]
    indexColumn: PropTypes.bool, // if true, first column will be a column with index of the row
    classesForRow: PropTypes.string, // classes that will be applied for every row
    size: PropTypes.string,
    variant: PropTypes.string,
    loading: PropTypes.bool,
};

export default TableProjectComponent;
