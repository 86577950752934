import React, { useEffect, useState } from 'react';
import PaymentDetailsComponent from './payment-details.component';
import { PaymentStatus } from 'utils/enums/PaymentStatus';
import { useGlobalStateContext } from 'contexts';
import { AdminService } from 'services';
import paymentsService from 'services/payments.service';
import { UserType } from 'utils/enums/UserType';
import { PaymentProvider } from 'utils/enums/PaymentProvider';
import { getChipLabel } from 'utils/getGiftCardStatus';
import _ from 'lodash';

function PaymentDetailsContainer(props) {
    const { transactionId } = props;
    const [payment, setPayment] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(null);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [chargebackStatus, setChargebackStatus] = useState({});
    const [chargebackTransactionId, setChargebackTransactionId] = useState('');
    const [showSnackbarStatus, setShowSnackbarStatus] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarMessageType, setSnackbarMessageType] = useState('');
    const [chargebackListStatus, setChargebackListStatus] = useState('');
    const [showChargeback, setShowChargeback] = useState(false);
    const [chargebackExists, setChargebackExists] = useState(false);
    const [fees, setFees] = useState([]);
    const { globalState } = useGlobalStateContext();
    const merchantId = globalState?.merchant?.id;

    let giftCardEnable = _.pick(props?.payment, ['TxnReference', 'provider', 'origin', 'payment_status', 'total']);
    let chipTexts = getChipLabel(giftCardEnable);
    let giftCardExits =
        props.payment.provider === PaymentProvider.GIFT_CARD ||
        giftCardEnable.TxnReference?.startsWith('OGC') ||
        giftCardEnable.TxnReference?.startsWith('GC');
    useEffect(() => {
        const getPayment = async () => {
            //const response = await TransactionsService.getPayemntDetails(referenceId);
            if (props.payment) {
                //const details = response.data.data;
                setPayment(props.payment);
                setStatus(
                    props.payment.payment_status
                        ? PaymentStatus[props.payment.payment_status]
                        : props.payment.status && 'Success'
                );
            } else {
                setError(true);
            }
        };
        getPayment();
    });
    useEffect(() => {
        const fetchChargeback = async () => {
            setLoading(true);
            if (globalState.user.UserType === UserType.Admin || globalState.user.UserType === UserType.SuperAdmin) {
                const response = await AdminService.fetchChargeback({
                    transaction_id: props.payment.id,
                    customer_id: merchantId.toString()
                });

                if (response.isSuccesfully) {
                    setChargebackExists(response.data ? true : false);
                } else {
                    setChargebackExists(false);
                }
            }
            const feeResponse = await paymentsService.GetSplitFee({
                payment_id: props.payment.id,
                payment_provider: props.payment.payment_provider.toString(),
                transactionMap: props.payment.TransactionMap,
                refunded: props.payment.total <= 0 && props.payment.refund ? true : false
            });
            if (feeResponse.isSuccesfully) {
                props?.payment?.refund?.includes('Partial Refund') && props?.payment?.total <= 0
                    ? setFees([])
                    : setFees(feeResponse.data.data);
            } else {
                setFees([]);
            }
            setLoading(false);
        };

        fetchChargeback();
        //eslint-disable-next-line
    }, []);
    const handleModal = () => {
        setOpenModal(true);
    };
    const handleCopyPaymentId = () => {
        navigator.clipboard.writeText(payment.id);
        setIsCopyAlertOpen(true);
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    let isAddCreditTrannsaction = (
        props.payment?.firstname?.toString() +
        ' ' +
        props.payment?.lastname?.toString()
    ).includes('Datman credit');
    return (
        <PaymentDetailsComponent
            {...props}
            payment={payment}
            error={error}
            loading={loading}
            show={show}
            setShow={setShow}
            transactionId={transactionId}
            handleCopyPaymentId={handleCopyPaymentId}
            closeCopyAlert={closeCopyAlert}
            isCopyAlertOpen={isCopyAlertOpen}
            status={status}
            chargebackStatus={chargebackStatus}
            setChargebackStatus={setChargebackStatus}
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleModal={handleModal}
            chargebackTransactionId={chargebackTransactionId}
            setChargebackTransactionId={setChargebackTransactionId}
            showSnackbarStatus={showSnackbarStatus}
            setShowSnackbarStatus={setShowSnackbarStatus}
            snackbarMessage={snackbarMessage}
            setSnackbarMessage={setSnackbarMessage}
            snackbarMessageType={snackbarMessageType}
            setSnackbarMessageType={setSnackbarMessageType}
            chargebackListStatus={chargebackListStatus}
            setChargebackListStatus={setChargebackListStatus}
            showChargeback={showChargeback}
            setShowChargeback={setShowChargeback}
            chargebackExists={chargebackExists}
            isAddCreditTrannsaction={isAddCreditTrannsaction}
            fees={fees}
            chipTexts={chipTexts}
            giftCardExits={giftCardExits}
        ></PaymentDetailsComponent>
    );
}

export default PaymentDetailsContainer;
