export const VirtualTerminalPayments = {
    customer_card: 'customer_card',
    email: 'email',
    invoice: 'invoice'
};

export const VirtualTerminalPaymentsText = {
    customer_card: "Proceed via customer's card",
    email: 'Request payment via email',
    invoice: 'Request payment via Invoice'
};
