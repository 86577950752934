import React, { useState, useEffect } from 'react';
import ModalPdqMerchantsComponent from './modal-pdq-merchants.component';
import { NonResellerMerchantService } from 'services';
import { useGlobalStateContext } from 'contexts';
import PropTypes from 'prop-types';

function ModalPdqMerchants(props) {
    const [merchants, setMerchants] = useState([]);
    const [productFilter] = useState('all');
    const { globalState } = useGlobalStateContext();
    const [showMessage, setShowMessage] = useState(false);
    const [page, setPage] = useState(0);
    const [modalLoading, setModalLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const { open, setOpen, dnaMerchantsList } = props;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getAllMerchantsForResellerAgent(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getAllMerchantsForResellerAgent(0, Number(event.target.value, 10));
    };

    const getAllMerchantsForResellerAgent = async (newPage, newRowsPerPage) => {
        if (searchValue !== '') {
            setModalLoading(true);
            const thePage = newPage ?? page;
            const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
            const response = await NonResellerMerchantService.getAllMerchantsForReseller(globalState.reseller?.id, {
                searchValue: searchValue.length > 2 ? searchValue : 'no',
                offset: thePage * theRowsPerPage,
                canonicalResellerId: [globalState.canonicalReseller?.id, globalState.reseller?.id],
                limit: theRowsPerPage,
                productFilter: productFilter
            });
            if (response.isSuccesfully) {
                setMerchants(response.data.merchants);
                setCount(response.data.count);
            } else {
                setMerchants([]);
                setCount(0);
            }
            setModalLoading(false);
        }
    };

    const searchResellerMerchants = (e) => {
        setPage(0);
        getAllMerchantsForResellerAgent(0);
        e.preventDefault();
    };

    useEffect(() => {
        if (showMessage) {
            const timeout = setTimeout(() => {
                setShowMessage(false);
            }, 1000);

            return () => clearTimeout(timeout);
        }
    }, [showMessage]);

    return (
        <ModalPdqMerchantsComponent
            open={open}
            setOpen={setOpen}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
            rowsPerPage={rowsPerPage}
            dnaMerchantsList={dnaMerchantsList}
            setCount={setCount}
            merchants={merchants}
            searchResellerMerchants={searchResellerMerchants}
            setSearchValue={setSearchValue}
            modalLoading={modalLoading}
            setModalLoading={setModalLoading}
            page={page}
            setMerchants={setMerchants}
        />
    );
}

ModalPdqMerchants.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    dnaMerchantsList: PropTypes.array
};

export default ModalPdqMerchants;
