import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Alert } from 'react-bootstrap';
import { Form, Button, CloseButton } from 'react-bootstrap';
import styles from './modal-business-creation.module.scss';

function ModalBusinessCreationComponent(props) {
    return (
        <Modal
            show={props.showModal}
            onHide={() => {
                props.hideModal(false);
                props.setServerMessage(null);
            }}
            centered={true}
        >
            <Modal.Header className={styles.businessCreationTitle}>
                <span>Create business </span>
                <CloseButton onClick={() => props.hideModal(false)} />
            </Modal.Header>
            <Form onSubmit={props.createBusiness}>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Business name:</Form.Label>
                        <Form.Control
                            type="text"
                            isValid={props.validationMessage.isValid}
                            isInvalid={!props.validationMessage.isValid}
                            value={props.businessName}
                            onChange={props.setBusinessName}
                        />
                        <Form.Text className="invalid-feedback">{props.validationMessage.message}</Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {props.serverMessage && !props.isLoading && (
                        <Alert
                            className={styles.alertError}
                            variant="danger"
                            onClose={() => props.setServerMessage(null)}
                            dismissible
                        >
                            {props.serverMessage ? props.serverMessage : ''}.
                        </Alert>
                    )}
                    {!props.isLoading ? (
                        <Button type="submit" disabled={!props.validationMessage.isValid}>
                            Submit
                        </Button>
                    ) : (
                        <button className="btn btn-primary" type="submit" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="ml-2">Loading...</span>
                        </button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

ModalBusinessCreationComponent.propTypes = {
    showModal: PropTypes.bool,
    hideModal: PropTypes.func,
    businessName: PropTypes.string,
    setBusinessName: PropTypes.func,
    validationMessage: PropTypes.object,
    createBusiness: PropTypes.func,
    isLoading: PropTypes.bool,
    userHasBusiness: PropTypes.bool,
    setUserHasBusiness: PropTypes.func,
    checkBusinessName: PropTypes.func,
    serverMessage: PropTypes.object,
    setServerMessage: PropTypes.func,
};

export default ModalBusinessCreationComponent;
