import { useGlobalStateContext } from 'contexts';
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import styles from './modal-take-payment.module.scss';
import getSource from './3ds';

const showSpinner = ({ amount, globalState }) => {
    return (
        <>
            <div className=" text-center">
                <Spinner animation="grow" variant="dark" />{' '}
                <p className="text-muted">
                    Payment processing for {getCurrencySymbol(globalState.merchant?.country)} {amount}. Please don’t
                    press back button or next button.
                </p>
            </div>
        </>
    );
};

const ModalTakePaymentComponent = (props) => {
    const { globalState } = useGlobalStateContext();
    const { showLoadingSpinner, paymentSuccess, onUpdateState: dispatch, state, amount, onModalClose } = props;

    const { showIframe } = state;

    return (
        <>
            <Modal show onHide={() => onModalClose(paymentSuccess, props, dispatch)}>
                <Modal.Header closeButton className={styles.modalHeader}></Modal.Header>
                <Modal.Body className={styles.modalBody}>
                    {showIframe ? (
                        <div style={{ margin: 'auto' }}>
                            <iframe
                                title="3ds"
                                srcDoc={getSource(state.acsUrl, state.md, state.paReq)}
                                height="500px"
                                className="card-form"
                                frameBorder={0}
                            />
                        </div>
                    ) : showLoadingSpinner ? (
                        <>{showSpinner({ amount, globalState })}</>
                    ) : paymentSuccess !== null ? (
                        paymentSuccess ? (
                            <>
                                <h1>
                                    Your payment request has been sent successfully.
                                    <span style={{ color: '#29a329', font: 'bold' }} className="fe fe-check" />
                                </h1>
                            </>
                        ) : (
                            <>
                                <h1>
                                    Your payment request has failed.
                                    <span style={{ color: '#8b0000', font: 'bold' }} className="fe fe-x" />
                                </h1>
                            </>
                        )
                    ) : (
                        <></>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalTakePaymentComponent;
