import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    Button,
    Grid,
    OutlinedInput,
    DialogContent,
    DialogActions,
    InputAdornment,
    CircularProgress,
    Box,
    Radio,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Typography
} from '@material-ui/core';
import { FormItem } from 'components/onboarding/onboarding-ui-components';
import { QrFields } from 'utils/enums/QrCode';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { SnackBarAndAlert, ValidDomainInput } from 'components';
import NumberFormat from 'react-number-format';
import styles from './modal-create-qr-code.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
function ModalCreateQrCodeComponent(props) {
    const {
        showCreteQr,
        selectPaymentQr,
        handleGenerateQr,
        setQrDetails,
        qrDetails,
        isDisable,
        isCopyAlertOpen,
        closeCopyAlert,
        showDatePicker,
        setShowDatePicker,
        setValue,
        value,
        closeCreateModal,
        isLoading,
        isMaxAmount,
        showDateValidationMessage,
        setSelectPaymentQr
    } = props;
    return (
        <Dialog
            className={styles.qrModalPaper}
            open={showCreteQr}
            styles={{ justifyContent: 'center' }}
            disableEnforceFocus
        >
            <div className={styles.qrModal}>
                <DialogContent>
                    <div id="form-dialog-title" className={styles.createQRTitle}>
                        Create QR Code
                    </div>
                    <br />
                    <Grid container className={styles.container}>
                        <div
                            className={styles.radioContainer}
                            onChange={(e) => {
                                setSelectPaymentQr(e.target.value === 'true' ? true : false);
                                if (e.target.value === 'true') {
                                    setValue('no');
                                }
                                setQrDetails({
                                    qrCodeExpiryDate: null,
                                    description: '',
                                    amount: '',
                                    qrName: '',
                                    websiteUrl: ''
                                });
                                setShowDatePicker(false);
                            }}
                        >
                            <RadioGroup row aria-label="position" name="position" defaultValue="true">
                                <FormControlLabel value="true" control={<Radio color="primary" />} label="Payment QR" />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="primary" />}
                                    label="Generic QR"
                                />
                            </RadioGroup>
                        </div>

                        {selectPaymentQr && (
                            <Grid xs={12}>
                                <div className={styles.labelText}>
                                    <Typography color="primary" className={styles.labelTextType}>
                                        Accept only fixed amount on this QR?
                                    </Typography>
                                </div>
                                <div
                                    className={styles.radioContainer}
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                    }}
                                >
                                    <RadioGroup row aria-label="position" name="position" defaultValue="no">
                                        <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                        <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                    </RadioGroup>
                                </div>
                                {value === 'yes' && (
                                    <div className={styles.amount}>
                                        <FormItem>
                                            <div>
                                                <div className={styles.labelText}>
                                                    <Typography color="primary" className={styles.labelTextType}>
                                                        Amount
                                                    </Typography>
                                                    <span style={{ color: 'red' }}>*</span>
                                                </div>
                                                <NumberFormat
                                                    id="amount-input"
                                                    autoFocus
                                                    className={styles.amountInput}
                                                    customInput={OutlinedInput}
                                                    placeholder="00.00"
                                                    disabled={selectPaymentQr === false}
                                                    inputProps={{ inputMode: 'numeric' }}
                                                    autoComplete="off"
                                                    isAllowed={isMaxAmount}
                                                    value={qrDetails.amount}
                                                    onChange={(event) => {
                                                        setQrDetails({
                                                            ...qrDetails,
                                                            amount: event.target.value
                                                        });
                                                    }}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    isNumericString={true}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <span>{getCurrencySymbol('United Kingdom')}</span>
                                                        </InputAdornment>
                                                    }
                                                    aria-describedby="outlined-weight-helper-text"
                                                    required
                                                />
                                            </div>
                                        </FormItem>
                                    </div>
                                )}
                            </Grid>
                        )}
                        <Grid xs={12}>
                            <Grid xs={12}>
                                <div className={styles.description}>
                                    <FormItem>
                                        <div className={styles.labelText}>
                                            <Typography color="primary" className={styles.labelTextType}>
                                                {QrFields.qrDescription + '(optional)'}
                                            </Typography>
                                        </div>
                                        <OutlinedInput
                                            placeholder="Description will be shown on the QR code"
                                            name="description"
                                            className={styles.qrModelInput}
                                            rows="2"
                                            disableUnderline
                                            type="text"
                                            required
                                            autoComplete="off"
                                            inputProps={{ maxLength: '60', inputMode: 'text' }}
                                            value={qrDetails.description}
                                            onChange={(event) => {
                                                setQrDetails({
                                                    ...qrDetails,
                                                    description: event.target.value
                                                });
                                            }}
                                            style={{ height: 35 }}
                                        />
                                    </FormItem>
                                </div>
                            </Grid>
                            <Grid xs={12}>
                                <div className={styles.checkBox}>
                                    <div className={styles.labelText}>
                                        <Typography color="primary" className={styles.labelTextType}>
                                            {QrFields.qrDueDate + '(optional)'}
                                        </Typography>
                                    </div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={showDatePicker}
                                                onChange={(e) => {
                                                    setShowDatePicker(e.target.checked);
                                                    if (!e.target.checked) {
                                                        setQrDetails({
                                                            ...qrDetails,
                                                            qrCodeExpiryDate: null
                                                        });
                                                    }
                                                }}
                                            />
                                        }
                                        label="Close this QR code after"
                                        labelPlacement="end"
                                    />
                                </div>
                                <FormItem>
                                    <div className={styles.dateInput}>
                                        <div className={styles.datePicker}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDateTimePicker
                                                    className={styles.expiryDateInput}
                                                    value={qrDetails.qrCodeExpiryDate}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            setQrDetails({
                                                                ...qrDetails,
                                                                qrCodeExpiryDate: new Date(value['$d'])
                                                            });
                                                        }
                                                    }}
                                                    onError={console.log}
                                                    inputFormat="YYYY/MM/DD HH:mm a"
                                                    mask="____/__/__ __:__ _M"
                                                    renderInput={(params) => (
                                                        <OutlinedInput
                                                            className={styles.expiryDateInput}
                                                            {...params}
                                                            placeholder={'YYYY/MM/DD HH:mm'}
                                                        />
                                                    )}
                                                    showToolbar={false}
                                                    disablePast={true}
                                                    disabled={!showDatePicker}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        {showDateValidationMessage && (
                                            <div className={styles.notificationMessageContainer}>
                                                <Typography
                                                    color="error"
                                                    style={{ marginLeft: '10px', fontSize: '14px' }}
                                                >
                                                    Time should be 5 minutes more than current time.
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </FormItem>
                            </Grid>
                            <Grid xs={12}>
                                <Grid>
                                    <div className={styles.labelText}>
                                        <Typography color="primary" className={styles.labelTextType}>
                                            {QrFields.qrName + '(optional)'}
                                        </Typography>
                                    </div>
                                    <OutlinedInput
                                        placeholder="Store Front (not visible on QR code)"
                                        className={styles.input}
                                        id="name-input"
                                        inputProps={{ maxLength: '60', inputMode: 'text' }}
                                        value={qrDetails.qrName}
                                        onChange={(event) => {
                                            setQrDetails({
                                                ...qrDetails,
                                                qrName: event.target.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {!selectPaymentQr && (
                                <Grid xs={12}>
                                    <FormItem>
                                        <span className={styles.qrModalValidInput}>*</span>
                                        <ValidDomainInput
                                            placeholder={
                                                selectPaymentQr === true ? 'Link' : 'Ex: https://www.omni-pay.com'
                                            }
                                            disabled={selectPaymentQr === true ? true : false}
                                            labelText={QrFields.link}
                                            labelId="website-url"
                                            id="website-url-input"
                                            value={selectPaymentQr ? '' : qrDetails.websiteUrl}
                                            onChange={(value) => {
                                                setQrDetails({
                                                    ...qrDetails,
                                                    websiteUrl: value
                                                });
                                            }}
                                            inputProps={{ maxLength: '150', inputMode: 'text' }}
                                        />
                                    </FormItem>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                {isLoading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DialogActions style={{ padding: '8px 24px' }}>
                        <div className={styles.actionButton}>
                            <Button variant="contained" className={styles.cancelBtn} onClick={closeCreateModal}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" disabled={isDisable} onClick={handleGenerateQr}>
                                Generate QR Code
                            </Button>
                        </div>
                    </DialogActions>
                )}
                {isCopyAlertOpen && (
                    <SnackBarAndAlert open={isCopyAlertOpen} onClose={closeCopyAlert} type={'success'}>
                        Successfully copied the payment url.
                    </SnackBarAndAlert>
                )}
            </div>
        </Dialog>
    );
}

ModalCreateQrCodeComponent.propTypes = {
    showQrCode: PropTypes.bool,
    setShowQrCode: PropTypes.bool
};

export default ModalCreateQrCodeComponent;
