import React, { useState, useEffect, useContext } from 'react';
import style from './merchant-dashboard-menu.module.scss';
import browserHistory from 'browserHistory';
import { AuthService } from 'services';
import PropTypes from 'prop-types';
import { Collapse, Grid, List, ListItem, Typography, useMediaQuery, Badge } from '@material-ui/core';
import {
    ArrowBack,
    ExitToApp,
    HelpOutline,
    HowToRegOutlined,
    Payment,
    SettingsOutlined,
    SupervisorAccount,
    Search,
    LanguageOutlined,
    DescriptionOutlined,
    ViewList,
    NoteAdd,
    RepeatOne,
    AccountBalance
} from '@material-ui/icons';
import CropFreeIcon from '@material-ui/icons/CropFree';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrintIcon from '@material-ui/icons/Print';
import { SnackBarAndAlert } from 'components';
import _ from 'lodash';
import Header from '../../layouts/header/header.container';
import { useGlobalStateContext } from 'contexts';
import { Auth } from 'aws-amplify';
import { ResellerType } from 'utils/enums/ResellerType';
import { Roles } from 'utils/enums/Roles';
import { UserType } from 'utils/enums/UserType';
import { Context } from '../../contexts/language-switch-context/language-switch-context';
import { supportedLanguages } from 'utils/constants';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { withStyles } from '@material-ui/core/styles';
import { merchantTypes } from 'utils/helper.js';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

const StyledBadge = withStyles({
    badge: {
        background: '#8783b5',
        color: '#f6f7fa',
        transform: 'scale(1) translate(85%, -45%)'
    }
})(Badge);
function MerchantDashBoardMenuComponent(props) {
    const context = useContext(Context);
    let defaultOpenPaymentCollapse = false;
    let defaultOpenQRCollapse = false;
    let isOnTransactionsPage;
    let isOnQrPage;
    const { globalState, setGlobalState, setRoute, getRoute } = useGlobalStateContext();
    isOnTransactionsPage = _.some(
        ['internal-transfers', 'chargebacks', 'payouts', 'payments', 'merchant-charges', 'virtual-terminal'],
        (pagePath) => _.includes(browserHistory.location.pathname, pagePath, 1)
    );
    isOnQrPage = _.some(['payment-qr-code', 'generic-qr-code'], (pagePath) =>
        _.includes(browserHistory.location.pathname, pagePath, 1)
    );

    if (isOnTransactionsPage) {
        defaultOpenPaymentCollapse = true;
    }
    if (isOnQrPage) {
        defaultOpenQRCollapse = true;
    }
    const [openPaymentCollapse, setOpenPaymentCollapse] = useState(defaultOpenPaymentCollapse);
    const notShowOption = merchantTypes.includes(globalState.merchant?.merchantType);
    const [openQrCollapse, setOpenQrCollapse] = useState(defaultOpenQRCollapse);

    const {
        paymentUrl,
        payoutsUrl,
        gfoPaymentsUrl,
        chargebackUrl,
        merchantChargesUrl,
        internalTransfersUrl,
        isDynamicMenu,
        setOpen,
        pdqTransactionUrl,
        showPdq,
        showVT,
        noOfNotSignedTermsAndConditions,
        setIsCopyAlertOpen,
        isCopyAlertOpen,
        handleCleverClick,
        listRefundUrl,
        chargebackListUrl
    } = props;
    const mediaLessThan960px = useMediaQuery('(max-width:959px)');
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isMerchant = globalState.user?.UserType?.name === UserType.Merchant;
    const isCanonicalResellerUser = globalState.user?.UserType?.name === UserType.Reseller;
    const isAdyenMerchant = globalState?.merchant?.isAdyenMerchant;
    const isMerchantAccountDisabled =
        globalState.merchant?.status === MerchantStatus.CLOSED &&
        globalState.user?.UserType?.name !== UserType.SuperAdmin;

    const showTermsAndCondition = noOfNotSignedTermsAndConditions > 0;
    let tabs = [
        {
            name: 'Dashboard',
            icon: <HowToRegOutlined className={style.iconSize} />,
            onClick: () => {
                handleCleverClick('Dashboard_nav_click');
                setOpenPaymentCollapse(false);
                setOpenQrCollapse(false);
                setRoute('/home');
                //remove balance from session storage for balance api call on loading of the dashboard
                sessionStorage.removeItem('balance');
            },
            show: isCanonicalResellerUser || isMerchant || isAdmin,
            disableForClosedMerchant: false
        },
        {
            name: 'Payments',
            icon: <Payment className={style.iconSize} />,
            onClick: () => {
                handleCleverClick('Payments_nav_click');
                mediaLessThan960px ? setOpenPaymentCollapse(true) : setOpenPaymentCollapse(!openPaymentCollapse);
                setOpenQrCollapse(false);
                setRoute(paymentUrl.day);
                sessionStorage.removeItem('selectedAcquirer');
                sessionStorage.removeItem('selectedProvider');
            },
            openCollapse: openPaymentCollapse,
            show: isMerchant || isAdmin,
            disableForClosedMerchant: isMerchantAccountDisabled,
            children: [
                {
                    name: 'Chargebacks',
                    show: globalState.reseller?.name === ResellerType.DATMAN,
                    onClick: () => setRoute(chargebackUrl.month),
                    disableForClosedMerchant: isMerchantAccountDisabled
                },
                {
                    name: 'Payouts',
                    show: globalState.reseller?.name === ResellerType.DATMAN,
                    onClick: () => setRoute(payoutsUrl.month),
                    disableForClosedMerchant: isMerchantAccountDisabled
                },
                {
                    name: 'Merchant Charges',
                    show: globalState.reseller?.name === ResellerType.DATMAN,
                    onClick: () => setRoute(merchantChargesUrl.month),
                    disableForClosedMerchant: isMerchantAccountDisabled
                },
                {
                    name: 'Internal Transfers',
                    show:
                        globalState.reseller?.name === ResellerType.DATMAN &&
                        globalState.merchant?.internalTransferStatus &&
                        [MerchantCountries.UNITED_KINGDOM, MerchantCountries.IRELAND].includes(
                            globalState.merchant?.country
                        ) &&
                        !notShowOption,

                    onClick: () => setRoute(internalTransfersUrl.month),
                    disableForClosedMerchant: isMerchantAccountDisabled
                },
                {
                    name: 'PDQ transactions',
                    show: showPdq,
                    onClick: () => {
                        handleCleverClick('PDQ_txn_nav_click');
                        setRoute(pdqTransactionUrl.day);
                    },
                    disableForClosedMerchant: isMerchantAccountDisabled
                    //onClick: () => setRoute(pdqTransactionUrl.day)
                },
                {
                    name: 'Google Food Orders',
                    show:
                        globalState.reseller?.name === ResellerType.DATMAN &&
                        (isAdyenMerchant || globalState.merchant?.country === MerchantCountries.AUSTRALIA),
                    onClick: () => setRoute(gfoPaymentsUrl.day),
                    disableForClosedMerchant: isMerchantAccountDisabled
                },
                {
                    name: 'Virtual terminal',
                    show: showVT,
                    onClick: () => {
                        handleCleverClick('VT_nav_click');
                        setRoute('/virtual-terminal');
                    },
                    disableForClosedMerchant: isMerchantAccountDisabled
                }
            ]
        },
        {
            name: 'QR Code',
            icon: <CropFreeIcon className={style.iconSize} />,
            onClick: () => {
                handleCleverClick('QR_code_nav_click');
                setOpenQrCollapse(!openQrCollapse);
                setOpenPaymentCollapse(false);
                setRoute('/generic-qr-code');
            },
            openCollapse: openQrCollapse,
            show: globalState.reseller?.name !== ResellerType.DATMAN && (isMerchant || isAdmin),
            disableForClosedMerchant: isMerchantAccountDisabled,
            children: [
                {
                    name: 'Payment QR',
                    show: globalState.reseller?.name === ResellerType.OMNIPAY,
                    onClick: () => setRoute('/payment-qr-code'),
                    disableForClosedMerchant: isMerchantAccountDisabled
                }
            ]
        },
        {
            name: 'Settings',
            icon: <SettingsOutlined className={style.iconSize} />,
            onClick: () => {
                handleCleverClick('Settings_nav_click');
                setOpenPaymentCollapse(false);
                setOpenQrCollapse(false);
                setRoute('/settings');
            },
            show: isCanonicalResellerUser || isMerchant || isAdmin,
            disableForClosedMerchant: isMerchantAccountDisabled
            // separator: 'DEVELOPER'
        },
        {
            name: 'Terms and Conditions',
            icon: <DescriptionOutlined className={style.iconSize} />,
            badgeNo: noOfNotSignedTermsAndConditions ? noOfNotSignedTermsAndConditions : 0,
            onClick: () => {
                setOpenPaymentCollapse(false);
                setOpenQrCollapse(false);
                setRoute('/setting-terms-and-conditions');
            },
            show: (isCanonicalResellerUser || isMerchant) && showTermsAndCondition,
            disableForClosedMerchant: isMerchantAccountDisabled
        },
        {
            name: 'Log out',
            show: isCanonicalResellerUser || isMerchant || isAdmin,
            icon: <ExitToApp className={style.iconSize} />,
            onClick: () => {
                handleCleverClick('Logout_click');
                AuthService.logout();
                Auth.signOut({ global: true }).then(() => {
                    setGlobalState({ ...globalState, user: null, merchant: null });
                    setRoute('/login');
                });
            },
            disableForClosedMerchant: false
        },
        {
            name: 'Get help',
            icon: <HelpOutline className={style.iconSize} />,
            onClick: () => {
                setOpenPaymentCollapse(false);
                setOpenQrCollapse(false);
                let routeToGo;
                if (globalState.reseller?.helpPageURL) {
                    routeToGo = globalState.reseller.helpPageURL;
                } else {
                    routeToGo = getRoute('/help');
                }
                window.open(routeToGo, '_blank');
            },
            show: isCanonicalResellerUser || isMerchant || isAdmin,
            disableForClosedMerchant: false
        },
        {
            name: 'Merchant Id',
            icon: <FiberManualRecordOutlinedIcon className={style.iconSize} />,
            onClick: () => {},
            showInResellerView: false,
            disableForClosedMerchant: false
        },
        {
            name: 'Search merchant',
            icon: <Search className={style.iconSize} />,
            onClick: () => {
                setOpenPaymentCollapse(false);
                setOpenQrCollapse(false);
                setRoute('/admin-pending-merchants');
            },
            show: isAdmin,
            separator: 'ADMIN',
            disableForClosedMerchant: false
        },
        {
            name: 'Missing merchants',
            icon: <Search className={style.iconSize} />,
            onClick: () => {
                setOpenPaymentCollapse(false);
                setRoute('/admin-missing-merchant');
            },
            show: isAdmin && globalState.reseller?.name === ResellerType.DATMAN,
            disableForClosedMerchant: false
        },
        {
            name: 'Admin functions',
            icon: <SupervisorAccount className={style.iconSize} />,
            onClick: () => {
                setRoute(`/admin-functions/${globalState.merchant?.id}`);
                setOpenPaymentCollapse(false);
            },
            show: isAdmin,
            disableForClosedMerchant: isMerchantAccountDisabled
        },
        {
            name: 'Bank verification list',
            icon: <SupervisorAccount className={style.iconSize} />,
            onClick: () => {
                setRoute('/pending-bank-verification-list');
            },
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: isMerchantAccountDisabled
        },
        {
            name: 'Account verification list',
            icon: <SupervisorAccount className={style.iconSize} />,
            onClick: () => {
                setRoute('/pending-account-verification-list');
            },
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: isMerchantAccountDisabled
        },
        {
            name: 'Contract details',
            icon: <NoteAdd className={style.iconSize} />,
            onClick: () => {
                setOpenPaymentCollapse(false);
                setRoute('/contract-details');
            },
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: isMerchantAccountDisabled
        },
        {
            name: 'Print agreement',
            icon: <PrintIcon className={style.iconSize} />,
            onClick: () => {
                setOpenPaymentCollapse(false);
                setRoute('/print-agreement');
            },
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: isMerchantAccountDisabled
        },
        {
            name: 'List refund',
            icon: <ViewList className={style.iconSize} />,
            onClick: () => setRoute(listRefundUrl.day),
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: false
        },
        {
            name: 'Chargeback List',
            icon: <RepeatOne className={style.iconSize} />,
            onClick: () => setRoute(chargebackListUrl.day),
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: false
        },
        {
            name: 'Bank error list',
            icon: <AccountBalance className={style.iconSize} />,
            onClick: () => setRoute('/bank-error-list'),
            show: isAdmin && globalState.reseller.name === ResellerType.DATMAN,
            disableForClosedMerchant: false
        }
    ];
    if (globalState.merchant?.userRole === Roles.User || globalState.reseller?.name !== ResellerType.OMNIPAY) {
        tabs = tabs.filter((tabObj) => tabObj.name !== 'Virtual terminal');
    }

    const [selectedTab, setSelectedTab] = useState(tabs[0].name);

    useEffect(() => {
        const currentLocation = window.location.pathname;

        if (currentLocation.includes('/admin-functions')) {
            setSelectedTab('Admin functions');
        }
    }, [globalState.merchant]);

    return (
        <React.Fragment>
            <div className={style.menuContainer}>
                <List component="nav">
                    {mediaLessThan960px && (
                        <ListItem>
                            <Grid container className={style.headerContainer}>
                                <Grid item xs={10} className={style.headerItem} onClick={() => setOpen(false)}>
                                    <Header />
                                </Grid>
                                <Grid item xs={2}>
                                    <ArrowBack className={style.arrowBack} onClick={() => setOpen(false)} />
                                </Grid>
                            </Grid>
                        </ListItem>
                    )}
                    {tabs.map((tab) => (
                        <React.Fragment key={tab.name}>
                            {tab.show && (
                                <React.Fragment>
                                    {tab.separator && (
                                        <ListItem button disabled>
                                            <div className={style.menuItemContainer}>
                                                <span className={style.separator}>{tab.separator}</span>
                                            </div>
                                        </ListItem>
                                    )}
                                    <ListItem
                                        className={selectedTab === tab.name ? style.activeMenu : ''}
                                        button
                                        onClick={() => {
                                            if (tab.disableForClosedMerchant) {
                                                return;
                                            }
                                            if (!tab.disableForClosedMerchant) {
                                                tab.onClick();
                                            }
                                            if (tab.name === 'Merchant Id') {
                                                return;
                                            }
                                            if (!tab.children && isDynamicMenu) {
                                                setOpen(false);
                                            }
                                            if (mediaLessThan960px) {
                                                setOpen(false);
                                            }
                                            setSelectedTab(tab.name);
                                            setGlobalState({
                                                ...globalState,
                                                shouldCheckForUrgentMessages: !globalState.shouldCheckForUrgentMessages
                                            });
                                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                        }}
                                    >
                                        <div
                                            className={`${style.menuItemContainer} ${
                                                tab.disableForClosedMerchant ? style.disableMenuContainer : ''
                                            }`}
                                            id="paymentListDaily"
                                        >
                                            {tab.icon}
                                            <StyledBadge
                                                id="badge"
                                                color="default"
                                                badgeContent={tab.badgeNo}
                                                data-tooltip="hello"
                                            >
                                                <Typography
                                                    color="primary"
                                                    className={`${style.menuText} ${
                                                        tab.disableForClosedMerchant ? style.disableMenuText : ''
                                                    }`}
                                                    component="span"
                                                >
                                                    {tab.name}
                                                </Typography>
                                            </StyledBadge>
                                        </div>
                                    </ListItem>
                                    {tab.children && (
                                        <Collapse in={tab.openCollapse} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding dense={true}>
                                                {tab.children.map((item) =>
                                                    item.show === undefined || item.show ? (
                                                        <ListItem
                                                            key={item.name}
                                                            button
                                                            onClick={() => {
                                                                if (isDynamicMenu) {
                                                                    setOpen(false);
                                                                }
                                                                setSelectedTab(tab.name);
                                                                if (!tab.disableForClosedMerchant) {
                                                                    item.onClick();
                                                                }
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    left: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                        >
                                                            <div className={style.menuItemContainer}>
                                                                <Typography
                                                                    color="primary"
                                                                    className={style.nestedMenuText}
                                                                    component="span"
                                                                >
                                                                    {item.name}
                                                                </Typography>
                                                            </div>
                                                        </ListItem>
                                                    ) : null
                                                )}
                                            </List>
                                        </Collapse>
                                    )}
                                    {tab.name === 'Merchant Id' && (
                                        <Grid container className={style.merchantIdMenuItemContainer}>
                                            <Grid>{globalState?.merchant?.id}</Grid>
                                            <Grid item xs={1}>
                                                <FileCopyIcon
                                                    className={style.copyIcon}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(globalState?.merchant?.id);
                                                        setIsCopyAlertOpen(true);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </div>
            {globalState.merchant?.country === MerchantCountries.MEXICO && (
                <ListItem>
                    <div className={style.menuItemContainer}>
                        <LanguageOutlined className={style.iconSize} />
                        <Typography color="primary" className={style.menuText} component="span">
                            <select
                                value={context.locale}
                                onChange={context.selectLanguage}
                                className={style.languageSelect}
                            >
                                {supportedLanguages.map((item) => (
                                    <option key={item.locale} value={item.locale}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </Typography>
                    </div>
                </ListItem>
            )}
            {isCopyAlertOpen && (
                <SnackBarAndAlert open={isCopyAlertOpen} onClose={() => setIsCopyAlertOpen(false)} type={'success'}>
                    Successfully copied the merchant id.
                </SnackBarAndAlert>
            )}
        </React.Fragment>
    );
}

MerchantDashBoardMenuComponent.propTypes = {
    paymentUrl: PropTypes.object,
    payoutsUrl: PropTypes.object,
    chargebackUrl: PropTypes.object,
    merchantChargesUrl: PropTypes.object,
    internalTransfersUrl: PropTypes.object,
    isDynamicMenu: PropTypes.bool,
    setOpen: PropTypes.func,
    pdqTransactionUrl: PropTypes.object,
    showPdq: PropTypes.bool,
    showVT: PropTypes.bool
};

export default MerchantDashBoardMenuComponent;
