import React, { useEffect, useState } from 'react';
import StripePayoutsSummaryAndTransactionsComponents from './stripe-payouts-summary-and-transactions.component';
import moment from 'moment';
import { formatDateTimeByFormatString, toTitleCase } from 'utils/helper';
import { PayoutsSummaryTypes } from '../../../../utils/enums/PayoutsSummaryTypes';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';

const StripePayoutsSummaryAndTransactions = (props) => {
    const { transactions, loading, id, date } = props;
    const [summary, setSummary] = useState([]);
    const [totalPayouts, setTotalPayouts] = useState(0);
    const [csvData, setCSVData] = useState([]);
    const [showReportModel, setShowReportModel] = useState(false);
    const [payoutStartDate, setPayoutStartDate] = useState('');
    const [payoutEndDate, setPayoutEndDate] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onCancelExport = () => {
        setShowReportModel(false);
    };
    const onConfirmExport = () => {
        setShowReportModel(false);
    };
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const stripeTransactionSummary = () => {
            const transactionSummary = [
                { label: 'Charges', count: 0, gross: 0, fees: 0, total: 0, type: 'payment' },
                { label: 'Refunds', count: 0, gross: 0, fees: 0, total: 0, type: 'payment_refund' },
                { label: 'Adjustments', count: 0, gross: 0, fees: 0, total: 0, type: 'adjustment' }
            ];

            transactions.map((transaction) => {
                if (transaction.type !== 'payout') {
                    const index = transactionSummary.findIndex((row) => row.type === transaction.type);

                    if (index >= 0) {
                        transactionSummary[index].count = transactionSummary[index].count + 1;
                        transactionSummary[index].gross = transactionSummary[index].gross + transaction.amount;
                        transactionSummary[index].fees = transactionSummary[index].fees + transaction.fee;
                        transactionSummary[index].total =
                            transactionSummary[index].total + transaction.amount - transaction.fee;
                    } else {
                        const summaryObject = {
                            label: PayoutsSummaryTypes[transaction.type],
                            count: 1,
                            gross: transaction.amount,
                            fees: transaction.fee,
                            total: transaction.amount - transaction.fee,
                            type: transaction.type
                        };
                        transactionSummary.push(summaryObject);
                    }
                }
                return null;
            });

            const total = transactionSummary.reduce((total, row) => row.total + total, 0);
            setSummary(transactionSummary);
            setTotalPayouts(total);
        };
        let data = transactions
            .filter((transaction) => transaction.type !== 'payout')
            .map((transaction) => {
                return {
                    Currency: getCurrencyThreeAlpha(globalState.merchant?.country),
                    Gross: transaction.amount / 100,
                    Fee: transaction.fee / 100,
                    Net: transaction.net / 100,
                    Date: formatDateTimeByFormatString(moment.unix(transaction.created), 'YYYY-MM-DD HH:mm'),
                    Type: toTitleCase(transaction.type)
                };
            });
        setPayoutEndDate(moment.unix(date).endOf('month').format('YYYY-MM-DD'));
        setPayoutStartDate(moment.unix(date).startOf('month').format('YYYY-MM-DD'));
        stripeTransactionSummary();
        setCSVData(data);
    }, [transactions, date, globalState.merchant.country]);

    const summaryTableHeaders = [
        { id: 'type', numeric: false, disablePadding: false, hideSortingIcon: true },
        { id: 'count', numeric: true, disablePadding: false, label: 'Count', hideSortingIcon: true, rightAlign: true },
        { id: 'gross', numeric: false, disablePadding: false, label: 'Gross', hideSortingIcon: true, rightAlign: true },
        { id: 'fees', numeric: false, disablePadding: false, label: 'Fees', hideSortingIcon: true, rightAlign: true },
        { id: 'total', numeric: false, disablePadding: false, label: 'Total', hideSortingIcon: true, rightAlign: true }
    ];
    const transactionsTableHeaders = [
        { id: 'type', numeric: false, disablePadding: false, label: 'Type', hideSortingIcon: true },
        { id: 'gross', numeric: false, disablePadding: false, label: 'Gross', hideSortingIcon: true, rightAlign: true },
        { id: 'fees', numeric: false, disablePadding: false, label: 'Fees', hideSortingIcon: true, rightAlign: true },
        { id: 'total', numeric: false, disablePadding: false, label: 'Total', hideSortingIcon: true, rightAlign: true },
        { id: 'date', numeric: false, disablePadding: false, label: 'Date', hideSortingIcon: true }
    ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 2.5));
        setPage(0);
    };
    return (
        <StripePayoutsSummaryAndTransactionsComponents
            summaryTableHeaders={summaryTableHeaders}
            transactionsTableHeaders={transactionsTableHeaders}
            transactions={transactions}
            summary={summary}
            totalPayouts={totalPayouts}
            loading={loading}
            id={id}
            csvData={csvData}
            showReportModel={showReportModel}
            setShowReportModel={setShowReportModel}
            payoutStartDate={payoutStartDate}
            payoutEndDate={payoutEndDate}
            onCancelExport={onCancelExport}
            onConfirmExport={onConfirmExport}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
        />
    );
};

export default StripePayoutsSummaryAndTransactions;
