import React, { useState, useEffect } from 'react';
import { useGlobalStateContext } from 'contexts';
import ResetPasswordFormComponent from './reset-password-form.component';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { AuthService } from 'services';

function ResetPasswordForm(props) {
    const { userOnPasswordUpdatePageAfterExpiry } = props;
    const { setRoute } = useGlobalStateContext();
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [resetToken, setResetToken] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordValidationFailed, setIsPasswordValidationFailed] = useState(true);
    const [redoPasswordValidation, setRedoPasswordValidation] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const location = useLocation();

    useEffect(() => {
        const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
        if (queryString.email) {
            setEmail(queryString.email);
        }
        if (queryString['reset-token'] && !userOnPasswordUpdatePageAfterExpiry) {
            setResetToken(queryString['reset-token']);
        }
        if (queryString.firstName) {
            setFirstName(queryString.firstName);
        }
        if (queryString.lastName) {
            setLastName(queryString.lastName);
        }
        // eslint-disable-next-line
    }, [location]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setIsSuccess(false);
        setMessage('');

        let result = await AuthService.changePassword({
            username: email,
            password: resetToken,
            newPassword: newPassword
        });

        if (result.isSuccesfully) {
            // try {
            //     await AuthService.resetDatmanPassword('email', email, newPassword);
            // } catch (error) {
            //     console.error('Datman password cannot be updated, ignore and continue.', error);
            // }

            setIsSuccess(true);
            setMessage('Successfully set the new password. Redirecting to login page...');
            setTimeout(() => {
                setRoute('/login');
            }, 3000);
        } else {
            setIsSuccess(false);
            let errorMessage = result.data.error;
            if (result.data.error === 'Incorrect username or password.') {
                errorMessage = 'Reset token has expired. This link is no longer valid.';
            }

            setMessage(`Failed to set the new password. Error: ${errorMessage}`);
        }

        setLoading(false);
    };

    const handleSubmitOnExpiry = async (event) => {
        event.preventDefault();
        setLoading(true);
        setIsSuccess(false);
        setMessage('');
        let result = await AuthService.passwordRotation({
            username: email,
            oldPassword,
            newPassword
        });
        if (result.isSuccesfully) {
            setIsSuccess(true);
            setMessage('Successfully set the new password. Redirecting to login page...');
            setTimeout(() => {
                setRoute('/login');
            }, 3000);
        } else {
            setIsSuccess(false);
            setMessage(`Failed to set the new password.`);
        }
        setLoading(false);
    };

    return (
        <ResetPasswordFormComponent
            handleSubmit={handleSubmit}
            isSuccess={isSuccess}
            message={message}
            setMessage={setMessage}
            loading={loading}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            setIsPasswordValidationFailed={setIsPasswordValidationFailed}
            isPasswordValidationFailed={isPasswordValidationFailed}
            redoPasswordValidation={redoPasswordValidation}
            setRedoPasswordValidation={setRedoPasswordValidation}
            email={email}
            firstName={firstName}
            lastName={lastName}
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            handleSubmitOnExpiry={handleSubmitOnExpiry}
            {...props}
        />
    );
}

export default ResetPasswordForm;
