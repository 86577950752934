import React from 'react';
import ListchargebackComponent from './listchargeback-component.jsx';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDateRangeOfWeek } from '../../utils/helper';
import { AdminService } from 'services';
import _ from 'lodash';

const ListChargebacks = (props) => {
    const [listChargeback, setListChargeback] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { path, range, via } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [txSearchTerm, setTxSearchTerm] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [chargebackStatus, setChargebackStatus] = useState({});
    const [chargebackTransactionId, setChargebackTransactionId] = useState('');
    const [showSnackbarStatus, setShowSnackbarStatus] = useState(false);

    const [initialListChargeback, setInitialListChargeback] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [chargebackListStatus, setChargebackListStatus] = useState('');

    let { date } = props.match.params;

    useEffect(() => {
        if (totalAmount) {
            fetchChargebackList();
        }
        // eslint-disable-next-line
    }, [date]);

    window.addEventListener('keydown', function (e) {
        if (e.metaKey && e.key === 'f') {
            if (document.getElementById('pending-merchants-search-two') !== document.activeElement) {
                e.preventDefault();
                document.getElementById('pending-merchants-search-two').focus();
            } else {
                return true;
            }
        }
    });

    const handleModal = (
        chargebackStatus,
        chargebackDate,
        chargebackReason,
        chargebackComments,
        chargebackOutcome,
        chargebackTxId
    ) => {
        setOpenModal(true);
        setChargebackStatus({
            chargebackStatus: chargebackStatus,
            chargebackDate: chargebackDate,
            chargebackReason: chargebackReason,
            chargebackComments: chargebackComments,
            chargebackOutcome: chargebackOutcome
        });
    };

    const fetchChargebackList = async () => {
        setLoading(true);
        const day = moment(date).format('DD/MM/YYYY');
        let payload = {
            date: day,
            amount: totalAmount
        };
        const responseList = await AdminService.getChargebackList(payload);
        if (responseList.isSuccesfully) {
            setListChargeback(responseList.data.chargebacks);
            setInitialListChargeback(responseList.data.chargebacks);
        } else {
            setListChargeback([]);
            setInitialListChargeback([]);
            setError(true);
        }

        setLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearchbyTx = () => {
        setLoading(true);
        if (txSearchTerm !== '') {
            const result = _.filter(initialListChargeback, function (refund) {
                let response = refund.tx_auth_no.includes(txSearchTerm);
                if (response) {
                    return refund;
                }
            });
            setListChargeback(result);
        } else {
            setListChargeback(initialListChargeback);
        }
        setLoading(false);
    };

    useEffect(() => {
        const urls = {
            next: `/${path}/${range}/`,
            previous: `/${path}/${range}/`,
            range: date
        };

        switch (range) {
            case 'day': {
                urls.range = moment(date).format('DD MMM YY');
                urls.previous += moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'day').format('YYYY-MM-DD');
                break;
            }
            case 'week': {
                const isoWeek = moment(date, 'YYYY-MM-DD').isoWeek();
                const year = moment(date, 'YYYY-MM-DD').isoWeekYear();
                const dateRangeOfWeek = getDateRangeOfWeek(isoWeek, year);

                urls.range = dateRangeOfWeek;
                urls.previous += moment(date).subtract(1, 'week').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'week').format('YYYY-MM-DD');
                break;
            }
            case 'month': {
                urls.range = moment(date).format('MMMM / YYYY');
                urls.previous += moment(date).subtract(1, 'month').format('YYYY-MM-DD');
                urls.next += moment(date).add(1, 'month').format('YYYY-MM-DD');
                break;
            }
            default:
                break;
        }
    }, [path, range, date]);

    let headCells = [
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: 'Date',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'merchantId',
            numeric: false,
            disablePadding: false,
            label: 'Merchant ID',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'paymentStatus',
            numeric: false,
            disablePadding: false,
            label: 'Payment Status',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'total',
            numeric: false,
            disablePadding: false,
            label: 'Total',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'crossReference',
            numeric: false,
            disablePadding: false,
            label: 'Cross Reference',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'taxAuthNo',
            numeric: false,
            disablePadding: false,
            label: 'TxAuth Number',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'provider',
            numeric: false,
            disablePadding: false,
            label: 'Provider',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'lastFour',
            numeric: false,
            disablePadding: false,
            label: 'Last4',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'address',
            numeric: false,
            disablePadding: false,
            label: 'Address',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'telephone',
            numeric: false,
            disablePadding: false,
            label: 'Telephone',
            hideSortingIcon: true,
            alignCenter: true
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Action',
            hideSortingIcon: true,
            alignCenter: true
        }
    ];

    return (
        <ListchargebackComponent
            via={via}
            date={date}
            rows={listChargeback}
            loading={loading}
            setLoading={setLoading}
            error={error}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            setTxSearchTerm={setTxSearchTerm}
            txSearchTerm={txSearchTerm}
            onSearchbyTx={onSearchbyTx}
            headCells={headCells}
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleModal={handleModal}
            setTotalAmount={setTotalAmount}
            totalAmount={totalAmount}
            fetchChargebackList={fetchChargebackList}
            chargebackStatus={chargebackStatus}
            setChargebackStatus={setChargebackStatus}
            chargebackTransactionId={chargebackTransactionId}
            setChargebackTransactionId={setChargebackTransactionId}
            snackbarMessage={snackbarMessage}
            setSnackbarMessage={setSnackbarMessage}
            chargebackListStatus={chargebackListStatus}
            setChargebackListStatus={setChargebackListStatus}
            showSnackbarStatus={showSnackbarStatus}
            setShowSnackbarStatus={setShowSnackbarStatus}
        />
    );
};
export default ListChargebacks;

ListChargebacks.propTypes = {
    onChangeDateRange: PropTypes.func,
    path: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func,
    locationInApp: PropTypes.string
};
