export const countyNames = [
    {
        County: 'Bath and North East Somerset',
        Country: 'England',
        CountyWithCountryName: 'Bath and North East Somerset(England)'
    },
    {
        County: 'Bedfordshire',
        Country: 'England',
        CountyWithCountryName: 'Bedfordshire(England)'
    },
    {
        County: 'Berkshire',
        Country: 'England',
        CountyWithCountryName: 'Berkshire(England)'
    },
    {
        County: 'Bristol',
        Country: 'England',
        CountyWithCountryName: 'Bristol(England)'
    },
    {
        County: 'Buckinghamshire',
        Country: 'England',
        CountyWithCountryName: 'Buckinghamshire(England)'
    },
    {
        County: 'Cambridgeshire',
        Country: 'England',
        CountyWithCountryName: 'Cambridgeshire(England)'
    },
    {
        County: 'Cheshire',
        Country: 'England',
        CountyWithCountryName: 'Cheshire(England)'
    },
    {
        County: 'Cornwall',
        Country: 'England',
        CountyWithCountryName: 'Cornwall(England)'
    },
    {
        County: 'County Durham',
        Country: 'England',
        CountyWithCountryName: 'County Durham(England)'
    },
    {
        County: 'Cumbria',
        Country: 'England',
        CountyWithCountryName: 'Cumbria(England)'
    },
    {
        County: 'Derbyshire',
        Country: 'England',
        CountyWithCountryName: 'Derbyshire(England)'
    },
    {
        County: 'Devon',
        Country: 'England',
        CountyWithCountryName: 'Devon(England)'
    },
    {
        County: 'Dorset',
        Country: 'England',
        CountyWithCountryName: 'Dorset(England)'
    },
    {
        County: 'East Riding of Yorkshire',
        Country: 'England',
        CountyWithCountryName: 'East Riding of Yorkshire(England)'
    },
    {
        County: 'East Sussex',
        Country: 'England',
        CountyWithCountryName: 'East Sussex(England)'
    },
    {
        County: 'Essex',
        Country: 'England',
        CountyWithCountryName: 'Essex(England)'
    },
    {
        County: 'Gloucestershire',
        Country: 'England',
        CountyWithCountryName: 'Gloucestershire(England)'
    },
    {
        County: 'Greater London',
        Country: 'England',
        CountyWithCountryName: 'Greater London(England)'
    },
    {
        County: 'Greater Manchester',
        Country: 'England',
        CountyWithCountryName: 'Greater Manchester(England)'
    },
    {
        County: 'Hampshire',
        Country: 'England',
        CountyWithCountryName: 'Hampshire(England)'
    },
    {
        County: 'Herefordshire',
        Country: 'England',
        CountyWithCountryName: 'Herefordshire(England)'
    },
    {
        County: 'Hertfordshire',
        Country: 'England',
        CountyWithCountryName: 'Hertfordshire(England)'
    },
    {
        County: 'Isle of Wight',
        Country: 'England',
        CountyWithCountryName: 'Isle of Wight(England)'
    },
    {
        County: 'Isles of Scilly',
        Country: 'England',
        CountyWithCountryName: 'Isles of Scilly(England)'
    },
    {
        County: 'Kent',
        Country: 'England',
        CountyWithCountryName: 'Kent(England)'
    },
    {
        County: 'Lancashire',
        Country: 'England',
        CountyWithCountryName: 'Lancashire(England)'
    },
    {
        County: 'Leicestershire',
        Country: 'England',
        CountyWithCountryName: 'Leicestershire(England)'
    },
    {
        County: 'Lincolnshire',
        Country: 'England',
        CountyWithCountryName: 'Lincolnshire(England)'
    },
    {
        County: 'Merseyside',
        Country: 'England',
        CountyWithCountryName: 'Merseyside(England)'
    },
    {
        County: 'Norfolk',
        Country: 'England',
        CountyWithCountryName: 'Norfolk(England)'
    },
    {
        County: 'North Somerset',
        Country: 'England',
        CountyWithCountryName: 'North Somerset(England)'
    },
    {
        County: 'North Yorkshire',
        Country: 'England',
        CountyWithCountryName: 'North Yorkshire(England)'
    },
    {
        County: 'Northamptonshire',
        Country: 'England',
        CountyWithCountryName: 'Northamptonshire(England)'
    },
    {
        County: 'Northumberland',
        Country: 'England',
        CountyWithCountryName: 'Northumberland(England)'
    },
    {
        County: 'Nottinghamshire',
        Country: 'England',
        CountyWithCountryName: 'Nottinghamshire(England)'
    },
    {
        County: 'Oxfordshire',
        Country: 'England',
        CountyWithCountryName: 'Oxfordshire(England)'
    },
    {
        County: 'Rutland',
        Country: 'England',
        CountyWithCountryName: 'Rutland(England)'
    },
    {
        County: 'Shropshire',
        Country: 'England',
        CountyWithCountryName: 'Shropshire(England)'
    },
    {
        County: 'Somerset',
        Country: 'England',
        CountyWithCountryName: 'Somerset(England)'
    },
    {
        County: 'South Gloucestershire',
        Country: 'England',
        CountyWithCountryName: 'South Gloucestershire(England)'
    },
    {
        County: 'South Yorkshire',
        Country: 'England',
        CountyWithCountryName: 'South Yorkshire(England)'
    },
    {
        County: 'Staffordshire',
        Country: 'England',
        CountyWithCountryName: 'Staffordshire(England)'
    },
    {
        County: 'Suffolk',
        Country: 'England',
        CountyWithCountryName: 'Suffolk(England)'
    },
    {
        County: 'Surrey',
        Country: 'England',
        CountyWithCountryName: 'Surrey(England)'
    },
    {
        County: 'Tyne & Wear',
        Country: 'England',
        CountyWithCountryName: 'Tyne & Wear(England)'
    },
    {
        County: 'Warwickshire',
        Country: 'England',
        CountyWithCountryName: 'Warwickshire(England)'
    },
    {
        County: 'West Midlands',
        Country: 'England',
        CountyWithCountryName: 'West Midlands(England)'
    },
    {
        County: 'West Sussex',
        Country: 'England',
        CountyWithCountryName: 'West Sussex(England)'
    },
    {
        County: 'West Yorkshire',
        Country: 'England',
        CountyWithCountryName: 'West Yorkshire(England)'
    },
    {
        County: 'WIltshire',
        Country: 'England',
        CountyWithCountryName: 'WIltshire(England)'
    },
    {
        County: 'Worcestershire',
        Country: 'England',
        CountyWithCountryName: 'Worcestershire(England)'
    },
    {
        County: 'Aberdeenshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Aberdeenshire(Scotland)'
    },
    {
        County: 'Angus',
        Country: 'Scotland',
        CountyWithCountryName: 'Angus(Scotland)'
    },
    {
        County: 'Argyll & Bute',
        Country: 'Scotland',
        CountyWithCountryName: 'Argyll & Bute(Scotland)'
    },
    {
        County: 'Ayrshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Ayrshire(Scotland)'
    },
    {
        County: 'Banffshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Banffshire(Scotland)'
    },
    {
        County: 'Berwickshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Berwickshire(Scotland)'
    },
    {
        County: 'Borders',
        Country: 'Scotland',
        CountyWithCountryName: 'Borders(Scotland)'
    },
    {
        County: 'Caithness',
        Country: 'Scotland',
        CountyWithCountryName: 'Caithness(Scotland)'
    },
    {
        County: 'Clackmannanshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Clackmannanshire(Scotland)'
    },
    {
        County: 'Dumfries & Galloway',
        Country: 'Scotland',
        CountyWithCountryName: 'Dumfries & Galloway(Scotland)'
    },
    {
        County: 'Dunbartonshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Dunbartonshire(Scotland)'
    },
    {
        County: 'East Ayrshire',
        Country: 'Scotland',
        CountyWithCountryName: 'East Ayrshire(Scotland)'
    },
    {
        County: 'East Dunbartonshire',
        Country: 'Scotland',
        CountyWithCountryName: 'East Dunbartonshire(Scotland)'
    },
    {
        County: 'East Lothian',
        Country: 'Scotland',
        CountyWithCountryName: 'East Lothian(Scotland)'
    },
    {
        County: 'East Renfrewshire',
        Country: 'Scotland',
        CountyWithCountryName: 'East Renfrewshire(Scotland)'
    },
    {
        County: 'Fife',
        Country: 'Scotland',
        CountyWithCountryName: 'Fife(Scotland)'
    },
    {
        County: 'Highland',
        Country: 'Scotland',
        CountyWithCountryName: 'Highland(Scotland)'
    },
    {
        County: 'Inverclyde',
        Country: 'Scotland',
        CountyWithCountryName: 'Inverclyde(Scotland)'
    },
    {
        County: 'Kincardineshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Kincardineshire(Scotland)'
    },
    {
        County: 'Midlothian',
        Country: 'Scotland',
        CountyWithCountryName: 'Midlothian(Scotland)'
    },
    {
        County: 'Moray',
        Country: 'Scotland',
        CountyWithCountryName: 'Moray(Scotland)'
    },
    {
        County: 'North Ayrshire',
        Country: 'Scotland',
        CountyWithCountryName: 'North Ayrshire(Scotland)'
    },
    {
        County: 'North Lanarkshire',
        Country: 'Scotland',
        CountyWithCountryName: 'North Lanarkshire(Scotland)'
    },
    {
        County: 'Orkney',
        Country: 'Scotland',
        CountyWithCountryName: 'Orkney(Scotland)'
    },
    {
        County: 'Perth & KInross',
        Country: 'Scotland',
        CountyWithCountryName: 'Perth & KInross(Scotland)'
    },
    {
        County: 'Renfrewshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Renfrewshire(Scotland)'
    },
    {
        County: 'Shetland',
        Country: 'Scotland',
        CountyWithCountryName: 'Shetland(Scotland)'
    },
    {
        County: 'South Ayrshire',
        Country: 'Scotland',
        CountyWithCountryName: 'South Ayrshire(Scotland)'
    },
    {
        County: 'South Lanarkshire',
        Country: 'Scotland',
        CountyWithCountryName: 'South Lanarkshire(Scotland)'
    },
    {
        County: 'Stirlingshire',
        Country: 'Scotland',
        CountyWithCountryName: 'Stirlingshire(Scotland)'
    },
    {
        County: 'West Dunbartonshire',
        Country: 'Scotland',
        CountyWithCountryName: 'West Dunbartonshire(Scotland)'
    },
    {
        County: 'West Lothian',
        Country: 'Scotland',
        CountyWithCountryName: 'West Lothian(Scotland)'
    },
    {
        County: 'Western Isles',
        Country: 'Scotland',
        CountyWithCountryName: 'Western Isles(Scotland)'
    },
    {
        County: 'Blaenau Gwent',
        Country: 'Wales',
        CountyWithCountryName: 'Blaenau Gwent(Wales)'
    },
    {
        County: 'Bridgend',
        Country: 'Wales',
        CountyWithCountryName: 'Bridgend(Wales)'
    },
    {
        County: 'Caerphilly',
        Country: 'Wales',
        CountyWithCountryName: 'Caerphilly(Wales)'
    },
    {
        County: 'Cardiff',
        Country: 'Wales',
        CountyWithCountryName: 'Cardiff(Wales)'
    },
    {
        County: 'Carmarthenshire',
        Country: 'Wales',
        CountyWithCountryName: 'Carmarthenshire(Wales)'
    },
    {
        County: 'Ceredigion',
        Country: 'Wales',
        CountyWithCountryName: 'Ceredigion(Wales)'
    },
    {
        County: 'Conwy',
        Country: 'Wales',
        CountyWithCountryName: 'Conwy(Wales)'
    },
    {
        County: 'Denbighshire',
        Country: 'Wales',
        CountyWithCountryName: 'Denbighshire(Wales)'
    },
    {
        County: 'Flintshire',
        Country: 'Wales',
        CountyWithCountryName: 'Flintshire(Wales)'
    },
    {
        County: 'Gwynedd',
        Country: 'Wales',
        CountyWithCountryName: 'Gwynedd(Wales)'
    },
    {
        County: 'Isle of Anglesey',
        Country: 'Wales',
        CountyWithCountryName: 'Isle of Anglesey(Wales)'
    },
    {
        County: 'Merthyr Tydfil',
        Country: 'Wales',
        CountyWithCountryName: 'Merthyr Tydfil(Wales)'
    },
    {
        County: 'Monmouthshire',
        Country: 'Wales',
        CountyWithCountryName: 'Monmouthshire(Wales)'
    },
    {
        County: 'Neath Port Talbot',
        Country: 'Wales',
        CountyWithCountryName: 'Neath Port Talbot(Wales)'
    },
    {
        County: 'Newport',
        Country: 'Wales',
        CountyWithCountryName: 'Newport(Wales)'
    },
    {
        County: 'Pembrokeshire',
        Country: 'Wales',
        CountyWithCountryName: 'Pembrokeshire(Wales)'
    },
    {
        County: 'Powys',
        Country: 'Wales',
        CountyWithCountryName: 'Powys(Wales)'
    },
    {
        County: 'Rhondda Cynon Taff',
        Country: 'Wales',
        CountyWithCountryName: 'Rhondda Cynon Taff(Wales)'
    },
    {
        County: 'Swansea',
        Country: 'Wales',
        CountyWithCountryName: 'Swansea(Wales)'
    },
    {
        County: 'Torfaen',
        Country: 'Wales',
        CountyWithCountryName: 'Torfaen(Wales)'
    },
    {
        County: 'Vale of Glamorgan',
        Country: 'Wales',
        CountyWithCountryName: 'Vale of Glamorgan(Wales)'
    },
    {
        County: 'Wrexham',
        Country: 'Wales',
        CountyWithCountryName: 'Wrexham(Wales)'
    },
    {
        County: 'Antrim',
        Country: 'Northern Ireland',
        CountyWithCountryName: 'Antrim(Northern Ireland)'
    },
    {
        County: 'Armagh',
        Country: 'Northern Ireland',
        CountyWithCountryName: 'Armagh(Northern Ireland)'
    },
    {
        County: 'Down',
        Country: 'Northern Ireland',
        CountyWithCountryName: 'Down(Northern Ireland)'
    },
    {
        County: 'Fermanagh',
        Country: 'Northern Ireland',
        CountyWithCountryName: 'Fermanagh(Northern Ireland)'
    },
    {
        County: 'Londonderry',
        Country: 'Northern Ireland',
        CountyWithCountryName: 'Londonderry(Northern Ireland)'
    },
    {
        County: 'Tyrone',
        Country: 'Northern Ireland',
        CountyWithCountryName: 'Tyrone(Northern Ireland)'
    }
];
