import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Input, Label } from 'components/onboarding/onboarding-ui-components';
import styles from './bank-sort-code-details.module.scss';
import { CheckMerchantCountries } from '../onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';

function BankSortCodeDetailsComponent(props) {
    const { sortCode, accountNumber, bsbCode, routeNum } = props;

    const useStyles = makeStyles((theme) => ({
        disabledInput: {
            color: theme.palette.text.primary,
            backgroundColor: 'Gainsboro',
            border: '1px solid LightGray',
            borderRadius: '5px',
            fontWeight: '600',
            userSelect: 'none'
        }
    }));

    const classes = useStyles();

    return (
        <Grid item xs={12} className={styles.bankDetailsContainer}>
            <CheckMerchantCountries
                excludedCountries={[
                    MerchantCountries.IRELAND,
                    MerchantCountries.AUSTRALIA,
                    MerchantCountries.NEW_ZEALAND,
                    MerchantCountries.UNITED_STATES
                ]}
            >
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Bank
                </Typography>
                <div className={styles.bankFields}>
                    <Label text="Account number" id="account-number"></Label>
                    <Input
                        disabled
                        placeholder="Account number"
                        id="account-number"
                        classes={{ disabled: classes.disabledInput }}
                        value={accountNumber}
                    />
                </div>
                <div className={styles.bankFields}>
                    <Label text="Sort code" id="sort-code"></Label>
                    <Input
                        disabled
                        placeholder="Sort code"
                        id="sort-code"
                        classes={{ disabled: classes.disabledInput }}
                        value={sortCode}
                    />
                </div>
            </CheckMerchantCountries>
            <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Bank
                </Typography>
                <div className={styles.bankFields}>
                    <Label text="Account number" id="account-number"></Label>
                    <Input
                        disabled
                        placeholder="Account number"
                        id="account-number"
                        classes={{ disabled: classes.disabledInput }}
                        value={accountNumber}
                    />
                </div>
                <div className={styles.bankFields}>
                    <Label text="BSB code" id="Bsb-code"></Label>
                    <Input
                        disabled
                        placeholder="Bsb code"
                        id="Bsb-code"
                        classes={{ disabled: classes.disabledInput }}
                        value={bsbCode}
                    />
                </div>
            </CheckMerchantCountries>
            <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Bank
                </Typography>
                <div className={styles.bankFields}>
                    <Label text="IBAN" id="account-number"></Label>
                    <Input
                        disabled
                        placeholder="Account number"
                        id="account-number"
                        classes={{ disabled: classes.disabledInput }}
                        value={accountNumber}
                    />
                </div>
            </CheckMerchantCountries>
            <CheckMerchantCountries includedCountries={[MerchantCountries.NEW_ZEALAND]}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Bank
                </Typography>
                <div className={styles.bankFields}>
                    <Label text="Account number" id="account-number"></Label>
                    <Input
                        disabled
                        placeholder="Account number"
                        id="account-number"
                        classes={{ disabled: classes.disabledInput }}
                        value={accountNumber}
                    />
                </div>
            </CheckMerchantCountries>
            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Bank
                </Typography>
                <div className={styles.bankFields}>
                    <Label text="Account number" id="account-number"></Label>
                    <Input
                        disabled
                        placeholder="Account number"
                        id="account-number"
                        classes={{ disabled: classes.disabledInput }}
                        value={accountNumber}
                    />
                </div>
                <div className={styles.bankFields}>
                    <Label text="Routing Number" id="Route-num"></Label>
                    <Input
                        disabled
                        placeholder="Routing Number"
                        id="Route-num"
                        classes={{ disabled: classes.disabledInput }}
                        value={routeNum}
                    />
                </div>
            </CheckMerchantCountries>
        </Grid>
    );
}

BankSortCodeDetailsComponent.propTypes = {
    sortCode: PropTypes.string,
    accountNumber: PropTypes.string,
    ibanNumber: PropTypes.string
};

export default BankSortCodeDetailsComponent;
