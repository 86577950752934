import React, { useEffect, useState } from 'react';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import T2SOnboardingBankDetailsComponent from './t2s-onboarding-bank-details.component';
import { NonResellerMerchantService, BankService, AuthService } from 'services';
import { InputValidators } from 'validators';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { validateBankAccountFields } from 'validators';
import { bankValidationMap } from 'validators/onboarding/onboarding-account-number.validator';
import { BankAccountStatus } from 'utils/enums/BankAccountStatus';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { formTypes } from 'utils/enums/FormTypes';
import { isTokenGoingToExpire } from 'utils/helper';

function T2SOnboardingBankDetailsStep(props) {
    // const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();

    const {
        onboardingForm,
        setOnboardingForm,
        oldSortCode,
        setOldSortCode,
        oldAccountNumber,
        setOldAccountNumber
    } = useOnboardingFormContext();

    const [isDisabled, setIsDisabled] = useState(true);
    const [isBankDetailsNotFound, setIsBankDetailsNotFound] = useState(false);
    const { globalState } = useGlobalStateContext();
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [lengthIssue, setLengthIssue] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showErrorForAccNo, setShowErrorForAccNo] = useState(false);
    const [AccNoErrorMessage, setAccNoErrorMessage] = useState('');
    const [accountCodeLength, setAccountCodeLength] = useState(0);
    const [statusValidationBankAccount, setStatusValidationBankAccount] = useState(0);
    const [show, setShow] = useState(false);

    const isT2SMerchant = globalState.isT2SMerchant;

    const [csrfToken, setCSRFToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(
            formTypes.ONBOARDING_BANK_DETAILS,
            globalState.merchant?.id
        );
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const checkDisabled = async () => {
            setStatusValidationBankAccount(0);
            const { accountHolderName, newAccountNumber, nameOfBank } = onboardingForm.tradingAddress;
            const validation = validateAcctNumber(newAccountNumber);
            const checkBankAccount = validateBankAccountFields(
                globalState.merchant?.country,
                onboardingForm.tradingAddress,
                confirmAccountNumber
            );

            const checkValues =
                validation &&
                checkBankAccount &&
                accountHolderName &&
                nameOfBank.trim() &&
                globalState.merchant.status !== MerchantStatus.WATCHLIST;

            setIsDisabled(!checkValues);
        };
        checkDisabled();
        // eslint-disable-next-line
    }, [
        onboardingForm.tradingAddress,
        setOnboardingForm,
        oldSortCode,
        setOldSortCode,
        oldAccountNumber,
        setOldAccountNumber,
        globalState.merchant.country,
        isT2SMerchant,
        confirmAccountNumber,
        globalState.merchant.status
    ]);

    useEffect(() => {
        const AccountNumberLength = async () => {
            if (globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
                setAccountCodeLength(8);
            }
            if (globalState.merchant?.country === MerchantCountries.UNITED_STATES) {
                setAccountCodeLength(17);
            }
            if (globalState.merchant?.country === MerchantCountries.MEXICO) {
                setAccountCodeLength(18);
            }
            if (globalState.merchant?.country === MerchantCountries.NEW_ZEALAND) {
                setAccountCodeLength(16);
            }
            if (globalState.merchant?.country === MerchantCountries.CANADA) {
                setAccountCodeLength(12);
            }
            if (globalState.merchant?.country === MerchantCountries.AUSTRALIA) {
                setAccountCodeLength(9);
            }
            if (globalState.merchant?.country === MerchantCountries.IRELAND) {
                setAccountCodeLength(22);
            }
        };
        AccountNumberLength();
    }, [globalState.merchant.country]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                bankDetails: !isDisabled ? true : false
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    useEffect(() => {
        const validateUKBankDetails = async () => {
            const { sortCode, newAccountNumber } = onboardingForm.tradingAddress;
            let checkValidBankAccount = false;
            if (newAccountNumber?.length === 8 && sortCode?.length === 6) {
                setOldAccountNumber(newAccountNumber);
                setOldSortCode(sortCode);

                const response = await BankService.validateBankDetailsForUK({
                    accountNumber: newAccountNumber,
                    sortCode: sortCode
                });
                if (response.isSuccesfully) {
                    checkValidBankAccount = response.data.IsCorrect;
                }

                setIsBankDetailsNotFound(!checkValidBankAccount);
                setStatusValidationBankAccount(checkValidBankAccount ? 1 : 2);
            } else {
                setIsBankDetailsNotFound(false);
                setStatusValidationBankAccount(0);
            }
        };
        if (globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
            validateUKBankDetails();
        }
        // eslint-disable-next-line
    }, [onboardingForm.tradingAddress.newAccountNumber, onboardingForm.tradingAddress.sortCode]);

    const onSubmit = async () => {
        const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
        let latestCSRFToken = csrfToken;
        if (isCSRFGoingToExpire) {
            latestCSRFToken = await generateCSRFToken();
        }
        const status =
            globalState.merchant?.country !== MerchantCountries.UNITED_KINGDOM
                ? BankAccountStatus.UNVERIFIED
                : statusValidationBankAccount === 1
                ? BankAccountStatus.PASSED
                : BankAccountStatus.FAILED;
        const data = {
            ...onboardingForm.tradingAddress,
            status: status,
            step: 3,
            ownerDetails: onboardingForm.ownerPrimaryAddress,
            formType: formTypes.ONBOARDING_BANK_DETAILS
        };

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 3, data, latestCSRFToken);
    };
    const checkAccountNumber = (confirmAccountNumber) => {
        const isSame = onboardingForm.tradingAddress.newAccountNumber === confirmAccountNumber;

        if (!isSame && confirmAccountNumber.length >= onboardingForm.tradingAddress.newAccountNumber.length) {
            setShowMessage(true);
        }
    };
    const validateAcctNumber = (newAccountNumber) => {
        var validateAccNumber = bankValidationMap(globalState.merchant?.country, newAccountNumber);
        if (validateAccNumber) {
            setShowErrorForAccNo(
                !validateAccNumber.isValid && onboardingForm.tradingAddress.newAccountNumber.length !== 0
            );
            setAccNoErrorMessage(validateAccNumber.message);
        }
        return validateAccNumber.isValid;
    };
    const setBankAccountHandler = (e) => {
        if (e.target.value.length <= accountCodeLength) {
            setShow(false);
            setOnboardingForm({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    newAccountNumber: e.target.value
                }
            });
        }
    };
    useEffect(() => {
        setShowMessage(false);
        if (confirmAccountNumber) {
            checkAccountNumber(confirmAccountNumber);
        }
        // eslint-disable-next-line
    }, [onboardingForm.tradingAddress.newAccountNumber, confirmAccountNumber]);

    useEffect(() => {
        setConfirmAccountNumber(onboardingForm.tradingAddress.newAccountNumber);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        setConfirmAccountNumber(onboardingForm.tradingAddress.newAccountNumber);
        // eslint-disable-next-line
    }, []);

    const checkvalue = () => {
        const isSame = onboardingForm.tradingAddress.newAccountNumber.length !== confirmAccountNumber.length;
        if (isSame) {
            setShow(true);
        } else {
            setShow(false);
        }
    };
    return (
        <T2SOnboardingBankDetailsComponent
            {...props}
            phoneNumberValidator={InputValidators.validatePhoneNumber}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            statusValidationBankAccount={statusValidationBankAccount}
            setStatusValidationBankAccount={setStatusValidationBankAccount}
            isBankDetailsNotFound={isBankDetailsNotFound}
            lengthIssue={lengthIssue}
            setLengthIssue={setLengthIssue}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            confirmAccountNumber={confirmAccountNumber}
            setConfirmAccountNumber={setConfirmAccountNumber}
            checkAccountNumber={checkAccountNumber}
            showErrorForAccNo={showErrorForAccNo}
            AccNoErrorMessage={AccNoErrorMessage}
            accountCodeLength={accountCodeLength}
            setBankAccountHandler={setBankAccountHandler}
            checkvalue={checkvalue}
            show={show}
            setShow={setShow}
            isLoading={isLoading}
        />
    );
}

export default T2SOnboardingBankDetailsStep;
