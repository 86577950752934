import React from 'react';
import { WithdrawalService, AdminService } from 'services';
import ModalConfirmReverseWithdrawalComponent from './modal-confirm-reverse-withdrawal.component';
import { useGlobalStateContext, useAdminFunctionsContext, useBalanceContext } from 'contexts';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';

const ModalConfirmReverseWithdrawal = (props) => {
    const { updatedMerchantData, setIsModalOpen, accountStatusUpdate, setIsLoading } = props;
    const { globalState, setGlobalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const { refreshBalance } = useBalanceContext();

    const reverseTheWithdrawals = async () => {
        setIsLoading(true);
        setIsModalOpen(false);

        if (accountStatusUpdate) {
            setAdminFunctions({
                ...adminFunctions,
                accountStatus: MerchantAccountStatus.WATCHLIST,
                accountVerify: 0,
                bankVerify: 0,
                allowWithdrawals: 0,
                autoWithdraw: 0,
                internalTransferStatus: 0
            });
            setGlobalState((globalState) => ({
                ...globalState,
                merchant: {
                    ...globalState.merchant,
                    status: 12
                }
            }));
        } else {
            setAdminFunctions({
                ...adminFunctions,
                bankVerify: 0
            });
        }
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, updatedMerchantData);

        if (response.isSuccesfully) {
            const reverseWithdrawalResponse = await WithdrawalService.reverseWithdrawal();
            if (reverseWithdrawalResponse.isSuccesfully) {
                refreshBalance();
            }
        }
        setIsLoading(false);
    };

    return <ModalConfirmReverseWithdrawalComponent {...props} reverseTheWithdrawals={reverseTheWithdrawals} />;
};

export default ModalConfirmReverseWithdrawal;
