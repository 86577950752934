import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';

export default function AllowWithdrawalsComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const { onChange } = props;
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;

    return (
        <div>
            <FormItem>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={5}>
                        <Label>Withdrawals (override)</Label>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        <NativeSelect
                            disabled={isReseller || adminFunctions.accountStatus === MerchantAccountStatus.WATCHLIST}
                            input={
                                <Input
                                    id="transaction-allow-withdrawals-id"
                                    value={adminFunctions.allowWithdrawals ? '1' : '0'}
                                    onChange={(e) => {
                                        setAdminFunctions({
                                            ...adminFunctions,
                                            allowWithdrawals: e.target.value === '1' ? true : false
                                        });
                                        onChange(e.target.value);
                                    }}
                                />
                            }
                        >
                            <option value="1">Allow all (override verification status)</option>
                            <option value="0">Allow for verified account</option>
                        </NativeSelect>
                    </Grid>
                </Grid>
            </FormItem>
        </div>
    );
}

AllowWithdrawalsComponent.propTypes = {
    onChange: PropTypes.func
};
