import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, CircularProgress, Box, Link, Grid } from '@material-ui/core';
import styles from './modal-preview-qr-code.module.scss';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SnackBarAndAlert } from 'components';
import CloseIcon from '@material-ui/icons/Close';

function ModalPreviewQrCodeComponent(props) {
    const {
        showQrCode,
        setShowQrCode,
        previewQrDetails,
        handlePrint,
        imageContentRef,
        imageRendered,
        imageLoadedFunction,
        handleCopyUrl,
        isCopyAlertOpen,
        setIsCopyAlertOpen
    } = props;

    return (
        <Dialog
            open={showQrCode}
            onClose={() => setShowQrCode(false)}
            maxWidth="sm"
            fullWidth
            style={{ justifyContent: 'center' }}
        >
            <div className={styles.closeIcon}>
                <CloseIcon onClick={() => setShowQrCode(false)} />
            </div>

            <DialogContent
                style={{ justifyContent: 'center' }}
                classes={{
                    root: styles.dialogContent
                }}
            >
                <div>
                    <img
                        className={styles.qrImage}
                        id="qrCodeImage"
                        src={previewQrDetails.qrImgLink}
                        ref={imageContentRef}
                        alt="Logo"
                        onLoad={imageLoadedFunction}
                    ></img>

                    {!imageRendered ? (
                        <Box textAlign="center" py={5}>
                            <CircularProgress />
                        </Box>
                    ) : null}
                    <div className={styles.urlDiv}>
                        <Grid item xs={10} className={styles.linkDiv}>
                            <Link
                                href={previewQrDetails.link}
                                target="_blank"
                                className={styles.textInput}
                                rel="noopener"
                            >
                                {previewQrDetails.link}
                            </Link>
                        </Grid>
                        <Grid item xs={1}>
                            <FileCopyIcon className={styles.copyIcon} onClick={handleCopyUrl} />
                        </Grid>
                    </div>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <div className={styles.btncontainer}>
                            <Button
                                className={styles.qrBtn}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handlePrint();
                                }}
                            >
                                &nbsp;Print &nbsp;
                            </Button>
                        </div>
                    </DialogActions>
                    {isCopyAlertOpen && (
                        <SnackBarAndAlert
                            open={isCopyAlertOpen}
                            onClose={() => {
                                setIsCopyAlertOpen(false);
                            }}
                            type={'success'}
                        >
                            Successfully copied url.
                        </SnackBarAndAlert>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

ModalPreviewQrCodeComponent.propTypes = {
    showQrCode: PropTypes.bool,
    setShowQrCode: PropTypes.bool,
    handlePrint: PropTypes.func,
    qrCodeType: PropTypes.string,
    imageLoadedFunction: PropTypes.string,
    imageRendered: PropTypes.bool
};

export default ModalPreviewQrCodeComponent;
