import React from 'react';
import styles from './contract-rent.module.scss';
import {
    Button,
    Grid,
    OutlinedInput,
    NativeSelect,
    FormControlLabel,
    Radio,
    RadioGroup,
    useMediaQuery
} from '@material-ui/core';
import { Input, Label, FormItem } from 'components/onboarding/onboarding-ui-components';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { MerchantStatus } from 'utils/enums/MerchantStatus';

function ContractRentComponent(props) {
    const {
        contractRentDetails,
        setContractRentDetails,
        contractRentUpdate,
        contractBtn,
        contractLengthMaxValue,
        isMaxSetupFee,
        noticePeriodMaxValue
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const { globalState } = useGlobalStateContext();
    const isMerchantAccountClosed = globalState.merchant?.status === MerchantStatus.CLOSED;

    return (
        <Grid container xs={12} sm={12} md={12}>
            <Grid container direction="row" className={styles.mainGrid} xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch">
                        <Grid item>
                            <FormItem>
                                <div className={styles.labelText}>
                                    <Label className={styles.contractLabels} text="Contract rent"></Label>
                                    <span className={styles.requiredIcon}>*</span>
                                </div>
                                <NumberFormat
                                    id="contract-rent"
                                    className={styles.numberInput}
                                    customInput={OutlinedInput}
                                    value={contractRentDetails.contract_rent}
                                    inputProps={{ inputMode: 'numeric' }}
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    placeholder={`0.00`}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    isAllowed={isMaxSetupFee}
                                    onChange={(e) =>
                                        setContractRentDetails({
                                            ...contractRentDetails,
                                            contract_rent: e.target.value
                                        })
                                    }
                                />
                            </FormItem>
                        </Grid>
                        <Grid item>
                            <FormItem>
                                <div className={styles.labelText}>
                                    <Label className={styles.contractLabels} text="Contract length"></Label>
                                    <span className={styles.requiredIcon}>*</span>
                                </div>
                                <NumberFormat
                                    id="contract-length"
                                    value={contractRentDetails.contract_length}
                                    className={styles.numberInput}
                                    decimalScale={0}
                                    fixedDecimalScale={0}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    isAllowed={contractLengthMaxValue}
                                    onChange={(e) =>
                                        setContractRentDetails({
                                            ...contractRentDetails,
                                            contract_length: e.target.value
                                        })
                                    }
                                />
                            </FormItem>
                        </Grid>
                        <Grid item>
                            <FormItem>
                                <div className={styles.labelText}>
                                    <Label className={styles.contractLabels} text="Notice period"></Label>
                                    <span className={styles.requiredIcon}>*</span>
                                </div>
                                <NumberFormat
                                    id="notice-period"
                                    className={styles.numberInput}
                                    value={contractRentDetails.notice_period}
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    decimalScale={0}
                                    fixedDecimalScale={0}
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    isAllowed={noticePeriodMaxValue}
                                    onChange={(e) =>
                                        setContractRentDetails({
                                            ...contractRentDetails,
                                            notice_period: e.target.value
                                        })
                                    }
                                />
                            </FormItem>
                        </Grid>
                        <Grid item>
                            <FormItem>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="left"
                                    alignItems="center"
                                    spacing={0}
                                    xs={12}
                                    md={12}
                                >
                                    <Grid item xs={3} md={3}>
                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                            <Label className={styles.contractLabels} text="Setup fee"></Label>
                                            <span className={styles.requiredIcon}>*</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                        <RadioGroup
                                            row
                                            aria-label="setup-fee"
                                            value={contractRentDetails.setup_fee}
                                            onChange={(e) =>
                                                setContractRentDetails({
                                                    ...contractRentDetails,
                                                    setup_fee: e.target.value
                                                })
                                            }
                                        >
                                            <FormControlLabel value="0" control={<Radio />} label="0" />
                                            <FormControlLabel value="150" control={<Radio />} label="150" />
                                            <FormControlLabel value="300" control={<Radio />} label="300" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </FormItem>
                            <FormItem>
                                <NumberFormat
                                    id="setup-fee"
                                    className={styles.numberInput}
                                    customInput={OutlinedInput}
                                    value={contractRentDetails.setup_fee}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    isAllowed={isMaxSetupFee}
                                    decimalScale={2}
                                    onChange={(e) =>
                                        setContractRentDetails({
                                            ...contractRentDetails,
                                            setup_fee: e.target.value
                                        })
                                    }
                                />
                            </FormItem>
                        </Grid>
                        <Grid item>
                            <FormItem>
                                <div className={styles.labelText}>
                                    <Label className={styles.contractLabels} text="Charge Setup Fee"></Label>{' '}
                                    <span className={styles.requiredIcon}>*</span>
                                </div>
                                <NativeSelect
                                    //disabled={isReseller}
                                    input={
                                        <Input
                                            id="setup-charged"
                                            style={{ width: mediaLessThan600px ? '90%' : '70%' }}
                                            value={contractRentDetails.setup_charged}
                                            onChange={(e) =>
                                                setContractRentDetails({
                                                    ...contractRentDetails,
                                                    setup_charged: JSON.parse(e.target.value)
                                                })
                                            }
                                        />
                                    }
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </NativeSelect>
                            </FormItem>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container direction="column" justifyContent="center" alignItems="stretch">
                        <Grid item>
                            <FormItem>
                                <Label className={styles.contractLabels} text="Extra comments"></Label>
                                <Input
                                    id="extra-comments"
                                    style={{ width: mediaLessThan600px ? '90%' : '70%' }}
                                    inputProps={{ maxLength: 150 }}
                                    value={contractRentDetails.extra_comments}
                                    onChange={(e) =>
                                        setContractRentDetails({
                                            ...contractRentDetails,
                                            extra_comments: e.target.value
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </Grid>
                        <Grid item>
                            <FormItem>
                                <Label className={styles.contractLabels} text="Service description"></Label>
                                <Input
                                    id="service-description"
                                    inputProps={{ maxLength: 100 }}
                                    style={{ width: mediaLessThan600px ? '90%' : '70%' }}
                                    value={contractRentDetails.services_description}
                                    onChange={(e) =>
                                        setContractRentDetails({
                                            ...contractRentDetails,
                                            services_description: e.target.value
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container justifyContent="center" xs={12} md={12} sm={12}>
                <Button
                    variant="contained"
                    disabled={contractBtn || isMerchantAccountClosed}
                    onClick={contractRentUpdate}
                    color="primary"
                    className={styles.updatebtn}
                >
                    Update
                </Button>
            </Grid>
        </Grid>
    );
}
export default ContractRentComponent;
ContractRentComponent.propTypes = {
    contractRentDetails: PropTypes.object,
    setContractRentDetails: PropTypes.func,
    contractRentUpdate: PropTypes.func,
    contractBtn: PropTypes.bool
};
