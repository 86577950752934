import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress
} from '@material-ui/core';
import styles from './modal-delete-merchant-account.module.scss';
import Alert from '@material-ui/lab/Alert';

function ModalDeleteMerchantAccountComponent(props) {
    const { setOpen, onDeleteMerchant, merchantName, isError, isLoading } = props;

    return (
        <Dialog open={true} onClose={() => setOpen(false)} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Delete account
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>
                        You are about to delete account {merchantName}.<br />
                        Are you sure you wish to proceed?
                    </div>
                    {isError && (
                        <Alert className={styles.alertError} severity="error">
                            Cannot delete account. Please contact support or try again.
                        </Alert>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.actionsContainer}>
                {isLoading && <CircularProgress className={styles.loading} color="inherit" />}
                <Button
                    id="cancelButtonDeleteMerchant"
                    onClick={() => setOpen(false)}
                    color="primary"
                    variant="contained"
                >
                    Cancel
                </Button>
                <Button
                    id="createButtonDeleteMerchant"
                    onClick={() => onDeleteMerchant()}
                    color="primary"
                    variant="contained"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalDeleteMerchantAccountComponent.propTypes = {
    setOpen: PropTypes.func,
    onDeleteMerchant: PropTypes.func,
    merchantName: PropTypes.string,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default ModalDeleteMerchantAccountComponent;
