import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import GfoPaymentsListComponent from './gfo-payments-list.component';
import { PaymentsService } from 'services';
import { useGlobalStateContext } from 'contexts';

const GfoPaymentsList = (props) => {
    const [error, setError] = useState(false);
    const [gfoPayments, setGfoPayments] = useState([]);
    const [total, setTotal] = useState(null);
    const [isRefundHappen, setIsRefundHappen] = useState(null);
    const { dates, daterange, loading, setLoading } = props;
    const { date, via } = props.transactionsListingOptions ? props.transactionsListingOptions : props.match.params;
    const { globalState } = useGlobalStateContext();
    useEffect(() => {
        const getPaymentsList = async () => {
            const isWeekly = via === 'week' ? true : false;
            const weekStart = moment(date).startOf('week').format('YYYY-MM-DD');
            // const weekEnd = moment(date).endOf('week').format('DD_MM-YYYY')
            let from = isWeekly ? weekStart : date;
            const paymentsList = await PaymentsService.GetPayments(globalState.merchant?.id, {
                from,
                via: via.toUpperCase()
            });
            if (paymentsList.isSuccesfully) {
                setGfoPayments(paymentsList.data.payments);
                setTotal(paymentsList.data.total);
                setError(false);
            } else {
                setError(true);
            }
            setLoading(false);
        };

        setLoading(true);

        getPaymentsList();
        // eslint-disable-next-line
    }, [props.location.pathname, date, via, globalState.merchant, isRefundHappen]);

    useEffect(() => {
        const getTransactions = async () => {
            try {
                let getTxnList = {
                    data: { payments: [] },
                    isSuccesfully: true,
                    props: false
                };

                if (dates) {
                    const promises = dates.map(async (date) => await getAllPaymentsForRange(date, getTxnList));
                    await Promise.all(promises);

                    getTxnList.data.payments = getTxnList.data.payments.filter((a) => {
                        return (
                            moment(a.time).isAfter(moment(daterange.start)) &&
                            moment(a.time).isBefore(moment(daterange.end).add(1, 'day'))
                        );
                    });

                    getTxnList.data.payments.sort((a, b) => (moment(a.time) < moment(b.time) ? 1 : -1));
                    setGfoPayments(getTxnList.data.payments);
                    setTotal(getTxnList.data.total);
                }
            } catch {
                setError(true);
            }
            setLoading(false);
        };
        setLoading(true);
        getTransactions();
        // eslint-disable-next-line
    }, [dates, daterange]);

    async function getAllPaymentsForRange(date, getTxnList) {
        const data = {
            from: date.from,
            to: date.to,
            via: 'RANGE'
        };
        const response = await PaymentsService.GetPayments(globalState.merchant?.id, data);
        if (!response.isSuccesfully) {
            getTxnList.isSuccesfully = response.isSuccesfully;
        }
        getTxnList.data.payments = [...getTxnList.data.payments, ...response.data.payments];
    }

    return (
        <GfoPaymentsListComponent
            {...props}
            loading={loading}
            error={error}
            gfoPayments={gfoPayments}
            total={total}
            setIsRefundHappen={setIsRefundHappen}
            isRefundHappen={isRefundHappen}
            date={date}
            via={via}
        />
    );
};

export default GfoPaymentsList;

GfoPaymentsList.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            date: PropTypes.string.isRequired,
            via: PropTypes.string.isRequired
        })
    }),
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    })
};
