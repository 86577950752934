import { Roles } from './enums/Roles';
import { Permissions } from './enums/Permissions';

const dictionary = {};

dictionary[Permissions.AssignUser] = [Roles.Admin, Roles.Owner, Roles.Manager];
dictionary[Permissions.BusinessCRUD] = [Roles.Admin, Roles.Owner];
dictionary[Permissions.UserManagement] = [Roles.Admin, Roles.Owner, Roles.Manager];

function checkPermissions(role, permission) {
    if (dictionary[permission] === undefined) return false;
    if (dictionary[permission].includes(role)) return true;

    return false;
}

const getUserRole = (entities) => {
    const rolesList = [];

    entities.Businesses.forEach((business) => {
        rolesList.push(business.role);
        business.Clients.forEach((client) => {
            rolesList.push(client.role);
            client.Merchants.forEach((merchant) => {
                rolesList.push(merchant.role);
            });
        });
    });

    entities.Clients.forEach((client) => {
        rolesList.push(client.role);
        client.Merchants.forEach((merchant) => {
            rolesList.push(merchant.role);
        });
    });

    entities.Merchants.forEach((merchant) => {
        rolesList.push(merchant.role);
    });

    let userRole;
    if (rolesList.includes(Roles.Admin)) {
        userRole = Roles.Admin;
    } else if (rolesList.includes(Roles.Owner)) {
        userRole = Roles.Owner;
    } else if (rolesList.includes(Roles.Manager)) {
        userRole = Roles.Manager;
    } else {
        userRole = Roles.User;
    }

    return userRole;
};

export { getUserRole, checkPermissions };
