import React, { useState, useEffect } from 'react';
import AdminTriggerResetPasswordComponent from './trigger-reset-password.component';
import { AdminService, TeamService } from 'services';
import { useGlobalStateContext } from 'contexts';

const AdminTriggerResetPassword = () => {
    const { globalState } = useGlobalStateContext();
    const [loading, setLoading] = useState(true);
    const [emailUndergoingReset, setEmailUndergoingReset] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamMembersOne, setTeamMembersOne] = useState([]);

    const [isErrorInTeamFetch, setIsErrorInTeamFetch] = useState('');
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [count, setCount] = useState(0);

    const sendResetPasswordLink = async (email, portalURL) => {
        setEmailUndergoingReset(email);
        let result = await AdminService.sendResetPasswordLink(email, portalURL);

        setEmailUndergoingReset('');

        if (result.isSuccesfully) {
            setMessage(`Successfully sent the password reset email to ${email}`);
            setIsSuccess(true);
        } else {
            let errorMessage = result.data.error;
            setMessage(
                `Failed to send the password reset email to ${email}. ${errorMessage ? `Error: ${errorMessage}` : ''}`
            );
            setIsSuccess(false);
        }
    };

    const getTeamMembers = async () => {
        setIsErrorInTeamFetch(false);
        const response = await TeamService.getTeamMembers(globalState.merchant?.id);
        if (response.isSuccesfully) {
            const team = response.data;
            team.teamMembers = team.teamMembers.map((member) => {
                member.Relationships = member.Relationships.find((r) => r.merchantId === globalState.merchant?.id);
                return member;
            });
            setTeamMembers(team.teamMembers);
            setTeamMembersOne(team.teamMembers.slice(0, rowsPerPage));
            setLoading(false);
            setCount(response.data.teamMembers.length);
        } else {
            setIsErrorInTeamFetch(true);
            setCount(0);
        }
    };

    useEffect(() => {
        getTeamMembers();
        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setTeamMembersOne(teamMembers.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage));
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setTeamMembersOne(teamMembers.slice(0, parseInt(event.target.value, 10)));
        setPage(0);
    };
    return (
        <AdminTriggerResetPasswordComponent
            sendResetPasswordLink={sendResetPasswordLink}
            rows={teamMembersOne}
            loading={loading}
            isErrorInTeamFetch={isErrorInTeamFetch}
            merchantName={globalState.merchant?.name}
            loggedInUserEmail={globalState.user?.email}
            emailUndergoingReset={emailUndergoingReset}
            isSuccess={isSuccess}
            message={message}
            setMessage={setMessage}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
        />
    );
};

export default AdminTriggerResetPassword;
