import React from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, Grid } from '@material-ui/core';
import { FormItem, Label, Input } from '../../../onboarding/onboarding-ui-components';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import { UserType } from 'utils/enums/UserType';
import ModalConfirmReverseWithdrawal from '../modal-confirm-reverse-withdrawal/modal-confirm-reverse-withdrawal.container';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import LoadingScreen from 'components/loading-screen/loading-screen.container';

export default function BankVerificationComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions } = useAdminFunctionsContext();
    const { onChange, isModalOpen, setIsModalOpen, updatedMerchantData, isLoading, setIsLoading } = props;
    const isReseller = globalState.user?.UserType?.name === UserType.Reseller;

    return (
        <div>
            <FormItem>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={5}>
                        <Label>Bank verification </Label>
                    </Grid>
                    <Grid item xs={5} md={4}>
                        <NativeSelect
                            disabled={isReseller || adminFunctions.accountStatus === MerchantAccountStatus.WATCHLIST}
                            input={
                                <Input
                                    id="transaction-refund-id"
                                    value={adminFunctions.bankVerify ? '1' : '0'}
                                    onChange={(e) => {
                                        onChange(e.target.value);
                                    }}
                                />
                            }
                        >
                            <option value="1">Verified</option>
                            <option value="0">Unverified</option>
                        </NativeSelect>
                    </Grid>
                </Grid>
                <ModalConfirmReverseWithdrawal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    modalText="Are you sure to change the bank status from Verified to Unverified ?"
                    updatedMerchantData={updatedMerchantData}
                    setIsLoading={setIsLoading}
                />
            </FormItem>
            <LoadingScreen open={isLoading} />
        </div>
    );
}

BankVerificationComponent.propTypes = {
    onChange: PropTypes.func
};
