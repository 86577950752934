import React from 'react';

import {
    Paper,
    Typography,
    makeStyles,
    withStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import {
    ChevronRight,
    ChevronLeft,
    KeyboardArrowDown,
    Search,
    PictureAsPdfSharp,
    AssessmentSharp
} from '@material-ui/icons';

import MuiTableCell from '@material-ui/core/TableCell';
import style from './admin-dashboard-info.module.scss';

const TableCell = withStyles({
    root: {
        borderBottom: 'none'
    }
})(MuiTableCell);
const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

function AdminDashboardInfoComponent({ rows }) {
    const classes = useStyles();
    return (
        <>
            <TableContainer component={Paper}>
                <div className={style.info_bar}>
                    <ChevronLeft className={style.click_effect} />
                    <Typography color="primary">Week Commencing</Typography>
                    <Typography color="primary" className={`${style.link_font} ${style.info_bar_text}`}>
                        27 Aug 2020
                    </Typography>
                    <KeyboardArrowDown className={style.click_effect} />
                    <span className={style.float_right}>
                        <Search className={style.click_effect} />
                        <ChevronRight className={style.click_effect} />
                    </span>
                </div>

                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Fees</TableCell>
                            <TableCell>NET</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Refund</TableCell>
                            <TableCell>IP address</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.customer}>
                                <TableCell component="th" scope="row">
                                    {row.customer}
                                </TableCell>
                                <TableCell>{row.price}</TableCell>
                                <TableCell>{row.fee}</TableCell>
                                <TableCell>{row.net}</TableCell>
                                <TableCell>{row.time}</TableCell>
                                <TableCell>{row.refund}</TableCell>
                                <TableCell>{row.ipAddr}</TableCell>
                                <TableCell>{row.date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className={`${style.info_ico_bar} ${style.link_font}`}>
                    <div className={`${style.float_right} ${style.info_ico} ${style.click_effect} `}>
                        <PictureAsPdfSharp className={`  `} /> <span>Download PDF</span>
                    </div>
                    <div className={` ${style.info_ico} ${style.click_effect}`}>
                        <AssessmentSharp /> <span>Download CSV</span>
                    </div>
                </div>
            </TableContainer>
        </>
    );
}

AdminDashboardInfoComponent.propTypes = {};

export default AdminDashboardInfoComponent;
