import React from 'react';
import ContractRentComponent from './contract-rent.component';
import PropTypes from 'prop-types';

function ContractRent(props) {
    const initialTermOptions = [...Array(13).keys()];
    initialTermOptions.shift();
    const contractLengthMaxValue = (inputObj) => {
        const { value } = inputObj;
        if (value <= 12) return true;
        return false;
    };
    const noticePeriodMaxValue = (inputObj) => {
        const { value } = inputObj;
        if (value <= 30) return true;
        return false;
    };

    const isMaxSetupFee = (values) => {
        const { value, floatValue } = values;
        const MAX_FEE = 999.0;
        const MIN_FEE = 0;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_FEE) {
            return false;
        }
        if (value < MIN_FEE) {
            return false;
        }
        return true;
    };
    const { contractRentDetails, setContractRentDetails, contractRentUpdate, contractBtn } = props;

    return (
        <ContractRentComponent
            contractRentDetails={contractRentDetails}
            setContractRentDetails={setContractRentDetails}
            contractRentUpdate={contractRentUpdate}
            contractBtn={contractBtn}
            initialTermOptions={initialTermOptions}
            contractLengthMaxValue={contractLengthMaxValue}
            isMaxSetupFee={isMaxSetupFee}
            noticePeriodMaxValue={noticePeriodMaxValue}
        />
    );
}

export default ContractRent;
ContractRent.propTypes = {
    contractRentDetails: PropTypes.object,
    setContractRentDetails: PropTypes.func,
    contractRentUpdate: PropTypes.func,
    contractBtn: PropTypes.bool
};
