import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import WithdrawalSubmissionComponent from './withdrawal-submission.component';
import { WithdrawalService, BusinessDetailsService, AuthService /*, BankService*/ } from 'services';
import { useBalanceContext, useGlobalStateContext } from 'contexts';
import { validateWithdraw } from 'validators';
import { formTypes } from 'utils/enums/FormTypes';
import { ErrorCodes } from 'utils/enums/ErrorCode';

// import { MerchantCountries } from 'utils/enums/MerchantCountries';

export default function WithdrawalSubmission(props) {
    const { refreshBalance, setBalance, setTransitBalance, setAvailableBalance } = useBalanceContext();
    const { globalState, setRoute } = useGlobalStateContext();
    const [isLoading, setIsLoading] = useState(false);
    //const [isFirstWithdrawal, setIsFirstWithdrawal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [info, setInfo] = useState('');
    //const [isBankAndAccountVerified, setIsBankAndAccountVerified] = useState(false);
    //const [businessType, setBusinessType] = useState('');
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
    const [isCancelDisabled, setIsCancelDisabled] = useState(false);
    const [sortCode, setSortCode] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ibanIreland, setIbanIreland] = useState('');
    // const [bankDetails, setBankDetails] = useState({});
    // const [isValid, setIsValid] = useState(false);
    // const [customerConfig, setCustomerConfig] = useState({});
    const [success, setSuccess] = useState(false);
    const { withdrawalAmount, setWithdrawalAmount, setShow, setOpenView } = props;
    const date = moment().format('YYYY-MM-DD');
    const payoutUrl = `/payouts/month/${date}`;
    const [nextPayoutDay, setNextpayoutDay] = useState('Tuesday 5PM');

    useEffect(() => {
        const getNextPayoutDay = async () => {
            const response = await WithdrawalService.getNextWithdrawalDay();
            if (response.isSuccesfully) {
                setNextpayoutDay(response?.data?.data);
            }
        };
        getNextPayoutDay();
        // eslint-disable-next-line
    }, []);

    const [csrfToken, setCSRFToken] = useState('');

    useEffect(() => {
        const generateCSRFToken = async () => {
            setIsLoading(true);
            const response = await AuthService.generateCSRFToken(
                formTypes.CREATE_WITHDRAWAL_REQUEST,
                globalState.merchant?.id
            );
            if (response.isSuccesfully) {
                setCSRFToken(response.data.csrfToken);
            }
            setIsLoading(false);
        };
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    //useEffect(() => {
    // async function fetchData() {
    //     setIsLoading(true);
    //     const response = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(globalState.merchant.id);
    //     setIsLoading(false);
    //     if (response.isSuccesfully) {
    //         setBusinessType(response.data.typeName);
    //         setIsBankAndAccountVerified(response.data.isBankAccountVerified && response.data.isAccountVerified);
    //     } else {
    //         setErrorMessage('An error occurred on bank verification, please contact support');
    //     }
    // }

    // const fetchCustomerConfig = async () => {
    //     setIsLoading(true);
    //     const responseCheckFirstWithdrawal = await WithdrawalService.checkFirstWithdrawal(globalState.merchant?.id);
    //     if (responseCheckFirstWithdrawal.isSuccesfully) {
    //         setIsFirstWithdrawal(responseCheckFirstWithdrawal.data.isFirstWithdrawal);
    //         const responseBankDetails = await BankService.getBankDetails(globalState.merchant?.id);
    //         if (responseBankDetails.isSuccesfully) {
    //             setBankDetails(responseBankDetails.data);
    //         } else {
    //             setBankDetails({});
    //         }
    //     }
    //     setIsLoading(false);
    // };

    //fetchData();
    //fetchCustomerConfig();
    // eslint-disable-next-line
    //}, [show, globalState.merchant.id]);

    // useEffect(() => {
    //     const fetchCustomerConfig = async () => {
    //         if(globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
    //             setIsLoading(true);
    //             const customerConfigResp = await WithdrawalService.getCustomerConfig();
    //             console.log(customerConfigResp);
    //             if (customerConfigResp.isSuccesfully) {
    //                 setCustomerConfig(customerConfigResp.data || {});
    //                 setIsFirstWithdrawal(customerConfig.isFirstwithdrawl);
    //             }
    //             setIsLoading(false);
    //         }

    //     };
    //     fetchCustomerConfig();
    // }, []);

    // useEffect(() => {
    //     const validateBankDetailsUK = async () => {
    //         const validationUK =
    //             sortCode &&
    //             sortCode.length === 6 &&
    //             sortCode === customerConfig.sortCode &&
    //             accountNumber &&
    //             accountNumber.length === 8 &&
    //             accountNumber === customerConfig.newAccountNumber;
    //         setIsValid(validationUK);
    //     };
    //     validateBankDetailsUK();
    // }, [sortCode, accountNumber, customerConfig.sortCode, customerConfig.newAccountNumber]);

    // useEffect(() => {
    //     const validateBankDetailsIreland = async () => {
    //         const validationUK =
    //             ibanIreland && ibanIreland.length === 22 && ibanIreland === bankDetails.newAccountNumber;
    //         setIsValid(validationUK);
    //     };
    //     validateBankDetailsIreland();
    // }, [ibanIreland, bankDetails.newAccountNumber]);

    const submitWithdrawal = async () => {
        setIsConfirmDisabled(true);
        setIsCancelDisabled(true);
        setIsLoading(true);

        const merchantVerificationResponse = await BusinessDetailsService.getMerchantBusinessTypeAndBankStatus(
            globalState.merchant?.id
        );
        if (merchantVerificationResponse.isSuccesfully) {
            const cantWithDrawValidationResult = validateWithdraw(
                merchantVerificationResponse.data.isAccountVerified,
                merchantVerificationResponse.data.isBankAccountVerified,
                merchantVerificationResponse.data.allowWithdrawals
            );
            if (!cantWithDrawValidationResult.isValid) {
                setErrorMessage(cantWithDrawValidationResult.message);
            } else {
                await WithdrawalService.submitWithdrawal(
                    withdrawalAmount,
                    globalState.user.id,
                    globalState.merchant?.id,
                    csrfToken
                ).then((response) => {
                    if (response.isSuccesfully) {
                        if (response.data.status !== 'fail') {
                            if (response.data.status === 'OK') {
                                // setBalance(response.data.balance);
                                setBalance(response.data.balance);
                                setTransitBalance(response.data.balance_transit);
                                setAvailableBalance(response.data.available_balance);
                                sessionStorage.setItem('balance', response.data.balance);
                                sessionStorage.setItem('availableBalance', response.data.available_balance);
                                sessionStorage.setItem('transitBalance', response.data.balance_transit);
                            } else {
                                refreshBalance();
                            }

                            setSuccess(true);
                            setOpenView(true);
                            setIsConfirmDisabled(false);

                            // let newCustomerConfig = { ...customerConfig };
                            // newCustomerConfig.isFirstwithdrawl = false;
                            // setCustomerConfig(newCustomerConfig);
                        } else {
                            if (response.data.statusCode == 1013) {
                                setInfo(response.data.message);
                            } else {
                                setErrorMessage(response.data.message);
                            }
                        }
                    } else {
                        const errorMessage =
                            response.data.errorCode === ErrorCodes.CSRF_VALIDATION_FAILURE.errorCode
                                ? ErrorCodes.CSRF_VALIDATION_FAILURE.message
                                : ErrorCodes.WITHDRAWAL_ERROR.message;
                        setErrorMessage(errorMessage);
                    }
                });
            }
        } else {
            setErrorMessage('An internal error occurred, please press Cancel to check your updated balance');
        }

        // if (
        //     customerConfig.isFirstwithdrawl &&
        //     (sortCode !== customerConfig.sortcode || accountNumber !== customerConfig.bankaccount)
        // ) {
        //     setErrorMessage('Sort code and account number do not match the existing details on your account!');
        //     return;
        // }

        setIsLoading(false);
        setIsCancelDisabled(false);
    };

    const handlePopupClose = () => {
        if (errorMessage || info) {
            window.location.reload();
        } else {
            setShow(false);
        }
        setTimeout(() => {
            setWithdrawalAmount(null);
        }, 500);
        setSuccess(false);
    };

    return (
        <WithdrawalSubmissionComponent
            {...props}
            submitWithdrawal={submitWithdrawal}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            // isBankAndAccountVerified={isBankAndAccountVerified}
            setRoute={setRoute}
            // businessType={businessType}
            isLoading={isLoading}
            isConfirmDisabled={isConfirmDisabled}
            isCancelDisabled={isCancelDisabled}
            handlePopupClose={handlePopupClose}
            sortCode={sortCode}
            setSortCode={setSortCode}
            accountNumber={accountNumber}
            setAccountNumber={setAccountNumber}
            ibanIreland={ibanIreland}
            setIbanIreland={setIbanIreland}
            // isFirstWithdrawal={isFirstWithdrawal}
            // isValid={isValid}
            // customerConfig={customerConfig}
            success={success}
            payoutUrl={payoutUrl}
            nextPayoutDay={nextPayoutDay}
            info={info}
        ></WithdrawalSubmissionComponent>
    );
}

WithdrawalSubmission.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    withdrawalAmount: PropTypes.number,
    setWithdrawalAmount: PropTypes.func,
    isDisabled: PropTypes.bool,
    setIsDisabled: PropTypes.func,
    handlePopupClose: PropTypes.func
};
