import React from 'react';
import CustomLoadingScreenComponent from './custom-loading-screen.component';

/**
 *
 * @param {{
 * open: boolean
 * }} props
 */
function CustomLoadingScreen(props) {
    return <CustomLoadingScreenComponent {...props} />;
}

export default CustomLoadingScreen;
