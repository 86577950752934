import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalPreviewQrCodeComponent from './modal-preview-qr-code.component';
import { useReactToPrint } from 'react-to-print';
function ModalPreviewQrCode(props) {
    const { setShowQrCode, showQrCode, qrDescription, previewQrDetails } = props;
    const imageContentRef = useRef();
    const [imageRendered, setImageRendered] = useState(false);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);

    const imageLoadedFunction = () => {
        setImageRendered(true);
    };

    const imageReference = React.useCallback(() => {
        return new Promise((resolve) => {
            imageContentRef.current.width = '800';
            resolve();
        });
    }, []);

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(previewQrDetails.link);
        setIsCopyAlertOpen(true);
    };

    const handlePrint = useReactToPrint({
        content: () => imageContentRef.current,
        documentTitle: `qr_code`,

        onBeforePrint: imageReference
    });

    return (
        <ModalPreviewQrCodeComponent
            setShowQrCode={setShowQrCode}
            previewQrDetails={previewQrDetails}
            showQrCode={showQrCode}
            handlePrint={handlePrint}
            imageContentRef={imageContentRef}
            qrDescription={qrDescription}
            imageRendered={imageRendered}
            imageLoadedFunction={imageLoadedFunction}
            handleCopyUrl={handleCopyUrl}
            isCopyAlertOpen={isCopyAlertOpen}
            setIsCopyAlertOpen={setIsCopyAlertOpen}
        />
    );
}
ModalPreviewQrCode.propTypes = {
    isLoading: PropTypes.bool,
    showQrCode: PropTypes.bool,
    setShowQrCode: PropTypes.bool,
    handlePrint: PropTypes.func
};
export default ModalPreviewQrCode;
