import React from 'react';
import PropTypes from 'prop-types';
import { ErrorOutline, CheckCircle } from '@material-ui/icons';
import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem,
    PostCodeSelector
} from 'components/onboarding/onboarding-ui-components';
import { BankSortCodeNumberContainer } from 'components';
import { checkEmptyValue, getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
import ReactCodeInput from 'react-verification-code-input';
import styles from './onboarding-address.module.scss';
import { Checkbox, FormControlLabel, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { useGlobalStateContext } from 'contexts';

function OnboardingAdressComponent(props) {
    const { globalState } = useGlobalStateContext();
    const isT2SMerchant = globalState.isT2SMerchant;
    const {
        onboardingForm,
        setOnboardingForm,
        isFormValid,
        onSubmit,
        selectAddressData,
        statusValidationBankAccount,
        onChangeSameAddress,
        isBankDetailsNotFound,
        accNumberInputRef,
        confirmAccountNumber,
        setConfirmAccountNumber,
        checkAccountNumber,
        showMessage,
        setShowMessage
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const mediaLessThan750px = useMediaQuery('(max-width:749px)');
    const mediaLessThan400px = useMediaQuery('(max-width:399px)');

    const accountCodeLengthUK = 8;

    const title = (
        <OnboardingTitle title="Trading address" subTitle=" Some specifics on your business"></OnboardingTitle>
    );

    function formBody() {
        return (
            <React.Fragment>
                <FormItem>
                    <FormControlLabel
                        className={styles.checkBoxLabel}
                        control={
                            <Checkbox
                                color="primary"
                                checked={onboardingForm.tradingAddress.isSameAsBusinessAddress}
                                onChange={(e) => onChangeSameAddress(e.target.checked)}
                            />
                        }
                        label="Trading address is the same as registered address"
                        labelPlacement="end"
                    />
                </FormItem>
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                    <FormItem>
                        <Label text="Postcode" id="address-label-post-code-selector"></Label>
                        <PostCodeSelector
                            postCodeValue={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onPostCodeChange={(postCode) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: postCode
                                    }
                                })
                            }
                            onAddressObjectChange={(addressData) => selectAddressData(addressData)}
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                    <FormItem>
                        <Label text="ZIP code" id="name-address-label-post-code"></Label>
                        <Input
                            placeholder="ZIP code"
                            id="zip-code"
                            value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries
                    excludedCountries={[MerchantCountries.UNITED_KINGDOM, MerchantCountries.UNITED_STATES]}
                >
                    <FormItem>
                        <Label text="Post code" id="address-label-post-code"></Label>
                        <Input
                            placeholder="Post code"
                            id="address-input-post-code"
                            value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        postCode: e.target.value
                                    }
                                })
                            }
                        ></Input>
                    </FormItem>
                </CheckMerchantCountries>
                <FormItem>
                    <Label text="Address line 1" id="address-label-address"></Label>
                    <Input
                        placeholder="Address line 1"
                        id="address-input-address"
                        value={checkEmptyValue(onboardingForm.tradingAddress.addressLine1)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                tradingAddress: {
                                    ...onboardingForm.tradingAddress,
                                    addressLine1: e.target.value
                                }
                            })
                        }
                    />
                </FormItem>
                <FormItem>
                    <Label text="Address line 2" id="address-label-address-line-2"></Label>
                    <Input
                        placeholder="Address line 2"
                        id="address-input-address-line-2"
                        value={checkEmptyValue(onboardingForm.tradingAddress.addressLine2)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                tradingAddress: {
                                    ...onboardingForm.tradingAddress,
                                    addressLine2: e.target.value
                                }
                            })
                        }
                    />
                </FormItem>
                <FormItem>
                    <Label text="Town/City" id="address-label-town-city"></Label>
                    <Input
                        placeholder="Town/City"
                        id="address-input-town-city"
                        value={checkEmptyValue(onboardingForm.tradingAddress.city)}
                        onChange={(e) =>
                            setOnboardingForm({
                                ...onboardingForm,
                                tradingAddress: {
                                    ...onboardingForm.tradingAddress,
                                    city: e.target.value
                                }
                            })
                        }
                    />
                </FormItem>
                <CheckMerchantCountries excludedCountries={[MerchantCountries.AUSTRALIA]}>
                    <FormItem>
                        <Label text="Country" id="address-label-country"></Label>
                        <Input
                            placeholder="Country"
                            id="address-input-country"
                            value={checkEmptyValue(onboardingForm.tradingAddress.country)}
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        country: e.target.value
                                    }
                                })
                            }
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                    {onboardingForm.isPopulated && (
                        <FormItem>
                            <Label text="BSB" id="bsb-australia-label" />
                            <ReactCodeInput
                                className={styles.codeInput}
                                fieldHeight={mediaLessThan600px ? 27 : 34}
                                fieldWidth={mediaLessThan600px ? 27 : 34}
                                autoFocus={false}
                                fields={6}
                                type="number"
                                pattern="\d*"
                                values={getArrayFromString(onboardingForm.tradingAddress.bsb)}
                                onChange={(value) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            bsb: value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    )}
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                    {onboardingForm.isPopulated && (
                        <FormItem>
                            <Label text="Routing number" id="routing-number" />
                            <ReactCodeInput
                                className={styles.codeInput}
                                fieldHeight={mediaLessThan600px ? (mediaLessThan400px ? 23 : 27) : 34}
                                fieldWidth={mediaLessThan600px ? (mediaLessThan400px ? 23 : 27) : 34}
                                autoFocus={false}
                                fields={9}
                                type="number"
                                pattern="\d*"
                                values={getArrayFromString(onboardingForm.tradingAddress.routingNumber)}
                                onChange={(value) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            routingNumber: value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    )}
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                    {onboardingForm.isPopulated && (
                        <FormItem>
                            <Label text="Transit number" id="transit-number" />
                            <ReactCodeInput
                                className={styles.codeInput}
                                fieldHeight={mediaLessThan600px ? 27 : 34}
                                fieldWidth={mediaLessThan600px ? 27 : 34}
                                autoFocus={false}
                                fields={5}
                                type="number"
                                pattern="\d*"
                                values={getArrayFromString(onboardingForm.tradingAddress.transitNumber)}
                                onChange={(value) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            transitNumber: value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    )}
                    {onboardingForm.isPopulated && (
                        <FormItem>
                            <Label text="Financial institution number" id="financial-institution-number" />
                            <ReactCodeInput
                                className={styles.codeInput}
                                fieldHeight={mediaLessThan600px ? 27 : 34}
                                fieldWidth={mediaLessThan600px ? 27 : 34}
                                autoFocus={false}
                                fields={3}
                                type="number"
                                pattern="\d*"
                                values={getArrayFromString(onboardingForm.tradingAddress.financialInstitutionNumber)}
                                onChange={(value) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            financialInstitutionNumber: value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    )}
                </CheckMerchantCountries>
                {onboardingForm.isPopulated && (
                    <React.Fragment>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                            <Grid container>
                                <Grid item xs={mediaLessThan600px ? 12 : 6}>
                                    <div className={styles.accountContainer}>
                                        <FormItem>
                                            <Label text="Bank sort code" id="bank-sort-code" />
                                            <BankSortCodeNumberContainer
                                                hideDashes={mediaLessThan600px}
                                                values={onboardingForm.tradingAddress.sortCode}
                                                nextElementRef={accNumberInputRef}
                                                onChange={(value) => {
                                                    setOnboardingForm({
                                                        ...onboardingForm,
                                                        tradingAddress: {
                                                            ...onboardingForm.tradingAddress,
                                                            sortCode: value
                                                        }
                                                    });
                                                }}
                                            />
                                        </FormItem>
                                        <FormItem>
                                            <Label text="Account number" id="new-account-number" />
                                            <Input
                                                className={styles.codeInput}
                                                autoFocus={false}
                                                type="number"
                                                inputProps={{ ref: accNumberInputRef }}
                                                pattern="\d*"
                                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= accountCodeLengthUK) {
                                                        setOnboardingForm({
                                                            ...onboardingForm,
                                                            tradingAddress: {
                                                                ...onboardingForm.tradingAddress,
                                                                newAccountNumber: e.target.value
                                                            }
                                                        });
                                                    }
                                                }}
                                            />
                                        </FormItem>
                                    </div>
                                </Grid>
                                <Grid item xs={mediaLessThan600px ? 12 : 6} className={styles.iconContainer}>
                                    {statusValidationBankAccount === 1 && <CheckCircle className={styles.validIcon} />}
                                    {statusValidationBankAccount === 2 && <ErrorOutline className={styles.errorIcon} />}
                                </Grid>
                                <Grid>
                                    {isBankDetailsNotFound && (
                                        <div className={styles.notificationContainer}>
                                            <div className={styles.iconUnverified}>
                                                <ErrorOutline className={styles.error_icon} />
                                            </div>
                                            <Typography color="primary">
                                                We did not find a match for the bank account details provided. Please
                                                review this. If they are correct you may continue with your application.
                                            </Typography>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                            <FormItem>
                                <Label text="Bank IBAN" id="bank-IBAN-ireland-label" />
                                <Input
                                    className={styles.codeInput + ' ' + styles.codeInputIreland}
                                    fieldHeight={
                                        (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px ? 22 : 25
                                    }
                                    fieldWidth={
                                        (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px ? 22 : 25
                                    }
                                    autoFocus={false}
                                    fields={22}
                                    type="text"
                                    value={
                                        onboardingForm.tradingAddress.newAccountNumber.length > 0
                                            ? checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)
                                            : 'IE'
                                    }
                                    onChange={(e) => {
                                        if (e.target.value.length <= 22) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    newAccountNumber: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                            <FormItem>
                                <Label text="Account number" id="account-number-australia-label" />
                                <Input
                                    autoFocus={false}
                                    fields={9}
                                    type="number"
                                    pattern="\d*"
                                    value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 9) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    newAccountNumber: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Confirm account number" id="confirm-account-number-australia-label" />
                                <Input
                                    autoFocus={false}
                                    fields={9}
                                    type="number"
                                    pattern="\d*"
                                    value={checkEmptyValue(confirmAccountNumber)}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 9) {
                                            setConfirmAccountNumber(e.target.value);
                                            setShowMessage(false);
                                            if (e.target.value.length === 9) {
                                                checkAccountNumber(e.target.value);
                                            }
                                        }
                                    }}
                                />
                            </FormItem>
                            <Grid className={styles.showMessageStyle}>
                                {showMessage && (
                                    <div className={styles.notificationContainer}>
                                        <div className={styles.iconUnverified}>
                                            <ErrorOutline className={styles.error_icon} />
                                        </div>
                                        <Typography color="primary">
                                            ‘Account number’ is not matching with ‘Confirm account number’
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                            <FormItem>
                                <Label text="Account number" id="account-number" />
                                <Input
                                    autoFocus={false}
                                    fields={20}
                                    type="number"
                                    pattern="\d*"
                                    value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 20) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    newAccountNumber: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.NEW_ZEALAND]}>
                            <FormItem>
                                <Label text="Bank account" id="bank-account" />
                                <Input
                                    autoFocus={false}
                                    type="number"
                                    pattern="\d*"
                                    value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 16) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    newAccountNumber: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                            <FormItem>
                                <Label text="Account number" id="new-account-number" />
                                <Input
                                    autoFocus={false}
                                    type="number"
                                    pattern="\d*"
                                    value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 20) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    newAccountNumber: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                    </React.Fragment>
                )}
                <CheckMerchantCountries
                    excludedCountries={[MerchantCountries.UNITED_KINGDOM, MerchantCountries.CANADA]}
                >
                    <FormItem>
                        <Label text="Name of bank" id="name-of-bank" />
                        <Input
                            placeholder="Name of bank"
                            id="name-of-bank-input"
                            value={onboardingForm.tradingAddress.nameOfBank}
                            onChange={(e) => {
                                if (e.target.value.length <= 80) {
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            nameOfBank: e.target.value
                                        }
                                    });
                                }
                            }}
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                    <FormItem>
                        <Label text="Bank address 1" id="bank-address-1" />
                        <Input
                            placeholder="Bank address 1"
                            id="bank-address-1-input"
                            value={onboardingForm.tradingAddress.bankAddress1}
                            onChange={(e) => {
                                if (e.target.value.length <= 80) {
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            bankAddress1: e.target.value
                                        }
                                    });
                                }
                            }}
                        />
                    </FormItem>
                </CheckMerchantCountries>
                <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                    <FormItem>
                        <Label text="Bank address 2" id="bank-address-2" />
                        <Input
                            placeholder="Bank address 2"
                            id="bank-address-2-input"
                            value={onboardingForm.tradingAddress.bankAddress2}
                            onChange={(e) => {
                                if (e.target.value.length <= 80) {
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            bankAddress2: e.target.value
                                        }
                                    });
                                }
                            }}
                        />
                    </FormItem>
                    {!isT2SMerchant && (
                        <FormItem>
                            <Label text="Account Name (as shown on bank statement)" id="account-holder-name" />
                            <Input
                                placeholder="Account holder(s) name"
                                id="account-holder-name-input"
                                value={onboardingForm.tradingAddress.accountHolderName}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            accountHolderName: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    )}
                </CheckMerchantCountries>

                {!isT2SMerchant && (
                    <CheckMerchantCountries excludedCountries={[MerchantCountries.IRELAND]}>
                        <FormItem>
                            <Label text="Account holder(s) name" id="account-holder-name" />
                            <Input
                                placeholder="Account holder(s) name"
                                id="account-holder-name-input"
                                value={onboardingForm.tradingAddress.accountHolderName}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            accountHolderName: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                )}
            </React.Fragment>
        );
    }

    const nextBtn = (
        <div>
            <NextButton
                disabled={!isFormValid}
                onSubmit={() => {
                    onSubmit();
                }}
            ></NextButton>
        </div>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

OnboardingAdressComponent.propTypes = {
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isFormValid: PropTypes.bool,
    onSubmit: PropTypes.func,
    selectAddressData: PropTypes.func,
    statusValidationBankAccount: PropTypes.bool,
    onChangeSameAddress: PropTypes.bool,
    isBankAccountValid: PropTypes.bool,
    isBankDetailsNotFound: PropTypes.bool,
    confirmAccountNumber: PropTypes.string,
    setConfirmAccountNumber: PropTypes.func,
    checkAccountNumber: PropTypes.func
};

export default OnboardingAdressComponent;
