import React, { useState, useEffect } from 'react';
import MerchantAccountDetailsComponent from './merchant-account-details.component';
import { BusinessDetailsService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { BankService } from 'services';
import _ from 'lodash';

const MerchantAccountDetails = () => {
    const { globalState } = useGlobalStateContext();
    const [businessDetails, setBusinessDetails] = useState({});
    const [ownerDetails, setOwnerDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showBusinessDetails, setShowBusinessDetails] = useState(true);
    const [selectedValue, setSelectedValue] = useState('Business details');
    const [bankData, setBankData] = useState({});

    useEffect(() => {
        const getMerchantBusinessDetails = async () => {
            setIsLoading(true);
            const result = await BusinessDetailsService.getBusinessDetails(globalState.merchant?.id);

            const bankDetails = await BankService.getBankDetails(globalState.merchant?.id);
            if (result.isSuccesfully) {
                setBusinessDetails(result.data?.businessDetails);
                setOwnerDetails(result.data?.ownerDetails);
            }
            if (bankDetails.isSuccesfully) {
                setBankData(bankDetails.data);
            }

            setIsLoading(false);
        };

        getMerchantBusinessDetails();
    }, [globalState.merchant]);
    let finalVal;
    if (!_.isEmpty(bankData)) {
        finalVal = bankData.newAccountNumber.toString();
        var myRegexp = /^([^\s]{6})([^\s]{7})([^\s]{2,3})$/g;
        var match = myRegexp.exec(finalVal);
        if (match) {
            match.shift();
            finalVal = match.join('-');
        }
    }
    return (
        <MerchantAccountDetailsComponent
            businessDetails={businessDetails}
            ownerDetails={ownerDetails}
            isLoading={isLoading}
            selectedValue={selectedValue}
            showBusinessDetails={showBusinessDetails}
            setSelectedValue={setSelectedValue}
            setShowBusinessDetails={setShowBusinessDetails}
            bankData={bankData}
            finalVal={finalVal}
        />
    );
};

export default MerchantAccountDetails;
