import React, { useEffect, useState, useRef } from 'react';
import ModalUpdateBankVerifyComponent from './modal-update-bank-account-verify.component';
import { useGlobalStateContext } from 'contexts';
import OtpService from 'services/otp.service';
const LIMIT = 59;

export default function ModalUpdateBankVerify(props) {
    const {
        setBankUpdateVerifyDialogBox,
        setLoading,
        bankUpdateVerifyDialogBox,
        newBankDetailsToSave,
        raiseBankDetailsUpdateRequest,
        phoneNumber
    } = props;
    const [otpText, setOtpText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const errorTimer = useRef(null);
    const { globalState } = useGlobalStateContext();
    const [seconds, setSeconds] = useState(LIMIT);
    const [isActiveTimer, setIsActiveTimer] = useState(true);

    const startTimer = () => {
        setSeconds(LIMIT);
        setIsActiveTimer(true);
    };

    const onCancel = async () => {
        setBankUpdateVerifyDialogBox(false);
    };

    const onResend = async () => {
        setIsLoading(true);
        errorTimer.current = null;
        setError('');
        const response = await OtpService.resendOtp(globalState.merchant?.id, {
            method: 'phone',
            type: 'bank-update',
            portalURL: globalState.reseller?.portalURL
        });
        if (response.isSuccesfully) {
            startTimer();
        } else {
            setError(response.data);
        }
        setIsLoading(false);
    };

    const onVerify = async () => {
        setIsLoading(true);
        errorTimer.current = null;
        setError('');
        const response = await OtpService.verifyOtp(globalState.merchant?.id, {
            method: 'phone',
            type: 'bank-update',
            value: otpText,
            portalURL: globalState.reseller?.portalURL
        });
        if (response.isSuccesfully) {
            setLoading(true);
            await raiseBankDetailsUpdateRequest(newBankDetailsToSave);
            onCancel();
            setLoading(false);
        } else {
            setError(response.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (error) {
            errorTimer.current = setTimeout(() => {
                setError('');
            }, 12000);
        }
    }, [error]);

    useEffect(() => {
        let interval = null;
        if (seconds < 0) {
            clearInterval(interval);
            setIsActiveTimer(false);
        } else {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isActiveTimer, seconds]);

    return (
        <ModalUpdateBankVerifyComponent
            phoneNumber={phoneNumber}
            seconds={seconds}
            isActiveTimer={isActiveTimer}
            onCancel={onCancel}
            onVerify={onVerify}
            onResend={onResend}
            setOtpText={setOtpText}
            otpText={otpText}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            error={error}
            bankUpdateVerifyDialogBox={bankUpdateVerifyDialogBox}
            setBankUpdateVerifyDialogBox={setBankUpdateVerifyDialogBox}
        ></ModalUpdateBankVerifyComponent>
    );
}
