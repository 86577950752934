import React from 'react';
import { Typography, Container, Button, Grid, Divider, OutlinedInput, useMediaQuery, Tooltip } from '@material-ui/core';
import { Error, CheckCircle, ErrorOutline, WarningRounded } from '@material-ui/icons';
import { Input, Label, UploadDocuments } from 'components/onboarding/onboarding-ui-components';
import style from './update-bank-info.module.scss';
import { CheckMerchantCountries } from 'components/onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import PropTypes from 'prop-types';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import {
    SnackBarAndAlert,
    BankSortCodeDetails,
    BankSortCodeNumberContainer,
    ModalUpdateBankCompletion,
    ModalUpdateBankVerify,
    LoadingScreen
} from 'components';
import NumberFormat from 'react-number-format';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { ResellerType } from 'utils/enums/ResellerType';
import { useGlobalStateContext } from 'contexts';
import ReactCodeInput from 'react-verification-code-input';
import NewZealandAccountNumberContainer from 'components/new-zealand-account-number/new-zealand-account-number.container';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { BankAccountLengthConstaints } from 'utils/enums/BankAccountLength';

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fdecea',
        color: '#611a15',
        fontSize: 14,
        height: '50px',
        maxWidth: '100%',
        padding: '0'
    }
}))(Tooltip);
function UpdateBankInfoComponent(props) {
    const { globalState } = useGlobalStateContext();
    const {
        handleSubmit,
        errorMessage,
        setErrorMessage,
        isLoading,
        confirmExistingSortCode,
        setConfirmExistingSortCode,
        confirmExistingAcountNumber,
        setConfirmExistingAcountNumber,
        confirmExistingIBAN,
        setConfirmExistingIBAN,
        newSortCode,
        setNewSortCode,
        newBsbCode,
        setNewBsbCode,
        newIBAN,
        setNewIBAN,
        newAcountNumber,
        setNewAcountNumber,
        newAcountNumberConfirm,
        setNewAcountNumberConfirm,
        newIBANConfirm,
        setNewIBANConfirm,
        newAcountHolder,
        setNewAcountHolder,
        showUKValidation,
        showIrelandValidation,
        statusValidationBankAccount,
        existingAccNumberInputRef,
        newAccNumberInputRef,
        statusValidationIBAN,
        setStatusValidationIBAN,
        confirmExistingBsbCode,
        setConfirmExistingBsbCode,
        newAustraliaAccountNum,
        setNewAustraliaAccountNum,
        confirmAustraliaAcountNum,
        setConfirmAustraliaAcountNum,
        newAustraliaAcountNumConfirm,
        setNewAustraliaAcountNumConfirm,
        bankUpdateDialogBox,
        setBankUpdateDialogBox,
        bankDetailsUpdateInitiated,
        overRideRequest,
        raiseBankDetailsUpdateRequest,
        newBankDetailsToSave,
        setIsLoading,
        setBankUpdateDetailsInitiated,
        setBankData,
        doAutoFocus,
        newNZAcountNumber,
        setNewNZAcountNumber,
        confirmExistingNZAcountNumber,
        setConfirmExistingNZAcountNumber,
        newNZAcountNumberConfirm,
        setNewNZAcountNumberConfirm,
        showMessage,
        disabled,
        showErrorForAccNo,
        AccNoErrorMessage,
        showErrorForNewAccNo,
        newAccNoErrorMessage,
        checkValue,
        show,
        setShow,
        isBankDetailsNotFound,
        isDisabled,
        phoneNumber,
        sendOtp,
        loader,
        otpError,
        setOtpError,
        confirmExistingRouteNum,
        setConfirmExistingRouteNum,
        setConfirmUnitedStatesAcountNum,
        setNewRouteNum,
        setNewUnitedStatesAccountNum,
        setNewUnitedStatesAcountNumConfirm,
        confirmUnitedStatesAcountNum,
        newRouteNum,
        newUnitedStatesAccountNum,
        newAcountNumConfirm,
        bankUpdateVerifyDialogBox,
        setBankUpdateVerifyDialogBox,
        businessDetails,
        validForm,
        setValidForm,
        setFormattedDocumentsStats,
        setUploadSuccess,
        setBucketDocuments,
        csrfToken,
        bankName,
        bankNameFieldOnChange,
        bankNameFieldOnBlur,
        bankNameFieldOnKeyDown,
        formType
    } = props;
    const datmanMerchant = globalState.reseller?.name === ResellerType.DATMAN;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const mediaLessThan750px = useMediaQuery('(max-width:749px)');
    const mediaLessThan400px = useMediaQuery('(max-width:399px)');
    const Message = show || showMessage;
    return (
        <Container className={style.updateBankInfoContainer}>
            <div className={style.container}>
                <div>
                    {errorMessage && (
                        <SnackBarAndAlert open={errorMessage} onClose={() => setErrorMessage(null)} type="error">
                            {errorMessage ? <h3>{errorMessage}</h3> : ''}
                        </SnackBarAndAlert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column">
                            <div className={style.inputs}>
                                <div className={style.pageTitle}>
                                    <Typography color="primary" className={style.container_title}>
                                        Update Bank Details
                                    </Typography>
                                </div>
                                <div className={style.updateBankDetailsForm}>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                                        <div className={style.bsbexistingContainer}>
                                            <div className={style.itemForm}>
                                                <Label text="Existing bsb code" id="bsb-australia-label" />
                                                <ReactCodeInput
                                                    className={style.bsbInput}
                                                    fieldHeight={mediaLessThan600px ? 30 : 34}
                                                    fieldWidth={mediaLessThan600px ? 30 : 34}
                                                    fields={6}
                                                    type="number"
                                                    pattern="\d*"
                                                    values={confirmExistingBsbCode}
                                                    onChange={(value) => {
                                                        setConfirmExistingBsbCode(value);
                                                        if (value.length === 6) {
                                                            doAutoFocus('existing-aus-account-number-input');
                                                        }
                                                    }}
                                                    required
                                                    autoFocus={true}
                                                />
                                            </div>
                                            <div className={style.itemForm + ' ' + style.containerItem}>
                                                <Label text="Existing account number" id="existing-account-number" />
                                                <ErrorTooltip
                                                    open={showErrorForAccNo}
                                                    arrow
                                                    title={
                                                        <Alert severity="error">
                                                            <ErrorOutlineIcon className={style.error_icon} />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                        </Alert>
                                                    }
                                                >
                                                    <Input
                                                        id="existing-aus-account-number-input"
                                                        className={style.codeInput}
                                                        customInput={OutlinedInput}
                                                        inputProps={{
                                                            maxLength: 9,
                                                            className: style.codeInput__input,
                                                            ref: existingAccNumberInputRef
                                                        }}
                                                        pattern="\d*"
                                                        placeholder="Account number"
                                                        required
                                                        value={confirmAustraliaAcountNum}
                                                        onChange={(e) => {
                                                            setConfirmAustraliaAcountNum(e.target.value);
                                                        }}
                                                    />
                                                </ErrorTooltip>
                                            </div>
                                        </div>
                                        <Divider className={style.divider} />
                                        <div className={style.existingContainer}>
                                            <div>
                                                <div className={style.itemForm}>
                                                    <Label text="New bsb code" id="bsb-australia-label" />
                                                    <ReactCodeInput
                                                        id="new-bsb-code-input"
                                                        className={style.bsbInput}
                                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                                        autoFocus={false}
                                                        fields={6}
                                                        type="number"
                                                        pattern="\d*"
                                                        values={newBsbCode}
                                                        onChange={(value) => {
                                                            setNewBsbCode(value);
                                                            if (value.length === 6) {
                                                                doAutoFocus('account-holder-name');
                                                            }
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div className={style.itemForm}>
                                                    <Label text="Account holder name" id="acct-holder-name"></Label>
                                                    <Input
                                                        id="account-holder-name"
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Account holder(s) name"
                                                        onChange={(e) => setNewAcountHolder(e.target.value)}
                                                        value={newAcountHolder}
                                                        required
                                                    />
                                                </div>
                                                <div className={style.itemForm}>
                                                    <Label text="Name of bank" id="name-of-bank" />
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Name of bank"
                                                        id="name-of-bank-input"
                                                        value={bankName}
                                                        onChange={(e) => {
                                                            bankNameFieldOnChange(e);
                                                        }}
                                                        onBlur={() => {
                                                            bankNameFieldOnBlur();
                                                        }}
                                                        onKeyDown={(e) => {
                                                            bankNameFieldOnKeyDown(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={style.containerItem}>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="New bank account number" id="new-account-number" />
                                                    <ErrorTooltip
                                                        open={showErrorForNewAccNo}
                                                        arrow
                                                        title={
                                                            <Alert severity="error">
                                                                <ErrorOutlineIcon className={style.error_icon} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{newAccNoErrorMessage}
                                                            </Alert>
                                                        }
                                                    >
                                                        <Input
                                                            id="aus-account-number-input"
                                                            className={style.codeInput}
                                                            customInput={OutlinedInput}
                                                            inputProps={{
                                                                maxLength: 9,
                                                                className: style.codeInput__input,
                                                                ref: existingAccNumberInputRef
                                                            }}
                                                            pattern="\d*"
                                                            placeholder="Account number"
                                                            required
                                                            value={newAustraliaAccountNum}
                                                            onChange={(e) => {
                                                                setNewAustraliaAccountNum(e.target.value);
                                                            }}
                                                        />
                                                    </ErrorTooltip>
                                                </div>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label
                                                        text="Confirm new account number"
                                                        id="existing-account-number"
                                                    />
                                                    <Input
                                                        onBlur={checkValue}
                                                        id="confirm-new-aus-account-input"
                                                        className={style.codeInput}
                                                        customInput={OutlinedInput}
                                                        inputProps={{
                                                            maxLength: newAustraliaAccountNum.length,
                                                            className: style.codeInput__input,
                                                            ref: existingAccNumberInputRef
                                                        }}
                                                        pattern="\d*"
                                                        placeholder="Account number"
                                                        required
                                                        value={newAustraliaAcountNumConfirm}
                                                        onChange={(e) => {
                                                            setShow(false);
                                                            setNewAustraliaAcountNumConfirm(e.target.value);
                                                        }}
                                                    />
                                                    {Message && (
                                                        <div className={style.notificationMessageContainer}>
                                                            <WarningRounded
                                                                className={style.error_icon}
                                                                color="error"
                                                            />
                                                            <Typography color="error" style={{ marginLeft: '10px' }}>
                                                                Your account numbers do not match
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </CheckMerchantCountries>

                                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                        {datmanMerchant && (
                                            <React.Fragment>
                                                <div className={style.existingContainer}>
                                                    <div className={style.itemForm}>
                                                        <Label text="Existing sort code" id="existing-sort-code" />
                                                        <BankSortCodeNumberContainer
                                                            values={confirmExistingSortCode}
                                                            onChange={(value) => setConfirmExistingSortCode(value)}
                                                            focusFirst
                                                            nextElementRef={existingAccNumberInputRef}
                                                        />
                                                    </div>
                                                    <div className={style.itemForm + ' ' + style.containerItem}>
                                                        <Label
                                                            text="Existing account number"
                                                            id="existing-account-number"
                                                        />
                                                        <ErrorTooltip
                                                            open={showErrorForAccNo}
                                                            arrow
                                                            title={
                                                                <Alert severity="error">
                                                                    <ErrorOutlineIcon className={style.error_icon} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                                </Alert>
                                                            }
                                                        >
                                                            <div>
                                                                <NumberFormat
                                                                    className={style.codeInput}
                                                                    customInput={OutlinedInput}
                                                                    inputProps={{
                                                                        inputMode: 'numeric',
                                                                        maxLength: 8,
                                                                        className: style.codeInput__input,
                                                                        ref: existingAccNumberInputRef
                                                                    }}
                                                                    pattern="\d*"
                                                                    allowLeadingZeros={true}
                                                                    decimalSeparator={false}
                                                                    allowNegative={false}
                                                                    isNumericString={true}
                                                                    placeholder="Account number"
                                                                    value={confirmExistingAcountNumber}
                                                                    onValueChange={(values) => {
                                                                        const { value } = values;
                                                                        setConfirmExistingAcountNumber(value);
                                                                    }}
                                                                    labelWidth={0}
                                                                    required
                                                                />
                                                            </div>
                                                        </ErrorTooltip>
                                                    </div>
                                                </div>
                                                <Divider className={style.divider} />
                                            </React.Fragment>
                                        )}
                                        <div className={style.existingContainer}>
                                            <div>
                                                <div className={style.itemForm}>
                                                    <Label text="New bank sort code" id="new-sort-code" />
                                                    <BankSortCodeNumberContainer
                                                        values={newSortCode}
                                                        onChange={(value) => setNewSortCode(value)}
                                                        nextElementRef={newAccNumberInputRef}
                                                    />
                                                </div>
                                                <div className={style.itemForm}>
                                                    <Label text="Account holder name" id="acct-holder-name"></Label>
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Account holder(s) name"
                                                        onChange={(e) => setNewAcountHolder(e.target.value)}
                                                        value={newAcountHolder}
                                                    />
                                                </div>

                                                <div className={style.itemForm}>
                                                    <Label text="Name of bank" id="name-of-bank" />
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Name of bank"
                                                        id="name-of-bank-input"
                                                        value={bankName}
                                                        onChange={(e) => {
                                                            bankNameFieldOnChange(e);
                                                        }}
                                                        onBlur={() => {
                                                            bankNameFieldOnBlur();
                                                        }}
                                                        onKeyDown={(e) => {
                                                            bankNameFieldOnKeyDown(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={style.containerItem}>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="New bank account number" id="new-account-number" />
                                                    <ErrorTooltip
                                                        open={showErrorForNewAccNo}
                                                        arrow
                                                        title={
                                                            <Alert severity="error">
                                                                <ErrorOutlineIcon className={style.error_icon} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{newAccNoErrorMessage}
                                                            </Alert>
                                                        }
                                                    >
                                                        <NumberFormat
                                                            className={style.codeInput}
                                                            customInput={OutlinedInput}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                                maxLength: 8,
                                                                className: style.codeInput__input,
                                                                ref: newAccNumberInputRef
                                                            }}
                                                            pattern="\d*"
                                                            allowLeadingZeros={true}
                                                            decimalSeparator={false}
                                                            allowNegative={false}
                                                            isNumericString={true}
                                                            placeholder="Account number"
                                                            value={newAcountNumber}
                                                            onValueChange={(values) => {
                                                                const { value } = values;
                                                                setNewAcountNumber(value);
                                                            }}
                                                            labelWidth={0}
                                                            required
                                                        />
                                                    </ErrorTooltip>
                                                </div>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label
                                                        text="Confirm new account number"
                                                        id="new-account-number-confirm"
                                                    />
                                                    <NumberFormat
                                                        onBlur={checkValue}
                                                        className={style.codeInput}
                                                        customInput={OutlinedInput}
                                                        inputProps={{
                                                            inputMode: 'numeric',
                                                            maxLength: newAcountNumber.length,
                                                            className: style.codeInput__input
                                                        }}
                                                        pattern="\d*"
                                                        allowLeadingZeros={true}
                                                        decimalSeparator={false}
                                                        allowNegative={false}
                                                        isNumericString={true}
                                                        placeholder="Account number"
                                                        value={newAcountNumberConfirm}
                                                        onValueChange={(values) => {
                                                            setShow(false);
                                                            const { value } = values;
                                                            setNewAcountNumberConfirm(value);
                                                        }}
                                                        labelWidth={0}
                                                        required
                                                    />
                                                    {Message && (
                                                        <div className={style.notificationMessageContainer}>
                                                            <WarningRounded
                                                                className={style.error_icon}
                                                                color="error"
                                                            />
                                                            <Typography color="error" style={{ marginLeft: '10px' }}>
                                                                Your account numbers do not match
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    {isBankDetailsNotFound && (
                                                        <div className={style.notificationContainer}>
                                                            <div className={style.iconUnverified}>
                                                                <ErrorOutline className={style.error_icon} />
                                                            </div>
                                                            <Typography color="primary">
                                                                The account number entered is not valid. Please enter
                                                                any valid account number.
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {showUKValidation && (
                                            <div className={style.validationWrapper}>
                                                {statusValidationBankAccount === 1 && (
                                                    <CheckCircle className={style.validIcon} />
                                                )}
                                                {statusValidationBankAccount === 2 && (
                                                    <Error className={style.errorIcon} />
                                                )}
                                            </div>
                                        )}
                                    </CheckMerchantCountries>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                                        {datmanMerchant && (
                                            <React.Fragment>
                                                <div className={style.existingContainer}>
                                                    <div className={style.itemForm + ' ' + style.formLeftSide}>
                                                        <Label text="Existing IBAN" id="existing-IBAN" />
                                                        <ErrorTooltip
                                                            open={showErrorForAccNo}
                                                            arrow
                                                            title={
                                                                <Alert severity="error">
                                                                    <ErrorOutlineIcon className={style.error_icon} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                                </Alert>
                                                            }
                                                        >
                                                            <Input
                                                                inputProps={{ className: style.codeInput }}
                                                                fieldHeight={
                                                                    (!mediaLessThan600px && mediaLessThan750px) ||
                                                                    mediaLessThan400px
                                                                        ? 22
                                                                        : 25
                                                                }
                                                                fieldWidth={
                                                                    (!mediaLessThan600px && mediaLessThan750px) ||
                                                                    mediaLessThan400px
                                                                        ? 22
                                                                        : 25
                                                                }
                                                                autoFocus={false}
                                                                fields={22}
                                                                type="text"
                                                                value={confirmExistingIBAN}
                                                                onChange={(e) => {
                                                                    if (
                                                                        e.target.value.length >= 2 &&
                                                                        e.target.value.length <= 22
                                                                    ) {
                                                                        setStatusValidationIBAN(0);
                                                                        setConfirmExistingIBAN(e.target.value);
                                                                    }
                                                                }}
                                                            />
                                                        </ErrorTooltip>
                                                    </div>
                                                </div>
                                                {statusValidationIBAN === 2 && (
                                                    <div className={style.notificationContainer}>
                                                        <div className={style.iconUnverified}>
                                                            <ErrorOutline className={style.error_icon} />
                                                        </div>
                                                        <Typography color="primary" className={style.errorMessage}>
                                                            The existing IBAN provided does not match our records.
                                                            Please review and enter the correct existing IBAN.
                                                        </Typography>
                                                    </div>
                                                )}
                                                <Divider className={style.divider} />
                                            </React.Fragment>
                                        )}
                                        <div>
                                            <div className={style.existingContainer}>
                                                <div className={style.itemForm + ' ' + style.formLeftSide}>
                                                    <Label text="New IBAN" id="new-IBAN" />
                                                    <ErrorTooltip
                                                        open={showErrorForNewAccNo}
                                                        arrow
                                                        title={
                                                            <Alert severity="error">
                                                                <ErrorOutlineIcon className={style.error_icon} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{newAccNoErrorMessage}
                                                            </Alert>
                                                        }
                                                    >
                                                        <Input
                                                            inputProps={{ className: style.codeInput }}
                                                            fieldHeight={
                                                                (!mediaLessThan600px && mediaLessThan750px) ||
                                                                mediaLessThan400px
                                                                    ? 22
                                                                    : 25
                                                            }
                                                            fieldWidth={
                                                                (!mediaLessThan600px && mediaLessThan750px) ||
                                                                mediaLessThan400px
                                                                    ? 22
                                                                    : 25
                                                            }
                                                            autoFocus={false}
                                                            fields={22}
                                                            type="text"
                                                            value={newIBAN}
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target.value.length >= 2 &&
                                                                    e.target.value.length <= 22
                                                                ) {
                                                                    setStatusValidationIBAN(0);
                                                                    setNewIBAN(e.target.value);
                                                                }
                                                            }}
                                                        />
                                                    </ErrorTooltip>
                                                </div>
                                                <div className={style.itemForm + ' ' + style.formRightSide}>
                                                    <Label text="Confirm new IBAN" id="confirm-new-IBAN" />
                                                    <Input
                                                        onBlur={checkValue}
                                                        inputProps={{
                                                            className: style.codeInput,
                                                            maxLength: newIBAN.length
                                                        }}
                                                        fieldHeight={
                                                            (!mediaLessThan600px && mediaLessThan750px) ||
                                                            mediaLessThan400px
                                                                ? 22
                                                                : 25
                                                        }
                                                        fieldWidth={
                                                            (!mediaLessThan600px && mediaLessThan750px) ||
                                                            mediaLessThan400px
                                                                ? 22
                                                                : 25
                                                        }
                                                        autoFocus={false}
                                                        type="text"
                                                        value={newIBANConfirm}
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.value.length >= 2 &&
                                                                e.target.value.length <= 22
                                                            ) {
                                                                setShow(false);
                                                                setStatusValidationIBAN(0);
                                                                setNewIBANConfirm(e.target.value);
                                                            }
                                                        }}
                                                    />
                                                    {Message && (
                                                        <div className={style.notificationMessageContainer}>
                                                            <WarningRounded
                                                                className={style.error_icon}
                                                                color="error"
                                                            />
                                                            <Typography color="error" style={{ marginLeft: '10px' }}>
                                                                Your account numbers do not match
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={style.existingContainer}>
                                                <div className={style.itemForm + ' ' + style.irelandAccountHolderName}>
                                                    <Label text="Account holder name" id="acct-holder-name"></Label>
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Account holder(s) name"
                                                        onChange={(e) => setNewAcountHolder(e.target.value)}
                                                        value={newAcountHolder}
                                                    />
                                                </div>
                                                <div className={style.itemForm + ' ' + style.formRightSide}>
                                                    <Label text="Name of bank" id="name-of-bank" />
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Name of bank"
                                                        id="name-of-bank-input"
                                                        value={bankName}
                                                        onChange={(e) => {
                                                            bankNameFieldOnChange(e);
                                                        }}
                                                        onBlur={() => {
                                                            bankNameFieldOnBlur();
                                                        }}
                                                        onKeyDown={(e) => {
                                                            bankNameFieldOnKeyDown(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {showIrelandValidation && (
                                            <div className={style.validationWrapper}>
                                                {statusValidationBankAccount === 1 && (
                                                    <CheckCircle className={style.validIcon} />
                                                )}
                                                {statusValidationBankAccount === 2 && (
                                                    <Error className={style.errorIcon} />
                                                )}
                                            </div>
                                        )}
                                        {statusValidationIBAN === 3 && (
                                            <div className={style.notificationContainer}>
                                                <div className={style.iconUnverified}>
                                                    <ErrorOutline className={style.error_icon} />
                                                </div>
                                                <Typography color="primary" className={style.errorMessage}>
                                                    New IBAN's provided don't match. Please review this information.
                                                </Typography>
                                            </div>
                                        )}
                                    </CheckMerchantCountries>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.NEW_ZEALAND]}>
                                        {datmanMerchant && (
                                            <React.Fragment>
                                                <div className={style.existingContainer}>
                                                    <div className={style.itemForm + ' ' + style.containerItem}>
                                                        <Label
                                                            text="Existing account number"
                                                            id="existing-account-number"
                                                        />
                                                        <ErrorTooltip
                                                            open={showErrorForAccNo}
                                                            arrow
                                                            title={
                                                                <Alert severity="error">
                                                                    <ErrorOutlineIcon className={style.error_icon} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                                </Alert>
                                                            }
                                                        >
                                                            <div>
                                                                <NewZealandAccountNumberContainer
                                                                    hideDashes={mediaLessThan600px}
                                                                    value={checkEmptyValue(
                                                                        confirmExistingNZAcountNumber
                                                                    )}
                                                                    onChange={(value) => {
                                                                        setConfirmExistingNZAcountNumber(value);
                                                                    }}
                                                                />
                                                            </div>
                                                        </ErrorTooltip>
                                                    </div>
                                                </div>
                                                <Divider className={style.divider} />
                                            </React.Fragment>
                                        )}
                                        <div className={style.existingContainer}>
                                            <div>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="Account holder name" id="acct-holder-name"></Label>
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Account holder(s) name"
                                                        onChange={(e) => setNewAcountHolder(e.target.value)}
                                                        value={newAcountHolder}
                                                    />
                                                </div>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="Name of bank" id="name-of-bank" />
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Name of bank"
                                                        id="name-of-bank-input"
                                                        value={bankName}
                                                        onChange={(e) => {
                                                            bankNameFieldOnChange(e);
                                                        }}
                                                        onBlur={() => {
                                                            bankNameFieldOnBlur();
                                                        }}
                                                        onKeyDown={(e) => {
                                                            bankNameFieldOnKeyDown(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className={style.containerItem}>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="New bank account number" id="new-account-number" />
                                                    <ErrorTooltip
                                                        open={showErrorForNewAccNo}
                                                        arrow
                                                        title={
                                                            <Alert severity="error">
                                                                <ErrorOutlineIcon className={style.error_icon} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{newAccNoErrorMessage}
                                                            </Alert>
                                                        }
                                                    >
                                                        <div>
                                                            <NewZealandAccountNumberContainer
                                                                hideDashes={mediaLessThan600px}
                                                                value={checkEmptyValue(newNZAcountNumber)}
                                                                onChange={(value) => {
                                                                    setShow(false);
                                                                    setNewNZAcountNumber(value);
                                                                }}
                                                            />
                                                        </div>
                                                    </ErrorTooltip>
                                                </div>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="Confirm new bank account number" />
                                                    <NewZealandAccountNumberContainer
                                                        onBlur={checkValue}
                                                        hideDashes={mediaLessThan600px}
                                                        value={checkEmptyValue(newNZAcountNumberConfirm)}
                                                        onChange={(value) => {
                                                            setShow(false);
                                                            setNewNZAcountNumberConfirm(value);
                                                        }}
                                                    />
                                                    {Message && (
                                                        <div className={style.notificationMessageContainer}>
                                                            <WarningRounded
                                                                className={style.error_icon}
                                                                color="error"
                                                            />
                                                            <Typography color="error" style={{ marginLeft: '10px' }}>
                                                                Your account numbers do not match
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </CheckMerchantCountries>
                                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                        <div className={style.bsbexistingContainer}>
                                            <div className={style.itemForm}>
                                                <Label text="Existing routing number" id="route-usa-label" />
                                                <ReactCodeInput
                                                    className={style.bsbInput}
                                                    fieldHeight={mediaLessThan600px ? 30 : 34}
                                                    fieldWidth={mediaLessThan600px ? 30 : 34}
                                                    fields={9}
                                                    type="number"
                                                    pattern="\d*"
                                                    values={confirmExistingRouteNum}
                                                    onChange={(value) => {
                                                        setConfirmExistingRouteNum(value);
                                                        if (value.length === 9) {
                                                            doAutoFocus('existing-usa-account-number-input');
                                                        }
                                                    }}
                                                    required
                                                    autoFocus={true}
                                                />
                                            </div>
                                            <div className={style.itemForm + ' ' + style.containerItem}>
                                                <Label text="Existing account number" id="existing-account-number" />
                                                <ErrorTooltip
                                                    open={showErrorForAccNo}
                                                    arrow
                                                    title={
                                                        <Alert severity="error">
                                                            <ErrorOutlineIcon className={style.error_icon} />
                                                            {AccNoErrorMessage}
                                                        </Alert>
                                                    }
                                                >
                                                    <Input
                                                        id="existing-usa-account-number-input"
                                                        className={style.codeInput}
                                                        customInput={OutlinedInput}
                                                        inputProps={{
                                                            maxLength: 17,
                                                            className: style.codeInput__input,
                                                            ref: existingAccNumberInputRef
                                                        }}
                                                        pattern="\d*"
                                                        placeholder="Account number"
                                                        required
                                                        value={confirmUnitedStatesAcountNum}
                                                        onChange={(e) => {
                                                            setConfirmUnitedStatesAcountNum(e.target.value);
                                                        }}
                                                    />
                                                </ErrorTooltip>
                                            </div>
                                        </div>
                                        <Divider className={style.divider} />
                                        <div className={style.existingContainer}>
                                            <div>
                                                <div className={style.itemForm}>
                                                    <Label text="New routing number" id="route-usa-label" />
                                                    <ReactCodeInput
                                                        id="new-route-code-input"
                                                        className={style.bsbInput}
                                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                                        autoFocus={false}
                                                        fields={9}
                                                        type="number"
                                                        pattern="\d*"
                                                        values={newRouteNum}
                                                        onChange={(value) => {
                                                            setNewRouteNum(value);
                                                            if (value.length === 9) {
                                                                doAutoFocus('account-holder-name');
                                                            }
                                                        }}
                                                        required
                                                    />
                                                </div>
                                                <div className={style.itemForm}>
                                                    <Label text="Account holder name" id="acct-holder-name"></Label>
                                                    <Input
                                                        id="account-holder-name"
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Account holder(s) name"
                                                        onChange={(e) => setNewAcountHolder(e.target.value)}
                                                        value={newAcountHolder}
                                                        required
                                                    />
                                                </div>
                                                <div className={style.itemForm}>
                                                    <Label text="Name of bank" id="name-of-bank" />
                                                    <Input
                                                        inputProps={{ className: style.holderName }}
                                                        placeholder="Name of bank"
                                                        id="name-of-bank-input"
                                                        value={bankName}
                                                        onChange={(e) => {
                                                            bankNameFieldOnChange(e);
                                                        }}
                                                        onBlur={() => {
                                                            bankNameFieldOnBlur();
                                                        }}
                                                        onKeyDown={(e) => {
                                                            bankNameFieldOnKeyDown(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className={style.containerItem}>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label text="New bank account number" id="new-account-number" />
                                                    <ErrorTooltip
                                                        open={showErrorForNewAccNo}
                                                        arrow
                                                        title={
                                                            <Alert severity="error">
                                                                <ErrorOutlineIcon className={style.error_icon} />
                                                                {newAccNoErrorMessage}
                                                            </Alert>
                                                        }
                                                    >
                                                        <Input
                                                            id="usa-account-number-input"
                                                            className={style.codeInput}
                                                            customInput={OutlinedInput}
                                                            inputProps={{
                                                                maxLength:
                                                                    BankAccountLengthConstaints[
                                                                        MerchantCountries.UNITED_STATES
                                                                    ].maxLength,
                                                                className: style.codeInput__input,
                                                                ref: existingAccNumberInputRef
                                                            }}
                                                            pattern="\d*"
                                                            placeholder="Account number"
                                                            required
                                                            value={newUnitedStatesAccountNum}
                                                            onChange={(e) => {
                                                                setNewUnitedStatesAccountNum(e.target.value);
                                                            }}
                                                        />
                                                    </ErrorTooltip>
                                                </div>
                                                <div className={style.itemForm + ' ' + style.containerItem}>
                                                    <Label
                                                        text="Confirm new account number"
                                                        id="existing-account-number"
                                                    />
                                                    <Input
                                                        onBlur={checkValue}
                                                        id="confirm-new-usa-account-input"
                                                        className={style.codeInput}
                                                        customInput={OutlinedInput}
                                                        inputProps={{
                                                            maxLength: newUnitedStatesAccountNum.length,
                                                            className: style.codeInput__input,
                                                            ref: existingAccNumberInputRef
                                                        }}
                                                        pattern="\d*"
                                                        placeholder="Account number"
                                                        required
                                                        value={newAcountNumConfirm}
                                                        onChange={(e) => {
                                                            setShow(false);
                                                            setNewUnitedStatesAcountNumConfirm(e.target.value);
                                                        }}
                                                    />
                                                    {Message && (
                                                        <div className={style.notificationMessageContainer}>
                                                            <WarningRounded
                                                                className={style.error_icon}
                                                                color="error"
                                                            />
                                                            <Typography color="error" style={{ marginLeft: '10px' }}>
                                                                Your account numbers do not match
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </CheckMerchantCountries>
                                    <ModalUpdateBankCompletion
                                        bankUpdateDialogBox={bankUpdateDialogBox}
                                        setBankUpdateDialogBox={setBankUpdateDialogBox}
                                        bankDetailsUpdateInitiated={bankDetailsUpdateInitiated}
                                        overRideRequest={overRideRequest}
                                        raiseBankDetailsUpdateRequest={raiseBankDetailsUpdateRequest}
                                        newBankDetailsToSave={newBankDetailsToSave}
                                        setLoading={setIsLoading}
                                        setBankUpdateDetailsInitiated={setBankUpdateDetailsInitiated}
                                        setBankData={setBankData}
                                        resellerName={globalState.reseller?.name}
                                        sendOtp={sendOtp}
                                        userType={globalState.user?.UserType?.name}
                                        merchantCountry={globalState.merchant?.country}
                                    />
                                    {bankUpdateVerifyDialogBox && (
                                        <ModalUpdateBankVerify
                                            bankUpdateVerifyDialogBox={bankUpdateVerifyDialogBox}
                                            setBankUpdateVerifyDialogBox={setBankUpdateVerifyDialogBox}
                                            raiseBankDetailsUpdateRequest={raiseBankDetailsUpdateRequest}
                                            newBankDetailsToSave={newBankDetailsToSave}
                                            setLoading={setIsLoading}
                                            setBankData={setBankData}
                                            phoneNumber={phoneNumber}
                                        />
                                    )}
                                    <UploadDocuments
                                        entity={'merchant'}
                                        entityId={globalState.merchant?.id}
                                        flag={DocumentFlag.BANK_VERIFY}
                                        setValidForm={setValidForm}
                                        setFormattedDocumentsStats={setFormattedDocumentsStats}
                                        setUploadSuccess={setUploadSuccess}
                                        uploadBtnName={'Submit'}
                                        businessTypeId={businessDetails.businessTypeId}
                                        source={'BANK_UPDATE'}
                                        setBucketDocuments={setBucketDocuments}
                                        onlyBucketUpload
                                        csrfToken={csrfToken}
                                        formType={formType}
                                    ></UploadDocuments>
                                </div>
                            </div>
                            <div className={style.itemForm + ' ' + style.updateButtonContainer}>
                                <Button
                                    className={style.updateButton}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={disabled || isLoading || isDisabled || !validForm}
                                >
                                    Update bank details
                                </Button>
                            </div>
                        </Grid>
                    </form>
                    {datmanMerchant && <BankSortCodeDetails />}
                </div>
                <SnackBarAndAlert
                    open={!!otpError}
                    type="error"
                    onClose={() => {
                        setOtpError('');
                    }}
                >
                    {otpError}
                </SnackBarAndAlert>
                <LoadingScreen open={loader} />
            </div>
        </Container>
    );
}

UpdateBankInfoComponent.propTypes = {
    handleSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    setErrorMessage: PropTypes.func,
    isLoading: PropTypes.bool,
    confirmExistingSortCode: PropTypes.string,
    setConfirmExistingSortCode: PropTypes.func,
    confirmExistingAcountNumber: PropTypes.string,
    setConfirmExistingAcountNumber: PropTypes.func,
    confirmExistingIBAN: PropTypes.string,
    setConfirmExistingIBAN: PropTypes.func,
    newSortCode: PropTypes.string,
    setNewSortCode: PropTypes.func,
    newBsbCode: PropTypes.string,
    setNewBsbCode: PropTypes.func,
    newIBAN: PropTypes.string,
    setNewIBAN: PropTypes.func,
    newAcountNumber: PropTypes.string,
    setNewAcountNumber: PropTypes.func,
    newAcountNumberConfirm: PropTypes.string,
    setNewAcountNumberConfirm: PropTypes.func,
    newIBANConfirm: PropTypes.string,
    setNewIBANConfirm: PropTypes.func,
    newAcountHolder: PropTypes.string,
    setNewAcountHolder: PropTypes.func,
    statusValidationBankAccount: PropTypes.number,
    showUKValidation: PropTypes.bool,
    showIrelandValidation: PropTypes.bool,
    statusValidationIBAN: PropTypes.number,
    setStatusValidationIBAN: PropTypes.func,
    setConfirmExistingBsbCode: PropTypes.func,
    newAustraliaAccountNum: PropTypes.string,
    setNewAustraliaAccountNum: PropTypes.func,
    confirmAustraliaAcountNum: PropTypes.string,
    setConfirmAustraliaAcountNum: PropTypes.func,
    newAustraliaAcountNumConfirm: PropTypes.string,
    setNewAustraliaAcountNumConfirm: PropTypes.func,
    bankUpdateDialogBox: PropTypes.bool,
    setBankUpdateDialogBox: PropTypes.func,
    phoneNumber: PropTypes.string,
    bankDetailsUpdateInitiated: PropTypes.bool,
    overRideRequest: PropTypes.bool,
    setOverRideRequest: PropTypes.func,
    confirmExistingNZAcountNumber: PropTypes.string,
    setConfirmExistingNZAcountNumber: PropTypes.func,
    newNZAcountNumber: PropTypes.string,
    setNewNZAcountNumber: PropTypes.func,
    newNZAcountNumberConfirm: PropTypes.string,
    setNewNZAcountNumberConfirm: PropTypes.func,
    loader: PropTypes.bool
};

export default UpdateBankInfoComponent;
