import React, { useState, useEffect } from 'react';
import CountyInputComponent from './county-input.component';
import { countyNames } from 'utils/enums/countyNames';

const CountyInput = (props) => {
    const { country } = props;
    const [countyList, setCountyList] = useState([]);

    useEffect(() => {
        const getCountyList = () => {
            let updatedCountyNames = [...countyNames];
            if (country) {
                updatedCountyNames = countyNames.filter((county) => county.country === country);
            }

            const list = updatedCountyNames.map((county) => {
                if (country) {
                    return county.County;
                } else {
                    return county.CountyWithCountryName;
                }
            });
            setCountyList(list);
        };

        getCountyList();
        // eslint-disable-next-line
    }, [country]);

    return <CountyInputComponent {...props} countyList={countyList} />;
};

export default CountyInput;
