import React from 'react';
import PropTypes from 'prop-types';
import styles from './merchant-reseller-list.module.scss';
import _ from 'lodash';
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    ListItem,
    Box,
    TableSortLabel
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useGlobalStateContext } from 'contexts';
import { ProductsRequired } from '../../utils/enums/ProductsRequired';
// import GatewayImg from '../../assets/icons/gateway.svg';
// import MyPayAppImg from '../../assets/icons/my-pay-app.svg';
// import VirtualTerminalImg from '../../assets/icons/virtual-terminal.png';
// import CardMachineImg from '../../assets/icons/card-machine.svg';
import AppImg from '../../assets/icons/App.png';
import VTImg from '../../assets/icons/VT.png';
import GateWayNewImg from '../../assets/icons/Gateway.png';
import PDQImg from '../../assets/icons/PDQ.png';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';
import { SnackBarAndAlert } from 'components';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ModalMerchantTermsAndConditions } from 'components';
import DoneIcon from '@material-ui/icons/Done';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { stableSort, getComparator } from 'utils/helper';

function MerchantListComponent(props) {
    const { setRoute, globalState } = useGlobalStateContext();

    const {
        merchants,
        isLoading,
        setSearchValue,
        onSearchMerchants,
        count,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        merchantCreation,
        setMerchantCreation,
        accountUpdated,
        showMessage,
        setShowMessage,
        filter,
        setFilter,
        needToSignTermsAndCondition,
        setNeedToSignTermsAndCondition,
        saveDraft,
        setSaveDraft,
        onCreateMerchant,
        productFilter,
        setProductFilter,
        isOmnipay,
        statusFilterOptions,
        createSortHandler,
        orderBy,
        order
    } = props;
    return (
        // <Grid ></Grid>
        <div className={styles.container}>
            {accountUpdated && (
                <div className={styles.listItemContainer}>
                    <div className={styles.iconVerified}>
                        <CheckCircleIcon style={{ color: green[500], fontSize: 22 }} />
                    </div>
                    <Typography color="primary">
                        <div>
                            Your 'Update Account Details' request has been submitted. This process can take up to 14
                            days.&nbsp;
                        </div>
                    </Typography>
                </div>
            )}
            <Grid
                xs={12}
                md={12}
                sm={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                className={styles.center}
            >
                <Grid item xs={10} md={10} sm={10}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-end"
                        xs={12}
                        md={12}
                        sm={12}
                    >
                        <Grid item xs={12} md={4} sm={4}>
                            <div className={styles.searchContainer}>
                                <Grid item xs={12}>
                                    <span className={styles.resellerId}>
                                        <Typography color="primary" className={styles.resellerLabel}>
                                            {globalState.canonicalReseller?.companyName}
                                        </Typography>
                                    </span>
                                </Grid>

                                <form onSubmit={onSearchMerchants} className={styles.center}>
                                    <TextField
                                        className={styles.searchMerchants}
                                        id="pending-merchants-search"
                                        size="small"
                                        label="Search"
                                        type="search"
                                        variant="outlined"
                                        autoComplete="off"
                                        autoFocus
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                    <Button
                                        id="pending-merchants-search-button"
                                        className={styles.searchButton}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={onSearchMerchants}
                                    >
                                        Search
                                    </Button>
                                </form>
                            </div>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-end"
                            xs={12}
                            md={6}
                            sm={6}
                            className={styles.filters}
                        >
                            {isOmnipay && (
                                <Grid item xs={12} md={6} sm={6}>
                                    <FormControl variant="outlined" className={styles.formControl}>
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            className={styles.inputlabel}
                                        >
                                            FIlter by product
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="FIlter By Product"
                                            value={productFilter}
                                            onChange={(e) => {
                                                setProductFilter(e.target.value);
                                            }}
                                            className={styles.selectInput}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            <MenuItem value={'Gateway'}>Gateway</MenuItem>
                                            <MenuItem value={'MyMoto App'}>MyMoto App</MenuItem>
                                            <MenuItem value={'Virtual Terminal'}>Virtual Terminal</MenuItem>
                                            <MenuItem value={'PDQ'}>PDQ</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6} sm={6} className={styles.left}>
                                <span>
                                    <FormControl variant="outlined" className={styles.formControl}>
                                        <InputLabel
                                            id="demo-simple-select-outlined-label"
                                            className={styles.inputlabel}
                                        >
                                            Filter by status
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            label="Filter by status"
                                            value={filter}
                                            onChange={(e) => {
                                                setFilter(e.target.value);
                                            }}
                                            className={styles.selectInput}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            {Object.keys(statusFilterOptions).map((status) => (
                                                <MenuItem value={statusFilterOptions[status]}>
                                                    {statusFilterOptions[status]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {isOmnipay && (
                    <Grid item xs={10} md={2} sm={2} className={styles.center}>
                        <Button
                            id="create-new-merchant-button"
                            className={styles.newMerchantbutton}
                            variant="contained"
                            color="primary"
                            disabled={!globalState.canonicalReseller}
                            onClick={onCreateMerchant}
                        >
                            Create merchant
                        </Button>
                    </Grid>
                )}
            </Grid>
            {/* <div className={styles.searchPaper}>

            </div> */}
            {isLoading ? (
                <div className={styles.loading}>
                    <CircularProgress />
                </div>
            ) : (
                //<div className={styles.container}>
                <TableContainer className={styles.TableContainer}>
                    <Table
                        aria-label="simple table"
                        aria-labelledby="tableTitle"
                        size="small"
                        stickyHeader
                        component={Paper}
                    >
                        <TableHead className={styles.header}>
                            <TableRow height={60}>
                                <TableCell sortDirection={orderBy === 'name' ? order : false}>
                                    <span>
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={createSortHandler('name')}
                                            IconComponent={ArrowDropDownIcon}
                                        >
                                            <span>Merchant Name</span>
                                        </TableSortLabel>
                                    </span>
                                </TableCell>
                                <TableCell>Address</TableCell>
                                {isOmnipay && (
                                    <TableCell align="center" width={10}>
                                        <Tooltip title={ProductsRequired.Gateway}>
                                            <img width={30} height={30} alt="" src={GateWayNewImg}></img>
                                        </Tooltip>
                                    </TableCell>
                                )}
                                {isOmnipay && (
                                    <TableCell align="center" width={10}>
                                        <Tooltip title={ProductsRequired.MyMoto_App}>
                                            <img width={30} height={30} alt="" src={AppImg}></img>
                                        </Tooltip>
                                    </TableCell>
                                )}
                                {isOmnipay && (
                                    <TableCell align="center" width={10}>
                                        <Tooltip title={ProductsRequired.Virtual_Terminal}>
                                            <img width={30} height={30} alt="" src={VTImg}></img>
                                        </Tooltip>
                                    </TableCell>
                                )}

                                {isOmnipay && (
                                    <TableCell align="center" width={10}>
                                        <Tooltip title={ProductsRequired.PDQ}>
                                            <img width={30} height={30} alt="" src={PDQImg}></img>
                                        </Tooltip>
                                    </TableCell>
                                )}
                                {!isOmnipay && <TableCell> Customer</TableCell>}
                                <TableCell sortDirection={orderBy === 'status' ? order : false}>
                                    <span>
                                        <TableSortLabel
                                            active={orderBy === 'status'}
                                            direction={orderBy === 'status' ? order : 'asc'}
                                            onClick={createSortHandler('status')}
                                            IconComponent={ArrowDropDownIcon}
                                        >
                                            <span>Status</span>
                                        </TableSortLabel>
                                    </span>
                                </TableCell>
                                {isOmnipay && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        {count > 0 ? (
                            <TableBody>
                                {stableSort(merchants, getComparator(order, orderBy)).map((merchant) => {
                                    let merchantAddress;
                                    if (merchant.address) {
                                        merchantAddress = _.compact([
                                            merchant.address.addressLine1,
                                            merchant.address.addressLine2,
                                            merchant.address.city,
                                            merchant.address.postCode
                                        ]).join(', ');
                                    }
                                    return (
                                        <TableRow key={merchant.id} className={styles.rowData}>
                                            <TableCell className={styles.nameColumn}>
                                                {isOmnipay ? (
                                                    <ListItem
                                                        className={styles.merchantName}
                                                        onClick={() =>
                                                            setRoute('/create-merchant', { merchantId: merchant.id })
                                                        }
                                                    >
                                                        <Box className={styles.nameColumn}>
                                                            <Tooltip
                                                                title={merchant.name}
                                                                arrow
                                                                placement="bottom-start"
                                                            >
                                                                <Typography
                                                                    className={styles.merchantName}
                                                                    component={Link}
                                                                >
                                                                    {merchant.name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    </ListItem>
                                                ) : (
                                                    <Box className={styles.nameColumn}>
                                                        <Tooltip title={merchant.name} placement="bottom-start">
                                                            <Link className={styles.merchantLink}>
                                                                <span>{merchant.name}</span>
                                                            </Link>
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                            </TableCell>

                                            <TableCell className={styles.addressColumn}>
                                                {merchantAddress ? (
                                                    <Tooltip title={merchantAddress} placement="bottom-start">
                                                        <span>{merchantAddress}</span>
                                                    </Tooltip>
                                                ) : (
                                                    <span>&mdash;</span>
                                                )}
                                            </TableCell>
                                            {isOmnipay && (
                                                <TableCell align="center">
                                                    {merchant.merchantProductRequired.includes(
                                                        ProductsRequired.Gateway
                                                    ) && <DoneIcon className={styles.doneIconStyle} />}
                                                </TableCell>
                                            )}
                                            {isOmnipay && (
                                                <TableCell align="center">
                                                    {merchant.merchantProductRequired.includes(
                                                        ProductsRequired.MyMoto_App
                                                    ) && <DoneIcon className={styles.doneIconStyle} />}
                                                </TableCell>
                                            )}
                                            {isOmnipay && (
                                                <TableCell align="center">
                                                    {merchant.merchantProductRequired.includes(
                                                        ProductsRequired.Virtual_Terminal
                                                    ) && <DoneIcon className={styles.doneIconStyle} />}
                                                </TableCell>
                                            )}
                                            {isOmnipay && (
                                                <TableCell align="center">
                                                    {merchant.merchantProductRequired.includes(
                                                        ProductsRequired.PDQ
                                                    ) && <DoneIcon className={styles.doneIconStyle} />}
                                                </TableCell>
                                            )}
                                            {!isOmnipay && (
                                                <TableCell className={styles.idsColumn}>{merchant.id}</TableCell>
                                            )}
                                            <TableCell>{MerchantStatusNames[merchant.status]}</TableCell>

                                            {isOmnipay && (
                                                <TableCell align="center">
                                                    <Button
                                                        className={styles.accessButton}
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => {
                                                            setRoute(`/merchant-details/${merchant.id}`);
                                                        }}
                                                    >
                                                        <span className={styles.accessNameButton}>View</span>
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                                <TableRow>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                <TableRow className={styles.rowData}>
                                    <Tooltip
                                        title={isOmnipay ? 'Click on CREATE NEW MERCHANT to start your journey' : ''}
                                        arrow
                                    >
                                        <TableCell colSpan={8} align="center">
                                            No merchants available to display
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                            </TableBody>
                        )}
                        <TableFooter>
                            {count > 20 && (
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: count }]}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'rows per page' },
                                            native: true
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            )}
                        </TableFooter>
                    </Table>
                </TableContainer>
                //</div>
            )}
            {merchantCreation && (
                <SnackBarAndAlert open={merchantCreation} onClose={() => setMerchantCreation(false)} type="success">
                    Merchant created successfully
                </SnackBarAndAlert>
            )}
            {saveDraft && (
                <SnackBarAndAlert open={saveDraft} onClose={() => setSaveDraft(false)} type="success">
                    Successfully saved draft!
                </SnackBarAndAlert>
            )}

            {showMessage && (
                <SnackBarAndAlert open={showMessage} onClose={() => setShowMessage(false)} type="error">
                    Please sign the Terms and Conditions.
                </SnackBarAndAlert>
            )}
            <ModalMerchantTermsAndConditions
                open={needToSignTermsAndCondition}
                setOpen={setNeedToSignTermsAndCondition}
            />
        </div>
    );
}
MerchantListComponent.propTypes = {
    merchants: PropTypes.array,
    isLoading: PropTypes.bool,
    setSearchValue: PropTypes.string,
    onSearchMerchants: PropTypes.func,
    count: PropTypes.number,
    page: PropTypes.number,
    canonicalReseller: PropTypes.object,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    saveDraft: PropTypes.bool,
    setSaveDraft: PropTypes.func,
    statusFilterOptions: PropTypes.object,
    createSortHandler: PropTypes.func,
    orderBy: PropTypes.string,
    order: PropTypes.string
};
export default MerchantListComponent;
