import React from 'react';
import style from './admin-dashboard-menu.module.scss';
import { List, ListItem, Typography } from '@material-ui/core';
import {
    Dashboard,
    Assignment,
    Payment,
    MailOutline,
    ExitToApp,
    AccountBalance,
    KeyboardReturn,
    CardTravel,
    ListAlt,
    AddAlert,
    WorkOff
} from '@material-ui/icons';

function AdminHomeMenuComponent() {
    return (
        <div className={style.menuContainer}>
            <List component="nav">
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <Dashboard />
                        <Typography color="primary" className={style.menuText} component="span">
                            Dashboard
                        </Typography>
                    </div>
                </ListItem>{' '}
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <Assignment />
                        <Typography color="primary" className={style.menuText} component="span">
                            To do
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button disabled>
                    <div className={style.menuItemContainer}>
                        <span className={style.developerSeparator}>LISTS</span>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <Payment />
                        <Typography color="primary" className={style.menuText} component="span">
                            Bank error list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <Payment />
                        <Typography color="primary" className={style.menuText} component="span">
                            Payment list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <CardTravel />
                        <Typography color="primary" className={style.menuText} component="span">
                            Account verified list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <AccountBalance />
                        <Typography color="primary" className={style.menuText} component="span">
                            Bank verified list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <ListAlt />
                        <Typography color="primary" className={style.menuText} component="span">
                            Balance list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <WorkOff />
                        <Typography color="primary" className={style.menuText} component="span">
                            Dormant account list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <KeyboardReturn />
                        <Typography color="primary" className={style.menuText} component="span">
                            Refunds list
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button disabled>
                    <div className={style.menuItemContainer}>
                        <span className={style.developerSeparator}>COMMUNICATION</span>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <AddAlert />
                        <Typography color="primary" className={style.menuText} component="span">
                            Add announcements
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <MailOutline />
                        <Typography color="primary" className={style.menuText} component="span">
                            Send email
                        </Typography>
                    </div>
                </ListItem>
                <ListItem button>
                    <div className={style.menuItemContainer} id="logout">
                        <ExitToApp />
                        <Typography color="primary" className={style.menuText} component="span">
                            Logout
                        </Typography>
                    </div>
                </ListItem>
            </List>
        </div>
    );
}

AdminHomeMenuComponent.propTypes = {};

export default AdminHomeMenuComponent;
