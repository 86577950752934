import { useGlobalStateContext } from 'contexts';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import styles from './card-details-form.module.scss';

const CardDetailsFormComponent = ({
    cardMonth,
    cardYear,
    cardHolder,
    cardHolderAddress,
    dispatch,
    cardNumberRef,
    cardHolderRef,
    cardMonthRef,
    cardCvvRef,
    cardYearRef,
    onCardInputFocus,
    onCardInputBlur,
    children,
    initiateSale,
    propState,
    cvv,
    cardNumber,
    isButtonDisabled,
    handleFormChange,
    onCardNumberChange,
    onCvvFocus,
    onCvvBlur,
    onInput,
    onKeyDown,
    cardNumberMask
}) => {
    const { globalState } = useGlobalStateContext();
    const { amount, description, email, phoneInput } = propState;

    return (
        <div className="card-form">
            <Row className={styles.cardForm}>
                <Col>
                    <div className="card-list">
                        <h1 style={{ textAlign: 'center' }}>{null}</h1>
                        {children}
                    </div>
                </Col>
                <Col>
                    <div className="card-form__inner">
                        <div className="card-input">
                            <label htmlFor="cardNumber" className="card-input__label">
                                Card number
                            </label>
                            <input
                                type="tel"
                                name="cardNumber"
                                className="card-input__input"
                                autoComplete="off"
                                placeholder="1234 1234 1234 1234"
                                onChange={onCardNumberChange}
                                maxLength="19"
                                ref={cardNumberRef}
                                onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                                onBlur={onCardInputBlur}
                                value={cardNumberMask}
                            />
                        </div>

                        <div className="card-input">
                            <label htmlFor="cardName" className="card-input__label">
                                Card holder name
                            </label>
                            <input
                                type="text"
                                className="card-input__input"
                                autoComplete="off"
                                name="cardHolder"
                                onChange={handleFormChange}
                                placeholder="Name on the card"
                                ref={cardHolderRef}
                                onFocus={(e) => onCardInputFocus(e, 'cardHolder')}
                                onBlur={onCardInputBlur}
                                value={cardHolder}
                            />
                        </div>

                        <div className="card-input">
                            <label htmlFor="cardHolderAddress" className="card-input__label">
                                Address
                            </label>
                            <input
                                type="text"
                                className="card-input__input"
                                autoComplete="off"
                                name="cardHolderAddress"
                                onChange={handleFormChange}
                                placeholder="Enter Customer's address."
                                value={cardHolderAddress}
                            />
                        </div>

                        <div className="card-form__row">
                            <div className="card-form__col">
                                <label htmlFor="cardMonth" className="card-input__label">
                                    Expiry month
                                </label>
                                <input
                                    type="number"
                                    className="card-input__input"
                                    autoComplete="off"
                                    name="cardMonth"
                                    placeholder="MM"
                                    value={cardMonth}
                                    ref={cardMonthRef}
                                    onInput={onInput}
                                    onKeyDown={onKeyDown}
                                    onChange={handleFormChange}
                                    onFocus={(e) => onCardInputFocus(e, 'cardMonth')}
                                    onBlur={onCardInputBlur}
                                />
                            </div>
                            <div className="card-form__col">
                                <label htmlFor="cardYear" className="card-input__label">
                                    Expiry year
                                </label>

                                <input
                                    type="number"
                                    className="card-input__input"
                                    autoComplete="off"
                                    name="cardYear"
                                    placeholder="YY"
                                    value={cardYear}
                                    ref={cardYearRef}
                                    onInput={onInput}
                                    onKeyDown={onKeyDown}
                                    onChange={handleFormChange}
                                    onFocus={(e) => onCardInputFocus(e, 'cardYear')}
                                    onBlur={onCardInputBlur}
                                />
                            </div>
                            <div className="card-form__col -cvv">
                                <div className="card-input">
                                    <label htmlFor="cardCvv" className="card-input__label">
                                        CVV
                                    </label>
                                    <input
                                        type="number"
                                        className="card-input__input"
                                        autoComplete="off"
                                        name="cardCvv"
                                        placeholder="CVV"
                                        value={cvv}
                                        onKeyDown={onKeyDown}
                                        ref={cardCvvRef}
                                        onInput={onInput}
                                        onChange={handleFormChange}
                                        onFocus={onCvvFocus}
                                        onBlur={onCvvBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-form__row">
                            <Button
                                size="lg"
                                variant="primary"
                                className={styles.payAmount}
                                block
                                onClick={() => {
                                    const cardDetails = {
                                        card_number: cardNumber,
                                        card_exp_month: cardMonth,
                                        card_exp_year: cardYear,
                                        card_cvv: cvv,
                                        cardHolderName: cardHolder,
                                        cardHolderAddress
                                    };

                                    dispatch({
                                        type: 'SET_MODAL_STATE',
                                        showModal: true,
                                        showLoadingSpinner: true,
                                        paymentSuccess: null
                                    });
                                    initiateSale(cardDetails, amount, description, email, phoneInput);
                                }}
                                disabled={!isButtonDisabled()}
                            >
                                Pay {getCurrencySymbol(globalState.merchant?.country)}
                                {amount}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CardDetailsFormComponent;
