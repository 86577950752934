import React from 'react';
import LoadingScreenComponent from './loading-screen.component';

/**
 *
 * @param {{
 * open: boolean
 * }} props
 */
function LoadingScreen(props) {
    return <LoadingScreenComponent {...props}></LoadingScreenComponent>;
}

export default LoadingScreen;
