import React from 'react';
import { Typography, Grid, Divider, makeStyles } from '@material-ui/core';
import styles from './page-footer.module.scss';

const useStyles = makeStyles({
    subtitle2: {
        '&.MuiTypography-subtitle2': {
            'font-size': '10px !important'
        }
    }
});

const PageFooterComponent = (props) => {
    const classes = useStyles();
    return (
        <Grid className={styles.footerContainer}>
            <Divider light />
            <Grid container direction="row" className={styles.footerInfoContainer} justify="space-between">
                <Grid direction="row" container item xs={9}>
                    <Typography variant="subtitle2" className={classes.subtitle2}>
                        MyPay
                    </Typography>
                    <Typography variant="subtitle2" className={classes.subtitle2}>
                        &nbsp; Wycliffe HouseWater Ln, Wilmslow SK9 5AF &nbsp; &nbsp; Telephone +44 (0) 303 123 1113 or
                        email: https://ico.org.uk/global/contact-us/email
                    </Typography>
                </Grid>
                <Grid>
                    <Typography variant="subtitle2" className={classes.subtitle2}>
                        Copyright MyPay 2020
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PageFooterComponent;
