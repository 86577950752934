import React from 'react';
import { OutlinedInput } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './new-zealand-account-number.module.scss';
import NumberFormat from 'react-number-format';

function NewZealandAccountNumberComponent(props) {
    const {
        firstInputElementRef,
        secondInputElementRef,
        thirdInputElementRef,
        firstInputChangeHandler,
        secondInputChangeHandler,
        thirdInputChangeHandler,
        firstInputValues,
        secondInputValues,
        thirdInputValues,
        hideDashes,
        disabled,
        onBlur
    } = props;

    return (
        <div className={styles.wrapper}>
            <NumberFormat
                className={
                    disabled
                        ? styles.wrapper__inputs + ' ' + styles.wrapper__inputs__disableInput
                        : styles.wrapper__inputs + ' ' + styles.wrapper__inputs__first
                }
                customInput={OutlinedInput}
                inputProps={{ inputMode: 'numeric', ref: firstInputElementRef, maxLength: 6 }}
                pattern="\d*"
                onBlur={onBlur}
                allowLeadingZeros={true}
                decimalSeparator={false}
                allowNegative={false}
                isNumericString={true}
                placeholder="000000"
                value={firstInputValues}
                onValueChange={firstInputChangeHandler}
                labelWidth={0}
                required
                disabled={disabled ? true : false}
            />
            {!hideDashes && <span className={styles.wrapper__inputs__dash}>-</span>}
            <NumberFormat
                className={
                    disabled
                        ? styles.wrapper__inputs + ' ' + styles.wrapper__inputs__disableInput
                        : styles.wrapper__inputs + ' ' + styles.wrapper__inputs__second
                }
                customInput={OutlinedInput}
                inputProps={{ inputMode: 'numeric', ref: secondInputElementRef, maxLength: 7 }}
                pattern="\d*"
                onBlur={onBlur}
                allowLeadingZeros={true}
                decimalSeparator={false}
                allowNegative={false}
                isNumericString={true}
                placeholder="0000000"
                value={secondInputValues}
                onValueChange={secondInputChangeHandler}
                labelWidth={0}
                required
                disabled={disabled ? true : false}
            />
            {!hideDashes && <span className={styles.wrapper__inputs__dash}>-</span>}
            <NumberFormat
                className={
                    disabled
                        ? styles.wrapper__inputs + ' ' + styles.wrapper__inputs__disableInput
                        : styles.wrapper__inputs + ' ' + styles.wrapper__inputs__third
                }
                customInput={OutlinedInput}
                inputProps={{ inputMode: 'numeric', ref: thirdInputElementRef, maxLength: 3 }}
                pattern="\d*"
                onBlur={onBlur}
                allowLeadingZeros={true}
                decimalSeparator={false}
                allowNegative={false}
                isNumericString={true}
                placeholder="000"
                value={thirdInputValues}
                onValueChange={thirdInputChangeHandler}
                labelWidth={0}
                required
                disabled={disabled ? true : false}
            />
        </div>
    );
}

NewZealandAccountNumberComponent.propTypes = {
    firstInputChangeHandler: PropTypes.func,
    secondInputChangeHandler: PropTypes.func,
    thirdInputChangeHandler: PropTypes.func,
    firstInputValues: PropTypes.string,
    secondInputValues: PropTypes.string,
    thirdInputValues: PropTypes.string,
    hideDashes: PropTypes.bool,
    onBlur: PropTypes.func
};

export default NewZealandAccountNumberComponent;
