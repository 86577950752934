import React from 'react';
import PropTypes from 'prop-types';
import {
    Input,
    Label,
    OnboardingTitle,
    FormItem,
    NextButton,
    OnboardingFormsContainer,
    PostCodeSelector
} from 'components/onboarding/onboarding-ui-components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import {
    Grid,
    TextField,
    Button,
    CircularProgress,
    withStyles,
    Tooltip,
    makeStyles,
    NativeSelect,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AntSwitch } from 'components';

import style from './onboarding-name-adress.module.scss';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { Alert } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useGlobalStateContext } from 'contexts';

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fdecea',
        color: '#611a15',
        fontSize: 14,
        height: '50px'
    }
}))(Tooltip);

const useStyles = makeStyles(() => ({
    noOptions: {
        color: 'red !important'
    }
}));

function OnboardingNameAdressComponent(props) {
    const classes = useStyles();

    const { globalState } = useGlobalStateContext();

    const {
        nextStep,
        onboardingForm,
        setOnboardingForm,
        isDisabled,
        onSubmit,
        selectAddressData,
        handleSelectedCompany,
        getCompanyDetails,
        value,
        setValue,
        inputValue,
        setInputValue,
        isLoading,
        openDropdown,
        setOpenDropdown,
        showErrorForRegisteredNumber,
        registeredNumberErrorMessage,
        isSoleTrader,
        setIsSoleTrader,
        setRegisteredNumberHandler,
        businessTypes,
        setIsBankAccountNameSwitchOn,
        setBankAccountNameVal,
        setRegBusinessNameVal,
        legalNameVal,
        setRegBusinessName,
        setLegalNameVal
    } = props;

    const title = <OnboardingTitle title="Name and address"></OnboardingTitle>;
    const isUKMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const registeredBusiness = onboardingForm.businessDetails.isRegisteredBusiness;
    const isSameAccountName = onboardingForm.businessDetails.isAccountNameSame;

    function formBody() {
        return (
            <React.Fragment>
                <FormItem>
                    <Label
                        text="Legal entity/Business type"
                        id="business-type-label"
                        languageSwitchEnabled={true}
                    ></Label>
                    <NativeSelect
                        input={
                            <Input
                                placeholder="Legal Entity/Business Type"
                                id="business-type"
                                value={onboardingForm.businessDetails.businessTypeId}
                                onChange={(e) => {
                                    setIsSoleTrader(e.target.value?.toString() === '2');
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            businessTypeId: e.target.value
                                        }
                                    });
                                }}
                            ></Input>
                        }
                    >
                        <option key={'--Please select--'} value={'--Please select--'}>
                            --Please select--
                        </option>
                        {businessTypes.map((type) => {
                            return (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            );
                        })}
                    </NativeSelect>
                </FormItem>

                {isSoleTrader && (
                    <React.Fragment>
                        <Grid container xs={12} justify="space-between" className={style.formItemGrid}>
                            <Grid item xs={12} sm={6}>
                                <Label>Do you have a registered Business name ?</Label>
                            </Grid>
                            <Grid item>
                                <div className={style.yesNoSwitch}>
                                    <Typography color="primary" component="span" className={style.checkBoxText}>
                                        No
                                    </Typography>
                                    <AntSwitch
                                        id="is-business-making-products"
                                        checked={onboardingForm.businessDetails.isRegisteredBusiness}
                                        onChange={(e) => {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                businessDetails: {
                                                    ...onboardingForm.businessDetails,
                                                    isRegisteredBusiness: e.target.checked
                                                }
                                            });
                                            setRegBusinessName(e.target.checked);
                                        }}
                                    ></AntSwitch>
                                    <Typography color="primary" component="span" className={style.checkBoxText}>
                                        Yes
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        {registeredBusiness && (
                            <FormItem>
                                <Label text="Please provide a registered Business name"></Label>
                                <Input
                                    placeholder="Business name"
                                    id="register-business-name"
                                    value={onboardingForm.businessDetails.tradingName}
                                    onChange={(e) => {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            businessDetails: {
                                                ...onboardingForm.businessDetails,
                                                tradingName: e.target.value,
                                                isAccountNameSame: false
                                            }
                                        });
                                        setBankAccountNameVal('');
                                        setRegBusinessNameVal(e.target.value);
                                        setIsBankAccountNameSwitchOn(false);
                                    }}
                                ></Input>
                            </FormItem>
                        )}

                        {!registeredBusiness && (
                            <Grid item xs={12} className={style.formItemGrid}>
                                <FormItem>
                                    <Grid container xs={12} justify="space-between" className={style.formItemGrid}>
                                        <Grid item xs={12} sm={6}>
                                            <Label>
                                                Please confirm you trade under your name and your name appears on your
                                                Bank ?
                                            </Label>
                                        </Grid>
                                        <Grid item>
                                            <div className={style.yesNoSwitch}>
                                                <Typography
                                                    color="primary"
                                                    component="span"
                                                    className={style.checkBoxText}
                                                >
                                                    No
                                                </Typography>
                                                <AntSwitch
                                                    id="is-business-making-products"
                                                    checked={onboardingForm.businessDetails.isAccountNameSame}
                                                    onChange={(e) => {
                                                        setOnboardingForm({
                                                            ...onboardingForm,
                                                            businessDetails: {
                                                                ...onboardingForm.businessDetails,
                                                                isAccountNameSame: e.target.checked
                                                            }
                                                        });
                                                        setRegBusinessNameVal('');
                                                        setIsBankAccountNameSwitchOn(e.target.checked);
                                                    }}
                                                ></AntSwitch>
                                                <Typography
                                                    color="primary"
                                                    component="span"
                                                    className={style.checkBoxText}
                                                >
                                                    Yes
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </FormItem>
                                {!isSameAccountName && (
                                    <FormItem>
                                        <Label text="Enter the name that appears on your bank account"></Label>
                                        <Input
                                            placeholder="Bank Account Name"
                                            value={onboardingForm.tradingAddress.accountHolderName}
                                            onChange={(e) => {
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    tradingAddress: {
                                                        ...onboardingForm.tradingAddress,
                                                        accountHolderName: e.target.value
                                                    },
                                                    businessDetails: {
                                                        ...onboardingForm.businessDetails,
                                                        isAccountNameSame: false
                                                    }
                                                });
                                                setRegBusinessNameVal('');
                                                setBankAccountNameVal(e.target.value);
                                                setIsBankAccountNameSwitchOn(false);
                                            }}
                                        ></Input>
                                    </FormItem>
                                )}
                            </Grid>
                        )}
                    </React.Fragment>
                )}

                <div>
                    {!isSoleTrader && (
                        <React.Fragment>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                <FormItem>
                                    <Label text="Company look-up" id="name-address-label-company-lookup-name"></Label>
                                    <Grid container className={style.companyContainer}>
                                        <Grid xs={12} sm={8}>
                                            <Autocomplete
                                                noOptionsText={'Company not found'}
                                                id="name-address-input-company-lookup-name"
                                                size="small"
                                                open={openDropdown}
                                                options={onboardingForm.nameAndAddress.companies || []}
                                                getOptionLabel={(option) =>
                                                    `${option.title ?? ''}
                                        ${option.company_number ?? ''}
                                        ${option.address_snippet ?? ''}`
                                                }
                                                classes={{ noOptions: classes.noOptions }}
                                                className={`${style.withoutBorders} ${style.customInput}`}
                                                value={value}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                    handleSelectedCompany(newValue);
                                                    setOpenDropdown(false);
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                    setOnboardingForm({
                                                        ...onboardingForm,
                                                        nameAndAddress: {
                                                            ...onboardingForm.nameAndAddress,
                                                            companyName: newInputValue
                                                        }
                                                    });
                                                }}
                                                renderOption={(option) => {
                                                    return (
                                                        <div>
                                                            <strong>{option.title ?? ''}</strong>
                                                            <br />
                                                            {`${option.company_number ?? ''}
                                                                    ${option.address_snippet ?? ''}`}
                                                        </div>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className={style.inputLookupCompany}
                                                        placeholder="Company number/name"
                                                        variant="outlined"
                                                    />
                                                )}
                                                onBlur={getCompanyDetails}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={4}>
                                            <React.Fragment>
                                                <Button
                                                    id="lookup-company-button"
                                                    className={style.lookupCompanyButton}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={getCompanyDetails}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress color="white" size={20} />
                                                    ) : (
                                                        <span className={style.textButton}>
                                                            Company
                                                            <br /> Look-up
                                                        </span>
                                                    )}
                                                </Button>
                                            </React.Fragment>
                                        </Grid>
                                    </Grid>
                                </FormItem>
                            </CheckMerchantCountries>
                            <FormItem>
                                <Label text="Registered number" id="registered-number-label"></Label>
                                <ErrorTooltip
                                    open={showErrorForRegisteredNumber}
                                    arrow
                                    title={
                                        <Alert severity="error">
                                            <ErrorOutlineIcon />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{registeredNumberErrorMessage}
                                        </Alert>
                                    }
                                >
                                    <Input
                                        placeholder="Registered number"
                                        id="registered-number"
                                        title="Title"
                                        inputProps={{
                                            maxLength: isUKMerchant ? 12 : ''
                                        }}
                                        maxLength="4"
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.registeredNumber)}
                                        onChange={setRegisteredNumberHandler}
                                    ></Input>
                                </ErrorTooltip>
                            </FormItem>
                        </React.Fragment>
                    )}

                    {!isSoleTrader && (
                        <FormItem>
                            <Label text="Legal name" id="name-address-label-legal-name"></Label>
                            <Input
                                placeholder="Legal name"
                                id="name-address-input-legal-name"
                                value={checkEmptyValue(legalNameVal)}
                                onChange={(e) => {
                                    setLegalNameVal(e.target.value);
                                }}
                            ></Input>
                        </FormItem>
                    )}

                    {!isSoleTrader && (
                        <React.Fragment>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                <FormItem>
                                    <Label text="Post code" id="name-address-label-post-code-selector"></Label>
                                    <PostCodeSelector
                                        postCodeValue={checkEmptyValue(onboardingForm.nameAndAddress.postCode)}
                                        onPostCodeChange={(postCode) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    postCode: postCode
                                                }
                                            })
                                        }
                                        onAddressObjectChange={(addressData) => selectAddressData(addressData)}
                                    />
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries
                                excludedCountries={[MerchantCountries.UNITED_KINGDOM, MerchantCountries.UNITED_STATES]}
                            >
                                <FormItem>
                                    <Label text="Post code" id="name-address-label-post-code"></Label>
                                    <Input
                                        placeholder="Post code"
                                        id="name-address-input-post-code"
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.postCode)}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    postCode: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                <FormItem>
                                    <Label text="ZIP code" id="name-address-label-zip-code"></Label>
                                    <Input
                                        placeholder="ZIP code"
                                        id="zip-code"
                                        value={checkEmptyValue(onboardingForm.nameAndAddress.postCode)}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                nameAndAddress: {
                                                    ...onboardingForm.nameAndAddress,
                                                    postCode: e.target.value
                                                }
                                            })
                                        }
                                    ></Input>
                                </FormItem>
                            </CheckMerchantCountries>
                            <FormItem>
                                <Label text="Address line 1" id="name-address-label-address-code-1"></Label>
                                <Input
                                    placeholder="Address line 1"
                                    id="name-address-input-address-code-1"
                                    value={checkEmptyValue(onboardingForm.nameAndAddress.addressLine1)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            nameAndAddress: {
                                                ...onboardingForm.nameAndAddress,
                                                addressLine1: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                            <FormItem>
                                <Label text="Address line 2" id="name-address-label-address-code-2"></Label>
                                <Input
                                    placeholder="Address line 2"
                                    id="name-address-input-address-code-2"
                                    value={checkEmptyValue(onboardingForm.nameAndAddress.addressLine2)}
                                    onChange={(e) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            nameAndAddress: {
                                                ...onboardingForm.nameAndAddress,
                                                addressLine2: e.target.value
                                            }
                                        })
                                    }
                                ></Input>
                            </FormItem>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

OnboardingNameAdressComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    selectAddressData: PropTypes.func,
    handleSelectedCompany: PropTypes.func,
    getCompanyDetails: PropTypes.func,
    value: PropTypes.string,
    setValue: PropTypes.func,
    inputValue: PropTypes.string,
    setInputValue: PropTypes.func,
    isLoading: PropTypes.bool,
    openDropdown: PropTypes.bool,
    setOpenDropdown: PropTypes.func,
    setLegalNameVal: PropTypes.func,
    showErrorForRegisteredNumber: PropTypes.bool,
    registeredNumberErrorMessage: PropTypes.string,
    isSoleTrader: PropTypes.bool,
    setIsSoleTrader: PropTypes.func,
    setRegisteredNumberHandler: PropTypes.func,
    businessTypes: PropTypes.array,
    setRegBusinessName: PropTypes.func,
    isBankAccountNameSwitchOn: PropTypes.bool,
    setIsBankAccountNameSwitchOn: PropTypes.func,
    bankAccountName: PropTypes.bool,
    setBankAccountName: PropTypes.func,
    bankAccountNameVal: PropTypes.string,
    setBankAccountNameVal: PropTypes.func
};

export default OnboardingNameAdressComponent;
