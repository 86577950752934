import React from 'react';
import styles from './login-form.module.scss';
import { Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getArrayFromString, checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { SnackBarAndAlert } from 'components';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import LinkMaterial from '@material-ui/core/Link';
import { useGlobalStateContext } from 'contexts';
import PasswordInput from 'components/password-input/password-input.container';

// import { ResellerType } from 'utils/enums/ResellerType';

function LoginFormComponent(props) {
    const { getRoute } = useGlobalStateContext();
    const {
        email,
        setEmail,
        password,
        setPassword,
        onSubmit,
        error,
        errorCode,
        setError,
        errorCodeMsg,
        setErrorCodeMsg,
        loading,
        isСonfirmationEmailShows,
        isEmailConfirmedSuccessfully,
        setСonfirmationEmailShows,
        verificationCode,
        setVerificationCode,
        isPasswordlessLogin,
        setIsPasswordlessLogin,
        passwordLessEmail,
        setPasswordLessEmail,
        passwordLessPhone,
        setPasswordLessPhone,
        loginWithEmail,
        verifyCode,
        loginWithPhone,
        needToVerifyCode,
        codeSentToPhone,
        resend,
        remainingRetryNumber,
        resetPasswordLessFields,
        showSmsSent,
        setShowSmsSent,
        cloakedPhoneNumber,
        isUpdatePasswordMessageShows,
        setIsUpdatePasswordMessageShows,
        querySubmitted,
        setQuerySubmitted,
        generalPageError,
        setGeneralPageError,
        setOrangeMessageStyle,
        orangeMessageStyle,
        isDisabled,
        setCountryCodeByNumber,
        countryCodeByNumber
    } = props;
    return (
        <div>
            <div className={styles.alert_message}>
                {isUpdatePasswordMessageShows && (
                    <SnackBarAndAlert
                        open={isUpdatePasswordMessageShows}
                        onClose={() => setIsUpdatePasswordMessageShows(false)}
                        type="success"
                    >
                        Password was changed successfully.
                    </SnackBarAndAlert>
                )}
                {isСonfirmationEmailShows &&
                    (isEmailConfirmedSuccessfully ? (
                        <SnackBarAndAlert
                            open={isСonfirmationEmailShows && isEmailConfirmedSuccessfully}
                            onClose={() => setСonfirmationEmailShows(false)}
                            type="success"
                        >
                            The email was successfully confirmed.
                        </SnackBarAndAlert>
                    ) : (
                        <SnackBarAndAlert
                            open={isСonfirmationEmailShows && !isEmailConfirmedSuccessfully}
                            onClose={() => setСonfirmationEmailShows(false)}
                            type="error"
                        >
                            {error
                                ? error
                                : 'An error was occurred when checking the email. Please contact support or try again.'}
                        </SnackBarAndAlert>
                    ))}
                {showSmsSent && (
                    <SnackBarAndAlert open={showSmsSent} onClose={() => setShowSmsSent(false)} type="success">
                        SMS sent to phone number set during sign up process.
                    </SnackBarAndAlert>
                )}
                {querySubmitted && (
                    <SnackBarAndAlert open={querySubmitted} onClose={() => setQuerySubmitted(false)} type="success">
                        Your query has been sent to our support team. We will get back to you shortly !
                    </SnackBarAndAlert>
                )}
                {generalPageError && (
                    <SnackBarAndAlert open={generalPageError} onClose={() => setGeneralPageError('')} type="error">
                        {generalPageError}
                    </SnackBarAndAlert>
                )}
            </div>

            <div className={styles.loginForm}>
                <Form onSubmit={onSubmit} autoComplete="off">
                    {isPasswordlessLogin && !needToVerifyCode && (
                        <>
                            <Box>
                                <Typography color="primary">
                                    <h2 className={styles.headerInfo}>Passwordless authentication</h2>
                                </Typography>
                            </Box>
                            <br />

                            <Form.Label>Phone number</Form.Label>
                            <div className={styles.passwordLessForm}>
                                <div className={styles.passwordLessInput}>
                                    <PhoneInput
                                        country={'gb'}
                                        value={passwordLessPhone}
                                        placeholder={'Phone'}
                                        alwaysDefaultMask={true}
                                        onChange={(phone, data) => {
                                            setPasswordLessPhone(phone);
                                            setCountryCodeByNumber(data.countryCode);
                                        }}
                                        inputClass={styles.phoneInput + ' passwordless-auth-phone-input'}
                                        name="phone"
                                    />
                                </div>

                                <div>
                                    <Button
                                        id="SendCodeToPhone"
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className={styles.passwordLessButton}
                                        onClick={loginWithPhone}
                                        disabled={isDisabled}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                            <br />

                            <Form.Label>Email address</Form.Label>
                            <div className={styles.passwordLessForm}>
                                <div className={styles.passwordLessInput}>
                                    <Form.Group controlId="loginFormEmailPaswordless">
                                        <Form.Control
                                            value={passwordLessEmail}
                                            autoFocus
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) => setPasswordLessEmail(e.target.value.toLowerCase())}
                                        />
                                    </Form.Group>
                                </div>
                                <div>
                                    <Button
                                        id="SendCodeToEmail"
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        className={styles.passwordLessButton}
                                        onClick={loginWithEmail}
                                        disabled={isDisabled}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>

                            <div className={styles.passwordLessForm}>
                                <div>
                                    <Typography color="primary">
                                        <p>
                                            <LinkMaterial
                                                component="button"
                                                onClick={() => setIsPasswordlessLogin(false)}
                                                underline="always"
                                            >
                                                SIGN IN
                                            </LinkMaterial>{' '}
                                            using email and password
                                        </p>
                                    </Typography>
                                </div>
                                {/* <div className={styles.verifyCodeBtn}>
                                    <Button
                                        id="SwitchForgotPassword"
                                        type="button"
                                        onClick={() => setRoute('/forgot-password')}
                                    >
                                        FORGOT PASSWORD
                                    </Button>
                                </div> */}
                            </div>
                        </>
                    )}
                    {isPasswordlessLogin && needToVerifyCode && (
                        <>
                            <Box>
                                <Typography color="primary">
                                    <p>
                                        <span className={styles.headerInfo}>
                                            If your account exists, A verification code will be sent to your&nbsp;
                                            {codeSentToPhone && (
                                                <span>
                                                    mobile number + {cloakedPhoneNumber}. Please check your phone, if
                                                    you don’t have this number anymore please contact support at
                                                    {countryCodeByNumber == 'us'
                                                        ? ' +1(256)2578511.'
                                                        : ' +44(0)3330165548.'}
                                                    &nbsp;
                                                </span>
                                            )}
                                            {!codeSentToPhone && <span> email address {passwordLessEmail}. </span>}
                                            Code is valid for 5 minutes and you have {remainingRetryNumber} retries.
                                        </span>
                                    </p>
                                </Typography>
                            </Box>
                            <Form.Group controlId="verificationCode">
                                <Form.Label>Verification code</Form.Label>
                                <Form.Control
                                    value={checkEmptyValue(verificationCode)}
                                    type="number"
                                    isInvalid={errorCodeMsg || errorCode}
                                    required
                                    autoFocus
                                    placeholder="Enter verification code"
                                    onChange={(e) => {
                                        if (e.target?.value?.length > 6) {
                                            return;
                                        }
                                        const verificationCodeRegEx = /^[0-9\b]+$/;
                                        if (e.target.value === '' || verificationCodeRegEx.test(e.target.value)) {
                                            setVerificationCode(e.target.value);
                                            if (errorCodeMsg && e.target?.value?.length === 6) {
                                                setErrorCodeMsg(false);
                                            }
                                        }
                                    }}
                                    onBlur={() => {
                                        setErrorCodeMsg(
                                            getArrayFromString(verificationCode).length > 0 &&
                                                getArrayFromString(verificationCode).length < 6
                                                ? true
                                                : false
                                        );
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errorCodeMsg ? 'Please Enter Valid 6 digit code' : ''}
                                </Form.Control.Feedback>
                                <br />
                                <div className={styles.passwordLessForm}>
                                    <div className={styles.loginFormButton}>
                                        <Button
                                            id="confirmVeirifcationCode"
                                            type="button"
                                            // color="secondary"
                                            variant="contained"
                                            onClick={resend}
                                        >
                                            RESEND
                                        </Button>
                                    </div>
                                    <div className={styles.verifyCodeBtn}>
                                        <Button
                                            id="confirmVeirifcationCode"
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            className={styles.verifyBtn}
                                            onClick={verifyCode}
                                        >
                                            Verify code
                                        </Button>
                                    </div>
                                </div>
                            </Form.Group>
                            <Box>
                                <Typography color="primary">
                                    <p>
                                        <LinkMaterial component="button" onClick={resetPasswordLessFields}>
                                            Go back
                                        </LinkMaterial>
                                    </p>
                                </Typography>
                            </Box>
                        </>
                    )}
                    {!isPasswordlessLogin && (
                        <>
                            <Form.Group controlId="loginFormEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    required
                                    value={email}
                                    type="email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                />
                            </Form.Group>

                            <Form.Group controlId="loginFormPassword">
                                <Grid container direction="row" justify="space-between">
                                    <Form.Label>Password </Form.Label>
                                    <div className={styles.loginFormButton}>
                                        <Link id="SwitchToPasswordLess" type="button" to={getRoute(`/forgot-password`)}>
                                            Reset password?
                                        </Link>
                                    </div>
                                </Grid>
                                <PasswordInput
                                    required
                                    password={password}
                                    placeholder="Password"
                                    onChange={(value) => setPassword(value)}
                                />
                            </Form.Group>

                            <div className={styles.loginFormButton}>
                                <Button
                                    id="loginFormSubmit"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isDisabled}
                                    className={styles.loginButton}
                                >
                                    Log in
                                </Button>
                            </div>
                            <div className={styles.loginFormButton}>
                                <p className={styles.orSpan}>
                                    <span>or</span>
                                </p>
                                <Button
                                    id="PasswordlessAuthentication"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setIsPasswordlessLogin(true)}
                                >
                                    Passwordless authentication
                                </Button>
                            </div>
                        </>
                    )}

                    {error && !loading && (
                        <SnackBarAndAlert
                            open={error && !loading}
                            onClose={() => {
                                setError(null);
                                setOrangeMessageStyle(false);
                            }}
                            orangeColor={orangeMessageStyle}
                            type="error"
                        >
                            {errorCode === 'PasswordResetRequiredException' ? (
                                <span>
                                    Your password has expired.{' '}
                                    <Link className={styles.alertResetPassword} to={getRoute(`/forgot-password`)}>
                                        RESET YOUR PASSWORD
                                    </Link>
                                </span>
                            ) : (
                                error
                            )}
                        </SnackBarAndAlert>
                    )}

                    {loading && (
                        <div className={styles.loading}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                </Form>
                {/* {globalState.reseller?.name === ResellerType.OMNIPAY && (
                    <div>
                        <AuthWithSocialMedia />
                    </div>
                )} */}
                {/* {globalState.reseller?.name === ResellerType.OMNIPAY && (
                    <React.Fragment>
                        <Form.Group className={styles.linkToSignUp}>
                            <Form.Label>
                                Don&apos;t have an account?{' '}
                                <Link
                                    to={
                                        globalState.customDomain === false
                                            ? `/${globalState.reseller?.portalURL}/signup`
                                            : '/signup'
                                    }
                                >
                                    Sign up
                                </Link>
                            </Form.Label>
                        </Form.Group>
                    </React.Fragment>
                )} */}
            </div>
        </div>
    );
}

LoginFormComponent.propTypes = {
    email: PropTypes.string,
    setEmail: PropTypes.func,
    password: PropTypes.string,
    setPassword: PropTypes.func,
    onSubmit: PropTypes.func,
    error: PropTypes.string,
    errorCode: PropTypes.string,
    setError: PropTypes.func,
    errorCodeMsg: PropTypes.bool,
    setErrorCodeMsg: PropTypes.func,
    loading: PropTypes.bool,
    isСonfirmationEmailShows: PropTypes.bool,
    isEmailConfirmedSuccessfully: PropTypes.bool,
    setСonfirmationEmailShows: PropTypes.func,
    verificationCode: PropTypes.string,
    setVerificationCode: PropTypes.func,
    isPasswordlessLogin: PropTypes.bool,
    setIsPasswordlessLogin: PropTypes.func,
    passwordLessEmail: PropTypes.string,
    setPasswordLessEmail: PropTypes.func,
    passwordLessPhone: PropTypes.string,
    setPasswordLessPhone: PropTypes.func,
    loginWithEmail: PropTypes.func,
    verifyCode: PropTypes.func,
    loginWithPhone: PropTypes.func,
    needToVerifyCode: PropTypes.bool,
    codeSentToPhone: PropTypes.bool,
    resend: PropTypes.func,
    remainingRetryNumber: PropTypes.number,
    resetPasswordLessFields: PropTypes.func,
    showSmsSent: PropTypes.bool,
    setShowSmsSent: PropTypes.func,
    cloakedPhoneNumber: PropTypes.string,
    isUpdatePasswordMessageShows: PropTypes.bool,
    setIsUpdatePasswordMessageShows: PropTypes.func,
    querySubmitted: PropTypes.bool,
    setQuerySubmitted: PropTypes.func
};

export default LoginFormComponent;
