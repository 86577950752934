import React, { useState, useEffect } from 'react';
import ProductsRequiredComponent from './products-required.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';

function ProductsRequired(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const checkValues = onboardingForm.selectedProducts.length > 0;
        setIsDisabled(!checkValues);
    }, [onboardingForm.selectedProducts]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                productsRequired: isDisabled ? false : true
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    const onSubmit = () => {
        const data = {
            productsRequiredIds: onboardingForm.selectedProducts,
            resellerId: globalState.reseller.id
        };
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 7, data);
    };

    return (
        <ProductsRequiredComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
        />
    );
}

export default ProductsRequired;
