import React from 'react';
import AdminHomeMenuComponent from './admin-dashboard-menu.component';

function AdminHomeMenu() {
    return <AdminHomeMenuComponent></AdminHomeMenuComponent>;
}

AdminHomeMenu.propTypes = {};

export default AdminHomeMenu;
