import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    UploadDocuments
} from 'components/onboarding/onboarding-ui-components';
import { DocumentFlag } from 'utils/enums/DocumentFlag';
import { PendingDocsList } from 'components';
import { LoadingScreen } from 'components';
function OnboardingDocumentsComponent(props) {
    const {
        onSubmit,
        isDisabled,
        merchantId,
        setValidForm,
        setUploadSuccess,
        businessTypeId,
        formattedDocumentsStats,
        setFormattedDocumentsStats,
        csrfToken,
        formType,
        isLoading,
        setCSRFToken,
        cardNumber,
        setCardNumber,
        setIdNumber,
        idNumber
    } = props;

    function formBody() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <UploadDocuments
                        entity={'merchant'}
                        entityId={merchantId}
                        flag={DocumentFlag.ONBOARDING}
                        setValidForm={setValidForm}
                        setUploadSuccess={setUploadSuccess}
                        uploadBtnName={'Upload'}
                        businessTypeId={businessTypeId}
                        setFormattedDocumentsStats={setFormattedDocumentsStats}
                        csrfToken={csrfToken}
                        formType={formType}
                        setCSRFToken={setCSRFToken}
                        setIdNumber={setIdNumber}
                        idNumber={idNumber}
                        cardNumber={cardNumber}
                        setCardNumber={setCardNumber}
                    ></UploadDocuments>
                </Grid>
                <LoadingScreen open={isLoading} />
            </React.Fragment>
        );
    }

    const title = (
        <OnboardingTitle
            title="Merchant verification"
            subTitle={
                <PendingDocsList
                    formattedDocumentsStats={formattedDocumentsStats}
                    businessTypeId={businessTypeId}
                ></PendingDocsList>
            }
            transalterEnabled={true}
        ></OnboardingTitle>
    );

    const nextBtn = <NextButton disabled={isDisabled} onSubmit={onSubmit}></NextButton>;

    return <OnboardingFormsContainer title={title} nextButton={nextBtn} parentProps={props} content={formBody} />;
}

OnboardingDocumentsComponent.propTypes = {
    onSubmit: PropTypes.func,
    isDisabled: PropTypes.bool,
    merchantId: PropTypes.string,
    setValidForm: PropTypes.func,
    setUploadSuccess: PropTypes.func
};

export default OnboardingDocumentsComponent;
