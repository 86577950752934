import React from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    makeStyles,
    Paper,
    Box
} from '@material-ui/core';
import { Input, Label, FormItem, PostCodeSelector } from 'components/onboarding/onboarding-ui-components';
import styles from './update-account-reseller-merchant.module.scss';
import { LoadingScreen, ValidURLInput, CountyInput } from 'components';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
function UpdateAccountResellerMerchantComponent(props) {
    const {
        disabled,
        businessDetails,
        ownerDetails,
        setBusinessDetails,
        setOwnerDetails,
        onSubmit,
        selectAddressData,
        showModal,
        modalMessage,
        isLoading,
        phoneNumberValidator,
        setShowModal,
        setPhoneCountryDialCode
    } = props;

    const useStyles = makeStyles((theme) => ({
        disabledInput: {
            color: theme.palette.text.primary,
            backgroundColor: 'Gainsboro',
            border: '1px solid LightGray',
            borderRadius: '5px',
            fontWeight: '600',
            userSelect: 'none'
        }
    }));

    const classes = useStyles();

    return (
        <Paper className={styles.updateAccountMypayContainer}>
            <Box className={styles.pageTitle}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Update Account Details
                </Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <div className={styles.formContainer}>
                        <FormItem>
                            <Label text="Company Name" id="company-name" bold></Label>
                            <Input
                                placeholder="Company name"
                                inputProps={{ maxLength: 150 }}
                                id="business-name-input"
                                value={businessDetails.legalName}
                                onChange={(e) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        legalName: e.target.value
                                    });
                                }}
                                disabled
                                classes={{ disabled: classes.disabledInput }}
                            />
                        </FormItem>
                        <div className={styles.formSection}>
                            <Label text="Business Address" bold></Label>
                            <FormItem>
                                <Label text="Post Code" id="business-post-code"></Label>
                                <PostCodeSelector
                                    postCodeValue={checkEmptyValue(businessDetails.postCode)}
                                    onPostCodeChange={(postCode) => {
                                        setBusinessDetails({
                                            ...businessDetails,
                                            postCode: postCode
                                        });
                                    }}
                                    onAddressObjectChange={(addresData) =>
                                        selectAddressData(addresData, setBusinessDetails, businessDetails)
                                    }
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Address Line 1" id="address-line-1"></Label>
                                <Input
                                    placeholder="Address"
                                    id="business-address-1-input"
                                    value={businessDetails.addressLine1}
                                    onChange={(e) => {
                                        setBusinessDetails({
                                            ...businessDetails,
                                            addressLine1: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Address Line 2" id="address-line-2"></Label>
                                <Input
                                    placeholder="Address"
                                    id="business-address-2-input"
                                    value={businessDetails.addressLine2}
                                    onChange={(e) => {
                                        setBusinessDetails({
                                            ...businessDetails,
                                            addressLine2: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <Label text="Town/City" id="city"></Label>
                                <Input
                                    placeholder="City"
                                    id="city-input"
                                    value={businessDetails.city}
                                    onChange={(e) => {
                                        setBusinessDetails({
                                            ...businessDetails,
                                            city: e.target.value
                                        });
                                    }}
                                />
                            </FormItem>
                            <CountyInput
                                labelText="County/Region"
                                labelId="county-region"
                                id="county-input"
                                inputProps={{ maxLength: 150 }}
                                value={businessDetails.county}
                                loading={isLoading}
                                onChange={(value) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        county: value
                                    });
                                }}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={styles.formContainer}>
                        <FormItem>
                            <Label text="Contact Name" id="contact-name" bold></Label>
                            <Input
                                placeholder="Name"
                                id="Contact-name-input"
                                value={ownerDetails.fullName}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        fullName: e.target.value
                                    });
                                }}
                                disabled
                                classes={{ disabled: classes.disabledInput }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Contact Email" id="owner-email" bold></Label>
                            <Input
                                placeholder="Email"
                                id="owner-email-input"
                                value={ownerDetails.email}
                                disabled
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        email: e.target.value.toLowerCase()
                                    });
                                }}
                            />
                        </FormItem>

                        <FormItem>
                            <Label text="Telephone Number" id="contact-phone-number"></Label>
                            <PhoneInput
                                country={'gb'}
                                id="contact-phone-number-input"
                                placeholder="Phone number"
                                value={checkEmptyValue(businessDetails.telephoneNumber)}
                                alwaysDefaultMask={true}
                                countryCodeEditable={false}
                                onChange={(phone, data) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        telephoneNumber: phoneNumberValidator(phone)
                                    });
                                    setPhoneCountryDialCode(data.dialCode);
                                }}
                                inputClass={styles.phoneInput}
                                name="phone"
                            />
                        </FormItem>
                        <FormItem>
                            <ValidURLInput
                                labelText="Business Website"
                                labelId="website-url"
                                id="website-url-input"
                                value={businessDetails.websiteUrl}
                                onChange={(value) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        websiteUrl: value
                                    });
                                }}
                            />
                        </FormItem>
                    </div>
                </Grid>
            </Grid>
            <div className={styles.btnContainer}>
                <Button
                    disabled={disabled}
                    id="update-btn"
                    color="primary"
                    variant="contained"
                    className={styles.updateBtn}
                    onClick={onSubmit}
                >
                    Update
                </Button>
                <Dialog open={showModal}>
                    <DialogContent>{modalMessage}</DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowModal(false)}
                            id="cancelButtonCreateMerchant"
                            autoFocus
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <LoadingScreen open={isLoading}></LoadingScreen>
            </div>
        </Paper>
    );
}
UpdateAccountResellerMerchantComponent.propTypes = {
    phoneNumberValidator: PropTypes.func
};

export default UpdateAccountResellerMerchantComponent;
