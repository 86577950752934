export const OnboardingStepsMapping = {
    'Name and address': 'nameAndAddress',
    'Bank details': 'bankDetails',
    'Business details': 'businessDetails',
    'Trading address': 'tradingAddress',
    'Owner details': 'ownerDetails',
    'Business profile': 'businessProfile',
    'Transaction profile': 'transactionProfile',
    'Products required': 'productsRequired',
    'Upload documents': 'uploadDocuments',
    Summary: 'summary'
};

//Onboarding steps mapping with label
export const OnboardingSteps = {
    NAME_AND_ADDRESS: 'Name and address',
    NAME_AND_BUSINESS_ADDRESS: 'Name and business address',
    BANK_DETAILS: 'Bank details',
    BUSINESS_DETAILS: 'Business details',
    TRADING_ADDRESS: 'Trading address',
    BUSINESS_ADDRESS: 'Business address',
    OWNER_DETAILS: 'Owner details',
    BUSINESS_PROFILE: 'Business profile',
    TRANSACTION_PROFILE: 'Transaction profile',
    PRODUCTS_REQUIRED: 'Products required',
    UPLOAD_DOCUMENTS: 'Upload documents',
    SUMMARY: 'Summary'
};
