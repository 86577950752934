import React from 'react';
import PropTypes from 'prop-types';
import styles from './finish-upload-button.module.scss';
import { useGlobalStateContext } from 'contexts';
import { Button } from '@material-ui/core';

const FinishUploadButtonComponent = (props) => {
    const { disabled } = props;

    const { setRoute } = useGlobalStateContext();
    return (
        <div className={styles.btnWrapper}>
            <Button
                disabled={disabled}
                color="primary"
                variant="contained"
                className={styles.btnWrapper__finishBtn}
                onClick={() => {
                    setRoute('/settings', { redirected: true });
                }}
            >
                Finish
            </Button>
        </div>
    );
};

FinishUploadButtonComponent.propTypes = {
    disabled: PropTypes.bool
};

export default FinishUploadButtonComponent;
