import React from 'react';
import PropTypes from 'prop-types';
import AddMerchnatIdComponent from './add-merchant-id.component';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';
import { AdminService } from 'services';

export default function AddMerchnatId(props) {
    const { adminFunctions } = useAdminFunctionsContext();
    const { globalState } = useGlobalStateContext();
    const { indexId } = props;

    const onChange = () => {
        AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            externalMerchant: {
                index: indexId,
                externalMerchantId: adminFunctions.merchantIds[indexId],
                externalMerchantStoreId: null
            },
            resellerId: globalState.reseller?.id
        });
    };

    return <AddMerchnatIdComponent {...props} onChange={onChange} />;
}

AddMerchnatId.propTypes = {
    indexId: PropTypes.number
};
