import React from 'react';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';
import './card-image.styles.scss';
import chip from '../../assets/images/card-chip/chip.png';
import cardBackground from '../../assets/images/card-background/17.jpeg';
import visaLogo from '../../assets/images/card-type/visa.png';
import amexLogo from '../../assets/images/card-type/amex.png';
import discoverLogo from '../../assets/images/card-type/discover.png';
import mastercardLogo from '../../assets/images/card-type/mastercard.png';
import unionpayLogo from '../../assets/images/card-type/unionpay.png';
import troyLogo from '../../assets/images/card-type/troy.png';

const cardLogoBgImage = {
    visa: visaLogo,
    amex: amexLogo,
    mastercard: mastercardLogo,
    unionpay: unionpayLogo,
    troy: troyLogo,
    discover: discoverLogo
};

const CardImageComponent = ({
    cardHolder,
    cardNumber,
    cardMonth,
    cardYear,
    cardCvv,
    isCardFlipped,
    currentFocusedElm,
    onCardElementClick,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    style,
    useCardType,
    maskCardNumber
}) => {
    return (
        <div className={`card-item ${isCardFlipped ? '-active' : ''}`}>
            <div className="card-item__side -front">
                <div className={`card-item__focus ${currentFocusedElm ? '-active' : ''}`} style={style} />
                <div className="card-item__cover">
                    <img alt="" src={cardBackground} className="card-item__bg" />
                </div>

                <div className="card-item__wrapper">
                    <div className="card-item__top">
                        <img src={chip} alt="" className="card-item__chip" />
                        <div className="card-item__type">
                            <img alt={useCardType} src={cardLogoBgImage[useCardType]} className="card-item__typeImg" />
                        </div>
                    </div>

                    <label
                        className="card-item__number"
                        ref={cardNumberRef}
                        onClick={() => onCardElementClick('cardNumber')}
                    >
                        <TransitionGroup className="slide-fade-up" component="div">
                            {cardNumber ? (
                                maskCardNumber(cardNumber).map((val, index) => (
                                    <CSSTransition classNames="slide-fade-up" timeout={250} key={index}>
                                        <div className="card-item__numberItem">{val}</div>
                                    </CSSTransition>
                                ))
                            ) : (
                                <CSSTransition classNames="slide-fade-up" timeout={250}>
                                    <div className="card-item__numberItem">#</div>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </label>
                    <div className="card-item__content">
                        <label
                            className="card-item__info"
                            onClick={() => onCardElementClick('cardHolder')}
                            ref={cardHolderRef}
                        >
                            <div className="card-item__holder">Card Holder</div>
                            <div className="card-item__name">
                                <TransitionGroup component="div" className="slide-fade-up">
                                    {cardHolder === 'FULL NAME' ? (
                                        <CSSTransition classNames="slide-fade-up" timeout={250}>
                                            <div>FULL NAME</div>
                                        </CSSTransition>
                                    ) : (
                                        cardHolder.split('').map((val, index) => (
                                            <CSSTransition timeout={250} classNames="slide-fade-right" key={index}>
                                                <span className="card-item__nameItem">{val}</span>
                                            </CSSTransition>
                                        ))
                                    )}
                                    <span>&nbsp;</span>
                                </TransitionGroup>
                            </div>
                        </label>
                        <div
                            className="card-item__date"
                            onClick={() => onCardElementClick('cardDate')}
                            ref={cardDateRef}
                            style={{ marginTop: '-0.5rem', paddingLeft: '0.5rem' }}
                        >
                            <label style={{ margin: 0 }} className="card-item__dateTitle">
                                Expires
                            </label>
                            <label className="card-item__dateItem">
                                <SwitchTransition in-out>
                                    <CSSTransition classNames="slide-fade-up" timeout={200} key={cardMonth}>
                                        <span>{!cardMonth ? 'MM' : cardMonth} </span>
                                    </CSSTransition>
                                </SwitchTransition>
                            </label>
                            /
                            <label htmlFor="cardYear" className="card-item__dateItem">
                                <SwitchTransition out-in>
                                    <CSSTransition classNames="slide-fade-up" timeout={250} key={cardYear}>
                                        <span>{!cardYear ? 'YY' : cardYear.toString().substr(-2)}</span>
                                    </CSSTransition>
                                </SwitchTransition>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-item__side -back">
                <div className="card-item__cover">
                    <img alt="" src={cardBackground} className="card-item__bg" />
                </div>
                <div className="card-item__band" />
                <div className="card-item__cvv">
                    <div className="card-item__cvvTitle">CVV</div>
                    <div className="card-item__cvvBand">
                        <TransitionGroup>
                            {cardCvv.split('').map((val, index) => (
                                <CSSTransition classNames="zoom-in-out" key={index} timeout={250}>
                                    <span>*</span>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </div>
                    <div className="card-item__type">
                        <img alt="card-type" src={cardLogoBgImage[useCardType]} className="card-item__typeImg" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardImageComponent;
