import { useGlobalStateContext } from 'contexts';
import React, { useState } from 'react';
import { NonResellerMerchantService } from 'services';
import ModalCloseMerchantAccountComponent from './modal-close-merchant-account.component';

function ModalCloseMerchantAccount(props) {
    const { globalState, setGlobalState } = useGlobalStateContext();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setOpen, setOpenConfirmation } = props;

    const onCloseMerchant = async () => {
        setIsError(false);
        setIsLoading(true);
        const data = {
            resellerId: globalState.reseller?.id
        };
        const response = await NonResellerMerchantService.requestCloseMerchantAccount(globalState.merchant?.id, data);
        if (response.isSuccesfully) {
            setOpen(false);
            setIsLoading(false);
            setGlobalState((globalState) => ({
                ...globalState,
                shouldLoadMerchants: !globalState.shouldLoadMerchants,
                shouldLoadThisMerchant: globalState.merchant?.id
            }));
            setOpenConfirmation(true);
        } else {
            setIsLoading(false);
            setIsError(true);
        }
    };

    return (
        <ModalCloseMerchantAccountComponent
            {...props}
            onCloseMerchant={onCloseMerchant}
            isError={isError}
            isLoading={isLoading}
        />
    );
}

export default ModalCloseMerchantAccount;
