import React from 'react';
import PropTypes from 'prop-types';
import styles from './onboarding-transaction-profile-step-two.module.scss';
import { Label, NextButton, OnboardingFormsContainer } from 'components/onboarding/onboarding-ui-components';
import { Grid, InputAdornment, OutlinedInput } from '@material-ui/core';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import NumberFormat from 'react-number-format';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';

function OnboardingTransactionProfileComponentStepTwo(props) {
    const { globalState } = useGlobalStateContext();
    const { title, nextStep, onboardingForm, setOnboardingForm, isDisabled, onSubmit } = props;
    function formBody() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} className={styles.subTitle}>
                    <Label>
                        <span style={{ fontWeight: 'bold' }}>Total Annual company Turnover</span>
                    </Label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Label text="Actual" id="actual-comapny-turnover"></Label>
                    <NumberFormat
                        className={styles.numberFormatInput}
                        customInput={OutlinedInput}
                        inputProps={{ inputMode: 'numeric' }}
                        pattern="\d*"
                        allowLeadingZeros={true}
                        allowNegative={false}
                        isNumericString={true}
                        placeholder={getCurrencySymbol(globalState.merchant?.country)}
                        value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.companyTurnOverActual)}
                        id="companyTurnOverActual"
                        onValueChange={(values) => {
                            const { value } = values;
                            setOnboardingForm({
                                ...onboardingForm,
                                transactionProfile: {
                                    ...onboardingForm.transactionProfile,
                                    stepTwo: {
                                        ...onboardingForm.transactionProfile.stepTwo,
                                        companyTurnOverActual: value
                                    }
                                }
                            });
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Label text="Projected" id="projected-comapny-turnover"></Label>
                    <NumberFormat
                        className={styles.numberFormatInput}
                        customInput={OutlinedInput}
                        inputProps={{ inputMode: 'numeric' }}
                        pattern="\d*"
                        allowLeadingZeros={true}
                        allowNegative={false}
                        isNumericString={true}
                        placeholder={getCurrencySymbol(globalState.merchant?.country)}
                        value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.companyTurnOverProjected)}
                        id="companyTurnOverProjected"
                        onValueChange={(values) => {
                            const { value } = values;
                            setOnboardingForm({
                                ...onboardingForm,
                                transactionProfile: {
                                    ...onboardingForm.transactionProfile,
                                    stepTwo: {
                                        ...onboardingForm.transactionProfile.stepTwo,
                                        companyTurnOverProjected: value
                                    }
                                }
                            });
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                        required
                    />
                </Grid>
                <Grid item xs={12} className={styles.subTitle}>
                    <Label>
                        <span style={{ fontWeight: 'bold' }}>Total Annual card Turnover</span>
                    </Label>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Label text="Actual" id="actual-comapny-turnover"></Label>
                    <NumberFormat
                        className={styles.numberFormatInput}
                        customInput={OutlinedInput}
                        inputProps={{ inputMode: 'numeric' }}
                        pattern="\d*"
                        allowLeadingZeros={true}
                        allowNegative={false}
                        isNumericString={true}
                        placeholder={getCurrencySymbol(globalState.merchant?.country)}
                        value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.cardTurnOverActual)}
                        id="cardTurnOverActual"
                        onValueChange={(values) => {
                            const { value } = values;
                            setOnboardingForm({
                                ...onboardingForm,
                                transactionProfile: {
                                    ...onboardingForm.transactionProfile,
                                    stepTwo: {
                                        ...onboardingForm.transactionProfile.stepTwo,
                                        cardTurnOverActual: value
                                    }
                                }
                            });
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Label text="Projected" id="projected-comapny-turnover"></Label>
                    <NumberFormat
                        className={styles.numberFormatInput}
                        customInput={OutlinedInput}
                        inputProps={{ inputMode: 'numeric' }}
                        pattern="\d*"
                        allowLeadingZeros={true}
                        allowNegative={false}
                        isNumericString={true}
                        placeholder={getCurrencySymbol(globalState.merchant?.country)}
                        value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.cardTurnOverProjected)}
                        id="cardTurnOverProjected"
                        onValueChange={(values) => {
                            const { value } = values;
                            setOnboardingForm({
                                ...onboardingForm,
                                transactionProfile: {
                                    ...onboardingForm.transactionProfile,
                                    stepTwo: {
                                        ...onboardingForm.transactionProfile.stepTwo,
                                        cardTurnOverProjected: value
                                    }
                                }
                            });
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        labelWidth={0}
                        required
                    />
                </Grid>
                <Grid item xs={12} className={styles.subTitle}>
                    <Label>
                        <span style={{ fontWeight: 'bold' }}>Product/Services price range</span>
                    </Label>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={styles.buttonGroupDirection}>
                        <NumberFormat
                            className={styles.numberFormatInput}
                            customInput={OutlinedInput}
                            inputProps={{ inputMode: 'numeric' }}
                            pattern="\d*"
                            allowLeadingZeros={true}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={getCurrencySymbol(globalState.merchant?.country)}
                            value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.priceRangeMin)}
                            endAdornment={<InputAdornment position="end">Min</InputAdornment>}
                            id="priceRangeMin"
                            onValueChange={(values) => {
                                const { value } = values;
                                setOnboardingForm({
                                    ...onboardingForm,
                                    transactionProfile: {
                                        ...onboardingForm.transactionProfile,
                                        stepTwo: {
                                            ...onboardingForm.transactionProfile.stepTwo,
                                            priceRangeMin: value
                                        }
                                    }
                                });
                            }}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                            required
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={styles.buttonGroupDirection}>
                        <NumberFormat
                            className={styles.numberFormatInput}
                            customInput={OutlinedInput}
                            inputProps={{ inputMode: 'numeric' }}
                            pattern="\d*"
                            allowLeadingZeros={true}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={getCurrencySymbol(globalState.merchant?.country)}
                            value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.priceRangeMax)}
                            endAdornment={<InputAdornment position="end">Max</InputAdornment>}
                            id="priceRangeMax"
                            onValueChange={(values) => {
                                const { value } = values;
                                setOnboardingForm({
                                    ...onboardingForm,
                                    transactionProfile: {
                                        ...onboardingForm.transactionProfile,
                                        stepTwo: {
                                            ...onboardingForm.transactionProfile.stepTwo,
                                            priceRangeMax: value
                                        }
                                    }
                                });
                            }}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                            required
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div className={styles.buttonGroupDirection}>
                        <NumberFormat
                            className={styles.numberFormatInput}
                            customInput={OutlinedInput}
                            inputProps={{ inputMode: 'numeric' }}
                            pattern="\d*"
                            allowLeadingZeros={true}
                            allowNegative={false}
                            isNumericString={true}
                            placeholder={getCurrencySymbol(globalState.merchant?.country)}
                            value={checkEmptyValue(onboardingForm.transactionProfile.stepTwo.priceRangeAvg)}
                            endAdornment={<InputAdornment position="end">Avg</InputAdornment>}
                            id="priceRangeAvg"
                            onValueChange={(values) => {
                                const { value } = values;
                                setOnboardingForm({
                                    ...onboardingForm,
                                    transactionProfile: {
                                        ...onboardingForm.transactionProfile,
                                        stepTwo: {
                                            ...onboardingForm.transactionProfile.stepTwo,
                                            priceRangeAvg: value
                                        }
                                    }
                                });
                            }}
                            aria-describedby="outlined-weight-helper-text"
                            labelWidth={0}
                            required
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

OnboardingTransactionProfileComponentStepTwo.propTypes = {
    title: PropTypes.element,
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func
};

export default OnboardingTransactionProfileComponentStepTwo;
