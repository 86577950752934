import React, { useState } from 'react';
import PaymentConfigurationModesComponent from './payment-configuration-modes.component';

export default function PaymentConfigurationModes() {
    const [testModeUpdate, setTestModeUpdate] = useState(false);
    const [threeDSecureUpdate, setThreeDSecureUpdate] = useState(false);
    const [testModeUpdateDialogBox, setTestModeUpdateDialogBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [testModeStatus, setTestModeStatus] = useState(false);
    const [threeDSecureStatus, setThreeDSecureStatus] = useState(false);

    return (
        <PaymentConfigurationModesComponent
            testModeUpdate={testModeUpdate}
            setTestModeUpdate={setTestModeUpdate}
            threeDSecureUpdate={threeDSecureUpdate}
            setThreeDSecureUpdate={setThreeDSecureUpdate}
            setTestModeUpdateDialogBox={setTestModeUpdateDialogBox}
            testModeUpdateDialogBox={testModeUpdateDialogBox}
            loading={loading}
            setLoading={setLoading}
            setTestModeStatus={setTestModeStatus}
            testModeStatus={testModeStatus}
            threeDSecureStatus={threeDSecureStatus}
            setThreeDSecureStatus={setThreeDSecureStatus}
        />
    );
}
