import React, { useState, useEffect } from 'react';
import VirtualTerminalComponent from './virtual-terminal.component';
import { useGlobalStateContext } from 'contexts';
import { cleverTapClick } from 'utils/helper';
import { VirtualTerminalPayments } from 'utils/enums/PaymentMode';

function VirtualTerminal(props) {
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const [showPayByLinkModal, setShowPayByLinkModal] = useState(false);
    const [showPayByInvoiceModal, setShowPayByInvoiceModal] = useState(false);
    const [paymentMode, setPaymentMode] = useState(VirtualTerminalPayments['customer_card']);
    const [showReceipt, setShowReceipt] = useState(false);
    const [email, setEmail] = useState('');
    const [phoneInput, setPhoneInput] = useState('');
    const [isVat, setIsVat] = useState(false);
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const { merchantAcquirer, merchant } = globalState;
    const isDna = merchant.isDna;
    const handleAmountChange = (e) => {
        const { value } = e;
        setAmount(value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const isMaxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 9999999.99;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };

    const isButtonDisabled = (amount) => {
        return !(!!amount && parseFloat(amount) > 0);
    };
    const handleClick = (mode) => {
        const data = {
            amount: amount,
            description: description,
            mode: mode,
            time_stamp: new Date()
        };
        cleverTapClick('Payment_initiate_click', globalState, data);
    };
    useEffect(() => {
        document.getElementById('amount_input').focus();
        const acquirer = merchant.isDna ? 'DNA' : null;
        setGlobalState({ ...globalState, merchantAcquirer: { acquirer, updatedAt: new Date() } });
        setShowReceipt(merchant.isReceiptEnabled && isDna);
        setIsVat(!!merchant.vatNumber);
        // eslint-disable-next-line
    }, [merchant]);

    const handlePayment = (mode) => {
        if (mode === VirtualTerminalPayments.customer_card) {
            handleClick('Pay By Card');
            setRoute(`/card-details`, {
                amount,
                description,
                merchantAcquirer,
                email,
                phoneInput
            });
        }
        if (mode === VirtualTerminalPayments.email) {
            handleClick('Pay By Link');
            setShowPayByLinkModal(true);
        }
        if (mode === VirtualTerminalPayments.invoice) {
            handleClick('Pay By Invoice');
            setShowPayByInvoiceModal(true);
        }
    };
    useEffect(() => {
        if (!showReceipt) {
            setEmail('');
        }
    }, [showReceipt]);

    return (
        <VirtualTerminalComponent
            {...props}
            amount={amount}
            setAmount={setAmount}
            description={description}
            setDescription={setDescription}
            showPayByLinkModal={showPayByLinkModal}
            setShowPayByLinkModal={setShowPayByLinkModal}
            handleAmountChange={handleAmountChange}
            handleDescriptionChange={handleDescriptionChange}
            isButtonDisabled={isButtonDisabled}
            isMaxAmount={isMaxAmount}
            merchantAcquirer={merchantAcquirer}
            showReceipt={showReceipt}
            setShowReceipt={setShowReceipt}
            email={email}
            setEmail={setEmail}
            phoneInput={phoneInput}
            setPhoneInput={setPhoneInput}
            isDna={isDna}
            handleClick={handleClick}
            setShowPayByInvoiceModal={setShowPayByInvoiceModal}
            showPayByInvoiceModal={showPayByInvoiceModal}
            paymentMode={paymentMode}
            setPaymentMode={setPaymentMode}
            handlePayment={handlePayment}
            isVat={isVat}
            isInvoiceEnabled={globalState?.merchant?.isInvoiceEnabled}
        />
    );
}

export default VirtualTerminal;
