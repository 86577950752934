import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Label, Input } from '../../../onboarding/onboarding-ui-components';
import styles from './third-party-merchant-id-and-store-id.module.scss';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SnackBarAndAlert } from 'components';

export default function ThirdPartyMerchantIdAndStoreIdComponent(props) {
    const {
        merchantIdAndMerchantStoreId,
        handleCopyMerchantAndStoreId,
        closeCopyAlert,
        isCopyAlertOpen,
        withLabel,
        title
    } = props;

    return (
        <React.Fragment>
            <Grid container spacing={2} className={!withLabel && styles.idContainer}>
                {withLabel ? (
                    <React.Fragment>
                        <Grid item xs={4}>
                            <Label>StoreId / MerchantId</Label>
                        </Grid>
                        <Grid item xs={5} sm={5} md={4}>
                            <Input inputProps={{ className: styles.textInput }} value={merchantIdAndMerchantStoreId} />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            sm={3}
                            md={2}
                            container
                            alignItems="center"
                            className={styles.copyIconContainer}
                        >
                            <FileCopyIcon className={styles.copyIcon} onClick={handleCopyMerchantAndStoreId} />
                        </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Grid item xs={9}>
                            <Input inputProps={{ className: styles.textInput }} value={merchantIdAndMerchantStoreId} />
                        </Grid>
                        <Grid item xs={3} container alignItems="center" className={styles.copyIconContainer}>
                            <FileCopyIcon className={styles.copyIcon} onClick={handleCopyMerchantAndStoreId} />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            {isCopyAlertOpen && (
                <SnackBarAndAlert open={isCopyAlertOpen} onClose={closeCopyAlert} type={'success'}>
                    {title === 'pdq'
                        ? 'Successfully copied the merchant ID'
                        : 'Successfully copied the merchant and store id.'}
                </SnackBarAndAlert>
            )}
        </React.Fragment>
    );
}

ThirdPartyMerchantIdAndStoreIdComponent.propTypes = {
    merchantIdAndMerchantStoreId: PropTypes.string.isRequired,
    isCopyAlertOpen: PropTypes.bool.isRequired,
    handleCopyMerchantAndStoreId: PropTypes.func.isRequired,
    closeCopyAlert: PropTypes.func.isRequired,
    withLabel: PropTypes.bool,
    title: PropTypes.string
};
