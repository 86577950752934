import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Box,
    Dialog,
    Grid,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { ExportCSV, ExportTransactionsPDF } from 'components';
import _ from 'lodash';
import { useGlobalStateContext } from 'contexts';
import { ResellerLogoMap } from 'utils/enums/ResellerLogoMap';
import moment from 'moment';
import { toTitleCase } from 'utils/helper';
import styles from './modal-export-report.module.scss';
import { ResellerType } from 'utils/enums/ResellerType';

const ModalExportReportComponent = (props) => {
    const {
        loading,
        intervalStart,
        intervalEnd,
        download,
        reportData,
        reportType,
        error,
        longRun,
        onRetryStripeReport,
        stripeReportId,
        setButtonLoad,
        buttonLoad,
        merchantTradingAddress,
        merchantLegalName,
        merchantMobile,
        setShowReportModel,
        companyAddress,
        daysRange,
        setDaysRange,
        setShowCharges,
        showCharges,
        withChargeData,
        isAdmin,
        path,
        paymentsFinanceDetails,
        showReportModel,
        GFOPaymentsFinanceDetails,
        showGFOTransaction,
        setShowGFOTransaction,
        allPayments
    } = props;
    const { globalState } = useGlobalStateContext();
    let showRetryStripeReportButton = !loading && longRun;
    let reportTitle;
    if (reportType === 'gfo-payments') {
        reportTitle = 'GFO-Payments';
    } else if (reportType === 'pdq-transactions') {
        reportTitle = 'PDQ_Transactions';
    } else if (reportType === 'internal-transfers') {
        reportTitle = 'Internal_Transfers';
    } else {
        reportTitle = toTitleCase(reportType);
    }
    let startExportDate = _.isEmpty(daysRange) ? intervalStart : daysRange.startDate;
    let endExportDate = _.isEmpty(daysRange) ? intervalEnd : daysRange.endDate;
    // To print current date in current month as end date
    let getDate = new Date(endExportDate);
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    let currentYear = currentDate.getFullYear();
    if (
        (currentMonth === getDate.getMonth() || currentYear === getDate.getFullYear()) &&
        getDate.getTime() > currentDate.getTime()
    ) {
        endExportDate = currentDate;
    }
    let csvDate =
        startExportDate === endExportDate
            ? moment(startExportDate).format('DDMMMYYYY')
            : moment(startExportDate).format('DDMMMYYYY') + '_To_' + moment(endExportDate).format('DDMMMYYYY');

    const isCheckboxDisabled =
        path === 'payments' || path === 'gfo-payments' ? reportData.length <= 2 : reportData.length <= 0;

    return (
        <React.Fragment>
            <Dialog
                maxWidth="md"
                open={showReportModel}
                onClose={() => props.cancelExport()}
                aria-labelledby="form-dialog-title"
                className={styles.exportrModalBox}
            >
                <DialogTitle id="form-dialog-title" onClose={() => props.cancelExport()}>
                    Export transactions
                </DialogTitle>

                <DialogContent dividers>
                    <Box className={styles.boxStyle}>
                        {(path === 'payments' || path === 'gfo-payments'
                            ? reportData.length <= 2
                            : _.isEmpty(reportData)) &&
                        download &&
                        _.isEmpty(allPayments) ? (
                            <>
                                <div className="payments-none text-center">
                                    <p className="text-muted">
                                        {error
                                            ? `Something went wrong... Try again`
                                            : longRun
                                            ? `Took longer than expected... Try again`
                                            : `No data for selected date range.`}
                                    </p>
                                </div>
                            </>
                        ) : _.isEmpty(merchantTradingAddress) && reportData.length === 0 ? (
                            <>
                                <div className="payments-none text-center">
                                    <Box textAlign="center" py={2}>
                                        <CircularProgress />
                                    </Box>{' '}
                                    <p className="text-muted">Please wait, running report...</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="payments-none text-center">
                                    {reportData.length > 0 && (
                                        <p className="text-muted">Report is ready, click below to download.</p>
                                    )}
                                    <div className={styles.buttonWrapper} style={{ marginTop: '10px' }}>
                                        {buttonLoad && reportData.length > 0 ? (
                                            <ExportCSV
                                                csvData={showCharges ? withChargeData : reportData}
                                                fileName={
                                                    globalState.merchant.id +
                                                    '_' +
                                                    reportTitle +
                                                    '_statement_' +
                                                    csvDate
                                                }
                                                setShowReportModel={setShowReportModel}
                                                setDaysRange={setDaysRange}
                                                path={path}
                                            ></ExportCSV>
                                        ) : (
                                            <div className="text-center">
                                                <Box textAlign="center" py={2}>
                                                    <CircularProgress />
                                                </Box>
                                                <p className="text-muted">Please wait, running report...</p>
                                            </div>
                                        )}
                                        <div className="ml-3 mr-3"></div>

                                        {reportData.length > 0 && (
                                            <ExportTransactionsPDF
                                                setButtonLoad={setButtonLoad}
                                                csvData={showCharges ? withChargeData : reportData}
                                                startDate={intervalStart}
                                                endDate={intervalEnd}
                                                merchantMobile={merchantMobile}
                                                merchantAddress={merchantTradingAddress ? merchantTradingAddress : null}
                                                merchantName={merchantLegalName}
                                                logo={ResellerLogoMap[globalState.reseller?.id]}
                                                reportType={reportType}
                                                setShowReportModel={setShowReportModel}
                                                companyAddress={companyAddress}
                                                daysRange={daysRange}
                                                setDaysRange={setDaysRange}
                                                showCharges={showCharges}
                                                paymentsFinanceDetails={paymentsFinanceDetails}
                                                GFOPaymentsFinanceDetails={GFOPaymentsFinanceDetails}
                                                path={path}
                                            />
                                        )}
                                    </div>
                                    {reportData.length > 0 && (
                                        <div className={styles.checkboxWrapper}>
                                            {(path === 'payments' || path === 'gfo-payments') &&
                                                globalState.reseller?.name === ResellerType.DATMAN &&
                                                isAdmin && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={showCharges}
                                                                onChange={(e) => {
                                                                    setShowCharges(e.target.checked);
                                                                }}
                                                                disabled={isCheckboxDisabled}
                                                            />
                                                        }
                                                        label="Include service fee"
                                                        style={{ margin: 0 }}
                                                    />
                                                )}
                                            {path === 'payments' &&
                                                globalState.reseller?.name === ResellerType.DATMAN &&
                                                isAdmin && (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={showGFOTransaction}
                                                                onChange={(e) => {
                                                                    setShowGFOTransaction(e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label="Include GFO transactions"
                                                        style={{ margin: 0 }}
                                                    />
                                                )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    {
                        <Grid
                            container
                            direction="row"
                            justify={showRetryStripeReportButton ? 'space-between' : 'flex-end'}
                        >
                            {showRetryStripeReportButton && (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        onRetryStripeReport(stripeReportId);
                                    }}
                                >
                                    Retry
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                autoFocus
                                onClick={props.confirmExport}
                            >
                                Close
                            </Button>
                        </Grid>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalExportReportComponent;

ModalExportReportComponent.propTypes = {
    loading: PropTypes.bool,
    intervalStart: PropTypes.string,
    intervalEnd: PropTypes.string,
    download: PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    reportData: PropTypes.object,
    error: PropTypes.bool,
    longRun: PropTypes.bool,
    onRetryStripeReport: PropTypes.func,
    stripeReportId: PropTypes.string,
    reportType: PropTypes.string,
    companyAddress: PropTypes.string
};
