import React, { useEffect, useState } from 'react';
import DocumentReviewComponent from './document-review.component';
import { useGlobalStateContext } from 'contexts';
import { DocumentsService, AdminService, AdyenService } from 'services';
import { useAdminFunctionsContext } from 'contexts';
import { MerchantAccountStatus } from 'utils/enums/MerchantAccountStatus';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { DocumentTypes } from '../../../../utils/enums/DocumentType';

const DocumentReview = (props) => {
    const { globalState } = useGlobalStateContext();
    const entity = 'merchant';
    const defaultDocumentType = 'All';
    const defaultDocumentStatus = 'ALL';
    const defaultDocumentReviewReason = 'ALL';
    const [documentToReview, setDocumentToReview] = useState([]);
    const { setAdminFunctions } = useAdminFunctionsContext();
    const [isLoading, setIsLoading] = useState(false);
    const [documentReviewReason, setDocumentReviewReason] = useState(defaultDocumentReviewReason);
    const [allDocumentToReview, setAllDocumentsToReview] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [documentType, setDocumentType] = useState(defaultDocumentType);
    const [documentStatus, setDocumentStatus] = useState(defaultDocumentStatus);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMerchantVerificationCheckListOpen, setIsMerchantVerificationCheckListOpen] = useState(false);
    const [merchantVerificationAcceptRejectMessage, setMerchantVerificationAcceptRejectMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [orangeMessageStyle, setOrangeMessageStyle] = useState(false);
    const [documentIndex, setDocumentIndex] = useState(0);
    const [documentUpdated, setDocumentsUpdated] = useState(false);
    const [message, setMessage] = useState('');
    const [showAdyenMessage, setShowAdyenMessage] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [documentToView, setDocumnetToView] = useState([]);
    const [count, setCount] = useState(0);

    const { download, setDownload, pathMerchantId } = props;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (pathMerchantId) {
                const merchantData = await AdminService.GetMerchantDataForAdmin(pathMerchantId);
                if (merchantData.isSuccesfully) {
                    switch (merchantData.data.status) {
                        case MerchantStatus.MERCHANT_PENDING:
                            merchantData.data.accountStatus = MerchantAccountStatus.PENDING;
                            break;
                        case MerchantStatus.ACTIVE:
                            merchantData.data.accountStatus = MerchantAccountStatus.ACTIVE;
                            break;
                        case MerchantStatus.CLOSED:
                            merchantData.data.accountStatus = MerchantAccountStatus.CLOSED;
                            break;
                        case MerchantStatus.REJECTED:
                            merchantData.data.accountStatus = MerchantAccountStatus.REJECTED;
                            break;
                        default:
                            merchantData.data.accountStatus = MerchantAccountStatus.PENDING;
                            break;
                    }
                    setAdminFunctions(merchantData.data);
                }
            }
            setIsLoading(false);
        };
        fetchData();
        // eslint-disable-next-line
    }, [pathMerchantId]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const response = await DocumentsService.listObjects(entity, globalState.merchant?.id);

            if (response.isSuccesfully) {
                const listOfParams = [];
                response.data.forEach((file) => {
                    const fileObj = {};
                    fileObj.entity = entity;
                    fileObj.entityId = globalState.merchant?.id;
                    fileObj.documentId = file.id;
                    fileObj.filename = file.filename;
                    fileObj.documentTypeName = file.DocumentType?.name;
                    fileObj.createdAt = file.created_at;
                    fileObj.documentStatus = file.status;
                    fileObj.flag = file.flag;
                    fileObj.docTypeId = file.doc_type_id;
                    fileObj.idNumber = file.idNumber;
                    fileObj.cardNumber = file.cardNumber;
                    listOfParams.push(fileObj);
                });

                setDocumentToReview(listOfParams);
                setAllDocumentsToReview(listOfParams);
                setDocumentType(defaultDocumentType);
                setDocumentReviewReason(defaultDocumentReviewReason);
                setDocumentStatus(defaultDocumentStatus);
                setCount(listOfParams.length);
                setDocumnetToView(listOfParams.slice(0, rowsPerPage));
                setPage(0);
            }
            setIsLoading(false);
        };

        getData();

        // eslint-disable-next-line
    }, [globalState.merchant]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await DocumentsService.listObjects(entity, globalState.merchant?.id);
            if (response.isSuccesfully) {
                const updatedDocuments = response.data;
                const allDocuments = [...allDocumentToReview];
                for (let i = 0; i < updatedDocuments.length; i++) {
                    if (updatedDocuments[i].id === allDocuments[i].documentId) {
                        if (updatedDocuments[i].status !== allDocuments[i].documentStatus) {
                            allDocuments[i].documentStatus = updatedDocuments[i].status;
                        }
                    }
                }
                setAllDocumentsToReview([...allDocuments]);
            }
        };

        if (documentUpdated) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [documentUpdated]);

    useEffect(() => {
        if (documentUpdated) {
            filterDocuments(documentReviewReason, documentType, documentStatus);
            setDocumentsUpdated(false);
        }
        // eslint-disable-next-line
    }, [allDocumentToReview]);

    useEffect(() => {
        const doDownload = async () => {
            setIsLoading(true);
            await DocumentsService.getObjects(documentToReview);
            setIsLoading(false);
            setDownload(false);
        };

        if (download) {
            doDownload();
        }

        // eslint-disable-next-line
    }, [download]);

    const filterDocuments = (documentReviewReason, documentType, documentStatus) => {
        const defaultReviewCondition = documentReviewReason === defaultDocumentReviewReason;
        const defaultTypeConditions = documentType === defaultDocumentType;
        const defaultStatusConditions = documentStatus === defaultDocumentStatus;

        const filteredDocuments = allDocumentToReview.filter(
            (document) =>
                (defaultReviewCondition ? true : document.flag === documentReviewReason) &&
                (defaultTypeConditions ? true : document.documentTypeName === documentType) &&
                (defaultStatusConditions ? true : document.documentStatus === documentStatus)
        );
        setDocumentToReview(filteredDocuments);
        setDocumnetToView(filteredDocuments.slice(0, rowsPerPage));
        setCount(filteredDocuments.length);
        setPage(0);
    };

    let headCells = [
        {
            id: 'document_type',
            numeric: true,
            disablePadding: false,
            label: 'Document type',
            hideSortingIcon: true
        },

        {
            id: 'file_name',
            numeric: false,
            disablePadding: false,
            label: 'File name',
            hideSortingIcon: true
        },

        { id: 'createdAt', numeric: false, disablePadding: false, label: 'Date', hideSortingIcon: false }
    ];

    const handleRequestSort = (event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handlerUploadDocumentToAdyen = async (documentId) => {
        setIsLoading(true);
        const response = await AdyenService.uploadDocumentToAdyen(globalState.merchant?.id, documentId);
        setShowAdyenMessage(true);

        if (response.isSuccesfully) {
            setMessageType('success');
            setMessage('Document uploaded successfully');
        } else {
            setMessageType('error');
            if (response.data && response.data[0] && response.data[0]?.errorDescription) {
                setMessage(response.data[0]?.errorDescription);
            } else if (response.data) {
                setMessage(response.data);
            } else {
                setMessage('An error occurred!');
            }
        }
        setIsLoading(false);
    };

    const updateDocumentTypeHandler = async (documentID, documentType) => {
        setIsLoading(true);
        const documentTypeId = DocumentTypes.filter((doc) => doc.name === documentType)[0].id;
        let selectedDocFromReview = documentToReview.filter((doc) => doc.documentId === documentID)[0];
        selectedDocFromReview = {
            ...selectedDocFromReview,
            docTypeId: documentTypeId,
            documentTypeName: documentType
        };
        const data = {
            documentTypeId: documentTypeId
        };
        const selectedDocIndex = allDocumentToReview.findIndex((doc) => doc.documentId === documentID);
        const tempAllDoc = [...allDocumentToReview];
        tempAllDoc[selectedDocIndex] = selectedDocFromReview;
        setAllDocumentsToReview(tempAllDoc);
        setDocumentsUpdated(true);
        await DocumentsService.updateDocumentType(globalState.merchant?.id, documentID, data);
        setIsLoading(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setDocumnetToView(documentToReview.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage));
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
        setDocumnetToView(documentToReview.slice(0, parseInt(event.target.value, 10)));
    };

    return (
        <DocumentReviewComponent
            headCells={headCells}
            documentToReview={documentToView}
            documentReviewReason={documentReviewReason}
            setDocumentReviewReason={setDocumentReviewReason}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            isLoading={isLoading}
            documentType={documentType}
            setDocumentType={setDocumentType}
            documentStatus={documentStatus}
            setDocumentStatus={setDocumentStatus}
            filterDocuments={filterDocuments}
            defaultDocumentType={defaultDocumentType}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isMerchantVerificationCheckListOpen={isMerchantVerificationCheckListOpen}
            setIsMerchantVerificationCheckListOpen={setIsMerchantVerificationCheckListOpen}
            merchantVerificationAcceptRejectMessage={merchantVerificationAcceptRejectMessage}
            setMerchantVerificationAcceptRejectMessage={setMerchantVerificationAcceptRejectMessage}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            messageType={messageType}
            setMessageType={setMessageType}
            orangeMessageStyle={orangeMessageStyle}
            setOrangeMessageStyle={setOrangeMessageStyle}
            documentIndex={documentIndex}
            setDocumentIndex={setDocumentIndex}
            setDocumentsUpdated={setDocumentsUpdated}
            setDocumentToReview={setDocumentToReview}
            handlerUploadDocumentToAdyen={handlerUploadDocumentToAdyen}
            setShowAdyenMessage={setShowAdyenMessage}
            showAdyenMessage={showAdyenMessage}
            message={message}
            setMessage={setMessage}
            updateDocumentTypeHandler={updateDocumentTypeHandler}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
        />
    );
};

export default DocumentReview;
