import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InternalTransferSubmissionComponent from './internal-transfer-submission.component';
import { InternalTransfersService } from 'services';
// import { useGlobalStateContext } from 'contexts';
import { validatePaymentAmount } from 'validators';
import { useBalanceContext } from 'contexts';

export default function InternalTransferSubmission(props) {
    const { availableBalance, refreshBalance } = useBalanceContext();
    const [supplier, setSupplier] = useState('');
    const [description, setDescription] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const {
        setShow,
        transferAmount,
        setTransferAmount,
        setIsSuccessWithdraw,
        setSuccessMessage,
        show,
        setOpenView
    } = props;
    const [currentAmount, setCurrentAmount] = useState(0);
    const [errMessageSupplier, setErrMessageSupplier] = useState('');
    const [errMessageAmount, setErrMessageAmount] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await InternalTransfersService.getSuppliers();
            if (response.isSuccesfully) {
                setSuppliers(response.data);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setCurrentAmount(transferAmount);
    }, [transferAmount, show]);

    useEffect(() => {
        setErrMessageSupplier('');
        setErrMessageAmount('');

        // eslint-disable-next-line
        if ((!supplier || supplier == -1) && show) {
            setErrMessageSupplier('Supplier not provided');
        }
        paymentActionValidation();
        // eslint-disable-next-line
        const disable =
            !supplier || supplier === -1 || !currentAmount || validationMessage.message || +currentAmount === 0;
        setDisabled(disable);
        // eslint-disable-next-line
    }, [supplier, description, currentAmount]);

    const isValidData = () => {
        return errMessageSupplier === '' && errMessageAmount === '';
    };
    let validationMessage;
    function paymentActionValidation() {
        validationMessage = validatePaymentAmount(availableBalance, currentAmount);
        if (!validationMessage.isValid) {
            setErrMessageAmount(validationMessage.message);
            // eslint-disable-next-line
        } else if (!currentAmount || currentAmount == 0) {
            setErrMessageAmount('Please enter an amount');
        }
    }

    const submitInternalTransfer = async () => {
        setFormSubmitted(true);

        if (!isValidData()) {
            return false;
        }
        setLoading(true);
        await InternalTransfersService.submitInternalTransfer({
            supplier_id: supplier,
            amount: currentAmount,
            action: 'create',
            description: description
        })
            .then((data) => {
                if (data.isSuccesfully) {
                    refreshBalance();
                    setShow(false);
                    setSupplier(-1);
                    setCurrentAmount(0);
                    setDescription('');
                    setTransferAmount(null);
                    setFormSubmitted(false);
                    setLoading(false);
                    setOpenView(false);
                    setSuccessMessage('Supplier payment successful.');
                    setIsSuccessWithdraw(true);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => setLoading(false));
    };
    const handleOnClose = () => {
        setShow(false);
        setSupplier('');
        setCurrentAmount(0);
        setErrMessageSupplier('');
        setErrMessageAmount('');
        setDescription('');
        setTransferAmount(null);
    };
    const isMaxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 9999999.99;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };
    return (
        <InternalTransferSubmissionComponent
            {...props}
            supplier={supplier}
            setSupplier={setSupplier}
            description={description}
            setDescription={setDescription}
            submitInternalTransfer={submitInternalTransfer}
            suppliers={suppliers}
            currentAmount={currentAmount}
            setCurrentAmount={setCurrentAmount}
            errMessageSupplier={errMessageSupplier}
            errMessageAmount={errMessageAmount}
            formSubmitted={formSubmitted}
            loading={loading}
            disabled={disabled}
            handleOnClose={handleOnClose}
            isMaxAmount={isMaxAmount}
        />
    );
}

InternalTransferSubmission.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    transferAmount: PropTypes.number,
    setTransferAmount: PropTypes.func,
    setIsSuccessWithdraw: PropTypes.func,
    setSuccessMessage: PropTypes.func
};
