import React from 'react';
import {
    Notes,
    Group,
    BusinessCenter,
    AccountBalance,
    AccountBalanceWallet,
    Phone,
    AssignmentIndSharp
} from '@material-ui/icons';
import { Typography, makeStyles, Paper, withStyles, Badge } from '@material-ui/core';
import style from './admin-dashboard-topbar.module.scss';
import Avatar from 'react-avatar';
import { useGlobalStateContext } from 'contexts';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -20,
        top: -1,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',

        '& > *': {
            margin: theme.spacing(0.5),
            width: theme.spacing(29.8),
            height: theme.spacing(6)
        }
    }
}));
export default function AdminDashbordTopBarComponent() {
    const { setRoute } = useGlobalStateContext();

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Paper>
                <div className={style.topbar_content}>
                    <Avatar round size={25} name="Jane Smith" />
                    <Typography className={style.topbar_content__text}> Hi Jane</Typography>
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}> Awating Review</Typography>
                    <Notes className={style.topbar_content__icon} /> <StyledBadge badgeContent={12} color="primary" />
                </div>
            </Paper>
            <Paper onClick={() => setRoute('/pending-customers')}>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}> Pending Customers</Typography>
                    <Group className={style.topbar_content__icon} /> <StyledBadge badgeContent={4} color="primary" />
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}> Account Verify</Typography>
                    <BusinessCenter className={style.topbar_content__icon} />
                    <StyledBadge badgeContent={33} color="primary" />
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}> Setup Fee</Typography>
                    <AccountBalanceWallet className={style.topbar_content__icon} />
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}>Bank Verify</Typography>
                    <AccountBalance className={style.topbar_content__icon} />
                </div>
            </Paper>

            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}>Phone</Typography>
                    <Phone className={style.topbar_content__icon} />
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}>Payment Not Received</Typography>
                    <BusinessCenter className={style.topbar_content__icon} />
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}>Outstanding Chargebacks</Typography>
                </div>
            </Paper>
            <Paper>
                <div className={style.topbar_content}>
                    <Typography className={style.topbar_content__text}>Agents Todo</Typography>
                    <AssignmentIndSharp className={style.topbar_content__icon} />
                    <StyledBadge badgeContent={10} color="primary" />
                </div>
            </Paper>
        </div>
    );
}
