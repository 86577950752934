import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    Grid,
    NativeSelect,
    OutlinedInput,
    InputAdornment
} from '@material-ui/core';
import { FormItem, Label, Input } from 'components/onboarding/onboarding-ui-components';
import styles from './internal-transfer-submission.module.scss';
import NumberFormat from 'react-number-format';
import { Alert } from '@material-ui/lab';
import { useGlobalStateContext } from 'contexts';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';

function InternalTransferSubmissionComponent(props) {
    const { globalState } = useGlobalStateContext();
    const {
        supplier,
        setSupplier,
        description,
        setDescription,
        submitInternalTransfer,
        suppliers,
        currentAmount,
        setCurrentAmount,
        errMessageSupplier,
        errMessageAmount,
        formSubmitted,
        loading,
        disabled,
        handleOnClose,
        isMaxAmount
    } = props;

    return (
        <Dialog open={props.show} onClose={handleOnClose} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                <span className={styles.title}>Internal Transfers</span>
                <div className={styles.headerDivider}></div>
            </DialogTitle>
            <DialogContent className={styles.dialogContent}>
                <div>
                    <FormItem>
                        <Grid container spacing={3}>
                            <Grid item className={styles.labelText}>
                                <Label>Amount</Label>
                                <span style={{ color: 'red' }}>*</span>
                            </Grid>
                            <Grid item>
                                <NumberFormat
                                    customInput={OutlinedInput}
                                    inputProps={{ inputMode: 'numeric' }}
                                    pattern="\d*"
                                    thousandSeparator={true}
                                    allowLeadingZeros={true}
                                    allowNegative={false}
                                    isNumericString={true}
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    id="amount"
                                    value={currentAmount}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className={styles.action_items__input__balance}>
                                                {getCurrencySymbol(globalState.merchant?.country)}
                                            </span>
                                            &nbsp;
                                        </InputAdornment>
                                    }
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        setCurrentAmount(value);
                                    }}
                                    isAllowed={isMaxAmount}
                                    aria-describedby="outlined-weight-helper-text"
                                    labelWidth={0}
                                    required
                                    className={styles.amountInput}
                                />
                                {errMessageAmount && (
                                    <Alert className={styles.alertError} severity="error">
                                        {errMessageAmount}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item className={styles.labelText}>
                                <Label>Supplier </Label>
                                <span style={{ color: 'red' }}> *</span>
                            </Grid>
                            <Grid item className={styles.supplierSelect}>
                                <NativeSelect
                                    input={
                                        <Input
                                            id="supplier"
                                            value={supplier}
                                            onChange={(e) => setSupplier(e.target.value)}
                                        />
                                    }
                                >
                                    <option key="-1" value="-1">
                                        --Please select--
                                    </option>
                                    {suppliers &&
                                        suppliers.length > 0 &&
                                        suppliers.map((sup) => {
                                            return (
                                                <option key={sup.id} value={sup.id}>
                                                    {sup.business_name}
                                                </option>
                                            );
                                        })}
                                </NativeSelect>

                                {formSubmitted && errMessageSupplier && (
                                    <Alert className={styles.alertError} severity="error">
                                        {errMessageSupplier}
                                    </Alert>
                                )}
                                <Grid item className={styles.topPadding}>
                                    <Input
                                        id="description"
                                        rowsMax={1}
                                        inputProps={{
                                            maxlength: 60
                                        }}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        variant="outlined"
                                        placeholder="Please enter the comments."
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormItem>
                </div>
            </DialogContent>
            <DialogActions className={styles.buttonsContainer}>
                <Grid container className={styles.grid}>
                    <Grid item xs={6}>
                        <Button className={styles.button} onClick={handleOnClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                    </Grid>
                    {loading ? (
                        <Grid item xs={6}>
                            <Button className={styles.button} variant="contained" disabled color="primary">
                                Confirm ...
                            </Button>
                        </Grid>
                    ) : (
                        <Grid item xs={6}>
                            <Button
                                className={styles.button}
                                variant="contained"
                                onClick={() => submitInternalTransfer()}
                                color="primary"
                                disabled={disabled}
                            >
                                Confirm
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

InternalTransferSubmissionComponent.propTypes = {
    supplier: PropTypes.string,
    setSupplier: PropTypes.func,
    currentAmount: PropTypes.number,
    setCurrentAmount: PropTypes.func,
    description: PropTypes.string,
    setDescription: PropTypes.func,
    submitInternalTransfer: PropTypes.func,
    show: PropTypes.bool,
    setShow: PropTypes.func,
    receivingDate: PropTypes.string,
    submitWithdrawal: PropTypes.func,
    suppliers: PropTypes.array,
    errMessageSupplier: PropTypes.string,
    errMessageAmount: PropTypes.string,
    formSubmitted: PropTypes.bool
};

export default InternalTransferSubmissionComponent;
