import React from 'react';
import MerchantDashBoardNavigationHeaderComponent from './merchant-dashboard-navigation-header.component';

function MerchantDashBoardNavigationHeader() {
    return <MerchantDashBoardNavigationHeaderComponent />;
}

MerchantDashBoardNavigationHeader.propTypes = {};

export default MerchantDashBoardNavigationHeader;
