import React from 'react';
import PropTypes from 'prop-types';
import styles from './onboarding-transaction-profile-step-one.module.scss';
import { AntSwitch } from 'components';
import { Typography, Grid } from '@material-ui/core';
import {
    Label,
    NextButton,
    OnboardingFormsContainer,
    FormItem,
    SelectedDropDown
} from 'components/onboarding/onboarding-ui-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        '& .MuiAutocomplete-inputRoot': {
            padding: 0,
            backgroundColor: '#fff'
        }
    },
    borderItemSpaces: {
        '& .MuiGrid-grid-xs-6': {
            maxWidth: '48%'
        }
    }
}));

function OnboardingTransactionProfileStepOneComponent(props) {
    const { title, nextStep, onboardingForm, setOnboardingForm, isDisabled, oneHundredValues, onSubmit } = props;

    const classes = useStyles();

    function formBody() {
        return (
            <React.Fragment>
                <FormItem>
                    <Label bold>Deposits</Label>
                    <div className={styles.switchContainer}>
                        <Grid container justify="space-between">
                            <Grid item xs={12} sm={6}>
                                <Typography color="primary" component="span">
                                    Do you take deposits
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={styles.yes_no_Switch}>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        No
                                    </Typography>
                                    <AntSwitch
                                        id="isDepositsTaken"
                                        checked={onboardingForm.transactionProfile.stepOne.isDepositsTaken}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepOne: {
                                                        ...onboardingForm.transactionProfile.stepOne,
                                                        isDepositsTaken: e.target.checked
                                                    }
                                                }
                                            })
                                        }
                                    ></AntSwitch>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        Yes
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </FormItem>

                {onboardingForm.transactionProfile.stepOne.isDepositsTaken && (
                    <FormItem>
                        <Grid container className={`${styles.borderItems} ${classes.borderItemSpaces}`}>
                            <Grid item xs={12} sm={6} className={`${styles.whiteRoundBorders} ${styles.inBorderText}`}>
                                <div>
                                    <span>% Goods/Services value taken as deposit?</span>
                                    <SelectedDropDown
                                        id="goods"
                                        options={oneHundredValues}
                                        getOptionLabel={(option) => option}
                                        listPrefix={' %'}
                                        value={onboardingForm.transactionProfile.stepOne.goods}
                                        onChange={(e, newValue) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepOne: {
                                                        ...onboardingForm.transactionProfile.stepOne,
                                                        goods: newValue
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`${styles.whiteRoundBorders} ${styles.inBorderText}`}>
                                <div>
                                    <span>% Total card turnover taken as deposit?</span>
                                    <SelectedDropDown
                                        id="cardTurnover"
                                        options={oneHundredValues}
                                        getOptionLabel={(option) => option}
                                        listPrefix={' %'}
                                        value={onboardingForm.transactionProfile.stepOne.cardTurnover}
                                        onChange={(e, newValue) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepOne: {
                                                        ...onboardingForm.transactionProfile.stepOne,
                                                        cardTurnover: newValue
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className={`${styles.borderItems} ${classes.borderItemSpaces}`}>
                            <Grid item xs={12} sm={6} className={`${styles.whiteRoundBorders} ${styles.inBorderText}`}>
                                <span
                                    className={
                                        onboardingForm.transactionProfile.stepOne.isDepositsTaken
                                            ? ''
                                            : styles.disabledText
                                    }
                                >
                                    How far in advance is deposit taken?
                                </span>
                                <SelectedDropDown
                                    disabled={!onboardingForm.transactionProfile.stepOne.isDepositsTaken}
                                    options={oneHundredValues}
                                    getOptionLabel={(option) => option}
                                    listPrefix={' Days'}
                                    value={onboardingForm.transactionProfile.stepOne.depositFarDays}
                                    onChange={(e, newValue) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            transactionProfile: {
                                                ...onboardingForm.transactionProfile,
                                                stepOne: {
                                                    ...onboardingForm.transactionProfile.stepOne,
                                                    depositFarDays: newValue
                                                }
                                            }
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={`${styles.whiteRoundBorders} ${styles.inBorderText}`}>
                                <span
                                    className={
                                        onboardingForm.transactionProfile.stepOne.isDepositsTaken
                                            ? ''
                                            : styles.disabledText
                                    }
                                >
                                    No. Days in advance of delivery is balance is taken?
                                </span>
                                <SelectedDropDown
                                    disabled={!onboardingForm.transactionProfile.stepOne.isDepositsTaken}
                                    options={oneHundredValues}
                                    getOptionLabel={(option) => option}
                                    listPrefix={' Days'}
                                    value={onboardingForm.transactionProfile.stepOne.noDeliveryDays}
                                    onChange={(e, newValue) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            transactionProfile: {
                                                ...onboardingForm.transactionProfile,
                                                stepOne: {
                                                    ...onboardingForm.transactionProfile.stepOne,
                                                    noDeliveryDays: newValue
                                                }
                                            }
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </FormItem>
                )}

                <FormItem>
                    <Label bold>Payment</Label>
                    <div className={styles.switchContainer}>
                        <Grid container justify="space-between">
                            <Grid item xs={12} sm={6}>
                                <Typography color="primary" component="span">
                                    Do you take full payments for Goods/services in advance
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className={styles.yes_no_Switch}>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        No
                                    </Typography>
                                    <AntSwitch
                                        id="isPrePayment"
                                        checked={onboardingForm.transactionProfile.stepOne.isPrePayment}
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepOne: {
                                                        ...onboardingForm.transactionProfile.stepOne,
                                                        isPrePayment: e.target.checked
                                                    }
                                                }
                                            })
                                        }
                                    ></AntSwitch>
                                    <Typography color="primary" component="span" className={styles.checkBoxText}>
                                        Yes
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </FormItem>

                {onboardingForm.transactionProfile.stepOne.isPrePayment && (
                    <FormItem>
                        <Grid container className={`${styles.borderItems} ${classes.borderItemSpaces}`}>
                            <Grid item xs={12} sm={6} className={`${styles.nonRoundBorders} ${styles.inBorderText}`}>
                                <div>
                                    <span>% of total card turnover taken as full pre-payments</span>
                                    <SelectedDropDown
                                        id="fullPrePayments"
                                        options={oneHundredValues}
                                        getOptionLabel={(option) => option}
                                        listPrefix={' %'}
                                        value={onboardingForm.transactionProfile.stepOne.fullPrePayments}
                                        onChange={(e, newValue) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepOne: {
                                                        ...onboardingForm.transactionProfile.stepOne,
                                                        fullPrePayments: newValue
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} className={`${styles.nonRoundBorders} ${styles.inBorderText}`}>
                                <div>
                                    <span>% How far in advance do you take full payment?</span>
                                    <SelectedDropDown
                                        id="advanceFullPaymentsDays"
                                        options={oneHundredValues}
                                        getOptionLabel={(option) => option}
                                        listPrefix={' Days'}
                                        value={onboardingForm.transactionProfile.stepOne.advanceFullPaymentsDays}
                                        onChange={(e, newValue) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                transactionProfile: {
                                                    ...onboardingForm.transactionProfile,
                                                    stepOne: {
                                                        ...onboardingForm.transactionProfile.stepOne,
                                                        advanceFullPaymentsDays: newValue
                                                    }
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </FormItem>
                )}
            </React.Fragment>
        );
    }

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} content={formBody} nextButton={nextBtn} />;
}

OnboardingTransactionProfileStepOneComponent.propTypes = {
    title: PropTypes.element,
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    oneHundredValues: PropTypes.array,
    onSubmit: PropTypes.func
};

export default OnboardingTransactionProfileStepOneComponent;
