import React from 'react';
import styles from './t2s-onboarding-bank-details.module.scss';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { withStyles } from '@material-ui/core/styles';
import {
    Input,
    Label,
    NextButton,
    OnboardingTitle,
    OnboardingFormsContainer,
    FormItem
} from 'components/onboarding/onboarding-ui-components';
import { checkEmptyValue, getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import ReactCodeInput from 'react-verification-code-input';
import { useMediaQuery, Typography, Tooltip } from '@material-ui/core';
import { BankSortCodeNumberContainer } from 'components';
import { ErrorOutline, CheckCircle, WarningRounded } from '@material-ui/icons';
import { useGlobalStateContext } from 'contexts';
import { FormatMessage } from 'utils/languageTranslater';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import NewZealandAccountNumberContainer from 'components/new-zealand-account-number/new-zealand-account-number.container';
import { LoadingScreen } from 'components';

const ErrorTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fdecea',
        color: '#611a15',
        fontSize: 14,
        height: '50px',
        maxWidth: '100%',
        padding: '0'
    }
}))(Tooltip);
function T2SOnboardingBankDetailsComponent(props) {
    const { globalState } = useGlobalStateContext();

    const {
        nextStep,
        onboardingForm,
        setOnboardingForm,
        isDisabled,
        onSubmit,
        accNumberInputRef,
        isBankDetailsNotFound,
        statusValidationBankAccount,
        lengthIssue,
        setLengthIssue,
        confirmAccountNumber,
        setConfirmAccountNumber,
        showMessage,
        showErrorForAccNo,
        AccNoErrorMessage,
        setBankAccountHandler,
        checkvalue,
        show,
        setShow,
        isLoading
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const mediaLessThan750px = useMediaQuery('(max-width:749px)');
    const mediaLessThan400px = useMediaQuery('(max-width:399px)');
    const isActiveMerchant = globalState.merchant?.status === MerchantStatus.ACTIVE;
    const isUkMerchant = globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM;
    const textField = globalState.merchant?.country === MerchantCountries.AUSTRALIA || MerchantCountries.MEXICO;
    const message = show || showMessage;

    function formBody() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={styles.formItemGrid}>
                        <Grid>
                            {globalState.merchant.status === MerchantStatus.WATCHLIST && (
                                <div className={styles.notificationContainer}>
                                    <div className={styles.iconUnverified}>
                                        <ErrorOutline className={styles.error_icon} />
                                    </div>
                                    <Typography color="primary">Bank Update is not available at this time</Typography>
                                </div>
                            )}
                        </Grid>
                        <FormItem>
                            <Label
                                text={
                                    globalState.merchant.country === MerchantCountries.MEXICO
                                        ? 'Account name'
                                        : 'Account Name (as shown on bank statement)'
                                }
                                id="account-holder-name"
                                transalterEnabled={true}
                            />
                            <Input
                                placeholder={FormatMessage({ id: 'account-holder-name', text: 'Account name' })}
                                id="account-holder-name-input"
                                value={onboardingForm.tradingAddress.accountHolderName}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            accountHolderName: e.target.value
                                        }
                                    })
                                }
                                autoFocus={true}
                                emptyField={
                                    isUkMerchant &&
                                    isActiveMerchant &&
                                    !checkEmptyValue(onboardingForm.tradingAddress.accountHolderName)
                                        ? true
                                        : false
                                }
                            />
                        </FormItem>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                            {onboardingForm.isPopulated && (
                                <FormItem>
                                    <Label text="BSB" id="bsb-australia-label" />
                                    <ReactCodeInput
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        fields={6}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(onboardingForm.tradingAddress.bsb)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    bsb: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            )}
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                            {onboardingForm.isPopulated && (
                                <FormItem>
                                    <Label text="Routing number" id="routing-number" />
                                    <ReactCodeInput
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34}
                                        fieldWidth={mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34}
                                        autoFocus={false}
                                        fields={9}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(onboardingForm.tradingAddress.routingNumber)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    routingNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            )}
                        </CheckMerchantCountries>
                        <Grid item xs={12} className={styles.formItemGrid}>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                                <FormItem>
                                    <Label text="Account number" id="account-number-label" transalterEnabled={true} />
                                    <ErrorTooltip
                                        open={showErrorForAccNo}
                                        arrow
                                        title={
                                            <Alert severity="error">
                                                <ErrorOutlineIcon className={styles.error_icon} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                            </Alert>
                                        }
                                    >
                                        <Input
                                            autoFocus={false}
                                            fields={18}
                                            type="character"
                                            pattern="\d*"
                                            value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                            onChange={setBankAccountHandler}
                                        />
                                    </ErrorTooltip>
                                </FormItem>
                            </CheckMerchantCountries>
                        </Grid>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                            {onboardingForm.isPopulated && (
                                <FormItem>
                                    <Label text="Transit number" id="transit-number" />
                                    <ReactCodeInput
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        fields={5}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(onboardingForm.tradingAddress.transitNumber)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    transitNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            )}
                            {onboardingForm.isPopulated && (
                                <FormItem>
                                    <Label text="Financial institution number" id="financial-institution-number" />
                                    <ReactCodeInput
                                        className={styles.codeInput}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        fields={3}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(
                                            onboardingForm.tradingAddress.financialInstitutionNumber
                                        )}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    financialInstitutionNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            )}
                        </CheckMerchantCountries>
                        {onboardingForm.isPopulated && (
                            <React.Fragment>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                                    <Grid container>
                                        <Grid item xs={mediaLessThan600px ? 12 : 6}>
                                            <div className={styles.accountContainer}>
                                                <FormItem>
                                                    <Label text="Bank sort code" id="bank-sort-code" />
                                                    <BankSortCodeNumberContainer
                                                        hideDashes={mediaLessThan600px}
                                                        values={onboardingForm.tradingAddress.sortCode}
                                                        nextElementRef={accNumberInputRef}
                                                        onChange={(value) => {
                                                            setOnboardingForm({
                                                                ...onboardingForm,
                                                                tradingAddress: {
                                                                    ...onboardingForm.tradingAddress,
                                                                    sortCode: value
                                                                }
                                                            });
                                                        }}
                                                        emptyField={
                                                            isUkMerchant &&
                                                            isActiveMerchant &&
                                                            !checkEmptyValue(onboardingForm.tradingAddress.sortCode)
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </FormItem>
                                                <FormItem>
                                                    <Label text="Account number" id="new-account-number" />
                                                    <ErrorTooltip
                                                        open={showErrorForAccNo}
                                                        arrow
                                                        title={
                                                            <Alert severity="error">
                                                                <ErrorOutlineIcon className={styles.error_icon} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                            </Alert>
                                                        }
                                                    >
                                                        <Input
                                                            className={styles.codeInput}
                                                            autoFocus={false}
                                                            type="number"
                                                            inputProps={{ ref: accNumberInputRef }}
                                                            pattern="\d*"
                                                            value={checkEmptyValue(
                                                                onboardingForm.tradingAddress.newAccountNumber
                                                            )}
                                                            onChange={setBankAccountHandler}
                                                            emptyField={
                                                                isUkMerchant &&
                                                                isActiveMerchant &&
                                                                !checkEmptyValue(
                                                                    onboardingForm.tradingAddress.newAccountNumber
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </ErrorTooltip>
                                                </FormItem>
                                                <FormItem>
                                                    <Label
                                                        text="Confirm account number"
                                                        id="confirm-account-number-label"
                                                        transalterEnabled={true}
                                                    />
                                                    <Input
                                                        onBlur={checkvalue}
                                                        inputProps={{
                                                            className: styles.codeInput,
                                                            maxLength:
                                                                onboardingForm.tradingAddress.newAccountNumber.length
                                                        }}
                                                        maxLength="1"
                                                        autoFocus={false}
                                                        onKeyDown={(e) =>
                                                            e.keyCode !== 45 &&
                                                            e.keyCode > 31 &&
                                                            (e.keyCode < 48 || e.keyCode > 57) &&
                                                            e.preventDefault()
                                                        }
                                                        pattern="\d*"
                                                        value={checkEmptyValue(confirmAccountNumber)}
                                                        onChange={(e) => {
                                                            setShow(false);
                                                            setConfirmAccountNumber(e.target.value);
                                                        }}
                                                    />
                                                </FormItem>
                                                <Grid className={styles.showMessageStyle}>
                                                    {message && (
                                                        <div className={styles.notificationMessageContainer}>
                                                            <WarningRounded
                                                                className={styles.error_icon}
                                                                color="error"
                                                            />
                                                            <Typography color="error" style={{ marginLeft: '10px' }}>
                                                                Your account numbers don’t match
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={mediaLessThan600px ? 12 : 6} className={styles.iconContainer}>
                                            {statusValidationBankAccount === 1 && (
                                                <CheckCircle className={styles.validIcon} />
                                            )}
                                            {statusValidationBankAccount === 2 && (
                                                <ErrorOutline className={styles.errorIcon} />
                                            )}
                                        </Grid>
                                        <Grid>
                                            {isBankDetailsNotFound && (
                                                <div className={styles.notificationContainer}>
                                                    <div className={styles.iconUnverified}>
                                                        <ErrorOutline className={styles.error_icon} />
                                                    </div>
                                                    <Typography color="primary">
                                                        We did not find a match for the bank account details provided.
                                                        Please review this. If they are correct you may continue with
                                                        your application.
                                                    </Typography>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                                    <FormItem>
                                        <Label text="Bank IBAN" id="bank-IBAN-ireland-label" />
                                        <ErrorTooltip
                                            open={showErrorForAccNo}
                                            arrow
                                            title={
                                                <Alert severity="error">
                                                    <ErrorOutlineIcon className={styles.error_icon} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                </Alert>
                                            }
                                        >
                                            <Input
                                                className={styles.codeInput + ' ' + styles.codeInputIreland}
                                                fieldHeight={
                                                    (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px
                                                        ? 22
                                                        : 25
                                                }
                                                fieldWidth={
                                                    (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px
                                                        ? 22
                                                        : 25
                                                }
                                                autoFocus={false}
                                                fields={22}
                                                type="text"
                                                value={
                                                    onboardingForm.tradingAddress.newAccountNumber.length > 0
                                                        ? checkEmptyValue(
                                                              onboardingForm.tradingAddress.newAccountNumber
                                                          )
                                                        : 'IE'
                                                }
                                                onChange={setBankAccountHandler}
                                            />
                                        </ErrorTooltip>

                                        {onboardingForm.tradingAddress.newAccountNumber.length < 22 && lengthIssue ? (
                                            <div className={styles.ibannotificationContainer}>
                                                <div className={styles.iconUnverified}>
                                                    <ErrorOutline className={styles.error_icon} />
                                                </div>
                                                <Typography color="primary">
                                                    Invalid IBAN provided (Irish Iban consists of 22 Characters)
                                                </Typography>
                                            </div>
                                        ) : null}
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                                    <FormItem>
                                        <Label text="Account number" id="account-number-australia-label" />
                                        <ErrorTooltip
                                            open={showErrorForAccNo}
                                            arrow
                                            title={
                                                <Alert severity="error">
                                                    <ErrorOutlineIcon className={styles.error_icon} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                </Alert>
                                            }
                                        >
                                            <Input
                                                autoFocus={false}
                                                fields={9}
                                                type="text"
                                                pattern="\d*"
                                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                                onChange={setBankAccountHandler}
                                            />
                                        </ErrorTooltip>
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                    <FormItem>
                                        <Label text="Account number" id="account-number" />
                                        <ErrorTooltip
                                            open={showErrorForAccNo}
                                            arrow
                                            title={
                                                <Alert severity="error">
                                                    <ErrorOutlineIcon className={styles.error_icon} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                </Alert>
                                            }
                                        >
                                            <Input
                                                autoFocus={false}
                                                fields={20}
                                                type="number"
                                                pattern="\d*"
                                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                                onChange={setBankAccountHandler}
                                            />
                                        </ErrorTooltip>
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.NEW_ZEALAND]}>
                                    <FormItem>
                                        <Label text="Bank account number" id="bank-account" />
                                        <ErrorTooltip
                                            open={showErrorForAccNo}
                                            arrow
                                            title={
                                                <Alert severity="error">
                                                    <ErrorOutlineIcon className={styles.error_icon} />
                                                    {AccNoErrorMessage}
                                                </Alert>
                                            }
                                        >
                                            <div>
                                                <NewZealandAccountNumberContainer
                                                    hideDashes={mediaLessThan600px}
                                                    value={checkEmptyValue(
                                                        onboardingForm.tradingAddress.newAccountNumber
                                                    )}
                                                    onChange={(value) => {
                                                        setShow(false);
                                                        setOnboardingForm({
                                                            ...onboardingForm,
                                                            tradingAddress: {
                                                                ...onboardingForm.tradingAddress,
                                                                newAccountNumber: value
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </ErrorTooltip>
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                                    <FormItem>
                                        <Label text="Account number" id="new-account-number" />
                                        <ErrorTooltip
                                            open={showErrorForAccNo}
                                            arrow
                                            title={
                                                <Alert severity="error">
                                                    <ErrorOutlineIcon className={styles.error_icon} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{AccNoErrorMessage}
                                                </Alert>
                                            }
                                        >
                                            <Input
                                                autoFocus={false}
                                                type="number"
                                                pattern="\d*"
                                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                                onChange={setBankAccountHandler}
                                            />
                                        </ErrorTooltip>
                                    </FormItem>
                                </CheckMerchantCountries>
                                <CheckMerchantCountries
                                    includedCountries={[
                                        MerchantCountries.UNITED_STATES,
                                        MerchantCountries.CANADA,
                                        MerchantCountries.AUSTRALIA,
                                        MerchantCountries.MEXICO
                                    ]}
                                >
                                    <FormItem>
                                        <Label
                                            text="Confirm account number"
                                            id="confirm-account-number-label"
                                            transalterEnabled={true}
                                        />
                                        <Input
                                            onBlur={checkvalue}
                                            autoFocus={false}
                                            inputProps={{
                                                maxLength: onboardingForm.tradingAddress.newAccountNumber.length
                                            }}
                                            type={textField ? 'text' : 'number'}
                                            pattern="\d*"
                                            value={checkEmptyValue(confirmAccountNumber)}
                                            onChange={(e) => {
                                                setShow(false);
                                                setConfirmAccountNumber(e.target.value);
                                            }}
                                        />
                                    </FormItem>
                                    <Grid className={styles.showMessageStyle}>
                                        {message && (
                                            <div className={styles.notificationMessageContainer}>
                                                <WarningRounded className={styles.error_icon} color="error" />
                                                <Typography color="error" style={{ marginLeft: '10px' }}>
                                                    Your account numbers don’t match
                                                </Typography>
                                            </div>
                                        )}
                                    </Grid>
                                </CheckMerchantCountries>

                                <CheckMerchantCountries includedCountries={[MerchantCountries.NEW_ZEALAND]}>
                                    <FormItem>
                                        <Label
                                            text="Confirm account number"
                                            id="confirm-account-number-label"
                                            transalterEnabled={true}
                                        />
                                        <NewZealandAccountNumberContainer
                                            onBlur={checkvalue}
                                            hideDashes={mediaLessThan600px}
                                            value={checkEmptyValue(confirmAccountNumber)}
                                            onChange={(value) => {
                                                setShow(false);
                                                setConfirmAccountNumber(value);
                                            }}
                                            nextElementRef={accNumberInputRef}
                                        />
                                    </FormItem>
                                    <Grid className={styles.showMessageStyle}>
                                        {message && (
                                            <div className={styles.notificationMessageContainer}>
                                                <WarningRounded className={styles.error_icon} color="error" />
                                                <Typography color="error" style={{ marginLeft: '10px' }}>
                                                    Your account numbers don’t match
                                                </Typography>
                                            </div>
                                        )}
                                    </Grid>
                                </CheckMerchantCountries>
                            </React.Fragment>
                        )}

                        <FormItem>
                            <Label text="Name of bank" id="name-of-bank" />
                            <Input
                                placeholder="Name of bank"
                                id="name-of-bank-input"
                                value={onboardingForm.tradingAddress.nameOfBank}
                                onChange={(e) => {
                                    let inputValue = e.target.value.replace(/[^A-Za-z0-9.()& -]/g, '').toUpperCase();
                                    if (inputValue.length <= 80) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                nameOfBank: inputValue
                                            }
                                        });
                                        setLengthIssue(true);
                                    }
                                }}
                                onBlur={() => {
                                    let trimmedValue = onboardingForm.tradingAddress.nameOfBank
                                        .trim()
                                        .replace(/\s+/g, ' ');
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            nameOfBank: trimmedValue
                                        }
                                    });
                                }}
                                onKeyDown={(e) => {
                                    const isCharacterAllowed = !/^[a-zA-Z0-9.()&\- ]+$/.test(e.key);
                                    const isBackspace = e.key === 'Backspace';
                                    if (isCharacterAllowed && !isBackspace) e.preventDefault();
                                }}
                            />
                        </FormItem>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                            <FormItem>
                                <Label text="Bank address 1" id="bank-address-1" />
                                <Input
                                    placeholder="Bank address 1"
                                    id="bank-address-1-input"
                                    value={onboardingForm.tradingAddress.bankAddress1}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 80) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    bankAddress1: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                            <FormItem>
                                <Label text="Bank address 2" id="bank-address-2" />
                                <Input
                                    placeholder="Bank address 2"
                                    id="bank-address-2-input"
                                    value={onboardingForm.tradingAddress.bankAddress2}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 80) {
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    bankAddress2: e.target.value
                                                }
                                            });
                                        }
                                    }}
                                />
                            </FormItem>
                        </CheckMerchantCountries>
                    </Grid>
                </Grid>
                <LoadingScreen open={isLoading} />
            </React.Fragment>
        );
    }

    const title = <OnboardingTitle title="Bank details" transalterEnabled={true}></OnboardingTitle>;

    const nextBtn = (
        <NextButton
            disabled={isDisabled}
            onSubmit={() => {
                nextStep();
                onSubmit();
            }}
        ></NextButton>
    );

    return <OnboardingFormsContainer title={title} nextButton={nextBtn} content={formBody} />;
}

T2SOnboardingBankDetailsComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    setOnboardingForm: PropTypes.func,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    getCompanyDetails: PropTypes.func,
    phoneNumberValidator: PropTypes.func,
    isLoading: PropTypes.bool,
    setInputValue: PropTypes.func,
    handleSelectedCompany: PropTypes.func,
    value: PropTypes.string,
    setValue: PropTypes.func,
    inputValue: PropTypes.string,
    setOpenDropdown: PropTypes.func,
    accountCodeLength: PropTypes.string,
    setBankAccountHandler: PropTypes.func
};

export default T2SOnboardingBankDetailsComponent;
