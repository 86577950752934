import React from 'react';
import PropTypes from 'prop-types';
import { AdminService } from 'services';
import MerchnatStoreIdComponent from './merchant-store-id.component';
import { useAdminFunctionsContext, useGlobalStateContext } from 'contexts';

export default function MerchnatStoreId(props) {
    const { adminFunctions } = useAdminFunctionsContext();
    const { globalState } = useGlobalStateContext();
    const { indexId } = props;

    const onChange = () => {
        AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            externalMerchant: {
                index: indexId,
                externalMerchantId: null,
                externalMerchantStoreId: adminFunctions.merchantStoreIds[indexId]
            },
            resellerId: globalState.reseller?.id
        });
    };

    return <MerchnatStoreIdComponent {...props} onChange={onChange} />;
}

MerchnatStoreId.propTypes = {
    indexId: PropTypes.number
};
