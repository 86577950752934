import React from 'react';
import MerchantChargesListTable from '../transaction-tables/merchant-charges-list-table/merchant-charges-list-table.container';

const MerchantChargesListComponent = (props) => {
    return (
        <div>
            <MerchantChargesListTable {...props} />
        </div>
    );
};

export default MerchantChargesListComponent;
