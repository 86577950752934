import React from 'react';

import {
    Paper,
    Typography,
    makeStyles,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Button
} from '@material-ui/core';
import { MoreVert, Search, UnfoldMore, InfoOutlined } from '@material-ui/icons';
import style from './pending-customer-info.module.scss';
import LoadingScreen from 'components/loading-screen/loading-screen.container';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

function PendingCustomerInfoComponent({ rows, isLoading }) {
    const classes = useStyles();
    return (
        <>
            <TableContainer component={Paper}>
                <div className={style.info_bar}>
                    <Typography color="primary" className={style.info_bar__text}>
                        Pending customers
                    </Typography>

                    <span className={style.float_right}>
                        <Search className={style.click_effect} />
                        <MoreVert className={style.click_effect} />
                    </span>
                </div>

                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Merchant id <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Store merchant id <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Name <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                URL <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Address <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Rent <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Verify doc viewer <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Verify <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                            <TableCell>
                                Bank verify <UnfoldMore className={style.unfold_icon} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.business_detail_id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.BusinessDetail.websiteUrl}</TableCell>
                                <TableCell>{`${row.Address.addressLine1}, ${row.Address.addressLine2}, ${row.Address.city}`}</TableCell>
                                <TableCell>
                                    <Button size="small" variant="contained" color="default">
                                        Update
                                    </Button>
                                </TableCell>
                                <TableCell>{row.verifyDoc}</TableCell>
                                <TableCell>
                                    <Button size="small" color="default" variant="contained">
                                        View
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <InfoOutlined className={`${style.info_bar__icon} ${style.link_font}`} />
                                    {row.bank_verify}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <LoadingScreen open={isLoading} />
                </Table>

                <div className={style.bottom_bar}></div>
            </TableContainer>
        </>
    );
}

PendingCustomerInfoComponent.propTypes = {};

export default PendingCustomerInfoComponent;
