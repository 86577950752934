import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Paper, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TableFooterComponent from '../table-footer/table-footer.component';
import TableHeader from '../table-header/table-header.container';
import TransactionStatusLabel from '../transaction-status-label/transaction-status-label.container';
import { formatCurrency, formatDateTimeByFormatString, getComparator, stableSort } from 'utils/helper';
import { getCurrencyThreeAlpha } from 'utils/getCurrencyByCountry';
import { useGlobalStateContext } from 'contexts';
import StripPayoutTransactions from '../stripe-payout-transactions-table/stripe-payout-transactions-table.container';
import styles from '../payouts-list-table/payouts-list-table.module.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        'border-top': '1px solid #ddd',
        'margin-top': '10px'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    container: {
        maxHeight: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    referenceBatchId: {
        paddingRight: '10px',
        fontWeight: 'bold'
    },
    checkbox: {
        color: '#8783b5'
    }
}));

const StripePayoutsListTableComponent = (props) => {
    const { setRoute, globalState } = useGlobalStateContext();
    const classes = useStyles();
    const { rows, selected, order, orderBy, loading, error, totalPayouts } = props;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={styles.paymentsNone}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={styles.paymentsNone}>There aren't any results for that query.</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer className={classes.container}>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        aria-label="enhanced table"
                                    >
                                        <TableHeader
                                            classes={classes}
                                            numSelected={selected.length}
                                            rowCount={rows.length}
                                            hideSelectAll={true}
                                            {...props}
                                        />
                                        <TableBody>
                                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        classes={{ selected: classes.selected }}
                                                        className={classes.tableRow}
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={index}
                                                        onClick={() =>
                                                            setRoute(`/stripe-payout-details/${index}`, {
                                                                payouts: row
                                                            })
                                                        }
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            align="right"
                                                            width="10%"
                                                        >
                                                            {formatCurrency(
                                                                row.amount / 100,
                                                                getCurrencyThreeAlpha(globalState.merchant?.country)
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {formatDateTimeByFormatString(
                                                                moment.unix(row.created),
                                                                'DD MMM HH:mm'
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {formatDateTimeByFormatString(
                                                                moment.unix(row.arrival_date),
                                                                'DD MMM'
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <TransactionStatusLabel
                                                                status={
                                                                    row.status === 'in_transit'
                                                                        ? 'In Transit'
                                                                        : row.status
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableFooterComponent total={totalPayouts()} resultsCount={rows.length} />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>

            {props.show && <StripPayoutTransactions id={props.id} show={props.show} setShow={props.setShow} />}
        </div>
    );
};

export default StripePayoutsListTableComponent;

StripePayoutsListTableComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    totalPayouts: PropTypes.func.isRequired
};
