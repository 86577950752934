import React, { useState, useEffect } from 'react';
import ModalExportReportScheduleComponent from './modal-export-report-schedule.component.jsx';
import { PaymentsService } from 'services';
import moment from 'moment';

const ModalExportScheduleReport = (props) => {
    const {
        reportType,
        setShowReportModel,
        showReportModel,
        setShowReportScheduleModel,
        scheduleExportSnackBar,
        setScheduleExportSnackBar
    } = props;
    const [loading, setLoading] = useState(false);

    const [timeFrame, setTimeFrame] = useState('Weekly');
    const [weeklyScheduleDay, setWeeklyScheduleDay] = useState(-1);
    const [weeklyScheduleTime, setWeeklyScheduleTime] = useState('-');
    const [monthlyScheduleDay, setMonthlyScheduleDay] = useState('--Please select--');
    const [monthlyScheduleTime, setMonthlyScheduleTime] = useState('-');
    const [customScheduleFromDay, setCustomScheduleFromDay] = useState('-');
    const [customScheduleToDay, setCustomScheduleToDay] = useState('-');
    const [customScheduleOnDay, setCustomScheduleOnDay] = useState('-');
    const [customScheduleTime, setCustomScheduleTime] = useState('-');
    const [disableExportBtn, setDisableExportBtn] = useState(true);
    const [downloadFormat, setDownloadFormat] = useState('CSV');
    const handleClose = () => {
        setShowReportScheduleModel(false);
    };
    const handleDownloadFormat = (event) => {
        setDownloadFormat(event.target.value);
    };
    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    };
    useEffect(() => {
        if (timeFrame === 'Weekly') {
            if (weeklyScheduleDay === -1 || weeklyScheduleDay === '-1' || downloadFormat === '-') {
                setDisableExportBtn(true);
            } else {
                setDisableExportBtn(false);
            }
        } else if (timeFrame === 'Monthly') {
            if (monthlyScheduleDay === '--Please select--' || downloadFormat === '-') {
                setDisableExportBtn(true);
            } else {
                setDisableExportBtn(false);
            }
        } else if (timeFrame === 'Custom') {
            if (
                customScheduleFromDay === '-' ||
                customScheduleToDay === '-' ||
                customScheduleOnDay === '-' ||
                downloadFormat === '-'
            ) {
                setDisableExportBtn(true);
            } else {
                setDisableExportBtn(false);
            }
        }
        if (timeFrame === 'Custom') {
            if (
                customScheduleFromDay > customScheduleOnDay ||
                customScheduleToDay > customScheduleOnDay ||
                customScheduleFromDay > customScheduleToDay
            ) {
                setDisableExportBtn(true);
            }
        }
        // eslint-disable-next-line
    }, [
        timeFrame,
        weeklyScheduleDay,
        downloadFormat,
        monthlyScheduleDay,
        customScheduleFromDay,
        customScheduleToDay,
        customScheduleOnDay
    ]);

    const schedulePaymentsExport = async () => {
        let payload = {};
        if (timeFrame === 'Custom') {
            payload = {
                schedule_date: moment(customScheduleOnDay).format('YYYY-MM-DD'),
                from_date: moment(customScheduleFromDay).format('YYYY-MM-DD'),
                to_date: moment(customScheduleToDay).format('YYYY-MM-DD'),
                report_type: downloadFormat,
                schedule_by: timeFrame.toUpperCase()
            };
        } else {
            payload = {
                schedule_day: timeFrame === 'Weekly' ? weeklyScheduleDay.toString() : monthlyScheduleDay.toString(),
                report_type: downloadFormat,
                schedule_by: timeFrame.toUpperCase()
            };
        }
        setLoading(true);
        const response = await PaymentsService.schedulePaymentsExport(payload);
        if (response.isSuccesfully) {
            setScheduleExportSnackBar({ open: true, message: 'Your export has been scheduled', type: 'success' });
        } else {
            setScheduleExportSnackBar({ open: true, message: 'Your export has not been scheduled', type: 'error' });
        }
        setLoading(false);
        setShowReportScheduleModel(false);
    };
    let datesArray = [];
    for (var i = 0; i < 32; i++) {
        if (i === 0) {
            datesArray.push('--Please select--');
        } else if (i !== 0 && i !== 31) {
            datesArray.push(i);
        } else {
            datesArray.push('LAST DAY');
        }
    }

    return (
        <ModalExportReportScheduleComponent
            {...props}
            loading={loading}
            reportType={reportType}
            setShowReportModel={setShowReportModel}
            showReportModel={showReportModel}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            weeklyScheduleDay={weeklyScheduleDay}
            setWeeklyScheduleDay={setWeeklyScheduleDay}
            weeklyScheduleTime={weeklyScheduleTime}
            setWeeklyScheduleTime={setWeeklyScheduleTime}
            monthlyScheduleDay={monthlyScheduleDay}
            setMonthlyScheduleDay={setMonthlyScheduleDay}
            monthlyScheduleTime={monthlyScheduleTime}
            setMonthlyScheduleTime={setMonthlyScheduleTime}
            customScheduleTime={customScheduleTime}
            setCustomScheduleTime={setCustomScheduleTime}
            customScheduleFromDay={customScheduleFromDay}
            setCustomScheduleFromDay={setCustomScheduleFromDay}
            customScheduleOnDay={customScheduleOnDay}
            setCustomScheduleOnDay={setCustomScheduleOnDay}
            customScheduleToDay={customScheduleToDay}
            setCustomScheduleToDay={setCustomScheduleToDay}
            handleClose={handleClose}
            disableExportBtn={disableExportBtn}
            setDisableExportBtn={setDisableExportBtn}
            downloadFormat={downloadFormat}
            setDownloadFormat={setDownloadFormat}
            handleDownloadFormat={handleDownloadFormat}
            schedulePaymentsExport={schedulePaymentsExport}
            scheduleExportSnackBar={scheduleExportSnackBar}
            setScheduleExportSnackBar={setScheduleExportSnackBar}
            handleTimeFrameChange={handleTimeFrameChange}
            datesArray={datesArray}
        />
    );
};

export default ModalExportScheduleReport;
