import React from 'react';
import PropTypes from 'prop-types';
import PaymentConfigurationModesDialogComponent from './payment-configuration-modes-dialog.component';
import { AdminService } from 'services';
import { useGlobalStateContext, useAdminFunctionsContext } from 'contexts';

export default function PaymentConfigurationModesDialog(props) {
    const { globalState } = useGlobalStateContext();
    const { adminFunctions, setAdminFunctions } = useAdminFunctionsContext();
    const {
        testModeUpdate,
        threeDSecureUpdate,
        testModeUpdateDialogBox,
        setTestModeUpdateDialogBox,
        setLoading,
        testModeStatus,
        threeDSecureStatus
    } = props;

    const testModeUpdateInitiated = async (testModeResult) => {
        setTestModeUpdateDialogBox(false);
        setLoading(true);
        setAdminFunctions({
            ...adminFunctions,
            testMode: testModeResult
        });
        const result = testModeResult ? '1' : '0';
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            resellerId: globalState.reseller?.id,
            testMode: result
        });
        if (response.isSuccesfully) {
            setTestModeUpdateDialogBox(false);
        }
        setLoading(false);
    };
    const threeDSecureUpdateInitiated = async (threeDSecureUpdate) => {
        setTestModeUpdateDialogBox(false);
        setLoading(true);
        setAdminFunctions({
            ...adminFunctions,
            threeDSecure: threeDSecureUpdate
        });
        const result = threeDSecureUpdate ? '1' : '0';
        const response = await AdminService.UpdateMerchantDataForAdmin(globalState.merchant?.id, {
            resellerId: globalState.reseller?.id,
            threeDSecure: result
        });
        if (response.isSuccesfully) {
            setTestModeUpdateDialogBox(false);
        }
        setLoading(false);
    };

    return (
        <PaymentConfigurationModesDialogComponent
            testModeUpdate={testModeUpdate}
            threeDSecureUpdate={threeDSecureUpdate}
            testModeUpdateDialogBox={testModeUpdateDialogBox}
            setTestModeUpdateDialogBox={setTestModeUpdateDialogBox}
            testModeUpdateInitiated={testModeUpdateInitiated}
            threeDSecureUpdateInitiated={threeDSecureUpdateInitiated}
            testModeStatus={testModeStatus}
            threeDSecureStatus={threeDSecureStatus}
        />
    );
}

PaymentConfigurationModesDialog.propTypes = {
    testModeUpdate: PropTypes.bool,
    threeDSecureUpdate: PropTypes.bool,
    testModeUpdateDialogBox: PropTypes.bool,
    setTestModeUpdateDialogBox: PropTypes.func,
    testModeStatus: PropTypes.bool,
    threeDSecureStatus: PropTypes.bool,
    setLoading: PropTypes.func
};
