import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CircularProgress
} from '@material-ui/core';
import styles from './modal-close-merchant-account.module.scss';
import Alert from '@material-ui/lab/Alert';
import { useGlobalStateContext } from 'contexts';

function ModalCloseMerchantAccountComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { setOpen, onCloseMerchant, isError, isLoading } = props;
    const merchantName = globalState.merchant?.name;

    return (
        <Dialog open={true} onClose={() => setOpen(false)} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Close account
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>
                        After confirming your request to close account {merchantName} a member of our team will be in
                        touch to walk you through the process. You may need to return devices or terminals, and you
                        could still be liable for chargebacks received up to 12 months from the date of closure.
                    </div>
                    {isError && (
                        <Alert className={styles.alertError} severity="error">
                            Cannot close account. Please contact support or try again.
                        </Alert>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={styles.actionsContainer}>
                {isLoading && <CircularProgress className={styles.loading} color="inherit" />}
                <Button
                    id="cancelButtonCloseMerchant"
                    onClick={() => setOpen(false)}
                    color="primary"
                    variant="contained"
                >
                    Cancel
                </Button>
                <Button
                    id="createButtonCloseMerchant"
                    onClick={() => onCloseMerchant()}
                    color="primary"
                    variant="contained"
                >
                    Request to close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalCloseMerchantAccountComponent.propTypes = {
    setOpen: PropTypes.func,
    onCloseMerchant: PropTypes.func,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default ModalCloseMerchantAccountComponent;
