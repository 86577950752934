import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Button, DialogContentText, Divider } from '@material-ui/core';
import styles from './modal-pdq-confirmation.module.scss';

function ModalPDQConfirmationComponent(props) {
    const { show, setShow, confirm, dialogText, modalLoading } = props;

    return (
        <Dialog
            open={show}
            onClose={() => setShow(false)}
            className={{
                paper: styles.paper
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            max-width="false"
        >
            <DialogContent
                classes={{
                    root: styles.dialogContent
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    <p className={styles.dialogText}>{dialogText}</p>
                </DialogContentText>
                <Divider />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        confirm();
                    }}
                    variant="contained"
                    className={styles.leaveBtn}
                    disabled={modalLoading}
                >
                    {modalLoading ? 'Please wait...' : 'Yes'}
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        setShow(false);
                    }}
                    color="primary"
                    className={styles.dialogBtn}
                >
                    NO
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModalPDQConfirmationComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.string
};

export default ModalPDQConfirmationComponent;
