import { isCardValid } from '../../utils/validator';

const initialState = {
    cardNumber: '#### #### #### ####',
    cardHolder: '',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    cardHolderAddress: '',
    isCardFlipped: false,

    isFormDataValid: {
        cardNumber: null,
        cardHolder: null,
        cardMonth: null,
        cardYear: null,
        cardCvv: null
    },

    amount: '',
    description: '',

    showIframe: false,

    acsUrl: '',
    paReq: '',
    md: '',
    paRes: '',

    showModal: false,
    paymentSuccess: null,
    showLoadingSpinner: true,

    currentFocusedElm: null
};

const TakePaymentsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CARD_NUMBER': {
            const { cardNumber } = action;
            const isCardNumberValid = isCardValid(cardNumber);

            return {
                ...state,
                cardNumber,
                isFormDataValid: {
                    ...state.isFormDataValid,
                    cardNumber: isCardNumberValid
                }
            };
        }

        case 'SET_CARD_DETAILS': {
            const { name, value } = action;

            return {
                ...state,
                [name]: value
            };
        }

        case 'SET_CARD_FLIPPED': {
            return {
                ...state,
                isCardFlipped: action.isCardFlipped
            };
        }

        case 'SET_3D_SECURE_DETAILS':
            return {
                ...state,
                acsUrl: action.acsUrl,
                paReq: action.paReq,
                md: action.md,
                showIframe: true
            };

        case 'SET_MODAL_STATE':
            return {
                ...state,
                showModal: action.showModal,
                paymentSuccess: action.paymentSuccess,
                showLoadingSpinner: action.showLoadingSpinner
            };

        case 'INIT_FINAL_SALE':
            return {
                ...state,
                showModal: action.showModal,
                showLoadingSpinner: action.showLoadingSpinner,
                showIframe: false,
                paRes: action.paRes,
                md: action.md
            };

        case 'SET_CURRENT_FOCUSED_ELEMENT':
            return {
                ...state,
                currentFocusedElm: action.currentFocusedElm
            };

        default:
            return { ...state };
    }
};

export default { initialState, TakePaymentsReducer };
