import React, { useState } from 'react';
import QrDetailedViewComponent from './qr-detailed-view-component';

const QrDetailedView = (props) => {
    const {
        previewQrDetails,
        qrDetails,
        setQrDetails,
        setIsPaneOpen,
        showQrCode,
        setShowQrCode,
        download,
        CloseSuccess,
        setCloseSuccess,
        setPreviewQrDetails
    } = props;
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const closeSlider = () => {
        setIsPaneOpen(false);
    };

    return (
        <QrDetailedViewComponent
            setShowQrCode={setShowQrCode}
            showQrCode={showQrCode}
            previewQrDetails={previewQrDetails}
            download={download}
            setShowConfirmationModal={setShowConfirmationModal}
            showConfirmationModal={showConfirmationModal}
            qrDetails={qrDetails}
            setQrDetails={setQrDetails}
            closeSlider={closeSlider}
            CloseSuccess={CloseSuccess}
            setCloseSuccess={setCloseSuccess}
            setPreviewQrDetails={setPreviewQrDetails}
        />
    );
};

export default QrDetailedView;
