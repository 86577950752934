import { threeDSRedirectUrl } from '../../utils/constants';

const getSource = (acs, md, paReq) => `<!DOCTYPE html PUBLIC '-//W3C//DTD HTML 4.01//EN'>
<html>
  <head>
    <title>Redirecting...</title>
    <meta http-equiv="Expires" content="-1" />
    <meta http-equiv="Cache-Control" content="no-cache" />
    <meta http-equiv="Pragma" content="no-cache" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  </head>
  <body class="threeDS">
    <form
      class="threeDS"
      id="threeDS"
      target="_self"
      method="POST"
      action="${acs}"
    >
      <input
        type="hidden"
        name="MD"
        value="${md}"
      />
      <input
        type="hidden"
        name="PaReq"
        value="${paReq}"
      />
      <input
        type="hidden"
        name="TermUrl"
        value="${threeDSRedirectUrl}"
      />
      <input type="submit" style="visibility: hidden;" />
    </form>
    <script type="text/javascript" defer="defer">
      window.setTimeout('document.forms.threeDS.submit()', 0);
    </script>
  </body>
</html>`;

export default getSource;
