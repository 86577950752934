import React, { useState, useEffect } from 'react';
import DnaFormModalComp from './dna-form-modal.component';
import { InputValidators } from 'validators';
function DnaFormModal(props) {
    const [isEdit, setIsEdit] = useState(false);
    const [disable, setDisable] = useState(false);
    const { data, setData, pdqFee, setPdqFee, onboardedMerchant, businessTypeId, isAdmin } = props;
    useEffect(() => {
        let checkValues;
        const bankDetails = isAdmin
            ? data.newAccountNumber?.length === 8 &&
              data.nameOfBank &&
              data.sortCode?.length === 6 &&
              data.accountHolderName
            : true;
        onboardedMerchant
            ? (checkValues =
                  data.fullName &&
                  InputValidators.isEmailValid(data.email) &&
                  data.postCode &&
                  data.city &&
                  InputValidators.validateInternationPhoneNumber('+' + data.contactPhone) &&
                  data.addressLine1 &&
                  pdqFee.pdqQty > 0)
            : (checkValues =
                  data.fullName &&
                  InputValidators.isEmailValid(data.email) &&
                  data.postCode &&
                  data.city &&
                  InputValidators.validateInternationPhoneNumber('+' + data.contactPhone) &&
                  data.addressLine1 &&
                  data.ownership &&
                  data.ownership > 0 &&
                  bankDetails &&
                  pdqFee.pdqQty > 0 &&
                  (businessTypeId === 1 ? data.registeredNumber : true));

        setDisable(!checkValues);
        //eslint-disable-next-line
    }, [data, pdqFee.pdqQty]);
    const handleChange = (field, value) => {
        const tempData = data;
        if (field === 'registeredNumber') {
            const regNumberCheckRegex = new RegExp('^[a-zA-Z0-9&]*$');

            if (value.match(regNumberCheckRegex) || value === '') {
                setData({ ...tempData, [field]: value });
            } else {
                setData({ ...tempData, [field]: data.registeredNumber });
            }
        } else {
            setData({ ...tempData, [field]: value });
        }
    };

    function changeFee(field, value) {
        const tempData = pdqFee;
        setPdqFee({ ...tempData, [field]: value });
    }
    const handleIncrement = () => {
        setPdqFee({ ...pdqFee, pdqQty: Number(pdqFee.pdqQty) + 1 });
    };

    const handleDecrement = () => {
        setPdqFee({ ...pdqFee, pdqQty: Number(pdqFee.pdqQty) - 1 });
    };
    const maxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 9999999.99;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };
    const pdqPrice = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 9999.99;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };
    const isMaxPercentage = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 100.0;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };
    const isMaxDevice = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 10;
        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };
    return (
        <DnaFormModalComp
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            newData={data}
            handleChange={handleChange}
            pdqFee={pdqFee}
            changeFee={changeFee}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            maxAmount={maxAmount}
            isMaxPercentage={isMaxPercentage}
            disable={disable}
            isMaxDevice={isMaxDevice}
            pdqPrice={pdqPrice}
            {...props}
        />
    );
}
export default DnaFormModal;
