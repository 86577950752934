import React from 'react';
import PropTypes from 'prop-types';
import style from './pending-verification-list.module.scss';
import { formatDateTimeByFormatString, getComparator, stableSort } from 'utils/helper';
import { Calendar } from 'components';
import TableHeader from 'components/payments/transaction-tables/table-header/table-header.container';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Paper,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    makeStyles,
    Select,
    Button,
    InputLabel,
    MenuItem,
    TextField,
    Typography,
    Grid,
    FormControl
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MerchantStatusNames } from 'utils/enums/MerchantStatusNames';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            padding: '6px 14px 6px 14px'
        },
        '& th': {
            padding: '6px 14px 6px 14px'
        }
    }
}));

function PendingVerificationListComponent(props) {
    const { pathname } = props.location;
    const {
        loading,
        error,
        page,
        rowsPerPage,
        rows,
        handleChangePage,
        handleChangeRowsPerPage,
        count,
        order,
        countryFilter,
        setCountryFilter,
        orderBy,
        onGoToAccount,
        setInputValue,
        onSearchMerchants,
        onChangeDateRange,
        daterange,
        setDateRange,
        headCells,
        handleCountryFilter
    } = props;
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container xs={12} className={style.container}>
                <Typography color="primary" component="span">
                    <span className={style.contractTitle}>
                        {pathname.includes('pending-account-verification-list')
                            ? `Pending Account Verification (${count})`
                            : `Pending Bank Verification (${count})`}
                    </span>
                </Typography>

                <form className={style.search_form} onSubmit={onSearchMerchants}>
                    <TextField
                        className={style.searchMerchants}
                        id="pending-merchants-search"
                        size="small"
                        label="Search"
                        type="search"
                        variant="outlined"
                        autoComplete="off"
                        autoFocus
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <Button
                        id="pending-merchants-search-button"
                        className={style.searchButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={onSearchMerchants}
                    >
                        Search
                    </Button>
                </form>
            </Grid>
            <Grid container xs={12} className={style.filterContainer}>
                <FormControl variant="outlined" className={style.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label" className={style.inputlabel}>
                        Filter
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Filter"
                        value={countryFilter}
                        onChange={handleCountryFilter}
                        className={style.selectInput}
                    >
                        <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'Australia'}>Australia</MenuItem>
                        <MenuItem value={'United States'}>United States</MenuItem>
                        <MenuItem value={'United Kingdom'}>United Kingdom</MenuItem>
                        <MenuItem value={'Mexico'}>Mexico</MenuItem>
                        <MenuItem value={'Canada'}>Canada</MenuItem>
                        <MenuItem value={'New Zealand'}>New Zealand</MenuItem>
                        <MenuItem value={'Ireland'}>Ireland</MenuItem>
                    </Select>
                </FormControl>
                <Grid>
                    <Calendar
                        {...props}
                        path="pending-bank-verification-list"
                        range="range"
                        current={pathname}
                        date={
                            daterange.start && daterange.end
                                ? `${daterange.start.format('DD MMM YY')} - ${daterange.end.format('DD MMM YY')}`
                                : ''
                        }
                        onChangeDateRange={onChangeDateRange}
                        nextPerviousHideButton={true}
                        daterange={daterange}
                        offrange={true}
                        setDaysRange={setDateRange}
                    />
                </Grid>
            </Grid>
            <Paper className={style.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <div className={style.noData}>An error occurred, please try reloading your browser.</div>
                ) : (
                    <React.Fragment>
                        {!rows || rows.length === 0 ? (
                            <div className={style.noData}>No pending data</div>
                        ) : (
                            <React.Fragment>
                                <TableContainer className={style.TableContainer}>
                                    <Table
                                        aria-label="simple table"
                                        aria-labelledby="tableTitle"
                                        size="small"
                                        stickyHeader
                                        component={Paper}
                                    >
                                        {/* Ensure TableHeader is properly rendered with headCells */}
                                        <TableHeader classes={classes} headCells={headCells} {...props} />

                                        <TableBody>
                                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => (
                                                <TableRow key={row.MerchantId || index}>
                                                    <TableCell className={style.tableDataCell}>
                                                        <Link onClick={() => onGoToAccount(row.id)}>
                                                            <span> {row.id}</span>
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell className={style.tableDataCell}>{row.name}</TableCell>
                                                    <TableCell className={style.tableDataCell}>{row.country}</TableCell>
                                                    <TableCell className={style.tableDataCell}>
                                                        {formatDateTimeByFormatString(row.date, 'DD MMM YYYY HH:mm')}
                                                    </TableCell>
                                                    <TableCell className={style.tableDataCell}>
                                                        {MerchantStatusNames[row.status]}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                    colSpan={8}
                                                    count={count}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: { 'aria-label': 'rows per page' },
                                                        native: true
                                                    }}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Paper>
        </React.Fragment>
    );
}

PendingVerificationListComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    rows: PropTypes.array,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    count: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
    userName: PropTypes.string,
    setInputValue: PropTypes.func,
    onSearchMerchants: PropTypes.func,
    onChangeDateRange: PropTypes.func,
    daterange: PropTypes.object,
    offrange: PropTypes.bool,
    setOffrange: PropTypes.func,
    setDaysRange: PropTypes.func,
    headCells: PropTypes.array.isRequired
};

export default PendingVerificationListComponent;
