import React, { useState } from 'react';
import OnboardingTransactionProfileStepsContainerComponent from './onboarding-transaction-profile-steps-container.component';

function OnboardingTransactionProfile(props) {
    const [subStep, setSubStep] = useState(1);
    const oneHundredValues = [...Array(101).keys()].map((item) => item);

    const nextSubStep = () => {
        setSubStep((step) => step + 1);
    };

    return (
        <OnboardingTransactionProfileStepsContainerComponent
            {...props}
            subStep={subStep}
            nextSubStep={nextSubStep}
            oneHundredValues={oneHundredValues}
        />
    );
}

export default OnboardingTransactionProfile;
