import React from 'react';
import { Route } from 'react-router-dom';
import RoutingManager from './routing-manager';
import Dashboard from '../pages/dashboard/dashboard.container';
import Profile from '../pages/profile/profile.container';
import RolesAndPermissions from '../pages/roles-and-permissions/roles-and-permissions.container';
import UserManagement from '../pages/user-management/user-management.container';
import Settings from '../pages/settings/settings.container';
import Team from '../pages/team/team.container';
import PaymentList from '../pages/payments/payments.container';
import ChargebackList from '../pages/chargebacks/chargebacks.container';
import MerchantChargesList from '../pages/merchant-charges/merchant-charges.container';
import InternalTransfersList from '../pages/internal-transfers/internal-transfers.container';
import PayoutsList from '../pages/payouts/payouts.container';
import GfoPaymentsList from '../pages/gfo-payments/gfo-payments.container';
import VirtualTerminal from '../pages/virtual-terminal/virtual-terminal.container';
import CardDetails from '../pages/card-details/card-details.container';
import AcceptInvitation from '../pages/accept-invitation/accept-invitation.container';
import AdminDashboard from '../pages/admin/dashboard/admin-dashboard.container';
import PendingCustomers from '../pages/admin/pending-customers/pending-customers.container';
import UpdateBank from '../pages/update-bank/update-bank.container';
import UpdateAccount from '../pages/update-account/update-account.container';
import GenericQRCode from '../pages/generic-qr-code/generic-qr-code.container';
import PaymentQRCode from '../pages/payment-qr-code/payment-qr-code.container';
import UpdatePassword from '../pages/update-password/update-password.container';
import AccountVerifyUploadDocuments from '../pages/account-verify-upload-documents/account-verify-upload-documents.container';
import PaymentDetails from 'pages/payment-details/payment-details.container';
import PayoutDetails from 'pages/payout-details/payout-details.container';
import StripePayoutDetails from 'pages/stripe-payouts-details/stripe-payout-details.container';
import PayoutsNotReceived from 'pages/payout-not-recieved/payout-not-received.container';

import AdminFunctions from 'pages/admin/admin-functions/admin-functions.container';
import PdqTransactions from 'pages/pdq-transactions/pdq-transactions.container';
import { AdminFunctionsProvider } from 'contexts';
import AdminUserTypeRoute from './admin-user-type-route';
import LoggedRoute from './logged-route';
import WorkingAccountRoute from './working-account-route';
import RestrictedAdminUserTypeRoute from './restricted-admin-user-type-route';
import TransactionPageAccessibilityRoute from './transaction-page-accessibility-route';
import MerchantManager from './merchant-routing-manager';
import ManageProducts from 'pages/manage-products/manage-products.container';
import BunkAccountUploadingDocumentsContainer from 'pages/bank-verification-upload-documents/bank-verification-upload-documents.container';
import AdminPendingMerchants from 'pages/admin/admin-pending-merchants/admin-pending-merchants.container';
import OwnerAndActiveMerchantOrAdminRoute from './owner-and-active-merchant-route';
import OwnerOrAdminRoute from './owner-or-admin-route';
import DashboardReseller from 'pages/dashboard-reseller/dashboard-reseller.container';
import ClientRegister from 'pages/client-registration/client-registration.container';
import MerchantDetails from 'pages/merchant-details/merchant-details.container';
import CanonicalResellerOnboarding from 'pages/canonical-reseller-onboarding/canonical-reseller-onboarding.container';
import canonicalResellerMerchant from 'pages/canonicalResellerMerchant/canonical-reseller-merchant.container';
import CanonicalResellerMerchantManagement from 'pages/canonical-reseller-merchant-management/canonical-reseller-merchant-management.container';
import SettingTermsAndConditions from 'pages/settings-terms-and-conditions/settings-terms-and-conditions.container';
import DocumentManagement from 'pages/admin/document-management/document-management.container';
import AdminMissingMerchantContainer from 'pages/admin/admin-missing-merchant/admin-missing-merchant.container';
import MissingDocument from 'pages/admin/missing-merchant-docoument/missing-merchant-document.container';
import ProductApprovedRoute from './product-approved-route';
import { ProductsRequired } from 'utils/enums/ProductsRequired';
import DashboardPdq from 'pages/dashboard-pdq/dashboard-pdq.container';

import RefundList from 'pages/refund-list/list-refund.container';
import ChargeBacks from 'pages/chargeback-list/chargeback.list.container';
import ContractDetails from 'pages/contract-details/contract-details.container';
import PrintAgreementContainer from 'pages/print-agreement/print-agreement.container';
import PdqOrderMerchantsContainer from 'pages/pdq-order-merchant/pdq-order-merchant.container';
import BankErrorList from 'pages/bank-error-list/bank-error-list.container';
import PendingBankVerificationList from 'pages/admin/admin-pending-bank-verfication/admin-pending-bank-verification.container';
import PendingAccountVerificationList from 'pages/admin/admin-pending-account-verification/admin-pending-account-verification.container';

export default [
    <RoutingManager
        key="home"
        path="/home"
        merchantComponent={Dashboard}
        resellerComponent={DashboardReseller}
        pdqComponent={DashboardPdq}
    ></RoutingManager>,
    <MerchantManager key="profile" path="/profile" component={Profile} />,
    <MerchantManager key="roles-permissions" path="/roles-permissions" component={RolesAndPermissions} />,
    <MerchantManager key="users" path="/users" component={UserManagement} />,
    <WorkingAccountRoute key="settings" path="/settings" component={Settings} />,
    <LoggedRoute key="team" path="/team" component={Team} />,
    <WorkingAccountRoute key="payment-list" path="/payments/:via/:date" component={PaymentList} />,
    <WorkingAccountRoute key="chargeback-list" path="/chargebacks/:via/:date" component={ChargebackList} />,
    <WorkingAccountRoute
        key="merchant-charges-list"
        path="/merchant-charges/:via/:date"
        component={MerchantChargesList}
    />,
    <WorkingAccountRoute key="payouts-list" path="/payouts/:via/:date" component={PayoutsList} />,
    <WorkingAccountRoute key="gfoPayments-list" path="/gfo-payments/:via/:date" component={GfoPaymentsList} />,

    <TransactionPageAccessibilityRoute
        key="internal-transfers-list"
        path="/internal-transfers/:via/:date"
        component={InternalTransfersList}
    />,
    <ProductApprovedRoute
        key="pdq-transactions"
        path="/pdq-transactions/:via/:date"
        component={PdqTransactions}
        productName={ProductsRequired.PDQ}
    />,
    <ProductApprovedRoute
        key="vt"
        exact
        path="/virtual-terminal"
        component={VirtualTerminal}
        productName={ProductsRequired.Virtual_Terminal}
    />,
    <ProductApprovedRoute
        key="cardDetails"
        path="/card-details"
        component={CardDetails}
        productName={ProductsRequired.Virtual_Terminal}
    />,
    <Route key="acceptInvitation" path="/accept-invitation" component={AcceptInvitation} />,
    <WorkingAccountRoute key="update-password" path="/update-password" component={UpdatePassword} />,
    <LoggedRoute key="canonical-reseller" path="/create-merchant" component={canonicalResellerMerchant} />,
    <WorkingAccountRoute
        key="setting-terms-and-conditions"
        path="/setting-terms-and-conditions"
        component={SettingTermsAndConditions}
    />,

    <WorkingAccountRoute
        key="accountVerifyUploadDocuments"
        path="/upload-bank-documents"
        component={BunkAccountUploadingDocumentsContainer}
    />,
    <OwnerOrAdminRoute
        key="accountVerifyUploadDocuments"
        path="/upload-documents"
        component={AccountVerifyUploadDocuments}
    />,

    <LoggedRoute key="payout-details" path="/payout-details/:id" component={PayoutDetails} />,
    <LoggedRoute key="stripe-payout-details" path="/stripe-payout-details/:id" component={StripePayoutDetails} />,
    <LoggedRoute key="payment-details" path="/payment-details/:id" component={PaymentDetails} />,
    <LoggedRoute key="merchant-pdq" path="/merchantpdq" component={DashboardPdq} />,

    <AdminFunctionsProvider key="admin-functions" path="/admin-functions/:merchantId">
        <RestrictedAdminUserTypeRoute path="/admin-functions/:merchantId" component={AdminFunctions} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="print-agreement" path="/print-agreement">
        <RestrictedAdminUserTypeRoute path="/print-agreement" component={PrintAgreementContainer} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="bank-error-list" path="/bank-error-list">
        <AdminUserTypeRoute path="/bank-error-list" component={BankErrorList} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="admin-pending-merchants" path="/admin-pending-merchants">
        <AdminUserTypeRoute component={AdminPendingMerchants} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="admin-missing-merchant" path="/admin-missing-merchant">
        <AdminUserTypeRoute component={AdminMissingMerchantContainer} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="missing-merchant-document" path="/missing-merchant-document/:merchantId">
        <AdminUserTypeRoute path="/missing-merchant-document/:merchantId" component={MissingDocument} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="pending-bank-verification" path="/pending-bank-verification-list">
        <AdminUserTypeRoute path="/pending-bank-verification-list" component={PendingBankVerificationList} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="pending-account-verification" path="/pending-account-verification-list">
        <AdminUserTypeRoute path="/pending-account-verification-list" component={PendingAccountVerificationList} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="client-register" path="/client-register">
        <AdminUserTypeRoute component={ClientRegister} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="merchantDetails" path="/merchant-details/:merchantId">
        <LoggedRoute path="/merchant-details/:merchantId" component={MerchantDetails} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="canonicalResellerOnboarding" path="/canonical-reseller-onboarding">
        <AdminUserTypeRoute component={CanonicalResellerOnboarding} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider
        key="canonicalResellerMerchantManagement"
        path="/canonical-reseller-merchant-management/:canonicalResellerId"
    >
        <AdminUserTypeRoute
            path="/canonical-reseller-merchant-management/:canonicalResellerId"
            component={CanonicalResellerMerchantManagement}
        />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="contract-details" path="/contract-details">
        <RestrictedAdminUserTypeRoute component={ContractDetails} />
    </AdminFunctionsProvider>,

    <AdminFunctionsProvider key="document-management" path="/document-management/:merchantId">
        <RestrictedAdminUserTypeRoute path="/document-management/:merchantId" component={DocumentManagement} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="pdq-order-merchant" path="/pdq-order-merchant/:merchantId">
        <LoggedRoute path="/pdq-order-merchant/:merchantId" component={PdqOrderMerchantsContainer} />
    </AdminFunctionsProvider>,

    <AdminFunctionsProvider key="refund-list" path="/refund-list/:via/:date">
        <AdminUserTypeRoute path="/refund-list/:via/:date" component={RefundList} />
    </AdminFunctionsProvider>,
    <AdminFunctionsProvider key="chargebacks" path="/list-chargebacks/:via/:date">
        <AdminUserTypeRoute path="/list-chargebacks/:via/:date" component={ChargeBacks} />
    </AdminFunctionsProvider>,

    <AdminFunctionsProvider key="payouts-not-received" path="/payouts-not-received">
        <AdminUserTypeRoute path="/payouts-not-received" component={PayoutsNotReceived} />
    </AdminFunctionsProvider>,
    <OwnerAndActiveMerchantOrAdminRoute key="update-bank" path="/update-bank" component={UpdateBank} />,
    <OwnerAndActiveMerchantOrAdminRoute key="update-account" path="/update-account" component={UpdateAccount} />,
    <OwnerAndActiveMerchantOrAdminRoute key="manage-products" path="/manage-products" component={ManageProducts} />,
    <OwnerAndActiveMerchantOrAdminRoute key="generic-qr-code" path="/generic-qr-code" component={GenericQRCode} />,
    <OwnerAndActiveMerchantOrAdminRoute key="payment-qr-code" path="/payment-qr-code" component={PaymentQRCode} />,

    <LoggedRoute key="admin" path="/admin" component={AdminDashboard} />,
    <LoggedRoute key="pending-customers" path="/pending-customers" component={PendingCustomers} />
];
