import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@material-ui/core';
import { Label } from 'components/onboarding/onboarding-ui-components';
import styles from './modal-create-fee-tier.module.scss';
import NumberFormat from 'react-number-format';
import { LoadingScreen } from 'components';
import { formatToFixesTwo } from 'utils/helper';

function ModalCreateFeeTierComponent(props) {
    const {
        setShowCreateFeeTier,
        showCreateFeeTier,
        setFeeTierData,
        feeTierData,
        onClickAdd,
        isDisable,
        isPercentageFee,
        isLoading
    } = props;

    return (
        <Dialog
            open={showCreateFeeTier}
            className={styles.feeTierDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => setShowCreateFeeTier(false)}
        >
            <DialogTitle>
                <div className={styles.feeTierModalTitle}>
                    <span className={styles.headingTitle}>Create Fee Tier</span>
                    <div className={styles.headerDivider}></div>
                </div>
            </DialogTitle>
            <DialogContent
                style={{ justifyContent: 'center' }}
                classes={{
                    root: styles.dialogContent
                }}
            >
                <div>
                    <Grid container xs={12} md={12} justify="space-between" className={styles.percentageFeeStyle}>
                        <Grid item xs={8} md="auto">
                            <Label text="Percentage Fee" id="percentage-fee"></Label>
                        </Grid>
                        <Grid item xs={12} md="auto">
                            <NumberFormat
                                className={styles.inputFeeTierValue}
                                id="percent-fee-input"
                                pattern="\d*"
                                allowLeadingZeros={true}
                                allowNegative={false}
                                isNumericString={true}
                                decimalScale={2}
                                autoFocus
                                fixedDecimalScale={2}
                                placeholder={`0.00`}
                                inputProps={{ inputMode: 'numeric' }}
                                value={feeTierData.percentageFee}
                                onChange={(event) => {
                                    setFeeTierData({ ...feeTierData, percentageFee: event.target.value });
                                }}
                                onBlur={(event) => {
                                    setFeeTierData({ ...feeTierData, percentageFee: (+event.target.value).toFixed(2) });
                                }}
                                isAllowed={isPercentageFee}
                                aria-describedby="outlined-weight-helper-text"
                                labelWidth={0}
                                required
                            />
                            <div className={styles.hintMessage}>Min: 0.00% and Max: 20%</div>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} md={12} justify="space-between" className={styles.fixedFeeStyle}>
                        <Grid item xs={8} md="auto">
                            <Label text="Fixed Fee" id="fixed-fee"></Label>
                        </Grid>
                        <Grid item xs={12} md="auto">
                            <NumberFormat
                                className={styles.inputFixedFeeValue}
                                id="fixed-fee-input"
                                allowLeadingZeros={true}
                                allowNegative={false}
                                format={formatToFixesTwo}
                                isNumericString={false}
                                decimalScale={2}
                                fixedDecimalScale={2}
                                placeholder={`0.00`}
                                inputProps={{ inputMode: 'numeric' }}
                                value={feeTierData.fixedFee}
                                onChange={(event) => {
                                    setFeeTierData({ ...feeTierData, fixedFee: event.target.value });
                                }}
                                onBlur={(event) => {
                                    setFeeTierData({ ...feeTierData, fixedFee: (+event.target.value).toFixed(2) });
                                }}
                                required
                            />
                            <div className={styles.hintMessage}>Min Fixed: 0.10 and Max Fixed: 0.99</div>
                        </Grid>
                    </Grid>

                    <DialogActions>
                        <div className={styles.btnContainer}>
                            <Button
                                className={styles.actionBtn}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setShowCreateFeeTier(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={styles.dialogBtn}
                                variant="contained"
                                color="primary"
                                onClick={onClickAdd}
                                disabled={isDisable}
                            >
                                Add
                            </Button>
                        </div>
                    </DialogActions>
                </div>
                <LoadingScreen open={isLoading} />
            </DialogContent>
        </Dialog>
    );
}

ModalCreateFeeTierComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.string
};

export default ModalCreateFeeTierComponent;
