import React, { useState, useEffect, useRef } from 'react';
import OnboardingAdressStepComponent from './onboarding-address.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService, BankService } from 'services';
import { validateBankAccountFields } from 'validators';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { BankAccountStatus } from 'utils/enums/BankAccountStatus';

function OnboardingAdressStep(props) {
    const {
        onboardingForm,
        setOnboardingForm,
        oldSortCode,
        setOldSortCode,
        oldAccountNumber,
        setOldAccountNumber
    } = useOnboardingFormContext();
    const [isFormValid, setIsFormValid] = useState(true);
    const [statusValidationBankAccount, setStatusValidationBankAccount] = useState(0);
    const { globalState } = useGlobalStateContext();
    const isT2SMerchant = globalState.isT2SMerchant;
    const [isBankDetailsNotFound, setIsBankDetailsNotFound] = useState(false);
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const accNumberInputRef = useRef(null);

    useEffect(() => {
        const checkDisabled = async () => {
            setStatusValidationBankAccount(0);
            const {
                postCode,
                addressLine1,
                city,
                country,
                accountHolderName,
                newAccountNumber
            } = onboardingForm.tradingAddress;

            const checkBankAccount = validateBankAccountFields(
                globalState.merchant?.country,
                onboardingForm.tradingAddress,
                isT2SMerchant ? confirmAccountNumber : newAccountNumber
            );

            const checkValues =
                postCode &&
                addressLine1 &&
                city &&
                (globalState.merchant.country === MerchantCountries.AUSTRALIA || country) &&
                checkBankAccount &&
                (isT2SMerchant || accountHolderName);

            setIsFormValid(checkValues);
        };

        checkDisabled();
    }, [
        onboardingForm.tradingAddress,
        setOnboardingForm,
        oldSortCode,
        setOldSortCode,
        oldAccountNumber,
        setOldAccountNumber,
        globalState.merchant.country,
        isT2SMerchant,
        confirmAccountNumber
    ]);

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                tradingAddress: isFormValid ? true : false
            }
        }));
    }, [isFormValid, setOnboardingForm]);

    useEffect(() => {
        const validateUKBankDetails = async () => {
            const { sortCode, newAccountNumber } = onboardingForm.tradingAddress;
            let checkValidBankAccount = false;
            if (newAccountNumber?.length === 8 && sortCode?.length === 6) {
                setOldAccountNumber(newAccountNumber);
                setOldSortCode(sortCode);

                const response = await BankService.validateBankDetailsForUK({
                    accountNumber: newAccountNumber,
                    sortCode: sortCode
                });
                if (response.isSuccesfully) {
                    checkValidBankAccount = response.data.IsCorrect;
                }

                setIsBankDetailsNotFound(!checkValidBankAccount);
                setStatusValidationBankAccount(checkValidBankAccount ? 1 : 2);
            } else {
                setIsBankDetailsNotFound(false);
                setStatusValidationBankAccount(0);
            }
        };
        if (globalState.merchant?.country === MerchantCountries.UNITED_KINGDOM) {
            validateUKBankDetails();
        }
        // eslint-disable-next-line
    }, [onboardingForm.tradingAddress.newAccountNumber, onboardingForm.tradingAddress.sortCode]);

    useEffect(() => {
        const { nameAndAddress, tradingAddress } = onboardingForm;
        const isSameAsBusinessAddress =
            nameAndAddress.postCode === tradingAddress.postCode &&
            nameAndAddress.addressLine1 === tradingAddress.addressLine1 &&
            nameAndAddress.addressLine2 === tradingAddress.addressLine2;
        const merchantCountry = globalState.merchant?.country;

        if (onboardingForm.tradingAddress.isSameAsBusinessAddress !== isSameAsBusinessAddress) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    isSameAsBusinessAddress: isSameAsBusinessAddress
                }
            }));
        }

        if (!onboardingForm.tradingAddress.country && merchantCountry === MerchantCountries.AUSTRALIA) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    country: merchantCountry
                }
            }));
        }
        // eslint-disable-next-line
    }, [onboardingForm.nameAndAddress, onboardingForm.tradingAddress]);

    const onChangeSameAddress = (checked) => {
        if (checked) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    addressLine1: onboardingForm.nameAndAddress.addressLine1,
                    addressLine2: onboardingForm.nameAndAddress.addressLine2,
                    postCode: onboardingForm.nameAndAddress.postCode,
                    city: onboardingForm.nameAndAddress.city,
                    country: onboardingForm.nameAndAddress.country,
                    isSameAsBusinessAddress: checked
                }
            }));
        } else {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    addressLine1: '',
                    addressLine2: '',
                    postCode: '',
                    city: '',
                    country: '',
                    isSameAsBusinessAddress: checked
                }
            }));
        }
    };

    const selectAddressData = (addressData) => {
        if (addressData) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                tradingAddress: {
                    ...onboardingForm.tradingAddress,
                    addressLine1: addressData.line_1,
                    addressLine2: addressData.line_2,
                    city: addressData.town_or_city,
                    country: addressData.country
                }
            }));
        }
    };

    const onSubmit = async () => {
        props.nextStep();

        const status =
            globalState.merchant?.country !== MerchantCountries.UNITED_KINGDOM
                ? BankAccountStatus.UNVERIFIED
                : statusValidationBankAccount === 1
                ? BankAccountStatus.PASSED
                : BankAccountStatus.FAILED;
        const data = { ...onboardingForm.tradingAddress, status: status };
        if (isT2SMerchant) {
            data.accountHolderName = globalState.merchant.name;
        }
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 3, data);
    };

    const checkAccountNumber = (confirmAccountNumber) => {
        const isSame = onboardingForm.tradingAddress.newAccountNumber === confirmAccountNumber;
        if (!isSame) {
            setShowMessage(true);
        }
    };

    return (
        <OnboardingAdressStepComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isFormValid={isFormValid}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            statusValidationBankAccount={statusValidationBankAccount}
            onChangeSameAddress={onChangeSameAddress}
            isBankDetailsNotFound={isBankDetailsNotFound}
            setIsBankDetailsNotFound={setIsBankDetailsNotFound}
            accNumberInputRef={accNumberInputRef}
            confirmAccountNumber={confirmAccountNumber}
            setConfirmAccountNumber={setConfirmAccountNumber}
            checkAccountNumber={checkAccountNumber}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
        />
    );
}

export default OnboardingAdressStep;
