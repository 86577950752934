import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalRemoveUserComponent from './modal-remove-member.component';
import { useGlobalStateContext } from 'contexts';
import { TeamService } from 'services';

export default function ModalRemoveUser(props) {
    const {
        open,
        setOpen,
        removeMember,
        getTeamMembers,
        setCount,
        count,
        setSelectedRole,
        setSelectedStatus,
        setSearchString
    } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { globalState } = useGlobalStateContext();

    const onRemoveConfirm = async (id) => {
        setLoading(true);
        const response = await TeamService.removeTeamMember(id, globalState.canonicalReseller?.id);
        if (response.isSuccesfully) {
            setLoading(false);
            getTeamMembers();
            setOpen(false);
            setCount(() => count - 1);
            setSelectedRole('All roles');
            setSelectedStatus('All');
            setSearchString('');
        } else {
            setLoading(false);
            setError(true);
        }
    };
    return (
        <ModalRemoveUserComponent
            open={open}
            setOpen={setOpen}
            removeMember={removeMember}
            onRemoveConfirm={onRemoveConfirm}
            loading={loading}
            error={error}
        />
    );
}

ModalRemoveUser.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    removeMember: PropTypes.object,
    refetch: PropTypes.func
};
