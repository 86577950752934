import React from 'react';
import styles from './dna-form-modal.module.scss';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    makeStyles,
    Table,
    TableCell,
    useMediaQuery,
    Paper,
    TableBody,
    TableRow,
    TextField,
    Grid,
    OutlinedInput
} from '@material-ui/core';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import NumberFormat from 'react-number-format';
import { ValidEmailInput } from 'components';
import PhoneInput from 'react-phone-input-2';
import { KeyToName } from 'utils/enums/PDQTableEnum';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { formatDateTimeByFormatString } from 'utils/helper';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& td': {
            minWidth: '230px',
            maxWidth: '230px',
            height: '50px'
        }
    },

    firstCell: {
        backgroundColor: '#fafafa',
        minWidth: '300px !important',
        maxWidth: '300px !important',
        overflow: 'auto'
    },
    notDisableCell: {
        backgroundColor: 'transparent',
        minWidth: '300px !important',
        maxWidth: '300px !important'
    },
    textField: {
        '& .MuiInputBase-root.Mui-disabled': {
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none'
        }
    },
    disabled: {
        backgroundColor: 'gainsbro !important'
    }
}));
function DnaFormModalComp(props) {
    const classes = useStyles();
    const {
        open,
        setOpen,
        isEdit,
        setIsEdit,
        onConfirm,
        businessTypeId,
        newData,
        handleChange,
        pdqFee,
        changeFee,
        handleIncrement,
        handleDecrement,
        isLoading,
        maxAmount,
        isMaxPercentage,
        isMaxDevice,
        disable,
        pdqPrice,
        isAdmin
    } = props;

    const fullScreen = useMediaQuery('(max-width:600px)');

    // function pricingMatrixComp(tag, feeKey, feeValue, authKey, authValue) {
    //     return (
    //         <>
    //             <h3>{tag}</h3>
    //             <Grid container spacing={2} className={styles.userName}>
    //                 <Grid item xs={8}>
    //                     %Fee
    //                 </Grid>
    //                 <Grid item xs={4}>
    //                     <NumberFormat
    //                         id="visa-debit-fee"
    //                         customInput={OutlinedInput}
    //                         inputProps={{ inputMode: 'numeric', maxLength: 7 }}
    //                         pattern="\d*"
    //                         thousandSeparator={false}
    //                         allowLeadingZeros={false}
    //                         allowNegative={false}
    //                         isNumericString={true}
    //                         decimalScale={2}
    //                         fixedDecimalScale={2}
    //                         value={feeValue}
    //                         disabled={!isEdit}
    //                         isAllowed={isMaxPercentage}
    //                         onChange={(e) => changeFee(feeKey, e.target.value)}
    //                         required
    //                         className={`${styles.Input} ${!isEdit ? styles.inputDisable : ''}`}
    //                     />
    //                 </Grid>
    //             </Grid>
    //             {feeValue < 0.4 && (
    //                 <div>
    //                     <Alert severity="error" className={styles.error_icon}>
    //                         <ErrorOutlineIcon />
    //                         Fee cannot be lower than 0.4
    //                     </Alert>
    //                 </div>
    //             )}
    //             <Grid container spacing={2} className={styles.userName}>
    //                 <Grid item xs={8}>
    //                     £ Authorisation fee
    //                 </Grid>
    //                 <Grid item xs={4}>
    //                     <NumberFormat
    //                         id="visa-debit-fee"
    //                         customInput={OutlinedInput}
    //                         inputProps={{ inputMode: 'numeric', maxLength: 7 }}
    //                         pattern="\d*"
    //                         thousandSeparator={false}
    //                         allowLeadingZeros={false}
    //                         allowNegative={false}
    //                         isNumericString={true}
    //                         decimalScale={2}
    //                         fixedDecimalScale={2}
    //                         value={authValue}
    //                         disabled={!isEdit}
    //                         onChange={(e) => changeFee(authKey, e.target.value)}
    //                         required
    //                         className={`${styles.Input} ${!isEdit ? styles.inputDisable : ''}`}
    //                     />
    //                 </Grid>
    //             </Grid>
    //             {authValue < 0.01 && (
    //                 <div>
    //                     <Alert severity="error" className={styles.error_icon}>
    //                         <ErrorOutlineIcon />
    //                         Auth fee cannot be lower than 0.01
    //                     </Alert>
    //                 </div>
    //             )}
    //         </>
    //     );
    // }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={styles.modal}
                fullScreen={fullScreen}
                disableEnforceFocus={true}
            >
                <DialogTitle id="draggable-dialog-title" stickyHeader className={styles.dialogHeader}>
                    DNA onboarding form
                    <BorderColorIcon className={styles.icon} onClick={() => setIsEdit(!isEdit)} />
                </DialogTitle>
                <Paper className={styles.dialogContent}>
                    <DialogContent className={styles.daialogbox}>
                        {
                            <Paper className={styles.TableContainer}>
                                <Table
                                    aria-label="simple table"
                                    aria-labelledby="tableTitle"
                                    size="small"
                                    stickyHeader
                                    component={Paper}
                                    // md={{ minWidth: 700 }}
                                    className={classes.tableCellSmallSize}
                                >
                                    <TableBody>
                                        {Object.entries(newData).map(([key, value]) => {
                                            return (
                                                <TableRow key={key}>
                                                    <TableCell>
                                                        {KeyToName[key]?.label}
                                                        {(key === 'registeredNumber' && businessTypeId === 1) ||
                                                        (isAdmin &&
                                                            (key === 'nameOfBank' ||
                                                                key === 'newAccountNumber' ||
                                                                key === 'sortCode' ||
                                                                key === 'accountHolderName')) ? (
                                                            <span className={styles.require}>*</span>
                                                        ) : (
                                                            KeyToName[key]?.required && (
                                                                <span className={styles.require}>*</span>
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        className={isEdit ? classes.notDisableCell : classes.firstCell}
                                                    >
                                                        {isEdit ? (
                                                            key === 'email' ? (
                                                                <div className={styles.emailField}>
                                                                    <ValidEmailInput
                                                                        labelId="email-address"
                                                                        id="email-input"
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            handleChange(key, e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : key === 'contactPhone' ? (
                                                                <PhoneInput
                                                                    country={'gb'}
                                                                    inputClass={styles.phoneInput}
                                                                    value={value}
                                                                    placeholder="Phone number"
                                                                    id="owner-input-phone-number"
                                                                    alwaysDefaultMask={true}
                                                                    countryCodeEditable={true}
                                                                    onChange={(value) => {
                                                                        handleChange(key, value);
                                                                    }}
                                                                />
                                                            ) : key === 'birthDate' ? (
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <MobileDatePicker
                                                                        inputFormat="D MMM YYYY"
                                                                        value={value}
                                                                        onChange={(newValue) => {
                                                                            if (newValue)
                                                                                handleChange(
                                                                                    key,
                                                                                    moment(newValue['$d']).format(
                                                                                        'D MMM YYYY'
                                                                                    )
                                                                                );
                                                                        }}
                                                                        maxDate={new Date()}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                placeholder={
                                                                                    'Select your date of birth'
                                                                                }
                                                                                className={styles.inputNumber}
                                                                                variant="outlined"
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            ) : KeyToName[key]?.type === 'text' ? (
                                                                <TextField
                                                                    inputProps={{ maxLength: KeyToName[key]?.length }}
                                                                    fullWidth
                                                                    value={value}
                                                                    onChange={(e) => {
                                                                        handleChange(key, e.target.value);
                                                                    }}
                                                                    disabled={KeyToName[key]?.disable}
                                                                    type={KeyToName[key]?.type}
                                                                    variant="outlined"
                                                                    size="small"
                                                                ></TextField>
                                                            ) : KeyToName[key]?.type === 'number' ? (
                                                                <NumberFormat
                                                                    id="pdq-qty"
                                                                    customInput={OutlinedInput}
                                                                    inputProps={{
                                                                        inputMode: 'numeric',
                                                                        maxLength: KeyToName[key]?.length
                                                                    }}
                                                                    pattern="\d*"
                                                                    thousandSeparator={false}
                                                                    allowLeadingZeros={false}
                                                                    allowNegative={false}
                                                                    isNumericString={true}
                                                                    decimalScale={0}
                                                                    fixedDecimalScale={0}
                                                                    value={value}
                                                                    disabled={!isEdit}
                                                                    onChange={(e) => handleChange(key, e.target.value)}
                                                                    required
                                                                    className={styles.inputNumber}
                                                                />
                                                            ) : (
                                                                KeyToName[key]?.type === 'float' && (
                                                                    <NumberFormat
                                                                        id="amount_input"
                                                                        customInput={OutlinedInput}
                                                                        inputProps={{
                                                                            inputMode: 'numeric',
                                                                            className: styles.myInput
                                                                        }}
                                                                        pattern="\d*"
                                                                        thousandSeparator={true}
                                                                        autoComplete="off"
                                                                        allowLeadingZeros={true}
                                                                        allowNegative={false}
                                                                        isNumericString={true}
                                                                        decimalScale={2}
                                                                        value={value}
                                                                        onValueChange={(e) => {
                                                                            handleChange(key, e.value);
                                                                        }}
                                                                        isAllowed={
                                                                            key === 'ownership'
                                                                                ? isMaxPercentage
                                                                                : maxAmount
                                                                        }
                                                                        labelWidth={0}
                                                                        required
                                                                        className={styles.inputNumber}
                                                                    />
                                                                )
                                                            )
                                                        ) : KeyToName[key]?.type === 'date' ? (
                                                            <span>
                                                                {formatDateTimeByFormatString(value, 'DD MMM HH:mm')}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {key === 'contactPhone' ? '+' : ''}
                                                                {value}
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {/* <TableRow>
                                            <TableCell>PDQ-Pricing Matrix</TableCell>
                                            <TableCell>
                                                <div className={styles.pricingMatrix}>
                                                    {pricingMatrixComp(
                                                        'Visa Debit Card',
                                                        'visaDebitFee',
                                                        pdqFee.visaDebitFee,
                                                        'visaDebitAuth',
                                                        pdqFee.visaDebitAuth
                                                    )}
                                                </div>
                                                <div className={styles.pricingMatrix}>
                                                    {pricingMatrixComp(
                                                        'Master Debit Card',
                                                        'masterDebitFee',
                                                        pdqFee.masterDebitFee,
                                                        'masterDebitAuth',
                                                        pdqFee.masterDebitAuth
                                                    )}
                                                </div>
                                                <div className={styles.pricingMatrix}>
                                                    {pricingMatrixComp(
                                                        'Visa Credit Card',
                                                        'visaCreditFee',
                                                        pdqFee.visaCreditFee,
                                                        'visaCreditAuth',
                                                        pdqFee.visaCreditAuth
                                                    )}
                                                </div>
                                                <div className={styles.pricingMatrix}>
                                                    {pricingMatrixComp(
                                                        'Master Credit Card',
                                                        'masterCreditFee',
                                                        pdqFee.masterCreditFee,
                                                        'masterCreditAuth',
                                                        pdqFee.masterCreditAuth
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell>PDQ's Required</TableCell>
                                            <TableCell>
                                                <div className={styles.pricingMatrix}>
                                                    <h3>PAX A920</h3>
                                                    <Grid container spacing={2} className={styles.userName}>
                                                        <Grid item xs={7}>
                                                            Quantity
                                                            <span className={styles.require}>*</span>
                                                        </Grid>
                                                        <Grid item xs={5} style={{ display: 'flex' }}>
                                                            {isEdit && (
                                                                <button
                                                                    className={styles.counterBtn}
                                                                    onClick={handleDecrement}
                                                                    disabled={Number(pdqFee.pdqQty) <= 1}
                                                                >
                                                                    -
                                                                </button>
                                                            )}

                                                            <NumberFormat
                                                                style={{ borderRadius: 'none !important' }}
                                                                id="pdq-qty"
                                                                customInput={OutlinedInput}
                                                                inputProps={{ inputMode: 'numeric' }}
                                                                pattern="\d*"
                                                                thousandSeparator={false}
                                                                allowLeadingZeros={true}
                                                                allowNegative={false}
                                                                isNumericString={true}
                                                                decimalScale={0}
                                                                fixedDecimalScale={0}
                                                                value={pdqFee.pdqQty}
                                                                disabled={!isEdit}
                                                                isAllowed={isMaxDevice}
                                                                onChange={(e) => changeFee('pdqQty', e.target.value)}
                                                                required
                                                                className={`${styles.Input} ${
                                                                    !isEdit ? styles.inputDisable : ''
                                                                }`}
                                                            />
                                                            {isEdit && (
                                                                <button
                                                                    className={styles.counterBtn}
                                                                    onClick={handleIncrement}
                                                                    disabled={Number(pdqFee.pdqQty) >= 10}
                                                                >
                                                                    +
                                                                </button>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} className={styles.userName}>
                                                        <Grid item xs={7}>
                                                            Price per PDQ
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <NumberFormat
                                                                id="pdq-price"
                                                                customInput={OutlinedInput}
                                                                inputProps={{ inputMode: 'numeric' }}
                                                                pattern="\d*"
                                                                thousandSeparator={false}
                                                                allowLeadingZeros={false}
                                                                allowNegative={false}
                                                                isNumericString={true}
                                                                decimalScale={2}
                                                                fixedDecimalScale={2}
                                                                isAllowed={pdqPrice}
                                                                value={pdqFee.pdqPrice}
                                                                disabled={!isEdit}
                                                                onChange={(e) => changeFee('pdqPrice', e.target.value)}
                                                                required
                                                                className={`${styles.Input} ${
                                                                    !isEdit ? styles.inputDisable : ''
                                                                }`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        }
                    </DialogContent>
                </Paper>
                <DialogActions className={styles.actionsContainer}>
                    <Button
                        id="cancelButtonCloseMerchant"
                        onClick={() => setOpen(false)}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        id="createButtonCloseMerchant"
                        onClick={() => onConfirm()}
                        color="primary"
                        variant="contained"
                        disabled={isLoading || disable}
                        className={styles.sendBtn}
                    >
                        {isLoading ? 'Please Wait...' : 'Send'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default DnaFormModalComp;
