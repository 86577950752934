import React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: 'black',
        background: 'rgba(255, 255, 255, 0.9) !important'
    }
}));

const CustomLoadingScreenComponent = (props) => {
    const classes = useStyles();

    return (
        <Backdrop open={props.open} className={classes.backdrop}>
            <div
                style={{
                    padding: '10%',
                    color: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <CircularProgress color="inherit" />
                <span style={{ color: 'inherit', marginTop: '20px' }}>{props.message}</span>
            </div>
        </Backdrop>
    );
};

export default CustomLoadingScreenComponent;

CustomLoadingScreenComponent.propTypes = {
    message: PropTypes.string
};
