import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NetVolumeCardComponent from './net-volume-card.component';
import { PaymentsService } from 'services';
import { useGlobalStateContext } from 'contexts';

function NetVolumeCard(props) {
    const [netVolume, setNetVolume] = useState(0);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const getPaymentsList = async () => {
            const date = new Date();
            const month = moment(date, 'YYYY-MM-DD').month() + 1;
            const year = moment(date, 'YYYY-MM-DD').year();

            const paymentsList = await PaymentsService.GetPayments(globalState.merchant?.id, {
                month,
                year
            });

            if (paymentsList.isSuccesfully) {
                let sum = 0;
                paymentsList.data.payments.forEach((element) => {
                    sum += Number(element.payed);
                });
                setNetVolume(sum);
            }
        };

        getPaymentsList();
    }, [globalState.merchant]);

    return <NetVolumeCardComponent {...props} netVolume={netVolume} />;
}

export default NetVolumeCard;
