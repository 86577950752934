import { useGlobalStateContext } from 'contexts';
import React, { useEffect, useState } from 'react';
import { NonResellerMerchantService } from 'services';
import MerchantResellerListComponent from './merchant-reseller-list.component';
import { MerchantStatusNames, OmnipayMerchantStatusNames } from 'utils/enums/MerchantStatusNames';
import { ResellerType } from 'utils/enums/ResellerType';
const allStatusIds = Object.keys(MerchantStatusNames).map((x) => +x);

function MerchantResellerList() {
    const { globalState, setRoute } = useGlobalStateContext();
    const [merchants, setMerchants] = useState([]);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [merchantCreation, setMerchantCreation] = useState(false);
    const [saveDraft, setSaveDraft] = useState(false);
    const [accountUpdated, setAccountUpdated] = useState(false);
    const [needToSignTermsAndCondition, setNeedToSignTermsAndCondition] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [canonicalResellerNameAndID, setCanonicalResellerNameAndId] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [filter, setFilter] = useState(['all']);
    const [statusFilter, setStatusFilter] = useState(allStatusIds);
    const [productFilter, setProductFilter] = useState('all');
    const isOmnipay = globalState.reseller?.name === ResellerType.OMNIPAY;
    const statusFilterOptions = isOmnipay ? OmnipayMerchantStatusNames : MerchantStatusNames;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getAllMerchantsForReseller(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getAllMerchantsForReseller(0, parseInt(event.target.value, 10));
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    useEffect(() => {
        if (globalState.canonicalReseller) {
            setIsLoading(true);
            setPage(0);
            setCanonicalResellerNameAndId(
                `${globalState.canonicalReseller?.id} - ${globalState.canonicalReseller?.companyName}`
            );
            getAllMerchantsForReseller(0);
        }
        // eslint-disable-next-line
    }, [globalState.canonicalReseller, statusFilter, productFilter]);

    useEffect(() => {
        const termsAndConditionInfo = globalState.canonicalReseller?.termsAndConditionInfo;
        if (termsAndConditionInfo) {
            const signingInfo = termsAndConditionInfo.signingInfo;
            setNeedToSignTermsAndCondition(signingInfo?.noOfNotSignedTermsAndConditions > 0);
        } else {
            setNeedToSignTermsAndCondition(false);
        }

        // eslint-disable-next-line
    }, [globalState.canonicalReseller]);

    useEffect(() => {
        switch (filter) {
            case 'Pending':
                setStatusFilter([1]);
                break;
            case 'Active':
                setStatusFilter([3]);
                break;
            case 'Not Submitted':
                setStatusFilter([0]);
                break;
            case 'Verified':
                setStatusFilter([2]);
                break;
            case 'Closed':
                setStatusFilter([4]);
                break;
            case 'Rejected':
                setStatusFilter([5]);
                break;
            case 'Pending Stripe':
                setStatusFilter([6]);
                break;
            case 'Watch List':
                setStatusFilter([12]);
                break;

            default:
                setStatusFilter(allStatusIds);
        }
        // eslint-disable-next-line
    }, [filter]);

    const getAllMerchantsForReseller = async (newPage, newRowsPerPage) => {
        setIsLoading(true);
        const thePage = newPage ?? page;
        const theRowsPerPage = newRowsPerPage ?? rowsPerPage;
        const response = await NonResellerMerchantService.getAllMerchantsForReseller(globalState.reseller?.id, {
            searchValue: searchValue.length > 2 ? searchValue : 'no',
            offset: thePage * theRowsPerPage,
            limit: theRowsPerPage,
            canonicalResellerId: globalState.canonicalReseller?.id,
            statusFilter: statusFilter,
            productFilter: productFilter
        });
        if (response.isSuccesfully) {
            setMerchants(response.data.merchants);
            setCount(response.data.count);
        } else {
            setMerchants([]);
            setCount(0);
        }
        setIsLoading(false);
    };

    const onSearchMerchants = (e) => {
        setPage(0);
        getAllMerchantsForReseller(0);
        e.preventDefault();
    };

    useEffect(() => {
        const show = sessionStorage.getItem('merchantCreationSuccess');
        if (show) {
            setMerchantCreation(true);
            sessionStorage.removeItem('merchantCreationSuccess');
        }
        const successDraft = sessionStorage.getItem('savedDraft');
        if (successDraft) {
            setSaveDraft(true);
            sessionStorage.removeItem('savedDraft');
        }
    }, []);

    useEffect(() => {
        const fetchStatus = async () => {
            const updatedAccount = sessionStorage.getItem('accountUpdated');
            if (updatedAccount) {
                setAccountUpdated(true);
                const timeout = setTimeout(() => {
                    setAccountUpdated(false);
                }, 15000);
                sessionStorage.removeItem('accountUpdated');
                return () => clearTimeout(timeout);
            }
        };

        fetchStatus();
    }, []);

    const onCreateMerchant = () => {
        if (needToSignTermsAndCondition) {
            setShowMessage(true);
        } else {
            setRoute('/create-merchant');
        }
    };

    return (
        <MerchantResellerListComponent
            isOmnipay={isOmnipay}
            merchants={merchants}
            isLoading={isLoading}
            setSearchValue={setSearchValue}
            onSearchMerchants={onSearchMerchants}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            canonicalReseller={globalState.canonicalReseller}
            merchantCreation={merchantCreation}
            setMerchantCreation={setMerchantCreation}
            accountUpdated={accountUpdated}
            canonicalResellerNameAndID={canonicalResellerNameAndID}
            onCreateMerchant={onCreateMerchant}
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            filter={filter}
            setFilter={setFilter}
            needToSignTermsAndCondition={needToSignTermsAndCondition}
            setNeedToSignTermsAndCondition={setNeedToSignTermsAndCondition}
            saveDraft={saveDraft}
            setSaveDraft={setSaveDraft}
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            statusFilterOptions={statusFilterOptions}
            createSortHandler={createSortHandler}
            orderBy={orderBy}
            order={order}
        />
    );
}

export default MerchantResellerList;
