import React, { useState, useEffect } from 'react';
import OnboardingOwnerPrimaryAddressComponent from './onboarding-owner-primary-address.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';

function OnboardingOwnerPrimaryAddress(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();
    const isT2SMerchant = globalState.isT2SMerchant;

    useEffect(() => {
        const { postCode, addressLine1, city, country } = onboardingForm.ownerPrimaryAddress;
        const checkValues = postCode && addressLine1 && city && (isT2SMerchant || country);
        setIsDisabled(!checkValues);
    }, [onboardingForm.ownerPrimaryAddress, isT2SMerchant]);

    const selectAddressData = (addressDataObj) => {
        if (addressDataObj) {
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                ownerPrimaryAddress: {
                    ...onboardingForm.ownerPrimaryAddress,
                    addressLine1: addressDataObj.line_1,
                    addressLine2: addressDataObj.line_2,
                    city: addressDataObj.town_or_city,
                    country: addressDataObj.country
                }
            }));
        }
    };

    const onSubmit = () => {
        const data = {
            addressData: onboardingForm.ownerPrimaryAddress,
            subStepNumber: 2
        };
        NonResellerMerchantService.completeOnboardingStep(globalState.merchant?.id, 4, data);
    };

    return (
        <OnboardingOwnerPrimaryAddressComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
            selectAddressData={selectAddressData}
            isT2SMerchant={isT2SMerchant}
        />
    );
}

export default OnboardingOwnerPrimaryAddress;
