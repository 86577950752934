import _ from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { InputValidators } from 'validators';
import 'moment/locale/en-gb';
import { DocumentTypes, getDocumentsCategories } from 'utils/enums/DocumentType';
import { ResellerType } from 'utils/enums/ResellerType';
import CryptoJS from 'crypto-js';
import { BusinessTypeEnumId } from './enums/BusinessType';
import clevertap from 'clevertap-web-sdk';
import { AuthService } from 'services';
import { UserType } from './enums/UserType';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import jwt from 'jsonwebtoken';

const currency = (currencyCode) =>
    new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyCode
    });

export const formatCurrency = (amount, currencyCode = 'GBP') => currency(currencyCode).format(amount);

export const formatDate = (date) => {
    const formattedDate = moment.utc(date).format('YYYY-MM-DD HH:mm:SS');

    return moment(formattedDate).locale(process.env.REACT_APP_LOCALE).format('l');
};

export const formatAmount = (num) => {
    return parseFloat(num)
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatToTime = (date) => moment.utc(date).format('HH:mm:SS');

export const formatDateTime = (dateTime) => {
    const formattedDateTime = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:SS');

    return moment(formattedDateTime).locale(process.env.REACT_APP_LOCALE).format('l LT');
};

export const formatDateTimeByFormatString = (dateTime, formatString = 'l LT', noUtc = false) => {
    let formattedDateTime = noUtc ? moment(dateTime) : moment.utc(dateTime);
    formattedDateTime = formattedDateTime.format('YYYY-MM-DD HH:mm:SS');

    return moment(formattedDateTime).locale(process.env.REACT_APP_LOCALE).format(formatString);
};

export const formatDateTimeByFormatStringV2 = (dateTime, formatString = 'l LT') => {
    let formattedDateTime = momentTZ.tz(dateTime, 'Europe/London');
    formattedDateTime = formattedDateTime.format('YYYY-MM-DD HH:mm:ss');

    return moment(formattedDateTime).locale(process.env.REACT_APP_LOCALE).format(formatString);
};

export function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
export function toUpperCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.toUpperCase();
    });
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */

export const difference = (object, base) => {
    function changes(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
};

// Find ISO week
export const findISOweek = (year, month) => {
    const firstDayOfMonth = moment(`${year}-${month + 1}`, 'YYYY-MM-DD');
    const numOfDays = firstDayOfMonth.daysInMonth();
    const weeks = new Set();

    for (let i = 0; i < numOfDays; i++) {
        const currentDay = moment(firstDayOfMonth, 'YYYY-MM-DD').add(i, 'days');

        weeks.add(currentDay.isoWeek());
    }

    return Array.from(weeks);
};

// Add a getWeek function to Date prototype.
// Returns the ISO week of the date.

// eslint-disable-next-line
Date.prototype.getWeek = function () {
    //eslint-disable-line
    // eslint-disable-line
    const date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
};

export const getDateRangeOfWeek = (weekNo, y, findIndex = false) => {
    const rangeIsFrom = moment().year(y).week(weekNo).startOf('week');
    const rangeIsTo = moment().year(y).week(weekNo).endOf('week');
    return findIndex
        ? `${rangeIsFrom.format('MMM Do')} - ${rangeIsTo.format('MMM Do')}`
        : `${rangeIsFrom.format('DD MMM YY')} - ${rangeIsTo.format('DD MMM YY')}`;
};

// Keep this function. It will be needed for reference when refactoring dashboard

// export const getDateOfISOWeek = (w, y) => {
//   const simple = new Date(y, 0, 1 + (w - 1) * 7);
//   const dow = simple.getDay();
//   const ISOweekStart = simple;
//   if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
//   else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
//   return ISOweekStart;
// };

export const descendingComparator = (tranx, tranxToCompare, orderBy) => {
    let valueFromA = tranx[orderBy];
    let valueFromB = tranxToCompare[orderBy];

    if (
        [
            'created_at',
            'createdAt',
            'chargebackDate',
            'transactionDate',
            'date_sent',
            'payoutExpectedDate',
            'time',
            'createdDate',
            'completedDate',
            'activatedAt',
            'deactivatedAt',
            'status'
        ].includes(orderBy)
    ) {
        valueFromA = moment(valueFromA).valueOf();
        valueFromB = moment(valueFromB).valueOf();
    } else if (['name'].includes(orderBy) || ['email'].includes(orderBy)) {
        valueFromA = tranx[orderBy].toLowerCase();
        valueFromB = tranxToCompare[orderBy].toLowerCase();
    }

    if (!valueFromA) {
        return 1;
    }

    if (!valueFromB) {
        return -1;
    }

    if (valueFromB < valueFromA) {
        return -1;
    }
    if (valueFromB > valueFromA) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (tranx, tranxToCompare) => descendingComparator(tranx, tranxToCompare, orderBy)
        : (tranx, tranxToCompare) => -descendingComparator(tranx, tranxToCompare, orderBy);
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((tranx, tranxToCompare) => {
        const order = comparator(tranx[0], tranxToCompare[0]);
        if (order !== 0) return order;
        return tranx[1] - tranxToCompare[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const numberFormatAllowValuesForPayments = (values) => {
    const { value, floatValue } = values;

    if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
        return true;
    }
    if (value.charAt(0) === '0') {
        if (value.charAt(1) && value.charAt(1) !== '.') {
            return false;
        }
    }
    return true;
};

export const pdfFileTemplate = (
    merchant,
    logo,
    pdfTitle,
    address,
    csvData,
    companyAddress,
    paidImage,
    isDatman,
    isPaid,
    transactionId,
    columnStyles,
    merchantLegalName
) => {
    const doc = new jsPDF('l');
    const marginLeft = 13;
    let marginTop = 60;
    const vAlignRight = 280;
    var companyPhoneNumber = `Phone Number : 03330165548`;
    var companyName = doc.splitTextToSize(!isDatman ? 'OmniPay LTD' : 'Mypay LTD', 60);
    var companyAddressLine1 = doc.splitTextToSize(companyAddress, 60);
    const data = Papa.parse(csvData)?.data;
    const res = data?.map((trans) => [...trans]).splice(1);
    let merchantName = '';
    let addressLine1 = '';
    let addressLine2 = '';
    let city = '';
    let postCode = address?.postCode ? address?.postCode : '';
    if (merchant?.legalName?.length > 40) {
        merchantName = merchantLegalName.slice(0, 40) + '...';
    } else {
        merchantName = merchantLegalName;
    }
    if (address?.addressLine1?.length > 40) {
        addressLine1 = address.addressLine1.slice(0, 40) + '...';
    } else {
        addressLine1 = address?.addressLine1 ? address.addressLine1 : '';
    }
    if (address?.addressLine2?.length > 40) {
        addressLine2 = address.addressLine2.slice(0, 40) + '...';
    } else {
        addressLine2 = address?.addressLine2 ? address.addressLine2 : '';
    }
    if (address?.city?.length > 40) {
        city = address.city.slice(0, 40) + '...';
    } else {
        city = address?.city ? address.city : '';
    }

    doc.setFontSize(14); //Merchant name
    let content = {
        startY: marginTop + 26,
        head: [data[0]],
        body: res,
        rowPageBreak: 'auto',
        bodyStyles: { valign: 'top' },
        columnStyles: columnStyles
    };
    const merchantID = `MID: ${merchant.id}`;
    doc.text(merchantName, marginLeft, 15, 'left');
    doc.text(addressLine1, marginLeft, 22, 'left');
    if (!address.addressLine2) {
        doc.text(city, marginLeft, 28, 'left');
        doc.text(postCode, marginLeft, 34, 'left');
    } else {
        doc.text(addressLine2, marginLeft, 28, 'left');
        doc.text(city, marginLeft, 34, 'left');
        doc.text(postCode, marginLeft, 40, 'left');
    }

    //Add Logo to right
    isDatman
        ? doc.addImage(logo, 'png', 120, 10, 40, 33, 'center', 'FAST')
        : doc.addImage(logo, 'png', 120, 10, 40, 14, 'center', 'FAST');

    doc.text(merchantID.toString(), 14, marginTop);
    doc.text(companyName, vAlignRight, 15, 'right');
    doc.text(companyAddressLine1, vAlignRight, 22, 'right');
    isDatman && doc.text(companyPhoneNumber, vAlignRight, 44, 'right');

    //Show transactions title
    doc.text(pdfTitle, 14, 70);
    doc.text(companyAddressLine1, vAlignRight, 22, 'right');
    doc.setFontSize(11);
    if (isPaid) {
        doc.addImage(paidImage, 'png', 250, 50, 31, 30, 'FAST');
    }
    //Put table in pdf
    doc.autoTable(content);
    doc.save(`Merchant_charge_${transactionId}.pdf`);
};

export const SuperAdminEmails = [
    'shalini.s+admin+@datman.je',
    'shalini.s+adminmypay1@datman.je',
    'aymen@datman.je',
    'adi@datman.je',
    'heather.upchurch@mypay.co.uk'
];

export const updateAccountMapKeyWord = (obj, oldObject) => {
    const mapKeyword = {
        city: 'Contact city',
        postCode: 'Contact post code',
        email: 'Contact email',
        telephoneNumber: 'Telephone number',
        websiteUrl: 'Business URL',
        country: 'Country',
        telNumber: 'Telephone number'
    };

    if ('addressLine1' in obj || 'addressLine2' in obj) {
        const city = 'city' in obj ? obj['city'] : oldObject['city'];
        const postCode = 'postCode' in obj ? obj['postCode'] : oldObject['postCode'];
        const addressLine2 = 'addressLine2' in obj ? obj['addressLine2'] : '';
        obj['Address'] = `${obj['addressLine1']} ${addressLine2}, ${city}, ${postCode}`;
        delete obj['addressLine1'];
        obj['addressLine2'] && delete obj['addressLine2'];
    }

    for (const property in obj) {
        if (property in mapKeyword) {
            obj[mapKeyword[property]] = obj[property];
            delete obj[property];
        }
    }

    return obj;
};

export const getFormattedDocumentsStatsForMerchant = ({
    merchantBusinessTypeId,
    merchantCountry,
    source,
    merchantDocuments,
    resellerName,
    isAdmin
}) => {
    merchantBusinessTypeId = Number(merchantBusinessTypeId || 1);

    let docCategories = JSON.parse(
        JSON.stringify(getDocumentsCategories(merchantCountry, source, resellerName, isAdmin))
    );
    let filteredDocumentCategories = docCategories.filter((category) => {
        if (!isAdmin && category.isAdminCategory && resellerName !== ResellerType.DATMAN) {
            return false;
        }
        category.documents = category.documents.filter((doc) => {
            doc.defaultTypeIds = doc.typeIds;
            return doc.neededInBusinessTypeIds.includes(merchantBusinessTypeId);
        });
        return (
            (source === 'BANK_UPDATE' ? category.isBankUpdateCategory : true) &&
            category.neededInBusinessTypeIds.includes(merchantBusinessTypeId)
        );
    });

    let allNeededDocumentTypeIds = _.flattenDeep(
        filteredDocumentCategories.map((category) => {
            return category.documents.map((doc) => {
                return doc.typeIds;
            });
        })
    );

    let merchantDocumentTypeIds = _.map(merchantDocuments, 'documentTypeId');
    let alreadyUploadedDocumentTypes = [];
    let pendingDocumentTypes = [];
    let pendingDocumentTypeIds = [];
    let hasAllMandatoryDocsUploaded = true;

    let pendingDocumentCategories = JSON.parse(JSON.stringify(filteredDocumentCategories));

    pendingDocumentCategories = pendingDocumentCategories.filter((category) => {
        //If one kind of document (passport/permit) is uploaded then the entire category should not be shown
        //If for a 2 faced id (aadhar), single side is uploaded - Show only the other side
        let uploadedDocumentTypeIdsInThisCategory = _.intersection(
            _.flattenDeep(
                category.documents.map((doc) => {
                    return doc.typeIds;
                })
            ),
            merchantDocumentTypeIds
        );

        let categoryNeedsMoreUploads = true;
        if (uploadedDocumentTypeIdsInThisCategory.length > 0) {
            let uploadedDocument = _.find(category.documents, (doc) => {
                return _.intersection(doc.typeIds, uploadedDocumentTypeIdsInThisCategory).length > 0;
            });

            let notYetUploadedTypeIdsForUploadDocument = _.difference(
                uploadedDocument.typeIds,
                uploadedDocumentTypeIdsInThisCategory
            );

            if (!notYetUploadedTypeIdsForUploadDocument.length) {
                categoryNeedsMoreUploads = false;
            }

            uploadedDocument.typeIds = notYetUploadedTypeIdsForUploadDocument;

            category.documents = [uploadedDocument];
        }
        if (categoryNeedsMoreUploads) {
            _.each(category.documents, (doc) => {
                pendingDocumentTypeIds.push(...doc.typeIds);
                if (!category.isOptionalCategory) {
                    hasAllMandatoryDocsUploaded = false;
                }
            });
        }

        return categoryNeedsMoreUploads;
    });

    DocumentTypes.forEach((docType) => {
        if (allNeededDocumentTypeIds.includes(docType.id)) {
            //Above line will remove older needed docs from listing here
            if (merchantDocumentTypeIds.includes(docType.id)) {
                alreadyUploadedDocumentTypes.push(docType);
            } else if (pendingDocumentTypeIds.includes(docType.id)) {
                pendingDocumentTypes.push(docType);
            } else if (
                (merchantBusinessTypeId === BusinessTypeEnumId.Partnership ||
                    merchantBusinessTypeId === BusinessTypeEnumId.Other) &&
                source !== 'BANK_UPDATE'
            ) {
                pendingDocumentTypes.push(docType);
            }
        }
    });

    pendingDocumentTypes = pendingDocumentTypes.sort(
        (a, b) => allNeededDocumentTypeIds.indexOf(a.id) - allNeededDocumentTypeIds.indexOf(b.id)
    );

    let needsOnlyGenericCategoryDocs =
        filteredDocumentCategories.length === 1 && filteredDocumentCategories[0].isAGenericCategory;

    return {
        filteredDocumentCategories,
        needsOnlyGenericCategoryDocs,
        pendingDocumentCategories,
        alreadyUploadedDocumentTypes,
        pendingDocumentTypes,
        pendingDocumentTypeIds,
        hasAllMandatoryDocsUploaded
    };
};

export const validateEmailAddress = (email) => {
    const index = email.indexOf('@');
    const subStr = email.substr(index + 1);
    let emailValidationMessage = '';

    let symbol = '';

    if (!InputValidators.isEmailValid(email)) {
        for (let i = 0; i < subStr.length; i++) {
            const characterCode = subStr.charCodeAt(i);
            if (
                (characterCode >= 97 && characterCode <= 122) ||
                (characterCode >= 65 && characterCode <= 90) ||
                (characterCode >= 48 && characterCode <= 57) ||
                (characterCode >= 45 && characterCode <= 46)
            ) {
                continue;
            } else {
                symbol = subStr[i];
                break;
            }
        }
    }

    if (email.length === 0) {
        emailValidationMessage = 'Please fill in this field';
    } else if (email.includes(' ')) {
        emailValidationMessage = 'Email should not contain space';
    } else if (index === -1) {
        emailValidationMessage = `Please include an '@' in email address. ${email} is missing an '@'`;
    } else if (index <= 0) {
        emailValidationMessage = `Please enter part followed by '@'. ${email} is incomplete.`;
    } else if (index === email.length - 1) {
        emailValidationMessage = `Please enter part following '@'. ${email} is incomplete.`;
    } else if (email.indexOf('.') === index + 1) {
        emailValidationMessage = `'.' is used at wrong position in ${subStr}.`;
    } else if (subStr.includes('..')) {
        emailValidationMessage = `Following part of '@' ${subStr} should not contain consecutive '.' .`;
    } else if (symbol) {
        emailValidationMessage = `A part following '@' should not contain the symbol " ${symbol} "`;
    } else if (!InputValidators.isEmailValid(email)) {
        emailValidationMessage = `Please provide the valid email address`;
    }

    return emailValidationMessage;
};

export const encryptData = (data) => {
    let secret = process.env.REACT_APP_ENCRYPTION_KEY;
    var encrypted = CryptoJS.AES.encrypt(data, secret);
    return encrypted.toString();
};
export const cleverTapClick = (value, state, data) => {
    if (AuthService.isLoggedUser() && state?.user?.UserType?.name === UserType.Merchant) {
        const date = moment(new Date()).format('h:mma DD-MM-YYYY');
        data = { ...data, time_stamp: date };
        clevertap.event.push(value, data);
    }
};

export const filterRefundDescription = (str) => {
    const regexArr = str?.match(/(?:because\s).*/);
    let refundDescription = '';
    if (regexArr && regexArr.length) {
        refundDescription = regexArr[0];
    } else {
        refundDescription = '-';
    }
    refundDescription = refundDescription.replace('because ', '');
    return refundDescription;
};

const isJsonString = (str = '') => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const filterDescription = (str = '') => {
    return isJsonString(str) ? JSON.parse(str) : str;
};

export const maskCharacter = (str, mask = '*', n = 4) => {
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
};

export const merchantTypes = ['DATMAN-RESELLER', 'DATMAN-DELIVERY-PARTNER', 'O2M-RESELLER'];

export const roundOfInteger = (num) => {
    return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
};

export const formatToFixesTwo = (numStr) => {
    if (numStr === '') {
        return '';
    } else if (numStr.length === 1 && +numStr[0] !== 0) {
        return `0.${numStr}`;
    } else if (numStr.length === 1 && +numStr[0] === 0) {
        return `${numStr}.`;
    } else if (numStr.length === 2) {
        return `${numStr[0]}.${numStr[1]}`;
    } else if (numStr.length > 2 && +numStr[0] !== 0) {
        return `0.${numStr[0]}${numStr[1]}`;
    } else {
        return `${numStr[0]}.${numStr[1]}${numStr[2]}`;
    }
};

export const isValidPassword = (password, firstName, lastName, email) => {
    const forbiddenTerms = [];

    const nameParts = [...firstName.split(' '), ...lastName.split(' ')];

    // Exclude specific terms from the password based on the length of name parts
    for (const term of nameParts) {
        if (term.length > 2) {
            forbiddenTerms.push(term.toLowerCase());
        }
    }

    forbiddenTerms.push(email.toLowerCase());

    // Check if password contains any forbidden terms
    for (const term of forbiddenTerms) {
        if (password.toLowerCase().includes(term)) {
            return false;
        }
    }

    return true;
};

export const isTokenGoingToExpire = (token) => {
    const tokenData = jwt.decode(token);
    const currentTime = moment().unix();
    console.log('currentTime', currentTime);
    console.log('tokenData.exp', tokenData.exp);
    console.log('tokenData.exp - currentTime', tokenData.exp - currentTime);
    if (tokenData.exp - currentTime < 10) {
        return true;
    }
    return false;
};
