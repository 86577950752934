import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductMultiSelectComponent from './product-multi-select.component';

/**
 *
 * @param {{
 * list: array;
 * onChange: func;
 * value: array;
 * }} props
 */
function ProductMultiSelect(props) {
    const [selectedEntities, setSelectedEntitites] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(false);

    useEffect(() => {
        const handleChange = async () => {
            setSelectedEntitites(props.value ? props.value : []);
        };
        handleChange();
    }, [props.value]);

    return (
        <ProductMultiSelectComponent
            {...props}
            selectedEntities={selectedEntities}
            setSelectedEntitites={setSelectedEntitites}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
        />
    );
}

ProductMultiSelect.propTypes = {
    value: PropTypes.array
};

export default ProductMultiSelect;
