import React, { useEffect, useState } from 'react';
import { NonResellerMerchantService, AuthService } from 'services';
import { useGlobalStateContext } from 'contexts';
import OnboardingDocumentsStepComponent from './onboarding-documents.component';
import PropTypes from 'prop-types';
import { useOnboardingFormContext } from 'contexts';
import { formTypes } from 'utils/enums/FormTypes';
import { isTokenGoingToExpire } from 'utils/helper';

function OnboardingDocumentsStep(props) {
    const { nextStep } = props;
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const [idNumber, setIdNumber] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const { globalState } = useGlobalStateContext();
    const [validForm, setValidForm] = useState(onboardingForm.completedSteps.uploadDocuments);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [formattedDocumentsStats, setFormattedDocumentsStats] = useState({});
    const [csrfToken, setCSRFToken] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const businessTypeId = onboardingForm.businessDetails.businessTypeId;

    const generateCSRFToken = async () => {
        setIsLoading(true);
        const response = await AuthService.generateCSRFToken(
            formTypes.ONBOARDING_UPLOAD_DOCUMENTS,
            globalState.merchant?.id
        );
        if (response.isSuccesfully) {
            setCSRFToken(response.data.csrfToken);
        }
        setIsLoading(false);
        return response?.data?.csrfToken;
    };

    useEffect(() => {
        generateCSRFToken();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!formattedDocumentsStats.hasAllMandatoryDocsUploaded) {
            setIsDisabled(!formattedDocumentsStats.hasAllMandatoryDocsUploaded);
        } else {
            setIsDisabled(cardNumber);
        }
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                uploadDocuments: formattedDocumentsStats.hasAllMandatoryDocsUploaded ? true : false
            }
        }));
    }, [validForm, setOnboardingForm, formattedDocumentsStats]);

    const onSubmit = async () => {
        // TODO - call back end once it is done
        const isCSRFGoingToExpire = isTokenGoingToExpire(csrfToken);
        let latestCSRFToken = csrfToken;
        if (isCSRFGoingToExpire) {
            latestCSRFToken = await generateCSRFToken();
        }
        const result = await NonResellerMerchantService.completeOnboardingStep(
            globalState.merchant?.id,
            8,
            { formType: formTypes.ONBOARDING_UPLOAD_DOCUMENTS },
            latestCSRFToken
        );
        if (result.isSuccesfully) {
            nextStep();
        }
    };

    return (
        <OnboardingDocumentsStepComponent
            onSubmit={onSubmit}
            isDisabled={isDisabled}
            merchantId={globalState.merchant?.id}
            setValidForm={setValidForm}
            uploadSuccess={uploadSuccess}
            setUploadSuccess={setUploadSuccess}
            businessTypeId={businessTypeId}
            formattedDocumentsStats={formattedDocumentsStats}
            setFormattedDocumentsStats={setFormattedDocumentsStats}
            csrfToken={csrfToken}
            isLoading={isLoading}
            formType={formTypes.ONBOARDING_UPLOAD_DOCUMENTS}
            setCSRFToken={setCSRFToken}
            setIdNumber={setIdNumber}
            idNumber={idNumber}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
        />
    );
}

OnboardingDocumentsStep.propTypes = {
    nextStep: PropTypes.func
};

export default OnboardingDocumentsStep;
