import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography, Card } from '@material-ui/core';
import { Label } from 'components/onboarding/onboarding-ui-components';
import { PasswordValidation, SnackBarAndAlert } from 'components';
import styles from './update-password.module.scss';
import PasswordInput from 'components/password-input/password-input.container';

function UpdatePasswordComponent(props) {
    const {
        currentPassword,
        setCurrentPassword,
        newPassword,
        setNewPassword,
        confirmPassword,
        setConfirmPassword,
        isPasswordValid,
        changePassword,
        setIsPasswordValid,
        newPasswordChange,
        setNewPasswordChange,
        redoPasswordValidation,
        setRedoPasswordValidation,
        errorMessage,
        setErrorMessage,
        userDetails
    } = props;

    return (
        <Card className={styles.updatePasswordCard}>
            {errorMessage && (
                <SnackBarAndAlert open={errorMessage} type={'error'} onClose={() => setErrorMessage('')}>
                    {errorMessage}
                </SnackBarAndAlert>
            )}
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={styles.pageTitle}>Update Password</Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} sm={5} className={styles.content}>
                    <Grid container>
                        <Grid item xs={12} className={styles.inputContainer}>
                            <Label text="Current password" />
                            <div>
                                <PasswordInput
                                    id="current-password"
                                    type="password"
                                    placeHolder="Current password"
                                    required={true}
                                    password={currentPassword}
                                    onChange={(value) => {
                                        setCurrentPassword(value);
                                        setNewPasswordChange(false);
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={styles.inputContainer}>
                            <Label text="New password" />
                            <PasswordInput
                                id="new-password"
                                type="password"
                                placeHolder="New password"
                                required={true}
                                password={newPassword}
                                onChange={(value) => {
                                    setNewPassword(value);
                                    setNewPasswordChange(true);
                                }}
                            ></PasswordInput>
                        </Grid>
                        <Grid item xs={12} className={styles.inputContainer}>
                            <Label text="Confirm password" />
                            <PasswordInput
                                id="confirm-password"
                                type="password"
                                placeHolder="Confirm password"
                                required={true}
                                password={confirmPassword}
                                onChange={(value) => {
                                    setConfirmPassword(value);
                                    setRedoPasswordValidation(true);
                                }}
                            ></PasswordInput>
                        </Grid>
                        <Grid item xs={12} className={styles.buttonContainer}>
                            <Button
                                id="change-password"
                                className={styles.buttonUpdatePass}
                                color="primary"
                                variant="contained"
                                disabled={!isPasswordValid}
                                onClick={() => changePassword()}
                            >
                                Update password
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12} sm={5}>
                    <Grid container className={styles.validationNote}>
                        <Grid item xs={9}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>Password Requirements:</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10}>
                            <PasswordValidation
                                newPasswordChange={newPasswordChange}
                                currentPassword={currentPassword}
                                honorCurrentPassword={true}
                                newPassword={newPassword}
                                confirmPassword={confirmPassword}
                                setIsPasswordValid={setIsPasswordValid}
                                redoPasswordValidation={redoPasswordValidation}
                                setRedoPasswordValidation={setRedoPasswordValidation}
                                isPasswordValid={isPasswordValid}
                                email={userDetails.email}
                                firstName={userDetails.firstName}
                                lastName={userDetails.lastName}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}

UpdatePasswordComponent.propTypes = {
    currentPassword: PropTypes.string,
    setCurrentPassword: PropTypes.func,
    newPassword: PropTypes.string,
    setNewPassword: PropTypes.func,
    confirmPassword: PropTypes.string,
    setConfirmPassword: PropTypes.func,
    isPasswordValid: PropTypes.bool,
    changePassword: PropTypes.func,
    setDisabled: PropTypes.func,
    hasEightCharacters: PropTypes.bool,
    hasUpperCaseCharacters: PropTypes.bool,
    hasLowerCaseCharacters: PropTypes.bool,
    hasSpecialCharacters: PropTypes.bool,
    hasDigitCharacters: PropTypes.bool,
    setIsPasswordValid: PropTypes.func,
    crosstrack: PropTypes.func,
    newPasswordChange: PropTypes.bool,
    setNewPasswordChange: PropTypes.func,
    redoPasswordValidation: PropTypes.bool,
    setRedoPasswordValidation: PropTypes.func,
    errorMessage: PropTypes.func,
    setErrorMessage: PropTypes.func
};

export default UpdatePasswordComponent;
