import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Checkbox, makeStyles, ListItemText, ListItem } from '@material-ui/core';
import styles from './modal-filter-pdq-merchants.module.scss';

const useStyles = makeStyles({
    dialogstatus: {
        left: '30%',
        top: '2%',
        '@media (max-width: 400px)': {
            left: '10%',
            top: '15%'
        }
    },
    dailogAgent: {
        left: '30%',
        top: '-5%',
        '@media (max-width: 400px)': {
            left: '10%',
            top: '15%'
        }
    }
});

function ModalFilterPdqMerchantsComponent(props) {
    const {
        statusFilter,
        agentFilter,
        setStatusFilter,
        setAgentFilter,
        handleItemChange,
        isPdqStatusChecked,
        toggleAgentCheck,
        filteredAgentEmails
    } = props;

    const classes = useStyles();
    return (
        <Dialog
            open={statusFilter || agentFilter}
            onClose={() => {
                setStatusFilter('');
                setAgentFilter('');
            }}
            aria-labelledby="customized-dialog-title"
            classes={{
                paper: statusFilter ? classes.dialogstatus : classes.dailogAgent
            }}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            {statusFilter === true && (
                <React.Fragment>
                    {isPdqStatusChecked.map((status) => (
                        <ListItem key={status.id}>
                            <Checkbox
                                checked={status.checked}
                                onChange={() => handleItemChange(status.id)}
                                color="primary"
                            />
                            <ListItemText primary={status.name} />
                        </ListItem>
                    ))}
                </React.Fragment>
            )}
            {agentFilter === true && (
                <React.Fragment>
                    <div className={styles.agentFilter}>
                        {filteredAgentEmails.map((agent) => {
                            return (
                                <ListItem key={agent.id}>
                                    <Checkbox
                                        checked={agent.checked}
                                        onChange={() => toggleAgentCheck(agent.id)}
                                        color="primary"
                                    />
                                    <ListItemText primary={agent.name} />
                                </ListItem>
                            );
                        })}
                    </div>
                </React.Fragment>
            )}
        </Dialog>
    );
}

ModalFilterPdqMerchantsComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.string
};

export default ModalFilterPdqMerchantsComponent;
