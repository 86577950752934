import React from 'react';
import { Form, Button, Spinner, Row, Col, Alert, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './search-user-form.module.scss';

function SearchUserFormComponent(props) {
    const {
        show,
        setShow,
        isLoading,
        email,
        setEmail,
        formSubmit,
        user,
        assignClick,
        error,
        errorMessage,
        success,
        isLoadingSubmit,
        inviteFlow,
        roles,
        selectedRole,
        setSelectedRole,
        existsRelationship,
        setExistsRelationship,
        isDisabledChangeRoleButton
    } = props;

    return (
        <Modal centered size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header className="modalHeader">Assign a user to business</Modal.Header>
            <Modal.Body>
                <Form onSubmit={formSubmit} className={styles.searchUserForm}>
                    <Form.Group>
                        <Form.Control
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            type="email"
                            placeholder="Enter Email"
                        />
                    </Form.Group>
                    {isLoading && (
                        <div className={styles.loadingSubmit}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                    <div className={styles.searchBtn}>
                        <Button id="search-btn" variant="primary" type="submit">
                            Search
                        </Button>
                    </div>
                    {inviteFlow && (
                        <Row className={styles.userResult}>
                            <Col>
                                User does not exist. <Button size="sm">Invite User</Button>
                            </Col>
                        </Row>
                    )}
                    {user && (
                        <Row className={styles.userResult}>
                            <Col className={styles.namesColumn}>
                                <span className={styles.names}>
                                    {user.firstName} {user.lastName}
                                </span>
                            </Col>
                            <Col className={styles.emailColumn}>{user.email}</Col>
                            <Col xs={5}>
                                <span>Role: </span>
                                <Form.Control
                                    as="select"
                                    disabled={existsRelationship}
                                    value={selectedRole}
                                    className={styles.selectRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                >
                                    {roles.map((role) => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col md="auto">
                                {existsRelationship ? (
                                    <Button
                                        id="submit-user-btn"
                                        size="sm"
                                        onClick={() => setExistsRelationship(false)}
                                        disabled={isDisabledChangeRoleButton}
                                        className={styles.selectBtn}
                                    >
                                        Change role
                                    </Button>
                                ) : (
                                    <Button
                                        id="submit-user-btn"
                                        size="sm"
                                        onClick={() => assignClick(user.id)}
                                        className={styles.selectBtn}
                                    >
                                        Assign
                                    </Button>
                                )}
                                {isLoadingSubmit && (
                                    <div className={styles.loadingSubmit}>
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                    {error && (
                        <Row>
                            <Alert className={styles.alert} variant="danger">
                                {errorMessage}
                            </Alert>
                        </Row>
                    )}
                    {success && (
                        <Row>
                            <Alert className={styles.alert} variant="success">
                                Operation completed successfully
                            </Alert>
                        </Row>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

SearchUserFormComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.string,
    isLoading: PropTypes.bool,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    formSubmit: PropTypes.func,
    user: PropTypes.object,
    assignClick: PropTypes.func,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    success: PropTypes.bool,
    isLoadingSubmit: PropTypes.bool,
    inviteFlow: PropTypes.bool,
    roles: PropTypes.array,
    selectedRole: PropTypes.string,
    setSelectedRole: PropTypes.func,
    existsRelationship: PropTypes.bool,
    setExistsRelationship: PropTypes.func,
    isDisabledChangeRoleButton: PropTypes.func
};

export default SearchUserFormComponent;
