import React from 'react';

import style from './listchargeback.module.scss';
import PropTypes from 'prop-types';
import { getComparator, stableSort, toTitleCase, formatDateTimeByFormatStringV2 } from '../../utils/helper';
import { useGlobalStateContext } from 'contexts';
import ModalChargebackAction from 'components/modal-chargeback-list-action/modal-chargeback-list-action.container';
import { SnackBarAndAlert } from 'components';
import { Link } from 'react-router-dom';

import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    Button,
    Grid,
    Paper,
    Container,
    TextField,
    CircularProgress,
    TableFooter,
    TablePagination,
    Box,
    makeStyles
} from '@material-ui/core';
import TableHeader from '../payments/transaction-tables/table-header/table-header.container';
const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tableCellSmallSize: {
        '& th': {
            minWidth: '147px',
            maxWidth: '147px'
        }
    }
}));

const ListchargebackComponent = (props) => {
    const { getRoute } = useGlobalStateContext();

    const {
        rows,
        loading,

        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,

        headCells,

        setLoading,
        openModal,
        setOpenModal,
        handleModal,
        setTotalAmount,
        totalAmount,
        fetchChargebackList,
        chargebackStatus,
        setChargebackStatus,
        chargebackTransactionId,
        setChargebackTransactionId,
        chargebackListStatus,
        setChargebackListStatus,
        snackbarMessage,
        setSnackbarMessage,
        showSnackbarStatus,
        setShowSnackbarStatus,
        setTxSearchTerm,
        onSearchbyTx,
        defaultchargeback,
        txSearchTerm,
        chargebackCreated,
        setChargebackCreated
    } = props;
    const classes = useStyles();

    const alertMessage = () => {
        setShowSnackbarStatus(false);
    };

    return (
        <Container className={style.mainContainer} maxWidth={false}>
            <Grid xs={12} sm={12} md={12} className={style.searchArea} container direction="row">
                <Grid item xs={4} sm={4} className={style.listChargeback} z-index={0}>
                    <TextField
                        className={style.searchMerchants}
                        id="pending-merchants-search"
                        size="small"
                        label="TxAuth Code"
                        type="search"
                        variant="outlined"
                        autoComplete="off"
                        value={txSearchTerm}
                        onChange={(e) => setTxSearchTerm(e.target.value)}
                    />
                    <Button
                        id="pending-merchants-search-button"
                        className={style.searchButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={onSearchbyTx}
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={4} sm={4} className={style.listChargeback}>
                    <TextField
                        className={style.searchMerchants}
                        id="pending-merchants-search-two"
                        size="small"
                        label="Total"
                        type="number"
                        variant="outlined"
                        autoComplete="off"
                        value={totalAmount}
                        onChange={(e) => setTotalAmount(e.target.value)}
                    />
                    <Button
                        id="pending-merchants-search-button"
                        className={style.searchButton}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={fetchChargebackList}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
            <Paper className={style.paper}>
                {loading ? (
                    <Box textAlign="center" py={5}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <React.Fragment>
                        <TableContainer className={style.TableContainer}>
                            <Table
                                aria-label="simple table"
                                aria-labelledby="tableTitle"
                                size="small"
                                stickyHeader
                                component={Paper}
                                sx={{ minWidth: 650 }}
                                className={classes.tableCellSmallSize}
                            >
                                <TableHeader classes={classes} headCells={headCells} {...props} />
                                {console.log(rows)}
                                {!rows || rows.length === 0 ? (
                                    <TableRow>
                                        <TableCell className={style.chargebackNone} colSpan={8}>
                                            <div>No Chargeback List</div>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <React.Fragment>
                                        <TableBody>
                                            {stableSort(
                                                rowsPerPage > 0
                                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : rows,
                                                getComparator()
                                            ).map((row, index) => {
                                                return (
                                                    <>
                                                        <TableRow>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.payment_time ? (
                                                                    formatDateTimeByFormatStringV2(
                                                                        row.payment_time,
                                                                        'DD MMM HH:mm'
                                                                    )
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                <Link
                                                                    type="button"
                                                                    target="_blank"
                                                                    to={getRoute(`/admin-functions/${row.merchant_id}`)}
                                                                >
                                                                    {row.merchant_id}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.payment_status ? (
                                                                    row.payment_status
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>

                                                            <TableCell className={style.tableDataCell}>
                                                                {row.amount ? row.amount : <span>&mdash;</span>}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.cross_reference ? (
                                                                    row.cross_reference
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.tx_auth_no ? row.tx_auth_no : <span>&mdash;</span>}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.payment_provider ? (
                                                                    toTitleCase(row.payment_provider)
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.last_4_digits ? (
                                                                    row.last_4_digits
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.firstname.trim() ? (
                                                                    row.firstname
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.address ? row.address : <span>&mdash;</span>}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                {row.telephoneNumber ? (
                                                                    row.telephoneNumber
                                                                ) : (
                                                                    <span>&mdash;</span>
                                                                )}
                                                            </TableCell>
                                                            <TableCell className={style.tableDataCell}>
                                                                <Button
                                                                    className={style.searchButton}
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={row.payment_status !== 'OK'}
                                                                    onClick={() => {
                                                                        handleModal(
                                                                            row.charge_back_exists,
                                                                            row.created_at,
                                                                            row.cb_reason,
                                                                            row.comments,
                                                                            row.outcome,
                                                                            row.transaction_id
                                                                        );
                                                                        setChargebackTransactionId(row.transaction_id);
                                                                    }}
                                                                >
                                                                    <span className={style.processRefundButton}>
                                                                        Action
                                                                    </span>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                );
                                            })}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                {rows.length > rowsPerPage && (
                                                    <TablePagination
                                                        rowsPerPageOptions={[20, 50, 100, { label: 'All', value: -1 }]}
                                                        // colSpan={6}
                                                        count={rows.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { 'aria-label': 'rows per page' },
                                                            native: true
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                )}
                                            </TableRow>
                                        </TableFooter>
                                    </React.Fragment>
                                )}
                            </Table>
                        </TableContainer>
                        {chargebackListStatus ? (
                            <>
                                <SnackBarAndAlert open={showSnackbarStatus} type={'success'} onClose={alertMessage}>
                                    {snackbarMessage}
                                </SnackBarAndAlert>
                            </>
                        ) : (
                            <SnackBarAndAlert open={showSnackbarStatus} type={'error'} onClose={alertMessage}>
                                {snackbarMessage}
                            </SnackBarAndAlert>
                        )}
                        <ModalChargebackAction
                            setOpenModal={setOpenModal}
                            openModal={openModal}
                            setLoading={setLoading}
                            loading={loading}
                            chargebackStatus={chargebackStatus}
                            chargebackTransactionId={chargebackTransactionId}
                            setChargebackListStatus={setChargebackListStatus}
                            chargebackListStatus={chargebackListStatus}
                            snackbarMessage={snackbarMessage}
                            setSnackbarMessage={setSnackbarMessage}
                            showSnackbarStatus={showSnackbarStatus}
                            setShowSnackbarStatus={setShowSnackbarStatus}
                            setChargebackStatus={setChargebackStatus}
                            defaultchargeback={defaultchargeback}
                            setChargebackCreated={setChargebackCreated}
                            chargebackCreated={chargebackCreated}
                        />
                    </React.Fragment>
                )}
            </Paper>
        </Container>
    );
};
export default ListchargebackComponent;

ListchargebackComponent.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array,
    error: PropTypes.bool,
    onchargebackProcess: PropTypes.func,
    chargebackStatusFilter: PropTypes.string,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    chargebackFilterUpdate: PropTypes.func,
    setSearchMerchant: PropTypes.func,
    setchargebackStatus: PropTypes.func
};
