export class ErrorResponse {
    constructor(data, status) {
        this.data = data;
        this.isSuccesfully = false;
        this.status = status;
        this.error = data;
    }
}

export class SuccesResponse {
    constructor(data, status) {
        this.data = data;
        this.isSuccesfully = true;
        this.status = status;
    }
}
