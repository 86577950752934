import React from 'react';
import AdminDashBoardNavigationHeaderComponent from './admin-dashboard-navigation-header.component';

function AdminDashBoardNavigationHeader(props) {
    return <AdminDashBoardNavigationHeaderComponent {...props} />;
}

AdminDashBoardNavigationHeader.propTypes = {};

export default AdminDashBoardNavigationHeader;
