import React from 'react';
import styles from './urgent-message.module.scss';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { SnackBarAndAlert } from 'components';
import { Alert } from '@material-ui/lab';
import { UserType } from 'utils/enums/UserType';
import { useGlobalStateContext } from 'contexts';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Input } from 'components/onboarding/onboarding-ui-components';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import ValidUrlInput from './url-input-field/url-input-field.container';

function UrgentMessageComponent(props) {
    const { globalState } = useGlobalStateContext();
    const {
        urgentMessage,
        onEditUrgentMessage,
        onDeleteUrgentMessage,
        showDeleteConfirmationDialog,
        setshowDeleteConfirmationDialog,
        success,
        setSuccess,
        error,
        setError,
        formItems,
        setFormItems,
        isEdit,
        setSelectedEndDate,
        isDisable,
        characterCount,
        setCharacterCount,
        setIsShowForm,
        isShowForm,
        dateError,
        isButtonDisable,
        isDeleteBtnDisable,
        showDomainValidationMessage,
        setShowDomainValidationMessage,
        setIsFocused
    } = props;
    const isAdmin =
        globalState.user?.UserType?.name === UserType.Admin || globalState.user?.UserType?.name === UserType.SuperAdmin;
    const isSuperAdmin = globalState.user?.UserType?.name === UserType.SuperAdmin;

    return (
        <div className={styles.urgentMessageBanner}>
            {isShowForm ? (
                <Grid container spacing={2} className={styles.formContainer}>
                    <div className={styles.datePickerContainer}>
                        <div className={styles.startDatePick}>
                            <div>Start date:</div>
                            <div className={styles.datePicker}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <Input
                                        id="date-time-picker-inline"
                                        name="startDate"
                                        type="datetime-local"
                                        inputVariant="outlined"
                                        required={true}
                                        disableToolbar={true}
                                        value={moment(urgentMessage.startDate).format('YYYY-MM-DD HH:mm:ss')}
                                        disabled={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className={styles.endDateDatePick}>
                            <div>End date:</div>
                            <div className={styles.datePicker}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <Input
                                        id="date-time-picker-inline"
                                        name="endDate"
                                        type="datetime-local"
                                        inputVariant="outlined"
                                        required={true}
                                        disableToolbar={true}
                                        value={
                                            formItems
                                                ? moment(formItems.endDate).format('YYYY-MM-DD HH:mm:ss')
                                                : moment(urgentMessage.endDate).format('YYYY-MM-DD HH:mm:ss')
                                        }
                                        onChange={(e) => {
                                            setSelectedEndDate(e.target.value);
                                            setFormItems({
                                                ...formItems,
                                                endDate: e.target.value ? e.target.value : urgentMessage.endDate
                                            });
                                        }}
                                        inputProps={{
                                            max: '9999-12-31',
                                            min: new Date().toISOString().slice(0, 16),
                                            default: moment(urgentMessage.endDate).format('YYYY-MM-DD HH:mm:ss')
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>

                    {dateError && (
                        <Grid item xs={12} sm={10}>
                            <div className={styles.notificationMessageContainer}>
                                <Typography color="error" style={{ marginTop: '-10px', fontSize: '14px' }}>
                                    {dateError}
                                </Typography>
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={11}>
                        <TextField
                            className={styles.inputMessage}
                            value={formItems.subject}
                            label="Subject"
                            variant="outlined"
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) =>
                                setFormItems({
                                    ...formItems,
                                    subject: e.target.value.replace(/\s\s+/g, '')
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={11} style={{ display: 'flex', gap: '10px' }}>
                        <Grid item xs={8} sm={8}>
                            <ValidUrlInput
                                labelText="URL"
                                labelId="website-url"
                                id="website-url-input"
                                value={formItems.url}
                                setIsFocused={setIsFocused}
                                onChange={(value) => {
                                    setFormItems({
                                        ...formItems,
                                        url: value
                                    });
                                    if (value.length === 0) {
                                        setFormItems({
                                            ...formItems,
                                            btnText: '',
                                            url: value
                                        });
                                    }
                                }}
                                inputProps={{ maxLength: '1024', inputMode: 'text' }}
                                showDomainValidationMessage={showDomainValidationMessage}
                                setShowDomainValidationMessage={setShowDomainValidationMessage}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <TextField
                                className={styles.inputMessage}
                                value={formItems.btnText}
                                label="Button Label"
                                inputProps={{ maxLength: 20 }}
                                variant="outlined"
                                disabled={formItems?.url?.length === 0}
                                onChange={(e) => {
                                    setFormItems({
                                        ...formItems,
                                        btnText: e.target.value.replace(/\s\s+/g, '')
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            className={styles.inputMessage}
                            value={formItems.message}
                            label="Message"
                            inputProps={{ maxLength: 250 }}
                            autoFocus
                            multiline
                            required
                            rows={3}
                            variant="outlined"
                            onChange={(e) =>
                                setFormItems(
                                    {
                                        ...formItems,
                                        message: e.target.value.replace(/\s\s+/g, '')
                                    },
                                    setCharacterCount(e.target.value.length)
                                )
                            }
                        />

                        <h4 className={styles.charCount}>{characterCount}/250</h4>
                    </Grid>
                </Grid>
            ) : (
                <div className={styles.messageUrgentMessage}>
                    <span className={styles.subject}>{urgentMessage.subject ? urgentMessage.subject + ': ' : ''}</span>
                    {urgentMessage.message}
                </div>
            )}
            {!isShowForm && urgentMessage.url && (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={styles.cancelBtn}
                    onClick={() => {
                        window.open(urgentMessage.url);
                    }}
                >
                    {urgentMessage?.btnText}
                </Button>
            )}
            {isAdmin && (
                <div className={styles.buttonsUrgentMessage}>
                    {isShowForm && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={styles.cancelBtn}
                            disabled={isButtonDisable}
                            onClick={() => {
                                setIsShowForm(false);
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                    {isSuperAdmin && (
                        <Button
                            id="editUrgentMessageButton"
                            disabled={isShowForm && isDisable}
                            onClick={() => {
                                if (isShowForm) {
                                    onEditUrgentMessage();
                                } else {
                                    setFormItems({
                                        startDate: new Date(urgentMessage.startDate),
                                        endDate: new Date(urgentMessage.endDate),
                                        subject: urgentMessage.subject,
                                        message: urgentMessage.message,
                                        url: urgentMessage?.url?.replace(/^https?:\/\//, ''),
                                        btnText: urgentMessage?.btnText
                                    });
                                }
                                setIsShowForm(true);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            {isShowForm ? 'Save' : 'Edit'}
                        </Button>
                    )}

                    {isSuperAdmin && (
                        <Delete
                            className={styles.deleteIcon}
                            style={
                                isButtonDisable
                                    ? { color: 'rgba(0, 0, 0, 0.12)', cursor: 'not-allowed' }
                                    : { color: 'black' }
                            }
                            onClick={() =>
                                isButtonDisable
                                    ? setshowDeleteConfirmationDialog(false)
                                    : setshowDeleteConfirmationDialog(true)
                            }
                        />
                    )}
                </div>
            )}
            {showDeleteConfirmationDialog && (
                <Dialog open={showDeleteConfirmationDialog} onClose={() => setshowDeleteConfirmationDialog(false)}>
                    <DialogTitle>Delete urgent message</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <div>Are you sure you wish to delete the urgent message?</div>
                            {error && (
                                <Alert className={styles.errorAlert} onClose={() => setError(false)} severity="error">
                                    An error occurred. Please contact support or try again.
                                </Alert>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={styles.actionsContainer}>
                        <Button
                            id="cancelDeleteUrgentMessage"
                            onClick={() => setshowDeleteConfirmationDialog(false)}
                            color="primary"
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            id="submitDeleteUrgentMessage"
                            onClick={onDeleteUrgentMessage}
                            color="primary"
                            variant="contained"
                            disabled={isDeleteBtnDisable}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {success && (
                <SnackBarAndAlert open={success} onClose={() => setSuccess(false)} type="success">
                    {isEdit ? 'Urgent message was edited successfully.' : 'Urgent message was deleted successfully.'}
                </SnackBarAndAlert>
            )}
            {error && isEdit && (
                <SnackBarAndAlert open={error && isEdit} onClose={() => setError(false)} type="error">
                    An error occurred. Please contact support or try again.
                </SnackBarAndAlert>
            )}
        </div>
    );
}

UrgentMessageComponent.propTypes = {
    urgentMessage: PropTypes.object,
    onEditUrgentMessage: PropTypes.func,
    onDeleteUrgentMessage: PropTypes.func,
    showDeleteConfirmationDialog: PropTypes.bool,
    setshowDeleteConfirmationDialog: PropTypes.func,
    success: PropTypes.bool,
    setSuccess: PropTypes.func,
    error: PropTypes.bool,
    setError: PropTypes.func,
    formItems: PropTypes.object,
    setFormItems: PropTypes.func,
    isEdit: PropTypes.bool,
    setCharacterCount: PropTypes.number
};

export default UrgentMessageComponent;
