import React, { useState, useEffect, useRef } from 'react';
import NewZealandAccountNumberComponent from './new-zealand-account-number.component';

function NewZealandAccountNumberContainer(props) {
    const { value, onChange, focusFirst, nextElementRef, disabled, onBlur } = props;

    const [firstInputValues, setFirstInputValues] = useState('');
    const [secondInputValues, setSecondInputValues] = useState('');
    const [thirdInputValues, setThirdInputValues] = useState('');

    const firstInputElementRef = useRef(null);
    const secondInputElementRef = useRef(null);
    const thirdInputElementRef = useRef(null);

    useEffect(() => {
        if (firstInputElementRef && focusFirst) {
            firstInputElementRef.current.focus();
        }
    }, [focusFirst]);

    useEffect(() => {
        if (value?.length === 16) {
            setFirstInputValues(value.substr(0, 6));
            setSecondInputValues(value.substr(6, 7));
            setThirdInputValues(value.substr(13, 3));
        } else if (value?.length === 15) {
            setFirstInputValues(value.substr(0, 6));
            setSecondInputValues(value.substr(6, 7));
            setThirdInputValues(value.substr(13, 2));
        }
    }, [value]);

    const firstInputChangeHandler = ({ value }) => {
        setFirstInputValues(value);

        if (value.length > 5) {
            setTimeout(() => {
                secondInputElementRef.current.focus();
            });
        }
    };

    const secondInputChangeHandler = ({ value }) => {
        setSecondInputValues(value);

        if (value.length > 6) {
            setTimeout(() => {
                thirdInputElementRef.current.focus();
            });
        }
    };

    const thirdInputChangeHandler = ({ value }) => {
        setThirdInputValues(value);

        if (value.length > 2) {
            setTimeout(() => {
                //thirdInputElementRef.current.blur();
                if (nextElementRef && nextElementRef.current) {
                    nextElementRef.current.focus();
                }
            });
        }
    };

    useEffect(() => {
        const toSendValues = `${firstInputValues}${secondInputValues}${thirdInputValues}`;

        if (toSendValues !== value && onChange) {
            onChange(toSendValues);
        }
    }, [firstInputValues, secondInputValues, thirdInputValues, onChange, value]);

    return (
        <NewZealandAccountNumberComponent
            disabled={disabled}
            hideDashes={props.hideDashes}
            firstInputValues={firstInputValues}
            secondInputValues={secondInputValues}
            thirdInputValues={thirdInputValues}
            firstInputChangeHandler={firstInputChangeHandler}
            secondInputChangeHandler={secondInputChangeHandler}
            thirdInputChangeHandler={thirdInputChangeHandler}
            firstInputElementRef={firstInputElementRef}
            secondInputElementRef={secondInputElementRef}
            thirdInputElementRef={thirdInputElementRef}
            onBlur={onBlur}
        />
    );
}
export default NewZealandAccountNumberContainer;
