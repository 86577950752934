import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PdqListTableComponent from './pdq-list-table.component';
import { PdqFilterTabs } from 'utils/enums/PdqFilterTabs.js';
import { useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import { statusToFilter } from 'utils/enums/pdqTransactionStatus';

const PdqListTable = (props) => {
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('date_time');
    const [statusFilter, setStatusFilter] = useState(PdqFilterTabs.All);
    const [page, setPage] = useState(0);
    const [refundValue, setRefundValue] = useState(0);
    const [total, setTotal] = useState();
    const { list, loading, error } = props;
    const { globalState } = useGlobalStateContext();

    const isOmnipay = globalState.reseller?.name === ResellerType.OMNIPAY;
    useEffect(() => {
        setPage(0);
        let dataRows = [];
        if (statusFilter !== PdqFilterTabs.All) {
            dataRows = list.filter(
                (list) => list.refund_status === statusFilter && list.transaction_status_id === statusToFilter.Success
            );
        } else {
            dataRows = list;
        }
        setRows(dataRows);
        let totalValue = 0;
        let refund = 0;

        for (let i = 0; i < list.length; i += 1) {
            if (
                list[i].refund_status === PdqFilterTabs.Refunded &&
                list[i].transaction_status_id === statusToFilter.Success
            ) {
                refund += parseFloat(list[i].total);
            } else if (
                list[i].refund_status === PdqFilterTabs.Successful &&
                list[i].transaction_status_id === statusToFilter.Success
            ) {
                totalValue += parseFloat(list[i].total);
            }
        }
        setRefundValue(refund);
        setTotal(totalValue);
    }, [list, statusFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const headCells = [
        { id: 'total', numeric: false, disablePadding: false, label: 'Amount' },
        { id: 'date_time', numeric: true, disablePadding: false, label: 'Date' },
        { id: 'auth_code', numeric: false, disablePadding: false, label: 'Auth Code', hideSortingIcon: true },
        {
            id: 'transaction_status_id',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            hideSortingIcon: true,
            notShow: !isOmnipay || statusFilter !== PdqFilterTabs.All
        }
    ];

    return (
        <PdqListTableComponent
            loading={loading}
            rows={rows}
            setStatusFilter={setStatusFilter}
            statusFilter={statusFilter}
            headCells={headCells}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            handleRequestSort={handleRequestSort}
            error={error}
            page={page}
            setPage={setPage}
            refundValue={refundValue}
            total={total}
            isOmnipay={isOmnipay}
            globalState={globalState}
            statusToFilter={statusToFilter}
        />
    );
};

export default PdqListTable;

PdqListTable.propTypes = {
    rows: PropTypes.array,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    handleRequestSort: PropTypes.func
};
