import React from 'react';
import { Link } from 'react-router-dom';
import styles from './forgot-password.module.scss';
// Style Deps
import { Form } from 'react-bootstrap';
import { getArrayFromString, checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import { useGlobalStateContext } from 'contexts';
import { PasswordValidation, SnackBarAndAlert, LoadingScreen, ValidEmailInput } from 'components';
import { Button, Grid, Typography, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import PasswordInput from 'components/password-input/password-input.container';

const FormForgotPassword = (props) => {
    const { globalState, getRoute } = useGlobalStateContext();
    const {
        isPasswordValid,
        setIsPasswordValid,
        errorCodeMsg,
        setErrorCodeMsg,
        verificationCode,
        setVerificationCode,
        newPassword,
        setNewPassword,
        confirmPassword,
        setConfirmPassword,
        setIsVerificationCodeError,
        redoPasswordValidation,
        setRedoPasswordValidation,
        sendVerificationEmail,
        showMessage,
        setShowMessage,
        verificationEmailMessage,
        messageType,
        errorExceptionType,
        disableButton,
        email,
        firstName,
        lastName
    } = props;

    return (
        <React.Fragment>
            <div className={styles.homeLogo}>
                <img src={globalState.reseller?.logo} alt="Logo"></img>
            </div>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <h1 className="display-4 text-center mb-3">Password reset</h1>
                    <div className="text-muted text-center mb-5">
                        {props.submitView === 'SEND-OTP' ? (
                            <span>
                                Enter your email to get a verification code. <br />
                                The code will be sent via SMS if you&#39;ve provided a valid phone number, otherwise
                                you&#39;ll get it via email.
                            </span>
                        ) : (
                            <span>Enter your verification code and the new password</span>
                        )}
                    </div>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        className={props.submitView === 'SUBMIT-OTP' ? styles.submitOtpFormWrapper : ''}
                    >
                        <Grid item xs={12} sm={5} className={styles.content}>
                            <Form onSubmit={props.handleSubmit} hidden={props.submitView === 'SUBMIT-OTP'}>
                                <Form.Group>
                                    <ValidEmailInput
                                        labelText="Username (email address)"
                                        labelId="email-input-id-reset-password"
                                        value={props.email}
                                        onChange={(e) => props.setEmail(e.target.value.toLowerCase())}
                                        disabled={props.submitView === 'SUBMIT-OTP'}
                                        required
                                        autoFocus
                                        type="email"
                                        name="email"
                                        placeholder="name@address.com"
                                    />
                                    <div hidden={true}>
                                        <p className="text-muted text-center mt-4 font-weight-bold"> Or </p>
                                        <Form.Label>Phone number</Form.Label>
                                        <Form.Control
                                            name="phone"
                                            type="number"
                                            placeholder="0712345678"
                                            autoFocus
                                            value={props.phone}
                                            onChange={(e) => props.setPhone(e.target.phone)}
                                            disabled={props.submitView === 'SUBMIT-OTP'}
                                        />
                                    </div>
                                </Form.Group>
                                {verificationEmailMessage && (
                                    <SnackBarAndAlert
                                        open={showMessage}
                                        onClose={() => setShowMessage(false)}
                                        type={messageType}
                                    >
                                        {verificationEmailMessage}
                                    </SnackBarAndAlert>
                                )}
                                {props.isSuccess && (
                                    <Dialog
                                        open={props.showPopup}
                                        classes={{
                                            paper: styles.paper
                                        }}
                                        onClose={() => props.setShowPopup(false)}
                                        aria-labelledby="draggable-dialog-title"
                                    >
                                        <DialogContent>
                                            <DialogContentText>{props.message}</DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                id="cancelButtonCreateMerchant-confirmation"
                                                autoFocus
                                                onClick={() => props.setShowPopup(false)}
                                                color="primary"
                                                className={styles.primaryActionBtn}
                                                variant="contained"
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                                {!props.isSuccess && (
                                    <Dialog
                                        open={props.showPopup}
                                        classes={{
                                            paper: styles.paper
                                        }}
                                        onClose={() => props.setShowPopup(false)}
                                        aria-labelledby="draggable-dialog-title"
                                    >
                                        <DialogContent>
                                            <DialogContentText>
                                                {`${props.message}`}
                                                {errorExceptionType === 'InvalidParameterException' && (
                                                    <span>
                                                        Please
                                                        <span
                                                            className={`${styles.textBold} ${styles.linkFont}`}
                                                            onClick={sendVerificationEmail}
                                                        >
                                                            &nbsp; click here &nbsp;
                                                        </span>
                                                        to receive the verification email. first verify the email, to
                                                        reset the password.
                                                    </span>
                                                )}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                id="cancelButtonCreateMerchant-confirmation"
                                                autoFocus
                                                onClick={() => props.setShowPopup(false)}
                                                color="primary"
                                                variant="contained"
                                                className={styles.primaryActionBtn}
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}

                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    className={styles.primaryActionBtn}
                                    disabled={props.loading || disableButton}
                                >
                                    {props.loading && (
                                        <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} />
                                    )}
                                    {props.loading && <span>Sending verification code...</span>}
                                    {!props.loading && <span>Send verification code</span>}
                                </Button>
                            </Form>

                            <Form onSubmit={props.handleSubmit} hidden={props.submitView === 'SEND-OTP'}>
                                <Form.Group>
                                    {newPassword.length || confirmPassword.length ? (
                                        <Grid item xs={10}>
                                            <div className={styles.password_errors}>
                                                <Typography>Password Requirements:</Typography>
                                                <PasswordValidation
                                                    honorCurrentPassword={false}
                                                    newPassword={newPassword}
                                                    confirmPassword={confirmPassword}
                                                    setIsPasswordValid={setIsPasswordValid}
                                                    redoPasswordValidation={redoPasswordValidation}
                                                    setRedoPasswordValidation={setRedoPasswordValidation}
                                                    email={email}
                                                    firstName={firstName}
                                                    lastName={lastName}
                                                />
                                            </div>
                                        </Grid>
                                    ) : null}

                                    <Form.Label>Verification code</Form.Label>
                                    <Form.Control
                                        name="confirmationCode"
                                        type="number"
                                        placeholder="6 digit verification code"
                                        isInvalid={errorCodeMsg}
                                        required
                                        autoFocus
                                        value={checkEmptyValue(verificationCode)}
                                        onChange={(e) => {
                                            if (e.target?.value?.length > 6) {
                                                return;
                                            }
                                            const verificationCodeRegEx = /^[0-9\b]+$/;
                                            if (e.target.value === '' || verificationCodeRegEx.test(e.target.value)) {
                                                setVerificationCode(e.target.value);
                                                if (errorCodeMsg && e.target?.value?.length === 6) {
                                                    setErrorCodeMsg(false);
                                                }
                                            }
                                        }}
                                        onBlur={() => {
                                            setErrorCodeMsg(
                                                getArrayFromString(verificationCode).length > 0 &&
                                                    getArrayFromString(verificationCode).length < 6
                                                    ? true
                                                    : false
                                            );
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errorCodeMsg ? 'Please Enter Valid 6 digit code' : ''}
                                    </Form.Control.Feedback>
                                    <Form.Label>New password</Form.Label>
                                    <PasswordInput
                                        name="newPassword"
                                        placeholder="New password"
                                        required
                                        type="password"
                                        password={newPassword}
                                        onChange={(value) => {
                                            setNewPassword(value);
                                            setRedoPasswordValidation(true);
                                        }}
                                    />
                                    <Form.Label>Confirm password</Form.Label>
                                    <PasswordInput
                                        name="confirmPassword"
                                        placeholder="Confirm password"
                                        autoFocus
                                        required
                                        type="password"
                                        password={confirmPassword}
                                        onChange={(value) => {
                                            setConfirmPassword(value);
                                            setRedoPasswordValidation(true);
                                        }}
                                    />
                                </Form.Group>
                                {!props.isSuccess && props.message && props.isVerificationCodeError && (
                                    <SnackBarAndAlert
                                        open={!props.isSuccess && props.isVerificationCodeError}
                                        onClose={() => {
                                            setIsVerificationCodeError(false);
                                            setErrorCodeMsg(false);
                                        }}
                                        type="error"
                                    >
                                        {props.message}{' '}
                                        <Link className={styles.linkNewRequest} onClick={() => props.onNewRequest()}>
                                            Please request another verification code.
                                        </Link>
                                    </SnackBarAndAlert>
                                )}
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    className={styles.primaryActionBtn}
                                    disabled={props.loading || !isPasswordValid || !verificationCode}
                                >
                                    {props.loading && (
                                        <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} />
                                    )}
                                    {props.loading && <span>Resetting password...</span>}
                                    {!props.loading && <span>Reset Password</span>}
                                </Button>
                            </Form>
                        </Grid>
                        <Grid item xs={1} hidden={props.submitView !== 'SUBMIT-OTP'}></Grid>
                        <Grid item xs={12} sm={5} hidden={props.submitView !== 'SUBMIT-OTP'}></Grid>
                    </Grid>
                    <br />
                    <Grid container justify="center">
                        Remember your password?&nbsp;
                        <Link to={getRoute(`/login`)}>Sign in</Link>
                    </Grid>
                </Grid>
            </Grid>
            <LoadingScreen open={props.loading}></LoadingScreen>
        </React.Fragment>
    );
};
// FormForgotPassword.propTypes = {
//     errorCodeMsg: PropTypes.bool,
//     setErrorCodeMsg: PropTypes.func,
// };
export default FormForgotPassword;
