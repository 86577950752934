import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import styles from './modal-confirmation.module.scss';

function ModalConfirmationComponent(props) {
    const { show, setShow, onSubmit, children } = props;

    return (
        <Modal
            show={show}
            className={styles.modalConfirmation}
            dialogClassName={styles.modalDialog}
            onHide={() => setShow(false)}
            animation={true}
        >
            <Modal.Header className={styles.modalHeader + ' modalHeader'}>
                <h3>Confirmation</h3>
            </Modal.Header>
            <Modal.Body className={styles.modalBody}>
                <div className={styles.text}>
                    <p>{children}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    No
                </Button>
                <Button
                    variant="info"
                    onClick={() => {
                        setShow(false);
                        onSubmit();
                    }}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

ModalConfirmationComponent.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.string
};

export default ModalConfirmationComponent;
