import React, { useState, useEffect } from 'react';

import BankSortCodeDetailsComponent from './bank-sort-code-details.component';
import { useGlobalStateContext } from 'contexts';
import { BankService } from 'services';

function BankSortCodeDetailsContainer() {
    const { globalState } = useGlobalStateContext();
    const asterisks = '*********';
    const [accountNumber, setAccountNumber] = useState(asterisks);
    const [sortCode, setSortCode] = useState(asterisks);
    const [bsbCode, setBsbCode] = useState(asterisks);
    const [routeNum, setRouteNum] = useState(asterisks);

    useEffect(() => {
        const fetchData = async () => {
            const response = await BankService.getMaskedBankDetails(globalState.merchant?.id);
            if (response.isSuccesfully) {
                setSortCode(response.data.sortCode);
                setAccountNumber(response.data.accountNumber);
                setBsbCode(response.data.bsbCode);
                setRouteNum(response.data.routingNumber);
            } else {
                setSortCode(asterisks);
                setAccountNumber(asterisks);
                setRouteNum(asterisks);
            }
        };
        fetchData();
    }, [globalState.merchant]);

    return (
        <BankSortCodeDetailsComponent
            sortCode={sortCode}
            accountNumber={accountNumber}
            bsbCode={bsbCode}
            routeNum={routeNum}
        ></BankSortCodeDetailsComponent>
    );
}
export default BankSortCodeDetailsContainer;
