import { Chip, Tooltip } from '@material-ui/core';
import React from 'react';
import styles from './gift-card-lable-chip.module.scss';

const GitfCardLableChipComponent = (props) => {
    const { titleText, chipLable } = props.status;
    return (
        <Tooltip title={<span dangerouslySetInnerHTML={{ __html: titleText }} />} arrow>
            <Chip label={chipLable} className={styles.chipCard} />
        </Tooltip>
    );
};

export default GitfCardLableChipComponent;
