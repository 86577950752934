import React from 'react';
import 'jspdf-autotable';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useGlobalStateContext } from 'contexts';
import { ResellerType } from 'utils/enums/ResellerType';
import Papa from 'papaparse';
import { pdfFileTemplate } from 'utils/helper.js';

const MerchantChargeExport = ({
    merchant,
    logo,
    pdfTitle,
    address,
    csvData,
    companyAddress,
    paidImage,
    isPaid,
    transactionId,
    columnStyles
}) => {
    const { globalState } = useGlobalStateContext();
    const merchantLegalName = globalState?.merchant?.legalName;
    const isDatman = globalState.reseller?.name === ResellerType.DATMAN;
    if (typeof csvData === 'object') {
        csvData = Papa.unparse(csvData);
    }

    const exportPDF = async () => {
        pdfFileTemplate(
            merchant,
            logo,
            pdfTitle,
            address,
            csvData,
            companyAddress,
            paidImage,
            isDatman,
            isPaid,
            transactionId,
            columnStyles,
            merchantLegalName
        );
    };
    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                startIcon={<GetAppIcon />}
                onClick={() => {
                    exportPDF();
                }}
            >
                PDF
            </Button>
        </div>
    );
};

export default MerchantChargeExport;
