import React from 'react';
import PayoutsListTable from '../transaction-tables/payouts-list-table/payouts-list-table.container';

const PayoutsListComponent = (props) => {
    return (
        <div>
            <PayoutsListTable {...props} />
        </div>
    );
};

export default PayoutsListComponent;
