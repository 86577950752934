import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Form, Button, CloseButton } from 'react-bootstrap';
import styles from './modal-client-creation.module.scss';

function ModalClientCreationComponent(props) {
    return (
        <Modal show={props.showModal} onHide={() => props.hideModal(false)} centered={true}>
            <Modal.Header className={styles.clientCreationTitle}>
                <span>Create client </span>
                <CloseButton onClick={() => props.hideModal(false)} />
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Client name:</Form.Label>
                        <Form.Control
                            type="text"
                            isValid={props.validationMessage.isValid}
                            isInvalid={!props.validationMessage.isValid}
                            value={props.clientName}
                            onChange={props.setClientName}
                        />
                        <Form.Text className="invalid-feedback">{props.validationMessage.message}</Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {!props.isLoading ? (
                        <Button type="submit" disabled={!props.validationMessage.isValid} onClick={props.createClient}>
                            Submit
                        </Button>
                    ) : (
                        <button className="btn btn-primary" type="submit" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="ml-2">Loading...</span>
                        </button>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

ModalClientCreationComponent.propTypes = {
    showModal: PropTypes.bool,
    hideModal: PropTypes.func,
    clientName: PropTypes.string,
    setClientName: PropTypes.func,
    validationMessage: PropTypes.object,
    createClient: PropTypes.func,
    isLoading: PropTypes.bool,
    validateClientNameIsUnique: PropTypes.func,
};

export default ModalClientCreationComponent;
