import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import ModalCreateQrCodeComponent from './modal-create-qr-code.component';
import { DocumentsService } from 'services';
import moment from 'moment';
import { InputValidators } from 'validators';

function ModalCreateQrCode(props) {
    const {
        setShowCreateQr,
        showCreteQr,
        selectPaymentQr,
        setQrCodeData,
        qrDetails,
        setQrDetails,
        setSuccess,
        success,
        setShowMessage,
        setCount,
        setSelectPaymentQr
    } = props;
    const { globalState } = useGlobalStateContext();
    const [paymentUrl, setPaymentUrl] = useState('');
    const resellerId = globalState.reseller?.id;
    const [selectDate, setSelectDate] = useState(null);
    const [isDisable, setIsDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [value, setValue] = useState('no');
    const [showDateValidationMessage, setShowDateValidationMessage] = useState(false);

    useEffect(() => {
        let isAmountValid = true;
        let enabled = true;

        if (showDatePicker) {
            const endTime = moment().format('YYYY-MM-DD HH:mm');
            const startTime = qrDetails.qrCodeExpiryDate;
            let diff = moment.utc(
                moment(startTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(endTime, 'YYYY-MM-DD HH:mm:ss'))
            );
            diff = moment.duration(diff).asMinutes();
            if (diff === 'Invalid date' || diff < 5) {
                enabled = false;
            }
        }

        if (!enabled && qrDetails.qrCodeExpiryDate) {
            setShowDateValidationMessage(true);
        } else {
            setShowDateValidationMessage(false);
        }

        if (selectPaymentQr === true) {
            if (value === 'yes') {
                if (
                    !qrDetails.amount ||
                    qrDetails.amount <= 0 ||
                    qrDetails.amount === 0.0 ||
                    qrDetails.amount === '.'
                ) {
                    isAmountValid = false;
                } else {
                    isAmountValid = true;
                }
            }
            const isDisable = isAmountValid && enabled;
            setIsDisable(!isDisable);
        } else {
            const isDisable = qrDetails.websiteUrl && InputValidators.isDomainUrlValid(qrDetails.websiteUrl) && enabled;
            setIsDisable(!isDisable);
        }
    }, [selectPaymentQr, value, qrDetails.amount, qrDetails.websiteUrl, showDatePicker, qrDetails.qrCodeExpiryDate]);

    useEffect(() => {
        const fetchQrData = async () => {
            const response = await DocumentsService.getQrCode(globalState.merchant?.id);
            if (response.isSuccesfully) {
                setPaymentUrl(response.data.paymentUrl);
            }
        };
        fetchQrData();
        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    const isMaxAmount = (values) => {
        const { value, floatValue } = values;
        const MAX_AMT = 999999.99;

        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true;
        }
        if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
                return false;
            }
        }
        if (value > MAX_AMT) {
            return false;
        }
        return true;
    };

    const handleGenerateQr = async (e) => {
        setIsLoading(true);
        const response = await DocumentsService.createQrCode(globalState.merchant?.id, {
            resellerId: resellerId,
            description: qrDetails.description,
            link: qrDetails.websiteUrl,
            amount: value === 'no' ? '' : qrDetails.amount,
            qrName: qrDetails.qrName,
            typeOfQr: selectPaymentQr ? 'payment' : 'generic',
            qrCodeExpiryDate: showDatePicker
                ? qrDetails.qrCodeExpiryDate
                    ? new Date(qrDetails.qrCodeExpiryDate).toISOString()
                    : null
                : null
        });
        if (response.isSuccesfully) {
            setSuccess(true);
            setQrCodeData(response?.data?.data?.qrCodes);
            setCount(response?.data?.data?.qrCodes.length);
            setShowMessage('Successfully generated QR code');
            setShowCreateQr(false);
            setQrDetails({ qrCodeExpiryDate: null });
            setShowDatePicker(false);
            setValue('no');
        } else {
            setShowMessage(response?.data?.errorMessage);
        }
        setIsLoading(false);
    };

    const handleCopyPaymentLink = () => {
        navigator.clipboard.writeText(paymentUrl);
        setIsCopyAlertOpen(true);
    };

    const closeCreateModal = () => {
        setShowCreateQr(false);
        setQrDetails({ qrCodeExpiryDate: null });
        setShowDatePicker(false);
        setValue('no');
    };

    const closeCopyAlert = () => {
        setIsCopyAlertOpen(false);
    };

    return (
        <ModalCreateQrCodeComponent
            setShowCreateQr={setShowCreateQr}
            showCreteQr={showCreteQr}
            selectPaymentQr={selectPaymentQr}
            handleGenerateQr={handleGenerateQr}
            success={success}
            setQrDetails={setQrDetails}
            qrDetails={qrDetails}
            paymentUrl={paymentUrl}
            setSelectDate={setSelectDate}
            selectDate={selectDate}
            setSuccess={setSuccess}
            isDisable={isDisable}
            setShowValidationMessage={setShowValidationMessage}
            showValidationMessage={showValidationMessage}
            handleCopyPaymentLink={handleCopyPaymentLink}
            isCopyAlertOpen={isCopyAlertOpen}
            closeCopyAlert={closeCopyAlert}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            value={value}
            setValue={setValue}
            closeCreateModal={closeCreateModal}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isMaxAmount={isMaxAmount}
            showDateValidationMessage={showDateValidationMessage}
            setSelectPaymentQr={setSelectPaymentQr}
        />
    );
}
ModalCreateQrCode.propTypes = {
    isLoading: PropTypes.bool,
    setShowCreateQr: PropTypes.bool,
    showCreteQr: PropTypes.bool,
    paymentUrl: PropTypes.string,
    selectPaymentQr: PropTypes.bool
};
export default ModalCreateQrCode;
