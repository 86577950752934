import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    // RadioGroup,
    // Radio,
    // FormControlLabel,
    MenuItem,
    TextField,
    Select
} from '@material-ui/core';
import { LoadingScreen } from 'components';
import { days } from 'utils/enums/Days';
import styles from './modal-export-report-schedule.module.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
const ModalExportReportScheduleComponent = (props) => {
    const {
        loading,
        timeFrame,
        handleTimeFrameChange,
        weeklyScheduleDay,
        setWeeklyScheduleDay,
        monthlyScheduleDay,
        setMonthlyScheduleDay,
        customScheduleFromDay,
        setCustomScheduleFromDay,
        customScheduleToDay,
        setCustomScheduleToDay,
        customScheduleOnDay,
        setCustomScheduleOnDay,
        handleClose,
        disableExportBtn,
        // downloadFormat,
        // handleDownloadFormat,
        schedulePaymentsExport,
        datesArray
    } = props;

    return (
        <React.Fragment>
            <Dialog
                open={true}
                className={styles.dialog}
                maxWidth="lg"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ overflow: 'hidden' }}>
                    <LoadingScreen open={loading} />

                    <DialogTitle id="form-dialog-title" className={styles.title}>
                        Schedule Export transactions
                    </DialogTitle>

                    <Box mx={2} className={styles.selectTimeFrame}>
                        <p className={styles.paraText}>Frequency</p>
                        <FormControl variant="outlined">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={timeFrame}
                                onChange={handleTimeFrameChange}
                                className={styles.exportSelect}
                            >
                                <MenuItem value="Weekly">Weekly</MenuItem>
                                <MenuItem value="Monthly">Monthly</MenuItem>
                                <MenuItem value="Custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {timeFrame === 'Weekly' && (
                        <DialogContent className={styles.dialogContent}>
                            <p className={styles.paraText}>Every</p>
                            <TextField
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                value={weeklyScheduleDay}
                                onChange={(event) => {
                                    setWeeklyScheduleDay(event.target.value);
                                }}
                                className={styles.dialogContentTextFieldWeekly}
                                size="small"
                            >
                                {days.map((day) => (
                                    <MenuItem value={day.value}>{day.day}</MenuItem>
                                ))}
                            </TextField>
                        </DialogContent>
                    )}
                    {timeFrame === 'Monthly' && (
                        <DialogContent className={styles.dialogContent}>
                            <p className={styles.paraText}>Every</p>
                            <TextField
                                id="standard-select-currency"
                                select
                                variant="outlined"
                                value={monthlyScheduleDay}
                                onChange={(event) => {
                                    setMonthlyScheduleDay(event.target.value);
                                }}
                                className={styles.dialogContentTextFieldWeekly}
                                size="small"
                            >
                                {datesArray.map((date) => (
                                    <MenuItem value={date}>{date}</MenuItem>
                                ))}
                            </TextField>
                        </DialogContent>
                    )}

                    {timeFrame === 'Custom' && (
                        <>
                            <DialogContent className={styles.dialogContento} style={{ overflow: 'hidden' }}>
                                <div>
                                    <p className={styles.paraTextCustom}>From</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            value={customScheduleFromDay}
                                            inputFormat="D MMM YY"
                                            onChange={(newValue) => {
                                                setCustomScheduleFromDay(newValue['$d']);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    className={styles.dialogContentDatePicker}
                                                    size="small"
                                                    placeholder="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div>
                                    <p className={styles.paraTextCustom}>To</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            value={customScheduleToDay}
                                            inputFormat="D MMM YY"
                                            onChange={(newValue) => {
                                                setCustomScheduleToDay(newValue['$d']);
                                            }}
                                            minDate={customScheduleFromDay}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    className={styles.dialogContentTextFieldTo}
                                                    size="small"
                                                    placeholder="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </DialogContent>
                            <DialogContent className={styles.dialogContentp}>
                                <div>
                                    <p className={styles.paraText}>On</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            inputFormat="D MMM YY"
                                            value={customScheduleOnDay}
                                            onChange={(newValue) => {
                                                setCustomScheduleOnDay(newValue['$d']);
                                            }}
                                            minDate={customScheduleToDay}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    className={styles.dialogContentDatePickerVariant}
                                                    size="small"
                                                    placeholder="DD/MM/YYYY"
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </DialogContent>
                        </>
                    )}
                    {/* <DialogContent className={styles.dialogContentDownloadFormat}>
                        <p className={styles.paraText}>Format</p>
                        <RadioGroup
                            aria-label="format"
                            name="format"
                            row
                            className={styles.downloadFormat}
                            value={downloadFormat}
                            onChange={handleDownloadFormat}
                        >
                            <FormControlLabel value="CSV" control={<Radio />} label="CSV" />
                            <FormControlLabel value="PDF" control={<Radio />} label="PDF" />
                        </RadioGroup>
                    </DialogContent> */}

                    <DialogActions className={styles.dialogActions}>
                        <Button variant="contained" onClick={handleClose} color="primary" className={styles.dialogbtn}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                schedulePaymentsExport();
                            }}
                            color="primary"
                            className={styles.dialogbtn}
                            disabled={disableExportBtn}
                        >
                            Export
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalExportReportScheduleComponent;

ModalExportReportScheduleComponent.propTypes = {
    loading: PropTypes.bool,
    intervalStart: PropTypes.string,
    intervalEnd: PropTypes.string,
    download: PropTypes.bool,
    buttonDisabled: PropTypes.bool,
    reportData: PropTypes.object,
    error: PropTypes.bool,
    longRun: PropTypes.bool,
    onRetryStripeReport: PropTypes.func,
    stripeReportId: PropTypes.string,
    reportType: PropTypes.string,
    companyAddress: PropTypes.string
};
