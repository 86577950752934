import React from 'react';
import PropTypes from 'prop-types';
import ModalUpdateBankCompletionComponent from './modal-update-bank-completion.component';
import { ResellerType } from 'utils/enums/ResellerType';
import { UserType } from 'utils/enums/UserType';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
export default function ModalUpdateBankCompletion(props) {
    const {
        setBankUpdateDialogBox,
        setLoading,
        bankUpdateDialogBox,
        bankDetailsUpdateInitiated,
        setBankUpdateDetailsInitiated,
        overRideRequest,
        newBankDetailsToSave,
        raiseBankDetailsUpdateRequest,
        resellerName,
        sendOtp,
        userType,
        merchantCountry
    } = props;

    const continueUpdating = async () => {
        setBankUpdateDialogBox(false);

        setLoading(true);

        setLoading(false);
    };

    const override = async () => {
        if (
            ResellerType.DATMAN === resellerName &&
            userType !== UserType.SuperAdmin &&
            merchantCountry === MerchantCountries.UNITED_KINGDOM
        ) {
            sendOtp();
        } else {
            setBankUpdateDialogBox(false);
            setLoading(true);
            await raiseBankDetailsUpdateRequest(newBankDetailsToSave);
            setLoading(false);
        }
    };

    return (
        <ModalUpdateBankCompletionComponent
            continueUpdating={continueUpdating}
            override={override}
            bankUpdateDialogBox={bankUpdateDialogBox}
            setBankUpdateDialogBox={setBankUpdateDialogBox}
            bankDetailsUpdateInitiated={bankDetailsUpdateInitiated}
            setBankUpdateDetailsInitiated={setBankUpdateDetailsInitiated}
            overRideRequest={overRideRequest}
        ></ModalUpdateBankCompletionComponent>
    );
}

ModalUpdateBankCompletion.propTypes = {
    bankUpdateDialogBox: PropTypes.bool,
    setBankUpdateDialogBox: PropTypes.func,
    bankDetailsUpdateInitiated: PropTypes.bool,
    overRideRequest: PropTypes.bool,
    resellerName: PropTypes.string,
    setBankUpdateVerifyDialogBox: PropTypes.func,
    userType: PropTypes.string,
    merchantCountry: PropTypes.string
};
