import React from 'react';
import PropTypes from 'prop-types';
import styles from './merchant-pdq-list.module.scss';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Box,
    Link,
    Grid,
    Container,
    useMediaQuery,
    TablePagination,
    TableFooter,
    makeStyles
} from '@material-ui/core';
import { useGlobalStateContext } from 'contexts';
import { ModalPdqMerchants, SnackBarAndAlert, ModalFilterPdqMerchants } from 'components';
import { PdqOrderStatus } from 'utils/enums/pdqOrderStatus';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TableHeader from 'components/payments/transaction-tables/table-header/table-header.container';
import { stableSort, getComparator } from 'utils/helper';
import Loader from 'components/loader/loader.container';
import { formatDateTimeByFormatString } from 'utils/helper';
const useStyles = makeStyles(() => ({
    tableCellSmallSize: {
        '& td': {
            minWidth: '200px',
            maxWidth: '200px',
            align: 'left'
        },
        '& th': {
            minWidth: '150px',
            maxWidth: '150px'
        }
    },
    root: {
        width: '100%'
    },
    container: {
        'overflow-y': 'visible'
    },
    table: {
        minWidth: '100%'
    },
    tableRow: {
        '&$selected, &$selected:hover': {
            backgroundColor: '#e4e1fe'
        }
    },
    selected: {},
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));
function MerchantPdqListComponent(props) {
    const { setRoute, globalState } = useGlobalStateContext();
    const {
        isLoading,
        setSearchValue,
        openSearchAllMerchants,
        setOpenSearchAllMerchants,
        dnaOnboardedMerchants,
        searchPdqOrders,
        count,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        dnaMerchantsList,
        handleCopyId,
        isCopyAlertOpen,
        closeCopyAlert,
        setStatusFilter,
        statusFilter,
        setAgentFilter,
        agentFilter,
        handleItemChange,
        isPdqStatusChecked,
        handleAgentChange,
        listOfAgentEmails,
        isAgentCheck,
        filteredAgentEmails,
        toggleAgentCheck,
        filter,
        handleClearFilter,
        order,
        orderBy
    } = props;
    const classes = useStyles();
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');

    return (
        <Container className={styles.container}>
            <div className={styles.resellerId}>
                <Typography color="primary" className={styles.resellerLabel}>
                    {globalState.canonicalReseller?.companyName}&nbsp;PDQ Portal
                </Typography>
            </div>
            <Grid
                xs={12}
                md={12}
                sm={12}
                container
                direction={mediaLessThan600px ? 'column' : 'row'}
                className={styles.resellerHeader}
                spacing={3}
            >
                <Grid item xs={12} md={5} lg={3} sm={5}>
                    <Button
                        id="create-new-merchant-button"
                        className={styles.newMerchantbutton}
                        variant="contained"
                        onClick={() => setOpenSearchAllMerchants(true)}
                    >
                        All Merchants
                    </Button>
                </Grid>
                <Grid item xs={12} md={7} lg={5} sm={7} className={styles.searchContainer}>
                    <form onSubmit={searchPdqOrders}>
                        <TextField
                            className={styles.searchMerchants}
                            id="pending-merchants-search"
                            size="small"
                            type="search"
                            variant="outlined"
                            placeholder="Search by name or MID"
                            autoComplete="off"
                            autoFocus
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                        <Button
                            id="pending-merchants-search-button"
                            className={styles.searchButton}
                            variant="contained"
                            color="primary"
                            onClick={searchPdqOrders}
                        >
                            Search
                        </Button>
                    </form>
                </Grid>
            </Grid>
            {isLoading ? (
                <div className={styles.loading}>
                    <Loader />
                </div>
            ) : (
                <div className={styles.container}>
                    <TableContainer className={styles.TableContainer}>
                        <Table
                            aria-label="simple table"
                            aria-labelledby="tableTitle"
                            size="small"
                            stickyHeader
                            component={Paper}
                            className={classes.tableCellSmallSize}
                        >
                            <TableHeader classes={classes} {...props} />
                            {dnaOnboardedMerchants.length > 0 ? (
                                <TableBody>
                                    {stableSort(dnaOnboardedMerchants, getComparator(order, orderBy))?.map((row) => {
                                        return (
                                            <TableRow className={styles.rowData}>
                                                <TableCell className={styles.nameColumn}>
                                                    <Box className={styles.nameColumn}>
                                                        <Tooltip
                                                            title={row.Merchant?.name}
                                                            className={styles.merchantName}
                                                            placement="bottom-start"
                                                        >
                                                            <Link
                                                                className={styles.nameLink}
                                                                onClick={() => {
                                                                    setRoute(`/pdq-order-merchant/${row.merchantId}`, {
                                                                        agentName:
                                                                            row.Merchant?.MerchantAgentRelationships[0]
                                                                                ?.User?.email
                                                                    });
                                                                    if (
                                                                        row.applicationStatus !== 'Success' &&
                                                                        row.applicationStatus !== 'rejected'
                                                                    ) {
                                                                        localStorage.setItem(
                                                                            'onBoardedMerchant',
                                                                            row.merchantId
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <span>{row.Merchant?.name}</span>
                                                            </Link>
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className={styles.midColumn}>{row.merchantId}</TableCell>
                                                <TableCell>
                                                    <div className={styles.applicationDiv}>
                                                        <span className={styles.textInput}>{row.applicationId}</span>
                                                        {row.applicationId && (
                                                            <sup>
                                                                <ContentCopyIcon
                                                                    className={styles.copyIcon}
                                                                    onClick={() => handleCopyId(row)}
                                                                />
                                                            </sup>
                                                        )}
                                                    </div>
                                                </TableCell>
                                                <TableCell className={styles.dateColumn}>
                                                    {formatDateTimeByFormatString(row.created_at, 'DD MMM YYYY')}
                                                </TableCell>
                                                <TableCell className={styles.midColumn}>
                                                    {PdqOrderStatus[row.applicationStatus]}
                                                </TableCell>
                                                <TableCell align="center" className={styles.dateColumn}>
                                                    <Tooltip
                                                        title={row.Merchant?.MerchantAgentRelationships[0]?.User?.email}
                                                        className={styles.merchantName}
                                                        placement="bottom-start"
                                                    >
                                                        <span className={styles.emailCell}>
                                                            {row.Merchant?.MerchantAgentRelationships[0]?.User?.email}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    {row.applicationReason ? (
                                                        row.applicationReason
                                                    ) : (
                                                        <span>&mdash;</span>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            ) : (
                                <>
                                    {!filter.includes('all') || !listOfAgentEmails.includes('all') ? (
                                        <TableBody>
                                            <TableRow className={styles.rowData}>
                                                {listOfAgentEmails.includes('all') || filter.includes('all') ? (
                                                    <TableCell colSpan={8} align="center">
                                                        No records
                                                    </TableCell>
                                                ) : (
                                                    <TableCell colSpan={8} align="center">
                                                        No results available for this filter.
                                                        <span
                                                            onClick={handleClearFilter}
                                                            className={styles.clearFilter}
                                                        >
                                                            Remove all filters.
                                                        </span>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow className={styles.rowData}>
                                                <TableCell colSpan={8} align="center">
                                                    No records
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </>
                            )}
                            <TableFooter>
                                {count > 20 && (
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[20, 50, 100, { label: 'All', value: count }]}
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                )}
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            )}

            <ModalPdqMerchants
                open={openSearchAllMerchants}
                setOpen={setOpenSearchAllMerchants}
                dnaMerchantsList={dnaMerchantsList}
            />
            <ModalFilterPdqMerchants
                dnaOnboardedMerchants={dnaOnboardedMerchants}
                statusFilter={statusFilter}
                agentFilter={agentFilter}
                setStatusFilter={setStatusFilter}
                setAgentFilter={setAgentFilter}
                handleItemChange={handleItemChange}
                isPdqStatusChecked={isPdqStatusChecked}
                handleAgentChange={handleAgentChange}
                listOfAgentEmails={listOfAgentEmails}
                isAgentCheck={isAgentCheck}
                filteredAgentEmails={filteredAgentEmails}
                toggleAgentCheck={toggleAgentCheck}
            />

            {isCopyAlertOpen && (
                <SnackBarAndAlert open={isCopyAlertOpen} onClose={closeCopyAlert} type={'success'}>
                    Successfully copied the application ID.
                </SnackBarAndAlert>
            )}
        </Container>
    );
}
MerchantPdqListComponent.propTypes = {
    merchants: PropTypes.array,
    isLoading: PropTypes.bool,
    setSearchValue: PropTypes.string,
    count: PropTypes.number,
    page: PropTypes.number,
    setPage: PropTypes.func,
    canonicalReseller: PropTypes.object,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    saveDraft: PropTypes.bool,
    setSaveDraft: PropTypes.func,
    searchValue: PropTypes.string,
    onGoToMerchant: PropTypes.func,
    searchResellerMerchants: PropTypes.func,
    setMerchants: PropTypes.func,
    pdqOrders: PropTypes.array,
    dnaMerchantsList: PropTypes.array
};
export default MerchantPdqListComponent;
