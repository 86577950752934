import { useGlobalStateContext } from 'contexts';
import React, { useState } from 'react';
import { NonResellerMerchantService } from 'services';
import ModalDeleteMerchantAccountComponent from './modal-delete-merchant-account.component';

function ModalDeleteMerchantAccount(props) {
    const { globalState, setGlobalState, setRoute } = useGlobalStateContext();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setOpen } = props;

    const onDeleteMerchant = async () => {
        setIsError(false);
        setIsLoading(true);
        const merchantName = globalState.merchant?.name;
        const response = await NonResellerMerchantService.deleteMerchantAccount(globalState.merchant?.id);
        if (response.isSuccesfully) {
            setOpen(false);
            setGlobalState((globalState) => ({
                ...globalState,
                shouldLoadMerchants: !globalState.shouldLoadMerchants,
                wasDeletedMerchantName: merchantName
            }));
            setIsLoading(false);
            setRoute('/home');
        } else {
            setIsLoading(false);
            setIsError(true);
        }
    };

    return (
        <ModalDeleteMerchantAccountComponent
            {...props}
            onDeleteMerchant={onDeleteMerchant}
            merchantName={globalState.merchant?.name}
            isError={isError}
            isLoading={isLoading}
        />
    );
}

export default ModalDeleteMerchantAccount;
