import React, { useState, useEffect } from 'react';
import ModalSendReportComp from './modal-send-report.component';
import PaymentsService from 'services/payments.service';
import moment from 'moment';
import { useGlobalStateContext } from 'contexts';

const ModalSendReport = (props) => {
    const {
        reportType,

        sendReportModal,
        setSendReportModal,
        scheduleExportSnackBar,
        setScheduleExportSnackBar,
        setExportSnackBar
    } = props;
    const [loading, setLoading] = useState(false);
    const [showCharges, setShowCharges] = useState(false);
    const [timeFrame, setTimeFrame] = useState('all');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [disable, setDisable] = useState(true);

    const { globalState } = useGlobalStateContext();
    const handleClose = () => {
        setSendReportModal(false);
    };

    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    };

    useEffect(() => {
        if (timeFrame === 'custom') {
            (toDate !== null || fromDate !== null) && (toDate !== '' || fromDate !== '') && fromDate <= toDate
                ? setDisable(true)
                : setDisable(false);
        } else setDisable(true);
    }, [timeFrame, toDate, fromDate]);

    const onSend = async () => {
        let paymentFrom;
        let paymentTo;
        let transactionsRequest = {
            fromDate: moment().format('YYYY-MM-DD'),
            via: timeFrame.toUpperCase(),
            isCsv: true,
            showMerchantCharges: showCharges
        };
        if (timeFrame === 'custom') {
            paymentFrom = moment(fromDate).format('YYYY-MM-DD');
            paymentTo = moment(toDate).format('YYYY-MM-DD');
            transactionsRequest = {
                ...transactionsRequest,
                fromDate: paymentFrom,
                toDate: paymentTo,
                via: 'RANGE'
            };
        }
        setLoading(true);
        const response = await PaymentsService.sentExportReport(globalState?.merchant?.id, transactionsRequest);
        if (response.isSuccesfully) {
            setExportSnackBar({ open: true, type: 'success', message: response.data?.message });
        } else {
            setExportSnackBar({ open: true, type: 'error', message: response.data?.message });
        }

        setLoading(false);
        handleClose();
    };

    return (
        <ModalSendReportComp
            {...props}
            loading={loading}
            reportType={reportType}
            timeFrame={timeFrame}
            setTimeFrame={setTimeFrame}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleClose={handleClose}
            disable={disable}
            setDisable={setDisable}
            onSend={onSend}
            scheduleExportSnackBar={scheduleExportSnackBar}
            setScheduleExportSnackBar={setScheduleExportSnackBar}
            handleTimeFrameChange={handleTimeFrameChange}
            showCharges={showCharges}
            setShowCharges={setShowCharges}
            sendReportModal={sendReportModal}
        />
    );
    // return <ModalSendReportComp />;
};

export default ModalSendReport;
