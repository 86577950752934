import React, { useState } from 'react';
import ModalBusinessComponent from './modal-business-creation.component';
import { validateBusinessName } from 'validators';
import { ValidationMessage } from 'models';
import { BusinessService } from 'services';

function ModalBusinessCreation(props) {
    const initialValidationMessage = new ValidationMessage();

    const [businessNameState, setBusinessNameState] = useState('');
    const [validationMessage, setValidationMessage] = useState(initialValidationMessage);
    const [isLoading, setIsLoading] = useState(false);
    const [userHasBusiness, setUserHasBusiness] = useState(false);
    const [serverMessage, setServerMessage] = useState(null);

    const setBusinessName = (event) => {
        const validationResult = validateBusinessName(event.target.value);
        setValidationMessage(validationResult);
        setBusinessNameState(event.target.value);
    };
    const createBusiness = (event) => {
        setIsLoading(true);
        BusinessService.createBusiness({ name: businessNameState.trim() }).then((responseCreateBusiness) => {
            if (responseCreateBusiness.isSuccesfully) {
                props.onActionDone();
                props.hideModal();
                setIsLoading(false);
                setBusinessNameState('');
            } else {
                if (responseCreateBusiness.data.error) {
                    setUserHasBusiness(true);
                    setServerMessage(responseCreateBusiness.data.error);
                    setIsLoading(false);
                }
            }
        });

        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <ModalBusinessComponent
            showModal={props.showModal}
            hideModal={props.hideModal}
            businessName={businessNameState}
            validationMessage={validationMessage}
            setBusinessName={setBusinessName}
            createBusiness={createBusiness}
            isLoading={isLoading}
            userHasBusiness={userHasBusiness}
            setUserHasBusiness={setUserHasBusiness}
            serverMessage={serverMessage}
            setServerMessage={setServerMessage}
        ></ModalBusinessComponent>
    );
}

export default ModalBusinessCreation;
