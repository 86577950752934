import React from 'react';
import { CSVLink } from 'react-csv';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button } from '@material-ui/core';

const ExportCSV = ({ csvData, fileName, buttonText, transactions, setShowReportModel, setDaysRange, path }) => {
    const tooltip = (
        <Tooltip id="tooltip">
            Click to <strong>download</strong> sheet.
        </Tooltip>
    );
    const isDisabled = path === 'payments' || path === 'gfo-payments' ? csvData.length <= 2 : csvData.length <= 0;

    return (
        <React.Fragment>
            <OverlayTrigger placement="top" overlay={tooltip}>
                <CSVLink
                    data={csvData}
                    filename={fileName + '.csv'}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="click to download sheet"
                    onClick={(event) => {
                        if (isDisabled) {
                            event.preventDefault();
                        } else {
                            setShowReportModel(false);
                        }
                    }}
                    target="_blank"
                >
                    <Button variant={transactions ? 'outlined' : 'contained'} color="primary" disabled={isDisabled}>
                        {buttonText ? buttonText : 'csv'}
                    </Button>
                </CSVLink>
            </OverlayTrigger>
        </React.Fragment>
    );
};

export default ExportCSV;
