import React from 'react';
import AddPricingTierComponent from './fee-tier.component';

const AddPricingTier = (props) => {
    const { feeTier, setFeeTier, pricingTiers, updateFeeTier, setIsModalUpdateFeeTier, setUpdateFeeTierValue } = props;

    return (
        <AddPricingTierComponent
            feeTier={feeTier}
            setFeeTier={setFeeTier}
            pricingTiers={pricingTiers}
            updateFeeTier={updateFeeTier}
            setIsModalUpdateFeeTier={setIsModalUpdateFeeTier}
            setUpdateFeeTierValue={setUpdateFeeTierValue}
        />
    );
};
export default AddPricingTier;
