import React from 'react';
import styles from './payout-card.module.scss';
import { Card, Typography, CardContent, CardActions } from '@material-ui/core';
import { formatAmount } from '../../utils/helper';
import PropTypes from 'prop-types';
import { useGlobalStateContext } from 'contexts';
import { getCurrencySymbol } from 'utils/getCurrencyByCountry';
import { DotFlashingLoading } from 'components';
function PayoutCardComponent(props) {
    const { globalState } = useGlobalStateContext();
    const { payout, calculateFontSize, loading } = props;

    return (
        <React.Fragment>
            <Card className={styles.card}>
                <CardContent className={styles.title}>
                    <Typography color="primary" gutterBottom>
                        <span className={styles.card_name}>Payouts</span>
                        {/* <span className={styles.rightMenu}>...</span> */}
                    </Typography>
                </CardContent>
                <CardActions className={styles.payouts}>
                    {loading ? (
                        <div className={styles.balanceLoading}>
                            <DotFlashingLoading />
                        </div>
                    ) : (
                        <div
                            style={calculateFontSize(parseFloat(payout).toFixed(2).toString().length)}
                            className={styles.action_items__account_balance}
                        >
                            <span className={styles.action_items__currency}>
                                {getCurrencySymbol(globalState.merchant?.country)}
                            </span>
                            {formatAmount(payout)}
                        </div>
                    )}
                </CardActions>
            </Card>
        </React.Fragment>
    );
}

PayoutCardComponent.propTypes = {
    payout: PropTypes.number,
    calculateFontSize: PropTypes.func
};

export default PayoutCardComponent;
