import validator from 'validator';
// import isCreditCard from 'validator/es/lib/isCreditCard';

export const isCardYearValid = (cardYear) => {
    const currentYear = Number(new Date().getFullYear().toString().substr(-2));

    return new RegExp('^[0-9]+$').test(cardYear) && String(cardYear).length <= 2 && Number(cardYear) >= currentYear;
};

export const isCardMonthValid = (cardMonth) =>
    new RegExp('^[0-9]+$').test(cardMonth) &&
    String(cardMonth).length <= 2 &&
    Number(cardMonth) >= 1 &&
    Number(cardMonth) <= 12;

export const isCvvValid = (cvv = '') => cvv.length >= 3; //Can be 3 digits Visa or 4 digits - American Express

export const isCardValid = (cardNumber) => validator.isCreditCard(cardNumber);

export const isCardHolderNameValid = (cardHolder) => !!cardHolder.trim();
