import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { Input, Label, OnboardingTitle, FormItem } from 'components/onboarding/onboarding-ui-components';
import { Edit } from '@material-ui/icons';
import styles from './onboarding-summary.module.scss';
import LoadingScreen from 'components/loading-screen/loading-screen.container';
import { useGlobalStateContext } from 'contexts';
import { Dialog, DialogContent, DialogActions, useMediaQuery } from '@material-ui/core';
import { translateText, FormatMessage } from 'utils/languageTranslater';
import { FormattedMessage } from 'react-intl';
import { checkEmptyValue, getArrayFromString } from 'validators/onboarding/onboarding-check-value.validator';
import { NativeSelect } from '@material-ui/core';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
import { CheckMerchantCountries } from '../onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import PhoneInput from 'react-phone-input-2';
import { BankSortCodeNumberContainer } from 'components';
import ReactCodeInput from 'react-verification-code-input';
import moment from 'moment';
import { MerchantStatus } from 'utils/enums/MerchantStatus';
import { UserType } from 'utils/enums/UserType';
import { getOnboardingTitle } from 'utils/onboarding-helper';
import { OnboardingSteps } from 'utils/enums/OnboardingStepsMapping';
import { BusinessTypeEnumId } from 'utils/enums/BusinessType';

export default function OnboardingSummaryComponent(props) {
    const { globalState, getRoute, setRoute } = useGlobalStateContext();
    const {
        nextStep,
        onboardingForm,
        isDisabled,
        onSubmit,
        isLoading,
        showModal,
        onModalClose,
        setOnboardingForm,
        fetchingData,
        telephoneDisabled,
        isSoleTrader
    } = props;
    const mediaLessThan600px = useMediaQuery('(max-width:599px)');
    const accountCodeLengthUK = 8;
    const mediaLessThan750px = useMediaQuery('(max-width:749px)');
    const mediaLessThan400px = useMediaQuery('(max-width:399px)');
    const registeredBusiness = onboardingForm.businessDetails.isRegisteredBusiness;
    const isSameAccountName = onboardingForm.businessDetails.isAccountNameSame;
    const merchantCountry = globalState.merchant?.country;
    const isMerchantUserType = merchantCountry === UserType.Merchant;
    const isActiveMerchant = merchantCountry === MerchantStatus.ACTIVE;
    const isUkMerchant = merchantCountry === MerchantCountries.UNITED_KINGDOM;
    const isUsaMerchant = merchantCountry === MerchantCountries.UNITED_STATES;

    let portalName = globalState.reseller?.name;
    const title = (
        <OnboardingTitle
            title="Setup summary"
            subTitle={
                fetchingData || !onboardingForm.isPopulated
                    ? 'Please wait while we are validating your data.'
                    : isDisabled
                    ? 'We need a few more details before we can setup your account.'
                    : 'Thanks, we have all the information we need to setup your account.'
            }
            isLoading={fetchingData || !onboardingForm.isPopulated}
            transalterEnabled={true}
            subTransalterEnabled={true}
        ></OnboardingTitle>
    );

    return (
        <Grid container className={styles.container}>
            <Grid item xs={12}>
                <Grid item className={styles.titleContainer}>
                    {title}
                </Grid>
                <Grid item className={styles.formsContainer}>
                    <Grid container xs={12} spacing={0} md={12}>
                        <Grid item xs={7} md={4}>
                            <Typography variant="h6" fontWeight="fontWeightBold" gutterBottom>
                                Owner details
                            </Typography>
                        </Grid>
                        <Grid item xs={5} md={8}>
                            <Edit
                                className={styles.editIcon}
                                onClick={() => {
                                    nextStep(4);
                                }}
                            />
                        </Grid>
                    </Grid>

                    <FormItem>
                        <Label text="Full name" id="owner-label-fullname" transalterEnabled="true"></Label>
                        {onboardingForm.ownersDetails.persons.length === 0 ? (
                            <Input
                                placeholder={FormatMessage({
                                    id: 'owner-label-fullname',
                                    text: 'Full name'
                                })}
                                id="owner-input-title"
                                value={checkEmptyValue(onboardingForm.ownersDetails.fullName)}
                                disabled
                            ></Input>
                        ) : (
                            <NativeSelect
                                input={
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'owner-label-fullname',
                                            text: 'Full name'
                                        })}
                                        id="owner-dropdown-title"
                                        value={onboardingForm.ownersDetails.fullName}
                                        disabled
                                    ></Input>
                                }
                            >
                                {onboardingForm.ownersDetails.persons.map((person, index) => (
                                    <option key={index} value={person.names.fullName}>
                                        {person.names.fullName}
                                    </option>
                                ))}
                            </NativeSelect>
                        )}
                    </FormItem>
                    <FormItem>
                        <Label text="Email" id="owner-label-email" transalterEnabled="true"></Label>
                        <Input
                            placeholder={FormatMessage({ id: 'owner-label-email', text: 'Email' })}
                            id="owner-input-email"
                            value={checkEmptyValue(onboardingForm.ownersDetails.email)}
                            disabled
                        ></Input>
                    </FormItem>
                    <FormItem>
                        <CheckMerchantCountries excludedCountries={[MerchantCountries.MEXICO]}>
                            <Label text="Mobile number" id="owner-label-contacttel" transalterEnabled="true"></Label>
                            <PhoneInput
                                country={'gb'}
                                inputClass={telephoneDisabled ? `${styles.phoneInput}` : `${styles.enablePhoneInput}`}
                                placeholder="Mobile number"
                                id="owner-input-contacttel"
                                value={checkEmptyValue(onboardingForm.ownersDetails.contactTel)}
                                alwaysDefaultMask={true}
                                countryCodeEditable={false}
                                disabled={telephoneDisabled}
                                name="Mobile Number"
                            />
                        </CheckMerchantCountries>
                    </FormItem>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                        <Label text="Mobile number" id="owner-label-contacttel" transalterEnabled="true"></Label>
                        <PhoneInput
                            country={'mx'}
                            placeholder={FormatMessage({ id: 'owner-label-contacttel', text: 'Mobile number' })}
                            id="owner-input-contacttel"
                            value={checkEmptyValue(onboardingForm.ownersDetails.contactTel)}
                            alwaysDefaultMask={true}
                            countryCodeEditable={false}
                            disabled={telephoneDisabled}
                            inputClass={telephoneDisabled ? `${styles.phoneInput}` : `${styles.enablePhoneInput}`}
                            name="Mobile Number"
                        />
                    </CheckMerchantCountries>
                </Grid>
                <Grid item className={styles.formsContainer}>
                    <Grid container xs={12} spacing={0} md={12}>
                        <Grid item xs={9} md={8}>
                            <Typography variant="h6" fontWeight="fontWeightBold" gutterBottom>
                                {getOnboardingTitle(OnboardingSteps.NAME_AND_ADDRESS, merchantCountry)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <Edit
                                className={styles.editIcon}
                                onClick={() => {
                                    nextStep(1);
                                }}
                            />
                        </Grid>
                    </Grid>
                    {isSoleTrader && registeredBusiness ? (
                        <FormItem>
                            <Label text="Registered Business name"></Label>
                            <Input
                                placeholder="Business name"
                                id="register-business-name"
                                value={onboardingForm.businessDetails.tradingName}
                                disabled
                                onChange={(e) => {
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            tradingName: e.target.value,
                                            isAccountNameSame: false
                                        }
                                    });
                                }}
                            ></Input>
                        </FormItem>
                    ) : null}
                    {isSoleTrader && !isSameAccountName && !registeredBusiness ? (
                        <FormItem>
                            <Label text="Bank Account Name"></Label>
                            <Input
                                placeholder="Bank Account Name"
                                value={onboardingForm.tradingAddress.accountHolderName}
                                disabled
                                onChange={(e) => {
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            accountHolderName: e.target.value
                                        },
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            isAccountNameSame: false
                                        }
                                    });
                                }}
                            ></Input>
                        </FormItem>
                    ) : null}
                    {(!isSoleTrader || isSameAccountName) && (
                        <FormItem>
                            <Label
                                text="Legal name"
                                id="name-address-label-legal-name"
                                transalterEnabled="true"
                            ></Label>
                            <Input
                                id="name-address-input-legal-name"
                                value={onboardingForm.nameAndAddress.legalName}
                                disabled
                            ></Input>
                        </FormItem>
                    )}
                    {!isSoleTrader && (
                        <React.Fragment>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                <FormItem>
                                    <Label
                                        text="ZIP code"
                                        id="name-address-label-zip-code-selector"
                                        transalterEnabled="true"
                                    ></Label>
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries excludedCountries={[MerchantCountries.UNITED_STATES]}>
                                <FormItem>
                                    <Label
                                        text="Post code"
                                        id="name-address-label-post-code-selector"
                                        transalterEnabled="true"
                                    ></Label>
                                </FormItem>
                            </CheckMerchantCountries>
                            <Input
                                placeholder={isUsaMerchant ? 'ZIP code' : 'Post code'}
                                id="address-input-post-code"
                                disabled
                                value={checkEmptyValue(onboardingForm.nameAndAddress.postCode)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        nameAndAddress: {
                                            ...onboardingForm.nameAndAddress,
                                            postCode: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </React.Fragment>
                    )}
                    {!isSoleTrader && (
                        <FormItem>
                            <Label
                                text="Address line 1"
                                id="name-address-label-address-code-1"
                                transalterEnabled="true"
                            ></Label>
                            <Input
                                placeholder={FormatMessage({
                                    id: 'name-address-label-address-code-1',
                                    text: 'Address line 1'
                                })}
                                id="name-address-input-address-code-1"
                                value={onboardingForm.nameAndAddress.addressLine1}
                                disabled
                            ></Input>
                        </FormItem>
                    )}
                    {!isSoleTrader && (
                        <CheckMerchantCountries excludedCountries={[MerchantCountries.AUSTRALIA]}>
                            <Grid item xs={12} className={styles.formItemGrid}>
                                <FormItem>
                                    <Label
                                        text="Telephone number"
                                        id="telephone-number-label"
                                        transalterEnabled="true"
                                    ></Label>
                                    <PhoneInput
                                        country={'gb'}
                                        id="telephone-number"
                                        value={onboardingForm.businessDetails.phoneNumber}
                                        placeholder={FormatMessage({
                                            id: 'telephone-number-label',
                                            text: 'Telephone number'
                                        })}
                                        alwaysDefaultMask={true}
                                        countryCodeEditable={false}
                                        inputClass={
                                            telephoneDisabled ? `${styles.phoneInput}` : `${styles.enablePhoneInput}`
                                        }
                                        disabled={telephoneDisabled}
                                        name="phone"
                                    />
                                </FormItem>
                            </Grid>
                        </CheckMerchantCountries>
                    )}
                    {!isSoleTrader && (
                        <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                            <Grid item xs={12} className={styles.formItemGrid}>
                                <FormItem>
                                    <Label text="Telephone number" id="telephone-number-label"></Label>
                                    <PhoneInput
                                        country={'au'}
                                        id="telephone-number"
                                        value={onboardingForm.businessDetails.phoneNumber}
                                        placeholder={'Telephone no.'}
                                        alwaysDefaultMask={true}
                                        countryCodeEditable={false}
                                        disabled={telephoneDisabled}
                                        inputClass={
                                            telephoneDisabled ? `${styles.phoneInput}` : `${styles.enablePhoneInput}`
                                        }
                                        name="phone"
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label
                                        text="Company email"
                                        id="company-email-label"
                                        transalterEnabled="true"
                                    ></Label>
                                    <Input
                                        placeholder={FormatMessage({
                                            id: 'company-email-label',
                                            text: 'Company email'
                                        })}
                                        id="website"
                                        value={checkEmptyValue(onboardingForm.businessDetails.email)}
                                        disabled
                                        onChange={(e) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                businessDetails: {
                                                    ...onboardingForm.businessDetails,
                                                    email: e.target.value.toLowerCase()
                                                }
                                            })
                                        }
                                    ></Input>
                                </FormItem>
                            </Grid>
                        </CheckMerchantCountries>
                    )}
                </Grid>
                <Grid item className={styles.formsContainer}>
                    <Grid container xs={12} md={12}>
                        <Grid item xs={9} md={5}>
                            <Typography variant="h6" fontWeight="fontWeightBold" gutterBottom>
                                {getOnboardingTitle(OnboardingSteps.TRADING_ADDRESS, merchantCountry)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={7}>
                            <Edit
                                className={styles.editIcon}
                                onClick={() => {
                                    nextStep(3);
                                }}
                            />
                        </Grid>
                    </Grid>
                    {onboardingForm.businessDetails.businessTypeId === BusinessTypeEnumId.Limited.toString() && (
                        <FormItem>
                            <Label text="Trading name" id="address-label-trading-name" transalterEnabled="true"></Label>
                            <Input
                                placeholder={FormatMessage({
                                    id: 'address-label-trading-name',
                                    text: 'Trading name'
                                })}
                                id="address-input-trading-name"
                                value={checkEmptyValue(onboardingForm.businessDetails.tradingName)}
                                disabled
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        businessDetails: {
                                            ...onboardingForm.businessDetails,
                                            tradingName: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    )}
                    <CheckMerchantCountries
                        excludedCountries={[MerchantCountries.UNITED_STATES, MerchantCountries.MEXICO]}
                    >
                        <FormItem>
                            <Label text="Post code" id="address-label-post-code" transalterEnabled="true"></Label>
                            <Input
                                placeholder="Post code"
                                id="address-input-post-code"
                                disabled
                                value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            postCode: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                        <FormItem>
                            <Label text="Post code" id="address-label-post-code" transalterEnabled="true"></Label>
                            <Input
                                placeholder={FormatMessage({
                                    id: 'address-label-post-code',
                                    text: 'Post code'
                                })}
                                id="address-input-post-code"
                                value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                                disabled
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            postCode: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                        <FormItem>
                            <Label text="ZIP Code" id="owner-primary-address-label-homepostcode"></Label>
                            <Input
                                placeholder="ZIP Code"
                                disabled
                                id="owner-primary-address-input-zip-code"
                                value={checkEmptyValue(onboardingForm.tradingAddress.postCode)}
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        ownerPrimaryAddress: {
                                            ...onboardingForm.tradingAddress,
                                            postCode: e.target.value
                                        }
                                    })
                                }
                            ></Input>
                        </FormItem>
                    </CheckMerchantCountries>
                    <FormItem>
                        <Label text="Town/City" id="address-label-town-city" transalterEnabled="true"></Label>
                        <Input
                            placeholder={FormatMessage({ id: 'address-label-town-city', text: 'Town/City' })}
                            id="address-input-town-city"
                            value={checkEmptyValue(onboardingForm.tradingAddress.city)}
                            disabled
                            onChange={(e) =>
                                setOnboardingForm({
                                    ...onboardingForm,
                                    tradingAddress: {
                                        ...onboardingForm.tradingAddress,
                                        city: e.target.value
                                    }
                                })
                            }
                        />
                    </FormItem>
                    <CheckMerchantCountries excludedCountries={[MerchantCountries.MEXICO]}>
                        <FormItem>
                            <Label text="Country" id="address-label-country"></Label>
                            <Input
                                placeholder="Country"
                                id="address-input-country"
                                value={checkEmptyValue(onboardingForm.tradingAddress.country)}
                                disabled
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            country: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                        <FormItem>
                            <Label text="Country" id="address-label-country" transalterEnabled="true"></Label>
                            <Input
                                placeholder={FormatMessage({ id: 'address-label-country', text: 'Country' })}
                                id="address-input-country"
                                value={MerchantCountries.MEXICO}
                                disabled
                                onChange={(e) =>
                                    setOnboardingForm({
                                        ...onboardingForm,
                                        tradingAddress: {
                                            ...onboardingForm.tradingAddress,
                                            country: e.target.value
                                        }
                                    })
                                }
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                </Grid>

                <Grid item className={styles.formsContainer}>
                    <Grid container xs={12} md={12}>
                        <Grid item xs={6} md={4}>
                            <Typography variant="h6" fontWeight="fontWeightBold" gutterBottom>
                                Bank details
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <Edit
                                className={styles.editIcon}
                                onClick={() => {
                                    nextStep(2);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.MEXICO]}>
                        <FormItem>
                            <Label text="Account number" id="account-number-label" transalterEnabled={true} />
                            <Input
                                autoFocus={false}
                                disabled
                                fields={10}
                                type="number"
                                pattern="\d*"
                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                onChange={(e) => {
                                    if (e.target.value.length <= 10) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_KINGDOM]}>
                        <Grid container>
                            <Grid item xs={mediaLessThan600px ? 12 : 6}>
                                <div className={styles.accountContainer}>
                                    <FormItem>
                                        <Label text="Bank sort code" id="bank-sort-code" />
                                        <BankSortCodeNumberContainer
                                            className={styles.codebgcolor}
                                            values={onboardingForm.tradingAddress.sortCode}
                                            disabled
                                            onChange={(value) => {
                                                setOnboardingForm({
                                                    ...onboardingForm,
                                                    tradingAddress: {
                                                        ...onboardingForm.tradingAddress,
                                                        sortCode: value
                                                    }
                                                });
                                            }}
                                        />
                                    </FormItem>
                                </div>
                            </Grid>
                        </Grid>
                        <FormItem>
                            <Label text="Account number" id="new-account-number" />
                            <Input
                                className={styles.codeInput}
                                autoFocus={false}
                                disabled
                                type="number"
                                pattern="\d*"
                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                onChange={(e) => {
                                    if (e.target.value.length <= accountCodeLengthUK) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                        {onboardingForm.isPopulated && (
                            <>
                                <FormItem>
                                    <Label text="Routing number" id="routing-number" />
                                    <ReactCodeInput
                                        className={styles.codebgcolor}
                                        fieldHeight={mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34}
                                        fieldWidth={mediaLessThan600px ? (mediaLessThan400px ? 23 : 30) : 34}
                                        autoFocus={false}
                                        fields={9}
                                        type="number"
                                        pattern="\d*"
                                        disabled
                                        values={getArrayFromString(onboardingForm.tradingAddress.routingNumber)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    routingNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                            </>
                        )}
                        <FormItem>
                            <Label text="Account number" id="account-number" />
                            <Input
                                autoFocus={false}
                                fields={20}
                                type="number"
                                disabled
                                pattern="\d*"
                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                onChange={(e) => {
                                    if (e.target.value.length <= 20) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.CANADA]}>
                        {onboardingForm.isPopulated && (
                            <>
                                <FormItem>
                                    <Label text="Transit number" id="transit-number" />
                                    <ReactCodeInput
                                        className={styles.codebgcolor}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        disabled
                                        fields={5}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(onboardingForm.tradingAddress.transitNumber)}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    transitNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>
                                <FormItem>
                                    <Label text="Financial institution number" id="financial-institution-number" />
                                    <ReactCodeInput
                                        className={styles.codebgcolor}
                                        fieldHeight={mediaLessThan600px ? 30 : 34}
                                        fieldWidth={mediaLessThan600px ? 30 : 34}
                                        autoFocus={false}
                                        disabled
                                        fields={3}
                                        type="number"
                                        pattern="\d*"
                                        values={getArrayFromString(
                                            onboardingForm.tradingAddress.financialInstitutionNumber
                                        )}
                                        onChange={(value) =>
                                            setOnboardingForm({
                                                ...onboardingForm,
                                                tradingAddress: {
                                                    ...onboardingForm.tradingAddress,
                                                    financialInstitutionNumber: value
                                                }
                                            })
                                        }
                                    />
                                </FormItem>{' '}
                            </>
                        )}
                        <FormItem>
                            <Label text="Account number" id="new-account-number" />
                            <Input
                                autoFocus={false}
                                type="number"
                                disabled
                                pattern="\d*"
                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                onChange={(e) => {
                                    if (e.target.value.length <= 20) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.AUSTRALIA]}>
                        {onboardingForm.isPopulated && (
                            <FormItem>
                                <Label text="BSB" id="bsb-australia-label" />
                                <ReactCodeInput
                                    className={styles.codebgcolor}
                                    fieldHeight={mediaLessThan600px ? 30 : 34}
                                    fieldWidth={mediaLessThan600px ? 30 : 34}
                                    autoFocus={false}
                                    fields={6}
                                    disabled
                                    type="number"
                                    pattern="\d*"
                                    values={getArrayFromString(onboardingForm.tradingAddress.bsb)}
                                    onChange={(value) =>
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                bsb: value
                                            }
                                        })
                                    }
                                />
                            </FormItem>
                        )}
                        <FormItem>
                            <Label text="Account number" id="account-number-australia-label" />
                            <Input
                                autoFocus={false}
                                fields={9}
                                type="number"
                                disabled
                                pattern="\d*"
                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                onChange={(e) => {
                                    if (e.target.value.length <= 9) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.NEW_ZEALAND]}>
                        <FormItem>
                            <Label text="Bank account number" id="bank-account" />
                            <Input
                                autoFocus={false}
                                type="number"
                                pattern="\d*"
                                disabled
                                value={checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)}
                                onChange={(e) => {
                                    if (e.target.value.length <= 16) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                    <CheckMerchantCountries includedCountries={[MerchantCountries.IRELAND]}>
                        <FormItem>
                            <Label text="Bank IBAN" id="bank-IBAN-ireland-label" />
                            <Input
                                className={styles.codeInput + ' ' + styles.codeInputIreland}
                                fieldHeight={
                                    (!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px ? 22 : 25
                                }
                                fieldWidth={(!mediaLessThan600px && mediaLessThan750px) || mediaLessThan400px ? 22 : 25}
                                autoFocus={false}
                                fields={22}
                                type="text"
                                value={
                                    onboardingForm.tradingAddress.newAccountNumber.length > 0
                                        ? checkEmptyValue(onboardingForm.tradingAddress.newAccountNumber)
                                        : 'IE'
                                }
                                disabled
                                onChange={(e) => {
                                    if (e.target.value.length <= 22) {
                                        setOnboardingForm({
                                            ...onboardingForm,
                                            tradingAddress: {
                                                ...onboardingForm.tradingAddress,
                                                newAccountNumber: e.target.value
                                            }
                                        });
                                    }
                                }}
                            />
                        </FormItem>
                    </CheckMerchantCountries>
                </Grid>

                <Typography color="textSecondary" gutterBottom className={styles.footerContainer}>
                    <FormattedMessage id="onboard-terms-1" defaultMessage="By submitting this form you agree to the" />
                    &nbsp;
                    <span
                        className={styles.termsandconditions}
                        onClick={() => window.open(getRoute(`/terms-and-conditions`), '_blank')}
                    >
                        <FormattedMessage
                            id="onboard-terms-2"
                            defaultMessage="{portalName} terms and conditions"
                            values={{
                                portalName
                            }}
                        />
                    </span>
                    <FormattedMessage
                        id="onboard-terms-3"
                        defaultMessage=", to receiving text messages from {portalName} and you certify that information
                        provided is complete and correct."
                        values={{
                            portalName
                        }}
                    />
                </Typography>
                <Grid className={styles.btnContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isDisabled}
                        className={styles.doneBtn}
                        onClick={(e) => onSubmit()}
                    >
                        {translateText({ id: 'onboarding-submit', text: 'Submit' })}
                    </Button>
                    {isMerchantUserType && isActiveMerchant && isUkMerchant && (
                        <div
                            className={styles.remindMeLater}
                            onClick={() => {
                                localStorage.setItem('skip_clicked', moment().format('DD-MM-YYYY hh:mm:ss'));
                                setRoute('/home');
                            }}
                        >
                            Remind me later
                        </div>
                    )}
                    <Dialog open={showModal} className={styles.modal}>
                        <DialogContent className={styles.modalText}>
                            <div className={styles.modalTitle}>
                                <FormattedMessage id="onboard-submit-thanks" defaultMessage="Success!" />
                            </div>
                            <div style={{ 'font-size': 'large', textAlign: 'center' }}>
                                <FormattedMessage
                                    id="onboard-submit-confirm"
                                    defaultMessage="Your application is now being processed. We will reach out to you if we need any additional information."
                                    values={{
                                        portalName,
                                        br: () => <br />
                                    }}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => onModalClose()}
                                id="cancelButtonCreateMerchant"
                                autoFocus
                                color="primary"
                                className={styles.confirmButton}
                            >
                                {translateText({ id: 'onboarding-close', text: 'Close' })}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
            <LoadingScreen open={isLoading}></LoadingScreen>
        </Grid>
    );
}

OnboardingSummaryComponent.propTypes = {
    nextStep: PropTypes.func,
    onboardingForm: PropTypes.object,
    isDisabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    telephoneDisabled: PropTypes.bool,
    fetchingData: PropTypes.bool
};
