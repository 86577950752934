import React, { useState } from 'react';
import { validatePaymentAmount } from 'validators';
import ModalRefundInternalTransferComponent from './modal-refund-internal-transfer.component.jsx';
import { InternalTransferService } from 'services';

const ModalRefundInternalTransfer = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [refundAmount, setRefundAmount] = useState('');
    const [showActionError, setShowActionError] = useState(false);

    const onSubmitRefund = async () => {
        setButtonDisabled(true);
        setLoading(true);
        const result = await InternalTransferService.refundInternalTransfer({
            transaction_id: props.transactionId,
            refund_amount: refundAmount,
            action: 'refund'
        });
        if (result.isSuccesfully) {
            setError(false);
            setSuccess(true);
            setErrorMessage('');
        } else {
            setSuccess(false);
            setError(true);
            setErrorMessage(result.data.error);
        }
        setDisableSubmit(true);
        setButtonDisabled(false);
        setLoading(false);
    };
    function refundActionValidation() {
        const validationMessage = validatePaymentAmount(props.amount, refundAmount);
        setShowActionError(!validationMessage.isValid);
        return validationMessage.message;
    }
    return (
        <ModalRefundInternalTransferComponent
            {...props}
            error={error}
            loading={loading}
            buttonDisabled={buttonDisabled}
            onSubmitRefund={onSubmitRefund}
            disableSubmit={disableSubmit}
            errorMessage={errorMessage}
            success={success}
            setRefundAmount={setRefundAmount}
            refundAmount={refundAmount}
            showActionError={showActionError}
            refundActionValidation={refundActionValidation}
        />
    );
};

export default ModalRefundInternalTransfer;
