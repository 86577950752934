import React from 'react';
import { Grid, Typography, Button, Dialog, DialogContent, DialogActions, Paper } from '@material-ui/core';
import { Input, Label, FormItem, PostCodeSelector } from 'components/onboarding/onboarding-ui-components';
import styles from './update-account.module.scss';
import { LoadingScreen, BankSortCodeDetails, ValidEmailInput } from 'components';
import { CheckMerchantCountries } from '../onboarding/onboarding-ui-components/check-merchant-countries/check-merchant-countries';
import { checkEmptyValue } from 'validators/onboarding/onboarding-check-value.validator';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import { SnackBarAndAlert } from 'components';
import 'react-phone-input-2/lib/bootstrap.css';
import { FormatMessage } from 'utils/languageTranslater';
import { useGlobalStateContext } from 'contexts';
import { MerchantCountries } from 'utils/enums/MerchantCountries';
function UppdateAccountComponent(props) {
    const { globalState } = useGlobalStateContext();

    const {
        disabled,
        businessDetails,
        ownerDetails,
        setBusinessDetails,
        setOwnerDetails,
        onSubmit,
        selectAddressData,
        showModal,
        modalMessage,
        errorMessage,
        showMessage,
        onModalClick,
        isLoading,
        BusinessName,
        setBusinessName,
        BusinessAddress,
        setBusinessAddress,
        OwnerName,
        setOwnerName,
        OwnerAddress,
        BankStatement,
        BusinessUtility,
        Identity,
        phoneNumberValidator,
        setOwnerAddress,
        handleClose
    } = props;

    const isUSMerchant = globalState.merchant?.country === MerchantCountries.UNITED_STATES;

    return (
        <Paper className={styles.UpdatesContainer}>
            <div className={styles.pageTitle}>
                <Typography component="span" color="primary" className={styles.formTitle}>
                    Update Account Details
                </Typography>
            </div>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <div className={styles.formContainer}>
                        <Typography component="span" color="primary" className={styles.formTitle}>
                            Business Details
                        </Typography>
                        <FormItem>
                            <Label text="Legal name *" id="legal-name"></Label>
                            <Input
                                placeholder="Legal name"
                                id="legal-name-input"
                                value={businessDetails.legalName}
                                onChange={(e) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        legalName: e.target.value
                                    });
                                    setBusinessName(true);
                                }}
                            />
                        </FormItem>
                        <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                            <FormItem>
                                <Label text="ZIP code" id="zip-code"></Label>
                            </FormItem>
                        </CheckMerchantCountries>
                        <CheckMerchantCountries excludedCountries={[MerchantCountries.UNITED_STATES]}>
                            <FormItem>
                                <Label text="Post code *" id="post-code"></Label>
                            </FormItem>
                        </CheckMerchantCountries>
                        <PostCodeSelector
                            placeholder={
                                isUSMerchant
                                    ? FormatMessage({ id: 'zip_code', text: 'Please enter ZIP code' })
                                    : FormatMessage({ id: 'post_code_input', text: 'Please enter post code' })
                            }
                            postCodeValue={checkEmptyValue(businessDetails.postCode)}
                            onPostCodeChange={(postCode) => {
                                setBusinessDetails({
                                    ...businessDetails,
                                    postCode: postCode
                                });
                                setBusinessAddress(true);
                            }}
                            onAddressObjectChange={
                                (addresData) => selectAddressData(addresData, setBusinessDetails, businessDetails)
                                // setBusinessAddress(true)
                            }
                        />
                        <FormItem>
                            <Label text="Address line 1 *" id="address-line-1"></Label>
                            <Input
                                placeholder="Address line 1"
                                id="address-line-1-input"
                                value={businessDetails.addressLine1}
                                onChange={(e) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        addressLine1: e.target.value
                                    });
                                    setBusinessAddress(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Address line 2" id="address-line-2"></Label>
                            <Input
                                placeholder="Address line 2"
                                id="address-line-2-input"
                                value={businessDetails.addressLine2}
                                onChange={(e) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        addressLine2: e.target.value
                                    });
                                    setBusinessAddress(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="City *" id="city"></Label>
                            <Input
                                placeholder="City"
                                id="city-input"
                                value={businessDetails.city}
                                onChange={(e) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        city: e.target.value
                                    });
                                    setBusinessAddress(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Telephone number *" id="telephone-number"></Label>
                            <PhoneInput
                                country={'gb'}
                                id="telephone-number-input"
                                placeholder="Telephone number"
                                value={checkEmptyValue(businessDetails.telephoneNumber)}
                                alwaysDefaultMask={true}
                                countryCodeEditable={false}
                                onChange={(phone) => {
                                    setBusinessDetails({
                                        ...businessDetails,
                                        telephoneNumber: phoneNumberValidator(phone)
                                    });
                                }}
                                inputClass={styles.phoneInput}
                                name="phone"
                            />
                        </FormItem>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className={styles.formContainer}>
                        <Typography component="span" color="primary" className={styles.formTitle}>
                            Owner Details
                        </Typography>
                        <FormItem>
                            <Label text="Full name *" id="full-name"></Label>
                            <Input
                                placeholder="Full name"
                                id="full-name-input"
                                value={ownerDetails.fullName}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        fullName: e.target.value
                                    });
                                    setOwnerName(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <CheckMerchantCountries includedCountries={[MerchantCountries.UNITED_STATES]}>
                                <FormItem>
                                    <Label text="ZIP code" id="zip-code"></Label>
                                </FormItem>
                            </CheckMerchantCountries>
                            <CheckMerchantCountries excludedCountries={[MerchantCountries.UNITED_STATES]}>
                                <FormItem>
                                    <Label text="Post code *" id="post-code"></Label>
                                </FormItem>
                            </CheckMerchantCountries>
                            <PostCodeSelector
                                placeholder={
                                    isUSMerchant
                                        ? FormatMessage({ id: 'zip_code', text: 'Please enter ZIP code' })
                                        : FormatMessage({ id: 'post_code_input', text: 'Please enter post code' })
                                }
                                postCodeValue={checkEmptyValue(ownerDetails.postCode)}
                                onPostCodeChange={(postCode) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        postCode: postCode
                                    });
                                    setOwnerAddress(true);
                                }}
                                onAddressObjectChange={(addresData) =>
                                    selectAddressData(addresData, setOwnerDetails, ownerDetails)
                                }
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Address line 1 *" id="owner-address-line-1"></Label>
                            <Input
                                placeholder="Address line 1"
                                id="owner-address-line-1-input"
                                value={ownerDetails.addressLine1}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        addressLine1: e.target.value
                                    });
                                    setOwnerAddress(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Address line 2" id="owner-address-line-2"></Label>
                            <Input
                                placeholder="Address line 2"
                                id="owner-address-line-2-input"
                                value={ownerDetails.addressLine2}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        addressLine2: e.target.value
                                    });
                                    setOwnerAddress(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="City *" id="owner-city"></Label>
                            <Input
                                placeholder="City"
                                id="owner-city-input"
                                value={ownerDetails.city}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        city: e.target.value
                                    });
                                    setOwnerAddress(true);
                                }}
                            />
                        </FormItem>
                        <FormItem>
                            <Label text="Telephone number *" id="owner-telephone-number"></Label>
                            <PhoneInput
                                country={'gb'}
                                id="owner-telephone-number-input"
                                placeholder="Telephone number"
                                value={checkEmptyValue(ownerDetails.telephoneNumber)}
                                alwaysDefaultMask={true}
                                countryCodeEditable={false}
                                onChange={(phone) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        telephoneNumber: phoneNumberValidator(phone)
                                    });
                                }}
                                inputClass={styles.phoneInput}
                                name="phone"
                            />
                        </FormItem>
                        <FormItem>
                            <ValidEmailInput
                                labelText="Email *"
                                labelId="owner-email"
                                placeholder={FormatMessage({ id: 'owner-email', text: 'Email' })}
                                transalterEnabled="true"
                                id="owner-email-input"
                                value={ownerDetails.email}
                                onChange={(e) => {
                                    setOwnerDetails({
                                        ...ownerDetails,
                                        email: e.target.value.toLowerCase()
                                    });
                                }}
                            />
                        </FormItem>
                    </div>
                </Grid>
                <BankSortCodeDetails />
            </Grid>
            <div className={styles.btnContainer}>
                <Button
                    disabled={disabled}
                    id="update-btn"
                    color="primary"
                    variant="contained"
                    className={styles.updateBtn}
                    onClick={onSubmit}
                >
                    Update
                </Button>
                <SnackBarAndAlert open={showMessage} onClose={handleClose} type="error">
                    {errorMessage}
                </SnackBarAndAlert>
                <Dialog open={showModal}>
                    <DialogContent>{modalMessage}</DialogContent>
                    <DialogContent>
                        <List>
                            {BusinessName ? <ListItemText primary={BankStatement} /> : null}
                            {BusinessAddress || OwnerAddress ? <ListItemText primary={BusinessUtility} /> : null}
                            {OwnerName ? <ListItemText primary={Identity} /> : null}
                        </List>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={onModalClick} id="cancelButtonCreateMerchant" autoFocus color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <LoadingScreen open={isLoading}></LoadingScreen>
            </div>
        </Paper>
    );
}
UppdateAccountComponent.propTypes = {
    phoneNumberValidator: PropTypes.func
};

UppdateAccountComponent.propTypes = {
    sortCode: PropTypes.string,
    accountNumber: PropTypes.string
};

export default UppdateAccountComponent;
