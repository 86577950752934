import React, { useState, useEffect, useRef } from 'react';
import PaymentQrCodeInfoComponent from './payment-qr-code-info.component';
import { DocumentsService } from 'services';
import { useGlobalStateContext } from 'contexts';
import { useReactToPrint } from 'react-to-print';

const PaymentQrCodeInfo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [qrImgSource, setQrImgSource] = useState('');
    const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
    const { globalState } = useGlobalStateContext();
    const imageContentRef = useRef();
    const [paymentUrl, setPaymentUrl] = useState(false);

    useEffect(() => {
        const fetchQrData = async () => {
            setIsLoading(true);

            const response = await DocumentsService.getQrCode(globalState.merchant?.id);

            if (response.isSuccesfully) {
                setQrImgSource(response.data.qrCodeUrl);
                setPaymentUrl(response.data.paymentUrl);
            }
            setIsLoading(false);
        };
        fetchQrData();
        // eslint-disable-next-line
    }, [globalState.merchant?.id]);

    const handlePrint = useReactToPrint({
        content: () => imageContentRef.current,
        documentTitle: `qr_code`
    });

    const handleCopyPaymentUrl = () => {
        navigator.clipboard.writeText(paymentUrl);
        setIsCopyAlertOpen(true);
    };

    return (
        <PaymentQrCodeInfoComponent
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            qrImgSource={qrImgSource}
            imageContentRef={imageContentRef}
            handlePrint={handlePrint}
            paymentUrl={paymentUrl}
            handleCopyPaymentUrl={handleCopyPaymentUrl}
            isCopyAlertOpen={isCopyAlertOpen}
            setIsCopyAlertOpen={setIsCopyAlertOpen}
        />
    );
};

export default PaymentQrCodeInfo;
