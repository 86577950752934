import React from 'react';
import ChargebacksListTable from '../transaction-tables/chargebacks-list-table/chargebacks-list-table.container.js';

const ChargebackListComponent = (props) => {
    return (
        <div>
            <ChargebacksListTable {...props} />
        </div>
    );
};

export default ChargebackListComponent;
