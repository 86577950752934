import React, { useState, useEffect } from 'react';
import OnboardingTransactionProfileStepThreeComponent from './onboarding-transaction-profile-step-three.component';
import { useOnboardingFormContext, useGlobalStateContext } from 'contexts';
import { NonResellerMerchantService } from 'services';

function OnboardingTransactionProfileStepThree(props) {
    const { onboardingForm, setOnboardingForm } = useOnboardingFormContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const { globalState } = useGlobalStateContext();

    useEffect(() => {
        const {
            isMotoPayment,
            totalCardTurnoverIsMoto,
            advanceGoodsMotoProvidedDays,
            isMaxTicketApplied,
            maxTicketValue
        } = onboardingForm.transactionProfile.stepThree;
        const checkValuesForMotoPayment = totalCardTurnoverIsMoto !== null && advanceGoodsMotoProvidedDays !== null;
        const checkValues =
            (isMotoPayment && checkValuesForMotoPayment && isMaxTicketApplied && maxTicketValue) ||
            (isMotoPayment && checkValuesForMotoPayment && !isMaxTicketApplied) ||
            (!isMotoPayment && isMaxTicketApplied && maxTicketValue) ||
            (!isMotoPayment && !isMaxTicketApplied);
        setIsDisabled(!checkValues);
    }, [onboardingForm.transactionProfile.stepThree]);

    const onSubmit = () => {
        var data = { ...onboardingForm.transactionProfile.stepThree };
        if (!onboardingForm.transactionProfile.stepThree.isMotoPayment) {
            data.totalCardTurnoverIsMoto = 0;
            data.advanceGoodsMotoProvidedDays = 0;
            data.isAutoRenewTransactions = false;
            data.motoRenewalReason = null;
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                transactionProfile: {
                    ...onboardingForm.transactionProfile,
                    stepThree: {
                        ...onboardingForm.transactionProfile.stepThree,
                        totalCardTurnoverIsMoto: 0,
                        advanceGoodsMotoProvidedDays: 0,
                        isAutoRenewTransactions: false,
                        motoRenewalReason: null
                    }
                }
            }));
        }

        if (!onboardingForm.transactionProfile.stepThree.isAutoRenewTransactions) {
            data.motoRenewalReason = null;
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                transactionProfile: {
                    ...onboardingForm.transactionProfile,
                    stepThree: {
                        ...onboardingForm.transactionProfile.stepThree,
                        motoRenewalReason: null
                    }
                }
            }));
        }

        if (!onboardingForm.transactionProfile.stepThree.isMaxTicketApplied) {
            data.maxTicketValue = 0;
            setOnboardingForm((onboardingForm) => ({
                ...onboardingForm,
                transactionProfile: {
                    ...onboardingForm.transactionProfile,
                    stepThree: {
                        ...onboardingForm.transactionProfile.stepThree,
                        maxTicketValue: 0
                    }
                }
            }));
        }

        if (onboardingForm.transactionProfile.stepThree.motoRenewalReason) {
            data.motoRenewalReason = onboardingForm.transactionProfile.stepThree.motoRenewalReason;
        }

        NonResellerMerchantService.completeOnboardingStep(globalState.merchant.id, 6, {
            transactionProfileData: data
        });
    };

    useEffect(() => {
        setOnboardingForm((onboardingForm) => ({
            ...onboardingForm,
            completedSteps: {
                ...onboardingForm.completedSteps,
                transactionProfile: isDisabled ? false : true
            }
        }));
    }, [isDisabled, setOnboardingForm]);

    return (
        <OnboardingTransactionProfileStepThreeComponent
            {...props}
            onboardingForm={onboardingForm}
            setOnboardingForm={setOnboardingForm}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
        />
    );
}

export default OnboardingTransactionProfileStepThree;
