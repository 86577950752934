import React from 'react';
import { DocumentTypes, DocumentTypesId } from 'utils/enums/DocumentType';
import _ from 'lodash';

const PendingDocsListComponent = (props) => {
    const { formattedDocumentsStats } = props;

    let titleList = (
        <ul>
            {formattedDocumentsStats?.pendingDocumentCategories?.length
                ? formattedDocumentsStats?.pendingDocumentCategories?.map((category) => {
                      return (
                          <li
                              style={{
                                  color:
                                      category.isOptionalCategory &&
                                      category.documents[0].defaultTypeIds[0] !==
                                          DocumentTypesId.PROOF_OF_ORGANIZATION_TAX_INFO
                                          ? 'green'
                                          : '#f44336'
                              }}
                          >
                              {category.categoryName}
                              <ul>
                                  {category.documents.map((doc) => {
                                      return (
                                          doc.defaultTypeIds.length > 1 &&
                                          doc.typeIds.map((typeId) => {
                                              return (
                                                  <li
                                                      style={{
                                                          color: category.isOptionalCategory ? 'green' : '#f44336'
                                                      }}
                                                  >
                                                      {_.find(DocumentTypes, { id: typeId }).name}
                                                  </li>
                                              );
                                          })
                                      );
                                  })}
                              </ul>
                          </li>
                      );
                  })
                : ''}
        </ul>
    );

    return titleList;
};

export default PendingDocsListComponent;
